import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useRefRect } from 'libs/utils/hooks'
import { isObject } from 'libs/utils/helpers'

import Conditional from 'components/Conditional'

import './style.scss'

const classes = {
  wrapper: 'PopUpMenu-wrapper',
  button: 'PopUpMenu-button',
  arrow: 'PopUpMenu-arrow',
  submenu: 'PopUpMenu-submenu',
  item: 'PopUpMenu-item',
  checked: 'PopUpMenu-checked',
}

const PopUpMenu = props => {
  const [hover, setHover] = useState(false)
  const [rect, container] = useRefRect()
  const {
    dataObj,
    title,
    vertical = 'top',
    horizontal = 'right',
    callback = () => {},
    sub,
    prefix,
  } = props
  const { width = 0, height = 0 } = { ...rect }

  const keys = Object.keys(dataObj)
  const subObjs = keys
    .map(key => {
      return { [key]: dataObj[key] }
    })
    .sort(a => {
      const key = Object.keys(a)[0]
      return (-1) ** Number(isObject(a[key]))
    })

  const styleObj = {
    opacity: hover ? '1' : '',
    zIndex: hover ? '1' : '',
    minWidth: width,
    top: vertical === 'bottom' ? height : '',
    bottom: vertical !== 'bottom' ? height : '',
    flexDirection: vertical !== 'bottom' ? 'column-reverse' : '',
    left: horizontal !== 'left' ? 0 : '',
    right: horizontal === 'left' ? 0 : '',
  }
  if (sub) {
    styleObj.left = horizontal !== 'left' ? width : ''
    styleObj.right = horizontal === 'left' ? width : ''
    styleObj.top = vertical === 'bottom' ? 0 : ''
    styleObj.bottom = vertical !== 'bottom' ? 0 : ''
  }

  return (
    <div
      ref={container}
      className={classes.wrapper}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={classes.button}
        style={{
          flexDirection: horizontal === 'left' ? 'row-reverse' : '',
          textAlign: horizontal === 'left' ? 'right' : '',
        }}
      >
        {title}
        <div className={classes.arrow}>{horizontal === 'left' ? '<' : '>'}</div>
      </div>
      <div style={styleObj} className={classes.submenu}>
        {subObjs.map(e => {
          const key = Object.keys(e)[0]
          return isObject(e[key]) ? (
            <PopUpMenu
              key={`PopUpMenu-submenu-${prefix ? `${prefix}.${key}` : key}`}
              dataObj={e[key]}
              title={key}
              vertical={vertical}
              horizontal={horizontal}
              callback={callback}
              sub
              prefix={prefix ? `${prefix}.${key}` : key}
            />
          ) : (
            <button
              key={`PopUpMenu-button-${prefix ? `${prefix}.${key}` : key}`}
              style={{
                flexDirection: horizontal === 'left' ? 'row-reverse' : '',
                textAlign: horizontal === 'left' ? 'right' : '',
              }}
              className={classes.item}
              type="button"
              onClick={() => {
                callback(prefix ? `${prefix}.${key}` : key, !e[key])
              }}
              tabIndex={hover ? 0 : -1}
            >
              {key}
              <Conditional dependencies={e[key]}>
                <div className={classes.checked}>+</div>
              </Conditional>
            </button>
          )
        })}
      </div>
    </div>
  )
}

PopUpMenu.propTypes = {
  dataObj: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  callback: PropTypes.func,
  sub: PropTypes.bool,
  prefix: PropTypes.string,
}

export default PopUpMenu
