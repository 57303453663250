import React, { useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import Conditional from 'components/Conditional'

import './style.scss'

const classes = {
  wrapper: 'Pagination-wrapper',
  content: 'Pagination-content',
  controlsBar: 'Pagination-controlsBar',
  controlsButton: 'Pagination-controlsButton',
  controlsInput: 'Pagination-controlsInput',
  input: 'Pagination-input',
  scrollBar: 'Pagination-scrollBar',
  range: 'Pagination-range',
}

const Pagination = props => {
  const inputRef = useRef()
  const { dataLength = 0, perPage, currentPage, pageCallback } = props
  const [inputValue, setInputValue] = useState({ focused: false, page: currentPage })
  const maxPages = useMemo(() => {
    return Math.ceil(dataLength / perPage)
  }, [dataLength, perPage])

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.controlsBar}
        // onMouseEnter={event => {
        //   inputRef.current.focus()
        // }}
      >
        <Conditional dependencies={maxPages > 2}>
          <div className={classes.scrollBar}>
            <input
              className={classes.range}
              type="range"
              min={1}
              max={maxPages}
              value={currentPage}
              onChange={event => {
                pageCallback(Number(event.target.value))
              }}
            />
          </div>
        </Conditional>
        <button
          className={classes.controlsButton}
          type="button"
          disabled={currentPage < 3}
          onClick={() => {
            pageCallback(1)
          }}
        >
          &lt;&lt;
        </button>
        <button
          className={classes.controlsButton}
          type="button"
          disabled={currentPage < 2}
          onClick={() => {
            pageCallback(Math.max(currentPage - 1, 1))
          }}
        >
          &lt;
        </button>
        <div className={classes.controlsInput}>
          <input
            type="text"
            ref={inputRef}
            className={classes.input}
            value={inputValue.focused ? inputValue.page : currentPage}
            onChange={event => {
              event.stopPropagation()
              const regex = /\D+/gi
              const value = Number(event.target.value.replace(regex, ''))
              setInputValue({ ...inputValue, page: value })
            }}
            onFocus={event => {
              event.target.select()
              setInputValue({ page: currentPage, focused: true })
            }}
            onBlur={() => {
              setInputValue({ ...inputValue, focused: false })
            }}
            onKeyDown={event => {
              if (event.keyCode === 27) {
                event.target.blur()
              }
              if (event.keyCode === 13 || event.keyCode === 9) {
                const value = Math.max(Math.min(inputValue.page, maxPages), 1)
                pageCallback(value)
                event.target.blur()
              }
            }}
          />
        </div>
        <button
          className={classes.controlsButton}
          type="button"
          disabled={currentPage > maxPages - 1}
          onClick={() => {
            pageCallback(Math.min(currentPage + 1, maxPages))
          }}
        >
          &gt;
        </button>
        <button
          className={classes.controlsButton}
          type="button"
          disabled={currentPage > maxPages - 2}
          onClick={() => {
            pageCallback(maxPages)
          }}
        >
          &gt;&gt;
        </button>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  dataLength: PropTypes.number,
  perPage: PropTypes.number,
  currentPage: PropTypes.number,
  pageCallback: PropTypes.func,
}

export default Pagination
