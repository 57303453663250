/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
import React, { useState, useMemo, useCallback, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { useRefRect } from 'libs/utils/hooks'
import { extractValue } from 'libs/utils/helpers'

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Conditional from 'components/Conditional'
import { useMouseTracker } from 'components/MouseTracker'
import { Context } from './TableContext'

const classes = {
  wrapper: 'Table-Column-wrapper',
  content: 'Table-Column-content',
  handler: 'Table-Column-handler',
  headerCell: 'Table-Column-headerCell',
  headerCellContent: 'Table-Column-headerCellContent',
  sortArrow: 'Table-Column-sortArrow',
  rowCell: 'Table-Column-rowCell',
  activeRow: 'Table-Column-activeRow',
}

const resizeHideValue = 5

const Column = props => {
  const [rect, container] = useRefRect()
  const [resize, setResize] = useState({
    dragging: false,
    start: null,
    startWidth: null,
  })
  const [reorder, setReorder] = useState({
    dragging: false,
    start: null,
    startScroll: null,
    startOffset: null,
  })
  const [mouseData, setMouseData] = useState({ x: 0, y: 0, mousedown: false })
  const { dataKey, setRef } = props
  const {
    columns,
    order,
    hasCheck,
    sort,
    getTableLeft,
    data,
    fullData,
    onRow,
    updateTablePref,
    getScrollPosition,
    setPlaceholderOrder,
    sortData,
  } = useContext(Context)
  const settings = { ...columns[dataKey] }
  const {
    resizeWidth,
    hidden,
    title,
    width: defaultWidth,
    resizable,
    render,
    sortFunction,
    className,
    type,
  } = {
    ...settings,
  }
  const columnOrder = order.indexOf(dataKey)
  const placeholderOrder = order.indexOf('_placeholderColumn')
  const sortOrder = dataKey === sort.key ? sort.order : 0
  const { minWidth } = { ...resizable }
  const { width: containerWidth } = { ...rect }

  const resizingWidth = Math.max(
    resize.startWidth +
      ((mouseData.mousedown ? mouseData.x : resize.start) - resize.start),
    minWidth || resizeHideValue
  )

  const columnWidth = useMemo(() => {
    return (
      (resize.dragging && resizingWidth) ||
      resizeWidth ||
      defaultWidth ||
      (reorder.dragging && containerWidth)
    )
  }, [
    resize.dragging,
    reorder.dragging,
    resizingWidth,
    defaultWidth,
    resizeWidth,
    containerWidth,
  ])

  const columnLeft = useMemo(() => {
    const scroll = getScrollPosition()
    let tempLeft = 0
    if (reorder.dragging) {
      tempLeft = reorder.start
      if (mouseData.mousedown) tempLeft = mouseData.x
      tempLeft -= getTableLeft() + reorder.startOffset
      tempLeft = Math.max(tempLeft, -scroll)
    }
    return tempLeft ? scroll + tempLeft : scroll
  }, [
    getScrollPosition,
    reorder.dragging,
    reorder.start,
    reorder.startOffset,
    mouseData.x,
    mouseData.mousedown,
    getTableLeft,
  ])

  const [timerSet, timerClear] = useMemo(() => {
    let timer = null
    const timeOutSet = event => {
      event.persist()
      const offset = event.currentTarget.getBoundingClientRect().left
      timer = setTimeout(() => {
        setReorder({
          dragging: true,
          start: event.clientX,
          startScroll: getScrollPosition(),
          startOffset: event.clientX - offset,
        })
        const newOrder = Math.max(columnOrder, Number(hasCheck))
        setPlaceholderOrder({ order: newOrder, key: dataKey })
      }, 500)
    }
    const timeOutClear = () => clearTimeout(timer)
    return [timeOutSet, timeOutClear]
  }, [getScrollPosition, columnOrder, hasCheck, setPlaceholderOrder, dataKey])

  const pointArray = useMemo(() => {
    if (reorder.dragging) {
      const tempObj = order.reduce(
        (acc, cur, i) => {
          let tempWidth = null
          if (cur === '_placeholderColumn') {
            tempWidth = columnWidth
          } else {
            tempWidth =
              columns[cur]?._ref().current?.getBoundingClientRect().width || 0
          }
          const tempWidths = [...acc.widths]
          tempWidths.push(tempWidth)
          const tempPoints = [...acc.points]
          tempPoints.push(tempWidth / 2 + acc.sums[i])
          const tempSums = [...acc.sums]
          tempSums.push(tempSums[i] + tempWidth)
          return {
            widths: tempWidths,
            points: tempPoints,
            sums: tempSums,
          }
        },
        { widths: [], points: [], sums: [0] }
      )
      return placeholderOrder !== -1
        ? [
            tempObj.points.slice(0, placeholderOrder),
            tempObj.points.slice(placeholderOrder + 1),
          ]
        : null
    }
    return null
  }, [columns, columnWidth, order, reorder.dragging, placeholderOrder])

  const newOrder = useMemo(() => {
    let tempNewOrder = null
    if (pointArray) {
      tempNewOrder = pointArray[0].find(point => point > columnLeft)
      tempNewOrder = pointArray[0].indexOf(tempNewOrder)
      if (tempNewOrder === -1) {
        const reversed = [...pointArray[1]].reverse()
        tempNewOrder = reversed.find(point => point < columnLeft + columnWidth)
        tempNewOrder = pointArray[1].indexOf(tempNewOrder) + pointArray[0].length + 1
      }
    }
    return tempNewOrder
  }, [pointArray, columnLeft, columnWidth])

  const updateMouseData = useCallback(
    hookMouseData => {
      if (resize.dragging || reorder.dragging) {
        if (!hookMouseData.mousedown) {
          if (resize.dragging) {
            if (resizingWidth > resizeHideValue) {
              const settingsObj = { resizeWidth: resizingWidth }
              updateTablePref(dataKey, settingsObj)
              setResize({ dragging: false, start: null, startWidth: null })
            } else {
              const settingsObj = { hidden: true }
              updateTablePref(dataKey, settingsObj)
              setResize({ dragging: false, start: null, startWidth: null })
            }
          }
          if (reorder.dragging) {
            const settingsObj = { _order: Math.max(newOrder - Number(hasCheck), 0) }
            updateTablePref(dataKey, settingsObj)
            setReorder({
              dragging: false,
              start: null,
              startScroll: null,
              startOffset: null,
            })
            setPlaceholderOrder(null)
          }
          setMouseData({ x: 0, y: 0, mousedown: false })
        } else setMouseData(hookMouseData)
      }
    },
    [
      resize.dragging,
      reorder.dragging,
      newOrder,
      hasCheck,
      dataKey,
      updateTablePref,
      resizingWidth,
      setPlaceholderOrder,
    ]
  )
  useMouseTracker(hookMouseData => updateMouseData(hookMouseData))

  const orderLength =
    order.filter(key => key !== '_actionsColumn' && key !== '_fillerColumn').length -
    1

  useEffect(() => {
    if (reorder.dragging && (newOrder || newOrder === 0))
      setPlaceholderOrder({
        order: Math.min(Math.max(newOrder, Number(hasCheck)), orderLength),
        key: dataKey,
      })
  }, [
    reorder.dragging,
    setPlaceholderOrder,
    newOrder,
    hasCheck,
    orderLength,
    dataKey,
  ])

  const sortCallback = useCallback(() => {
    sortData({ key: dataKey, sort: sortFunction, order: (sortOrder + 1) % 3 })
  }, [sortData, dataKey, sortFunction, sortOrder])

  const onRowCallback = useCallback(
    event => {
      const { index } = event.currentTarget.dataset
      if (onRow) onRow(data[index])
    },
    [data, onRow]
  )

  return (
    <>
      <div
        ref={container}
        className={`${classes.wrapper}${className ? ` ${className}` : ''}`}
        style={{
          width: columnWidth ? `${columnWidth}px` : '',
          flexGrow: columnWidth ? 0 : '',
          flexShrink: columnWidth ? 0 : '',
          display: hidden ? 'none' : 'flex',
          cursor: resize.dragging ? 'w-resize' : '',
          position: reorder.dragging ? 'absolute' : '',
          order: reorder.dragging ? '' : columnOrder,
          left: reorder.dragging ? columnLeft : '',
          zIndex: reorder.dragging ? 1 : '',
          borderLeft: reorder.dragging ? '1px solid dodgerblue' : '',
          borderRight: reorder.dragging ? '1px solid dodgerblue' : '',
          opacity: reorder.dragging ? '0.4' : '',
        }}
      >
        <div ref={setRef(dataKey)} className={classes.content}>
          <div
            role="columnheader"
            tabIndex={sortFunction ? '0' : -1}
            className={`${classes.headerCell}${sortFunction ? ' sorter' : ''}`}
            onMouseDown={event => {
              if (['checker', 'actions', 'filler'].indexOf(type) === -1)
                timerSet(event)
            }}
            onMouseUp={() => {
              if (sortFunction && !reorder.dragging) sortCallback()
              timerClear()
            }}
            onKeyDown={event => {
              if (event.keyCode === 13 && sortFunction && !reorder.dragging)
                sortCallback()
            }}
          >
            <div className={classes.headerCellContent}>
              {render ? render(title, true, null, fullData, dataKey) : title}
            </div>
            <Conditional dependencies={!!sortFunction}>
              <div className={classes.sortArrow}>
                {!!sortOrder &&
                  (sortOrder === 1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
              </div>
            </Conditional>
          </div>
          {data.map((datum, i) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`Table-rowCell-${datum.datakey}-${i}`}
              onClick={onRowCallback}
              className={`${classes.rowCell}${onRow ? ` ${classes.activeRow}` : ''}`}
              data-index={i}
            >
              {render
                ? render(
                    extractValue(datum, dataKey),
                    false,
                    datum,
                    fullData,
                    dataKey
                  )
                : extractValue(datum, dataKey)}
            </div>
          ))}
        </div>
        <Conditional dependencies={resizable}>
          <div
            style={{ opacity: resize.dragging ? 1 : '' }}
            className={classes.handler}
            onMouseDown={event => {
              setResize({
                dragging: true,
                start: event.clientX,
                startWidth: columnWidth || containerWidth,
              })
            }}
            onDoubleClick={() => {
              const settingsObj = { resizeWidth: null }
              updateTablePref(dataKey, settingsObj)
            }}
          />
        </Conditional>
      </div>
      <Conditional dependencies={reorder.dragging}>
        <div
          className={`${classes.wrapper} placeholder`}
          style={{
            width: columnWidth ? `${columnWidth}px` : '',
            flexGrow: columnWidth ? 0 : '',
            flexShrink: columnWidth ? 0 : '',
            order: placeholderOrder,
          }}
        />
      </Conditional>
    </>
  )
}

Column.propTypes = {
  dataKey: PropTypes.string.isRequired,
  setRef: PropTypes.func,
}

export default Column
