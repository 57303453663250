export const classes = {
  wrapper: 'ResourceTable-wrapper',
  loader: 'ResourceTable-loader',
  headerSortingCellDiv: 'ResourceTable-headerSortingCellDiv',
  textDiv: 'ResourceTable-textDiv',
  sortingArrow: 'ResourceTable-sortingArrow',
  dropDownHeader: 'ResourceTable-dropDownHeader',
  stat: 'ResourceTable-stat',
  svgBar: 'ResourceTable-svgBar',
}
