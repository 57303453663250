import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import { generateColors } from 'libs/utils/color'

import UserControlsBar from 'components/UserControls'
import ConfusionMatrixGraph from '../ConfusionMatrixGraph'
import { Provider } from '../ConfusionMatrixChartContext'

const classes = {
  wrapper: 'ConfusionMatrixChart-UserControlWrapper-wrapper',
}

const UserControlWrapper = props => {
  const {
    controlable,
    datasets: propDatasets,
    features: propFeatures,
    featureType: propFeatureType,
    combineDatasets,
    combineFeatures,
    mainColor,
  } = props
  const { prefKey } = controlable
  const {
    pref: { [prefKey]: storePref = {} },
    updatePref,
    datasets: storeDatasets,
  } = useStore('selectPref', 'updatePref', 'selectDatasets')
  const [statePref, setStatePref] = useState({})

  const storeData = {
    datasets: storeDatasets,
    //  brains: []
  }

  const pref = (prefKey && storePref) || statePref

  const datasets = useMemo(() => {
    return pref.datasets || propDatasets || []
  }, [pref.datasets, propDatasets])
  const features = useMemo(() => {
    return pref.features || propFeatures || []
  }, [pref.features, propFeatures])
  const type =
    propFeatureType || pref.featureType || (features[0] && features[0].type) || null

  const datasetsCallback = selectedDatasets => {
    const tempPref = { ...pref }
    tempPref.datasets = selectedDatasets
    if (prefKey) {
      updatePref({ prefKey, data: tempPref })
    } else setStatePref(tempPref)
  }
  const featuresCallback = selectedFeatures => {
    const tempPref = { ...pref }
    tempPref.features = selectedFeatures
    tempPref.featureType = selectedFeatures[0] && selectedFeatures[0].type
    if (prefKey) {
      updatePref({ prefKey, data: tempPref })
    } else setStatePref(tempPref)
  }

  const controls = []
  if (controlable.datasets) {
    controls.push({
      control: 'datasetSelect',
      values: datasets,
      callback: datasetsCallback,
      multiple: controlable.datasets.multiple,
    })
  }
  if (controlable.features) {
    controls.push({
      control: 'featureSelect',
      ids: datasets,
      values: features,
      callback: featuresCallback,
      multiple: controlable.features.multiple,
      type: propFeatureType,
      title: `Feature${controlable.features.multiple ? 's' : ''}`,
    })
  }

  const data = useMemo(() => {
    const returnData = []
    if (datasets.length && features.length) {
      const filteredStoreData = storeData.datasets.filter(
        ({ id }) => datasets.indexOf(id) !== -1
      )
      const tempData = features.reduce((acc, cur) => {
        const tempResult = filteredStoreData
          .filter(instance =>
            instance.spec.features.find(
              feature => feature.type === cur.type && feature.name === cur.name
            )
          )
          .map(instance => {
            return {
              dataset: instance.id,
              featureName: cur.name,
              featureType: cur.type,
              data: instance.data.map(e => e[cur.name]),
            }
          })
        if (combineDatasets) {
          const combinedResult = tempResult.reduce(
            (accumulated, current) => {
              const tempCombined = { ...accumulated }
              tempCombined.data = [...tempCombined.data, ...current.data]
              return tempCombined
            },
            {
              dataset: 'combined',
              featureName: cur.name,
              featureType: cur.type,
              data: [],
            }
          )
          return [...acc, combinedResult]
        }
        return [...acc, ...tempResult]
      }, [])
      let resultData = tempData
      if (combineFeatures) {
        resultData = resultData.reduce((acc, cur) => {
          const datasetID = cur.dataset
          let instance = acc.find(datum => datum.dataset === datasetID)
          if (instance) {
            instance.data = [...instance.data, ...cur.data]
            return acc
            // return [...acc.map(e => (e.dataset === datasetID ? instance : e))] // <-- utvrdi da li je ovo potrebno
          }
          instance = {
            dataset: datasetID,
            featureName: 'combined',
            featureType: cur.featureType,
            data: [...cur.data],
          }
          const tempResult = [...acc]
          tempResult.push(instance)
          return tempResult
        }, [])
      }
      resultData = resultData.map(
        ({ dataset, featureName, featureType, data: dataArray }) => {
          return {
            title: `${dataset}-${featureName}-${featureType}`,
            data: dataArray,
          }
        }
      )
      returnData.push(...resultData)
    }
    const colorArray =
      (returnData.length &&
        mainColor &&
        generateColors(
          mainColor,
          returnData.length,
          0.2 + returnData.length / (returnData.length + 1) / 10
        )) ||
      []
    return returnData.map((datum, i) => {
      return { ...datum, color: colorArray[i] }
    })
  }, [
    storeData.datasets,
    features,
    combineDatasets,
    combineFeatures,
    datasets,
    mainColor,
  ])

  const contextData = {
    type,
    data,
  }

  return (
    <div className={classes.wrapper}>
      <UserControlsBar controls={controls} />
      <Provider value={contextData}>
        <ConfusionMatrixGraph />
      </Provider>
    </div>
  )
}

UserControlWrapper.propTypes = {
  controlable: PropTypes.shape({
    datasets: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    features: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    prefKey: PropTypes.string,
  }),
  datasets: PropTypes.arrayOf(PropTypes.string),
  features: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
  ),
  featureType: PropTypes.string,
  combineBrains: PropTypes.bool,
  combineDatasets: PropTypes.bool,
  combineFeatures: PropTypes.bool,
  mainColor: PropTypes.string,
}

export default UserControlWrapper
