import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect, useStore } from 'core'

import {
  ProfilePage,
  SubscriptionsPage,
  UsersPage,
  LicencesPage,
  PlansPage,
} from 'pages/UserSection'

const USER_PERMISSIONS = {
  MANAGE_USER_LICENCES: 'MANAGE_USER_LICENCES',
  MANAGE_ORGANIZATION_PAYMENTS: 'MANAGE_ORGANIZATION_PAYMENTS',
  MANAGE_USER_PERMISSIONS: 'MANAGE_USER_PERMISSIONS',
}

const UserSectionRouter = props => {
  const {
    userPermissions = [
      'MANAGE_USER_LICENCES',
      'MANAGE_ORGANIZATION_PAYMENTS',
      'MANAGE_USER_PERMISSIONS',
    ],
    // 'selectUserPermissions'
  } = useStore()

  const showSubscriptionPage =
    userPermissions?.indexOf(USER_PERMISSIONS.MANAGE_ORGANIZATION_PAYMENTS) !== -1
  const showPlansPage =
    userPermissions?.indexOf(USER_PERMISSIONS.MANAGE_ORGANIZATION_PAYMENTS) !== -1 ||
    userPermissions?.indexOf(USER_PERMISSIONS.MANAGE_USER_LICENCES) !== -1
  const showLicencesPage =
    userPermissions?.indexOf(USER_PERMISSIONS.MANAGE_USER_LICENCES) !== -1
  const showUsersPage = true
  //   userPermissions?.indexOf(USER_PERMISSIONS.MANAGE_USER_PERMISSIONS) !== -1 ||
  //   userPermissions?.indexOf(USER_PERMISSIONS.MANAGE_USER_LICENCES) !== -1

  const { match } = props
  const routes = []
  if (showSubscriptionPage)
    routes.push(
      <Route
        key="subscriptionPageRoute"
        path={`${match.path}/subscriptions`}
        component={SubscriptionsPage}
      />
    )
  else
    routes.push(
      <Route
        key="subscriptionPageRoute"
        path={`${match.path}/subscriptions`}
        render={() => <pre>You do not have permission to view this page</pre>}
      />
    )
  if (showUsersPage)
    routes.push(
      <Route
        key="usersPageRoute"
        path={`${match.path}/users`}
        component={UsersPage}
      />
    )
  else
    routes.push(
      <Route
        key="usersPageRoute"
        path={`${match.path}/users`}
        render={() => <pre>You do not have permission to view this page</pre>}
      />
    )
  if (showLicencesPage)
    routes.push(
      <Route
        key="licencesPageRoute"
        path={`${match.path}/licences`}
        component={LicencesPage}
      />
    )
  else
    routes.push(
      <Route
        key="licencesPageRoute"
        path={`${match.path}/licences`}
        render={() => <pre>You do not have permission to view this page</pre>}
      />
    )
  if (showPlansPage)
    routes.push(
      <Route
        key="plansPageRoute"
        path={`${match.path}/plans`}
        component={PlansPage}
      />
    )
  else
    routes.push(
      <Route
        key="plansPageRoute"
        path={`${match.path}/plans`}
        render={() => <pre>You do not have permission to view this page</pre>}
      />
    )
  return (
    <Switch>
      <Redirect exact path={`${match.path}`} to={`${match.path}/profile`} />
      <Route path={`${match.path}/profile`} component={ProfilePage} />
      {routes}
      <Route
        render={({ location: loc }) => {
          return (
            <pre>{`  404 Page Not Found\n\n  The page under "${loc.pathname}" does not exist.`}</pre>
          )
        }}
      />
    </Switch>
  )
}

UserSectionRouter.propTypes = {
  match: PropTypes.exact({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.object,
    isExact: PropTypes.bool,
  }),
}

export default UserSectionRouter
