import React, { useState } from 'react'
import { useStore } from 'core'
import './style.scss'

import Conditional from 'components/Conditional'
import { BaseCard as Card } from 'components/CardLayout'
import { Button } from '@material-ui/core'
import ScoringInspector from 'components/ScoringInspector'
import { ScoringTableHeader, ScoringTableEntry } from './components'

const classes = {
  wrapper: 'ScoringsPage-wrapper',
  title: 'ScoringsPage-title',
  subTitle: 'ScoringsPage-subTitle',
  horizontalScrollDiv: 'ScoringsPage-horizontalScrollDiv',
  scrollDiv: 'ScoringsPage-scrollDiv',
}

const ScoringsPage = () => {
  const [sort, setSort] = useState({ column: 'name', order: 1 })
  const {
    scorings: storeScorings,
    selectedScoring: selectedScoringId,
    selectedScoredDataset: selectedScoredDatasetId,
    initializeScoringWizard,
  } = useStore(
    'selectScorings',
    'selectSelectedScoring',
    'selectSelectedScoredDataset',
    'initializeScoringWizard'
  )

  const {
    name: activeName = '',
    id: resourceID = null,
    type: resourceType,
  } = {
    ...(storeScorings
      ?.find(({ id }) => id === selectedScoringId)
      ?.datasets?.find(({ id }) => id === selectedScoredDatasetId) || {}),
  }

  const sortFunction = (a, b, key, order) => {
    const aVal = a[key]
    const bVal = b[key]
    let result = 0
    if (typeof aVal === 'string' || typeof bVal === 'string') {
      result = `${aVal}`.localeCompare(`${bVal}`, 'en', {
        sensitivity: 'base',
      })
    } else result = aVal - bVal
    return result * (-1) ** order
  }

  const scorings = (storeScorings || [])
    .map(e => {
      const { name, id, datasets } = e
      const lastScored = (datasets || []).sort((a, b) =>
        sortFunction(a, b, 'created_at', 1)
      )[0]
      const status = lastScored?.status
      const date = lastScored?.created_at
      return { name, id, status, date, original: e }
    })
    .sort((a, b) => sortFunction(a, b, sort.column, sort.order + 1))

  return (
    <>
      <div className={classes.wrapper}>
        <Card>
          <div className={classes.title}>Predictions</div>
          <span className={classes.subTitle}>
            Predictions use a set of models to score (new) data and can run
            dynamically, if an automation is specified.
          </span>
          <div className={classes.horizontalScrollDiv}>
            <ScoringTableHeader
              sort={{
                column: sort.column,
                order: sort.order,
                callback: (column, order) => {
                  if (order) {
                    const tempSort = { column, order }
                    setSort(tempSort)
                  } else setSort({ column: null, order })
                },
              }}
            />
            <div className={classes.scrollDiv}>
              {scorings.map(sc => (
                <ScoringTableEntry
                  key={sc.id}
                  id={sc.id}
                  name={sc.name}
                  status={sc.status}
                  date={sc.date}
                  original={sc.original}
                  selected={sc.id === selectedScoringId}
                  sort={sort}
                />
              ))}
            </div>
          </div>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              initializeScoringWizard(
                null,
                null,
                null,
                null,
                null,
                false,
                true,
                true,
                0
              )
            }}
          >
            Score a dataset
          </Button>
        </Card>
      </div>
      <Conditional dependencies={resourceID}>
        {resourceID && (
          <ScoringInspector
            title={activeName}
            resource={resourceID}
            resourceType={resourceType}
          />
        )}
      </Conditional>
    </>
  )
}

export default ScoringsPage
