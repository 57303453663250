/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Slider from 'components/DataSplitSlider'

import 'style.scss'

import {
  TextField,
  MenuItem,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import {
  Form,
  FormItem,
  FormConditional,
  FormFor,
  FormAction,
} from 'components/Form'
import RemoveObjectiveButton from 'components/RemoveObjectiveButton'

import { classes as classNames } from '../../classNames'
import { validator as prevValidator } from '../2'

const metrics = {
  numeric: [
    { type: 'mse', text: 'Mean square error (mse)' },
    { type: 'mae', text: 'Mean absolute error (mae)' },
    { type: 'sortino', text: 'Sortino diff' },
    { type: 'sortino_short', text: 'Sortino with shorting diff' },
    { type: 'exp_ret', text: 'Expected annual return diff' },
    {
      type: 'mse_10',
      text: 'Mse of the worst 10%',
    },
    {
      type: 'mae_10',
      text: 'Mae of the worst 10%',
    },
  ],
  category: [
    { type: 'kappa', text: 'Kappa' },
    {
      type: 'f1_score',
      text: 'F1 score',
    },
    {
      type: 'recall',
      text: 'Recall',
    },
    {
      type: 'precision',
      text: 'Precision',
    },
    { type: 'acc', text: 'Accuracy' },
    {
      type: 'fp',
      text: 'False Positives',
    },
    {
      type: 'fn',
      text: 'False Negatives',
    },
  ],
}
const expandObjectiveMetrics = objectives =>
  objectives.reduce((acc, { id = '', metric = '' }) => {
    if (metric.constructor === Array) {
      metric.forEach((m = '') => acc.push({ id, metric: m }))
    } else {
      acc.push({ id, metric })
    }
    return acc
  }, [])

const flatten = (obj, flatKey, result = {}) => {
  // result = result || obj.constructor()
  Object.keys(obj).forEach(key => {
    const isParentArray = Array.isArray(obj)
    let fullKey = ''

    if (isParentArray) fullKey = `${flatKey || ''}[${key}]`
    else fullKey = flatKey ? `${flatKey}.${key}` : key

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      return flatten(obj[key], fullKey, result)
    }
    // eslint-disable-next-line no-return-assign
    return (result[fullKey] = { _value: obj[key] })
  })
  return result
}

const hasSpecChanged = (prev, next) => {
  if (
    prev &&
    next &&
    prev.objectives &&
    next.objectives &&
    prev.objectives.length !== next.objectives.length
  )
    return true
  prev = prev ? flatten(prev) : {}
  next = next ? flatten(next) : {}
  /* eslint-disable no-underscore-dangle */
  return (
    Object.keys(next).some(key => {
      const nextVal = next[key] ? next[key]._value : null
      const prevVal = prev[key] ? prev[key]._value : null
      return prevVal !== nextVal
    }) ||
    Object.keys(prev).some(key => {
      const nextVal = next[key] ? next[key]._value : null
      const prevVal = prev[key] ? prev[key]._value : null
      return prevVal !== nextVal
    })
  )
  /* eslint-enable no-underscore-dangle */
}

const classes = {
  ...classNames,
  stepThree: 'TrainingWizard-step-three',
  noDataMsg: 'TrainingWizard-no-data-msg',
  alternativeDataMsg: 'TrainingWizard-alternative-data-msg',
  splitWrapper: 'TrainingWizard-split-wrapper',
  splitDiv: 'TrainingWizard-split-div',
  splitSpan: 'TrainingWizard-split-span',
  validationMsg: 'TrainingWizard-validation-msg',
  row: 'TrainingWizard-config-row',
  removeObjectiveButton: 'TrainingWizard-remove-btn',
}

const objectivesValidator = objectives =>
  !!objectives.length &&
  objectives.every(e => `${e.id}` && e.metric) &&
  objectives.map(e => `${e.id}-${e.metric}`).reduce((a, b) => a !== b)

const gapsValidator = (objectives, output) =>
  !objectives.length ||
  objectives.some(e => !e.id) ||
  (output &&
    output.features
      .filter(
        feature =>
          !objectives.some(objective => String(objective.id) === String(feature.id))
      )
      .every(feature => !feature.stats.nmissing))

// const varianceValidator = (objectives, output) => {
//   return (
//     !objectives.length ||
//     objectives.some(e => !e.id) ||
//     (output &&
//       output.features
//         .filter(feature =>
//           objectives.some(objective => String(objective.id) === String(feature.id))
//         )
//         .every(
//           feature =>
//             // eslint-disable-next-line dot-notation
//             feature.stats['grouped_uniqueness' /* 'variance' */] >
//             (feature.stats.nmissing ? 2 : 1)
//         ))
//   )
// }

const labelValidator = (objectives, output) => {
  return (
    !objectives.length ||
    objectives.some(e => !e.id) ||
    (output &&
      output.features
        .filter(feature =>
          objectives.some(objective => String(objective.id) === String(feature.id))
        )
        .every(feature => feature.stats.could_be_label))
  )
}

export const validator = training => {
  const { spec, title, pipeline_output } = training

  return (
    prevValidator(training) &&
    objectivesValidator(spec.objectives) &&
    // splitValidator(spec) &&
    gapsValidator(spec.objectives, pipeline_output) &&
    // varianceValidator(spec.objectives, pipeline_output) &&
    !!title
  )
}

const TrainingWizardStepThree = props => {
  const formRef = useRef()
  window.forma = formRef
  const { training } = props
  const { title, step, spec: originalSpec, pipeline_output, id } = training
  const spec = {
    ...originalSpec,
    objectives: expandObjectiveMetrics(originalSpec.objectives),
  }

  const { objectives } = spec
  const { setTrainingWizardSpec, setTrainingWizardName } = useStore(
    'setTrainingWizardSpec',
    'setTrainingWizardName'
  )
  const stepCompleted = validator(training)
  // const splitError = () => !splitValidator(spec)
  const labelError = !labelValidator(objectives, pipeline_output)
  const gapsError = !gapsValidator(objectives, pipeline_output)
  // const varianceError = !varianceValidator(objectives, pipeline_output)
  const uniqueError = useMemo(
    () =>
      objectives.length &&
      objectives.every(e => e.id && e.metric) &&
      !objectives.map(e => `${e.id}-${e.metric}`).reduce((a, b) => a !== b),
    [objectives]
  )
  const features = (pipeline_output ? pipeline_output.features : [])
    .filter(f => f.type === 'numeric' || f.type === 'category')
    .filter(f => f.id !== pipeline_output.stacked_by)
  const datasetType = pipeline_output ? pipeline_output.type : 'basedata'
  const disabled = step !== 2
  const formChangeHandler = state => {
    if (hasSpecChanged(spec, state)) {
      setTrainingWizardSpec({
        ...spec,
        ...state,
        objectives: state.objectives ? state.objectives.filter(e => e) : [],
      })
    }
  }
  const addObjective = () => {
    const { objectives: obj } = formRef.current.get()
    formRef.current.set('objectives', [
      ...(obj || []).filter(e => e),
      { id: '', metric: '' },
    ])
  }

  return (
    <div className={classes.step} data-active={step === 2}>
      <div className={classes.progressWrapper}>
        <div className={classes.progressBar} data-completed={stepCompleted} />
      </div>
      <div className={`${classes.stepContent} ${classes.stepThree}`}>
        <TextField
          disabled={disabled}
          // dataKey="name"
          // defaultValue={`New Training - ${moment().format('hh:mm YYYY-MM-DD')}`}
          defaultValue={title}
          label="Name"
          inputProps={{ autoFocus: true }}
          onChange={e => setTrainingWizardName(e.target.value)}
        />
        <Form
          ref={formRef}
          preload={spec}
          // preload={formPreload}
          onChange={formChangeHandler}
          key={id}
        >
          <h2>Configure the training:</h2>
          <Conditional dependencies={!pipeline_output}>
            <span className={classes.noDataMsg}>No metadata available.</span>
            <span className={classes.alternativeDataMsg}>
              Please make sure pipeline is processable.
            </span>
          </Conditional>
          <Conditional dependencies={pipeline_output}>
            <div className={`${classes.splitWrapper} ${classes.stepSection}`}>
              <div className={classes.sectionHeader}>
                <span>
                  Dataset Split<span>Train</span>
                  <span>Validate</span>
                  <span>Test</span>
                </span>
                <Tooltip
                  title={
                    <div className={classes.sectionInfo}>
                      <span>
                        <span>TRAIN:</span>
                        This percentage of the dataset that is used for the training
                        of the model.
                      </span>
                      <span>
                        <span>VALIDATE:</span>
                        This percentage of the dataset that is used for
                        cross-validation.
                      </span>
                      <span>
                        <span>TEST:</span>
                        This percentage of the dataset that is used for testing and
                        is not used by the training and optimization process.
                      </span>
                    </div>
                  }
                  placement="left"
                >
                  <Icon type="info" />
                </Tooltip>
              </div>
              <Slider total={1} step={0.05} normalized />
            </div>
            <div className={classes.stepSection} style={{ paddingBottom: '0px' }}>
              <div className={classes.sectionHeader}>
                <span>Training Setup</span>
                <Tooltip
                  title={
                    <div className={classes.sectionInfo}>
                      <Conditional dependencies={datasetType !== 'basedata'}>
                        <span>
                          LOOKBACK PERIOD: Number of samples that are used for the
                          moving window.
                        </span>
                        <span>
                          PREDICTION HORIZON: Number of the future sample that the
                          model is supposed to predict.
                        </span>
                        <Conditional
                          dependencies={datasetType === 'stacked-timeseries'}
                        >
                          <span>
                            IGNORE STACKING FEATURE: Ignore stacking feature for
                            normalization and training, to build models for unseen
                            stacking categories.
                          </span>
                        </Conditional>
                      </Conditional>
                      <span>
                        INPUT OPTIMIZATION: Automatically select inputs instead of
                        using all provided inputs.
                      </span>
                    </div>
                  }
                  placement="left"
                >
                  <Icon type="info" />
                </Tooltip>
              </div>
              <div className={classes.row}>
                <FormItem
                  disabled={disabled}
                  dataKey="max_no_of_brains"
                  defaultValue={100}
                  select
                  label="Maximum brains"
                  fullWidth
                  // style={{ width: '48%' }}
                  component={TextField}
                  // onChange={changeHandler}
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={300}>300</MenuItem>
                  <MenuItem value={1000}>1000</MenuItem>
                  <MenuItem value={10000}>10000</MenuItem>
                  <MenuItem value={0}>unlimited</MenuItem>
                </FormItem>
                <FormItem
                  disabled={disabled}
                  dataKey="time_to_run_seconds"
                  defaultValue={10800}
                  select
                  label="Maximum run time"
                  fullWidth
                  // style={{ width: '48%' }}
                  component={TextField}
                  // onChange={changeHandler}
                >
                  <MenuItem value={3 * 60 * 60}>3 hours</MenuItem>
                  <MenuItem value={6 * 60 * 60}>6 hours</MenuItem>
                  <MenuItem value={12 * 60 * 60}>12 hours</MenuItem>
                  <MenuItem value={24 * 60 * 60}>24 hours</MenuItem>
                  <MenuItem value={7 * 24 * 60 * 60}>1 week</MenuItem>
                  <MenuItem value={0}>unlimited</MenuItem>
                </FormItem>
              </div>
              <Conditional dependencies={datasetType !== 'basedata'}>
                <div className={classes.row}>
                  <FormItem
                    disabled={disabled}
                    dataKey="metadata.lookback"
                    defaultValue={6}
                    inputProps={{ min: 2 }}
                    type="number"
                    label="Lookback Period"
                    fullWidth
                    style={{ width: '48%' }}
                    component={TextField}
                    // onChange={changeHandler}
                  />
                  <FormItem
                    disabled={disabled}
                    dataKey="metadata.prediction_horizon"
                    defaultValue={6}
                    inputProps={{ min: 0 }}
                    type="number"
                    label="Prediction Horizon"
                    fullWidth
                    style={{ width: '48%' }}
                    component={TextField}
                    // onChange={changeHandler}
                  />
                </div>
              </Conditional>
              <Conditional dependencies={datasetType === 'stacked-timeseries'}>
                <div className={classes.row}>
                  <FormItem
                    dataKey="metadata.ignore_stacking"
                    margin="dense"
                    label="Ignore stacking feature"
                    labelPlacement="start"
                    style={{
                      width: '100%',
                      flex: 1,
                      // marginTop: '10px',
                      marginLeft: '0',
                      marginRight: '0',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    checked
                    control={<Switch color="primary" />}
                    component={FormControlLabel}
                  />
                </div>
              </Conditional>
              <div className={classes.row}>
                <FormItem
                  dataKey="optimize_inputs"
                  margin="dense"
                  label="Optimize inputs"
                  labelPlacement="start"
                  style={{
                    width: '100%',
                    flex: 1,
                    // marginTop: '10px',
                    marginLeft: '0',
                    marginRight: '0',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  checked
                  control={<Switch color="primary" />}
                  component={FormControlLabel}
                />
              </div>
              <div className={classes.row}>
                <FormItem
                  dataKey="reduce_dimensionality"
                  margin="dense"
                  label="Reduce dimensionality"
                  labelPlacement="start"
                  style={{
                    width: '100%',
                    flex: 1,
                    // marginTop: '10px',
                    marginLeft: '0',
                    marginRight: '0',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  checked
                  control={<Switch color="primary" />}
                  component={FormControlLabel}
                />
              </div>
            </div>
            <div className={classes.stepSection}>
              <div className={classes.sectionHeader}>
                <span>Objectives</span>
                {/* <Tooltip
                  title={<div className={classes.sectionInfo}>LALALA</div>}
                  placement="left"
                >
                  <Icon type="info" />
                </Tooltip> */}
              </div>
              <FormFor dataKey="objectives">
                <div className={classes.row}>
                  <FormItem
                    disabled={disabled}
                    dataKey="id"
                    defaultValue=""
                    select
                    label="Select Objective"
                    fullWidth
                    // style={{ width: '48%' }}
                    component={TextField}
                    // onChange={changeHandler}
                  >
                    {features.map(feature => (
                      <MenuItem value={`${feature.id}`} key={feature.id}>
                        {feature.name}
                      </MenuItem>
                    ))}
                  </FormItem>
                  <div style={{ width: '48%' }}>
                    <FormConditional
                      dataKey="id"
                      value={value => {
                        return (
                          features.find(
                            feature => String(feature.id) === String(value)
                          )?.type === 'category'
                        )
                      }}
                    >
                      <FormItem
                        disabled={disabled}
                        dataKey="metric"
                        defaultValue="kappa"
                        select
                        label="Select Metric"
                        fullWidth
                        // style={{ width: '31%' }}
                        component={TextField}
                        // onChange={changeHandler}
                      >
                        {metrics.category.map(metric => (
                          <MenuItem value={metric.type} key={metric.type}>
                            {metric.text}
                          </MenuItem>
                        ))}
                      </FormItem>
                    </FormConditional>
                    <FormConditional
                      dataKey="id"
                      value={value => {
                        return (
                          features.find(
                            feature => String(feature.id) === String(value)
                          )?.type === 'numeric'
                        )
                      }}
                    >
                      <FormItem
                        disabled={disabled}
                        dataKey="metric"
                        defaultValue="mse"
                        select
                        label="Select Metric"
                        fullWidth
                        // style={{ width: '31%' }}
                        component={TextField}
                        // onChange={changeHandler}
                      >
                        {metrics.numeric.map(metric => (
                          <MenuItem value={metric.type} key={metric.type}>
                            {metric.text}
                          </MenuItem>
                        ))}
                      </FormItem>
                    </FormConditional>
                    <FormAction
                      style={{ width: '15%' }}
                      action="delete"
                      dataKey=""
                      component={RemoveObjectiveButton}
                    >
                      X
                    </FormAction>
                  </div>
                </div>
              </FormFor>
              <Conditional dependencies={uniqueError}>
                <span className={classes.validationMsg}>
                  Objectives must be unique.
                </span>
              </Conditional>
              <Conditional dependencies={gapsError}>
                <span className={classes.validationMsg}>
                  Non-objective features must not have gaps.
                </span>
              </Conditional>
              {/* <Conditional dependencies={varianceError}>
              <span className={classes.validationMsg}>
                Objective features must have a variance.
              </span>
            </Conditional> */}
              <Conditional dependencies={labelError}>
                <span className={classes.validationMsg}>
                  Selected feature are not valid objectives.
                </span>
              </Conditional>
              <Conditional dependencies={objectives.length < 2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addObjective}
                  disabled={step !== 2 || objectives.length === 2}
                >
                  {`Add a training objective (${objectives.length}/2)`}
                </Button>
              </Conditional>
            </div>
          </Conditional>
        </Form>
      </div>
    </div>
  )
}

TrainingWizardStepThree.propTypes = {
  training: PropTypes.object.isRequired,
}

export default TrainingWizardStepThree
