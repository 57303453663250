/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { generateColors } from 'libs/utils/color'

import Icon from 'components/Icon'

import { Tooltip } from '@material-ui/core'
import {
  BrainFeatureSelectControl,
  BrainSelectControl,
  CurveSelectControl,
  DatasetSelectControl,
  StackingCategorySelectControl,
  FeatureSelectControl,
  TrainingSelectControl,
  TrainingSplitSelectControl,
} from './Controls'

import './style.scss'

const classes = {
  wrapper: 'UserControlsBar-wrapper',
  controlsDiv: 'UserControlsBar-controlsDiv',
  infoDiv: 'UserControlsBar-infoDiv',
  tooltip: 'UserControlsBar-tooltip',
  tooltipInfo: 'UserControlsBar-tooltipInfo',
}

const controlsInfo = {
  brainFeatureSelect: {
    single: 'BRAIN OBJECTIVE: Select a brain objective for the appropriate axis.',
    multi:
      'BRAIN OBJECTIVE (multiselect): Select one or more brain objectives for the appropriate axis. Upon selecting the first brain objective, the list will be filtered by brain objective type and by selecting other brain objectives, they will be added to the selection list. If you want to select another brain objective of a different brain objective type, first deselect all of the selected brain objectives.',
    color: '',
  },
  brainSelect: {
    title:
      'BRAINS: Select one or more brains for the appropriate selected training. You can select multiple brains by selecting them from the drop-down, or by clicking the right-hand side icon in the brain select control that opens up a dialog in which you can check all the brains you wish to select, and clicking the select button.',
    color: '',
  },
  curveSelect: {
    title: 'CURVE: Select a curve.',
    color: '',
  },
  datasetSelect: {
    single: 'DATASET: Select a dataset.',
    multi:
      'DATASET (multiselect): Select one or more datasets. Upon selecting the first dataset, the list will be filtered by dataset type and by selecting other datasets, they will be added to the selection list. If you want to select another dataset of a different dataset type, first deselect all of the selected datasets. For datasets of stacked-timeseries type, the list will not be filtered, because you can only select one dataset of stacked-timeseries type (there is not multi-select for this type).',
    color: '',
  },
  stackingCategorySelect: {
    title: 'STACKING CATEGORY: Select a stacking category for the selected dataset.',
    color: '',
  },
  featureSelect: {
    single: 'FEATURE: Select a feature for the appropriate axis.',
    multi:
      'FEATURE (multiselect): Select one or more features for the appropriate axis. Upon selecting the first feature, the list will be filtered by feature type and by selecting other features, they will be added to the selection list. If you want to select another feature of a different feature type, first deselect all of the selected features.',
    color: '',
  },
  trainingSelect: {
    single: 'TRAINING: Select a training.',
    multi: 'TRAINING: Select one or more trainings.',
    color: '',
  },
  trainingSplitSelect: {
    title:
      'TRAINING SPLIT: Select one of the appropriate training splits for the selected trainings.',
    color: '',
  },
}

const UserControlsBar = props => {
  const [hover, setHover] = useState(false)
  const { controls, chart } = props

  const filteredControls = controls.filter(
    ({ control }, i) => i === controls.findIndex(e => e.control === control)
  ) // filter duplicates
  const colors = generateColors(
    '#1E90FF',
    filteredControls.length,
    0.2 + filteredControls.length / (filteredControls.length + 1) / 10
  )
  const coloredControles = filteredControls.map((control, i) => ({
    ...control,
    color: colors[i],
  }))
  const infoTexts = coloredControles.map(e => {
    const { control, multiple, color } = e
    const info = controlsInfo[control]
    if (multiple) return { text: info?.multi || info?.title || '', color }
    return { text: info?.single || info?.title || '', color }
  })

  return (
    <div className={classes.wrapper}>
      <div className={classes.controlsDiv}>
        {controls.map((e, i) => {
          const { control } = e
          let controlElement = null
          switch (control) {
            case 'brainFeatureSelect':
              controlElement = (
                <BrainFeatureSelectControl
                  key={`${control}-${i}}`}
                  ids={e && e.ids}
                  values={e && e.values}
                  onChange={e && e.callback}
                  chartType={e && e.chartType}
                  multiple={e && e.multiple}
                  type={e && e.type}
                  singleType={e && e.singleType}
                  title={e && e.title}
                  color={
                    (hover &&
                      coloredControles.find(k => k.control === control)?.color) ||
                    ''
                  }
                />
              )
              break
            case 'brainSelect':
              controlElement = (
                <BrainSelectControl
                  key={`${control}-${i}}`}
                  id={e && e.id}
                  split={e && e.split}
                  values={e && e.values}
                  onChange={e && e.callback}
                  multiple={e && e.multiple}
                  type={e && e.type}
                  singleType={e && e.singleType}
                  title={e && e.title}
                  color={
                    (hover &&
                      coloredControles.find(k => k.control === control)?.color) ||
                    ''
                  }
                />
              )
              break
            case 'curveSelect':
              controlElement = (
                <CurveSelectControl
                  key={`${control}-${i}}`}
                  ids={e && e.ids}
                  value={e && e.value}
                  onChange={e && e.callback}
                  color={
                    (hover &&
                      coloredControles.find(k => k.control === control)?.color) ||
                    ''
                  }
                />
              )
              break
            case 'datasetSelect':
              controlElement = (
                <DatasetSelectControl
                  key={`${control}-${i}}`}
                  values={e && e.values}
                  onChange={e && e.callback}
                  multiple={e && e.multiple}
                  type={e && e.type}
                  singleType={e && e.singleType}
                  title={e && e.title}
                  color={
                    (hover &&
                      coloredControles.find(k => k.control === control)?.color) ||
                    ''
                  }
                />
              )
              break
            case 'stackingCategorySelect':
              controlElement = (
                <StackingCategorySelectControl
                  key={`${control}-${i}}`}
                  ids={e && e.ids}
                  values={e && e.values}
                  onChange={e && e.callback}
                  multiple={e && e.multiple}
                  title={e && e.title}
                  chart={chart}
                  color={
                    (hover &&
                      coloredControles.find(k => k.control === control)?.color) ||
                    ''
                  }
                />
              )
              break
            case 'featureSelect':
              controlElement = (
                <FeatureSelectControl
                  key={`${control}-${i}}`}
                  ids={e && e.ids}
                  values={e && e.values}
                  onChange={e && e.callback}
                  multiple={e && e.multiple}
                  type={e && e.type}
                  singleType={e && e.singleType}
                  title={e && e.title}
                  chart={chart}
                  color={
                    (hover &&
                      coloredControles.find(k => k.control === control)?.color) ||
                    ''
                  }
                />
              )
              break
            case 'trainingSelect':
              controlElement = (
                <TrainingSelectControl
                  key={`${control}-${i}}`}
                  values={e && e.values}
                  onChange={e && e.callback}
                  multiple={e && e.multiple}
                  title={e && e.title}
                  color={
                    (hover &&
                      coloredControles.find(k => k.control === control)?.color) ||
                    ''
                  }
                />
              )
              break
            case 'trainingSplitSelect':
              controlElement = (
                <TrainingSplitSelectControl
                  key={`${control}-${i}}`}
                  values={e && e.values}
                  onChange={e && e.callback}
                  multiple={e && e.multiple}
                  includeCombined={e && e.includeCombined}
                  color={
                    (hover &&
                      coloredControles.find(k => k.control === control)?.color) ||
                    ''
                  }
                />
              )
              break
            default:
              return null
          }
          return controlElement
        })}
      </div>
      <div className={classes.infoDiv}>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={
            <>
              <div className={classes.tooltipInfo}>
                {infoTexts.map(({ text, color }) => (
                  <span style={{ backgroundColor: color }}>{text}</span>
                ))}
              </div>
            </>
          }
          placement="bottom-end"
        >
          <Icon
            type="info"
            onMouseEnter={event => {
              event.stopPropagation()
              event.preventDefault()
              setHover(true)
            }}
            onMouseLeave={event => {
              event.stopPropagation()
              event.preventDefault()
              setHover(false)
            }}
          />
        </Tooltip>
      </div>
    </div>
  )
}

UserControlsBar.propTypes = {
  controls: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        // brainFeature
        control: PropTypes.string,
        ids: PropTypes.arrayOf(PropTypes.string),
        values: PropTypes.arrayOf(PropTypes.string),
        callback: PropTypes.func,
        multiple: PropTypes.bool,
        type: PropTypes.oneOfType([
          PropTypes.arrayOf(PropTypes.oneOf(['numeric', 'category'])),
          PropTypes.oneOf(['numeric', 'category']),
        ]),
        singleType: PropTypes.bool,
        title: PropTypes.string,
      }),
      PropTypes.shape({
        // brain
        control: PropTypes.string,
        id: PropTypes.string,
        split: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
        callback: PropTypes.func,
        multiple: PropTypes.bool,
        title: PropTypes.string,
      }),
      PropTypes.shape({
        // curve
        control: PropTypes.string,
        ids: PropTypes.arrayOf(PropTypes.string),
        value: PropTypes.arrayOf(PropTypes.string),
        callback: PropTypes.func,
      }),
      PropTypes.shape({
        // dataset
        // training
        control: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
        callback: PropTypes.func,
        multiple: PropTypes.bool,
        title: PropTypes.string,
      }),
      PropTypes.shape({
        // stackingCategory
        control: PropTypes.string,
        ids: PropTypes.arrayOf(PropTypes.string),
        values: PropTypes.arrayOf(PropTypes.string),
        callback: PropTypes.func,
        multiple: PropTypes.bool,
        title: PropTypes.string,
        chart: PropTypes.bool,
      }),
      PropTypes.shape({
        // feature
        control: PropTypes.string,
        ids: PropTypes.arrayOf(PropTypes.string),
        values: PropTypes.arrayOf(
          PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
        ),
        callback: PropTypes.func,
        multiple: PropTypes.bool,
        type: PropTypes.string,
        singleType: PropTypes.bool,
        title: PropTypes.string,
        chart: PropTypes.bool,
      }),
      PropTypes.shape({
        // trainingSplit
        control: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
        callback: PropTypes.func,
        multiple: PropTypes.bool,
        includeCombined: PropTypes.bool,
      }),
    ])
  ),
  chart: PropTypes.bool,
}

export default UserControlsBar
