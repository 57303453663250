/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react'
import { useStore } from 'core'
import { v4 as uuid } from 'uuid'

import Conditional from 'components/Conditional'
import { Button } from '@material-ui/core'
import UploadProgress from './UploadProgress'

import './style.scss'

const classes = {
  wrapper: 'CSVUploader-wrapper',
  uploadCardsDiv: 'CSVUploader-uploadCardsDiv',
  footer: 'CSVUploader-footer',
  selectAndDrop: 'CSVUploader-selectAndDrop',
  dragSpan: 'CSVUploader-dragSpan',
  selectSpan: 'CSVUploader-selectSpan',
}

const CSVUploader = props => {
  const inputRef = useRef()
  const dragAndDropRef = useRef()
  const {
    uploads,
    addUploadFile,
    configureUploadEntity,
    uploadFile,
    removeUploadEntity,
  } = useStore(
    'selectUploads',
    'addUploadFile',
    'configureUploadEntity',
    'uploadFile',
    'removeUploadEntity'
  )

  const keys = Object.keys(uploads)

  const forClear = keys
    .map(key => ({ ...uploads[key], key }))
    .filter(({ status }) => status === 'completed')
  const forUpload = keys
    .map(key => ({ ...uploads[key], key }))
    .filter(({ status }) => status === 'configurable')

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.uploadCardsDiv}
        style={{ width: keys?.length > 0 ? '100%' : '0' }}
      >
        <UploadProgress />
        <Conditional dependencies={keys.length}>
          <div className={classes.footer}>
            <Conditional dependencies={forClear.length}>
              <Button
                color="primary"
                variant="contained"
                onClick={event => {
                  event.stopPropagation()
                  forClear.forEach(({ key }) => removeUploadEntity({ id: key }))
                }}
              >
                Clear All
              </Button>
            </Conditional>
            <Conditional dependencies={forUpload.length}>
              <Button
                color="primary"
                variant="contained"
                onClick={event => {
                  event.stopPropagation()
                  forUpload.forEach(({ key }) => uploadFile(key))
                }}
              >
                Upload All
              </Button>
            </Conditional>
          </div>
        </Conditional>
      </div>
      <div
        ref={dragAndDropRef}
        className={classes.selectAndDrop}
        onClick={() => {
          if (inputRef.current) inputRef.current.click()
        }}
        onDrop={event => {
          event.preventDefault()
          if (dragAndDropRef.current) {
            const regex = / dragging/gi
            const className = dragAndDropRef.current.className.replace(regex, '')
            dragAndDropRef.current.className = className
          }
          const { files } = event.dataTransfer
          for (let i = 0; i < files.length; i++) {
            const file = files.item(i)
            const id = uuid()
            addUploadFile({ id, file })
            if (files.length === 1) configureUploadEntity(id)
          }
        }}
        onDrag={event => {
          event.preventDefault()
        }}
        onDragStart={event => {
          event.preventDefault()
        }}
        onDragEnd={event => {
          event.preventDefault()
        }}
        onDragOver={event => {
          event.preventDefault()
          if (dragAndDropRef.current)
            dragAndDropRef.current.className = `${classes.selectAndDrop} dragging`
        }}
        onDragEnter={event => {
          event.preventDefault()
          if (dragAndDropRef.current)
            dragAndDropRef.current.className = `${classes.selectAndDrop} dragging`
        }}
        onDragLeave={event => {
          if (dragAndDropRef.current) {
            const regex = / dragging/gi
            const className = dragAndDropRef.current.className.replace(regex, '')
            dragAndDropRef.current.className = className
          }
          event.preventDefault()
        }}
      >
        <input
          ref={inputRef}
          type="file"
          name="file"
          files={[]}
          accept=".csv"
          multiple
          style={{ display: 'none' }}
          onChange={event => {
            const { target } = event
            const { files } = target
            if (files.length) {
              for (let i = 0; i < files.length; i++) {
                const file = files.item(i)
                const id = uuid()
                addUploadFile({ id, file })
                if (files.length === 1) configureUploadEntity(id)
              }
              target.value = ''
            }
          }}
        />
        <span className={classes.dragSpan}>Drag files to upload</span>
        <span className={classes.selectSpan}>Click to select files</span>
      </div>
    </div>
  )
}

CSVUploader.propTypes = {}

export default CSVUploader
