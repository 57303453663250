/* eslint-disable no-console */
import React from 'react'
import { v4 as uuid } from 'uuid'
import { useStore } from 'core'

import { Pipeline } from 'components/Pipeline'
// import { SplitLayout } from 'components/Layout'

import './style.scss'

const classes = {
  wrapper: 'Dev-wrapper',
  test: 'Dev-test',
}

const data = []
const numOfSlices = Math.round(Math.random() * 4 + 5)
for (let i = 0; i < numOfSlices; i++) {
  data.push({
    name: 'peramikazika',
    value: i.toString(),
    else: i + Math.round(Math.random() * 5),
    pera: i + Math.round(Math.random() * 10),
    mika: i + Math.round(Math.random() * 15),
    zika: i + Math.round(Math.random() * 20),
    franjo: i + Math.round(Math.random() * 25),
    joska: {
      pera: i + Math.round(Math.random() * 30),
      mika: i + Math.round(Math.random() * 30),
      zika: i + Math.round(Math.random() * 30),
      franjo: {
        franjo: i + Math.round(Math.random() * 30),
        joska: i + Math.round(Math.random() * 30),
      },
    },
    id: uuid(),
  })
}

const Dev = props => {
  const color = '#3377ff'

  const { setPipeline, pipeline } = useStore('setPipeline', 'selectPipeline')

  // console.log(pipeline.meta)

  return (
    <div className={classes.wrapper}>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          > */}
          <Pipeline />
          {/* </div> */}
        </div>
        <div>
          <button
            type="button"
            onClick={() => {
              setPipeline({
                spec: [{ type: 'dataset', datasetArrayIndex: 0 }],
                datasetArray: [],
              })
            }}
          >
            SETTER
          </button>
        </div>
      </div>
    </div>
  )
}

export default Dev
