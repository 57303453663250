/* eslint-disable max-lines */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { useInteractiveClick } from 'libs/interactive'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core'
import PopUpMultiSelect from 'components/PopUpMultiSelect'

import CalculationBoxFinancialFunctions from './CalculationBoxFinancialFunctions'
import { CALCULATION_FUNCTIONS, FEATURE_TYPES } from './stringConstants'
import { financialFunctionsArrayGenerator } from './FinancialFunctions'

const CALCULATION_FUNCTIONS_FEATURE_TYPES = {
  [CALCULATION_FUNCTIONS.CLONE]: [
    FEATURE_TYPES.NUMERIC,
    FEATURE_TYPES.CATEGORY,
    FEATURE_TYPES.TEXT,
    FEATURE_TYPES.TIME,
  ],
  [CALCULATION_FUNCTIONS.FORMULA]: [
    FEATURE_TYPES.NUMERIC,
    FEATURE_TYPES.CATEGORY,
    FEATURE_TYPES.TEXT,
    FEATURE_TYPES.TIME,
  ],
  [CALCULATION_FUNCTIONS.ZIGZAG]: [FEATURE_TYPES.NUMERIC],
  [CALCULATION_FUNCTIONS.DECOMPOSITION]: [FEATURE_TYPES.NUMERIC],
  [CALCULATION_FUNCTIONS.WINDOW]: [FEATURE_TYPES.NUMERIC],
  [CALCULATION_FUNCTIONS.FINANCIAL]: [FEATURE_TYPES.NUMERIC],
}

const classes = {
  wrapper: 'CalculationBox-config-content-wrapper',
  ruleWrapper: 'CalculationBox-config-content-CalculationRule-wrapper',
  ruleDescription: 'CalculationBox-config-content-CalculationRule-description',
  ruleApplication: 'CalculationBox-config-content-CalculationRule-application',
  ruleRemoveButton: 'CalculationBox-config-content-CalculationRule-removeButton',
  addRuleButtonsDiv: 'ConfigBox-addRuleButtonsDiv',
  addRuleButtonsSection: 'ConfigBox-addRuleButtonsSection',
  addRuleButton: 'ConfigBox-addRuleButton',
}

const [
  timeFunctions,
  openFunctions,
  lowHighFunctions,
  closeFunctions,
  noSuffixFunctions,
] = financialFunctionsArrayGenerator()

const CalculationRule = ({
  id,
  method,
  name,
  prefix,
  suffix,
  options,
  features = [],
  meta: tempMeta = [],
  callback,
  nameError,
}) => {
  const rule = { id, method, feature_id: features }
  if (name !== undefined) rule.name = name
  if (prefix !== undefined || suffix !== undefined) {
    rule.prefix = prefix
    rule.suffix = suffix
  }
  const meta = tempMeta.filter(
    ({ type }) => CALCULATION_FUNCTIONS_FEATURE_TYPES[method].indexOf(type) !== -1
  )
  if (options) {
    const tempOptions = { ...options }
    // if (!options?.open) tempOptions.open = meta[0].id
    // if (!options?.low) tempOptions.low = meta[0].id
    // if (!options?.high) tempOptions.high = meta[0].id
    // if (!options?.close) tempOptions.close = meta[0].id
    rule.options = tempOptions
  }

  let string = null
  if (
    method === CALCULATION_FUNCTIONS.ZIGZAG ||
    method === CALCULATION_FUNCTIONS.FINANCIAL
  )
    string = 'target'
  if (method === CALCULATION_FUNCTIONS.DECOMPOSITION) string = 'output'
  if (method === CALCULATION_FUNCTIONS.WINDOW) string = 'using'
  const selectOptions = []
  if (method === CALCULATION_FUNCTIONS.ZIGZAG) selectOptions.push('signal', 'result')
  if (method === CALCULATION_FUNCTIONS.DECOMPOSITION)
    selectOptions.push('trend', 'seasonal', 'residual')
  if (method === CALCULATION_FUNCTIONS.WINDOW)
    selectOptions.push(
      'min',
      'max',
      'mean',
      'median',
      'sum',
      'slope',
      'std',
      'percentile'
    )

  if (method === CALCULATION_FUNCTIONS.FINANCIAL) {
    if (['stoch'].indexOf(options?.function) !== -1)
      selectOptions.push('slowk', 'slowd')
    if (['stochf', 'stochrsi'].indexOf(options?.function) !== -1)
      selectOptions.push('fastk', 'fastd')
    if (['bbands'].indexOf(options?.function) !== -1)
      selectOptions.push('upperband', 'middleband', 'lowerband')
    if (['mama'].indexOf(options?.function) !== -1)
      selectOptions.push('mama', 'fama')
    if (['ht_phasor'].indexOf(options?.function) !== -1)
      selectOptions.push('inphase', 'quadrature')
    if (['ht_sine'].indexOf(options?.function) !== -1)
      selectOptions.push('sine', 'leadsine')
  }
  let number = null
  if (method === CALCULATION_FUNCTIONS.ZIGZAG) number = 'percentile'
  if (method === CALCULATION_FUNCTIONS.DECOMPOSITION) number = 'frequency'
  if (method === CALCULATION_FUNCTIONS.WINDOW) number = 'samples'

  return (
    <div className={classes.ruleWrapper}>
      <div className={classes.ruleDescription}>
        <Conditional
          dependencies={
            method !== CALCULATION_FUNCTIONS.FORMULA &&
            noSuffixFunctions.indexOf(options?.function) === -1
          }
        >
          <span>{method === CALCULATION_FUNCTIONS.CLONE ? 'Clone:' : 'Name:'}</span>
          {/* <TextField
            className="prefix"
            value={prefix}
            onChange={event => {
              const tempRule = { ...rule, prefix: event.target.value }
              if (event.target.value === '' && suffix === '') {
                tempRule.suffix = '-'
              }
              callback(id, tempRule)
            }}
            label="prefix"
            margin="dense"
            fullWidth
          /> */}
          <span>[feature name]</span>
          <TextField
            className="suffix"
            error={nameError}
            value={suffix}
            onChange={event => {
              const tempRule = { ...rule, suffix: event.target.value }
              // if (event.target.value === '' && prefix === '') {
              //   tempRule.suffix = '-'
              // }
              callback(id, tempRule)
            }}
            onFocus={event => {
              event.target.select()
            }}
            label="suffix"
            margin="dense"
            fullWidth
          />
        </Conditional>
        <Conditional
          dependencies={
            method === CALCULATION_FUNCTIONS.FORMULA ||
            noSuffixFunctions.indexOf(options?.function) !== -1
          }
        >
          <TextField
            className="name"
            error={nameError}
            value={name}
            onChange={event => {
              callback(id, { ...rule, name: event.target.value || '-' })
            }}
            onFocus={event => {
              event.target.select()
            }}
            label="New feature name"
            margin="dense"
            fullWidth
          />
        </Conditional>
        <Conditional dependencies={method === CALCULATION_FUNCTIONS.FORMULA}>
          <Tooltip
            title="Use :#:, where # is the index of the feature to use, with arithmetic operators (+, -, *, /)"
            placement="right"
          >
            <TextField
              className="formula"
              value={options?.formula}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, formula: event.target.value },
                })
              }}
              label="formula"
              margin="dense"
              fullWidth
            />
          </Tooltip>
        </Conditional>
        <Conditional
          dependencies={
            method === CALCULATION_FUNCTIONS.ZIGZAG ||
            method === CALCULATION_FUNCTIONS.DECOMPOSITION ||
            method === CALCULATION_FUNCTIONS.WINDOW
          }
        >
          <TextField
            className="string"
            select
            value={options && options[string]}
            onChange={event => {
              const tempOptions = { ...options, [string]: event.target.value }
              if (string === 'using' && event.target.value === 'percentile')
                tempOptions.percentile = 1
              callback(id, { ...rule, options: tempOptions })
            }}
            label={string}
            margin="dense"
            fullWidth
          >
            {selectOptions.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <Conditional dependencies={options && options[string] === 'percentile'}>
            <TextField
              className="number"
              value={options?.percentile}
              type="number"
              InputProps={{
                inputProps: { min: 1, step: 0.1 },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, percentile: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="percent"
              margin="dense"
              fullWidth
            />
          </Conditional>
          <TextField
            className="number"
            value={options && options[number]}
            type="number"
            InputProps={{
              inputProps: {
                min: 1 + Number(method === CALCULATION_FUNCTIONS.WINDOW),
              },
            }}
            onChange={event => {
              callback(id, {
                ...rule,
                options: { ...options, [number]: Number(event.target.value) },
              })
            }}
            onFocus={event => {
              event.target.select()
            }}
            label={number}
            margin="dense"
            fullWidth
          />
        </Conditional>
        <Conditional dependencies={method === CALCULATION_FUNCTIONS.WINDOW}>
          <TextField
            className="string"
            select
            value={(options?.center && 'centered') || 'trailing'}
            onChange={event => {
              const tempOptions = {
                ...options,
                center: event.target.value === 'centered',
              }
              callback(id, { ...rule, options: tempOptions })
            }}
            margin="dense"
            fullWidth
          >
            <MenuItem value="centered">centered</MenuItem>
            <MenuItem value="trailing">trailing</MenuItem>
          </TextField>
        </Conditional>
        <Conditional dependencies={method === CALCULATION_FUNCTIONS.FINANCIAL}>
          <Conditional
            dependencies={
              [
                'ht_phasor',
                'ht_sine',
                'bbands',
                'mama',
                'stoch',
                'stochf',
                'stochrsi',
              ].indexOf(options?.function) !== -1
            }
          >
            <TextField
              className="string"
              select
              value={options && options[string]}
              onChange={event =>
                callback(id, {
                  ...rule,
                  options: { ...options, [string]: event.target.value },
                })
              }
              label={string}
              margin="dense"
              fullWidth
            >
              {selectOptions.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Conditional>
          <Conditional dependencies={['stoch'].indexOf(options?.function) !== -1}>
            <TextField
              className="number"
              value={options?.slowk_period}
              type="number"
              InputProps={{
                inputProps: { min: 1, step: 1 },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, slowk_period: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="slowk"
              margin="dense"
              fullWidth
            />
          </Conditional>
          <Conditional dependencies={['stoch'].indexOf(options?.function) !== -1}>
            <TextField
              className="number"
              value={options?.slowd_period}
              type="number"
              InputProps={{
                inputProps: { min: 1, step: 1 },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, slowd_period: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="slowd"
              margin="dense"
              fullWidth
            />
          </Conditional>
          <Conditional
            dependencies={
              ['stoch', 'stochf', 'stochrsi'].indexOf(options?.function) !== -1
            }
          >
            <TextField
              className="number"
              value={options?.fastk_period}
              type="number"
              InputProps={{
                inputProps: { min: 1, step: 1 },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, fastk_period: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="fastk"
              margin="dense"
              fullWidth
            />
          </Conditional>
          <Conditional
            dependencies={['stochf', 'stochrsi'].indexOf(options?.function) !== -1}
          >
            <TextField
              className="number"
              value={options?.fastd_period}
              type="number"
              InputProps={{
                inputProps: { min: 1, step: 1 },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, fastd_period: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="fastd"
              margin="dense"
              fullWidth
            />
          </Conditional>
          <Conditional dependencies={['sar'].indexOf(options?.function) !== -1}>
            <TextField
              className="number-wide"
              value={options?.acceleration}
              type="number"
              InputProps={{
                inputProps: { min: 0, step: 0.01 },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, acceleration: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="acceleration"
              margin="dense"
              fullWidth
            />
            <TextField
              className="number-wide"
              value={options?.maximum}
              type="number"
              InputProps={{
                inputProps: { min: 0, step: 0.1 },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, maximum: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="maximum"
              margin="dense"
              fullWidth
            />
          </Conditional>
          <Conditional dependencies={['t3'].indexOf(options?.function) !== -1}>
            <TextField
              className="number"
              value={options?.vfactor}
              type="number"
              InputProps={{
                inputProps: { min: 0, step: 0.1 },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, vfactor: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="vfactor"
              margin="dense"
              fullWidth
            />
          </Conditional>
          <Conditional
            dependencies={
              ['macd', 'macdext', 'adosc'].indexOf(options?.function) !== -1
            }
          >
            <TextField
              className="slow"
              value={options?.slow}
              type="number"
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, slow: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="slow"
              margin="dense"
              fullWidth
            />
            <TextField
              className="fast"
              value={options?.fast}
              type="number"
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, fast: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="fast"
              margin="dense"
              fullWidth
            />
          </Conditional>
          <Conditional
            dependencies={['macd', 'macdext'].indexOf(options?.function) !== -1}
          >
            <TextField
              className="signal"
              value={options?.signal}
              type="number"
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, signal: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="signal"
              margin="dense"
              fullWidth
            />
          </Conditional>
          <Conditional dependencies={['macdext'].indexOf(options?.function) !== -1}>
            <TextField
              className="slow"
              value={options?.slowmatype}
              type="number"
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, slowmatype: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="slowma"
              margin="dense"
              fullWidth
            />
            <TextField
              className="slow"
              value={options?.fastmatype}
              type="number"
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, fastmatype: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="fastma"
              margin="dense"
              fullWidth
            />
            <TextField
              className="slow"
              value={options?.signalmatype}
              type="number"
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, signalmatype: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="signalma"
              margin="dense"
              fullWidth
            />
          </Conditional>
          <Conditional
            dependencies={['normalized_slope'].indexOf(options?.function) !== -1}
          >
            <TextField
              className="number"
              value={options?.slope_samples}
              type="number"
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, slope_samples: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="slope"
              margin="dense"
              fullWidth
            />
            <FormControlLabel
              className="checkbox"
              label="Centered"
              checked={options?.slope_center}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, slope_center: event.target.checked },
                })
              }}
              margin="dense"
              control={<Checkbox color="default" />}
            />
            <TextField
              className="number"
              value={options?.stddev_samples}
              type="number"
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: {
                    ...options,
                    stddev_samples: Number(event.target.value),
                  },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="stddev"
              margin="dense"
              fullWidth
            />
            <FormControlLabel
              className="checkbox"
              label="Centered"
              checked={options?.stddev_center}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, stddev_center: event.target.checked },
                })
              }}
              margin="dense"
              control={<Checkbox color="default" />}
            />
          </Conditional>
          <Conditional
            dependencies={timeFunctions.indexOf(options?.function) !== -1}
          >
            <TextField
              className="time"
              value={options?.time}
              type="number"
              InputProps={{
                inputProps: {
                  min: 2,
                },
              }}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, time: Number(event.target.value) },
                })
              }}
              onFocus={event => {
                event.target.select()
              }}
              label="time"
              margin="dense"
              fullWidth
            />
          </Conditional>
          <Conditional
            dependencies={
              ['ad', 'adosc', 'obv', 'mfi'].indexOf(options?.function) !== -1
            }
          >
            <TextField
              className="volume"
              error={options && !options.volume}
              select
              value={options?.volume || ''}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, volume: event.target.value },
                })
              }}
              label="volume"
              margin="dense"
              fullWidth
            >
              {meta.map(feature => (
                <MenuItem key={feature.id} value={feature.id}>
                  {feature.name}
                </MenuItem>
              ))}
            </TextField>
          </Conditional>
          <Conditional
            dependencies={openFunctions.indexOf(options?.function) !== -1}
          >
            <TextField
              className="open"
              error={options && !options.open}
              select
              value={options?.open || ''}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, open: event.target.value },
                })
              }}
              label="open"
              margin="dense"
              fullWidth
            >
              {meta.map(feature => (
                <MenuItem key={feature.id} value={feature.id}>
                  {feature.name}
                </MenuItem>
              ))}
            </TextField>
          </Conditional>
          <Conditional
            dependencies={lowHighFunctions.indexOf(options?.function) !== -1}
          >
            <TextField
              className="low"
              error={options && !options.low}
              select
              value={options?.low || ''}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, low: event.target.value },
                })
              }}
              label="low"
              margin="dense"
              fullWidth
            >
              {meta.map(feature => (
                <MenuItem key={feature.id} value={feature.id}>
                  {feature.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className="high"
              error={options && !options.high}
              select
              value={options?.high || ''}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, high: event.target.value },
                })
              }}
              label="high"
              margin="dense"
              fullWidth
            >
              {meta.map(feature => (
                <MenuItem key={feature.id} value={feature.id}>
                  {feature.name}
                </MenuItem>
              ))}
            </TextField>
          </Conditional>
          <Conditional
            dependencies={closeFunctions.indexOf(options?.function) !== -1}
          >
            <TextField
              className="close"
              error={options && !options.close}
              select
              value={options?.close || ''}
              onChange={event => {
                callback(id, {
                  ...rule,
                  options: { ...options, close: event.target.value },
                })
              }}
              label="close"
              margin="dense"
              fullWidth
            >
              {meta.map(feature => (
                <MenuItem key={feature.id} value={feature.id}>
                  {feature.name}
                </MenuItem>
              ))}
            </TextField>
          </Conditional>
        </Conditional>
      </div>
      <Conditional
        dependencies={
          method !== CALCULATION_FUNCTIONS.FORMULA &&
          noSuffixFunctions.indexOf(options?.function) === -1
        }
      >
        <div className={classes.ruleApplication}>
          <PopUpMultiSelect
            error={!features.length}
            label={method === CALCULATION_FUNCTIONS.WINDOW ? 'Lookup' : 'Features'}
            margin="dense"
            fullWidth
            value={JSON.stringify(features)}
            onChange={e => {
              callback(id, {
                ...rule,
                feature_id: JSON.parse(e).sort((a, b) => a - b),
              })
            }}
          >
            {meta.map(feature => (
              <MenuItem key={feature.id} value={feature.id}>
                {feature.name}
              </MenuItem>
            ))}
          </PopUpMultiSelect>
        </div>
      </Conditional>
      <button
        className={classes.ruleRemoveButton}
        type="button"
        onClick={event => {
          event.stopPropagation()
          callback(id, null)
        }}
        onKeyDown={event => {
          event.stopPropagation()
          if (event.keyCode === 13) {
            callback(id, null)
          }
        }}
      >
        <Icon type="trash" />
      </button>
    </div>
  )
}

const CalculationConfig = ({ config, meta, dataType, callback }) => {
  const [expanded, setExpanded] = useState(false)
  const contentRef = useRef()
  const ruleCallback = (id, rule) => {
    if (rule) {
      const tempConfig = { ...config }
      tempConfig.rules = [...config.rules].map(e =>
        e.id === id ? { ...rule } : { ...e }
      )
      callback({ ...tempConfig })
    } else {
      const tempConfig = { ...config }
      tempConfig.rules = [...config.rules].filter(e => e.id !== id)
      callback({ ...tempConfig })
    }
  }
  const addRule = useCallback(
    (event, method, options) => {
      const tempRule = {
        id: uuid(),
        method,
      }
      const tempConfig = {
        ...config,
        rules: [...config.rules, tempRule],
      }
      if (
        method !== CALCULATION_FUNCTIONS.FORMULA &&
        noSuffixFunctions.indexOf(options?.function) === -1
      )
        tempRule.feature_id = []
      if (
        method === CALCULATION_FUNCTIONS.FORMULA ||
        noSuffixFunctions.indexOf(options?.function) !== -1
      )
        tempRule.name = '-'
      else {
        tempRule.prefix = ''
        tempRule.suffix = '-'
      }
      if (options !== undefined) tempRule.options = options
      callback(tempConfig)
    },
    [callback, config]
  )
  const { onClick, onKeyDown } = useInteractiveClick(addRule)
  const ruleLength = config?.rules?.length || 0
  useEffect(() => {
    if (contentRef?.current)
      contentRef.current.scrollTop = contentRef.current.scrollHeight
  }, [ruleLength])
  return (
    <>
      <div className={classes.wrapper} ref={contentRef}>
        {config.rules.map(
          ({
            id,
            method,
            name,
            prefix,
            suffix,
            options,
            feature_id: featureIds,
          }) => (
            <CalculationRule
              key={id}
              id={id}
              method={method}
              name={name}
              prefix={prefix}
              suffix={suffix}
              options={options}
              features={featureIds}
              meta={meta}
              callback={ruleCallback}
              nameError={(() => {
                const metaNames = meta.map(({ name: n }) => n)
                const ruleNames = config.rules
                  .map(({ name: n, suffix: s, feature_id: fids = [] }) => {
                    if (n) return n
                    if (s)
                      return fids.map(i => {
                        const metaObj = meta.find(({ id: tempId }) => tempId === i)
                        if (metaObj) return metaObj.name + s
                        return null
                      })
                    return null
                  })
                  .reduce((accumulated, current) => {
                    if (Array.isArray(current)) {
                      const newNames = [...accumulated]
                      current.forEach(tName => {
                        if (tName) newNames.push(tName)
                      })
                      return newNames
                    }
                    const newNames = [...accumulated]
                    if (current) newNames.push(current)
                    return newNames
                  }, [])
                const allNames = metaNames.concat(ruleNames)
                return (
                  (suffix &&
                    featureIds.reduce((acc, cur) => {
                      const { name: tempName = null } =
                        meta.find(({ id: tempId }) => tempId === cur) || {}
                      const suffixedName = tempName + suffix
                      return (
                        acc ||
                        allNames.filter(str => str !== suffixedName).length + 1 <
                          allNames.length
                      )
                    }, false)) ||
                  (name &&
                    allNames.filter(str => str !== name).length + 1 <
                      allNames.length)
                )
              })()}
            />
          )
        )}
      </div>
      <div
        className={`${classes.addRuleButtonsDiv}${
          dataType !== 'basedata' && expanded ? ' extended' : ''
        }`}
      >
        <div className={classes.addRuleButtonsSection}>
          <button
            className={classes.addRuleButton}
            type="button"
            onClick={event => {
              onClick(event, CALCULATION_FUNCTIONS.FORMULA, { formula: '' })
            }}
            onKeyDown={event => {
              onKeyDown(event, CALCULATION_FUNCTIONS.FORMULA, { formula: '' })
            }}
          >
            Formula
          </button>
          <button
            className={classes.addRuleButton}
            type="button"
            onClick={event => onClick(event, CALCULATION_FUNCTIONS.CLONE)}
            onKeyDown={event => {
              onKeyDown(event, CALCULATION_FUNCTIONS.CLONE)
            }}
          >
            Clone
          </button>
          <Conditional dependencies={dataType !== 'basedata'}>
            <button
              className={classes.addRuleButton}
              type="button"
              onClick={event => {
                onClick(event, CALCULATION_FUNCTIONS.ZIGZAG, {
                  percentile: 1,
                  target: 'signal',
                })
              }}
              onKeyDown={event => {
                onKeyDown(event, CALCULATION_FUNCTIONS.ZIGZAG, {
                  percentile: 1,
                  target: 'signal',
                })
              }}
            >
              Zigzag
            </button>
            <button
              className={classes.addRuleButton}
              type="button"
              onClick={event => {
                onClick(event, CALCULATION_FUNCTIONS.DECOMPOSITION, {
                  frequency: 1,
                  output: 'seasonal',
                })
              }}
              onKeyDown={event => {
                onKeyDown(event, CALCULATION_FUNCTIONS.DECOMPOSITION, {
                  frequency: 1,
                  output: 'seasonal',
                })
              }}
            >
              Decomposition
            </button>
            <button
              className={classes.addRuleButton}
              type="button"
              onClick={event => {
                onClick(event, CALCULATION_FUNCTIONS.WINDOW, {
                  samples: 2,
                  using: 'std',
                  center: false,
                })
              }}
              onKeyDown={event => {
                onKeyDown(event, CALCULATION_FUNCTIONS.WINDOW, {
                  samples: 2,
                  using: 'std',
                  center: false,
                })
              }}
            >
              Window
            </button>
            <button
              className={classes.addRuleButton}
              type="button"
              onClick={event => {
                event.preventDefault()
                setExpanded(!expanded)
              }}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  event.preventDefault()
                  event.stopPropagation()
                  setExpanded(!expanded)
                }
              }}
            >
              Financial Functions
            </button>
          </Conditional>
        </div>
        <Conditional dependencies={dataType !== 'basedata' && expanded}>
          <CalculationBoxFinancialFunctions callback={addRule} />
        </Conditional>
      </div>
    </>
  )
}

CalculationConfig.propTypes = {
  config: PropTypes.object,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  dataType: PropTypes.oneOf(['basedata', 'timeseries', 'stacked-timeseries']),
  callback: PropTypes.func,
}

CalculationRule.propTypes = {
  id: PropTypes.string,
  method: PropTypes.string,
  name: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  options: PropTypes.shape({
    type: PropTypes.string,
    formula: PropTypes.string,
    percentile: PropTypes.number,
    target: PropTypes.string,
    frequency: PropTypes.number,
    output: PropTypes.string,
    samples: PropTypes.number,
    using: PropTypes.string,
    center: PropTypes.bool,
    function: PropTypes.string,
    slowk_period: PropTypes.number,
    slowd_period: PropTypes.number,
    fastk_period: PropTypes.number,
    fastd_period: PropTypes.number,
    vfactor: PropTypes.number,
    acceleration: PropTypes.number,
    maximum: PropTypes.number,
    slow: PropTypes.number,
    fast: PropTypes.number,
    signal: PropTypes.number,
    slowmatype: PropTypes.number,
    fastmatype: PropTypes.number,
    signalmatype: PropTypes.number,
    slope_center: PropTypes.bool,
    slope_samples: PropTypes.number,
    stddev_center: PropTypes.bool,
    stddev_samples: PropTypes.number,
    time: PropTypes.number,
    volume: PropTypes.number,
    open: PropTypes.number,
    low: PropTypes.number,
    high: PropTypes.number,
    close: PropTypes.number,
  }),
  features: PropTypes.arrayOf(PropTypes.number),
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  callback: PropTypes.func,
  nameError: PropTypes.bool,
}

export default CalculationConfig

// const calculationBoxConfig = {
//   id: uuid(),
//   method: 'string', // [formula-calculation, feature-clone, zigzag, decomposition, window-calculation, financial-calculation]
//   name: 'string', // for creating a single new feature (formula-calculation / financial-calculation)
//   prefix: 'string', // for creating multiple new features
//   suffix: 'string', // for creating multiple new features
//   feature_id: [0, 1, 2], // array of feature indices to which this rule applies (lookup, clone, ...) of type number
//   options: {
//     formula: 'string', // for the formula-calculation
//     target: 'string', // [result, signal] for zigzag
//     percentile: 1, // type number for zigzag and window-percentile
//     output: 'string', // [trend, seasonal, residual] for decomposition
//     frequency: 1, // type number for decomposition
//     using: 'string', // [min, max, mean, median, sum, slope, std, percentile] for window-calculation
//     samples: 1, // type number for window-calculation
//     function: 'string', //
//     slow: 0, //
//     fast: 0, //
//     signal: 0, //
//     time: 0, //
//     low: 0, // feature index of type number
//     close: 0, // feature index of type number
//     high: 0, // feature index of type number
//     ...
//   },
// }
