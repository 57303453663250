import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import Spinner from 'components/Spinner'
import { MenuItem, TextField } from '@material-ui/core'
import { LocalActivity } from '@material-ui/icons'

const classes = {
  wrapper: 'UserControl-wrapper',
  title: 'UserControl-title',
}

const TrainingSplitSelectControl = props => {
  const { trainings } = useStore('selectTrainings')
  const { ids, value = '', onChange, color } = props

  const selectedTrainings =
    trainings.filter(training => ids.indexOf(training.id) !== -1) || []
  const loading = selectedTrainings
    .filter(training => training.isLoading)
    .map(({ id }) => id)

  const options = [{ label: 'Learning Curve', id: 'learningCurve' }]

  if (
    trainings.some(
      training =>
        !!training.spec?.objectives?.some(
          objective => objective.type === 'binary_classification'
        )
    )
  )
    options.push({ label: 'ROC Curve', id: 'rocCurve' })

  return (
    <div
      className={classes.wrapper}
      style={{
        filter: color ? `drop-shadow(0px 0px 5px ${color})` : '',
        boxShadow: color ? `0px 0px 3px 1px ${color}` : '',
      }}
    >
      <div className={classes.title}>
        <span>Curve</span>
        <Conditional dependencies={loading.length}>
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
        </Conditional>
      </div>
      <TextField
        label="Split"
        select
        value={value}
        onChange={event => onChange(event.target.value)}
      >
        {options.map(({ label, id }) => {
          return (
            <MenuItem key={id} value={id}>
              {label}
            </MenuItem>
          )
        })}
      </TextField>
    </div>
  )
}

TrainingSplitSelectControl.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  color: PropTypes.string,
}

export default TrainingSplitSelectControl
