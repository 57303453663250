import React, { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import { useInteractiveClick } from 'libs/interactive'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import { LineChart, ScatterChart } from 'components/Charts'
import { ResourceTable, Provider as RenderProvider } from 'components/ResourceTable'

import 'style.scss'

const PREF_KEY = 'DatasourceInspector-expanded'
const RESOURCE_TABLE_KEY = 'DatasourceInspector-table'

const classes = {
  wrapper: 'DatasourceInspector-wrapper',
  header: 'DatasourceInspector-header',
  download: 'download-link',
  body: 'DatasourceInspector-body',
  content: 'DatasourceInspector-content',
  tableWrapper: 'DatasourceInspector-brains-table-wrapper',
  chartWrapper: 'DatasourceInspector-chart-wrapper',
  trackBorder: 'DatasourceInspector-track-border',
  track: 'DatasourceInspector-track',
  tab: 'DatasourceInspector-tab',
}

const DatasourceInspector = ({ title, resource, resourceType }) => {
  const [tab, setTab] = useState('table')
  const [expanded, setExpanded] = useState(null)
  const contentRef = useRef()
  const trackRef = useRef()
  const ctx = useMemo(() => ({ resourceType }), [resourceType])

  const { resourceDataResources: resources, registerResourceRequest } = useStore(
    'selectResourceDataResources',
    'registerResourceRequest'
  )

  const loading = !!resources.find(e => e.id === resource)?.isLoading
  const prepared = !!resources.find(e => e.id === resource)?.prepared
  const data = prepared

  const pref = window.sessionStorage[PREF_KEY]
    ? JSON.parse(window.sessionStorage[PREF_KEY])
    : null
  const autoExpand =
    pref !== null ? pref : expanded || (expanded === null && data ? true : expanded)

  const { onClick, onKeyDown } = useInteractiveClick(() => {
    if (data) {
      setExpanded(!(expanded || expanded === null))
      window.sessionStorage[PREF_KEY] = !autoExpand
    }
  })

  useEffect(() => {
    const contentNode = contentRef.current
    const timeout = autoExpand ? 0 : 300
    const id = setTimeout(() => {
      contentNode && (contentNode.style.visibility = autoExpand ? '' : 'hidden')
    }, timeout)
    return () => {
      clearTimeout(id)
    }
  }, [autoExpand])

  useEffect(() => {
    if (loading) {
      let el = document.activeElement
      while (el) {
        el = el.parentElement
        if (el === contentRef.current) break
      }
      if (!el) {
        trackRef.current && trackRef.current.focus()
      }
    }
  }, [loading])

  useEffect(() => {
    if (tab === 'chart')
      registerResourceRequest(`chart-${resource}`, resource, 'chart')
  }, [tab, registerResourceRequest, resource])

  return (
    <div className={classes.wrapper}>
      <Conditional dependencies={resource}>
        <div className={classes.body} data-active={autoExpand}>
          <div
            className={classes.track}
            onClick={onClick}
            role="button"
            tabIndex={0}
            onKeyDown={onKeyDown}
            data-expanded={autoExpand}
            data-loading={loading}
            ref={trackRef}
          >
            <Icon data-icon="collapse" type="arrow-left" />
            <Icon data-icon="loading" type="spinner" />
          </div>
          <div
            className={classes.tab}
            onClick={event => {
              event.stopPropagation()
              setTab('table')
              if (!expanded) {
                setExpanded(true)
                window.sessionStorage[PREF_KEY] = true
              }
            }}
            role="button"
            tabIndex={0}
            onKeyDown={event => {
              event.stopPropagation()
              if (event.keyCode === 13) {
                setTab('table')
                if (!expanded) {
                  setExpanded(true)
                  window.sessionStorage[PREF_KEY] = true
                }
              }
            }}
            data-tab={tab === 'table' ? 'active' : ''}
          >
            <Icon type="list" />
          </div>
          <div
            className={classes.tab}
            onClick={event => {
              event.stopPropagation()
              setTab('chart')
              if (!expanded) {
                setExpanded(true)
                window.sessionStorage[PREF_KEY] = true
              }
            }}
            role="button"
            tabIndex={0}
            onKeyDown={event => {
              event.stopPropagation()
              if (event.keyCode === 13) {
                setTab('chart')
                if (!expanded) {
                  setExpanded(true)
                  window.sessionStorage[PREF_KEY] = true
                }
              }
            }}
            data-tab={tab === 'chart' ? 'active' : ''}
          >
            <Icon type="chart" />
          </div>
          <div className={classes.trackBorder} />
          <div className={classes.content} ref={contentRef}>
            <h2 className={classes.header}>{title}</h2>
            {/* <div className={classes.chartsWrapper}>Chart Area</div> */}
            <div
              className={classes.tableWrapper}
              style={{ display: tab !== 'table' ? 'none' : '' }}
            >
              <RenderProvider value={ctx}>
                <ResourceTable
                  key={resource}
                  requestKey={RESOURCE_TABLE_KEY}
                  resource={resource}
                  perPage={14}
                />
              </RenderProvider>
            </div>
            <div
              className={classes.chartWrapper}
              style={{ display: tab !== 'chart' ? 'none' : '' }}
            >
              <LineChart
                controlable={{
                  stackingCategories: { multiple: true },
                  x: {},
                  y: { multiple: true },
                }}
                legend={{ position: 'right', width: 200 }}
                xAxis={{ label: ' ', height: 50 }}
                yAxis={{ label: ' ', width: 50 }}
                grid
                datasets={{ id: resource }}
                mainColor="#1E90FF"
              />
              <ScatterChart
                controlable={{
                  stackingCategories: { multiple: true },
                  x: {},
                  y: { multiple: true },
                  z: true,
                }}
                legend={{ position: 'right', width: 200 }}
                xAxis={{ label: ' ', height: 50 }}
                yAxis={{ label: ' ', width: 50 }}
                grid
                datasets={{ id: resource }}
                mainColor="#1E90FF"
              />
            </div>
          </div>
        </div>
      </Conditional>
    </div>
  )
}

DatasourceInspector.propTypes = {
  title: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf(['UPLOADED_DATASET', 'QUERY_DATASET']),
}

export default DatasourceInspector
