import React from 'react'
import warning from 'tiny-warning'

const injectMaximums = (segments, total) => {
  const segs = [...segments]
  const tempSegments = segs.map((segment, index) => {
    const result = { ...segment }
    const minSum = segs.reduce(
      (acc, { min }, i) => (i !== index ? acc + min : acc),
      0
    )
    const max = Math.min(result.max || Infinity, total - minSum)
    warning(
      !(result.maximum || result.maximum === 0) || result.max === max,
      `Invalid maximum provided for segment \`${result.name}\`.
        \nCalculated maximum: ${max}.`
    )
    if (result.fixed) result.max = result.value
    else result.max = max
    return result
  })
  return tempSegments
}

export const adjustSegments = (segments, propTotal, step) => {
  const total = propTotal && step ? Math.round(propTotal / step) * step : propTotal
  // if (step) {
  //   warning(
  //     total === propTotal,
  //     `Prop \`total\` of type \`${typeof propTotal}\` supplied to \`Slider\` is not a multiple of ${step}.
  //     \nValue corrected to ${total}.`
  //   )
  // }
  let tempAdjustedSegments = [...segments].map(
    ({
      name,
      value: tempValue,
      min: tempMin,
      max: tempMax,
      fixed,
      disabled,
      color,
    }) => {
      const min = tempMin && step ? Math.round(tempMin / step) * step : tempMin
      const max = tempMax && step ? Math.round(tempMax / step) * step : tempMax
      let value = tempValue && step ? Math.round(tempValue / step) * step : tempValue
      // if (step) {
      //   warning(
      //     min === tempMin,
      //     `The minimum provided to the segment \`${name}\` is not a multiple of ${step}.
      //     \nValue corrected to ${min}.`
      //   )
      //   warning(
      //     max === tempMax,
      //     `The maximum provided to the segment \`${name}\` is not a multiple of ${step}.
      //     \nValue corrected to ${max}.`
      //   )
      //   warning(
      //     value === tempValue,
      //     `The value provided to the segment \`${name}\` is not a multiple of ${step}.
      //     \nValue corrected to ${value}.`
      //   )
      // }
      const result = { name, fixed, disabled, color }
      if (fixed) {
        if (!(value || value === 0)) {
          warning(
            false,
            `Undefined value provided to fixed segment \`${name}\`.
            \nValue defaults to ${min ? `${min} (minimum)` : 0}.`
          )
          value = min || 0
        }
        result.value = value
        result.min = value
        result.max = value
      } else if (min > max) {
        warning(
          false,
          `Invalid minimum/maximum provided for segment \`${name}\`.
          \nIgnoring segment bounds.`
        )
        if (value || value === 0) result.value = value
        result.min = 0
      } else {
        result.min = min || 0
        if (max) result.max = max
        if (value || value === 0)
          if (result.min <= value && value <= (result.max || Infinity))
            result.value = value
          else {
            if (value < result.min) result.value = result.min
            if (value > result.max) result.value = result.max
            warning(
              false,
              `Invalid value provided for segment \`${name}\`.
              \nValue out of bounds.\nD:[${min || 0},${
                max ? `${max}]` : 'infinity)'
              }, value: ${value}\nValue corrected to ${result.value}`
            )
          }
        else result.value = result.min
      }
      return result
    }
  )
  let tempTotal = 0
  const minimum = tempAdjustedSegments.reduce((acc, { min, fixed, value }) => {
    if (fixed) return acc + value
    if (min) return acc + min
    return acc
  }, 0)
  const maximum = tempAdjustedSegments.find(({ max }) => !(max || max === 0))
    ? Infinity
    : tempAdjustedSegments.reduce((acc, { max }) => acc + max, 0)

  if (total) {
    if (minimum > total) {
      tempTotal = minimum
      warning(
        false,
        `The sum of all provided segment minimums exceedes the given total. Given total ignored - proceeded with a calculated total of ${minimum}.
        \nAll segment values set to respective minimums.`
      )
      tempAdjustedSegments = tempAdjustedSegments.map(segment => ({
        ...segment,
        value: segment.min,
        max: segment.min,
      }))
    } else if (total > maximum) {
      tempTotal = maximum
      warning(
        false,
        `The given total exceedes the posible sum of all provided segment maximums. Given total ignored - proceeded with a calculated total of ${maximum}.
        \nAll segment values set to respective maximums.`
      )
      tempAdjustedSegments = tempAdjustedSegments.map(segment => ({
        ...segment,
        value: segment.max,
        min: segment.max,
      }))
    } else {
      tempTotal = total
      tempAdjustedSegments = injectMaximums(tempAdjustedSegments, tempTotal)
      const calculatedTotal = tempAdjustedSegments.reduce(
        (acc, { min, fixed, value }) => {
          if (fixed || value) return acc + value
          if (min) return acc + min
          return acc
        },
        0
      )
      const expand = tempTotal > calculatedTotal
      const availability = tempAdjustedSegments.map(({ min, max, value }) =>
        expand ? max - value : value - min
      )
      const availableRange = availability.reduce((acc, cur) => acc + cur)
      const ratio = (tempTotal - calculatedTotal) / availableRange
      if (step) {
        const availableSteps = availability.map(range => range / step)
        const corrections = availableSteps.map(count => count * ratio)
        const stepCorrections = corrections.map(count => Math.round(count))
        let difference =
          stepCorrections.reduce((acc, cur) => acc + cur) * step +
          calculatedTotal -
          tempTotal

        while (difference) {
          const tempDifference = difference
          // eslint-disable-next-line unused-imports/no-unused-vars
          const [_, index] = corrections.reduce(
            (acc, cur, i) => {
              if (cur) {
                const tempVal =
                  Math.abs(0.5 + (cur % 1) * Math.sign(tempDifference)) % 1
                return [Math.min(tempVal, acc[0]), tempVal < acc[0] ? i : acc[1]]
              }
              return acc
            },
            [Infinity, null]
          )
          corrections[index] -= Math.sign(tempDifference)
          difference =
            stepCorrections.reduce((acc, cur) => acc + cur) * step +
            calculatedTotal -
            tempTotal
          if (difference) difference = 0
        }
        const valueCorrections = stepCorrections.map(steps => steps * step)
        tempAdjustedSegments = tempAdjustedSegments.map((segment, i) => {
          const result = { ...segment }
          if (valueCorrections[i]) result.value += valueCorrections[i]
          return result
        })
      } else
        tempAdjustedSegments = tempAdjustedSegments.map((segment, i) => {
          const result = { ...segment }
          if (availability[i]) result.value += availability[i] * ratio
          return result
        })
    }
  } else {
    tempTotal = tempAdjustedSegments.reduce((acc, { value }) => acc + value, 0)
    tempAdjustedSegments = injectMaximums(tempAdjustedSegments, tempTotal)
  }

  tempAdjustedSegments = tempAdjustedSegments.map(segment => {
    const { min, max } = segment
    return { ...segment, fixed: min === max }
  })

  return [tempAdjustedSegments, tempTotal]
}

export const generateTicks = (segments, total) => {
  const [farLeftMins] = segments.reduce(
    (acc, cur) => {
      const array = [...acc[0]]
      const sum = acc[1] + cur.min / total
      array.push(sum)
      return [array, sum]
    },
    [[], 0]
  )
  farLeftMins.pop()
  const [farLeftMaxs] = [...segments].reverse().reduce(
    (acc, cur) => {
      const array = [...acc[0]]
      if (cur.max) {
        const sum = Math.min(acc[1] + cur.max / total, 1)
        array.push(1 - sum)
        return [array, sum]
      }
      array.push(0)
      return [array, 1]
    },
    [[], 0]
  )
  farLeftMaxs.pop()
  farLeftMaxs.reverse()
  const farLeft = farLeftMins.map((min, i) => Math.max(min, farLeftMaxs[i]))
  const [farRightMins] = [...segments].reverse().reduce(
    (acc, cur) => {
      const array = [...acc[0]]
      const sum = acc[1] + cur.min / total
      array.push(1 - sum)
      return [array, sum]
    },
    [[], 0]
  )
  farRightMins.pop()
  farRightMins.reverse()
  const [farRightMaxs] = segments.reduce(
    (acc, cur) => {
      const array = [...acc[0]]
      if (cur.max) {
        const sum = Math.min(acc[1] + cur.max / total, 1)
        array.push(sum)
        return [array, sum]
      }
      array.push(1)
      return [array, 1]
    },
    [[], 0]
  )
  const farRight = farRightMaxs.map((max, i) => Math.min(max, farRightMins[i]))
  const [positions] = [...segments].reduce(
    (acc, cur) => {
      const array = [...acc[0]]
      const sum = acc[1] + cur.value / total
      array.push(sum)
      return [array, sum]
    },
    [[], 0]
  )
  positions.pop()

  const ticks = positions.map((value, i) => ({
    min: farLeft[i],
    max: farRight[i],
    value,
    pushLeft: (segments[i].value - segments[i].min) / total,
    pushRight: (segments[i + 1].value - segments[i + 1].min) / total,
    pullLeft: (segments[i].max - segments[i].value) / total,
    pullRight: (segments[i + 1].max - segments[i + 1].value) / total,
  }))

  return ticks
}

const barPadding = 10
const barThickness = 2
// const tickRadius = 8
const stepSpacing = 2
export const getStaticElements = (rect, ratio, tickData) => {
  const classes = {
    bar: 'Slider-bar',
    fadedTick: 'Slider-fadedTick',
  }
  const { width = 1, height = 1 } = { ...rect }
  // const fadedTicks = tickData.map(({ value }, i) => {
  //   return (
  //     <circle
  //       // eslint-disable-next-line react/no-array-index-key
  //       key={`faded-tick-${i}-${value}`}
  //       className={classes.fadedTick}
  //       cx={Math.max(
  //         ((width - 2 * (barPadding + barThickness / 2)) * value +
  //           barPadding +
  //           barThickness / 2) *
  //           ratio,
  //         0
  //       )}
  //       cy="50%"
  //       r={tickRadius * ratio}
  //     />
  //   )
  // })
  return (
    <>
      <rect
        className={classes.bar}
        x={barPadding * ratio}
        rx={(barThickness / 2) * ratio}
        y={((height - barThickness) / 2) * ratio}
        width={Math.max((width - 2 * barPadding) * ratio, 0)}
        height={barThickness * ratio}
      />
      {/* {fadedTicks} */}
    </>
  )
}
export const getStepElements = (rect, ratio, count, segments, ticks) => {
  const classes = {
    step: 'Slider-step',
  }
  const { width = 1, height = 1 } = { ...rect }
  const steps = []
  for (let i = 0; i < count; i++) {
    const stepWidth =
      (Math.max(width - 2 * barPadding, 0) - (count + 1) * stepSpacing) / count
    steps.push(
      <rect
        className={classes.step}
        x={(barPadding + stepSpacing + i * (stepSpacing + stepWidth)) * ratio}
        y={((height - barThickness) / 2) * ratio}
        width={stepWidth * ratio}
        height={barThickness * ratio}
      />
    )
  }
  return steps
}

export const getTickPositions = (tickData, tickIndex, position) => {
  let result = [...tickData]
  if (tickIndex || tickIndex === 0) {
    const { min, max, value, pushLeft, pushRight } = tickData[tickIndex]
    const boundPosition = Math.max(Math.min(max, position), min)
    const l = boundPosition < value - pushLeft
    const r = boundPosition > value + pushRight
    result[tickIndex].value = boundPosition
    const tempResult = [{ ...tickData[tickIndex], value: boundPosition }]
    if (l) {
      let leftPush = Math.max(value - boundPosition - pushLeft, 0)
      for (let i = tickIndex - 1; i >= 0; i--) {
        const previousTick = { ...tickData[i] }
        const { min: pMin, max: pMax, value: pValue, pushLeft: pLeft } = previousTick
        let tempValue = pValue
        if (leftPush) tempValue -= leftPush
        const previousBoundPosition = Math.max(Math.min(pMax, tempValue), pMin)
        leftPush = Math.max(leftPush - pLeft, 0)
        tempResult.unshift({ ...previousTick, value: previousBoundPosition })
      }
    } else {
      // tempResult.unshift(...tickData.slice(0, tickIndex))
      let rightShift = Math.max(boundPosition - value, 0)
      for (let i = tickIndex - 1; i >= 0; i--) {
        const previousTick = { ...tickData[i] }
        const { min: pMin, max: pMax, value: pValue } = previousTick
        const { pullLeft } = tickData[i + 1]
        let tempValue = pValue
        if (rightShift > pullLeft) {
          rightShift -= pullLeft
          tempValue += rightShift
        } else rightShift = 0
        const previousBoundPosition = Math.max(Math.min(pMax, tempValue), pMin)
        tempResult.unshift({ ...previousTick, value: previousBoundPosition })
      }
    }
    if (r) {
      let rightPush = Math.max(boundPosition - value - pushRight, 0)
      for (let i = tickIndex + 1; i < tickData.length; i++) {
        const nextTick = { ...tickData[i] }
        const { min: nMin, max: nMax, value: nValue, pushRight: nRight } = nextTick
        let tempValue = nValue
        if (rightPush) tempValue += rightPush
        const nextBoundPosition = Math.max(Math.min(nMax, tempValue), nMin)
        rightPush = Math.max(rightPush - nRight, 0)
        tempResult.push({ ...nextTick, value: nextBoundPosition })
      }
    } else {
      // tempResult.push(...tickData.slice(tickIndex + 1))
      let leftShift = Math.max(value - boundPosition, 0)
      for (let i = tickIndex + 1; i < tickData.length; i++) {
        const nextTick = { ...tickData[i] }
        const { min: nMin, max: nMax, value: nValue } = nextTick
        const { pullRight } = tickData[i - 1]
        let tempValue = nValue
        if (leftShift > pullRight) {
          leftShift -= pullRight
          tempValue -= leftShift
        } else leftShift = 0
        const nextBoundPosition = Math.max(Math.min(nMax, tempValue), nMin)
        tempResult.push({ ...nextTick, value: nextBoundPosition })
      }
    }
    result = tempResult
  }
  return result
}

export const ticksToSegments = (segments, ticks, total) => {
  return segments.map((segment, i) => {
    if (i < ticks.length) {
      return {
        ...segment,
        value: Number(
          Number.parseFloat(
            (ticks[i].value - (i && ticks[i - 1].value)) * total
          ).toFixed(4)
        ),
      }
    }
    return {
      ...segment,
      value: Number(Number.parseFloat((1 - ticks[i - 1].value) * total).toFixed(4)),
    }
  })
}
