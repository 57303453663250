import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { useRefRect } from 'libs/utils/hooks'

import { useMouseTracker } from 'components/MouseTracker'

import './BaseCardStyle.scss'

const classes = {
  wrapper: 'BaseCard-wrapper',
  svg: 'BaseCard-svg',
}

const BaseCard = ({ children, className, style }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [rect, rectRef] = useRefRect()

  let { top: absTop = 0, left: absLeft = 0 } = { ...rect }
  const { width = 1, height = 1 } = { ...rect }
  absTop += (rect || 0) && rectRef.current.getBoundingClientRect().top
  absLeft += (rect || 0) && rectRef.current.getBoundingClientRect().left
  const xRange = 1000
  const yRange = 1000

  const ratios = {
    x: width / xRange,
    y: height / yRange,
  }

  const updateMouseData = useCallback(
    hookMouseData => {
      const xRatio = (width && 1 / width) || 0
      const yRatio = (height && 1 / height) || 0
      const x = (hookMouseData.x - absLeft) * xRatio
      const y = (hookMouseData.y - absTop) * yRatio
      setPosition({ x, y })
    },
    [setPosition, absLeft, absTop, width, height]
  )
  useMouseTracker(hookMouseData => updateMouseData(hookMouseData))

  const id = useMemo(() => `radial-${uuid()}`, [])

  return (
    <div
      className={`${classes.wrapper}${className ? ` ${className}` : ''}`}
      style={{ ...style }}
    >
      <svg
        ref={rectRef}
        className={classes.svg}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${xRange} ${yRange}`}
        preserveAspectRatio="none"
      >
        <defs>
          <radialGradient id={id}>
            <stop offset="0%" />
            <stop offset="100%" />
          </radialGradient>
        </defs>
        <ellipse
          cx={position.x * xRange || 0}
          cy={position.y * yRange || 0}
          rx={250 / ratios.x}
          ry={250 / ratios.y}
          fill={`url('#${id}')`}
        />
      </svg>
      {children}
    </div>
  )
}

BaseCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default BaseCard
