import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'

const classes = {
  removeObjectiveButton: 'TrainingWizard-remove-btn',
}

const RemoveObjectiveButton = props => {
  const { onClick } = props
  return (
    <button
      type="button"
      className={classes.removeObjectiveButton}
      onClick={onClick}
      onKeyDown={event => {
        if (event.keyCode === 13) {
          event.stopPropagation()
          event.preventDefault()
          onClick && onClick(event)
        }
      }}
    >
      <Icon type="trash" />
    </button>
  )
}

RemoveObjectiveButton.propTypes = {
  onClick: PropTypes.func,
}

export default RemoveObjectiveButton
