import React, { useRef, useState, useMemo } from 'react'
import { useStore } from 'core'

import { BaseCard as Card } from 'components/CardLayout'
import { Button } from '@material-ui/core'
import { NewTransformationModal } from 'components/ResourceModalComponents'
import { TransformationTableEntry, TransformationTableHeader } from './components'

import './style.scss'

const classes = {
  wrapper: 'TransformationsPage-wrapper',
  title: 'TransformationsPage-title',
  subTitle: 'TransformationsPage-subTitle',
  horizontalScrollDiv: 'TransformationsPage-horizontalScrollDiv',
  scrollDiv: 'TransformationsPage-scrollDiv',
}

const getTransformations = (transformations, sort) =>
  transformations
    ?.map(e => {
      const { name, id, updated_at: date = 'N/A' } = e
      return { name, date, id, original: e }
    })
    .sort((a, b) => {
      const aVal = a[sort.column]
      const bVal = b[sort.column]
      let result = 0
      if (typeof aVal === 'string' || typeof bVal === 'string') {
        result = `${aVal}`.localeCompare(`${bVal}`, 'en', {
          sensitivity: 'base',
        })
      } else result = aVal - bVal
      return result * (-1) ** (sort.order + 1)
    })

const TransformationsPage = () => {
  const modalRef = useRef()
  const [sort, setSort] = useState({ column: 'name', order: 1 })

  const {
    transformations: storeTransformations,
    selectedTransformation: selectedTransformationId,
  } = useStore('selectTransformations', 'selectSelectedTransformation')

  const transformations = useMemo(
    () => getTransformations(storeTransformations, sort),
    [storeTransformations, sort]
  )

  return (
    <>
      <NewTransformationModal ref={modalRef} />
      <div className={classes.wrapper}>
        <Card>
          <div className={classes.title}>Pipelines</div>
          <span className={classes.subTitle}>
            Pipelines allow to stepwise clean, impute and enrich multiple data
            sources into a dataset for training and scoring.
          </span>
          <div className={classes.horizontalScrollDiv}>
            <TransformationTableHeader
              sort={{
                column: sort.column,
                order: sort.order,
                callback: (column, order) => {
                  if (order) {
                    const tempSort = { column, order }
                    setSort(tempSort)
                  } else setSort({ column: null, order })
                },
              }}
            />
            <div className={classes.scrollDiv}>
              {transformations?.map(tf => (
                <TransformationTableEntry
                  key={tf.id}
                  id={tf.id}
                  name={tf.name}
                  description={tf.description}
                  date={tf.date}
                  original={tf.original}
                  selected={tf.id === selectedTransformationId}
                />
              ))}
            </div>
          </div>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => modalRef.current.open()}
          >
            Create a new pipeline
          </Button>
        </Card>
      </div>
    </>
  )
}

export default TransformationsPage
