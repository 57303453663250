import React, { forwardRef, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { useStore } from 'core'

import Modal from 'components/Modal'
import { Form, FormItem } from 'components/Form'
import { TextField } from '@material-ui/core'

import './style.scss'

const classes = {
  wrapper: 'EditTrainingModal-wrapper',
  formDiv: 'EditTrainingModal-formDiv',
}

const EditTrainingModal = forwardRef((props, ref) => {
  const formRef = useRef()
  const { editTraining } = useStore('editTraining')
  const { selectedTraining = {} } = props

  return (
    <Modal
      className={classes.wrapper}
      ref={ref}
      title={selectedTraining?.name}
      okText="Save"
      onOk={() => {
        const data = formRef.current.get()
        editTraining({
          id: selectedTraining.id,
          name: data.name,
          // description: data.description,
        })
        ref.current.close()
      }}
      onCancel={() => ref.current.close()}
      onClose={() => ref.current.close()}
      footer
    >
      <div className={classes.formDiv}>
        <Form ref={formRef} preload={selectedTraining}>
          <FormItem
            dataKey="name"
            defaultValue=""
            margin="dense"
            label="Name"
            fullWidth
            inputProps={{ autoFocus: true }}
            component={TextField}
          />
          {/* <FormItem
            dataKey="description"
            defaultValue=""
            margin="dense"
            label="Description"
            fullWidth
            multiline
            component={TextField}
          /> */}
        </Form>
      </div>
    </Modal>
  )
})

EditTrainingModal.propTypes = {
  selectedTraining: PropTypes.object,
}

export default EditTrainingModal
