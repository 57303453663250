/* eslint-disable no-shadow */
import { v4 as uuid } from 'uuid'
import { createBundle } from 'core/bundler'
import { createSelector } from 'create-selector'

import {
  TRAINING_CREATION_STARTED,
  TRAINING_CREATION_SUCCEEDED,
  TRAINING_CREATION_FAILED,
  COMPATIBLE_DATASETS_FETCH_STARTED,
  COMPATIBLE_DATASETS_FETCH_SUCCEEDED,
  COMPATIBLE_DATASETS_FETCH_FAILED,
  PIPELINE_PROCESSING_STARTED,
  PIPELINE_PROCESSING_FINISHED,
  TRANSFORMATION_METADATA_FETCH_STARTED,
} from 'core/actiontypes'
import { parseMeta } from '../../../../../libs/utils/helpers'

const createDynamicDataset =
  payload =>
  (dispatch, { api }) => {
    api('/datasets', {
      method: 'POST',
      body: payload,
    })
  }

export default config =>
  createBundle({
    name: 'dynamic-dataset',
    reducer: null,
    selectors: {},
    actions: {
      createDynamicDataset,
    },
    init: store => {
      store.serverEvent('POSTGRES_IMPORT_STARTED', ({ data }, store) => {
        // console.log('POSTGRES IMPORT STARTED', data)
      })
      store.serverEvent('POSTGRES_IMPORT_FINISHED', ({ data }, store) => {
        store.markDatasetsAsOutdated()
      })
      store.serverEvent('POSTGRES_IMPORT_FAILED', ({ data }, store) => {
        // console.log('POSTGRES IMPORT FAILED', data)
      })
      store.serverEvent('SFTP_DOWNLOAD_STARTED', ({ data }, store) => {
        // console.log('SFTP DOWNLOAD STARTED', data)
      })
      store.serverEvent('SFTP_DOWNLOAD_FINISHED', ({ data }, store) => {
        store.markDatasetsAsOutdated()
      })
      store.serverEvent('SFTP_DOWNLOAD_FAILED', ({ data }, store) => {
        // console.log('SFTP DOWNLOAD FAILED', data)
      })
    },
    args: null,
    middleware: null,
    persist: null,
  })
