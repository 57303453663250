import React, { useState, forwardRef, useImperativeHandle, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Dialog } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

import 'style.scss'

const Modal = forwardRef((props, ref) => {
  const {
    title,
    footer,
    okText = 'Ok',
    cancelText = 'Cancel',
    onOk,
    onCancel,
    onClose,
    children,
    disabled,
    onEnter,
    open: isOpen,
    ...rest
  } = props

  const [visible, setVisible] = useState(false)

  const close = useCallback(() => {
    setVisible(false)
  }, [])
  const open = useCallback(() => {
    setVisible(true)
  }, [])

  useImperativeHandle(ref, () => ({
    close,
    open,
    isOpen: visible,
  }))

  const showDialog = isOpen !== undefined ? !!isOpen : visible

  return (
    <Dialog
      classes={{ root: 'Modal' }}
      open={showDialog}
      onEnter={onEnter}
      onClick={event => event.stopPropagation()}
      // REMOVED TO PREVENT ESC / BACKDROP CLICK from closing the modal
      // // esc + enter handler
      onKeyDown={event => {
        if (event.keyCode === 13) onOk && onOk(event)
        if (event.keyCode === 27) onClose && onClose(event)
      }}
      // // click background handler
      onClose={event => {
        onClose && onClose(event)
        // onCancel && onCancel(event)
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <MuiDialogTitle classes={{ root: 'Modal-title' }}>
        <Typography>{title}</Typography>
        <IconButton
          aria-label="close"
          classes={{ root: 'button-root' }}
          onClick={event => {
            onClose && onClose(event)
            onCancel && onCancel(event)
          }}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent dividers>{children}</MuiDialogContent>
      {footer && (
        <MuiDialogActions>
          <Button onClick={onCancel} color="primary" variant="outlined">
            {cancelText}
          </Button>
          <Button
            onClick={onOk}
            variant="contained"
            color="primary"
            disabled={disabled}
          >
            {okText}
          </Button>
        </MuiDialogActions>
      )}
    </Dialog>
  )
})

export default Modal

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footer: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onEnter: PropTypes.func,
  open: PropTypes.bool,
}
