/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { createBundle } from 'core/bundler'
import { createSelector } from 'create-selector'

import {
  RESOURCE_REQUEST_REGISTERED,
  RESOURCE_REQUEST_PAGINATION_SET,
  RESOURCE_REQUEST_SORT_SET,
  RESOURCE_PREPARATION_REGISTERED,
  RESOURCE_PREPARATION_REQUEST_STARTED,
  RESOURCE_PREPARATION_REQUEST_FAILED,
  RESOURCE_DATA_PREPARATION_REQUEST_SUCCEEDED,
  RESOURCE_CHART_PREPARATION_REQUEST_SUCCEEDED,
  RESOURCE_DATA_PREPARATION_EXPIRED,
  RESOURCE_CHART_PREPARATION_EXPIRED,
  RESOURCE_REQUEST_DATA_FETCH_STARTED,
  RESOURCE_REQUEST_DATA_FETCH_SUCCEEDED,
  RESOURCE_REQUEST_DATA_FETCH_FAILED,
  RESOURCE_REQUEST_META_FETCH_STARTED,
  RESOURCE_REQUEST_META_FETCH_SUCCEEDED,
  RESOURCE_REQUEST_META_FETCH_FAILED,
} from 'core/actiontypes'
import invariant from 'tiny-invariant'
import { BATCH_ACTIONS } from '../../../../core/actiontypes'

const NAME = 'resource-data'
const SELECTORS = {
  REQUESTS: 'selectResourceDataRequests',
  RESOURCES: 'selectResourceDataResources',
  DATA: 'selectResourceData',
  META: 'selectResourceMeta',
  PREPARATION_FAILED: 'selectResourcePreparationFailed',
  IS_LOADING: 'selectResourceRequestIsLoading',
  PREPARATION_IS_LOADING: 'selectResourceRequestPreparationIsLoading',
  METADATA_IS_LOADING: 'selectResourceRequestMetaIsLoading',
  LAST_SUCCESS: 'selectResourceRequestLastSuccess',
  ERROR: 'selectResourceRequestError',
  FAILED_PERMANENTLY: 'selectResourceRequestFailed',
  PAGE: 'selectResourcePage',
  SORT: 'selectResourceSortParams',
}

const defaultConfig = {
  retry: 15000, // fifteen seconds
  maxRetryCount: 3,
}

const extract = response => {
  const { data, features } = response
  return data.map(row => {
    return features.reduce((res, feature, i) => {
      res[feature.name] = row[i]
      return res
    }, {})
  })
}

const initialResourceRequest = {
  key: null,
  resource: null,
  type: null,
  page: 1,
  perPage: 100,
  sortKey: null,
  sortOrder: 0,
  data: null,
  // meta: null,
  isLoading: false,
  isOutdated: false,
  lastSuccess: null,
  error: null,
  errorTimes: [],
}

const initialPreparedResource = {
  id: null,
  prepared: false,
  isLoading: false,
  chartPrepared: false,
  chartIsLoading: false,
  error: null,
  errorTimes: [],
}

const initialResourceMetadata = {
  id: null,
  data: null,
  isLoading: false,
  error: null,
  errorTimes: [],
}

const initialState = {
  requests: [],
  prepared: [],
  metadata: [],
}

const getResourceRequestPath = ({
  type,
  resource,
  page,
  perPage,
  sortKey,
  sortOrder,
}) => {
  switch (type) {
    case 'table':
      return `/resources/${resource}/data?page=${page}&perPage=${perPage}${
        sortKey || sortKey === 0
          ? `&sortBy=${sortKey}&order=${sortOrder || 'asc'}`
          : ''
      }`
    case 'chart':
      return `/resources/${resource}/chart`
    default:
      return `/resources/${resource}/data?page=1&perPage=100000`
  }
}

export default config => {
  config = { ...defaultConfig, config }

  const resourceRequestSelector = (state, key) =>
    key
      ? state[NAME].requests.find(e => e.key === key) || null
      : state[NAME].requests

  const preparedResourceSelector = (state, resourceID) =>
    resourceID
      ? state[NAME].prepared.find(e => e.id === resourceID) || null
      : state[NAME].prepared

  const resourceMetadataSelector = (state, resourceID) =>
    resourceID
      ? state[NAME].metadata.find(e => e.key === resourceID) || null
      : state[NAME].metadata

  const dataSelector = (state, key) => {
    const request = resourceRequestSelector(state, key)
    const data = request?.data || null
    if (key) return data
    return request
  }

  const metaSelector = (state, key) => {
    const request = resourceRequestSelector(state, key)
    const metadata = resourceMetadataSelector(state, request?.resource)
    const meta = metadata?.data || null
    if (key) return meta
    return metadata
  }

  const preparationFailedSelector = (state, key) => {
    const request = resourceRequestSelector(state, key)
    invariant(
      request !== null,
      `Cannot read data for key "${key}", make sure you have registered a resource-request for that key.`
    )
    if (key) return !!preparedResourceSelector(state, request.resource)?.prepared

    const resources = preparedResourceSelector(state)

    return request.reduce((result, req) => {
      const res = resources.find(e => e.id === req.resource) || null
      result[req.key] = res ? res.errorTimes.length > config.maxRetryCount : false
      return result
    }, {})
  }

  const preparationLoadingSelector = (state, key) => {
    const request = resourceRequestSelector(state, key)
    const prepared = preparedResourceSelector(state, request?.resource)
    const loadKey = request?.type === 'chart' ? 'chartIsLoading' : 'isLoading'
    const isLoading = prepared?.[loadKey] || null
    if (key) return isLoading
    return prepared
  }

  const metaLoadingSelector = (state, key) => {
    const request = resourceRequestSelector(state, key)
    const metadata = resourceMetadataSelector(state, request?.resource)
    const isLoading = metadata?.isLoading || null
    if (key) return isLoading
    return metadata
  }

  const loadingSelector = (state, key) => {
    const isLoading = resourceRequestSelector(state, key)?.isLoading
    return isLoading !== undefined ? isLoading : null
  }

  const lastSuccessSelector = (state, key) => {
    const lastSuccess = resourceRequestSelector(state, key)?.lastSuccess
    return lastSuccess || null
  }

  const errorSelector = (state, key) => {
    const error = resourceRequestSelector(state, key)?.error
    return error || null
  }

  const failedPermanentlySelector = (state, key) => {
    const errorTimes = resourceRequestSelector(state, key)?.errorTimes
    return errorTimes ? errorTimes.length > config.maxRetryCount : null
  }

  const pageSelector = (state, key) => {
    const page = resourceRequestSelector(state, key)?.page
    return page || null
  }

  const sortSelector = (state, key) => {
    const sKey = resourceRequestSelector(state, key)?.sortKey
    const sOrder = resourceRequestSelector(state, key)?.sortOrder
    return { key: sKey, order: sOrder }
  }

  const missingPreparedResourceReactor = createSelector(
    resourceRequestSelector,
    preparedResourceSelector,
    (requests, prepared) => {
      if (!requests.length) return null
      const missing = requests.filter(
        e => !prepared.some(resource => resource.id === e.resource)
      )

      if (!missing.length) return null

      return dispatch =>
        dispatch({
          type: 'BATCH_ACTIONS',
          actions: missing.map(({ resource }) => ({
            type: RESOURCE_PREPARATION_REGISTERED,
            payload: resource,
          })),
        })
    }
  )

  const missingResourcePreparationReactor = createSelector(
    preparedResourceSelector,
    resourceRequestSelector,
    'selectAppTime',
    (prepared, requests, appTime) => {
      const missing = prepared
        .filter(e => !(e.prepared && e.chartPrepared))
        // .filter(e => !(e.prepared || e.isLoading))
        .filter(e => e.errorTimes.length <= config.maxRetryCount)
        .filter(e => appTime - (e.errorTimes.slice(-1)[0] || null) > config.retry)
        .filter(e =>
          requests
            .filter(r => r.resource === e.id)
            .some(
              r =>
                (r.type === 'table' && !(e.prepared || e.isLoading)) ||
                (r.type === 'chart' && !(e.chartPrepared || e.chartIsLoading))
            )
        )

      if (!missing.length) return null

      return (dispatch, { api }) =>
        missing.forEach(resource => {
          const { id } = resource
          const resReq = requests.filter(e => e.resource === resource.id)
          const requestData = !!resReq.filter(e => e.type === 'table').length
          const requestChart = !!resReq.filter(e => e.type === 'chart').length
          dispatch({
            type: RESOURCE_PREPARATION_REQUEST_STARTED,
            payload: {
              id,
              requestData,
              requestChart,
            },
          })
          api
            .view(`/resources/${id}/prepare`, {
              method: 'POST',
              body: {
                chart: requestChart,
                // chart_only: requestChart && !requestData,
                chart_only: false,
              },
            })
            .catch(e => {
              // TODO: uncomment when backend fixes response type text > json
              dispatch({
                type: RESOURCE_PREPARATION_REQUEST_FAILED,
                payload: id,
                error: e,
              })
              throw e
              // we should NEVER end up here unless it is a network error
              // if we do it means implementation is bad.
              // if it is a network error, it should be picked up by a global
              // error handler which still isnt implemented but /ohwell
            })
        })
    }
  )

  const missingResourceDataReactor = createSelector(
    resourceRequestSelector,
    preparedResourceSelector,
    'selectAppTime',
    (requests, prepared, appTime) => {
      prepared = prepared.filter(e => e.prepared || e.chartPrepared)
      if (!requests.length || !prepared.length) return null

      const missing = requests
        .filter(e => prepared.some(resource => resource.id === e.resource))
        .filter(e => e.errorTimes.length <= config.maxRetryCount)
        .filter(e => !e.data || e.isOutdated)
        .filter(e => !e.isLoading)
        .filter(e => appTime - (e.errorTimes.slice(-1)[0] || null) > config.retry)

      if (!missing.length) return null

      return (dispatch, { api }) => {
        dispatch({
          type: BATCH_ACTIONS,
          actions: missing.reduce((batch, request) => {
            const { key } = request
            batch.push({
              type: RESOURCE_REQUEST_DATA_FETCH_STARTED,
              payload: { key },
            })
            return batch
          }, []),
        })
        missing.forEach(request => {
          const { key, resource } = request
          api
            .view(getResourceRequestPath(request))
            .then(data => {
              dispatch({
                type: RESOURCE_REQUEST_DATA_FETCH_SUCCEEDED,
                payload: { data, key },
              })
            })
            .catch(error => {
              const expiredActions = []
              const dataPrepError = error.error_type === 'DATA_NOT_PREPARED'
              const chartPrepError = error.error_type === 'CHART_NOT_PREPARED'
              if (dataPrepError)
                expiredActions.push({
                  type: RESOURCE_DATA_PREPARATION_EXPIRED,
                  payload: resource,
                })
              if (chartPrepError)
                expiredActions.push({
                  type: RESOURCE_CHART_PREPARATION_EXPIRED,
                  payload: resource,
                })
              dispatch({
                type: BATCH_ACTIONS,
                actions: [
                  ...expiredActions,
                  {
                    type: RESOURCE_REQUEST_DATA_FETCH_FAILED,
                    payload: { error, key },
                  },
                ],
              })
            })
        })
      }
    }
  )

  const missingResourceMetaReactor = createSelector(
    resourceMetadataSelector,
    preparedResourceSelector,
    'selectAppTime',
    (metadata, prepared, appTime) => {
      prepared = prepared.filter(e => e.prepared || e.chartPrepared)
      if (!metadata.length || !prepared.length) return null

      const missing = metadata
        .filter(e => prepared.some(resource => resource.id === e.id))
        .filter(e => e.errorTimes.length <= config.maxRetryCount)
        .filter(e => !(e.data || e.isLoading))
        .filter(e => appTime - (e.errorTimes.slice(-1)[0] || null) > config.retry)

      if (!missing.length) return null

      return (dispatch, { api }) => {
        missing.forEach(resource => {
          const { id } = resource
          dispatch({ type: RESOURCE_REQUEST_META_FETCH_STARTED, payload: id })
          api
            .view(`/resources/${id}/meta`)
            .then(data => {
              dispatch({
                type: RESOURCE_REQUEST_META_FETCH_SUCCEEDED,
                payload: { data, id },
              })
            })
            .catch(error => {
              dispatch({
                type: RESOURCE_REQUEST_META_FETCH_FAILED,
                payload: id,
                error,
              })
            })
        })
      }
    }
  )

  const downloadDataset =
    ({ id }) =>
    (_, { store, api }) => {
      fetch(`https://view.guufii.de/resources/${id}/download`, {
        // fetch(`${api.view.path}/resources/${id}/download`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${store.selectToken()}`,
        },
      }).then(response => {
        response.blob().then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `${Date.now()}.csv`
          a.click()
        })
        // api.view(`/resources/${id}/download`, { method: 'GET' }).then(res => {
        //   res.blob().then(blob => {
        //     const url = window.URL.createObjectURL(blob)
        //     const a = document.createElement('a')
        //     a.href = url
        //     a.download = `${name}.csv`
        //     a.click()
        //   })
      })
    }

  return createBundle({
    name: NAME,
    reducer: (state = initialState, { type: action, payload, error }) => {
      if (action === RESOURCE_REQUEST_REGISTERED) {
        const {
          key,
          resource_id: resource,
          page,
          perPage,
          sortKey,
          sortOrder,
          type,
          refresh,
        } = payload
        const req = state.requests.find(e => e.key === key) || null
        if (!refresh && req) return state
        return {
          ...state,
          requests: [
            ...state.requests.filter(e => e.key !== key),
            {
              ...initialResourceRequest,
              key,
              resource,
              page,
              perPage,
              sortKey,
              sortOrder,
              type,
            },
          ],
        }
      }
      if (action === RESOURCE_PREPARATION_REGISTERED) {
        return {
          ...state,
          prepared: [
            ...state.prepared.filter(e => e.id !== payload),
            {
              ...initialPreparedResource,
              id: payload,
            },
          ],
          metadata: [
            ...state.metadata.filter(e => e.id !== payload),
            {
              ...initialResourceMetadata,
              id: payload,
            },
          ],
        }
      }
      if (action === RESOURCE_PREPARATION_REQUEST_STARTED) {
        const { id, requestData, requestChart } = payload
        const resource = state.prepared.find(e => e.id === id)
        return {
          ...state,
          prepared: [
            ...state.prepared.filter(e => e.id !== id),
            {
              ...resource,
              isLoading: requestData,
              chartIsLoading: requestChart,
            },
          ],
        }
      }
      if (action === RESOURCE_DATA_PREPARATION_REQUEST_SUCCEEDED) {
        const resource = state.prepared.find(e => e.id === payload)
        return {
          ...state,
          prepared: [
            ...state.prepared.filter(e => e.id !== payload),
            {
              ...resource,
              prepared: true,
              isLoading: false,
              error: null,
              errorTimes: [],
            },
          ],
        }
      }
      if (action === RESOURCE_CHART_PREPARATION_REQUEST_SUCCEEDED) {
        const resource = state.prepared.find(e => e.id === payload)
        return {
          ...state,
          prepared: [
            ...state.prepared.filter(e => e.id !== payload),
            {
              ...resource,
              chartPrepared: true,
              chartIsLoading: false,
              error: null,
              errorTimes: [],
            },
          ],
        }
      }
      if (action === RESOURCE_DATA_PREPARATION_EXPIRED) {
        const resource = state.prepared.find(e => e.id === payload)
        return {
          ...state,
          prepared: [
            ...state.prepared.filter(e => e.id !== payload),
            {
              ...resource,
              prepared: false,
            },
          ],
        }
      }
      if (action === RESOURCE_CHART_PREPARATION_EXPIRED) {
        const resource = state.prepared.find(e => e.id === payload)
        return {
          ...state,
          prepared: [
            ...state.prepared.filter(e => e.id !== payload),
            {
              ...resource,
              chartPrepared: false,
            },
          ],
        }
      }
      if (action === RESOURCE_PREPARATION_REQUEST_FAILED) {
        const resource = state.prepared.find(e => e.id === payload)
        const errorMessage = (error && error.message) || error
        return {
          ...state,
          prepared: [
            ...state.prepared.filter(e => e.id !== payload),
            {
              ...resource,
              isLoading: false,
              chartIsLoading: false,
              error: errorMessage,
              errorTimes: [...resource.errorTimes, Date.now()],
            },
          ],
        }
      }
      if (action === RESOURCE_REQUEST_META_FETCH_STARTED) {
        const metadata = state.metadata.find(e => e.id === payload)
        return {
          ...state,
          metadata: [
            ...state.metadata.filter(e => e.id !== payload),
            {
              ...metadata,
              isLoading: true,
            },
          ],
        }
      }
      if (action === RESOURCE_REQUEST_META_FETCH_SUCCEEDED) {
        const { id, data } = payload
        const metadata = state.metadata.find(e => e.id === id)
        return {
          ...state,
          metadata: [
            ...state.metadata.filter(e => e.id !== id),
            {
              ...metadata,
              data,
              isLoading: false,
              error: null,
              errorTimes: [],
            },
          ],
        }
      }
      if (action === RESOURCE_REQUEST_META_FETCH_FAILED) {
        const metadata = state.metadata.find(e => e.id === payload)
        const errorMessage = (error && error.message) || error
        return {
          ...state,
          metadata: [
            ...state.metadata.filter(e => e.id !== payload),
            {
              ...metadata,
              isLoading: false,
              error: errorMessage,
              errorTimes: [...metadata.errorTimes, Date.now()],
            },
          ],
        }
      }
      if (action === RESOURCE_REQUEST_DATA_FETCH_STARTED) {
        const request = state.requests.find(e => e.key === payload.key)
        return {
          ...state,
          requests: [
            ...state.requests.filter(e => e.key !== payload.key),
            {
              ...request,
              isLoading: true,
            },
          ],
        }
      }
      if (action === RESOURCE_REQUEST_DATA_FETCH_SUCCEEDED) {
        const request = state.requests.find(e => e.key === payload.key)
        return {
          ...state,
          requests: [
            ...state.requests.filter(e => e.key !== payload.key),
            {
              ...request,
              data: extract(payload.data),
              meta: Object.keys(payload.data)
                .filter(e => e !== 'data')
                .reduce((acc, key) => {
                  acc[key] = payload.data[key]
                  return acc
                }, {}),
              isLoading: false,
              isOutdated: false,
              lastSuccess: Date.now(),
              error: null,
              errorTimes: [],
            },
          ],
        }
      }
      if (action === RESOURCE_REQUEST_DATA_FETCH_FAILED) {
        const request = state.requests.find(e => e.key === payload.key)
        const errorMessage = (error && error.message) || error
        return {
          ...state,
          requests: [
            ...state.requests.filter(e => e.key !== payload.key),
            {
              ...request,
              isLoading: false,
              error: errorMessage,
              errorTimes: [...request.errorTimes, Date.now()],
            },
          ],
        }
      }
      if (action === RESOURCE_REQUEST_PAGINATION_SET) {
        const request = state.requests.find(e => e.key === payload.key)
        if (request.page !== payload.page || request.perPage !== payload.perPage) {
          const page = payload.page || request.page
          const perPage = payload.perPage || request.perPage
          return {
            ...state,
            requests: [
              ...state.requests.filter(e => e.key !== payload.key),
              {
                ...request,
                page,
                perPage,
                isOutdated: true,
              },
            ],
          }
        }
      }
      if (action === RESOURCE_REQUEST_SORT_SET) {
        const request = state.requests.find(e => e.key === payload.key)
        if (
          request.sortKey !== payload.sortKey ||
          request.sortOrder !== payload.sortOrder
        ) {
          const { sortKey, sortOrder } = payload
          // const sortKey =
          // payload.sortKey || payload.sortKey === 0
          //   ? payload.sortKey
          //   : request.sortKey
          // const sortOrder = payload.sortOrder || request.sortOrder
          return {
            ...state,
            requests: [
              ...state.requests.filter(e => e.key !== payload.key),
              {
                ...request,
                sortKey,
                sortOrder,
                isOutdated: true,
              },
            ],
          }
        }
      }
      if (action === 'test_micko') {
        const reqs = state.requests
          .filter(e => e.resource === payload)
          .map(e => ({ ...e, data: null }))
        return {
          ...state,
          metadata: [
            ...state.metadata.filter(e => e.id !== payload),
            { ...initialResourceMetadata },
          ],
          requests: [...state.requests.filter(e => e.resource !== payload), ...reqs],
        }
      }
      return state
    },
    selectors: {
      [SELECTORS.REQUESTS]: resourceRequestSelector,
      [SELECTORS.RESOURCES]: preparedResourceSelector,
      [SELECTORS.DATA]: dataSelector,
      [SELECTORS.META]: metaSelector,
      [SELECTORS.PREPARATION_FAILED]: preparationFailedSelector,
      [SELECTORS.IS_LOADING]: loadingSelector,
      [SELECTORS.PREPARATION_IS_LOADING]: preparationLoadingSelector,
      [SELECTORS.METADATA_IS_LOADING]: metaLoadingSelector,
      [SELECTORS.LAST_SUCCESS]: lastSuccessSelector,
      [SELECTORS.ERROR]: errorSelector,
      [SELECTORS.FAILED_PERMANENTLY]: failedPermanentlySelector,
      [SELECTORS.PAGE]: pageSelector,
      [SELECTORS.SORT]: sortSelector,
      reactMissingPreparedResource: missingPreparedResourceReactor,
      reactMissingResourcePreparation: missingResourcePreparationReactor,
      reactMissingResourceData: missingResourceDataReactor,
      reactMissingResourceMeta: missingResourceMetaReactor,
    },
    actions: {
      // eslint-disable-next-line camelcase
      prepareResourceData: resource_id => ({
        type: RESOURCE_PREPARATION_REGISTERED,
        payload: resource_id,
      }),
      // eslint-disable-next-line camelcase
      registerResourceRequest: (key, resource_id, type, page, perPage, refresh) => ({
        type: RESOURCE_REQUEST_REGISTERED,
        payload: { key, resource_id, type, page, perPage, refresh },
      }),
      setResourceRequestPagination: (key, page, perPage) => ({
        type: RESOURCE_REQUEST_PAGINATION_SET,
        payload: { key, page, perPage },
      }),
      setResourceRequestSort: (key, sortKey, sortOrder) => ({
        type: RESOURCE_REQUEST_SORT_SET,
        payload: { key, sortKey, sortOrder },
      }),
      downloadDataset,
      testMicko: () => ({
        type: 'test_micko',
        payload: '55555555-5555-5555-5555-555555555555',
      }),
    },
    init: store => {
      store.serverEvent('DATA_PREPARE_FINISHED', ({ data }, store) => {
        store.dispatch({
          type: RESOURCE_DATA_PREPARATION_REQUEST_SUCCEEDED,
          payload: data.resource_id,
        })
      })
      store.serverEvent('CHART_PREPARE_FINISHED', ({ data }, store) => {
        store.dispatch({
          type: RESOURCE_CHART_PREPARATION_REQUEST_SUCCEEDED,
          payload: data.resource_id,
        })
      })
      store.serverEvent('DATA_PREPARE_FAILED', ({ data }, store) => {
        store.dispatch({
          type: RESOURCE_PREPARATION_REQUEST_FAILED,
          payload: data.resource_id,
          error: data.error,
        })
      })
    },
    args: null,
    middleware: null,
    persist: null,
  })
}
