/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { useContext, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import invariant from 'tiny-invariant'
import warning from 'tiny-warning'
import { Context } from '../FormContext'

const self = 'FormItem'

const extract = (e, keystring) => {
  // eslint-disable-next-line no-param-reassign
  if (keystring === 'value' || keystring === 'checked') e = e.target
  const value = keystring
    .split('.')
    .filter(key => key.length)
    .reduce((target, key) => target[key], e)
  warning(
    value !== undefined,
    `Recieved 'undefined' as ${self} value, verify the "value" property is defined properly.`
  )
  return value
}

const FormItem = ({
  component,
  dataKey: fieldKey = '',
  onChange,
  onBlur,
  value: valueProp,
  defaultValue,
  checked,
  absolute,
  ...rest
}) => {
  const context = useContext(Context)

  invariant(context, `You cannot use the ${self} component outside of <Form>`)
  invariant(
    component,
    `Component ${self} requires a React Component to be passed as a 'component' prop, got: ${component}`
  )
  const { index, contextKey, dispatch } = context

  let dataKey = !absolute ? contextKey : ''
  if (dataKey && fieldKey && !/^(\[[0-9]+\])/.test(fieldKey)) dataKey += '.'
  dataKey += fieldKey

  const child = component
  const state = context.get(dataKey, 'value')

  const valueKey =
    valueProp === undefined
      ? checked !== undefined
        ? 'checked'
        : 'value'
      : valueProp

  const value =
    state !== undefined
      ? state
      : valueKey === 'checked' && checked !== undefined
      ? checked
      : defaultValue

  const changeHandler = useCallback(
    (...params) => {
      const e = params[0]
      const val =
        typeof valueKey === 'function' ? valueKey(...params) : extract(e, valueKey)
      onChange && onChange(...params, index, dataKey)
      context.onChange && context.onChange(dataKey, val)
    },
    [context, dataKey, index, onChange, valueKey]
  )

  const blurHandler = useCallback(
    (...params) => {
      onBlur && onBlur(...params, index)
      context.onBlur && context.onBlur(dataKey)
    },
    [context, dataKey, index, onBlur]
  )

  const props = {
    onChange: changeHandler,
    onBlur: blurHandler,
    [valueKey === 'checked' ? 'checked' : 'value']: value,
    ...rest,
  }

  //  ===================================
  //  IMPORTANT! Do not change the deps!
  //  ===================================
  const hasField = context.get(dataKey)
  useEffect(() => {
    if (!hasField) {
      context.init(dataKey, value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKey, !!hasField])

  useEffect(() => {
    return () => dispatch('delete', dataKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKey])

  return <>{React.createElement(child, props)}</>
}

FormItem.propTypes = {
  component: PropTypes.elementType,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  defaultValue: PropTypes.any,
  checked: PropTypes.bool,
  dataKey: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  absolute: PropTypes.bool,
}

export default FormItem
