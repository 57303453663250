import React, { useRef, useState } from 'react'
import { useStore } from 'core'
import { TextField, Switch, FormHelperText } from '@material-ui/core'

import Modal from 'components/Modal'
import { Form, FormItem } from 'components/Form'
import { Table, Column } from 'components/Table'
import { CardLayout, UploadColumnCard } from 'components/CardLayout'

import './style.scss'

const classes = {
  wrapper: 'UploadProgress-wrapper',
  entity: 'UploadProgress-entity',
  gauge: 'UploadProgress-gauge',
  uploadButton: 'UploadProgress-uploadButton',
  discardButton: 'UploadProgress-discardButton',
  modalContentWrapper: 'UploadProgress-modalContentWrapper',
  formAligner: 'UploadProgress-formAligner',
  formDiv: 'UploadProgress-formDiv',
  tableDiv: 'UploadProgress-tableDiv',
}

const UploadProgress = props => {
  const modalRef = useRef()
  const settings = useRef()
  // const [page, setPage] = useState(1)
  const {
    uploads,
    uploadEntityConfiguring: configuring,
    uploadFile,
    parseUploadData,
    configureUploadEntity,
    updateUploadEntityConfiguration,
    removeUploadEntity,
  } = useStore(
    'selectUploads',
    'selectUploadEntityConfiguring',
    'uploadFile',
    'parseUploadData',
    'configureUploadEntity',
    'updateUploadEntityConfiguration',
    'removeUploadEntity'
  )
  const keys = Object.keys(uploads)

  const onEntity = id => {
    configureUploadEntity(id)
    // setPage(1)
  }

  const configUploadEntity = uploads[configuring]?.configuration || { spec: {} }

  const sampleData = (uploads[configuring]?.sampleData?.data || []).slice(0, 11)
  // const sampleMeta = uploads[configID]?.sampleData?.meta?.fields || []

  const defaults = {
    name: configUploadEntity.name,
    delimiter: configUploadEntity.delimiter,
    has_header: configUploadEntity.has_header,
    spec: {
      date_format: configUploadEntity.spec.date_format,
      type: configUploadEntity.spec.type,
      stacked_by: configUploadEntity.spec.stacked_by,
    },
  }

  return (
    <>
      <Modal
        ref={modalRef}
        title="Upload options (sample data)"
        open={!!configuring}
        onOk={() => {
          const payload = {
            id: configuring,
            data: settings.current.get(),
          }
          updateUploadEntityConfiguration(payload)
          uploadFile(configuring)
          configureUploadEntity(null)
        }}
        onCancel={() => {
          configureUploadEntity(null)
        }}
        onClose={() => {
          configureUploadEntity(null)
        }}
        okText="Upload"
        footer
      >
        <div className={classes.modalContentWrapper}>
          <Form ref={settings} preload={defaults}>
            <div className={classes.formAligner}>
              <FormItem
                label="Name"
                dataKey="name"
                defaultValue={defaults.name}
                fullWidth
                inputProps={{ autoFocus: true }}
                component={TextField}
              />
              <br />
              {/* <FormHelperText>Delimiter</FormHelperText>
              <FormItem
                dataKey="delimiter"
                defaultValue={defaults.delimiter}
                onChange={event => {}}
                row
                component={RadioGroup}
              >
                <FormControlLabel
                  control={<Radio size="small" color="default" value="," />}
                  label=","
                />
                <FormControlLabel
                  control={<Radio size="small" color="default" value=";" />}
                  label=";"
                />
              </FormItem> */}
              <FormHelperText>Header</FormHelperText>
              <FormItem
                dataKey="has_header"
                checked
                onChange={event => {
                  parseUploadData({
                    id: configuring,
                    file: uploads[configuring]?.file || null,
                    config: { header: event.target.checked },
                  })
                }}
                color={configUploadEntity.has_header ? 'primary' : 'default'}
                component={Switch}
              />
              <br />
              {/* <FormItem
                dataKey="spec.date_format"
                defaultValue="DD.MM.YYYY"
                label="Date format"
                select
                component={TextField}
              >
                <MenuItem value="DD.MM.YYYY">DD.MM.YYYY</MenuItem>
                <MenuItem value="YYYY-MM-DD">YYYY-MM-DD</MenuItem>
              </FormItem>
              <br /> */}
              {/* <FormItem
                dataKey="spec.type"
                defaultValue="basedata"
                label="Data type"
                select
                component={TextField}
              >
                <MenuItem value="basedata">Base data</MenuItem>
                <MenuItem value="timeseries">Timeseries</MenuItem>
                <MenuItem value="stacked-timeseries">Stacked timeseries</MenuItem>
              </FormItem>
              <br />
              <FormConditional dataKey="spec.type" value="stacked-timeseries">
                <FormItem
                  dataKey="spec.stacked_by"
                  defaultValue=""
                  label="Stacked by"
                  select
                  component={TextField}
                >
                  {sampleMeta.map((feature, i) => (
                    <MenuItem key={`type-${feature}`} value={i}>
                      {feature}
                    </MenuItem>
                  ))}
                </FormItem>
                <br />
              </FormConditional> */}
            </div>
          </Form>
          <div className={classes.tableDiv}>
            <Table
              data={sampleData}
              // footer={{
              //   pagination: {
              //     perPage: 10,
              //     currentPage: page,
              //     setCurrentPage: setPage,
              //   },
              // }}
            >
              <Column dataKey="_defaultColumn" width={120} />
            </Table>
          </div>
        </div>
      </Modal>
      <div className={classes.wrapper}>
        <CardLayout
          direction="column"
          spacing={20}
          sort={{ default: 'priority', order: 1 }}
        >
          {keys.map((key, index) => {
            const upload = uploads[key]
            let progress = upload.progress || 0
            let priority = -progress
            if (upload.status.search('failed') !== -1) {
              progress = 1
              priority += 300
            } else if (upload.status === 'configurable') {
              progress = 1
              priority -= 100
            } else if (upload.status === 'typifying') {
              priority += 100
            } else if (upload.status === 'completed') {
              priority += 200
            }
            return (
              <UploadColumnCard
                key={`uploadColumnCard-${key}`}
                id={key}
                i={index}
                progress={progress}
                title={upload.configuration.name || upload.fileName}
                status={upload.status}
                priority={priority}
                onEntity={() => onEntity(key)}
                uploadFile={() => uploadFile(key)}
                removeEntity={() => removeUploadEntity({ id: key })}
              />
            )
          })}
        </CardLayout>
      </div>
    </>
  )
}

UploadProgress.propTypes = {}

export default UploadProgress
