/* eslint-disable camelcase */
import { createBundle } from 'core/bundler'
import { v4 as uuid } from 'uuid'

import {
  USER_LOGGED_OUT,
  LOGIN_REQUEST_STARTED,
  LOGIN_REQUEST_SUCCEEDED,
  LOGIN_REQUEST_FAILED,
  ACCOUNT_VERIFICATION_STARTED,
  ACCOUNT_VERIFICATION_SUCCEEDED,
  ACCOUNT_VERIFICATION_FAILED,
  TOKEN_RESEND_REQUEST_STARTED,
  TOKEN_RESEND_REQUEST_FAILED,
  TOKEN_RESEND_REQUEST_SUCCEEDED,
  REGISTRATION_REQUEST_STARTED,
  REGISTRATION_REQUEST_SUCCEEDED,
  REGISTRATION_REQUEST_FAILED,
  USER_INVITATION_REQUEST_STARTED,
  USER_INVITATION_REQUEST_FAILED,
  USER_INVITATION_REQUEST_SUCCEEDED,
  PASSWORD_VERIFICATION_REQUESTED,
  PASSWORD_VERIFICATION_CANCELED,
  PASSWORD_VERIFICATION_STARTED,
  PASSWORD_VERIFICATION_SUCCEEDED,
  PASSWORD_VERIFICATION_FAILED,
  PASSWORD_RECOVERY_REQUEST_STARTED,
  PASSWORD_RECOVERY_REQUEST_SUCCEEDED,
  PASSWORD_RECOVERY_REQUEST_FAILED,
  PASSWORD_RECOVERY_TOKEN_VALIDATION_STARTED,
  PASSWORD_RECOVERY_TOKEN_VALIDATION_SUCCEEDED,
  PASSWORD_RECOVERY_TOKEN_VALIDATION_FAILED,
  PASSWORD_RESET_REQUEST_STARTED,
  PASSWORD_RESET_REQUEST_SUCCEEDED,
  PASSWORD_RESET_REQUEST_FAILED,
  ROUTER_URL_REDIRECTED,
  ROUTER_URL_UPDATED,
} from 'core/actiontypes'

const name = 'registration-bundle'

const initialState = {
  registrationRequestID: null,
  registrationError: null,
  registrationSucceeded: false,
  verificationInProgress: false,
  verificationError: null,
  verificationSucceeded: false,
  registrationtokenRefreshInProgress: false,
  registrationtokenRefreshError: null,
  registrationtokenRefreshSucceeded: false,
  userInviteInProgress: false,
  userInviteError: null,
  userInviteSucceeded: false,
  passwordRecoveryRequestInProgress: false,
  passwordRecoveryRequestSucceeded: false,
  passwordRecoveryRequestError: null,
  recoveryTokenValidationInProgress: false,
  recoveryTokenValidationSucceeded: false,
  recoveryTokenValidationError: null,
  recoveryToken: null,
  passwordResetRequestInProgress: false,
  passwordResetRequestSucceeded: false,
  passwordResetRequestError: null,
}

const selectRegistrationSucceeded = root => root[name].registrationSucceeded
const selectRegistrationError = root => root[name].registrationError
const selectRegistrationInProgress = root =>
  !root[name].registrationSucceeded &&
  !root[name].registrationError &&
  root[name].registrationRequestID

const selectVerificationSucceeded = root => root[name].verificationSucceeded
const selectVerificationError = root => root[name].verificationError
const selectVerificationInProgress = root => root[name].verificationInProgress

const selectTokenRefreshSucceeded = root =>
  root[name].registrationtokenRefreshSucceeded
const selectTokenRefreshError = root => root[name].registrationtokenRefreshError
const selectTokenRefreshInProgress = root =>
  root[name].registrationtokenRefreshInProgress

const selectUserInviteSucceeded = root => root[name].userInviteSucceeded
const selectUserInviteError = root => root[name].userInviteError
const selectUserInviteInProgress = root => root[name].userInviteInProgress

// Request password recovery token to be set to email
const selectPasswordRecoveryRequestInProgress = root =>
  root[name].passwordRecoveryRequestInProgress
const selectPasswordRecoveryRequestSucceeded = root =>
  root[name].passwordRecoveryRequestSucceeded
const selectPasswordRecoveryRequestError = root =>
  root[name].passwordRecoveryRequestError

// Validate password recovery token
const selectRecoveryTokenValidationInProgress = root =>
  root[name].recoveryTokenValidationInProgress
const selectRecoveryTokenValidationSucceeded = root =>
  root[name].recoveryTokenValidationSucceeded
const selectRecoveryTokenValidationError = root =>
  root[name].recoveryTokenValidationError
const selectPasswordRecoveryToken = root => root[name].recoveryToken

// Reset password
const selectPasswordResetInProgress = root =>
  root[name].passwordResetRequestInProgress
const selectPasswordResetSucceeded = root => root[name].passwordResetRequestSucceeded
const selectPasswordResetError = root => root[name].passwordResetRequestError

const reactRegistrationSucceeded = root => {
  const { registrationRequestID, registrationSucceeded } = root[name]
  if (registrationRequestID && registrationSucceeded) {
    return (dispatch, { matchPath }) => {
      const result = matchPath('/signup')
      if (result)
        dispatch({
          type: ROUTER_URL_REDIRECTED,
          payload: '/confirm',
        })
    }
  }
  return null
}

export default config =>
  createBundle({
    name,
    reducer: (state = initialState, { type, payload, error }) => {
      const { registrationRequestID } = state
      const requestMatch = registrationRequestID === payload
      if (type === REGISTRATION_REQUEST_STARTED) {
        return {
          ...state,
          registrationRequestID: payload,
          registrationSucceeded: null,
          registrationError: null,
        }
      }
      if (type === REGISTRATION_REQUEST_SUCCEEDED && requestMatch) {
        return {
          ...state,
          registrationSucceeded: payload,
          registrationError: null,
        }
      }
      if (type === REGISTRATION_REQUEST_FAILED && requestMatch) {
        return {
          ...state,
          registrationError: error,
        }
      }
      if (
        registrationRequestID &&
        (type === ROUTER_URL_UPDATED || type === ROUTER_URL_REDIRECTED)
      ) {
        return {
          ...state,
          registrationRequestID: null,
        }
      }
      if (type === ACCOUNT_VERIFICATION_STARTED) {
        return {
          ...state,
          verificationInProgress: true,
          verificationSucceeded: false,
          verificationError: null,
        }
      }

      if (type === ACCOUNT_VERIFICATION_SUCCEEDED) {
        return {
          ...state,
          verificationInProgress: false,
          verificationSucceeded: true,
          verificationError: null,
        }
      }

      if (type === ACCOUNT_VERIFICATION_FAILED) {
        return {
          ...state,
          verificationInProgress: false,
          verificationError: error,
        }
      }
      if (type === TOKEN_RESEND_REQUEST_STARTED) {
        return {
          ...state,
          registrationtokenRefreshInProgress: true,
          registrationtokenRefreshSucceeded: false,
          registrationtokenRefreshError: null,
        }
      }

      if (type === TOKEN_RESEND_REQUEST_SUCCEEDED) {
        return {
          ...state,
          registrationtokenRefreshInProgress: false,
          registrationtokenRefreshSucceeded: true,
          registrationtokenRefreshError: null,
        }
      }

      if (type === TOKEN_RESEND_REQUEST_FAILED) {
        return {
          ...state,
          registrationtokenRefreshInProgress: false,
          registrationtokenRefreshError: error,
        }
      }
      if (type === USER_INVITATION_REQUEST_STARTED) {
        return {
          ...state,
          userInviteInProgress: true,
          userInviteSucceeded: false,
          userInviteError: null,
        }
      }

      if (type === USER_INVITATION_REQUEST_SUCCEEDED) {
        return {
          ...state,
          userInviteInProgress: false,
          userInviteSucceeded: true,
          userInviteError: null,
        }
      }

      if (type === USER_INVITATION_REQUEST_FAILED) {
        return {
          ...state,
          userInviteInProgress: false,
          userInviteError: error,
        }
      }
      if (type === PASSWORD_RECOVERY_REQUEST_STARTED) {
        return {
          ...state,
          passwordRecoveryRequestInProgress: true,
          passwordRecoveryRequestSucceeded: false,
          passwordRecoveryRequestError: false,
        }
      }
      if (type === PASSWORD_RECOVERY_REQUEST_SUCCEEDED) {
        return {
          ...state,
          passwordRecoveryRequestInProgress: false,
          passwordRecoveryRequestSucceeded: true,
          passwordRecoveryRequestError: false,
        }
      }

      if (type === PASSWORD_RECOVERY_REQUEST_FAILED) {
        return {
          ...state,
          passwordRecoveryRequestInProgress: false,
          passwordRecoveryRequestError: error,
        }
      }
      if (type === PASSWORD_RECOVERY_TOKEN_VALIDATION_STARTED) {
        return {
          ...state,
          recoveryToken: null,
          recoveryTokenValidationInProgress: true,
          recoveryTokenValidationSucceeded: false,
          recoveryTokenValidationError: null,
        }
      }
      if (type === PASSWORD_RECOVERY_TOKEN_VALIDATION_SUCCEEDED) {
        return {
          ...state,
          recoveryToken: payload,
          recoveryTokenValidationInProgress: false,
          recoveryTokenValidationSucceeded: true,
          recoveryTokenValidationError: null,
        }
      }
      if (type === PASSWORD_RECOVERY_TOKEN_VALIDATION_FAILED) {
        return {
          ...state,
          recoveryTokenValidationInProgress: false,
          recoveryTokenValidationError: error,
        }
      }
      if (type === PASSWORD_RESET_REQUEST_STARTED) {
        return {
          ...state,
          passwordResetRequestInProgress: true,
          passwordResetRequestSucceeded: false,
          passwordResetRequestError: null,
        }
      }

      if (type === PASSWORD_RESET_REQUEST_SUCCEEDED) {
        return {
          ...state,
          passwordResetRequestInProgress: false,
          passwordResetRequestSucceeded: true,
          passwordResetRequestError: null,
        }
      }

      if (type === PASSWORD_RESET_REQUEST_FAILED) {
        return {
          ...state,
          passwordResetRequestInProgress: false,
          passwordResetRequestError: error,
        }
      }
      return state
    },
    selectors: {
      selectRegistrationInProgress,
      selectRegistrationSucceeded,
      selectRegistrationError,
      selectVerificationInProgress,
      selectVerificationSucceeded,
      selectVerificationError,
      selectTokenRefreshInProgress,
      selectTokenRefreshSucceeded,
      selectTokenRefreshError,
      selectUserInviteInProgress,
      selectUserInviteSucceeded,
      selectUserInviteError,
      selectPasswordRecoveryRequestInProgress,
      selectPasswordRecoveryRequestSucceeded,
      selectPasswordRecoveryRequestError,
      selectRecoveryTokenValidationInProgress,
      selectRecoveryTokenValidationSucceeded,
      selectRecoveryTokenValidationError,
      selectPasswordRecoveryToken,
      selectPasswordResetInProgress,
      selectPasswordResetSucceeded,
      selectPasswordResetError,
      reactRegistrationSucceeded,
    },
    actions: {
      register:
        ({ email, username, password, organization, firstName, lastName, token }) =>
        (dispatch, { api }) => {
          const data = {
            email,
            username,
            password,
            organization,
            first_name: firstName,
            last_name: lastName,
          }
          if (token) data.token = token
          const requestID = uuid()
          dispatch({ type: REGISTRATION_REQUEST_STARTED, payload: requestID })
          api('/register', {
            method: 'POST',
            body: data,
            credentials: 'omit',
          })
            .then(() => {
              dispatch({ type: REGISTRATION_REQUEST_SUCCEEDED, payload: requestID })
            })
            .catch(error => {
              dispatch({
                type: REGISTRATION_REQUEST_FAILED,
                payload: requestID,
                error,
              })
            })
        },
      confirmAccount:
        token =>
        (dispatch, { api }) => {
          dispatch({ type: ACCOUNT_VERIFICATION_STARTED })
          api('/register/confirm', {
            method: 'POST',
            body: {
              token,
            },
            credentials: 'omit',
          })
            .then(data => {
              dispatch({ type: ACCOUNT_VERIFICATION_SUCCEEDED })
            })
            .catch(error => {
              dispatch({ type: ACCOUNT_VERIFICATION_FAILED, error })
            })
        },
      requestVerificationToken:
        (token, email) =>
        (dispatch, { api }) => {
          const data = {}
          if (token) data.token = token
          if (email) data.email = email
          dispatch({ type: TOKEN_RESEND_REQUEST_STARTED })
          api('/register/resend', {
            method: 'POST',
            body: data,
            credentials: 'omit',
          })
            .then(() => {
              dispatch({ type: TOKEN_RESEND_REQUEST_SUCCEEDED })
            })
            .catch(error => {
              dispatch({ type: TOKEN_RESEND_REQUEST_FAILED, error })
            })
        },
      sendUserInvite:
        email =>
        (dispatch, { api }) => {
          dispatch({ type: USER_INVITATION_REQUEST_STARTED })
          api('/users/invite', {
            method: 'POST',
            body: {
              email,
            },
          })
            .then(() => dispatch({ type: USER_INVITATION_REQUEST_SUCCEEDED }))
            .catch(e => dispatch({ type: USER_INVITATION_REQUEST_FAILED, error: e }))
        },
      requestPasswordRecovery:
        email =>
        (dispatch, { api, history }) => {
          dispatch({ type: PASSWORD_RECOVERY_REQUEST_STARTED })
          history.push('/password-reset')
          api('/auth/password-recovery', {
            method: 'POST',
            body: {
              email,
            },
          })
            .then(data => {
              dispatch({ type: PASSWORD_RECOVERY_REQUEST_SUCCEEDED })
            })
            .catch(err => {
              dispatch({ type: PASSWORD_RECOVERY_REQUEST_FAILED, error: err })
            })
        },
      validateRecoveryToken:
        token =>
        (dispatch, { api }) => {
          dispatch({ type: PASSWORD_RECOVERY_TOKEN_VALIDATION_STARTED })
          api(`/auth/password-recovery/${token}`)
            .then(data => {
              dispatch({
                type: PASSWORD_RECOVERY_TOKEN_VALIDATION_SUCCEEDED,
                payload: token,
              })
            })
            .catch(err => {
              dispatch({
                type: PASSWORD_RECOVERY_TOKEN_VALIDATION_FAILED,
                error: err,
              })
            })
        },
      resetPassword:
        (password, token) =>
        (dispatch, { api }) => {
          dispatch({ type: PASSWORD_RESET_REQUEST_STARTED })
          api(`/auth/password-recovery/${token}`, {
            method: 'PATCH',
            body: {
              password,
            },
          })
            .then(data => {
              dispatch({ type: PASSWORD_RESET_REQUEST_SUCCEEDED })
            })
            .catch(error => {
              dispatch({ type: PASSWORD_RESET_REQUEST_FAILED })
            })
        },
    },
    init: null,
    args: null,
    persist: null,
    middleware: null,
  })
