import React, { useCallback } from 'react'
import { useStore } from 'core'

import './style.scss'

import Icon from 'components/Icon'

const classes = {
  wrapper: 'ScoringMenuButton-wrapper',
}

const ScoringMenuButton = props => {
  const { activeScoring, openScoringWizard, closeScoringWizard } = useStore(
    'selectActiveScoring',
    'openScoringWizard',
    'closeScoringWizard'
  )
  const { id, open, title } = activeScoring

  const scoringWizardToggle = useCallback(() => {
    if (open) closeScoringWizard()
    else openScoringWizard()
  }, [open, closeScoringWizard, openScoringWizard])

  if (!id) return null
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      // eslint-disable-next-line no-script-url
      href="javascript:void(0)"
      className={classes.wrapper}
      data-active={open}
      onClick={scoringWizardToggle}
    >
      <Icon type="rect" />
      <span>{title || 'Scoring Wizard'}</span>
    </a>
  )
}

export default ScoringMenuButton
