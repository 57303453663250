import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'core'

import { StaticDatasourcesPage, DynamicDatasourcesPage } from 'pages/Datasources'

const DatasourcesRouter = props => {
  const { match } = props

  return (
    <Switch>
      <Redirect
        path={`${match.path}`}
        to={({ match: route }) => {
          return `${route.url}/static`
        }}
      />
      <Route path={`${match.path}/static`} component={StaticDatasourcesPage} />
      <Route path={`${match.path}/dynamic`} component={DynamicDatasourcesPage} />
      <Route
        render={({ location: loc }) => {
          return (
            <pre>{`  404 Page Not Found\n\n  The page under "${loc.pathname}" does not exist.`}</pre>
          )
        }}
      />
    </Switch>
  )
}

DatasourcesRouter.propTypes = {
  match: PropTypes.exact({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.object,
    isExact: PropTypes.bool,
  }),
}

export default DatasourcesRouter
