/* eslint-disable no-shadow */
import { createSelector } from 'create-selector'
import { createBundle } from 'core/bundler'
import {
  SCORINGS_FETCH_STARTED,
  SCORINGS_FETCH_SUCCEEDED,
  SCORINGS_FETCH_FAILED,
  SCORINGS_CLEARED,
  SCORINGS_OUTDATED,
  SCORINGS_EXPIRED,
  USER_LOGGED_OUT,
  SCORING_SELECTED,
  SCORED_DATASET_SELECTED,
} from 'core/actiontypes'

const name = 'scorings' // e.g notification, trainings, users
const uCaseName = name.charAt(0).toUpperCase() + name.slice(1)
const defaultConfig = {
  apiRoute: '/scorings',
  stale: 900000, // fifteen minutes
  retry: 60000, // one minute,
  expireAfter: Infinity,
  persist: false,
  // checkIfOnline: true,
}

const initialState = {
  data: null,
  errorTimes: [],
  error: null,
  lastSuccess: null,
  isOutdated: false,
  isLoading: false,
  isExpired: false,
  failedPermanently: false,
  selectedScoring: null,
  selectedScoredDataset: null,
}

const singularString = uCaseName.slice(0, uCaseName.length - 1)

const rawSelector = `select${uCaseName}Raw`
const dataSelector = `select${uCaseName}`
const selectedScoringSelector = `selectSelected${singularString}`
const selectedScoredDatasetSelector = 'selectSelectedScoredDataset'
const lastSuccessSelector = `select${uCaseName}LastSuccess`
const isExpiredSelector = `select${uCaseName}IsExpired`
const lastErrorSelector = `select${uCaseName}LastError`
const isStaleSelector = `select${uCaseName}IsStale`
const isWaitingToRetrySelector = `select${uCaseName}IsWaitingToRetry`
const isLoadingSelector = `select${uCaseName}IsLoading`
const failedPermanentlySelector = `select${uCaseName}FailedPermanently`
const shouldUpdateSelector = `select${uCaseName}ShouldUpdate`

const fetchSuccess = payload => ({ type: SCORINGS_FETCH_SUCCEEDED, payload })
const fetchError = error => ({ type: SCORINGS_FETCH_FAILED, error })

export default config => {
  // eslint-disable-next-line no-param-reassign
  config = { ...defaultConfig, ...config }
  const markDataAsOutdated = () => ({ type: SCORINGS_OUTDATED })
  const clearData = () => ({ type: SCORINGS_CLEARED })
  const expireData = () => ({ type: SCORINGS_EXPIRED })
  const fetchData =
    () =>
    (dispatch, { api }) => {
      dispatch({ type: SCORINGS_FETCH_STARTED })
      return api(config.apiRoute).then(
        payload => {
          // dispatch(fetchSuccess([...payload.owned]))
          dispatch(fetchSuccess(payload))
        },
        error => {
          dispatch(fetchError(error))
        }
      )
    }
  const removeScoring =
    ({ id }) =>
    (dispatch, { api }) =>
      api(`/scorings/${id}`, { method: 'DELETE' }).then(() =>
        dispatch(markDataAsOutdated())
      )
  const editScoring =
    payload =>
    (dispatch, { api }) =>
      api(`/scorings/${payload.id}`, {
        method: 'PATCH',
        body: { ...payload },
      }).then(() => dispatch(markDataAsOutdated()))
  const deleteScoredDataset =
    id =>
    (dispatch, { api }) =>
      api(`/scorings/${id}`, { method: 'DELETE' }).then(() =>
        dispatch(markDataAsOutdated())
      )
  const setSelectedScoring = payload => ({ type: SCORING_SELECTED, payload })
  const setSelectedScoredDataset = payload => ({
    type: SCORED_DATASET_SELECTED,
    payload,
  })

  const selectors = {
    [rawSelector]: state => state[name],
    [dataSelector]: createSelector(rawSelector, root => root.data),
    [selectedScoringSelector]: createSelector(
      rawSelector,
      state => state.selectedScoring
    ),
    [selectedScoredDatasetSelector]: createSelector(
      rawSelector,
      state => state.selectedScoredDataset
    ),
    [isStaleSelector]: createSelector(
      rawSelector, // raw - everything
      lastSuccessSelector, //  ???
      'selectAppTime',
      (state, time, appTime) => {
        if (state.isOutdated) {
          return true
        }
        if (!time) {
          return false
        }
        return appTime - time > config.stale
      }
    ),
    [isExpiredSelector]: createSelector(rawSelector, root => root.isExpired),
    [lastErrorSelector]: createSelector(
      rawSelector,
      resource => resource.errorTimes.slice(-1)[0] || null
    ),
    [lastSuccessSelector]: createSelector(rawSelector, root => root.lastSuccess),
    [isWaitingToRetrySelector]: createSelector(
      lastErrorSelector,
      'selectAppTime',
      (time, appTime) => {
        if (!time) {
          return false
        }
        return appTime - time < config.retry
      }
    ),
    [isLoadingSelector]: createSelector(
      rawSelector,
      resourceState => resourceState.isLoading
    ),
    [failedPermanentlySelector]: createSelector(
      rawSelector,
      resourceState => resourceState.failedPermanently
    ),
    [shouldUpdateSelector]: createSelector(
      isLoadingSelector,
      failedPermanentlySelector,
      isWaitingToRetrySelector,
      dataSelector,
      isStaleSelector,
      // 'selectIsOnline',
      (
        isLoading,
        failedPermanently,
        isWaitingToRetry,
        data,
        isStale
        // isOnline
      ) => {
        if (
          // (checkIfOnline && !isOnline) ||
          isLoading ||
          failedPermanently ||
          isWaitingToRetry
        ) {
          return false
        }
        if (data === null) {
          return true
        }
        return isStale
      }
    ),
    reactMissingScorings: createSelector(
      'selectUser',
      shouldUpdateSelector,
      (user, update) => {
        if (update && user) {
          return fetchData()
        }
        return null
      }
    ),
    reactSelectedScoringNonExistent: createSelector(
      selectedScoringSelector,
      dataSelector,
      (selected, data) => {
        if (selected && !data?.find(dataset => dataset.id === selected)) {
          return setSelectedScoring(null)
        }
        return null
      }
    ),
    reactSelectedScoredDatasetNonExistent: createSelector(
      selectedScoringSelector,
      selectedScoredDatasetSelector,
      dataSelector,
      (selectedScoring, selectedScoredDataset, data) => {
        if (selectedScoredDataset) {
          if (!selectedScoring) return setSelectedScoredDataset(null)
          if (
            !data
              ?.find(scoring => scoring.id === selectedScoring)
              ?.datasets?.find(({ id }) => id === selectedScoredDataset)
          )
            return setSelectedScoredDataset(null)
        }
        return null
      }
    ),
  }

  if (config.expireAfter !== Infinity) {
    selectors[`reactExpire${uCaseName}`] = createSelector(
      lastSuccessSelector,
      'selectAppTime',
      (time, appTime) => {
        if (!time) {
          return null
        }
        if (appTime - time > config.expireAfter) {
          return expireData()
        }
        return null
      }
    )
  }

  return createBundle({
    name,
    reducer: (state = initialState, { type, payload, error, merge }) => {
      if (type === SCORINGS_FETCH_STARTED) {
        return { ...state, isLoading: true }
      }
      if (type === SCORINGS_FETCH_SUCCEEDED) {
        // const updatedData = merge ? { ...state.data, ...payload } : payload
        const updatedData = payload.scorings.map(scoring => ({
          ...scoring,
          datasets: payload.scored_datasets.filter(
            dataset => dataset.scoring_id === scoring.id
          ),
        }))
        return {
          ...state,
          isLoading: false,
          data: updatedData,
          lastSuccess: Date.now(),
          errorTimes: [],
          error: null,
          failedPermanently: false,
          isOutdated: false,
          isExpired: false,
        }
      }
      if (type === SCORINGS_FETCH_FAILED) {
        const errorMessage = (error && error.message) || error
        return {
          ...state,
          isLoading: false,
          errorTimes: [...state.errorTimes, Date.now()],
          error: errorMessage,
          failedPermanently: !!(error && error.permanent),
        }
      }
      if (type === SCORINGS_CLEARED || type === USER_LOGGED_OUT) {
        return initialState
      }
      if (type === SCORINGS_EXPIRED) {
        return {
          ...initialState,
          isExpired: true,
          errorTimes: state.errorTimes,
          error: state.error,
        }
      }
      if (type === SCORINGS_OUTDATED) {
        return { ...state, isOutdated: true }
      }
      if (type === SCORING_SELECTED) {
        if (payload === null)
          return {
            ...state,
            selectedScoring: payload,
            selectedScoredDataset: payload,
          }
        return {
          ...state,
          selectedScoring: payload,
          selectedScoredDataset:
            payload === state.selectedScoring ? state.selectedScoredDataset : null,
        }
      }
      if (type === SCORED_DATASET_SELECTED) {
        if (payload !== null) {
          const tempScoring = state.data.find(
            scoring => !!scoring.datasets.find(({ id }) => id === payload)
          )
          return {
            ...state,
            selectedScoring: tempScoring.id,
            selectedScoredDataset: payload,
          }
        }
        return { ...state, selectedScoredDataset: payload }
      }
      return state
    },
    selectors,
    actions: {
      [`fetch${uCaseName}`]: fetchData,
      [`mark${uCaseName}AsOutdated`]: markDataAsOutdated,
      [`clear${uCaseName}`]: clearData,
      [`expire${uCaseName}`]: expireData,
      removeScoring,
      editScoring,
      deleteScoredDataset,
      setSelectedScoring,
      setSelectedScoredDataset,
    },
    init: store => {
      store.serverEvent('SCORING_STARTED', ({ data }, store) => {
        store.markScoringsAsOutdated()
      })
      store.serverEvent('SCORING_FINISHED', ({ data }, store) => {
        store.markScoringsAsOutdated()
      })
      store.serverEvent('SCORING_FAILED', ({ data }, store) => {
        store.markScoringsAsOutdated()
      })
    },
    args: null,
    middleware: null,
    persist: config.persist
      ? [
          SCORINGS_FETCH_SUCCEEDED,
          SCORINGS_EXPIRED,
          SCORINGS_OUTDATED,
          SCORINGS_CLEARED,
        ]
      : null,
  })
}
