import React, { forwardRef, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { useStore } from 'core'

import Modal from 'components/Modal'
import { Form, FormItem } from 'components/Form'
import { Radio, RadioGroup, Typography, FormControlLabel } from '@material-ui/core'

import './style.scss'

const classes = {
  wrapper: 'DeleteScoringModal-wrapper',
  formDiv: 'DeleteScoringModal-formDiv',
}

const DeleteScoringModal = forwardRef((props, ref) => {
  const formRef = useRef()
  const { deleteScoredDataset, removeScoring } = useStore(
    'deleteScoredDataset',
    'removeScoring'
  )
  const { selectedScoring = {} } = props

  const datasets = selectedScoring.datasets?.map(({ id }) => id)

  return (
    <Modal
      ref={ref}
      title="Do you want to delete this scoring?"
      cancelText="No"
      okText="Yes"
      onOk={() => {
        ref.current.close()
        const radio = formRef.current.get()?.radio
        if (radio === 'both') {
          datasets.forEach(id => deleteScoredDataset(id))
          removeScoring(selectedScoring.id)
        } else if (radio === 'datasets')
          datasets.forEach(id => deleteScoredDataset(id))
      }}
      onCancel={() => ref.current.close()}
      onClose={() => ref.current.close()}
      footer
    >
      <div className={classes.formDiv}>
        <Form ref={formRef} preload={selectedScoring}>
          <FormItem
            dataKey="radio"
            defaultValue="both"
            margin="dense"
            style={{ flexDirection: 'column' }}
            component={RadioGroup}
          >
            <FormControlLabel
              value="both"
              control={<Radio size="small" color="default" />}
              label="Delete both scoring and all of its scored datasets"
            />
            <FormControlLabel
              value="datasets"
              control={<Radio size="small" color="default" />}
              label="Delete only the scored datasets"
            />
          </FormItem>
        </Form>
        <Typography>
          This action will permanently delete this prediction result.
        </Typography>
      </div>
    </Modal>
  )
})

DeleteScoringModal.propTypes = {
  selectedScoring: PropTypes.object,
}

export default DeleteScoringModal
