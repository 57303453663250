import { createSelector } from 'create-selector'
import { createBundle } from 'core/bundler'
import {
  TRANSFORMATIONS_FETCH_STARTED,
  TRANSFORMATIONS_FETCH_SUCCEEDED,
  TRANSFORMATIONS_FETCH_FAILED,
  TRANSFORMATIONS_CLEARED,
  TRANSFORMATIONS_OUTDATED,
  TRANSFORMATIONS_EXPIRED,
  USER_LOGGED_OUT,
  TRANSFORMATION_SELECTED,
} from 'core/actiontypes'

const name = 'transformations' // e.g notification, trainings, users
const uCaseName = name.charAt(0).toUpperCase() + name.slice(1)
const defaultConfig = {
  apiRoute: '/transformations',
  stale: 900000, // fifteen minutes
  retry: 60000, // one minute,
  expireAfter: Infinity,
  persist: false,
  // checkIfOnline: true,
}

const initialState = {
  data: null,
  errorTimes: [],
  error: null,
  lastSuccess: null,
  isOutdated: false,
  isLoading: false,
  isExpired: false,
  failedPermanently: false,
  selectedTransformation: null,
}

const singularString = uCaseName.slice(0, uCaseName.length - 1)

const rawSelector = `select${uCaseName}Raw`
const dataSelector = `select${uCaseName}`
const selectedSelector = `selectSelected${singularString}`
const lastSuccessSelector = `select${uCaseName}LastSuccess`
const isExpiredSelector = `select${uCaseName}IsExpired`
const lastErrorSelector = `select${uCaseName}LastError`
const isStaleSelector = `select${uCaseName}IsStale`
const isWaitingToRetrySelector = `select${uCaseName}IsWaitingToRetry`
const isLoadingSelector = `select${uCaseName}IsLoading`
const failedPermanentlySelector = `select${uCaseName}FailedPermanently`
const shouldUpdateSelector = `select${uCaseName}ShouldUpdate`

const fetchSuccess = payload => ({ type: TRANSFORMATIONS_FETCH_SUCCEEDED, payload })
const fetchError = error => ({ type: TRANSFORMATIONS_FETCH_FAILED, error })

export default config => {
  // eslint-disable-next-line no-param-reassign
  config = { ...defaultConfig, ...config }
  const markDataAsOutdated = () => ({ type: TRANSFORMATIONS_OUTDATED })
  const clearData = () => ({ type: TRANSFORMATIONS_CLEARED })
  const expireData = () => ({ type: TRANSFORMATIONS_EXPIRED })
  const fetchData =
    () =>
    (dispatch, { api }) => {
      dispatch({ type: TRANSFORMATIONS_FETCH_STARTED })
      return api(config.apiRoute).then(
        payload => {
          // dispatch(fetchSuccess([...payload.owned]))
          dispatch(fetchSuccess(payload))
        },
        error => {
          dispatch(fetchError(error))
        }
      )
    }
  const setSelectedTransformation = payload => ({
    type: TRANSFORMATION_SELECTED,
    payload,
  })

  const selectors = {
    [rawSelector]: state => state[name],
    [dataSelector]: createSelector(rawSelector, root => root.data),
    [selectedSelector]: createSelector(
      rawSelector,
      state => state.selectedTransformation
    ),
    [isStaleSelector]: createSelector(
      rawSelector, // raw - everything
      lastSuccessSelector, //  ???
      'selectAppTime',
      (state, time, appTime) => {
        if (state.isOutdated) {
          return true
        }
        if (!time) {
          return false
        }
        return appTime - time > config.stale
      }
    ),
    [isExpiredSelector]: createSelector(rawSelector, root => root.isExpired),
    [lastErrorSelector]: createSelector(
      rawSelector,
      resource => resource.errorTimes.slice(-1)[0] || null
    ),
    [lastSuccessSelector]: createSelector(rawSelector, root => root.lastSuccess),
    [isWaitingToRetrySelector]: createSelector(
      lastErrorSelector,
      'selectAppTime',
      (time, appTime) => {
        if (!time) {
          return false
        }
        return appTime - time < config.retry
      }
    ),
    [isLoadingSelector]: createSelector(
      rawSelector,
      resourceState => resourceState.isLoading
    ),
    [failedPermanentlySelector]: createSelector(
      rawSelector,
      resourceState => resourceState.failedPermanently
    ),
    [shouldUpdateSelector]: createSelector(
      isLoadingSelector,
      failedPermanentlySelector,
      isWaitingToRetrySelector,
      dataSelector,
      isStaleSelector,
      // 'selectIsOnline',
      (
        isLoading,
        failedPermanently,
        isWaitingToRetry,
        data,
        isStale
        // isOnline
      ) => {
        if (
          // (checkIfOnline && !isOnline) ||
          isLoading ||
          failedPermanently ||
          isWaitingToRetry
        ) {
          return false
        }
        if (data === null) {
          return true
        }
        return isStale
      }
    ),
    reactMissingTransformations: createSelector(
      'selectUser',
      shouldUpdateSelector,
      (user, update) => {
        if (update && user) {
          return fetchData()
        }
        return null
      }
    ),
    reactSelectedTransformationNonExistent: createSelector(
      selectedSelector,
      dataSelector,
      (selected, data) => {
        if (
          selected &&
          !data?.find(transformation => transformation.id === selected)
        ) {
          return setSelectedTransformation(null)
        }
        return null
      }
    ),
  }

  if (config.expireAfter !== Infinity) {
    selectors[`reactExpire${uCaseName}`] = createSelector(
      lastSuccessSelector,
      'selectAppTime',
      (time, appTime) => {
        if (!time) {
          return null
        }
        if (appTime - time > config.expireAfter) {
          return expireData()
        }
        return null
      }
    )
  }

  return createBundle({
    name,
    reducer: (state = initialState, { type, payload, error, merge }) => {
      if (type === TRANSFORMATIONS_FETCH_STARTED) {
        return { ...state, isLoading: true }
      }
      if (type === TRANSFORMATIONS_FETCH_SUCCEEDED) {
        const updatedData = merge ? { ...state.data, ...payload } : payload
        return {
          ...state,
          isLoading: false,
          data: updatedData,
          lastSuccess: Date.now(),
          errorTimes: [],
          error: null,
          failedPermanently: false,
          isOutdated: false,
          isExpired: false,
        }
      }
      if (type === TRANSFORMATIONS_FETCH_FAILED) {
        const errorMessage = (error && error.message) || error
        return {
          ...state,
          isLoading: false,
          errorTimes: [...state.errorTimes, Date.now()],
          error: errorMessage,
          failedPermanently: !!(error && error.permanent),
        }
      }
      if (type === TRANSFORMATIONS_CLEARED || type === USER_LOGGED_OUT) {
        return initialState
      }
      if (type === TRANSFORMATIONS_EXPIRED) {
        return {
          ...initialState,
          isExpired: true,
          errorTimes: state.errorTimes,
          error: state.error,
        }
      }
      if (type === TRANSFORMATIONS_OUTDATED) {
        return { ...state, isOutdated: true }
      }
      if (type === TRANSFORMATION_SELECTED) {
        return { ...state, selectedTransformation: payload }
      }
      return state
    },
    selectors,
    actions: {
      [`fetch${uCaseName}`]: fetchData,
      [`mark${uCaseName}AsOutdated`]: markDataAsOutdated,
      [`clear${uCaseName}`]: clearData,
      [`expire${uCaseName}`]: expireData,
      setSelectedTransformation,
    },
    init: null,
    args: null,
    middleware: null,
    persist: config.persist
      ? [
          TRANSFORMATIONS_FETCH_SUCCEEDED,
          TRANSFORMATIONS_EXPIRED,
          TRANSFORMATIONS_OUTDATED,
          TRANSFORMATIONS_CLEARED,
        ]
      : null,
  })
}
