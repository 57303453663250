import React, { forwardRef, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { useStore } from 'core'

import Modal from 'components/Modal'
import { Form, FormItem } from 'components/Form'
import { TextField } from '@material-ui/core'

import './style.scss'

const classes = {
  wrapper: 'EditTransformationModal-wrapper',
  formDiv: 'EditTransformationModal-formDiv',
}

const EditTransformationModal = forwardRef((props, ref) => {
  const formRef = useRef()
  const { saveTransformation } = useStore('saveTransformation')
  const { selectedTransformation = {} } = props

  return (
    <Modal
      className={classes.wrapper}
      ref={ref}
      title={selectedTransformation?.name}
      okText="Save"
      onOk={() => {
        const data = formRef.current.get()
        ref.current.close()
        saveTransformation({
          id: selectedTransformation.id,
          name: data.name,
          description: data.description,
        })
      }}
      onCancel={() => ref.current.close()}
      onClose={() => ref.current.close()}
      footer
    >
      <div className={classes.formDiv}>
        <Form ref={formRef} preload={selectedTransformation}>
          <FormItem
            dataKey="name"
            defaultValue=""
            margin="dense"
            label="Name"
            fullWidth
            inputProps={{ autoFocus: true }}
            component={TextField}
          />
          <FormItem
            dataKey="description"
            defaultValue=""
            margin="dense"
            label="Description"
            fullWidth
            multiline
            component={TextField}
          />
        </Form>
      </div>
    </Modal>
  )
})

EditTransformationModal.propTypes = {
  selectedTransformation: PropTypes.object,
}

export default EditTransformationModal
