/* eslint-disable max-lines */
import React, { useRef, useCallback, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import { useInteractiveClick } from 'libs/interactive'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core'
import PopUpMultiSelect from 'components/PopUpMultiSelect'
import { Provider, Context } from './FilterBoxContext'

import { FILTER_FUNCTIONS, FEATURE_TYPES } from './stringConstants'

const FILTER_FUNCTION_FEATURE_TYPES = {
  [FILTER_FUNCTIONS.MISSING]: [
    FEATURE_TYPES.NUMERIC,
    FEATURE_TYPES.CATEGORY,
    FEATURE_TYPES.TEXT,
    FEATURE_TYPES.TIME,
  ],
  [FILTER_FUNCTIONS.CONTAINS_TEXT]: [FEATURE_TYPES.CATEGORY, FEATURE_TYPES.TEXT],
  [FILTER_FUNCTIONS.MISSING_TEXT]: [FEATURE_TYPES.CATEGORY, FEATURE_TYPES.TEXT],
  [FILTER_FUNCTIONS.NUMERIC_COMPARISON]: [FEATURE_TYPES.NUMERIC],
  [FILTER_FUNCTIONS.DATE_COMPARISON]: [FEATURE_TYPES.TIME],
}

const classes = {
  wrapper: 'FilterBox-config-content-wrapper',
  ruleGroupWrapper: 'FilterBox-config-content-RuleGroup-wrapper',
  ruleGroupHeader: 'FilterBox-config-content-RuleGroup-header',
  ruleWrapper: 'FilterBox-config-content-FilterRule-wrapper',
  ruleDescription: 'FilterBox-config-content-FilterRule-description',
  ruleApplication: 'FilterBox-config-content-FilterRule-application',
  ruleButtonDiv: 'FilterBox-config-content-FilterRule-buttonDiv',
  ruleButton: 'FilterBox-config-content-FilterRule-button',
  addRuleButtonsDiv: 'ConfigBox-addRuleButtonsDiv',
  addRuleButton: 'ConfigBox-addRuleButton',
}

const removeRule = (ruleConfig, ruleId) => {
  const tempConfig = { ...ruleConfig }
  const [tempRules, tempFound] = [...tempConfig.rules].reduce(
    (acc, cur) => {
      const result = [...acc[0]]
      let found = acc[1]
      if (!found) {
        if (cur.type === 'simple') {
          if (cur.id === ruleId) found = true
          else result.push(cur)
        } else if (cur.id === ruleId) found = true
        else {
          const [nested, nestedFound] = removeRule(cur, ruleId)
          if (nested?.rules?.length) {
            if (nested.rules.length > 1) result.push(nested)
            else result.push(nested.rules[0])
          }
          found = found || nestedFound
        }
      } else result.push(cur)
      return [result, found]
    },
    [[], false]
  )
  tempConfig.rules = tempRules
  return [tempConfig, tempFound]
}
const groupRule = (ruleConfig, rule, targetId) => {
  const [tempConfig] = removeRule(ruleConfig, rule.id)
  const recursive = conf => {
    const tempResult = [...conf.rules].reduce(
      (acc, cur) => {
        const result = [...acc[0]]
        let found = acc[1]
        if (!found) {
          if (cur.type === 'simple') {
            if (cur.id === targetId) {
              result.push({
                id: uuid(),
                type: 'nested',
                logic: 'or',
                rules: [cur, rule],
              })
              found = true
            } else result.push(cur)
          } else if (cur.id === targetId) {
            result.push({
              id: uuid(),
              type: 'nested',
              logic: 'or',
              rules: [cur, rule],
            })
            found = true
          } else {
            const [nested, nestedFound] = recursive(cur)
            result.push(nested)
            found = found || nestedFound
          }
        } else result.push(cur)
        return [result, found]
      },
      [[], false]
    )
    return [{ ...conf, rules: tempResult[0] }, tempResult[1]]
  }
  const groupedConfig = recursive(tempConfig)
  return groupedConfig[0]
}
const updateRule = (ruleConfig, rule) => {
  const tempConfig = { ...ruleConfig }
  const [tempRules, tempFound] = [...tempConfig.rules].reduce(
    (acc, cur) => {
      const result = [...acc[0]]
      let found = acc[1]
      if (!found) {
        if (cur.type === 'simple') {
          if (cur.id === rule.id) {
            result.push(rule)
            found = true
          } else result.push(cur)
        } else if (cur.id === rule.id) {
          result.push(rule)
          found = true
        } else {
          const [nested, nestedFound] = updateRule(cur, rule)
          if (nested?.rules?.length) {
            if (nested.rules.length > 1) result.push(nested)
            else result.push(nested.rules[0])
          }
          found = found || nestedFound
        }
      } else result.push(cur)
      return [result, found]
    },
    [[], false]
  )
  tempConfig.rules = tempRules
  return [tempConfig, tempFound]
}

const FilterRule = ({
  id,
  target,
  method,
  operator,
  value,
  features,
  meta: tempMeta = [],
  stackedBy,
  place,
  callback,
}) => {
  const { connecting } = useContext(Context)
  const rule = {
    id,
    type: 'simple',
    target,
    method,
    operator,
    value,
    feature_id: features,
  }
  const meta = tempMeta.filter(({ type: metaType, id: featureId }) =>
    target === 'column'
      ? featureId !== stackedBy
      : FILTER_FUNCTION_FEATURE_TYPES[method || FILTER_FUNCTIONS.MISSING].indexOf(
          metaType
        ) !== -1
  )
  if (value) rule.value = value

  const textError = !value
  //  = !(value || value === 0)

  const amIBeingConnected = connecting?.id === id
  const classAppendage = amIBeingConnected ? ' connecting' : ' available'

  const interactable = !!connecting && !amIBeingConnected && target !== 'column'

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`${classes.ruleWrapper}${
        connecting && target !== 'column' ? classAppendage : ''
      }`}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={interactable ? 0 : -1}
      type={interactable ? 'button' : ''}
      onClick={
        interactable
          ? event => {
              event.stopPropagation()
              // callback(id, 'nest')
              callback('group', rule)
            }
          : null
      }
      onKeyDown={
        interactable
          ? event => {
              if (event.keyCode === 13) {
                event.stopPropagation()
                event.preventDefault()
                callback('group', rule)
                // callback(id, 'nest')
              }
            }
          : null
      }
    >
      <div className={classes.ruleDescription}>
        <span>{target === 'column' ? 'Remove column' : 'Remove rows that'}</span>
        <Conditional dependencies={target === 'row'}>
          <Conditional
            dependencies={
              method === FILTER_FUNCTIONS.CONTAINS_TEXT ||
              method === FILTER_FUNCTIONS.MISSING_TEXT
            }
          >
            <TextField
              className="method"
              disabled={!!connecting}
              select
              value={method}
              onChange={event => {
                callback('update', { ...rule, method: event.target.value })
              }}
              margin="dense"
              fullWidth
            >
              <MenuItem value={FILTER_FUNCTIONS.CONTAINS_TEXT}>contain</MenuItem>
              <MenuItem value={FILTER_FUNCTIONS.MISSING_TEXT}>
                don&#39;t contain
              </MenuItem>
            </TextField>
            <TextField
              className="text"
              disabled={!!connecting}
              error={!connecting && textError}
              value={value}
              onChange={event => {
                callback('update', { ...rule, value: event.target.value })
              }}
              onFocus={event => {
                event.target.select()
              }}
              margin="dense"
              fullWidth
            />
            <FormControlLabel
              className="whole"
              disabled={!!connecting}
              label="whole word"
              checked={operator === 'ww'}
              onChange={event => {
                callback('update', {
                  ...rule,
                  operator: event.target.checked ? 'ww' : '',
                })
              }}
              margin="dense"
              control={<Checkbox color="default" />}
            />
          </Conditional>
          <Conditional dependencies={method === FILTER_FUNCTIONS.MISSING}>
            <span>are</span>
            <TextField
              className="invalid"
              disabled={!!connecting}
              select
              value={operator}
              onChange={event => {
                callback('update', { ...rule, operator: event.target.value })
              }}
              margin="dense"
              fullWidth
            >
              <MenuItem value="missing">missing</MenuItem>
              <MenuItem value="invalid">invalid</MenuItem>
              <MenuItem value="both">missing or invalid</MenuItem>
            </TextField>
          </Conditional>
          <Conditional dependencies={method === FILTER_FUNCTIONS.NUMERIC_COMPARISON}>
            <span>are</span>
            <TextField
              className="numeric"
              disabled={!!connecting}
              select
              value={operator}
              onChange={event => {
                callback('update', { ...rule, operator: event.target.value })
              }}
              margin="dense"
              fullWidth
            >
              <MenuItem value="lt">&#60;</MenuItem>
              <MenuItem value="lte">&#60;&#61;</MenuItem>
              <MenuItem value="eq">&#61;</MenuItem>
              <MenuItem value="gte">&#62;&#61;</MenuItem>
              <MenuItem value="gt">&#62;</MenuItem>
            </TextField>
            <TextField
              className="numeric-value"
              disabled={!!connecting}
              value={value}
              type="number"
              onChange={event => {
                callback('update', { ...rule, value: Number(event.target.value) })
              }}
              onFocus={event => {
                event.target.select()
              }}
              margin="dense"
              fullWidth
            />
          </Conditional>
          <Conditional dependencies={method === FILTER_FUNCTIONS.DATE_COMPARISON}>
            <span>are</span>
            <TextField
              className="date"
              disabled={!!connecting}
              select
              value={operator?.slice(0, 2) || 'eq'}
              onChange={event => {
                callback('update', { ...rule, operator: event.target.value })
              }}
              margin="dense"
              fullWidth
            >
              <MenuItem value="lt">before</MenuItem>
              <MenuItem value="eq">exactly</MenuItem>
              <MenuItem value="gt">after</MenuItem>
            </TextField>
            <TextField
              className="date-value"
              disabled={!!connecting}
              value={value}
              type="datetime-local"
              InputProps={{
                inputProps: { step: 1 },
              }}
              onChange={event => {
                callback('update', { ...rule, value: event.target.value })
              }}
              margin="dense"
              fullWidth
            />
            <Conditional
              dependencies={
                method === FILTER_FUNCTIONS.DATE_COMPARISON && operator !== 'eq'
              }
            >
              <FormControlLabel
                className="including"
                disabled={!!connecting}
                label="Including"
                checked={operator?.slice(-1) === 'e'}
                onChange={event => {
                  callback('update', {
                    ...rule,
                    operator:
                      (event.target.checked && `${operator}e`) ||
                      operator.slice(0, -1),
                  })
                }}
                margin="dense"
                control={<Checkbox color="default" />}
              />
            </Conditional>
          </Conditional>
        </Conditional>
      </div>
      <div className={classes.ruleApplication}>
        <PopUpMultiSelect
          disabled={!!connecting}
          error={!connecting && !features.length}
          label="Apply to"
          margin="dense"
          fullWidth
          value={JSON.stringify(features)}
          onChange={e => {
            callback('update', {
              ...rule,
              feature_id: JSON.parse(e).sort((a, b) => a - b),
            })
          }}
        >
          {meta.map(feature => (
            <MenuItem key={feature.id} value={feature.id}>
              {feature.name}
            </MenuItem>
          ))}
        </PopUpMultiSelect>
      </div>
      <Conditional dependencies={place === 'inner' && target !== 'column'}>
        <button
          className={`${classes.ruleButton}${connecting ? ' disabled' : ''} small`}
          // className={`${classes.ruleButton}${connecting ? ' disabled' : ''}`}
          disabled={!!connecting}
          type="button"
          onClick={event => {
            event.stopPropagation()
            callback('ungroup', rule)
          }}
          onKeyDown={event => {
            if (event.keyCode === 13) {
              event.stopPropagation()
              event.preventDefault()
              callback('ungroup', rule)
            }
          }}
        >
          <Icon type="ungroup" />
        </button>
      </Conditional>
      <Conditional dependencies={place === 'outer' && target !== 'column'}>
        <button
          className={`${classes.ruleButton}${
            !!connecting && !amIBeingConnected ? ' disabled' : ''
          } small`}
          disabled={!!connecting && !amIBeingConnected}
          type="button"
          onClick={event => {
            event.stopPropagation()
            callback(connecting ? 'disconnect' : 'connect', rule)
          }}
          onKeyDown={event => {
            if (event.keyCode === 13) {
              event.stopPropagation()
              event.preventDefault()
              callback(connecting ? 'disconnect' : 'connect', rule)
            }
          }}
        >
          <Icon type="group" />
        </button>
      </Conditional>
      <button
        className={`${classes.ruleButton}${connecting ? ' disabled' : ''}`}
        disabled={!!connecting}
        type="button"
        onClick={event => {
          event.stopPropagation()
          callback('remove', rule)
        }}
        onKeyDown={event => {
          if (event.keyCode === 13) {
            event.stopPropagation()
            event.preventDefault()
            // callback(id, null)
            callback('remove', rule)
          }
        }}
      >
        <Icon type="trash" />
      </button>
    </div>
  )
}

const RuleGroup = ({
  id: groupId,
  logic: groupLogic,
  rules: groupRules,
  meta,
  stackedBy,
  callback,
  place,
}) => {
  const { connecting } = useContext(Context)
  const rule = {
    id: groupId,
    type: 'nested',
    logic: groupLogic,
    rules: groupRules,
  }
  const amIBeingConnected = connecting?.id === groupId
  const classAppendage = amIBeingConnected ? ' connecting' : ' available'
  return (
    <div
      className={`${classes.ruleGroupWrapper}${connecting ? classAppendage : ''}`}
      tabIndex={!!connecting && !amIBeingConnected ? 0 : -1}
      type={!!connecting && !amIBeingConnected ? 'button' : ''}
      onClick={
        !!connecting && !amIBeingConnected
          ? event => {
              event.stopPropagation()
              callback('group', rule)
            }
          : null
      }
      onKeyDown={
        !!connecting && !amIBeingConnected
          ? event => {
              if (event.keyCode === 13) {
                event.stopPropagation()
                event.preventDefault()
                callback('group', rule)
              }
            }
          : null
      }
    >
      <div className={classes.ruleGroupHeader}>
        <FormControlLabel
          label="AND"
          labelPlacement="start"
          margin="dense"
          control={
            <FormControlLabel
              disabled={!!connecting}
              label="OR"
              labelPlacement="end"
              checked={groupLogic !== 'and'}
              margin="dense"
              onChange={event => {
                callback('update', {
                  ...rule,
                  logic: event.target.checked ? 'or' : 'and',
                })
              }}
              control={<Switch color="default" />}
            />
          }
        />
        <div className={classes.ruleButtonDiv}>
          <Conditional dependencies={place === 'inner'}>
            <button
              className={`${classes.ruleButton}${
                connecting ? ' disabled' : ''
              } small`}
              disabled={!!connecting}
              type="button"
              onClick={event => {
                event.stopPropagation()
                callback('ungroup', rule)
              }}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  event.stopPropagation()
                  event.preventDefault()
                  callback('ungroup', rule)
                }
              }}
            >
              <Icon type="ungroup" />
            </button>
          </Conditional>
          <Conditional dependencies={place === 'outer'}>
            <button
              className={`${classes.ruleButton}${
                !!connecting && !amIBeingConnected ? ' disabled' : ''
              } small`}
              disabled={!!connecting && !amIBeingConnected}
              type="button"
              onClick={event => {
                event.stopPropagation()
                callback(connecting ? 'disconnect' : 'connect', rule)
              }}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  event.stopPropagation()
                  event.preventDefault()
                  callback(connecting ? 'disconnect' : 'connect', rule)
                }
              }}
            >
              <Icon type="group" />
            </button>
          </Conditional>
          <button
            className={`${classes.ruleButton}${connecting ? ' disabled' : ''} small`}
            disabled={!!connecting}
            type="button"
            onClick={event => {
              event.stopPropagation()
              callback('remove', rule)
            }}
            onKeyDown={event => {
              if (event.keyCode === 13) {
                event.stopPropagation()
                event.preventDefault()
                callback('remove', rule)
              }
            }}
          >
            <Icon type="trash" />
          </button>
        </div>
      </div>
      {groupRules.map(
        ({
          id,
          type,
          logic,
          rules,
          target,
          method,
          operator,
          value,
          feature_id: featureIds,
        }) => {
          return type === 'simple' ? (
            <FilterRule
              key={id}
              id={id}
              target={target}
              method={method}
              operator={operator}
              value={value}
              features={featureIds}
              meta={meta}
              stackedBy={stackedBy}
              callback={callback}
              place="inner"
            />
          ) : (
            <RuleGroup
              key={id}
              id={id}
              logic={logic}
              rules={rules}
              meta={meta}
              stackedBy={stackedBy}
              callback={callback}
              place="inner"
            />
          )
        }
      )}
    </div>
  )
}

const FilterConfig = ({ config, meta, stackedBy, callback }) => {
  const [connectingRule, setConnectingRule] = useState(null)
  const contentRef = useRef()
  const addRule = useCallback(
    (_, target, method, operator, value) => {
      const tempRule = {
        id: uuid(),
        type: 'simple',
        target,
        feature_id: [],
      }
      const tempConfig = {
        ...config,
        rules: [...config.rules, tempRule],
      }
      if (target === 'row') tempRule.method = method
      if (operator !== undefined) tempRule.operator = operator
      if (value !== undefined) tempRule.value = value
      callback(tempConfig)
    },
    [callback, config]
  )
  const ruleCallback = (method, rule) => {
    if (rule) {
      if (method === 'connect') {
        setConnectingRule(rule)
      }
      if (method === 'group') {
        const tempConfig = { ...config }
        const newConfig = groupRule(tempConfig, connectingRule, rule.id)
        callback({ ...newConfig })
        setConnectingRule(null)
      }
      if (method === 'ungroup') {
        const tempConfig = { ...config }
        const [removed] = removeRule(tempConfig, rule.id)
        const newConfig = {
          ...removed,
          rules: [...removed.rules, rule],
        }
        callback({ ...newConfig })
      }
      if (method === 'update') {
        const tempConfig = { ...config }
        const [newConfig] = updateRule(tempConfig, rule)
        callback({ ...newConfig })
      }
      if (method === 'remove') {
        const tempConfig = { ...config }
        const [newConfig] = removeRule(tempConfig, rule.id)
        callback({ ...newConfig })
      }
    }
    if (method === 'disconnect') {
      setConnectingRule(null)
    }
  }
  const { onClick, onKeyDown } = useInteractiveClick(addRule)
  const ruleLength = config?.rules?.length || 0

  useEffect(() => {
    if (contentRef?.current)
      contentRef.current.scrollTop = contentRef.current.scrollHeight
  }, [ruleLength])

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={classes.wrapper}
        ref={contentRef}
        onKeyDown={event => {
          if (event.keyCode === 27 && connectingRule) {
            event.stopPropagation()
            setConnectingRule(null)
          }
        }}
      >
        <Provider value={{ connecting: connectingRule }}>
          {config.rules.map(
            ({
              id,
              type,
              logic,
              rules,
              target,
              method,
              operator,
              value,
              feature_id: featureIds,
            }) =>
              type === 'nested' ? (
                <RuleGroup
                  key={id}
                  id={id}
                  logic={logic}
                  rules={rules}
                  meta={meta}
                  stackedBy={stackedBy}
                  callback={ruleCallback}
                  place="outer"
                />
              ) : (
                <FilterRule
                  key={id}
                  id={id}
                  target={target}
                  method={method}
                  operator={operator}
                  value={value}
                  features={featureIds}
                  meta={meta}
                  stackedBy={stackedBy}
                  callback={ruleCallback}
                  place="outer"
                />
              )
          )}
        </Provider>
      </div>
      <div className={classes.addRuleButtonsDiv}>
        <button
          className={`${classes.addRuleButton}${connectingRule ? ' disabled' : ''}`}
          type="button"
          onClick={event => onClick(event, 'column')}
          onKeyDown={event => onKeyDown(event, 'column')}
          disabled={!!connectingRule}
        >
          Remove Column
        </button>
        <button
          className={`${classes.addRuleButton}${connectingRule ? ' disabled' : ''}`}
          type="button"
          onClick={event => {
            onClick(event, 'row', FILTER_FUNCTIONS.CONTAINS_TEXT, '', '')
          }}
          onKeyDown={event => {
            onKeyDown(event, 'row', FILTER_FUNCTIONS.CONTAINS_TEXT, '', '')
          }}
          disabled={!!connectingRule}
        >
          Text
        </button>
        <button
          className={`${classes.addRuleButton}${connectingRule ? ' disabled' : ''}`}
          type="button"
          onClick={event => {
            onClick(event, 'row', FILTER_FUNCTIONS.MISSING, 'both')
          }}
          onKeyDown={event => {
            onKeyDown(event, 'row', FILTER_FUNCTIONS.MISSING, 'both')
          }}
          disabled={!!connectingRule}
        >
          Invalid
        </button>
        <button
          className={`${classes.addRuleButton}${connectingRule ? ' disabled' : ''}`}
          type="button"
          onClick={event => {
            onClick(event, 'row', FILTER_FUNCTIONS.NUMERIC_COMPARISON, 'lt', 0)
          }}
          onKeyDown={event => {
            onKeyDown(event, 'row', FILTER_FUNCTIONS.NUMERIC_COMPARISON, 'lt', 0)
          }}
          disabled={!!connectingRule}
        >
          Numeric
        </button>
        <button
          className={`${classes.addRuleButton}${connectingRule ? ' disabled' : ''}`}
          type="button"
          onClick={event => {
            onClick(
              event,
              'row',
              FILTER_FUNCTIONS.DATE_COMPARISON,
              'lt',
              moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss')
            )
          }}
          onKeyDown={event => {
            onKeyDown(
              event,
              'row',
              FILTER_FUNCTIONS.DATE_COMPARISON,
              'lt',
              moment(Date.now()).format('YYYY-MM-DD')
            )
          }}
          disabled={!!connectingRule}
        >
          Chronological
        </button>
      </div>
    </>
  )
}

FilterConfig.propTypes = {
  config: PropTypes.object,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  stackedBy: PropTypes.number,
  callback: PropTypes.func,
}

FilterRule.propTypes = {
  id: PropTypes.string,
  target: PropTypes.oneOf(['column', 'row']),
  method: PropTypes.string,
  operator: PropTypes.oneOf([
    'missing',
    'invalid',
    'both',
    'lt',
    'lte',
    'eq',
    'gte',
    'gt',
    'ww', // whole word
    '', // for unchecked whole word
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  features: PropTypes.arrayOf(PropTypes.number),
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  stackedBy: PropTypes.number,
  place: PropTypes.string,
  callback: PropTypes.func,
}

RuleGroup.propTypes = {
  id: PropTypes.string,
  logic: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.shape(FilterRule.propTypes)), // FIX
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  stackedBy: PropTypes.number,
  place: PropTypes.string,
  callback: PropTypes.func,
}

RuleGroup.propTypes = {
  id: PropTypes.string,
  logic: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.shape(FilterRule.propTypes)), // FIX
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  stackedBy: PropTypes.number,
  place: PropTypes.string,
  callback: PropTypes.func,
}

export default FilterConfig
