export const classes = {
  dialog: 'TrainingWizard-dialog',
  wrapper: 'TrainingWizard-wrapper',
  title: 'TrainingWizard-title',
  header: 'TrainingWizard-header',
  content: 'TrainingWizard-content',
  footer: 'TrainingWizard-footer',
  overlayWrapper: 'TrainingWizard-overlayWrapper',
  stepsWrapper: 'TrainingWizard-stepsWrapper',
  step: 'TrainingWizard-step',
  stepContent: 'TrainingWizard-stepContent',
  stepSection: 'TrainingWizard-stepSection',
  sectionHeader: 'TrainingWizard-sectionHeader',
  sectionInfo: 'TrainingWizard-sectionInfo',
  progressWrapper: 'TrainingWizard-progressWrapper',
  progressBar: 'TrainingWizard-progressBar',
  closeButton: 'TrainingWizard-closeButton',
  cpuModal: 'Cpu-modal',
}
