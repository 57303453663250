/* eslint-disable no-loop-func */

export const interpolateLine = (line, boundaries) => {
  // ==================================
  // ====== REMOVE COLINEAR DOTS ======
  // ==================================
  let tempLine = line.reduce((acc, cur, i, array) => {
    const { length } = array
    if (i !== 0 && i < length - 1) {
      const yRange = array[i + 1].y - array[i - 1].y
      const xRange = array[i + 1].x - array[i - 1].x
      const xDistance = cur.x - array[i - 1].x
      const expectedY = array[i - 1].y + yRange * (xDistance / xRange)
      if (!(cur.y === expectedY)) acc.push(cur)
    } else acc.push(cur)
    return acc
  }, [])
  // ==================================

  // FiB === First in Bounds
  // FooB === First out of Bounds
  // ==================================
  // ========= CUTTING BEFORE =========
  // ==================================
  let FiB = tempLine.findIndex(l => l.x >= boundaries.xMin)
  if (FiB === -1) tempLine = []
  else if (FiB > 0) {
    const fill = {
      x: boundaries.xMin,
      y:
        tempLine[FiB - 1].y +
        ((tempLine[FiB].y - tempLine[FiB - 1].y) *
          (boundaries.xMin - tempLine[FiB - 1].x)) /
          (tempLine[FiB].x - tempLine[FiB - 1].x),
    }
    tempLine.splice(0, FiB, fill)
  }
  // ==================================

  // ==================================
  // ========= CUTTING AFTER ==========
  // ==================================
  let FooB = tempLine.findIndex(l => l.x >= boundaries.xMax)
  if (FooB === 0) tempLine = []
  else if (FooB !== -1) {
    const fill = {
      x: boundaries.xMax,
      y:
        tempLine[FooB - 1].y +
        ((tempLine[FooB].y - tempLine[FooB - 1].y) *
          (boundaries.xMax - tempLine[FooB - 1].x)) /
          (tempLine[FooB].x - tempLine[FooB - 1].x),
    }
    tempLine.splice(FooB, tempLine.length - FooB, fill)
  }
  // ==================================

  // ==================================
  // ========= CUTTING ABOVE ==========
  // ==================================
  FooB = tempLine.findIndex(l => l.y > boundaries.yMax)
  while (FooB !== -1) {
    const firstFill =
      FooB === 0
        ? { x: tempLine[0].x, y: boundaries.yMax }
        : {
            x:
              tempLine[FooB - 1].x +
              ((tempLine[FooB].x - tempLine[FooB - 1].x) *
                (boundaries.yMax - tempLine[FooB - 1].y)) /
                (tempLine[FooB].y - tempLine[FooB - 1].y),
            y: boundaries.yMax,
          }
    FiB = tempLine.findIndex((l, i) => l.y < boundaries.yMax && i > FooB)
    const lastFill =
      FiB === -1
        ? { x: tempLine[tempLine.length - 1].x, y: boundaries.yMax }
        : {
            x:
              tempLine[FiB - 1].x +
              ((tempLine[FiB].x - tempLine[FiB - 1].x) *
                (boundaries.yMax - tempLine[FiB - 1].y)) /
                (tempLine[FiB].y - tempLine[FiB - 1].y),
            y: boundaries.yMax,
          }
    tempLine.splice(
      FooB,
      FiB !== -1 ? FiB - FooB : tempLine.length - FooB,
      firstFill,
      lastFill
    )
    FooB = tempLine.findIndex(l => l.y > boundaries.yMax)
  }
  // ==================================

  // ==================================
  // ========= CUTTING BELOW ==========
  // ==================================
  FooB = tempLine.findIndex(l => l.y < boundaries.yMin)
  while (FooB !== -1) {
    const firstFill =
      FooB === 0
        ? { x: tempLine[0].x, y: boundaries.yMin }
        : {
            x:
              tempLine[FooB - 1].x +
              ((tempLine[FooB].x - tempLine[FooB - 1].x) *
                (boundaries.yMin - tempLine[FooB - 1].y)) /
                (tempLine[FooB].y - tempLine[FooB - 1].y),
            y: boundaries.yMin,
          }
    FiB = tempLine.findIndex((l, i) => l.y > boundaries.yMin && i > FooB)
    const lastFill =
      FiB === -1
        ? { x: tempLine[tempLine.length - 1].x, y: boundaries.yMin }
        : {
            x:
              tempLine[FiB - 1].x +
              ((tempLine[FiB].x - tempLine[FiB - 1].x) *
                (boundaries.yMin - tempLine[FiB - 1].y)) /
                (tempLine[FiB].y - tempLine[FiB - 1].y),
            y: boundaries.yMin,
          }
    tempLine.splice(
      FooB,
      FiB !== -1 ? FiB - FooB : tempLine.length - FooB,
      firstFill,
      lastFill
    )
    FooB = tempLine.findIndex(l => l.y < boundaries.yMin)
  }
  /// ////////////////
  return tempLine
}

export const getLowHighPath = (low, high) => {
  const lowString = low.reduce((acc, { x, y }, i) => {
    const tempString = `${acc}${i === 0 ? 'M' : ' L'} ${x} ${-y}`
    return tempString
  }, '')
  const reversedHigh = [...high].reverse()
  const highString = reversedHigh.reduce((acc, { x, y }) => {
    const tempString = `${acc} L ${x} ${-y}`
    return tempString
  }, '')

  return `${lowString + highString} Z`
}
export const getLinePath = line => {
  const string = line.reduce((acc, { x, y }, i) => {
    const tempString = `${acc}${i === 0 ? 'M' : ' L'} ${x} ${-y}`
    return tempString
  }, '')

  return string
}
