import React from 'react'
import 'style.scss'

const classes = {
  wrapper: 'Footer-wrapper',
}

const Footer = props => {
  return <div className={classes.wrapper} />
}

export default Footer
