/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react'

import { Slider } from 'components/Slider'
import { Context } from 'components/Form'
import './style.scss'

const DataSplitSlider = React.forwardRef((props, ref) => {
  const ctx = useContext(Context)
  const { get, dispatch } = ctx

  const formData = get()

  // const colors = generateColors('#1E90FF', 3, 0.2 + 3 / (3 + 1) / 10)
  const colors = ['#ff9900', '#ff00ff', '#00ffcc']

  const segments = [
    { name: 'Offset Start', value: formData.data_split_offset || 0, color: '#888' },
    {
      name: 'Train',
      value: formData.data_split_train || 0,
      color: colors[0],
      min: 0.05,
    },
    {
      name: 'Validate',
      value: formData.data_split_validate || 0,
      color: colors[1],
      min: 0.05,
    },
    {
      name: 'Test',
      value: formData.data_split_test || 0,
      color: colors[2],
      min: 0.05,
    },
    { name: 'Offset End', value: formData.data_split_end || 0, color: '#888' },
  ]

  return (
    <Slider
      ref={ref}
      segments={segments}
      onMouseUp={data => {
        const offset = data.find(split => split.name === 'Offset Start')
        const train = data.find(split => split.name === 'Train')
        const validate = data.find(split => split.name === 'Validate')
        const test = data.find(split => split.name === 'Test')
        const end = data.find(split => split.name === 'Offset End')
        const split = {
          data_split_offset: offset.value,
          data_split_train: train.value,
          data_split_validate: validate.value,
          data_split_test: test.value,
          data_split_end: end.value,
        }
        dispatch('merge', null, split)
      }}
      {...props}
    />
  )
})

export default DataSplitSlider
