import React from 'react'
import PropTypes from 'prop-types'
import { secondsToReadable } from 'libs/utils/helpers'

import Conditional from 'components/Conditional'
import { BaseCard as Card } from 'components/CardLayout'
import Icon from 'components/Icon'

import './style.scss'

const classes = {
  wrapper: 'TrainingDetails-wrapper',
  title: 'TrainingDetails-title',
  content: 'TrainingDetails-content',
  datasources: 'TrainingDetails-datasources',
  setup: 'TrainingDetails-setup',
  objectives: 'TrainingDetails-objectives',
  error: 'TrainingDetails-error',
}

const TrainingDetails = ({ training = {} }) => {
  const { metadata = [], spec = {} } = training
  const {
    objectives = [],
    data_split_train: train,
    data_split_validate: validate,
    data_split_test: test,
    max_no_of_brains: maxBrains,
    time_to_run_seconds: time,
    // no_cpus: cpus,
    metadata: specMeta = {},
  } = spec
  const { prediction_horizon: prediction, lookback } = specMeta

  const objectiveNames = objectives.reduce((acc, cur) => {
    const tempResult = [...acc]
    // let feature = null
    // let i = 0
    // while (!feature && i < metadata.length) {
    //   feature = metadata[i]?.features?.find(f => f.id === cur.id)
    //   i++
    // }
    // if (feature) {
    cur.metric.forEach(metric => {
      tempResult.push(`${cur.name} - ${metric}`)
    })
    // } else tempResult.push(null)
    return tempResult
  }, [])

  return (
    <Card className={classes.wrapper}>
      <div className={classes.title}>
        <Icon type="info" />
        Training details
      </div>
      <div className={classes.content}>
        <Conditional dependencies={metadata?.length >= 1}>
          <div className={classes.datasources}>
            <span>
              {`Datasource${metadata.length > 1 ? 's' : ':'}`}
              <Conditional dependencies={metadata?.length === 1}>
                <span>{metadata[0]?.trained_on_name}</span>
              </Conditional>
            </span>
            <Conditional dependencies={metadata?.length > 1}>
              {metadata.map((ds, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={i}>
                    {`Datasource ${i + 1}:`}
                    <span>{ds.trained_on_name}</span>
                  </span>
                )
              })}
            </Conditional>
          </div>
        </Conditional>
        <div className={classes.setup}>
          <span>Data Split:</span>
          <Conditional dependencies={train}>
            <span>
              Train:
              <span>{`${train * 100}%`}</span>
            </span>
          </Conditional>
          <Conditional dependencies={validate}>
            <span>
              Validate:
              <span>{`${validate * 100}%`}</span>
            </span>
          </Conditional>
          <Conditional dependencies={test}>
            <span>
              Test:
              <span>{`${test * 100}%`}</span>
            </span>
          </Conditional>
          <br />
          <span>
            Prediction Horizon:
            <span>{prediction}</span>
          </span>
          <span>
            Lookback Period:
            <span>{lookback}</span>
          </span>
          {/* <span>
            Number of CPUs:
            <span>{cpus}</span>
          </span> */}
          <br />
          Convergence Criteria
          <span>
            Number of brains:
            <span>{maxBrains || 'unlimited'}</span>
          </span>
          <span>
            Training time:
            <span>{(time && secondsToReadable(time)) || 'unlimited'}</span>
          </span>
        </div>
        <Conditional dependencies={objectiveNames?.length >= 1}>
          <div className={classes.objectives}>
            <span>
              {`Objective${objectiveNames.length > 1 ? 's' : ':'}`}
              <Conditional dependencies={objectiveNames?.length === 1}>
                <span className={!objectiveNames[0] ? classes.error : ''}>
                  {objectiveNames[0] || 'Cannot find objective'}
                </span>
              </Conditional>
            </span>
            <Conditional dependencies={objectiveNames?.length > 1}>
              {objectiveNames.map((ob, i) => {
                return (
                  <span key={ob}>
                    {`Objective ${i + 1}:`}
                    <span className={!ob ? classes.error : ''}>
                      {ob || 'Cannot find objective'}
                    </span>
                  </span>
                )
              })}
            </Conditional>
          </div>
        </Conditional>
      </div>
    </Card>
  )
}

TrainingDetails.propTypes = {
  training: PropTypes.any,
}

export default TrainingDetails
