import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { isFunction } from 'libs/utils/helpers'

import { AlertDialog } from 'components/AlertDialog'

import './style.scss'

const SimpleResourceDialog = forwardRef((props, ref) => {
  const { titleText, contentText, cancelText, okText, okFunction } = props

  const buttonTexts = {}
  if (cancelText) buttonTexts.cancelText = cancelText
  if (okText) buttonTexts.okText = okText

  return (
    <AlertDialog
      ref={ref}
      title={titleText}
      content={contentText}
      onOk={event => {
        event.stopPropagation()
        event.preventDefault()
        if (okFunction && isFunction(okFunction)) okFunction()
        ref.current.close()
      }}
      onCancel={() => ref.current.close()}
      onClose={() => ref.current.close()}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonTexts}
    />
  )
})

SimpleResourceDialog.propTypes = {
  titleText: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  okFunction: PropTypes.func,
}

export default SimpleResourceDialog
