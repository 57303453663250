import React from 'react'
import PropTypes from 'prop-types'

import { MenuItem, TextField } from '@material-ui/core'

const classes = {
  wrapper: 'UserControl-wrapper',
  title: 'UserControl-title',
}

const TrainingSplitSelectControl = props => {
  const {
    values: propValues = [],
    onChange,
    multiple,
    includeCombined,
    color,
  } = props

  const options = ['train', 'validation', 'test']
  if (includeCombined) options.push('combined')

  const values = (multiple ? propValues : propValues.slice(0, 1)).filter(
    val => options.indexOf(val) !== -1
  )

  return (
    <div
      className={classes.wrapper}
      style={{
        filter: color ? `drop-shadow(0px 0px 5px ${color})` : '',
        boxShadow: color ? `0px 0px 3px 1px ${color}` : '',
      }}
    >
      <div className={classes.title}>
        <span>Training Split</span>
      </div>
      <TextField
        label="Split"
        select
        SelectProps={{ multiple: true, disabled: !options.length }}
        value={values}
        onChange={event => {
          const tempVal = event.target.value
          const val = tempVal && !Array.isArray(tempVal) ? [tempVal] : tempVal
          const difference = val.filter(id => values.indexOf(id) === -1)
          onChange(multiple ? val : difference)
        }}
      >
        {options.map(option => {
          return (
            <MenuItem key={option} value={option}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </MenuItem>
          )
        })}
      </TextField>
    </div>
  )
}

TrainingSplitSelectControl.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  includeCombined: PropTypes.bool,
  color: PropTypes.string,
}

export default TrainingSplitSelectControl
