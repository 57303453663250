import React, { useRef, useState, useMemo } from 'react'
import { useStore, useHistory } from 'core'

import { AlertDialog } from 'components/AlertDialog'
import { Conditional } from 'components/Conditional'
import { Button } from '@material-ui/core'

import './style.scss'
import PlanCard from './component/PlanCard'

const classes = {
  wrapper: 'PlansPage-wrapper',
  container: 'PlansPage-plans-container',
  showDetailsButton: 'PlansPage-show-details-button',
  detailsContainer: 'PlansPage-details-container',
}

// const storePlansTest = [
//   {
//     currency: 'USD',
//     billing_type: 'Annually',
//     price: 9799900,
//     trial_type: null,
//     description: null,
//     trial_period: null,
//     name: 'Pro yearly',
//     meta: {
//       plan_id: 1,
//       licence_type: 'PRO',
//       licence_count: 7,
//       process_count: 30,
//     },
//     billing_period: 1,
//     available: true,
//     id: 'pro-yearly',
//   },
//   {
//     currency: 'USD',
//     billing_type: 'Monthly',
//     price: 949900,
//     trial_type: null,
//     description: null,
//     trial_period: null,
//     name: 'Pro monthly',
//     meta: {
//       plan_id: 1,
//       licence_type: 'PRO',
//       licence_count: 7,
//       process_count: 30,
//     },
//     billing_period: 1,
//     available: true,
//     id: 'pro-monthly',
//   },
//   {
//     currency: 'USD',
//     billing_type: 'Annually',
//     price: 7999900,
//     trial_type: null,
//     description: null,
//     trial_period: null,
//     name: 'Basic yearly',
//     meta: {
//       plan_id: 2,
//       licence_type: 'BASIC',
//       licence_count: 5,
//       process_count: 20,
//     },
//     billing_period: 1,
//     available: true,
//     id: 'basic-yearly',
//   },
//   {
//     currency: 'USD',
//     billing_type: 'Monthly',
//     price: 799900,
//     trial_type: null,
//     description: null,
//     trial_period: null,
//     name: 'Basic monthly',
//     meta: {
//       plan_id: 2,
//       licence_type: 'BASIC',
//       licence_count: 5,
//       process_count: 20,
//     },
//     billing_period: 1,
//     available: true,
//     id: 'basic-monthly',
//   },
// ]

const PlansPage = props => {
  const history = useHistory()
  // const [showDetails, setShowDetails] = useState(false)
  const showDetails = false
  const modalRef = useRef()
  const {
    user,
    plans: storePlans,
    checkout,
  } = useStore('selectUser', 'selectPlans', 'checkout')

  const plans = useMemo(
    () =>
      storePlans.reduce((acc, plan) => {
        if (!plan.meta || !plan.meta.plan_id) return acc
        const {
          // name,
          description,
          currency,
          price,
          billing_type: billing,
          meta: planMeta,
          id: chargeBeeId,
        } = plan
        // eslint-disable-next-line camelcase
        const { plan_id: id, licence_type, licence_count, process_count } = planMeta

        const existingPlan = acc.find(e => e.id === id)

        if (!existingPlan) {
          // doesnt exist, create
          acc.push({
            id,
            name: id,
            description,
            currency,
            licenceType: licence_type,
            licences: licence_count,
            processes: process_count,
            [String(billing).toLowerCase()]: { price, chargeBeeId },
          })
        } else {
          // exists add
          existingPlan[String(billing).toLowerCase()] = { price, chargeBeeId }
        }
        return acc
      }, []),
    [storePlans]
  )

  const purchaseHandler = id => {
    if (user) checkout(id)
    else {
      modalRef.current.open()
    }
  }

  return (
    <>
      <AlertDialog
        ref={modalRef}
        // title="Registeration required"
        content="You must register in order to purchase a product. Register now?"
        okText="Register"
        cancelText="Cancel"
        onOk={event => {
          event.stopPropagation()
          event.preventDefault()
          history.push('/signup')
          modalRef.current.close()
        }}
        onCancel={event => {
          event.stopPropagation()
          modalRef.current.close()
        }}
      />
      <div className={classes.wrapper}>
        <div className={`${classes.container} ${showDetails ? 'no-details' : ''}`}>
          {plans
            .sort(
              (a, b) =>
                // eslint-disable-next-line no-unused-expressions
                a.display_order - b.display_order
            )
            .map(plan => (
              <PlanCard
                key={plan.id}
                plan={plan}
                subscribe={purchaseHandler}
                showDetails={!showDetails}
              />
            ))}
        </div>
        <Conditional dependencies={!showDetails}>
          <div />
          {/* <Button
            variant="text"
            className={classes.showDetailsButton}
            onClick={e => setShowDetails(true)}
          >
            Show details
          </Button> */}
        </Conditional>
        <Conditional dependencies={showDetails}>
          <div className={classes.detailsContainer}>
            <div>Pera</div>
            {plans.map(e => e.licenceType)}
          </div>
        </Conditional>
      </div>
    </>
  )
}

export default PlansPage
