export const DATASET_TYPES = {
  BASEDATA: 'basedata',
  TIMESERIES: 'timeseries',
  'STACKED-TIMESERIES': 'stacked-timeseries',
}

export const FEATURE_TYPES = {
  NUMERIC: 'numeric',
  CATEGORY: 'category',
  TEXT: 'text',
  TIME: 'time',
}

export const FILTER_FUNCTIONS = {
  MISSING: 'missing',
  CONTAINS_TEXT: 'contains-text',
  MISSING_TEXT: 'not-contains-text', // "missing-text"
  NUMERIC_COMPARISON: 'numeric-comparison',
  DATE_COMPARISON: 'date-comparison',
}

export const IMPUTATION_FUNCTIONS = {
  FIXED_VALUE: 'fixed-value',
  LOCF: 'locf',
  LOCB: 'locb',
  MEAN: 'mean',
  MEDIAN: 'median',
  MIN: 'min',
  MAX: 'max',
  LINEAR: 'interp-linear',
}

export const SANITATION_FUNCTIONS = {
  HARMONIZE_CASING: 'harmonize',
  DECIMAL_CORRECTION: 'correct-decimal',
  SUBSTRING: 'remove-chars',
  TRIM: 'remove-spaces',
  TEXT_REPLACE: 'text-replace',
}

export const CALCULATION_FUNCTIONS = {
  FORMULA: 'formula-calculation',
  WINDOW: 'window-calculation',
  FINANCIAL: 'financial-calculation',
  ZIGZAG: 'zigzag',
  DECOMPOSITION: 'decomposition',
  CLONE: 'feature-clone',
}
