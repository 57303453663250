import React, { useState } from 'react'
import { useStore } from 'core'
import { formatDate } from 'libs/utils/helpers'

import { BaseCard as Card } from 'components/CardLayout'
import { Table, Column } from 'components/Table'

import './style.scss'
import { Button } from '@material-ui/core'

const classes = {
  wrapper: 'SubscriptionsPage-wrapper',
  section: 'AccountPages-section',
  sectionTitle: 'AccountPages-section-title',
  formDiv: 'AccountPages-formDiv',
  billingInfoDiv: 'SubscriptionsPage-billingInfoDiv',
  fieldSpan: 'SubscriptionsPage-fieldSpan',
  valueSpan: 'SubscriptionsPage-valueSpan',
  changeButton: 'SubscriptionsPage-changeButton',
  subscriptionsDiv: 'SubscriptionsPage-subscriptionsDiv',
  paymentsDiv: 'SubscriptionsPage-paymentsDiv',
}

const tableData = []

for (let i = 0; i < 100; i++) {
  const price = Number((99.95 + (i / 2) * Math.sign(Math.random() - 0.5)).toFixed(2))
  const paid = Number((price + Math.round(Math.random() * 2000) / 100).toFixed(2))
  tableData.push({
    saleId: Math.round(Math.random() * 1000000),
    date: formatDate(Date.now()),
    product: 'PRO 1 month (recurring)',
    price,
    paid,
    tax: Number((paid - price).toFixed(2)),
    status: 'successful',
    method: 'Mastercard xyz1',
  })
}

const SubscriptionsPage = props => {
  const [currentPage, setCurrentPage] = useState(1)

  const { payments } = useStore('selectPayments')

  // const { subscriptions } = contracts || {}

  return (
    <div className={classes.wrapper}>
      {/* <Card>
        <div className={classes.section} style={{ flex: 3 }}>
          <span className={classes.sectionTitle}>Billing Info</span>
          <span>
            {`Next payment: ${undefined}   |   Recurring: `}
            <TextField select value="active" margin="dense">
              <MenuItem value="active">Active</MenuItem>
            </TextField>
          </span>
          <div className={classes.billingInfoDiv}>
            <span className={classes.fieldSpan}>Name</span>
            <span className={classes.valueSpan}>Pera Mika Zika</span>
            <Button
              className={classes.changeButton}
              color="primary"
              onClick={() => {}}
            >
              Change
            </Button>
            <span className={classes.fieldSpan}>Phone</span>
            <span className={classes.valueSpan}>+381606060606</span>
            <Button
              className={classes.changeButton}
              color="primary"
              onClick={() => {}}
            >
              Change
            </Button>
            <span className={classes.fieldSpan}>Business Name</span>
            <span className={classes.valueSpan}>Perisimus Franjojoskic</span>
            <Button
              className={classes.changeButton}
              color="primary"
              onClick={() => {}}
            >
              Change
            </Button>
            <span className={classes.fieldSpan}>Address Line 1</span>
            <span className={classes.valueSpan}>Aleja Ulicana 69/420</span>
            <Button
              className={classes.changeButton}
              color="primary"
              onClick={() => {}}
            >
              Change
            </Button>
            <span className={classes.fieldSpan}>Country</span>
            <span className={classes.valueSpan}>Neverland</span>
            <Button
              className={classes.changeButton}
              color="primary"
              onClick={() => {}}
            >
              Change
            </Button>
            <span className={classes.fieldSpan}>City</span>
            <span className={classes.valueSpan}>Townsville</span>
            <Button
              className={classes.changeButton}
              color="primary"
              onClick={() => {}}
            >
              Change
            </Button>
            <span className={classes.fieldSpan}>Postal Code</span>
            <span className={classes.valueSpan}>69420</span>
            <Button
              className={classes.changeButton}
              color="primary"
              onClick={() => {}}
            >
              Change
            </Button>
            <span className={classes.fieldSpan}>VAT/ABN</span>
            <span className={classes.valueSpan}>Whatever this thing is.</span>
            <Button
              className={classes.changeButton}
              color="primary"
              onClick={() => {}}
            >
              Change
            </Button>
          </div>
        </div>
        <div className={classes.section} style={{ flex: 5 }}>
          <span className={classes.sectionTitle}>Summary</span>
          <span>{`My plan: ${'*ultra SUPER plan*'}   |   UPGRADE`}</span>
          <div className={classes.subscriptionsDiv}>{null}</div>
        </div>
      </Card> */}
      <Card>
        <div className={classes.section} style={{ flex: 5 }}>
          <span className={classes.sectionTitle}>Payments</span>
          <span>{`Next payment: ${undefined}   |   Recurring: ${'active'}`}</span>
          <div className={classes.paymentsDiv}>
            <Table
              data={payments}
              footer={{
                pagination: {
                  perPage: 18,
                  currentPage,
                  setCurrentPage,
                },
              }}
            >
              <Column
                dataKey="_defaultColumn"
                render={(value, header) => {
                  const stringValue = `${value}`
                  return header
                    ? stringValue.charAt(0).toUpperCase() + stringValue.slice(1)
                    : value
                }}
              />
              <Column dataKey="paid_at" title="Date" width={90} />
              <Column dataKey="id" title="ID" hidden width={75} />
              <Column
                dataKey="organization_id"
                title="Organization ID"
                hidden
                width={75}
              />
              <Column
                dataKey="amount"
                title="Amount"
                render={(value, header) => (header ? value : `$${value}`)}
                width={125}
              />
              <Column
                dataKey="subscription_id"
                title="Subscription ID"
                hidden
                width={75}
              />
              <Column dataKey="currency" title="Currency" hidden width={75} />
            </Table>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default SubscriptionsPage
