import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { TextField, Checkbox, FormControlLabel } from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import Modal from 'components/Modal'

import './style.scss'

const classes = {
  wrapper: 'PopUpMultiSelect-wrapper',
  iconWrapper: 'PopUpMultiSelect-iconWrapper',
  form: 'multiselect-form',
  selectBtn: 'select-btn',
}

const CheckBoxes = forwardRef((props, ref) => {
  const [state, setState] = useState(null)

  const { values, checkboxData } = props

  const calculated = state || values || []

  useImperativeHandle(ref, () => ({
    checked: calculated,
  }))

  return (
    <>
      {checkboxData.map(({ label, value }) => {
        const selected = calculated.indexOf(value) !== -1
        return (
          <FormControlLabel
            key={value}
            label={label}
            checked={selected}
            control={
              <Checkbox
                color="primary"
                // inputProps={{ autoFocus: i === 0 }}
              />
            }
            onChange={event => {
              // console.log(event.target.checked)
              event.stopPropagation()
              event.preventDefault()
              if (event.target.checked) setState([...calculated, value])
              else
                setState(prev => {
                  // console.log(prev)
                  return calculated.filter(id => id !== value)
                })
            }}
            // onKeyDown={event => {
            //   if (event.keyCode === 13) {
            //     console.log(event.target.checked)
            //     event.stopPropagation()
            //     event.preventDefault()
            //     if (event.target.checked) setState([...calculated, value])
            //     else setState(calculated.filter(el => el !== value))
            //   }
            // }}
          />
        )
      })}
    </>
  )
})

CheckBoxes.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  checkboxData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
}

const PopUpMultiSelect = ({
  value,
  disabled,
  onChange,
  onClick,
  children,
  ...rest
}) => {
  const ref = useRef()
  const [lastClicked, setLastClicked] = useState()
  const [showSelectAll, setShowSelectAll] = useState(true)
  const modalRef = useRef()
  // eslint-disable-next-line no-param-reassign
  value = typeof value === 'number' ? [value] : JSON.parse(value)

  const checkboxData = React.Children.map(children, child => ({
    label: child.props.children,
    value: child.props.value,
  }))

  const data = React.Children.map(children, child => child.props.value)

  const cloned = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      onClick: event => {
        if (event.shiftKey) {
          const computedValue =
            lastClicked !== null && lastClicked !== undefined
              ? lastClicked
              : Number(value[value.length - 1])
          const headElement = value.length ? computedValue : data[0]

          const multiselectorHead = data.indexOf(headElement)
          const multiselectorTail = index

          const equal =
            multiselectorHead === multiselectorTail && data[multiselectorHead]

          const headFirst =
            multiselectorHead < multiselectorTail &&
            data.slice(multiselectorHead, multiselectorTail + 1)

          const tailFirst =
            multiselectorHead > multiselectorTail &&
            data.slice(multiselectorTail, multiselectorHead + 1)

          const slicedData = equal || headFirst || tailFirst

          const set = new Set([...value, ...slicedData])
          onChange(JSON.stringify([...set]))
        } else {
          const childValue = child.props.value
          const modifiedValue = value.some(element => element === childValue)
            ? value.filter(element => element !== childValue)
            : [...value, childValue]
          setLastClicked(childValue)
          onChange(JSON.stringify(modifiedValue))
        }

        typeof child.props.onClick === 'function' && child.props.onClick(event)
      },
    })
  })

  const title = showSelectAll ? (
    <span
      className={classes.selectBtn}
      role="button"
      tabIndex="0"
      onClick={event => {
        onChange(JSON.stringify(checkboxData.map(element => element.value)))
        setShowSelectAll(!showSelectAll)
        modalRef.current.close()
      }}
      onKeyDown={event => {
        if (event.keyCode === 13) {
          onChange(JSON.stringify(checkboxData.map(element => element.value)))
          setShowSelectAll(!showSelectAll)
          modalRef.current.close()
        }
      }}
    >
      Select all
    </span>
  ) : (
    <span
      className={classes.selectBtn}
      role="button"
      tabIndex="0"
      onClick={event => {
        onChange('[]')
        setShowSelectAll(!showSelectAll)
        modalRef.current.close()
      }}
      onKeyDown={event => {
        if (event.keyCode === 13) {
          onChange('[]')
          setShowSelectAll(!showSelectAll)
          modalRef.current.close()
        }
      }}
    >
      Deselect all
    </span>
  )

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 460,
        // width: 250,
      },
    },
    variant: 'menu',
    getContentAnchorEl: null,
  }

  return (
    <>
      <TextField
        className={classes.wrapper}
        select
        SelectProps={{ multiple: true, disabled, MenuProps }}
        value={value}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {cloned}
      </TextField>
      <div className={`${classes.iconWrapper}${disabled ? ' disabled' : ''}`}>
        <OpenInNewIcon
          fontSize="small"
          style={{ cursor: 'pointer', margin: 'auto' }}
          onClick={event => {
            if (!disabled) {
              event.preventDefault()
              event.stopPropagation()
              modalRef.current.open()
            }
          }}
        />
      </div>
      <Modal
        ref={modalRef}
        title={title}
        onClose={event => {
          event.stopPropagation()
          modalRef.current.close()
        }}
        onCancel={event => {
          event.stopPropagation()
          modalRef.current.close()
        }}
        onOk={() => {
          onChange(JSON.stringify(ref.current.checked))
          modalRef.current.close()
        }}
        footer
        okText="Select"
      >
        <div>
          {/* <div className={classes.form}>{generateCheckboxes()}</div> */}
          <div className={classes.form}>
            <CheckBoxes ref={ref} values={value} checkboxData={checkboxData} />
          </div>
        </div>
      </Modal>
    </>
  )
}

PopUpMultiSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default PopUpMultiSelect
