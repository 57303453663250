import React, { useEffect, useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { usePerm } from 'libs/utils/hooks'

let uniqueID = 0
const subscribers = new Map()

const staticMouseDataObject = {
  x: 0,
  y: 0,
  pageX: 0,
  pageY: 0,
  mousedown: false,
}

export const MouseTrackerContext = React.createContext(staticMouseDataObject)

export const useMouseTracker = callback => {
  const id = usePerm(() => ++uniqueID)

  const subscribe = useCallback(() => {
    subscribers.set(id, callback)
  }, [id, callback])

  const unsubscribe = useCallback(() => {
    subscribers.delete(id)
  }, [id])

  useEffect(() => {
    subscribe()
  }, [subscribe])

  useEffect(() => unsubscribe, [unsubscribe])
}

// export const useMouseTracker = () => {
//   const id = usePerm(() => ++uniqueID)
//   const mouseData = useContext(MouseTrackerContext)
//   const [data, setData] = useState({
//     x: 0,
//     y: 0,
//     pageX: 0,
//     pageY: 0,
//     mousedown: false,
//     subscribed: false,
//   })

//   const updater = useCallback(() => {
//     setData(() => ({ ...mouseData, subscribed: true }))
//   }, [mouseData])

//   const subscribe = useCallback(() => {
//     subscribers.set(id, updater)
//   }, [id, updater])

//   const unsubscribe = useCallback(() => {
//     if (data.subscribed) {
//       subscribers.delete(id)
//       setData({ ...mouseData, subscribed: false })
//     }
//   }, [mouseData, id, data.subscribed])

//   return [data, subscribe, unsubscribe]
// }

export const Provider = ({ absolute = true, children }) => {
  const mouseMoveHandler = useCallback(event => {
    if (event) {
      staticMouseDataObject.x = event.clientX
      staticMouseDataObject.y = event.clientY
      staticMouseDataObject.pageX = event.pageX
      staticMouseDataObject.pageY = event.pageY
      subscribers.forEach(callback => callback({ ...staticMouseDataObject }))
    }
  }, [])
  const mouseDownHandler = useCallback(() => {
    staticMouseDataObject.mousedown = true
    subscribers.forEach(callback => callback({ ...staticMouseDataObject }))
  }, [])
  const mouseUpHandler = useCallback(() => {
    staticMouseDataObject.mousedown = false
    subscribers.forEach(callback => callback({ ...staticMouseDataObject }))
  }, [])

  useEffect(() => {
    window.addEventListener('mousemove', mouseMoveHandler)
    window.addEventListener('mouseup', mouseUpHandler)
    window.addEventListener('mousedown', mouseDownHandler)
    return () => {
      window.removeEventListener('mousemove', mouseMoveHandler)
      window.removeEventListener('mouseup', mouseUpHandler)
      window.removeEventListener('mousedown', mouseDownHandler)
    }
  }, [mouseDownHandler, mouseMoveHandler, mouseUpHandler])

  return (
    <MouseTrackerContext.Provider value={staticMouseDataObject}>
      {children}
    </MouseTrackerContext.Provider>
  )
}

Provider.propTypes = {
  absolute: PropTypes.bool,
  children: PropTypes.node,
}
