import React from 'react'
import PropTypes from 'prop-types'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import { Tooltip } from '@material-ui/core'

import './style.scss'

const classes = {
  wrapper: 'DatasetTableHeader-wrapper',
  cell: 'DatasetTableHeader-cell',
  name: 'DatasetTableHeader-name',
  date: 'DatasetTableHeader-date',
  status: 'DatasetTableHeader-status',
  type: 'DatasetTableHeader-type',
  typeInfoDiv: 'DatasetTableHeader-typeInfoDiv',
  actions: 'DatasetTableHeader-actions',
}

const DatasetTableHeader = ({ sort }) => {
  const { column, order, callback } = sort

  return (
    <div className={classes.wrapper}>
      <div
        className={`${classes.cell} ${classes.name}${
          column === 'name' && order === 1 ? ' ascending' : ''
        }`}
        role="button"
        tabIndex={0}
        onClick={event => {
          event.stopPropagation()
          callback('name', column === 'name' ? (order + 1) % 3 : 1)
        }}
        onKeyDown={event => {
          event.stopPropagation()
          if (event.keyCode === 13) {
            callback('name', column === 'name' ? (order + 1) % 3 : 1)
          }
        }}
      >
        Name
        <Conditional dependencies={column === 'name'}>
          <Icon type="arrow-down" />
        </Conditional>
      </div>
      <div
        className={`${classes.cell} ${classes.type}${
          column === 'type' && order === 1 ? ' ascending' : ''
        }`}
        role="button"
        tabIndex={0}
        onClick={event => {
          event.stopPropagation()
          callback('type', column === 'type' ? (order + 1) % 3 : 1)
        }}
        onKeyDown={event => {
          event.stopPropagation()
          if (event.keyCode === 13) {
            callback('type', column === 'type' ? (order + 1) % 3 : 1)
          }
        }}
      >
        <Tooltip
          title={
            <>
              <div className={classes.typeInfoDiv}>
                <span>BASEDATA: Data without a timewise relation.</span>
                <span>TIMESERIES: Data with one timewise relation.</span>
                <span>
                  STACKED-TIMESERIES: Data with multiple stacked timeseries,
                  distinguished by a stacking category.
                </span>
              </div>
            </>
          }
        >
          <span>
            Type
            <Icon type="info" />
          </span>
        </Tooltip>
        <Conditional dependencies={column === 'type'}>
          <Icon type="arrow-down" />
        </Conditional>
      </div>
      <div
        className={`${classes.cell} ${classes.status}${
          column === 'status' && order === 1 ? ' ascending' : ''
        }`}
        role="button"
        tabIndex={0}
        onClick={event => {
          event.stopPropagation()
          callback('status', column === 'status' ? (order + 1) % 3 : 1)
        }}
        onKeyDown={event => {
          event.stopPropagation()
          if (event.keyCode === 13) {
            callback('status', column === 'status' ? (order + 1) % 3 : 1)
          }
        }}
      >
        Status
        <Conditional dependencies={column === 'status'}>
          <Icon type="arrow-down" />
        </Conditional>
      </div>
      <div
        className={`${classes.cell} ${classes.date}${
          column === 'date' && order === 1 ? ' ascending' : ''
        }`}
        role="button"
        tabIndex={0}
        onClick={event => {
          event.stopPropagation()
          callback('date', column === 'date' ? (order + 1) % 3 : 1)
        }}
        onKeyDown={event => {
          event.stopPropagation()
          if (event.keyCode === 13) {
            callback('date', column === 'date' ? (order + 1) % 3 : 1)
          }
        }}
      >
        Last Modified
        <Conditional dependencies={column === 'date'}>
          <Icon type="arrow-down" />
        </Conditional>
      </div>
      <div className={`${classes.cell} ${classes.actions}`}>{/* Actions */}</div>
    </div>
  )
}

DatasetTableHeader.propTypes = {
  sort: PropTypes.shape({
    column: PropTypes.string,
    order: PropTypes.number,
    callback: PropTypes.func,
  }),
}

export default DatasetTableHeader
