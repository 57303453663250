import React, { useCallback } from 'react'
import { useStore } from 'core'

import './style.scss'

import Icon from 'components/Icon'

const classes = {
  wrapper: 'TrainingMenuButton-wrapper',
}

const TrainingMenuButton = props => {
  const { activeTraining, openTrainingWizard, closeTrainingWizard } = useStore(
    'selectActiveTraining',
    'openTrainingWizard',
    'closeTrainingWizard'
  )
  const { id, open, title } = activeTraining

  const trainingWizardToggle = useCallback(() => {
    if (open) closeTrainingWizard()
    else openTrainingWizard()
  }, [open, closeTrainingWizard, openTrainingWizard])

  if (!id) return null
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      // eslint-disable-next-line no-script-url
      href="javascript:void(0)"
      className={classes.wrapper}
      data-active={open}
      onClick={trainingWizardToggle}
    >
      <Icon type="training" />
      <span>{title || 'Training Wizard'}</span>
    </a>
  )
}

export default TrainingMenuButton
