import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import { BaseCard as Card } from 'components/CardLayout'
import Icon from 'components/Icon'
import Spinner from 'components/Spinner'
import AlertDialog from 'components/AlertDialog'
import InfoDialog from 'components/InfoDialog'

import './style.scss'

const classes = {
  wrapper: 'TrainingCard-wrapper',
  header: 'TrainingCard-header',
  spinner: 'TrainingCard-spinner',
  // content: 'TrainingCard-content',
  // main: 'TrainingCard-main',
  // status: 'TrainingCard-status',
  // showMore: 'TrainingCard-showMore',
  // details: 'TrainingCard-details',
  // footer: 'TrainingCard-footer',
  // actionButton: 'TrainingCard-actionButton',
}

// const TrainingCard = props => {
//   const [expanded, setExpanded] = useState(false)
//   const { stopTraining, deleteTraining } = useStore('stopTraining', 'deleteTraining')
//   const {
//     id,
//     title,
//     details,
//     status,
//     onInspect: inspectHandler,
//     // startedAt
//   } = props
//   const statusText = status === 'FINISHED' ? 'COMPLETED' : status

//   const dialogRef = useRef()
//   // const progress = Math.round(Math.max(0, Math.min(1, 0.5)) * 100) / 100
//   const progress = 0

//   const maskID = `ringMask-${uuid()}`
//   const mask = (
//     <rect x="0%" y="0%" width="100%" height="100%" rx="50%" fill="white" />
//   )

//   return (
//     <div
//       className={classes.wrapper}
//       onMouseLeave={() => {
//         setExpanded(false)
//       }}
//     >
//       <div className={classes.header}>
//         <span>{title}</span>
//       </div>
//       <div className={classes.content}>
//         <div className={classes.main} data-active={!expanded}>
//           <svg
//             version="1.1"
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 100 100"
//             preserveAspectRatio="none"
//           >
//             <mask id={maskID}>
//               {mask}
//               <rect x="2%" y="2%" width="96%" height="96%" rx="50%" fill="black" />
//             </mask>
//             <mask id="progressRingMask">
//               <rect x="0%" y="0%" width="100%" height="100%" rx="50%" fill="white" />
//               <rect x="2%" y="2%" width="96%" height="96%" rx="50%" fill="black" />
//             </mask>
//             <defs>
//               <radialGradient
//                 id="ringGradient"
//                 cx={0.5 + 0.5 * Math.sin(2 * Math.PI * (progress - 0.2))}
//                 cy={0.5 - 0.5 * Math.cos(2 * Math.PI * (progress - 0.2))}
//                 fx={0.5 + 0.5 * Math.sin(2 * Math.PI * progress)}
//                 fy={0.5 - 0.5 * Math.cos(2 * Math.PI * progress)}
//                 r={0.6}
//               >
//                 <stop offset="0%" stopColor="dodgerblue" />
//                 <stop offset="100%" stopColor="#414554" />
//                 <animateTransform
//                   attributeName="gradientTransform"
//                   attributeType="XML"
//                   type="rotate"
//                   from="0 0.5 0.5"
//                   to="360 0.5 0.5"
//                   dur="3s"
//                   begin="0s"
//                   repeatCount="indefinite"
//                 />
//               </radialGradient>
//             </defs>
//             <rect
//               x="0%"
//               y="0%"
//               width="100%"
//               height="100%"
//               rx="50%"
//               fill="url(#ringGradient)"
//               mask={`url(#${maskID})`}
//             />
//           </svg>
//           <div className={classes.status}>
//             <span>{statusText}</span>
//             {/* <span>Acc: 0.97</span>
//             <span>Kappa: 0.88</span> */}
//           </div>
//         </div>
//         <Conditional dependencies={details}>
//           <div className={classes.details} data-active={expanded}>
//             <div
//               className={classes.showMore}
//               role="button"
//               tabIndex={0}
//               onKeyDown={event => {
//                 if (event.keyCode === 13) setExpanded(!expanded)
//               }}
//               onClick={() => setExpanded(!expanded)}
//             >
//               {expanded ? 'show less' : 'show more'}
//             </div>
//             {details}
//           </div>
//         </Conditional>
//       </div>
//       <div className={classes.footer}>
//         <button
//           className={classes.actionButton}
//           disabled={status !== 'RUNNING'}
//           type="button"
//           onClick={() => stopTraining(id)}
//         >
//           <Icon type="pause" />
//           <span>stop</span>
//         </button>
//         <button
//           className={classes.actionButton}
//           type="button"
//           onClick={() => {
//             inspectHandler(id)
//           }}
//         >
//           <Icon type="eye" />
//           <span>inspect</span>
//         </button>
//         <button
//           disabled
//           className={classes.actionButton}
//           type="button"
//           onClick={() => {}}
//         >
//           <Icon type="world" />
//           <span>share</span>
//         </button>
//         <button
//           className={classes.actionButton}
//           type="button"
//           disabled={!(status === 'FINISHED' || status === 'FAILED')}
//           onClick={() => {
//             dialogRef.current.open()
//           }}
//         >
//           <Icon type="trash" />
//           <span>delete</span>
//         </button>
//       </div>
//       <AlertDialog
//         ref={dialogRef}
//         title="Do you want to delete this training?"
//         content="This action will permanently delete training."
//         onOk={event => {
//           deleteTraining(id)
//           dialogRef.current.close()
//         }}
//         onCancel={event => {
//           dialogRef.current.close()
//         }}
//       />
//     </div>
//   )
// }

const TrainingCard = props => {
  const {
    // stopTraining,
    deleteTraining,
    // startTrainingFromResource,
    // fetchTrainingLogs,
  } = useStore(
    'stopTraining',
    'deleteTraining',
    'startTrainingFromResource',
    'fetchTrainingLogs'
  )
  const dialogRef = useRef()
  const detailsDialogRef = useRef()

  const {
    id,
    // name,
    status,
    //  date,
    // onInspect: inspectHandler,
    // original = {},
  } = props

  return (
    <>
      <AlertDialog
        ref={dialogRef}
        title="Do you want to delete this training?"
        content="This action will permanently delete this training."
        onOk={event => {
          event.stopPropagation()
          deleteTraining(id)
          dialogRef.current.close()
        }}
        onCancel={event => {
          event.stopPropagation()
          dialogRef.current.close()
        }}
      />
      <InfoDialog
        ref={detailsDialogRef}
        title="Training logs"
        onBtnClick={event => {
          event.stopPropagation()
          event.preventDefault()
          detailsDialogRef.current.close()
        }}
      >
        <div>TEXT of body</div>
      </InfoDialog>
      <Card className={classes.wrapper}>
        <div className={classes.header}>{}</div>
        <Conditional dependencies={status === 'RUNNING'}>
          <div className={classes.spinner}>
            <Spinner spin={3} />
            <span>running</span>
          </div>
        </Conditional>
      </Card>
    </>
  )
}

TrainingCard.propTypes = {
  // id: PropTypes.string.isRequired,
  // startedAt: PropTypes.string,
  // title: PropTypes.string,
  // status: PropTypes.string,
  // details: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // onInspect: PropTypes.func,

  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  status: PropTypes.string,
  date: PropTypes.string,
  original: PropTypes.any,
  selected: PropTypes.bool.isRequired,
}

export default TrainingCard
