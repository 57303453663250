import React, { forwardRef, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { useStore } from 'core'

import Modal from 'components/Modal'
import { Form, FormItem } from 'components/Form'
import { TextField } from '@material-ui/core'

import './style.scss'

const classes = {
  wrapper: 'EditScoringModal-wrapper',
  formDiv: 'EditScoringModal-formDiv',
}

const EditScoringModal = forwardRef((props, ref) => {
  const formRef = useRef()
  const { editScoring } = useStore('editScoring')
  const { selectedScoring = {} } = props

  return (
    <Modal
      className={classes.wrapper}
      ref={ref}
      title={selectedScoring?.name}
      okText="Save"
      onOk={() => {
        const data = formRef.current.get()
        ref.current.close()
        editScoring({
          id: selectedScoring.id,
          name: data.name,
          description: data.description,
        })
      }}
      onCancel={() => ref.current.close()}
      onClose={() => ref.current.close()}
      footer
    >
      <div className={classes.formDiv}>
        <Form ref={formRef} preload={selectedScoring}>
          <FormItem
            dataKey="name"
            defaultValue=""
            margin="dense"
            label="Name"
            fullWidth
            inputProps={{ autoFocus: true }}
            component={TextField}
          />
          <FormItem
            dataKey="description"
            defaultValue=""
            margin="dense"
            label="Description"
            fullWidth
            multiline
            component={TextField}
          />
        </Form>
      </div>
    </Modal>
  )
})

EditScoringModal.propTypes = {
  selectedScoring: PropTypes.object,
}

export default EditScoringModal
