import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { useInteractiveClick } from 'libs/interactive'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import { MenuItem, TextField } from '@material-ui/core'
import PopUpMultiSelect from 'components/PopUpMultiSelect'

import { IMPUTATION_FUNCTIONS, FEATURE_TYPES } from './stringConstants'

const IMPUTATION_FUNCTION_FEATURE_TYPES = {
  [IMPUTATION_FUNCTIONS.LOCF]: [
    FEATURE_TYPES.NUMERIC,
    FEATURE_TYPES.CATEGORY,
    FEATURE_TYPES.TEXT,
    FEATURE_TYPES.TIME,
  ],
  [IMPUTATION_FUNCTIONS.LOCB]: [
    FEATURE_TYPES.NUMERIC,
    FEATURE_TYPES.CATEGORY,
    FEATURE_TYPES.TEXT,
    FEATURE_TYPES.TIME,
  ],
  [IMPUTATION_FUNCTIONS.MEAN]: [FEATURE_TYPES.NUMERIC, FEATURE_TYPES.TIME],
  [IMPUTATION_FUNCTIONS.MEDIAN]: [FEATURE_TYPES.NUMERIC, FEATURE_TYPES.TIME],
  [IMPUTATION_FUNCTIONS.MIN]: [FEATURE_TYPES.NUMERIC, FEATURE_TYPES.TIME],
  [IMPUTATION_FUNCTIONS.MAX]: [FEATURE_TYPES.NUMERIC, FEATURE_TYPES.TIME],
  [IMPUTATION_FUNCTIONS.LINEAR]: [FEATURE_TYPES.NUMERIC, FEATURE_TYPES.TIME],
  [IMPUTATION_FUNCTIONS.FIXED_VALUE]: [
    FEATURE_TYPES.NUMERIC,
    FEATURE_TYPES.CATEGORY,
    FEATURE_TYPES.TEXT,
    FEATURE_TYPES.TIME,
  ],
}

const classes = {
  wrapper: 'ImputationBox-config-content-wrapper',
  ruleWrapper: 'ImputationBox-config-content-ImputationRule-wrapper',
  ruleDescription: 'ImputationBox-config-content-ImputationRule-description',
  ruleApplication: 'ImputationBox-config-content-ImputationRule-application',
  ruleRemoveButton: 'ImputationBox-config-content-ImputationRule-removeButton',
  addRuleButtonsDiv: 'ConfigBox-addRuleButtonsDiv',
  addRuleButton: 'ConfigBox-addRuleButton',
}

const ImputationRule = ({
  id,
  method,
  value,
  features,
  meta: tempMeta = [],
  callback,
}) => {
  const rule = { id, method, feature_id: features }
  const meta = tempMeta.filter(
    ({ type }) => IMPUTATION_FUNCTION_FEATURE_TYPES[method].indexOf(type) !== -1
  )
  if (value) rule.value = value

  const fixedError = !(value || value === 0)

  return (
    <div className={classes.ruleWrapper}>
      <div className={classes.ruleDescription}>
        <span>Impute</span>
        {method === IMPUTATION_FUNCTIONS.FIXED_VALUE ? (
          <TextField
            error={fixedError}
            value={value}
            onChange={event => {
              callback(id, { ...rule, value: event.target.value })
            }}
            onFocus={event => {
              event.target.select()
            }}
            margin="dense"
            fullWidth
          />
        ) : (
          <span>{method}</span>
        )}
      </div>
      <div className={classes.ruleApplication}>
        <PopUpMultiSelect
          error={!features.length}
          label="Apply to"
          margin="dense"
          fullWidth
          value={JSON.stringify(features)}
          onChange={e => {
            callback(id, {
              ...rule,
              feature_id: JSON.parse(e).sort((a, b) => a - b),
            })
          }}
        >
          {meta.map(feature => (
            <MenuItem key={feature.id} value={feature.id}>
              {feature.name}
            </MenuItem>
          ))}
        </PopUpMultiSelect>
      </div>
      <button
        className={classes.ruleRemoveButton}
        type="button"
        onClick={event => {
          event.stopPropagation()
          callback(id, null)
        }}
        onKeyDown={event => {
          event.stopPropagation()
          if (event.keyCode === 13) {
            callback(id, null)
          }
        }}
      >
        <Icon type="trash" />
      </button>
    </div>
  )
}

const ImputationConfig = ({ config, meta, dataType, callback }) => {
  const contentRef = useRef()
  const ruleCallback = (id, rule) => {
    if (rule) {
      const tempConfig = { ...config }
      tempConfig.rules = [...config.rules].map(e =>
        e.id === id ? { ...rule } : { ...e }
      )
      callback({ ...tempConfig })
    } else {
      const tempConfig = { ...config }
      tempConfig.rules = [...config.rules].filter(e => e.id !== id)
      callback({ ...tempConfig })
    }
  }
  const addRule = useCallback(
    (event, method, value) => {
      const tempConfig = {
        ...config,
        rules: [...config.rules, { id: uuid(), method, feature_id: [] }],
      }
      if (value !== undefined) tempConfig.value = value
      callback(tempConfig)
    },
    [callback, config]
  )
  const { onClick, onKeyDown } = useInteractiveClick(addRule)
  const ruleLength = config?.rules?.length || 0
  useEffect(() => {
    if (contentRef?.current)
      contentRef.current.scrollTop = contentRef.current.scrollHeight
  }, [ruleLength])
  return (
    <>
      <div className={classes.wrapper} ref={contentRef}>
        {config.rules.map(({ id, method, value, feature_id: featureIds }) => (
          <ImputationRule
            key={id}
            id={id}
            method={method}
            value={value}
            features={featureIds}
            meta={meta}
            callback={ruleCallback}
          />
        ))}
      </div>
      <div className={classes.addRuleButtonsDiv}>
        <Conditional dependencies={dataType !== 'basedata'}>
          <button
            className={classes.addRuleButton}
            type="button"
            onClick={event => onClick(event, IMPUTATION_FUNCTIONS.LOCF)}
            onKeyDown={event => onKeyDown(event, IMPUTATION_FUNCTIONS.LOCF)}
          >
            LOCF
          </button>
          <button
            className={classes.addRuleButton}
            type="button"
            onClick={event => onClick(event, IMPUTATION_FUNCTIONS.LOCB)}
            onKeyDown={event => onKeyDown(event, IMPUTATION_FUNCTIONS.LOCB)}
          >
            LOCB
          </button>
          <button
            className={classes.addRuleButton}
            type="button"
            onClick={event => onClick(event, IMPUTATION_FUNCTIONS.LINEAR)}
            onKeyDown={event => onKeyDown(event, IMPUTATION_FUNCTIONS.LINEAR)}
          >
            Linear
          </button>
        </Conditional>
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => onClick(event, IMPUTATION_FUNCTIONS.MEAN)}
          onKeyDown={event => onKeyDown(event, IMPUTATION_FUNCTIONS.MEAN)}
        >
          Mean
        </button>
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => onClick(event, IMPUTATION_FUNCTIONS.MEDIAN)}
          onKeyDown={event => onKeyDown(event, IMPUTATION_FUNCTIONS.MEDIAN)}
        >
          Median
        </button>
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => onClick(event, IMPUTATION_FUNCTIONS.MIN)}
          onKeyDown={event => onKeyDown(event, IMPUTATION_FUNCTIONS.MIN)}
        >
          Min
        </button>
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => onClick(event, IMPUTATION_FUNCTIONS.MAX)}
          onKeyDown={event => onKeyDown(event, IMPUTATION_FUNCTIONS.MAX)}
        >
          Max
        </button>
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => onClick(event, IMPUTATION_FUNCTIONS.FIXED_VALUE, '')}
          onKeyDown={event => onKeyDown(event, IMPUTATION_FUNCTIONS.FIXED_VALUE, '')}
        >
          Fixed
        </button>
      </div>
    </>
  )
}

ImputationConfig.propTypes = {
  config: PropTypes.object,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  dataType: PropTypes.oneOf(['basedata', 'timeseries', 'stacked-timeseries']),
  callback: PropTypes.func,
}

ImputationRule.propTypes = {
  id: PropTypes.string,
  method: PropTypes.string,
  value: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.number),
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  callback: PropTypes.func,
}

export default ImputationConfig
