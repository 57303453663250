import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import { formatDate } from 'libs/utils/helpers'

import Icon from 'components/Icon'
import { SimpleResourceDialog } from 'components/ResourceModalComponents'
import { Tooltip } from '@material-ui/core'

import './style.scss'

const classes = {
  wrapper: 'DatasetTableEntry-wrapper',
  cell: 'DatasetTableEntry-cell',
  name: 'DatasetTableEntry-name',
  date: 'DatasetTableEntry-date',
  status: 'DatasetTableEntry-status',
  type: 'DatasetTableEntry-type',
  actions: 'DatasetTableEntry-actions',
  actionButton: 'DatasetTableEntry-actionButton',
}

const DatasetTableEntry = props => {
  const dialogRef = useRef()
  const { setSelectedDataset, removeDataset } = useStore(
    'setSelectedDataset',
    'removeDataset'
  )
  const {
    id,
    name = '',
    type = '',
    status = '',
    date = '',
    original = {},
    selected,
  } = props

  const dataset = { ...original }
  if (!dataset.spec) dataset.spec = { features: [] }
  else if (!dataset.spec.features) dataset.spec.features = []
  const filteredFeatures = dataset.spec.features.filter(
    feature => feature.type === 'category'
  )
  if (typeof dataset.spec.stacked_by !== 'number') {
    if (filteredFeatures.length) dataset.spec.stacked_by = ''
    else dataset.spec.stacked_by = 'error'
  } else dataset.spec.stacked_by = original.spec.stacked_by

  return (
    <>
      <SimpleResourceDialog
        ref={dialogRef}
        titleText="Do you want to delete this dataset?"
        contentText="This action will permanently delete this dataset."
        okFunction={() => removeDataset({ id })}
      />
      <div
        className={`${classes.wrapper}${selected ? ' selected' : ''}`}
        role="button"
        tabIndex={0}
        onClick={() => setSelectedDataset(dataset.id)}
        onKeyDown={event => {
          if (event.keyCode === 13) setSelectedDataset(dataset.id)
        }}
      >
        <Tooltip title={name}>
          <div className={`${classes.cell} ${classes.name}`}>
            <span>{name}</span>
          </div>
        </Tooltip>
        <div className={`${classes.cell} ${classes.type}`}>
          <span>{type}</span>
        </div>
        <Tooltip title={status}>
          <div className={`${classes.cell} ${classes.status}`}>
            <span>{status}</span>
          </div>
        </Tooltip>
        <div className={`${classes.cell} ${classes.date}`}>
          <span>{formatDate(date)}</span>
        </div>
        <div className={`${classes.cell} ${classes.actions}`}>
          <button
            type="button"
            className={classes.actionButton}
            onClick={event => {
              event.stopPropagation()
              dialogRef.current.open()
            }}
            onKeyDown={event => {
              event.stopPropagation()
              if (event.keyCode === 13) {
                dialogRef.current.open()
              }
            }}
          >
            <Icon type="trash" />
          </button>
        </div>
      </div>
    </>
  )
}

DatasetTableEntry.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  date: PropTypes.string,
  original: PropTypes.object,
  selected: PropTypes.bool,
}

export default DatasetTableEntry
