import React, { useEffect, useMemo, useState } from 'react'
import { useStore } from 'core'
import { extractValue } from 'libs/utils/helpers'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import Spinner from 'components/Spinner'
import { LineChart, ScatterChart, ConfusionMatrixChart } from 'components/Charts'
import UserControlsBar from 'components/UserControls'
import { Table, Column } from 'components/Table'
import { Dialog, Button, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import './style.scss'

const classes = {
  dialog: 'TrainingInspector-dialog',
  wrapper: 'TrainingInspector-wrapper',
  header: 'TrainingInspector-header',
  title: 'TrainingInspector-title',
  trainingStatus: 'TrainingInspector-trainingStatus',
  closeButton: 'TrainingInspector-closeButton',
  content: 'TrainingInspector-content',
  userControlsDiv: 'TrainingInspector-userControlsDiv',
  visualsDiv: 'TrainingInspector-visualsDiv',
  graphsDiv: 'TrainingInspector-graphsDiv',
  infoIcon: 'TrainingInspector-infoIcon',
  rightSideDiv: 'TrainingInspector-rightSideDiv',
  tableDiv: 'TrainingInspector-tableDiv',
  footer: 'TrainingInspector-footer',
}

const TrainingInspector = () => {
  const [chartOptions, setChartOptions] = useState({
    selectedBrains: [],
    split: 'test',
  })
  const { selectedBrains, split } = chartOptions

  const {
    inspectedTraining: trainingId,
    trainings,
    brains: allBrains,
    pref,
    registerBrainsRequest,
    setInspectedTraining,
  } = useStore(
    'selectInspectedTraining',
    'selectTrainings',
    'selectBrains',
    // `selectBrains[${training.id}]`,
    'selectPref',
    'registerBrainsRequest',
    'setInspectedTraining'
  )

  const collapsed = !!pref?.menu?.collapsed

  // const trainingId = training?.id

  const inspectedTraining =
    trainings?.find(training => training.id === trainingId) || {}

  const activeBrains = [{ id: trainingId, brains: selectedBrains }]

  const brains = useMemo(
    () => allBrains?.find(training => training.id === trainingId)?.data || [],
    [allBrains, trainingId]
  )

  const tableBrains = useMemo(
    () =>
      (brains?.objectives || {})[split]?.map(brain => ({
        ...brain,
        'combined [loss] endpoint': ((brains?.learningCurve || {})[split]?.find(
          lrnBrain => lrnBrain.id === brain.id
        )?.lossEndPoint || {})['combined [loss]'],
      })) || [],
    [split, brains]
  )

  const learningCurveObjectives =
    brains?.learningCurve?.objectives?.filter(
      ({ name }) => name === 'combined [loss]'
    ) || []
  const rocCurveFeatures = brains?.rocCurve?.features || []

  const activeBrainObjectives =
    brains?.objectives?.objectives?.filter(({ type }) => type === 'numeric') || []

  useEffect(() => {
    if (trainingId) registerBrainsRequest(trainingId)
  }, [trainingId, registerBrainsRequest])

  return (
    <Dialog
      className={`${classes.dialog}${collapsed ? ' wide' : ''}`}
      fullScreen
      open={!!trainingId}
      onClose={() => {
        setInspectedTraining(null)
        setChartOptions({ ...chartOptions, selectedBrains: [], split: 'train' })
      }}
      onCancel={() => {
        setInspectedTraining(null)
        setChartOptions({ ...chartOptions, selectedBrains: [], split: 'train' })
      }}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.title}>{inspectedTraining.name || null}</div>
          <div className={classes.trainingStatus}>
            {`(${inspectedTraining.status || null})`}
          </div>
          <Button
            className={classes.closeButton}
            type="button"
            onClick={() => {
              setInspectedTraining(null)
              setChartOptions({
                ...chartOptions,
                selectedBrains: [],
                split: 'train',
              })
            }}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.visualsDiv}>
            <div className={classes.graphsDiv}>
              <div>
                <Tooltip
                  // eslint-disable-next-line max-len
                  title="The loss of each prediction output is a measure of the prediction error. It is calculated for training, validation and test set and is supposed to decline over the number of epochs (epoch = complete dataset evaluation)."
                  placement="right"
                >
                  <span>
                    Loss curves
                    <Icon className={classes.infoIcon} type="info" />
                  </span>
                </Tooltip>
                <LineChart
                  legend={{ width: 212 }}
                  xAxis
                  yAxis={{ label: ' ', width: 48 }}
                  grid
                  trainings={inspectedTraining}
                  brains={activeBrains}
                  features={learningCurveObjectives}
                  split={split}
                  type="learningCurve"
                  // domain={{ x: [-5, 5], y: { min: null, max: 2 } }}
                  // domain={{ y: { min: 2, max: 3 } }}
                  domain={{ y: { min: -2, max: 2 } }}
                  mainColor="#1E90FF"
                />
              </div>
              <div>
                <Tooltip
                  // eslint-disable-next-line max-len
                  title="The scatter plot shows the objectives for each prediction output. Newer models (with higher index) are supposed to show improvements in the objective function."
                  placement="right"
                >
                  <span>
                    Objective function plot
                    <Icon className={classes.infoIcon} type="info" />
                  </span>
                </Tooltip>
                <ScatterChart
                  legend={{ width: 212 }}
                  xAxis
                  yAxis={{ label: ' ', width: 48 }}
                  grid
                  trainings={inspectedTraining}
                  brains={activeBrains}
                  split={split}
                  x={activeBrainObjectives[0]}
                  y={activeBrainObjectives[activeBrainObjectives.length - 1]}
                  z={{ name: 'name', type: 'category', id: 'name-category' }}
                  mainColor="#1E90FF"
                />
              </div>
              <Conditional
                dependencies={inspectedTraining?.spec?.objectives?.find(
                  ({ type }) => type === 'binary_classification'
                )}
              >
                <div>
                  <Tooltip
                    // eslint-disable-next-line max-len
                    title="Plots the False Positive Rate (fpr) over the True Positive Rate (tpr). It is only available in the case of a binary classification task and is supposed to have a large area under the curve."
                    placement="right"
                  >
                    <span>
                      ROC curves
                      <Icon className={classes.infoIcon} type="info" />
                    </span>
                  </Tooltip>
                  <LineChart
                    legend={{ width: 212 }}
                    xAxis
                    yAxis={{ label: ' ', width: 48 }}
                    grid
                    trainings={inspectedTraining}
                    brains={activeBrains}
                    features={rocCurveFeatures}
                    split={split}
                    type="rocCurve"
                    mainColor="#1E90FF"
                  />
                </div>
              </Conditional>
              <Conditional
                dependencies={inspectedTraining?.spec?.objectives?.find(
                  ({ type }) =>
                    type === 'binary_classification' || type === 'classification'
                )}
              >
                <div>
                  <Tooltip
                    // eslint-disable-next-line max-len
                    title="This matrix is available for every model and shows the prediction of the model versus the ground truth. It is supposed to mostly have zero values off the diagonal. The class <UNK> is the sink class for all unknown classes."
                    placement="right"
                  >
                    <span>
                      Confusion matrix
                      <Icon className={classes.infoIcon} type="info" />
                    </span>
                  </Tooltip>
                  <ConfusionMatrixChart
                    trainings={inspectedTraining}
                    brains={activeBrains}
                    legend={{ position: 'left' }}
                    split={split}
                    stripPosition="left"
                    // mainColor="#171a24"
                    mainColor="#123456"
                  />
                </div>
              </Conditional>
            </div>
            <div className={classes.rightSideDiv}>
              <div className={classes.userControlsDiv}>
                <UserControlsBar
                  controls={[
                    {
                      control: 'trainingSplitSelect',
                      values: [split],
                      callback: values =>
                        setChartOptions({
                          ...chartOptions,
                          split: values[0] || 'train',
                        }),
                    },
                  ]}
                />
              </div>
              <div className={classes.tableDiv}>
                <Conditional dependencies={tableBrains?.length}>
                  <Table
                    data={tableBrains}
                    // footer={{
                    //   manageColumns: false,
                    //   pagination: { perPage: 13, currentPage, setCurrentPage },
                    // }}
                    check={{
                      checked: selectedBrains,
                      id: 'id',
                      checkCallback: (r, checked, ar, id) => {
                        if (checked) {
                          setChartOptions({
                            ...chartOptions,
                            selectedBrains: selectedBrains.filter(c => c !== id),
                          })
                        } else {
                          const newList = [...selectedBrains]
                          newList.push(id)
                          setChartOptions({
                            ...chartOptions,
                            selectedBrains: newList,
                          })
                        }
                      },
                      checkAllCallback: (ar, checked) => {
                        if (checked) {
                          setChartOptions({ ...chartOptions, selectedBrains: [] })
                        } else {
                          const newList = ar.map(r => extractValue(r, 'id'))
                          setChartOptions({
                            ...chartOptions,
                            selectedBrains: newList,
                          })
                        }
                      },
                    }}
                  >
                    <Column
                      title="Name"
                      dataKey="name"
                      width={150}
                      sortFunction={(a, b) => {
                        const aVal = extractValue(a, 'name')
                        const bVal = extractValue(b, 'name')
                        let result = 0
                        if (typeof aVal === 'string' || typeof bVal === 'string') {
                          result = `${aVal}`.localeCompare(`${bVal}`, 'en', {
                            sensitivity: 'base',
                          })
                        } else result = aVal - bVal
                        return result
                      }}
                    />
                    <Column title="ID" dataKey="id" hidden />
                    <Column
                      title="combined [loss] endpoint"
                      dataKey="combined [loss] endpoint"
                      width={250}
                    />
                    <Column
                      dataKey="_defaultColumn"
                      width={180}
                      render={(val, header, data) => {
                        if (header) return val
                        return Math.round(val * 1000000) / 1000000
                      }}
                      sortFunction={(a, b, key) => {
                        const aVal = extractValue(a, key)
                        const bVal = extractValue(b, key)
                        let result = 0
                        if (typeof aVal === 'string' || typeof bVal === 'string') {
                          result = `${aVal}`.localeCompare(`${bVal}`, 'en', {
                            sensitivity: 'base',
                          })
                        } else result = aVal - bVal
                        return result
                      }}
                    />
                  </Table>
                </Conditional>
                <Conditional dependencies={!tableBrains?.length}>
                  <Spinner
                    spin={1}
                    strokeWidth={2}
                    mainColor="#1E90FF"
                    emptyColor="#2e313a"
                  />
                </Conditional>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={classes.footer}>{null}</div> */}
      </div>
    </Dialog>
  )
}

export default TrainingInspector
