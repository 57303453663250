/* eslint-disable prefer-destructuring */
import React from 'react'
import PropTypes from 'prop-types'

const classes = {
  barsGroup: 'CurrencyGraph-barsGroup',
  bar: 'CurrencyGraph-bar',
}

const Bars = props => {
  const { data, viewBox, range, bounds } = props

  const min = -viewBox.y
  const viewBoxHeightPercent = 0.25
  const height = viewBox.yRange * viewBoxHeightPercent

  const bars = [...data]
    .reverse()
    .filter(e => e.x >= bounds.xMin && e.x <= bounds.xMax)
    .reduce((acc, cur, i) => {
      const res = [...acc]
      let lastBar = res[0]

      const barColors = ['red', 'green']

      if (!lastBar) {
        res.push({
          xMin: cur.x - range,
          xMax: cur.x,
          barHeight: cur.bar || 0,
          color: barColors[Math.round(Math.random())],
        })
        lastBar = res[0]
      } else if (cur.x <= lastBar.xMin) {
        while (cur.x <= lastBar.xMin) {
          res.unshift({
            xMin: lastBar.xMin - range,
            xMax: lastBar.xMin,
            barHeight: 0,
            color: barColors[Math.round(Math.random())],
          })
          lastBar = res[0]
        }
        lastBar.barHeight += cur.bar || 0
      } else lastBar.barHeight += cur.bar || 0
      return res
    }, [])

  const barHeightMax =
    bars.reduce((acc, cur) => Math.max(acc, cur.barHeight), 0) || 1

  const barHeightRatio = height / barHeightMax

  return (
    <g className={classes.barsGroup}>
      {bars.map(({ xMin, barHeight, color }) => (
        <rect
          key={`${xMin}`}
          className={`${classes.bar} ${color}`}
          x={xMin + range * 0.05}
          width={range * 0.9}
          y={min - barHeight * barHeightRatio}
          height={barHeight * barHeightRatio}
        />
      ))}
    </g>
  )
}

Bars.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  viewBox: PropTypes.shape({ y: PropTypes.number, yRange: PropTypes.number }),
  range: PropTypes.number,
  bounds: PropTypes.shape({
    xMin: PropTypes.number,
    xMax: PropTypes.number,
    yMin: PropTypes.number,
    yMax: PropTypes.number,
  }),
}

export default Bars
