/* eslint-disable no-underscore-dangle */
import React from 'react'
import Conditional from 'components/Conditional'
import Icon from 'components/Icon'

import { Tooltip } from '@material-ui/core'
import { classes } from '../classNames'

const renderer = (
  resourceId,
  resourceMeta,
  columnTypes,
  columnStats,
  sort,
  setSort,
  statsVisible,
  toggleStats,
  context
) => {
  const { openExplainer, requestModelExplanation } = context
  const count = resourceMeta?.count || 0
  const sortKey =
    resourceMeta?.features?.find(feat => feat.id === sort.key)?.name || null
  const sortOrder = sort.order === 'asc' ? 1 : -1

  const maxStatsCount =
    Object.keys(columnStats).reduce(
      (acc, key) => Math.max(columnStats[key].length, acc),
      0
    ) + 1
  const height = 40 * maxStatsCount + 5 * (maxStatsCount - 1) + 56

  const modelMeta =
    resourceMeta?.features?.reduce((acc, feature) => {
      if (feature.objective && feature.brain_id)
        acc[feature.name] = {
          brain: feature.brain_id,
          objective: feature.objective,
        }
      else acc[feature.name] = null
      return acc
    }, {}) || {}

  const explanations = (context.explanations || []).reduce((acc, ex) => {
    acc[ex.column] = acc[ex.column] || {}
    if (ex.row || ex.row === 0) acc[ex.column][ex.row] = ex
    else acc[ex.column].overall = ex
    return acc
  }, {})

  const inspectHandler = (dataKey, r) => {
    const row = r || 'overall'
    const { objective, brain } = modelMeta[dataKey]
    const hasExplanation = !!explanations?.[dataKey]?.[row]
    const explanationData = hasExplanation ? explanations[dataKey][row].data : null
    if (explanationData) openExplainer(dataKey, row)
    else if (r) requestModelExplanation(resourceId, brain, objective, row)
  }

  return (key, header, record, records, dataKey) => {
    const { objective, brain } = modelMeta[dataKey] || {}
    if (!header) {
      if (objective && brain) {
        const hasExplanation = !!explanations?.[dataKey]?.[record._id]
        const explanationData = hasExplanation
          ? explanations[dataKey][record._id].data
          : null
        // const isProcessing = hasExplanation && !explanationData
        const isProcessing =
          hasExplanation &&
          (explanations[dataKey][record._id].status === 'STARTING' ||
            explanations[dataKey][record._id].status === 'RUNNING')
        return (
          <>
            <span
              style={{
                display: 'block',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {key}
            </span>
            <Conditional dependencies={key || key === 0}>
              {isProcessing ? (
                <Icon type="spinner" style={{ marginLeft: '10px' }} />
              ) : (
                <Tooltip title="View explanation">
                  <Icon
                    type="eye"
                    style={{
                      marginLeft: '10px',
                      color: explanationData ? '#00FF00' : 'orange',
                      cursor: 'pointer',
                    }}
                    onClick={() => inspectHandler(dataKey, record._id)}
                  />
                </Tooltip>
              )}
            </Conditional>
          </>
        )
      }
      return key
    }
    const stats = [...columnStats[key], { name: 'count', value: count }]
    const missing = stats.find(stat => stat?.name === 'nmissing')?.value
    const hasExplanation = objective && brain && !!explanations?.[dataKey]?.overall
    const explanationData = hasExplanation
      ? explanations[dataKey].overall.data
      : null
    const isProcessing =
      hasExplanation &&
      (explanations[dataKey].overall.status === 'STARTING' ||
        explanations[dataKey].overall.status === 'RUNNING')

    return (
      <>
        <div
          className={classes.headerSortingCellDiv}
          role="button"
          tabIndex={-1}
          onClick={event => {
            event.stopPropagation()
            const order = sortKey === key ? (sortOrder + 1) % 3 : 1
            const feature = resourceMeta?.features?.find(feat => feat.name === key)
            if (feature && order) {
              const newKey = feature.id
              const newOrder = order === 1 ? 'asc' : 'desc'
              if (newKey || newKey === 0) setSort(newKey, newOrder)
            } else setSort(null, '')
          }}
          onKeyDown={event => {
            event.stopPropagation()
            if (event.keyCode === 13) {
              const order = sortKey === key ? (sortOrder + 1) % 3 : 1
              const feature = resourceMeta?.features?.find(feat => feat.name === key)
              if (feature && order) {
                const newKey = feature.id
                const newOrder = order === 1 ? 'asc' : 'desc'
                if (newKey || newKey === 0) setSort(newKey, newOrder)
              } else setSort(null, '')
            }
          }}
        >
          <div className={classes.textDiv}>
            <div>{key}</div>
            <div>
              <span>{columnTypes[key]}</span>
              <Conditional dependencies={objective && brain}>
                <Conditional dependencies={explanationData}>
                  <Tooltip title="View explanation average">
                    <Icon
                      type="eye"
                      style={{
                        marginLeft: '10px',
                        color: '#00FF00',
                        cursor: 'pointer',
                      }}
                      onClick={event => {
                        event.stopPropagation()
                        inspectHandler(dataKey, null)
                      }}
                    />
                  </Tooltip>
                </Conditional>
                {isProcessing ? (
                  <Icon type="spinner" style={{ marginLeft: '10px' }} />
                ) : (
                  <Tooltip title="Request 500 random explanations">
                    <Icon
                      type="play"
                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                      onClick={event => {
                        event.stopPropagation()
                        const { objective: obj, brain: brn } = modelMeta[dataKey]
                        requestModelExplanation(resourceId, brn, obj, null)
                      }}
                    />
                  </Tooltip>
                )}
              </Conditional>
            </div>
          </div>
          <div
            className={
              classes.sortingArrow +
              (sortKey === key && sortOrder === 1 ? ' ascending' : '')
            }
          >
            <Conditional dependencies={sortKey === key}>
              <Icon type="arrow-down" />
            </Conditional>
          </div>
        </div>
        <div
          className={classes.dropDownHeader}
          style={{
            flexDirection: statsVisible ? 'column-reverse' : '',
            height: statsVisible ? `${height}px` : '',
            background: statsVisible
              ? `linear-gradient(0deg, rgba(23, 26, 36, 1) 0px, rgba(23, 26, 36, 0.9) ${
                  height - 80
                }px, rgba(255,255,255,0) ${height - 45}px)`
              : '',
            cursor: statsVisible ? 'inherit' : '',
            top: statsVisible ? '0' : '',
          }}
        >
          {stats.map(stat => {
            const { name, value } = stat || {}
            return (
              <div key={`stat:${name}/${value}`} className={classes.stat}>
                {`${name}: ${value}`}
              </div>
            )
          })}
          <div
            className={statsVisible ? 'open' : ''}
            role="button"
            tabIndex={-1}
            style={
              {
                // display: open
                //   ?
                //     'none'
                //   : '',
              }
            }
            onClick={event => {
              event.stopPropagation()
              toggleStats()
            }}
            onKeyDown={() => {}}
          >
            <Icon type="arrow-down" />
          </div>
        </div>
        <svg
          className={classes.svgBar}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1 1"
          preserveAspectRatio="none"
        >
          <rect x="0%" y="0%" width="100%" height="100%" fill="#171a24" />
          <rect
            x="0%"
            y="0%"
            width={`${count ? (1 - (missing || 0) / count) * 100 : 100}%`}
            height="100%"
            fill="dodgerblue"
          />
        </svg>
      </>
    )
  }
}

export default renderer
