import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'core'

// import TrainingsRouter from './TrainingsRouter'
// import TransformationsRouter from './TransformationsRouter'
// import ScoringsRouter from './ScoringsRouter'

import TransformationsPage from 'pages/Transformations'
import TrainingsPage from 'pages/Trainings'
import ScoringsPage from 'pages/Scorings'
import DatasourcesRouter from './DatasourcesRouter'

// import ProjectsPage from 'pages/Projects'

const ResourcesRouter = props => {
  const { match } = props

  return (
    <Switch>
      <Redirect
        path={`${match.path}`}
        to={({ match: route }) => {
          return `${route.url}/datasources`
        }}
      />
      <Route path={`${match.path}/datasources`} component={DatasourcesRouter} />
      <Route path={`${match.path}/pipelines`} component={TransformationsPage} />
      <Route path={`${match.path}/models`} component={TrainingsPage} />
      <Route path={`${match.path}/predictions`} component={ScoringsPage} />
      {/* <Route path={`${match.path}`} component={ProjectsPage} /> */}
      <Route
        render={({ location: loc }) => {
          return (
            <pre>{`  404 Page Not Found\n\n  The page under "${loc.pathname}" does not exist.`}</pre>
          )
        }}
      />
    </Switch>
  )
}

ResourcesRouter.propTypes = {
  match: PropTypes.exact({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.object,
    isExact: PropTypes.bool,
  }),
}

export default ResourcesRouter
