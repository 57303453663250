import React, { useState, useCallback, useImperativeHandle, forwardRef } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'

import './style.scss'

const AlertDialog = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)

  const {
    title,
    content,
    okText = 'Yes',
    cancelText = 'No',
    onOk,
    onCancel,
    onClose,
    open: isOpen,
    ...rest
  } = props

  const close = useCallback(() => {
    setVisible(false)
  }, [])
  const open = useCallback(() => {
    setVisible(true)
  }, [])

  useImperativeHandle(ref, () => ({
    close,
    open,
  }))

  const showDialog = isOpen !== undefined ? !!isOpen : visible

  return (
    <Dialog
      classes={{ root: 'AlertDialog' }}
      open={showDialog}
      onClick={event => event.stopPropagation()}
      //  esc + enter handler
      onKeyDown={event => {
        if (event.keyCode === 13) onOk(event)
        if (event.keyCode === 27) onCancel(event)
      }}
      //  click background handler
      onClose={event => {
        onCancel && onCancel(event)
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <DialogTitle classes={{ root: 'AlertDialog-title' }}>
        <Typography>{title}</Typography>
      </DialogTitle>
      <MuiDialogContent>
        <DialogContentText>{content}</DialogContentText>
      </MuiDialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" variant="outlined">
          {cancelText}
        </Button>
        <Button onClick={onOk} color="primary" variant="contained">
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  )
})

AlertDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

export default AlertDialog
