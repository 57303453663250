import React, { useRef, useCallback } from 'react'
import { useStore } from 'core'
import moment from 'moment'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import {
  LogsInfoDialog,
  EditScoringModal,
  AutomationModal,
} from 'components/ResourceModalComponents'
import { Tooltip } from '@material-ui/core'

const classes = {
  wrapper: 'ScoringsMenu-wrapper',
  menuGroup: 'ScoringsMenu-menuGroup',
  title: 'ScoringsMenu-title',
  controlsDiv: 'ScoringsMenu-controlsDiv',
  control: 'ScoringsMenu-control',
}

const ScoringsMenu = () => {
  const logsDialogRef = useRef()
  const editModalRef = useRef()
  const automationModalRef = useRef()
  const {
    logs,
    scorings,
    selectedScoring: selectedScoringId,
    selectedScoredDataset: selectedScoredDatasetId,
    fetchScoringLogs,
    initializeScoringWizard,
    // runScoring,
    startScoringFromResource,
    downloadDataset,
    setSelectedScoring,
  } = useStore(
    'selectLogs',
    'selectScorings',
    'selectSelectedScoring',
    'selectSelectedScoredDataset',
    'fetchScoringLogs',
    'initializeScoringWizard',
    'startScoringFromResource',
    'runScoring',
    'downloadDataset',
    'setSelectedScoring'
  )

  const selectedScoring =
    scorings?.find(({ id }) => id === selectedScoringId) || null

  const startScoringFromResourceCallback = useCallback(
    scoring => {
      if (!scoring) return
      startScoringFromResource(
        scoring.id,
        'scoring',
        true,
        `${scoring.name} on ${moment(Date.now()).format('DD.MM.YYYY. HH:mm')}`
      )
    },
    [startScoringFromResource]
  )

  return (
    <>
      <EditScoringModal ref={editModalRef} selectedScoring={selectedScoring} />
      <LogsInfoDialog
        ref={logsDialogRef}
        title="Scoring Logs"
        logs={logs.find(e => e.id === selectedScoredDatasetId) || []}
      />
      <AutomationModal
        ref={automationModalRef}
        resource="scoring"
        resourceId={selectedScoringId}
      />
      <div className={classes.wrapper}>
        <div
          className={classes.menuGroup}
          style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
        >
          <div className={classes.controlsDiv} style={{ height: '2em' }}>
            <Tooltip title="NEW scoring">
              <div
                className={classes.control}
                style={{ width: '2em' }}
                role="button"
                tabIndex={0}
                onClick={event => {
                  event.stopPropagation()
                  initializeScoringWizard(
                    null,
                    null,
                    null,
                    null,
                    null,
                    false,
                    true,
                    true,
                    0
                  )
                }}
                onKeyDown={event => {
                  event.stopPropagation()
                  if (event.keyCode === 13)
                    initializeScoringWizard(
                      null,
                      null,
                      null,
                      null,
                      null,
                      false,
                      true,
                      true,
                      0
                    )
                }}
              >
                <Icon type="score" />
              </div>
            </Tooltip>
          </div>
          {/* <div className={classes.title} style={{ height: '18px' }}>
            Train
          </div> */}
        </div>
        <Conditional dependencies={!!selectedScoringId}>
          <div
            className={classes.menuGroup}
            style={{ width: `calc(${2 * 1.5}em + ${(2 + 1) * 10}px)` }}
          >
            <div className={classes.title} style={{ height: '26px' }}>
              Scoring
            </div>
            <div className={classes.controlsDiv} style={{ height: '1.5em' }}>
              <Tooltip title="SCORE a new dataset">
                <div
                  className={
                    classes.control + (selectedScoringId ? '' : ' disabled')
                  }
                  // className={`${classes.control} disabled`}
                  style={{ width: '1.5em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedScoringId)}
                  // tabIndex={-1}
                  onClick={event => {
                    event.stopPropagation()
                    if (selectedScoringId) {
                      startScoringFromResourceCallback(selectedScoring)
                    }
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13 && selectedScoringId) {
                      startScoringFromResourceCallback(selectedScoring)
                    }
                  }}
                >
                  <Icon type="score" />
                </div>
              </Tooltip>
              {/* <Tooltip title="RUN selected scoring">
                <div
                  className={
                    classes.control + (selectedScoringId ? '' : ' disabled')
                  }
                  // className={`${classes.control} disabled`}
                  style={{ width: '1.5em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedScoringId)}
                  // tabIndex={-1}
                  onClick={event => {
                    event.stopPropagation()
                    if (selectedScoringId) {
                      runScoring(selectedScoringId)
                    }
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13 && selectedScoringId) {
                      runScoring(selectedScoringId)
                    }
                  }}
                >
                  <Icon type="play" />
                </div>
              </Tooltip> */}
              <Tooltip title="EDIT selected scoring">
                <div
                  className={
                    classes.control + (selectedScoringId ? '' : ' disabled')
                  }
                  style={{ width: '1.5em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedScoringId)}
                  onClick={event => {
                    event.stopPropagation()
                    editModalRef.current.open()
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) editModalRef.current.open()
                  }}
                >
                  <Icon type="edit-box" />
                </div>
              </Tooltip>
            </div>
          </div>
          <Conditional dependencies={!!selectedScoredDatasetId}>
            <div
              className={classes.menuGroup}
              style={{ width: `calc(${2 * 1.5}em + ${(2 + 1) * 10}px)` }}
            >
              <div className={classes.title} style={{ height: '26px' }}>
                Dataset
              </div>
              <div className={classes.controlsDiv} style={{ height: '1.5em' }}>
                <Tooltip title="VIEW selected dataset logs">
                  <div
                    className={
                      classes.control + (selectedScoredDatasetId ? '' : ' disabled')
                    }
                    style={{ width: '1.5em' }}
                    role="button"
                    tabIndex={-1 + Number(!!selectedScoredDatasetId)}
                    onClick={event => {
                      event.stopPropagation()
                      fetchScoringLogs(selectedScoredDatasetId)
                      logsDialogRef.current.open()
                    }}
                    onKeyDown={event => {
                      event.stopPropagation()
                      if (event.keyCode === 13) {
                        fetchScoringLogs(selectedScoredDatasetId)
                        logsDialogRef.current.open()
                      }
                    }}
                  >
                    <Icon type="list" />
                  </div>
                </Tooltip>
                <Tooltip title="DOWNLOAD selected dataset">
                  <div
                    className={
                      classes.control + (selectedScoredDatasetId ? '' : ' disabled')
                    }
                    style={{ width: '1.5em' }}
                    role="button"
                    tabIndex={-1 + Number(!!selectedScoredDatasetId)}
                    onClick={event => {
                      event.stopPropagation()
                      downloadDataset({ id: selectedScoredDatasetId })
                    }}
                    onKeyDown={event => {
                      event.stopPropagation()
                      if (event.keyCode === 13)
                        downloadDataset({ id: selectedScoredDatasetId })
                    }}
                  >
                    <Icon type="download" />
                  </div>
                </Tooltip>
              </div>
            </div>
          </Conditional>
          <div
            className={classes.menuGroup}
            style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
          >
            <div className={classes.controlsDiv} style={{ height: '2em' }}>
              <Tooltip title="AUTOMATE selected scoring">
                <div
                  className={
                    classes.control + (selectedScoringId ? '' : ' disabled')
                  }
                  style={{ width: '2em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedScoringId)}
                  onClick={event => {
                    event.stopPropagation()
                    automationModalRef.current.open()
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) automationModalRef.current.open()
                  }}
                >
                  <Icon type="automate" />
                </div>
              </Tooltip>
            </div>
            {/* <div className={classes.title} style={{ height: '18px' }}>
                AUTO
              </div> */}
          </div>
          <div
            className={classes.menuGroup}
            style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
          >
            <div className={classes.controlsDiv} style={{ height: '2em' }}>
              <Tooltip title="DESELECT">
                <div
                  className={
                    classes.control + (selectedScoredDatasetId ? '' : ' disabled')
                  }
                  style={{ width: '2em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedScoredDatasetId)}
                  onClick={event => {
                    event.stopPropagation()
                    setSelectedScoring(null)
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) setSelectedScoring(null)
                  }}
                >
                  <Icon type="close" />
                </div>
              </Tooltip>
            </div>
            {/* <div className={classes.title} style={{ height: '18px' }}>
              {null}
            </div> */}
          </div>
        </Conditional>
      </div>
    </>
  )
}

export default ScoringsMenu
