import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

// import Conditional from 'components/Conditional'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import VisibilityIcon from '@material-ui/icons/Visibility'
import PauseIcon from '@material-ui/icons/Pause'
import PublicIcon from '@material-ui/icons/Public'
import DeleteIcon from '@material-ui/icons/Delete'
import Icon from 'components/Icon'

import './ModelStyle.scss'

const classes = {
  wrapper: 'ModelCard-wrapper',
  content: 'ModelCard-content',
  header: 'ModelCard-header',
  showMore: 'ModelCard-showMore',
  main: 'ModelCard-main',
  footer: 'ModelCard-footer',
  actionButton: 'ModelCard-actionButton',
  details: 'ModelCard-details',
}

const ModelCard = props => {
  const [expanded, setExpanded] = useState(false)

  const { title, status, details } = props
  // const progress = Math.round(Math.max(0, Math.min(1, propProgress)) * 100) / 100
  const progress = 0

  const maskID = `ringMask-${uuid()}`
  const mask = (
    <rect x="0%" y="0%" width="100%" height="100%" rx="50%" fill="white" />
  )

  return (
    <div
      className={classes.wrapper}
      onMouseLeave={() => {
        setExpanded(false)
      }}
    >
      <div className={classes.header}>{title}</div>
      <div className={classes.content}>
        <div className={classes.main + (expanded ? ' expanded' : '')}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <mask id={maskID}>
              {mask}
              <rect x="2%" y="2%" width="96%" height="96%" rx="50%" fill="black" />
            </mask>
            <mask id="progressRingMask">
              <rect x="0%" y="0%" width="100%" height="100%" rx="50%" fill="white" />
              <rect x="2%" y="2%" width="96%" height="96%" rx="50%" fill="black" />
            </mask>
            <defs>
              <radialGradient
                id="ringGradient"
                cx={0.5 + 0.5 * Math.sin(2 * Math.PI * (progress - 0.2))}
                cy={0.5 - 0.5 * Math.cos(2 * Math.PI * (progress - 0.2))}
                fx={0.5 + 0.5 * Math.sin(2 * Math.PI * progress)}
                fy={0.5 - 0.5 * Math.cos(2 * Math.PI * progress)}
                r={0.6}
              >
                <stop offset="0%" stopColor="dodgerblue" />
                <stop offset="100%" stopColor="#414554" />
                <animateTransform
                  attributeName="gradientTransform"
                  attributeType="XML"
                  type="rotate"
                  from="0 0.5 0.5"
                  to="360 0.5 0.5"
                  dur="3s"
                  begin="0s"
                  repeatCount="indefinite"
                />
              </radialGradient>
            </defs>
            <rect
              x="0%"
              y="0%"
              width="100%"
              height="100%"
              rx="50%"
              fill="url(#ringGradient)"
              mask={`url(#${maskID})`}
            />
          </svg>
          {/* <span
            style={{
              fontSize: '2.5em',
              marginTop: '5px',
              fontWeight: 200,
              zIndex: 1,
            }}
          >
            {`${Math.round(progress * 100)}%`}
          </span> */}
          <span style={{ fontSize: '0.8em', opacity: 0.6 }}>{status}</span>
        </div>
        <div className={classes.details + (expanded ? ' expanded' : '')}>
          <div
            className={classes.showMore}
            role="button"
            tabIndex={0}
            onKeyDown={event => {
              if (event.keyCode === 13) setExpanded(!expanded)
            }}
            onClick={() => setExpanded(!expanded)}
          >
            show
            {expanded ? ' less' : ' more'}
            {/* <ArrowDropUpIcon /> */}
          </div>
          {details}
        </div>
      </div>
      <div className={classes.footer}>
        <button className={classes.actionButton} type="button" onClick={() => {}}>
          {/* <Icon type="play" /> */}
          <PlayArrowIcon />
        </button>
        <button className={classes.actionButton} type="button" onClick={() => {}}>
          {/* <Icon type="eye" /> */}
          <VisibilityIcon />
        </button>
        <button className={classes.actionButton} type="button" onClick={() => {}}>
          {/* <Icon type="pause" /> */}
          <PauseIcon />
        </button>
        <button className={classes.actionButton} type="button" onClick={() => {}}>
          {/* <Icon type="trash" /> */}
          {/* <PublicIcon /> */}
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}

ModelCard.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default ModelCard
