import React, { useState, useCallback, useImperativeHandle, forwardRef } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import './style.scss'

const InfoDialog = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)

  const { title, children, buttonText = 'Ok', onBtnClick, onClose, ...rest } = props

  const close = useCallback(() => {
    setVisible(false)
  }, [])
  const open = useCallback(() => {
    setVisible(true)
  }, [])

  useImperativeHandle(ref, () => ({
    close,
    open,
  }))

  return (
    <Dialog
      className="Info-Dialog"
      onKeyDown={event => {
        if (event.keyCode === 13) {
          setVisible(false)
          typeof onBtnClick === 'function' && onBtnClick(event)
        }
        if (event.keyCode === 27) setVisible(false)
      }}
      onClick={event => event.stopPropagation()}
      open={visible}
      onClose={() => {
        onClose && onClose()
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <DialogTitle
        style={{
          paddingTop: '30px',
          paddingLeft: '29px',
          // border: '1px solid black',
          // filter: 'drop-shadow(5px 5px 10px #000000)',
          // backgroundColor: 'rgba(46, 49, 58, 0.65)',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '20px',
            marginBottom: '10px',
          }}
        >
          <InfoOutlinedIcon style={{ color: 'dodgerblue', marginRight: '5px' }} />
          {title}
        </div>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions
        style={{
          padding: '0px 25px 30px',
        }}
      >
        <Button onClick={onBtnClick} color="primary" variant="outlined">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
})

InfoDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  children: PropTypes.node,
  // content: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  buttonText: PropTypes.string,
  onBtnClick: PropTypes.func,
  onClose: PropTypes.func,
}

export default InfoDialog
