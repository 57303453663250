import React from 'react'
import { useStore } from 'core'

import { BaseCard as Card } from 'components/CardLayout'

import './style.scss'
import { Button } from '@material-ui/core'

const classes = {
  wrapper: 'PlansPage-wrapper',
  section: 'AccountPages-section',
  sectionTitle: 'AccountPages-section-title',
  formDiv: 'AccountPages-formDiv',
  plansDiv: 'PlansPage-plansDiv',
  planDiv: 'PlansPage-planDiv',
  planTopDiv: 'PlansPage-planTopDiv',
  planTitle: 'PlansPage-planTitle',
  planPrice: 'PlansPage-planPrice',
  planPeriod: 'PlansPage-planPeriod',
  planBuyButton: 'PlansPage-planBuyButton',
  planBottomDiv: 'PlansPage-planBottomDiv',
}

const PlansPage = props => {
  const { plans } = useStore('selectPlans')

  return (
    <div className={classes.wrapper}>
      <Card>
        <div className={classes.section} style={{ flex: 5 }}>
          <span className={classes.sectionTitle}>Plans</span>
          <span>{`Licences: ${''}   |   CPUs: ${''}`}</span>
          <div className={classes.plansDiv}>
            {plans.map(({ name, price, currency }) => (
              <div className={classes.planDiv}>
                <div className={classes.planTopDiv}>
                  <span className={classes.planTitle}>{name}</span>
                  <span className={classes.planPrice}>{`${price} ${currency}`}</span>
                  <span className={classes.planPeriod}>monthly</span>
                  <Button
                    className={classes.planBuyButton}
                    color="primary"
                    variant="contained"
                    onClick={() => {}}
                  >
                    Buy Plan
                  </Button>
                </div>
                <div className={classes.planBottomDiv}>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Aspernatur minus porro, rerum deleniti cupiditate dolorem aliquid
                  nihil distinctio vitae! Ipsa!
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default PlansPage
