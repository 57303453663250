import React, { useEffect, useMemo, useState } from 'react'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import AlertDialog from 'components/AlertDialog'
import Pipeline from 'components/Pipeline'
import { LineChart, ScatterChart } from 'components/Charts'
import { ResourceTable, Provider as RenderProvider } from 'components/ResourceTable'

import { Dialog, Button } from '@material-ui/core'
import MinimizeIcon from '@material-ui/icons/Close'

import './style.scss'

const RESOURCE_TABLE_KEY = 'PipelineInspector-table'

const classes = {
  dialog: 'PipelineInspector-dialog',
  wrapper: 'PipelineInspector-wrapper',
  header: 'PipelineInspector-header',
  content: 'PipelineInspector-content',
  main: 'PipelineInspector-main',
  side: 'PipelineInspector-side',
  tab: 'PipelineInspector-tab',
  table: 'PipelineInspector-table',
  charts: 'PipelineInspector-charts',
  lineUserControl: 'PipelineInspector-lineUserControl',
  scatterUserControl: 'PipelineInspector-scatterUserControl',
  footer: 'PipelineInspector-footer',
  title: 'PipelineInspector-title',
  closeButton: 'PipelineInspector-closeButton',
}

const pipelineValidator = meta => {
  if (!meta) return false

  const mappingBox = meta.find(e => e.type === 'mapping')
  if (!mappingBox) return false

  const { features } = mappingBox.metadata[0]
  if (!features || !features.length) return false
  return true
}

const PipelineInspector = props => {
  const [tab, setTab] = useState('table')
  const {
    pipelineActiveBox,
    activePipeline,
    closePipelineManager,
    savePipeline,
    discardPipeline,
    rejectPipelineChange,
    registerResourceRequest,
  } = useStore(
    'selectPipelineActiveBox',
    'selectActivePipeline',
    'closePipelineManager',
    'savePipeline',
    'discardPipeline',
    'rejectPipelineChange',
    'registerResourceRequest'
  )

  const { meta: boxMeta } = { ...pipelineActiveBox }

  const { meta, id, pending, open, title } = { ...activePipeline }
  const disabled = !pipelineValidator(meta)
  const resourceID = boxMeta ? boxMeta.hash_out : null
  const discardWarning = !!(id && pending)

  const ctx = useMemo(() => ({ resourceType: 'UPLOADED_DATASET' }), [])

  useEffect(() => {
    if (resourceID && tab === 'chart')
      registerResourceRequest(`chart-${resourceID}`, resourceID, 'chart')
  }, [tab, registerResourceRequest, resourceID])

  return (
    <>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={open}
        onClose={closePipelineManager}
      >
        <div className={classes.wrapper}>
          <div className={classes.main}>
            <div className={classes.header}>
              <div className={classes.title}>{title}</div>
              <Button
                className={classes.closeButton}
                type="button"
                onClick={closePipelineManager}
              >
                <MinimizeIcon />
              </Button>
            </div>
            <div className={classes.content}>
              <div className={classes.main}>
                <Pipeline />
              </div>
            </div>
            <div className={classes.footer}>
              <Button onClick={discardPipeline}>Discard</Button>
              <Button color="primary" disabled={disabled} onClick={savePipeline}>
                Save
              </Button>
            </div>
          </div>
          <div
            className={classes.side}
            style={{ borderLeft: resourceID ? '1px solid' : '' }}
          >
            <RenderProvider value={ctx}>
              <Conditional dependencies={resourceID}>
                <div
                  className={classes.tab}
                  onClick={event => {
                    event.stopPropagation()
                    setTab('table')
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) {
                      setTab('table')
                    }
                  }}
                  data-tab={tab === 'table' ? 'active' : ''}
                >
                  <Icon type="list" />
                </div>
                <div
                  className={classes.tab}
                  onClick={event => {
                    event.stopPropagation()
                    setTab('chart')
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) {
                      setTab('chart')
                    }
                  }}
                  data-tab={tab === 'chart' ? 'active' : ''}
                >
                  <Icon type="chart" />
                </div>
                <div
                  className={classes.table}
                  style={{ display: tab !== 'table' ? 'none' : '' }}
                >
                  {resourceID && (
                    <ResourceTable
                      key={resourceID}
                      requestKey={RESOURCE_TABLE_KEY}
                      resource={resourceID}
                      perPage={14}
                    />
                  )}
                </div>
                <div
                  className={classes.charts}
                  style={{ display: tab !== 'chart' ? 'none' : '' }}
                >
                  <LineChart
                    controlable={{
                      stackingCategories: { multiple: true },
                      x: {},
                      y: { multiple: true },
                    }}
                    legend={{ position: 'right', width: 200 }}
                    xAxis={{ label: ' ', height: 50 }}
                    yAxis={{ label: ' ', width: 50 }}
                    grid
                    datasets={{ id: resourceID }}
                    mainColor="#1E90FF"
                  />
                  <ScatterChart
                    controlable={{
                      stackingCategories: { multiple: true },
                      x: {},
                      y: { multiple: true },
                      z: true,
                    }}
                    legend={{ position: 'right', width: 200 }}
                    xAxis={{ label: ' ', height: 50 }}
                    yAxis={{ label: ' ', width: 50 }}
                    grid
                    datasets={{ id: resourceID }}
                    mainColor="#1E90FF"
                  />
                </div>
              </Conditional>
            </RenderProvider>
          </div>
        </div>
      </Dialog>
      <AlertDialog
        open={discardWarning}
        title="Discard current pipeline?"
        content="You currently have an active pipeline. Do you wish to discard it?"
        okText="Discard"
        cancelText="Cancel"
        onOk={event => {
          event.stopPropagation()
          event.preventDefault()
          discardPipeline()
        }}
        onCancel={event => {
          event.stopPropagation()
          rejectPipelineChange()
        }}
      />
    </>
  )
}

PipelineInspector.propTypes = {}

export default PipelineInspector
