import React, { forwardRef, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import {
  DynamicDatasourceQueryModal,
  EditDatasetModal,
  AutomationModal,
} from 'components/ResourceModalComponents'
import { Tooltip } from '@material-ui/core'

const classes = {
  wrapper: 'DatasourcesMenu-wrapper',
  menuGroup: 'DatasourcesMenu-menuGroup',
  title: 'DatasourcesMenu-title',
  controlsDiv: 'DatasourcesMenu-controlsDiv',
  control: 'DatasourcesMenu-control',
}

const FileUploadInput = forwardRef((_, ref) => {
  const { addUploadFile, configureUploadEntity, fileStateInspectorDialogToggle } =
    useStore(
      'addUploadFile',
      'configureUploadEntity',
      'fileStateInspectorDialogToggle'
    )
  const openDialog = () => fileStateInspectorDialogToggle(true)

  return (
    <input
      ref={ref}
      type="file"
      name="file"
      files={[]}
      accept=".csv"
      multiple
      style={{ display: 'none' }}
      onChange={event => {
        const { target } = event
        const { files } = target
        if (files.length) {
          for (let i = 0; i < files.length; i++) {
            const file = files.item(i)
            const id = uuid()
            addUploadFile({ id, file })
            if (files.length === 1) configureUploadEntity(id)
          }
          target.value = ''
        }
        openDialog()
      }}
    />
  )
})

const DatasourcesMenu = () => {
  const modalRef = useRef()
  const dynamicModal = useRef()
  const inputRef = useRef()
  const automationModalRef = useRef()
  const {
    datasets,
    selectedDataset: selectedDatasetId,
    startTrainingFromResource,
    downloadDataset,
    setSelectedDataset,
  } = useStore(
    'selectDatasets',
    'selectSelectedDataset',
    'startTrainingFromResource',
    'downloadDataset',
    'setSelectedDataset'
  )

  const selectedDataset =
    datasets?.find(({ id }) => id === selectedDatasetId) || null

  return (
    <>
      <DynamicDatasourceQueryModal ref={dynamicModal} />
      <EditDatasetModal ref={modalRef} selectedDataset={selectedDataset || {}} />
      <FileUploadInput ref={inputRef} />
      <AutomationModal
        ref={automationModalRef}
        resource="dataset"
        resourceId={selectedDatasetId}
      />
      <div className={classes.wrapper}>
        <div
          className={classes.menuGroup}
          style={{ width: `calc(${2 * 1.5}em + ${(2 + 1) * 10}px)` }}
        >
          <div className={classes.title} style={{ height: '26px' }}>
            Upload
          </div>
          <div className={classes.controlsDiv} style={{ height: '1.5em' }}>
            <Tooltip title="STATIC dataset upload">
              <div
                className={classes.control}
                style={{ width: '1.5em' }}
                role="button"
                tabIndex={0}
                onClick={event => {
                  event.stopPropagation()
                  if (inputRef.current) inputRef.current.click()
                }}
                onKeyDown={event => {
                  event.stopPropagation()
                  if (event.keyCode === 13 && inputRef.current)
                    inputRef.current.click()
                }}
              >
                <Icon type="static-dataset" />
              </div>
            </Tooltip>
            <Tooltip title="DYNAMIC datasource query">
              <div
                className={classes.control}
                style={{ width: '1.5em' }}
                role="button"
                tabIndex={0}
                onClick={event => {
                  event.stopPropagation()
                  dynamicModal.current.open()
                }}
                onKeyDown={event => {
                  event.stopPropagation()
                  if (event.keyCode === 13 && inputRef.current)
                    dynamicModal.current.open()
                }}
              >
                <Icon type="dynamic-datasource" />
              </div>
            </Tooltip>
          </div>
        </div>
        <Conditional dependencies={!!selectedDatasetId}>
          <div
            className={classes.menuGroup}
            style={{ width: `calc(${3 * 1.5}em + ${(3 + 1) * 10}px)` }}
          >
            <div className={classes.title} style={{ height: '26px' }}>
              Actions
            </div>
            <div className={classes.controlsDiv} style={{ height: '1.5em' }}>
              <Tooltip title="TRAIN with selected dataset">
                <div
                  className={
                    classes.control + (selectedDatasetId ? '' : ' disabled')
                  }
                  style={{ width: '1.5em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedDatasetId)}
                  onClick={event => {
                    event.stopPropagation()
                    if (selectedDatasetId)
                      startTrainingFromResource(
                        selectedDatasetId,
                        'dataset',
                        true,
                        'New training'
                      )
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13 && selectedDatasetId)
                      startTrainingFromResource(
                        selectedDatasetId,
                        'dataset',
                        true,
                        'New $training'
                      )
                  }}
                >
                  <Icon type="train" />
                </div>
              </Tooltip>
              <Tooltip title="DOWNLOAD selected dataset">
                <div
                  className={
                    classes.control + (selectedDatasetId ? '' : ' disabled')
                  }
                  style={{ width: '1.5em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedDatasetId)}
                  onClick={event => {
                    event.stopPropagation()
                    downloadDataset({ id: selectedDatasetId })
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13)
                      downloadDataset({ id: selectedDatasetId })
                  }}
                >
                  <Icon type="download" />
                </div>
              </Tooltip>
              <Tooltip title="EDIT selected dataset">
                <div
                  className={
                    classes.control + (selectedDatasetId ? '' : ' disabled')
                  }
                  style={{ width: '1.5em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedDatasetId)}
                  onClick={event => {
                    event.stopPropagation()
                    modalRef.current.open()
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) {
                      modalRef.current.open()
                    }
                  }}
                >
                  <Icon type="edit-box" />
                </div>
              </Tooltip>
            </div>
          </div>
          <Conditional dependencies={selectedDataset?.type === 'QUERY_DATASET'}>
            <div
              className={classes.menuGroup}
              style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
            >
              <div className={classes.controlsDiv} style={{ height: '2em' }}>
                <Tooltip title="REUPLOAD selected dataset from query">
                  <div
                    className={
                      classes.control + (selectedDatasetId ? '' : ' disabled')
                    }
                    // className={`${classes.control} disabled`}
                    style={{ width: '2em' }}
                    role="button"
                    tabIndex={-1 + Number(!!selectedDatasetId)}
                    // tabIndex={-1}
                    onClick={event => {
                      event.stopPropagation()
                      setSelectedDataset(null)
                    }}
                    onKeyDown={event => {
                      event.stopPropagation()
                      if (event.keyCode === 13) setSelectedDataset(null)
                    }}
                  >
                    <Icon type="reupload" />
                  </div>
                </Tooltip>
              </div>
              {/* <div className={classes.title} style={{ height: '18px' }}>
                AUTO
              </div> */}
            </div>
            <div
              className={classes.menuGroup}
              style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
            >
              <div className={classes.controlsDiv} style={{ height: '2em' }}>
                <Tooltip title="AUTOMATE selected dataset">
                  <div
                    className={
                      classes.control + (selectedDatasetId ? '' : ' disabled')
                    }
                    style={{ width: '2em' }}
                    role="button"
                    tabIndex={-1 + Number(!!selectedDatasetId)}
                    onClick={event => {
                      event.stopPropagation()
                      automationModalRef.current.open()
                    }}
                    onKeyDown={event => {
                      event.stopPropagation()
                      if (event.keyCode === 13) automationModalRef.current.open()
                    }}
                  >
                    <Icon type="automate" />
                  </div>
                </Tooltip>
              </div>
              {/* <div className={classes.title} style={{ height: '18px' }}>
                AUTO
              </div> */}
            </div>
          </Conditional>
          <div
            className={classes.menuGroup}
            style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
          >
            <div className={classes.controlsDiv} style={{ height: '2em' }}>
              <Tooltip title="DESELECT">
                <div
                  className={
                    classes.control + (selectedDatasetId ? '' : ' disabled')
                  }
                  style={{ width: '2em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedDatasetId)}
                  onClick={event => {
                    event.stopPropagation()
                    setSelectedDataset(null)
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) setSelectedDataset(null)
                  }}
                >
                  <Icon type="close" />
                </div>
              </Tooltip>
            </div>
            {/* <div className={classes.title} style={{ height: '18px' }}>
              {null}
            </div> */}
          </div>
        </Conditional>
      </div>
    </>
  )
}

export default DatasourcesMenu
