import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import Spinner from 'components/Spinner'
import { MenuItem, TextField } from '@material-ui/core'

const classes = {
  wrapper: 'UserControl-wrapper',
  title: 'UserControl-title',
}

const StackingCategorySelectControl = props => {
  const { resourceDataResources, resourceMeta: allMeta } = useStore(
    'selectResourceDataRequests',
    'selectResourceDataResources',
    'selectResourceMeta'
  )

  const {
    values: propValues = [],
    onChange,
    multiple,
    ids: resourceIDs,
    title,
    chart = false,
    color,
  } = props

  const preparing =
    resourceDataResources
      ?.filter(({ id }) => resourceIDs.indexOf(id) !== -1)
      .filter(resource => (chart ? resource.chartIsLoading : resource.isLoading))
      .map(({ id }) => id) || []
  const resourceMeta =
    allMeta?.filter(({ id }) => resourceIDs.indexOf(id) !== -1) || []
  const loading = resourceMeta
    .filter(resource => resource.isLoading)
    .map(({ id }) => id)

  const datasetMetas = resourceMeta
    ?.filter(({ id }) => resourceIDs.indexOf(id) !== -1)
    .map(({ data }) => data)

  // ALL DATASET CATEGORIES
  const categories = datasetMetas.reduce((acc, cur) => {
    const tempResult = [...acc]
    cur?.stacking_categories?.forEach(category => {
      if (!tempResult.find(res => category === res)) tempResult.push(category)
    })
    return tempResult
  }, [])

  const values = multiple ? propValues : propValues.slice(0, 1)

  return (
    <div
      className={classes.wrapper}
      style={{
        filter: color ? `drop-shadow(0px 0px 5px ${color})` : '',
        boxShadow: color ? `0px 0px 3px 1px ${color}` : '',
      }}
    >
      <div className={classes.title}>
        <span>{title}</span>
        <Conditional dependencies={preparing.length || loading.length}>
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
        </Conditional>
      </div>
      <TextField
        label={
          categories.length
            ? `Categor${(multiple && 'ies') || 'y'}`
            : `No categor${(multiple && 'ies') || 'y'} to select`
        }
        select
        SelectProps={{ multiple: true, disabled: !categories.length }}
        value={values}
        onChange={event => {
          const tempValue = event.target.value
          const tempValues =
            tempValue && !Array.isArray(tempValue) ? [tempValue] : tempValue
          const difference = tempValues.filter(val => values.indexOf(val) === -1)
          onChange(multiple ? tempValues : difference)
        }}
      >
        {categories
          .sort((a, b) =>
            `${a}`.localeCompare(`${b}`, 'en', { sensitivity: 'base' })
          )
          .map(category => {
            return (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            )
          })}
      </TextField>
    </div>
  )
}

StackingCategorySelectControl.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  values: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  type: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  singleType: PropTypes.bool,
  title: PropTypes.string,
  chart: PropTypes.bool,
  color: PropTypes.string,
}

export default StackingCategorySelectControl
