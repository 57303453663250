/* eslint-disable camelcase */
import { createBundle } from 'core/bundler'
import { createSelector } from 'create-selector'

import {
  ASSETS_FETCH_STARTED,
  ASSETS_FETCH_SUCCEEDED,
  ASSETS_FETCH_FAILED,
  USERS_FETCH_STARTED,
  USERS_FETCH_SUCCEEDED,
  USERS_FETCH_FAILED,
  USER_INVITATION_SENT,
  USER_PERMISSIONS_FETCH_STARTED,
  USER_PERMISSIONS_FETCH_SUCCEEDED,
  USER_PERMISSIONS_FETCH_FAILED,
  // USER_INVITATION_FAILED,
} from 'core/actiontypes'

const name = 'system'
const initialState = {
  users: {
    data: [],
    isLoading: false,
    error: null,
  },
  assets: {
    data: [],
    isLoading: false,
    error: null,
  },
}

// =================================
//              ASSETS
// =================================
const fetchAssets =
  () =>
  (dispatch, { api }) => {
    dispatch({ type: ASSETS_FETCH_STARTED })
    api('/assets')
      .then(data => dispatch({ type: ASSETS_FETCH_SUCCEEDED, payload: data }))
      .catch(err => dispatch({ type: ASSETS_FETCH_FAILED, error: err }))
  }
const assetsSelectorRaw = state => state[name].assets
const assetsSelector = createSelector(assetsSelectorRaw, assets => assets.data)
const reactMissingAssets = createSelector(
  'selectUser',
  assetsSelectorRaw,
  (user, assets) => {
    if (assets.isLoading || assets.error || !user) return null
    if (!assets.data.length) {
      return fetchAssets()
    }
    return null
  }
)

// =================================
//              USERS
// =================================
const initialUserEntity = {
  permissions: null,
  permissionsRequestLoading: false,
  permissionsRequestError: null,
}
const fetchUsers =
  () =>
  (dispatch, { api }) => {
    dispatch({ type: USERS_FETCH_STARTED })
    api('/users')
      .then(data => dispatch({ type: USERS_FETCH_SUCCEEDED, payload: data }))
      .catch(err => dispatch({ type: USERS_FETCH_FAILED, error: err }))
  }

const fetchUserPermissions =
  userID =>
  (dispatch, { api }) => {
    dispatch({ type: USER_PERMISSIONS_FETCH_STARTED, payload: userID })
    api(`/users/${userID}/permissions`)
      .then(data =>
        dispatch({
          type: USER_PERMISSIONS_FETCH_SUCCEEDED,
          payload: {
            id: userID,
            data,
          },
        })
      )
      .catch(err =>
        dispatch({
          type: USER_PERMISSIONS_FETCH_FAILED,
          payload: userID,
          error: err,
        })
      )
  }
const usersSelectorRaw = state => state[name].users
const usersSelector = createSelector(usersSelectorRaw, users => users.data)
const reactMissingUsers = createSelector(
  'selectUser',
  usersSelectorRaw,
  (user, users) => {
    if (users.isLoading || users.error || !user) return null
    if (!users.data.length) {
      return fetchUsers()
    }
    return null
  }
)

export default config =>
  createBundle({
    name: 'system',
    reducer: (state = initialState, { type: action, payload, error }) => {
      if (action === ASSETS_FETCH_STARTED) {
        return {
          ...state,
          assets: {
            ...state.assets,
            isLoading: true,
          },
        }
      }
      if (action === ASSETS_FETCH_SUCCEEDED) {
        return {
          ...state,
          assets: {
            ...state.assets,
            data: payload,
            isLoading: false,
          },
        }
      }
      if (action === ASSETS_FETCH_FAILED) {
        return {
          ...state,
          assets: {
            ...state.assets,
            isLoading: false,
            error,
          },
        }
      }
      if (action === USERS_FETCH_STARTED) {
        return {
          ...state,
          users: {
            ...state.users,
            isLoading: true,
          },
        }
      }
      if (action === USERS_FETCH_SUCCEEDED) {
        return {
          ...state,
          users: {
            ...state.users,
            // data: payload.map(e => ({ ...e, ...initialUserEntity })),
            data: payload.map(e => ({ ...initialUserEntity, ...e })),
            isLoading: false,
          },
        }
      }
      if (action === USERS_FETCH_FAILED) {
        return {
          ...state,
          users: {
            ...state.users,
            isLoading: false,
            error,
          },
        }
      }
      if (action === USER_PERMISSIONS_FETCH_STARTED) {
        return {
          ...state,
          users: {
            ...state.users,
            data: state.users.data.map(user => {
              if (user.id !== payload) return user
              return { ...user, permissionsRequestLoading: true }
            }),
          },
        }
      }
      if (action === USER_PERMISSIONS_FETCH_FAILED) {
        return {
          ...state,
          users: {
            ...state.users,
            data: state.users.data.map(user => {
              if (user.id !== payload) return user

              return {
                ...user,
                permissionsRequestError: error,
                permissionsRequestLoading: false,
              }
            }),
          },
        }
      }
      if (action === USER_PERMISSIONS_FETCH_SUCCEEDED) {
        const { id, data } = payload
        return {
          ...state,
          users: {
            ...state.users,
            data: state.users.data.map(user => {
              if (user.id !== id) return user

              return {
                ...user,
                permissions: data,
                permissionsRequestLoading: false,
              }
            }),
          },
        }
      }
      return state
    },
    selectors: {
      selectAssets: assetsSelector,
      reactMissingAssets,
      selectUsers: usersSelector,
      reactMissingUsers,
    },
    actions: {
      fetchAssets,
      fetchUsers,
      fetchUserPermissions,
    },
    init: null,
    args: null,
    // persist: [LOGIN_REQUEST_SUCCEEDED, USER_LOGGED_OUT, LOGIN_REQUEST_STARTED],
    middleware: null,
  })
