import React, { useRef, useState, useMemo } from 'react'
import { useStore, useHistory } from 'core'
import { extractValue } from 'libs/utils/helpers'

import Conditional from 'components/Conditional'
import Modal from 'components/Modal'
import AlertDialog from 'components/AlertDialog'
import { BaseCard as Card } from 'components/CardLayout'
import { Table, Column } from 'components/Table'
import {
  CircularProgress,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core'

import './style.scss'

const classes = {
  wrapper: 'UsersPage-wrapper',
  section: 'AccountPages-section',
  sectionTitle: 'AccountPages-section-title',
  formDiv: 'AccountPages-formDiv',
  usersTableDiv: 'UsersPage-usersTableDiv',
  inviteUserDiv: 'UsersPage-inviteUserDiv',
  inviteUserButton: 'UsersPage-inviteUserButton',
  permissionsDiv: 'UsersPage-permissionsDiv',
  permissionCheckbox: 'UsersPage-permissionCheckbox',
  licencesDiv: 'UsersPage-licencesDiv',
  licenceWrapper: 'UsersPage-licenceWrapper',
  addLicenceButton: 'UsersPage-addLicenceButton',
  modalLicencesDiv: 'UsersPage-modalLicencesDiv',
}

const USER_PERMISSIONS = {
  MANAGE_USER_LICENCES: 'MANAGE_USER_LICENCES',
  MANAGE_ORGANIZATION_PAYMENTS: 'MANAGE_ORGANIZATION_PAYMENTS',
  MANAGE_USER_PERMISSIONS: 'MANAGE_USER_PERMISSIONS',
}

const deriveInviteStatusMessage = (error, success, prog) => {
  if (prog) return 'Processing...'
  if (error) return 'User invite error'
  if (success) return 'Successful'
  return null
}

const UsersPage = props => {
  const addLicenceModalRef = useRef()
  const buyLicenceModalRef = useRef()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [inviteEmail, setInviteEmail] = useState('')

  const {
    users: storeUsers,
    licences: storeLicences,
    user: storeUser,
    addUserLicence,
    removeUserLicence,
    grantPermissionToUser,
    revokePermissionFromUser,
    fetchUsers,
    fetchLicences,
    sendUserInvite,
    userInviteInProgress,
    userInviteError,
    userInviteSucceeded,
  } = useStore(
    'selectUsers',
    'selectLicences',
    'selectUser',
    'addUserLicence',
    'removeUserLicence',
    'grantPermissionToUser',
    'revokePermissionFromUser',
    'fetchUsers',
    'fetchLicences',
    'sendUserInvite',
    'selectUserInviteInProgress',
    'selectUserInviteError',
    'selectUserInviteSucceeded'
  )

  const users =
    storeUsers?.map(u => ({
      ...u,
      permissions: u.permissions?.filter(
        p => Object.values(USER_PERMISSIONS).indexOf(p) !== -1
      ),
    })) || []

  const user = users?.find(u => u.id === selectedUserId)
  const activeUser = users?.find(u => u.id === storeUser?.id)

  const licences = storeLicences
    ?.filter(({ user: licenceUser }) => !licenceUser)
    ?.map(({ id, licence: { name: type } }) => ({
      id,
      type,
    }))

  const tableData = []
  users?.forEach(tempUser => {
    const {
      id,
      username,
      first_name: fn,
      last_name: ln,
      licences: lcns,
      permissions: prms,
    } = tempUser
    const userObj = {
      id,
      username,
      fullName: '',
      licences: lcns || [],
      permissions: prms || [],
    }
    if (fn && ln)
      userObj.fullName = `${fn.charAt(0).toUpperCase() + fn.slice(1)} ${
        ln.charAt(0).toUpperCase() + ln.slice(1)
      }`
    tableData.push(userObj)
  })

  const canManageUserPermissions =
    !!(
      activeUser?.permissions?.indexOf(USER_PERMISSIONS.MANAGE_USER_PERMISSIONS) + 1
    ) || false
  const canManageUserLinences =
    !!(
      activeUser?.permissions?.indexOf(USER_PERMISSIONS.MANAGE_USER_LICENCES) + 1
    ) || false

  const addLicenceHandler = e => {
    if (licences.length) addLicenceModalRef.current.open()
    else buyLicenceModalRef.current.open()
  }

  const userInviteStatusMessage = useMemo(
    () =>
      deriveInviteStatusMessage(
        userInviteError,
        userInviteSucceeded,
        userInviteInProgress
      ),
    [userInviteError, userInviteInProgress, userInviteSucceeded]
  )

  return (
    <>
      <AlertDialog
        ref={buyLicenceModalRef}
        title="No licences available"
        content="You do not have any available licences. Buy more?"
        okText="Buy"
        onCancel={() => {
          buyLicenceModalRef.current.close()
        }}
        onOk={() => {
          history.push('/pricing')
        }}
      />
      <Modal
        ref={addLicenceModalRef}
        title="Add a licence"
        onCancel={() => {
          addLicenceModalRef.current.close()
        }}
      >
        <div className={classes.modalLicencesDiv}>
          {licences?.map(licence => (
            <Button
              key={licence.id}
              className={classes.licenceWrapper}
              color="primary"
              onClick={() => {
                addUserLicence(user.id, licence.id)
                fetchUsers()
                fetchLicences()
                addLicenceModalRef.current.close()
              }}
            >
              <span>{licence.id}</span>
              <span>{licence.type}</span>
            </Button>
          ))}
        </div>
      </Modal>
      <div className={classes.wrapper}>
        <Card>
          <div className={classes.section}>
            <span className={classes.sectionTitle}>Users</span>
            {/* <span>{`Next payment: ${undefined}   |   Recurring: ${'active'}`}</span> */}
            <div className={classes.usersTableDiv}>
              <Table
                data={tableData}
                footer={{
                  pagination: {
                    perPage: 18,
                    currentPage,
                    setCurrentPage,
                  },
                }}
                onRow={
                  (canManageUserPermissions || canManageUserLinences || null) &&
                  (e => {
                    setSelectedUserId(e.id)
                  })
                }
              >
                <Column
                  dataKey="_defaultColumn"
                  render={(value, header) => {
                    const stringValue = `${value}`
                    return header
                      ? stringValue.charAt(0).toUpperCase() + stringValue.slice(1)
                      : value
                  }}
                />
                <Column dataKey="id" hidden />
                <Column
                  dataKey="username"
                  title="Username"
                  // hidden
                  width={120}
                  sortFunction={(a, b) => {
                    const aVal = extractValue(a, 'username')
                    const bVal = extractValue(b, 'username')
                    let result = 0
                    if (typeof aVal === 'string' || typeof bVal === 'string') {
                      result = `${aVal}`.localeCompare(`${bVal}`, 'en', {
                        sensitivity: 'base',
                      })
                    } else result = aVal - bVal
                    return result
                  }}
                />
                <Column
                  dataKey="fullName"
                  title="Full Name"
                  hidden
                  width={180}
                  sortFunction={(a, b) => {
                    const aVal = extractValue(a, 'fullName')
                    const bVal = extractValue(b, 'fullName')
                    let result = 0
                    if (typeof aVal === 'string' || typeof bVal === 'string') {
                      result = `${aVal}`.localeCompare(`${bVal}`, 'en', {
                        sensitivity: 'base',
                      })
                    } else result = aVal - bVal
                    return result
                  }}
                  sorting={2}
                />
                <Column
                  dataKey="licences"
                  title="Licences"
                  width={120}
                  render={(value, header) =>
                    header ? (
                      value
                    ) : (
                      <div>{`${value
                        ?.map(({ licence: { name: licenceType } }) => licenceType)
                        ?.join(', ')}`}</div>
                    )
                  }
                  sortFunction={(a, b) => a?.licences?.length - b?.licences?.length}
                />
                <Column
                  dataKey="permissions"
                  title="Permissions"
                  // width={180}
                  render={(value, header) =>
                    header
                      ? value
                      : `${value
                          .map(permission => {
                            const permissionString = permission.replaceAll('_', ' ')
                            return (
                              permissionString.charAt(0).toUpperCase() +
                              permissionString.slice(1).toLowerCase()
                            )
                          })
                          .join(', ')}`
                  }
                  sortFunction={(a, b) =>
                    a?.permissions?.length - b?.permissions?.length ||
                    a?.permissions?.join('')?.length -
                      b?.permissions?.join('')?.length
                  }
                />
              </Table>
            </div>
            <div className={classes.inviteUserDiv}>
              <TextField
                label="Email"
                fullWidth
                inputProps={{}}
                value={inviteEmail}
                onFocus={e => e.target.select()}
                onChange={event => {
                  setInviteEmail(event.target.value)
                }}
                FormHelperTextProps={{
                  error: !!userInviteError,
                  filled: !userInviteError && !!userInviteSucceeded,
                }}
                helperText={userInviteStatusMessage}
              />
              <Button
                className={classes.inviteUserButton}
                color="primary"
                variant="contained"
                onClick={() => {
                  sendUserInvite(inviteEmail)
                }}
              >
                INVITE USER
              </Button>
            </div>
          </div>
        </Card>
        <Conditional
          dependencies={user && (canManageUserPermissions || canManageUserLinences)}
        >
          <Card>
            <div className={classes.section} style={{ flex: 0 }}>
              <span className={classes.sectionTitle}>
                {user?.fullName || user?.username}
              </span>
              <span>{`${user?.email}`}</span>
            </div>
            <Conditional dependencies={canManageUserPermissions}>
              <div className={classes.section} style={{ flex: 0 }}>
                <span className={classes.sectionTitle}>Permissions</span>
                {/* <span>{`${user?.username}   |   ${user?.email}   |   ID: ${user?.id}   |   Registration Date: ${user?.registrationDate}`}</span> */}
                <div className={classes.permissionsDiv}>
                  <FormControlLabel
                    className={classes.permissionCheckbox}
                    label={(() => {
                      const permissionString =
                        USER_PERMISSIONS.MANAGE_ORGANIZATION_PAYMENTS.replaceAll(
                          '_',
                          ' '
                        )
                      return (
                        permissionString.charAt(0).toUpperCase() +
                        permissionString.slice(1).toLowerCase()
                      )
                    })()}
                    checked={
                      user?.permissions?.indexOf(
                        USER_PERMISSIONS.MANAGE_ORGANIZATION_PAYMENTS
                      ) !== -1
                    }
                    onChange={event => {
                      if (event.target.checked)
                        grantPermissionToUser(
                          user.id,
                          USER_PERMISSIONS.MANAGE_ORGANIZATION_PAYMENTS
                        )
                      else
                        revokePermissionFromUser(
                          user.id,
                          USER_PERMISSIONS.MANAGE_ORGANIZATION_PAYMENTS
                        )

                      fetchUsers()
                    }}
                    margin="dense"
                    control={<Checkbox color="default" />}
                  />
                  <FormControlLabel
                    className={classes.permissionCheckbox}
                    label={(() => {
                      const permissionString =
                        USER_PERMISSIONS.MANAGE_USER_PERMISSIONS.replaceAll('_', ' ')
                      return (
                        permissionString.charAt(0).toUpperCase() +
                        permissionString.slice(1).toLowerCase()
                      )
                    })()}
                    checked={
                      user?.permissions?.indexOf(
                        USER_PERMISSIONS.MANAGE_USER_PERMISSIONS
                      ) !== -1
                    }
                    onChange={event => {
                      if (event.target.checked)
                        grantPermissionToUser(
                          user.id,
                          USER_PERMISSIONS.MANAGE_USER_PERMISSIONS
                        )
                      else
                        revokePermissionFromUser(
                          user.id,
                          USER_PERMISSIONS.MANAGE_USER_PERMISSIONS
                        )
                      fetchUsers()
                    }}
                    margin="dense"
                    control={<Checkbox color="default" />}
                  />
                  <FormControlLabel
                    className={classes.permissionCheckbox}
                    label={(() => {
                      const permissionString =
                        USER_PERMISSIONS.MANAGE_USER_LICENCES.replaceAll('_', ' ')
                      return (
                        permissionString.charAt(0).toUpperCase() +
                        permissionString.slice(1).toLowerCase()
                      )
                    })()}
                    checked={
                      user?.permissions?.indexOf(
                        USER_PERMISSIONS.MANAGE_USER_LICENCES
                      ) !== -1
                    }
                    onChange={event => {
                      if (event.target.checked)
                        grantPermissionToUser(
                          user.id,
                          USER_PERMISSIONS.MANAGE_USER_LICENCES
                        )
                      else
                        revokePermissionFromUser(
                          user.id,
                          USER_PERMISSIONS.MANAGE_USER_LICENCES
                        )
                      fetchUsers()
                    }}
                    margin="dense"
                    control={<Checkbox color="default" />}
                  />
                </div>
              </div>
            </Conditional>
            <Conditional dependencies={canManageUserLinences}>
              <div className={classes.section} style={{ flex: 5 }}>
                <span className={classes.sectionTitle}>Licences</span>
                {/* <span>{`${user?.username}   |   ${user?.email}   |   ID: ${user?.id}   |   Registration Date: ${user?.registrationDate}`}</span> */}
                <div className={classes.licencesDiv}>
                  {user?.licences?.map(licence => (
                    <div key={licence.id} className={classes.licenceWrapper}>
                      {licence?.licence?.name}
                      <div
                        style={{
                          width: '10em',
                          marginRight: 'auto',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        &nbsp;({licence?.contract?.id})
                      </div>
                      <Button
                        className={classes.removeLicenceButton}
                        color="primary"
                        onClick={() => {
                          removeUserLicence(user.id, licence.id)
                          fetchUsers()
                          fetchLicences()
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                </div>
                <Button
                  className={classes.addLicenceButton}
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={addLicenceHandler}
                >
                  Add a licence
                </Button>
              </div>
            </Conditional>
          </Card>
        </Conditional>
      </div>
    </>
  )
}

export default UsersPage
