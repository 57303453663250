import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { useRefRect } from 'libs/utils/hooks'

import Conditional from 'components/Conditional'

import './style.scss'
import { useMemo } from 'use-memo-one'

const classes = {
  wrapper: 'Spinner-wrapper',
}

const SpinnerGradient = props => {
  const { id, spin, mainColor, emptyColor } = props

  return (
    <radialGradient
      id={`spinnerGradient-${id}`}
      cx={0.5 + 0.5 * Math.sin(-2 * Math.PI * 0.2)}
      cy={0.5 - 0.5 * Math.cos(-2 * Math.PI * 0.2)}
      fx={0.5}
      fy={0}
      r={0.6}
    >
      <stop offset="0%" stopColor={mainColor} />
      <stop offset="100%" stopColor={emptyColor} />
      <Conditional dependencies={spin}>
        <animateTransform
          attributeName="gradientTransform"
          attributeType="XML"
          type="rotate"
          from="0 0.5 0.5"
          to="360 0.5 0.5"
          dur={`${spin}s`}
          begin="0s"
          repeatCount="indefinite"
        />
      </Conditional>
    </radialGradient>
  )
}

const Spinner = props => {
  const [rect, container] = useRefRect()
  const {
    spin = 0,
    progress: propProgress = 0,
    strokeWidth = 5,
    mainColor = 'dodgerblue',
    emptyColor = '#414554',
    fillColor,
  } = props

  const { width = 1, height = 1 } = rect || {}
  const xRatio = 100 / width
  const yRatio = 100 / height

  const progress = Math.round(Math.max(0, Math.min(1, propProgress)) * 100) / 100

  const id = useMemo(() => uuid(), [])

  const fullMaskID = `full-${id}`
  let mask = <rect x="0%" y="0%" width="100%" height="100%" rx="50%" fill="white" />
  if (!spin && progress < 1)
    mask = (
      <path
        d={`M 50 50, L 50 0 A 50 50, 0, ${Number(progress > 0.5)}, 1, ${
          50 + 50 * Math.sin(2 * Math.PI * progress)
        } ${50 - 50 * Math.cos(2 * Math.PI * progress)} Z`}
        fill="white"
      />
    )

  const emptyMaskID = `empty-${id}`
  const progressRingMask = (
    <mask id={emptyMaskID}>
      <rect x={0.1} y={0.1} width={99.8} height={99.8} rx="50%" fill="white" />
      <rect
        x={strokeWidth * xRatio}
        y={strokeWidth * yRatio}
        width={Math.abs(100 - 2 * strokeWidth * xRatio)}
        height={Math.abs(100 - 2 * strokeWidth * xRatio)}
        rx="50%"
        fill="black"
      />
    </mask>
  )

  return (
    <div ref={container} className={classes.wrapper}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <defs>
          <SpinnerGradient
            id={id}
            spin={spin}
            mainColor={mainColor}
            emptyColor={emptyColor}
          />
        </defs>
        {progressRingMask}
        <mask id={fullMaskID}>
          {mask}
          <rect
            x={strokeWidth * xRatio + 0.01}
            y={strokeWidth * yRatio + 0.01}
            width={Math.abs(100 - 2 * strokeWidth * xRatio - 0.02)}
            height={Math.abs(100 - 2 * strokeWidth * xRatio - 0.02)}
            rx="50%"
            fill="black"
          />
        </mask>
        <Conditional dependencies={progress || spin}>
          <Conditional dependencies={!spin}>
            <rect
              x="0%"
              y="0%"
              width="100%"
              height="100%"
              rx="50%"
              fill={emptyColor}
              mask={`url(#${emptyMaskID})`}
            />
          </Conditional>
          <rect
            x="0%"
            y="0%"
            width="100%"
            height="100%"
            rx="50%"
            fill={spin ? `url(#spinnerGradient-${id})` : mainColor}
            mask={`url(#${fullMaskID})`}
          />
        </Conditional>
        <Conditional dependencies={fillColor}>
          <circle cx="50%" cy="50%" r="30%" fill={fillColor} />
        </Conditional>
      </svg>
    </div>
  )
}

SpinnerGradient.propTypes = {
  id: PropTypes.string,
  spin: PropTypes.number,
  mainColor: PropTypes.string,
  emptyColor: PropTypes.string,
}

Spinner.propTypes = {
  spin: PropTypes.number, // duration of one revolution
  progress: PropTypes.number,
  strokeWidth: PropTypes.number,
  mainColor: PropTypes.string,
  emptyColor: PropTypes.string,
  fillColor: PropTypes.string,
}

export default Spinner
