import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, useRouteMatch, useStore } from 'core'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import { Provider, Context } from './MenuContext'

const classes = {
  wrapper: 'MenuItem-wrapper',
  leftMarker: 'MenuItem-leftMarker',
  main: 'MenuItem-main',
  iconDiv: 'MenuItem-iconDiv',
  textDiv: 'MenuItem-textDiv',
  buttonDiv: 'MenuItem-buttonDiv',
  subMenu: 'MenuItem-subMenu',
}

const MenuItem = props => {
  const { pref: storePref } = useStore('selectPref')
  const { menu: pref = {} } = storePref
  const { collapsed = false } = pref
  const { indexable } = useContext(Context)

  const {
    to,
    simple,
    icon,
    text,
    exact,
    disabled,
    dropdownDirection = 'down',
    button,
    heightString = '',
    className = '',
    children,
    ...rest
  } = props

  const linkItem = typeof to !== 'undefined'
  const match = !!useRouteMatch(`${linkItem ? to : '//////'}`, false, false, false)
  const WrapperComponent = linkItem ? Link : 'a'

  const childrenCount = React.Children.toArray(children).length
  const height = heightString || `calc(${childrenCount} * 2em + 3em`

  return (
    <div
      className={`${classes.wrapper}${className}${simple ? ' simple' : ''}${
        disabled ? ' disabled' : ''
      }`}
      data-active={match}
      style={{
        height: match && childrenCount && !collapsed ? height : '',
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <Conditional dependencies={!simple}>
        <div className={classes.leftMarker} />
      </Conditional>
      <WrapperComponent
        className={classes.main}
        to={!disabled && to}
        absolute
        indexable={!disabled && indexable}
        disabled={disabled}
        exact={exact}
        // eslint-disable-next-line no-script-url
        href={linkItem ? null : 'javascript:void(0)'}
      >
        <Conditional dependencies={!simple}>
          <div className={classes.iconDiv}>
            <Conditional dependencies={!!icon}>
              <Icon type={icon || ''} />
            </Conditional>
          </div>
        </Conditional>
        <div className={classes.textDiv}>
          <span>{text}</span>
        </div>
        <Conditional dependencies={button}>
          <div className={classes.buttonDiv}>{button}</div>
        </Conditional>
      </WrapperComponent>
      <Conditional dependencies={!!childrenCount}>
        <div className={`${classes.subMenu} ${dropdownDirection}`}>
          <Provider value={{ indexable: match && !collapsed }}>{children}</Provider>
        </div>
      </Conditional>
    </div>
  )
}

MenuItem.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  simple: PropTypes.bool,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  disabled: PropTypes.bool,
  dropdownDirection: PropTypes.oneOf(['up', 'down']),
  button: PropTypes.node,
  heightString: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default MenuItem
