/* eslint-disable max-lines */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import { createBundle } from 'core/bundler'
import { createSelector } from 'create-selector'

import invariant from 'tiny-invariant'
import { v4 as uuid } from 'uuid'

import {
  USER_LOGGED_OUT,
  BRAINS_FETCH_REQUESTED,
  // BRAINS_FETCH_SUCCEEDED,
  // BRAINS_FETCH_FAILED,
  PIPELINE_PROCESSING_FINISHED,
  PIPELINE_PROCESSING_FAILED,
  PIPELINE_ELEMENT_FINISHED,
  PIPELINE_ELEMENT_FAILED,
  ACTIVE_SCORING_CHANGE_REQUESTED,
  ACTIVE_SCORING_CHANGE_ACCEPTED,
  ACTIVE_SCORING_CHANGE_REJECTED,
  ACTIVE_SCORING_DISCARDED,
  SCORING_WIZARD_ACTIVATED,
  SCORING_WIZARD_DEACTIVATED,
  SCORING_WIZARD_NEXT_STEP,
  SCORING_WIZARD_PREVIOUS_STEP,
  SCORING_WIZARD_TRAINING_UPDATED,
  SCORING_WIZARD_TRAINING_META_INITIALIZED,
  SCORING_WIZARD_BRAINS_UPDATED,
  SCORING_WIZARD_DATASETS_UPDATED,
  SCORING_WIZARD_PIPELINE_UPDATED,
  SCORING_WIZARD_NAME_UPDATED,
  SCORING_WIZARD_RECENT_DATA_TOGGLED,
  SCORING_WIZARD_PIPELINE_COMMITED,
  SCORING_START_REQUEST_STARTED,
  SCORING_START_REQUEST_SUCCEEDED,
  SCORING_START_REQUEST_FAILED,
} from 'core/actiontypes'

// const extractDatasetBoxes = (pipe, res) => {
//   return pipe.reduce((acc, box) => {
//     if (box.constructor !== Array) {
//       if (box.type === 'dataset') acc.push(box)
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       acc = [...acc, ...extractDatasetBoxes(box)]
//     }
//     return acc
//   }, [])
// }

const getBoxCount = branch => {
  if (!branch || !branch.length) return 0
  return branch.reduce((acc, cur) => {
    if (Array.isArray(cur)) {
      return acc + getBoxCount(cur)
    }
    return acc + 1
  }, 0)
}

const NAME = 'scoring-wizard'
const STEPS_COUNT = 3

const initialState = {
  id: null,
  title: null,
  pending: null,
  open: false,
  editMode: true,
  step: 0,
  training: null,
  training_name: null,
  brains: [],
  datasets: [],
  pipeline: [],
  pipeline_id: null,
  pipeline_processing_id: null,
  pipeline_processing_error: null,
  pipeline_processing_progress: 0,
  pipeline_operations_count: 0,
  pipeline_output: null,
  pipeline_output_hash: null,
  pipeline_output_error: null,
  starting: false,
  last_row_only: false,
}

export default config => {
  const rawSelector = state => state[NAME]
  const selectScoringWizardStep = state => state[NAME].step
  const selectScoringWizardEditable = state => state[NAME].editMode
  // const selectScoringWizardBrainsFetching = state =>
  //   createSelector(rawSelector, 'selectBrains', (state, allBrains) => {
  //     const { training } = state
  //     if (!training) return false
  //     const brainsRequest = allBrains.find(request => request.id === training)
  //     if (!brainsRequest) return false
  //     return brainsRequest.isLoading
  //   })
  const selectScoringWizardPipelineProcessing = state =>
    !!state[NAME].pipeline_processing_id

  const initializeScoringWizard = (
    id,
    training,
    brains,
    datasets,
    title,
    last_row_only,
    editMode = true,
    open = true,
    step = 0
  ) => {
    return {
      type: ACTIVE_SCORING_CHANGE_REQUESTED,
      payload: {
        id: id || uuid(),
        title,
        editMode,
        open,
        step,
        training: training || null,
        brains: brains || [],
        datasets: datasets || [],
        last_row_only,
        pipeline: null,
        pipeline_id: null,
        pipeline_output: null,
        pipeline_processing_id: null,
        pipeline_processing_error: null,
        pipeline_processing_progress: 0,
        pipeline_operations_count: 0,
      },
    }
  }

  const startScoringFromResource =
    (resourceID, resourceType, open, title) =>
    (dispatch, { store }) => {
      if (resourceType === 'training') {
        dispatch(
          initializeScoringWizard(
            null,
            resourceID,
            null,
            [],
            title,
            false,
            true,
            open,
            1
          )
        )
      }
      if (resourceType === 'scoring') {
        const scorings = store.selectScorings()
        if (!scorings) return

        const scoring = scorings.find(scr => scr.id === resourceID) || null
        if (!scoring) return

        const { id, spec } = scoring
        const { training_id: training, brain_ids: brains, last_row_only } = spec
        dispatch(
          initializeScoringWizard(
            id,
            training,
            brains,
            [],
            title,
            last_row_only,
            true,
            open,
            1
          )
        )
      }
    }

  const openScoringWizard = () => ({ type: SCORING_WIZARD_ACTIVATED })
  const closeScoringWizard = () => ({ type: SCORING_WIZARD_DEACTIVATED })
  const acceptScoringChange = () => ({ type: ACTIVE_SCORING_CHANGE_ACCEPTED })
  const rejectScoringChange = () => ({ type: ACTIVE_SCORING_CHANGE_REJECTED })
  const discardActiveScoring = () => ({ type: ACTIVE_SCORING_DISCARDED })

  const scoringWizardNextStep = () => ({ type: SCORING_WIZARD_NEXT_STEP })
  const scoringWizardPrevStep = () => ({ type: SCORING_WIZARD_PREVIOUS_STEP })
  const setScoringWizardTraining = (trainingID, name = null) => ({
    type: SCORING_WIZARD_TRAINING_UPDATED,
    payload: { id: trainingID, name },
  })
  const setScoringWizardBrains = brains => ({
    type: SCORING_WIZARD_BRAINS_UPDATED,
    payload: typeof brains === 'string' ? [brains] : brains,
  })
  const setScoringWizardDataset = (datasetID, index = 0) => ({
    type: SCORING_WIZARD_DATASETS_UPDATED,
    payload: {
      id: datasetID,
      index,
    },
  })
  const setScoringWizardName = title => ({
    type: SCORING_WIZARD_NAME_UPDATED,
    payload: title,
  })
  const setScoringWizardSwitch = checked => ({
    type: SCORING_WIZARD_RECENT_DATA_TOGGLED,
    payload: checked,
  })

  const scoringWizardStartScoring =
    () =>
    (dispatch, { api, store }) => {
      const activeScoring = store.selectActiveScoring()
      if (!activeScoring) return
      const {
        datasets,
        training,
        brains,
        title: name,
        id,
        last_row_only,
      } = activeScoring
      dispatch({ type: SCORING_START_REQUEST_STARTED })

      const scorings = store.selectScorings()
      const scoring = scorings && scorings.find(s => s.id === id)

      const path = scoring ? `/scorings/${id}` : '/scorings'

      api(path, {
        method: 'POST',
        body: {
          name,
          description: '',
          scoring_spec: {
            last_row_only,
            brain_ids: brains,
            dataset_ids: datasets,
            training_id: training,
          },
        },
      })
        .then(response => {
          dispatch({ type: SCORING_START_REQUEST_SUCCEEDED })
          store.markScoringsAsOutdated()
        })
        .catch(error => dispatch({ type: SCORING_START_REQUEST_FAILED }))
    }

  const runScoring =
    id =>
    (dispatch, { api, store }) => {
      const scorings = store.selectScorings()
      if (!scorings) return
      const scoring = scorings.find(s => s.id === id)
      if (!scoring) return

      api(`/scorings/${id}`, {
        method: 'POST',
        body: {
          name: scoring.name,
          description: scoring.description,
          scoring_spec: scoring.scoring_spec,
        },
      })
        .then(response => {
          dispatch({ type: SCORING_START_REQUEST_SUCCEEDED })
          store.markScoringsAsOutdated()
        })
        .catch(error => dispatch({ type: SCORING_START_REQUEST_FAILED }))
    }

  return createBundle({
    name: NAME,
    reducer: (state = initialState, { type, payload, error }) => {
      if (type === SCORING_WIZARD_ACTIVATED) {
        return { ...state, open: true }
      }
      if (type === SCORING_WIZARD_DEACTIVATED) {
        return { ...state, open: false }
      }
      if (type === ACTIVE_SCORING_CHANGE_REQUESTED) {
        const { id } = payload
        if (id === state.id) {
          return {
            ...state,
            open: true,
          }
        }
        return {
          ...state,
          pending: payload,
        }
      }
      if (type === ACTIVE_SCORING_CHANGE_REJECTED) {
        return {
          ...state,
          pending: null,
        }
      }
      if (type === ACTIVE_SCORING_CHANGE_ACCEPTED) {
        return {
          ...state.pending,
          pending: null,
        }
      }
      if (type === ACTIVE_SCORING_DISCARDED) {
        return { ...initialState, pending: state.pending }
      }
      if (type === USER_LOGGED_OUT) {
        return initialState
      }
      if (type === SCORING_WIZARD_NEXT_STEP) {
        return { ...state, step: Math.min(state.step + 1, STEPS_COUNT - 1) }
      }
      if (type === SCORING_WIZARD_PREVIOUS_STEP) {
        return { ...state, step: Math.max(state.step - 1, 0) }
      }
      if (type === SCORING_WIZARD_TRAINING_UPDATED) {
        const { id, name } = payload
        if (id !== state.id) {
          return {
            ...state,
            training: id,
            training_name: name,
            datasets: [],
            pipeline: null,
            pipeline_output: null,
            pipeline_processing_error: null,
            pipeline_processing_id: null,
            pipeline_processing_progress: 0,
            pipeline_operations_count: 0,
          }
        }
      }
      if (type === SCORING_WIZARD_TRAINING_META_INITIALIZED) {
        return {
          ...state,
          training_name: payload.name,
          datasets: payload.datasets,
          pipeline: payload.pipeline,
          pipeline_output: null,
          pipeline_processing_error: null,
          pipeline_processing_id: null,
          pipeline_processing_progress: 0,
          pipeline_operations_count: 0,
        }
      }
      if (type === SCORING_WIZARD_BRAINS_UPDATED) {
        return {
          ...state,
          brains: payload,
        }
      }
      if (type === SCORING_WIZARD_DATASETS_UPDATED) {
        const { id, index } = payload
        return {
          ...state,
          datasets: state.datasets.map((e, i) => {
            if (i === index) return id
            return e
          }),
          pipeline_processing_id: null,
          pipeline_output: null,
          pipeline_output_hash: null,
          pipeline_output_error: null,
          pipeline_processing_error: null,
        }
      }
      if (type === SCORING_WIZARD_PIPELINE_UPDATED) {
        const { pipeline, datasets, id } = payload
        return {
          ...state,
          pipeline,
          pipeline_output: null,
          pipeline_output_hash: null,
          pipeline_output_error: null,
          pipeline_processing_id: null,
          pipeline_processing_error: null,
          pipeline_processing_progress: 0,
          pipeline_id: id,
          datasets,
        }
      }
      if (type === SCORING_WIZARD_NAME_UPDATED) {
        return { ...state, title: payload }
      }
      if (type === SCORING_WIZARD_RECENT_DATA_TOGGLED) {
        return { ...state, last_row_only: payload }
      }
      if (type === SCORING_WIZARD_PIPELINE_COMMITED) {
        return {
          ...state,
          pipeline_processing_id: payload,
          pipeline_processing_error: null,
          pipeline_processing_progress: 0,
          pipeline_output: null,
          pipeline_output_hash: null,
          pipeline_output_error: null,
        }
      }
      if (type === PIPELINE_PROCESSING_FINISHED) {
        const { id, meta } = payload
        if (!id) return state
        if (id === state.pipeline_processing_id) {
          return {
            ...state,
            pipeline_output_hash: meta[0].hash_out,
            pipeline_processing_error: null,
            pipeline_operations_count: getBoxCount(meta),
          }
        }
      }
      // if (type === PIPELINE_PROCESSING_FINISHED) {
      //   const { id, meta } = payload
      //   if (!id) return state
      //   if (id === state.pipeline_processing_id) {
      //     return {
      //       ...state,
      //       pipeline_output: { key: meta[0].hash_out, ...meta[0].metadata_out },
      //       pipeline_processing_error: null,
      //       // pipeline_processing_id: null,
      //     }
      //   }
      // }
      if (type === PIPELINE_PROCESSING_FAILED) {
        // TODO error message
        const { id } = payload
        if (!id) return state
        if (id === state.pipeline_processing_id) {
          return {
            ...state,
            pipeline_processing_id: null,
            pipeline_processing_error: true,
          }
        }
      }
      if (type === PIPELINE_ELEMENT_FINISHED) {
        const { id, meta, hashOut: hash } = payload
        if (!id) return state
        if (id === state.pipeline_processing_id) {
          if (hash === state.pipeline_output_hash)
            return {
              ...state,
              pipeline_output: meta.stats ? { ...meta.stats } : null,
              pipeline_output_error: null,
              pipeline_processing_id: null,
              pipeline_processing_progress: state.pipeline_processing_progress + 1,
            }
          return {
            ...state,
            pipeline_processing_progress: state.pipeline_processing_progress + 1,
          }
        }
      }
      if (type === PIPELINE_ELEMENT_FAILED) {
        const { id, hashOut: hash } = payload
        if (!id) return state
        if (
          id === state.pipeline_processing_id &&
          hash === state.pipeline_output_hash
        ) {
          return {
            ...state,
            pipeline_output: null,
            pipeline_output_error: true,
            pipeline_processing_id: null,
          }
        }
      }
      // if (type === PIPELINE_ELEMENT_FINISHED || type === PIPELINE_ELEMENT_FAILED) {
      //   // TODO error message
      //   const { id, hashOut, meta } = payload
      //   if (!id) return state
      //   if (
      //     id === state.pipeline_processing_id &&
      //     state.pipeline_output &&
      //     state.pipeline_output.key === hashOut
      //   ) {
      //     return {
      //       ...state,
      //       pipeline_processing_id: null,
      //       pipeline_processing_error: meta.ready ? null : true,
      //     }
      //   }
      // }
      if (type === SCORING_START_REQUEST_STARTED) {
        return { ...state, starting: true }
      }
      if (type === SCORING_START_REQUEST_SUCCEEDED) {
        return { ...initialState, starting: false }
      }
      if (type === SCORING_START_REQUEST_FAILED) {
        return { ...initialState, starting: false }
      }
      return state
    },
    selectors: {
      selectActiveScoring: rawSelector,
      selectScoringWizardStep,
      selectScoringWizardEditable,
      selectScoringWizardPipelineProcessing,
      reactScoringChangeRequest: createSelector(rawSelector, state => {
        if (!state.id && state.pending) return acceptScoringChange()
        return null
      }),
      reactScoringWizardMissingBrains: createSelector(
        rawSelector,
        'selectBrains',
        (state, allBrains) => {
          const { training } = state
          if (training === null) return null
          if (allBrains.find(request => request.id === training)) return null
          return { type: BRAINS_FETCH_REQUESTED, payload: training }
        }
      ),
      reactScoringWizardMissingTrainingMetadata: createSelector(
        rawSelector,
        'selectTrainings',
        'selectDatasets',
        (state, trainings, allDatasets) => {
          const { training: id, datasets } = state
          if (id === null || datasets.length) return null
          const training = trainings.find(t => t.id === id)
          if (!training) return null

          return {
            type: SCORING_WIZARD_TRAINING_META_INITIALIZED,
            payload: {
              name: training.name,
              datasets: training.metadata.map((box, i) =>
                allDatasets.find(ds => ds.id === box.trained_on_id)
                  ? box.trained_on_id
                  : null
              ),
              pipeline: training.pipeline,
            },
          }
        }
      ),
      reactScoringPipelineOutputMissing: createSelector(rawSelector, state => {
        if (
          state.datasets.filter(e => e).length &&
          !state.pipeline_output &&
          !state.pipeline_processing_id
        ) {
          const requestID = uuid()
          return (dispatch, { api }) => {
            dispatch({
              type: SCORING_WIZARD_PIPELINE_COMMITED,
              payload: requestID,
            })
            api.transform('/commit', {
              method: 'POST',
              body: {
                spec: state.pipeline,
                datasets: state.datasets,
                request_id: requestID,
              },
            })
          }
        }
        return null
      }),
    },
    actions: {
      initializeScoringWizard,
      startScoringFromResource,
      acceptScoringChange,
      rejectScoringChange,
      openScoringWizard,
      closeScoringWizard,
      discardActiveScoring,
      scoringWizardNextStep,
      scoringWizardPrevStep,
      setScoringWizardTraining,
      setScoringWizardBrains,
      setScoringWizardDataset,
      setScoringWizardName,
      setScoringWizardSwitch,
      scoringWizardStartScoring,
      runScoring,
    },
    init: null,
    args: null,
    middleware: null,
    persist: null,
  })
}
