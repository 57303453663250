import React, { useContext, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useRefRect } from 'libs/utils/hooks'
import { generateGridPoints, getViewBox } from 'libs/utils/helpers'

import Conditional from 'components/Conditional'
// import Spinner from 'components/Spinner'
import ChartWrapper from 'components/Charts/ChartWrapper'
import { Context } from './RainflowChartContext'

import './style.scss'

const classes = {
  wrapper: 'RainflowChart-RainflowGraph-wrapper',
  loading: 'RainflowChart-RainflowGraph-loading',
  graphSVG: 'RainflowChart-RainflowGraph-graphSVG',
  barRect: 'RainflowChart-RainflowGraph-barRect',
  barLine: 'RainflowChart-RainflowGraph-barLine',
  barText: 'RainflowChart-RainflowGraph-barText',
  noDataDiv: 'RainflowChart-RainflowGraph-noDataDiv',
}

const viewBoxRange = 1000
const minBarWidth = 32

const RainflowGraph = props => {
  const [rect, ref] = useRefRect()
  const wrapperRef = useRef()
  const {
    data = { bars: [] },
    // loading
  } = useContext(Context)

  const {
    xAxis,
    yAxis,
    grid,
    // color
  } = props
  const gridSize = (grid !== true && grid) || 30

  // const { position: yAxisPosition = 'left' } = yAxis || {}
  // const { position: xAxisPosition = 'bottom' } = xAxis || {}

  // const { mouseDownHandler: handler } = wrapperRef.current || {}

  const { start = 0 } = data
  const barCount = data.bars?.length || 0
  const end = data?.bars?.reduce((acc, { value }) => acc + value, start)
  const constantFlow =
    Math.abs(data?.bars?.reduce((acc, { value }) => acc + value, 0)) ===
    data?.bars?.reduce((acc, { value }) => acc + Math.abs(value), 0)

  const domain = useMemo(() => {
    const tempBars = data.bars?.map(({ value }) => value)
    const max = tempBars
      ?.filter(value => value > 0)
      .reduce((acc, cur) => acc + cur, start)
    const min = tempBars
      ?.filter(value => value < 0)
      .reduce((acc, cur) => acc + cur, start)
    const tempDomain = {
      min,
      max,
    }
    return tempDomain
  }, [data, start])

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [viewBoxTemp, _, normalizer] = useMemo(() => {
    return getViewBox(
      {
        x: { min: 0, max: viewBoxRange },
        y: { min: domain.min, max: domain.max },
      },
      null,
      false
    )
  }, [domain])

  /// /////////////// ///
  /// VIEWBOX PADDING ///
  /// /////////////// ///
  const padding = 0.1
  const viewBox = useMemo(
    () => ({
      x: viewBoxTemp.x,
      xRange: viewBoxTemp.xRange,
      y: viewBoxTemp.y - padding * (viewBoxTemp.yRange || viewBoxTemp.y || 1),
      yRange:
        viewBoxTemp.yRange * (1 + 2 * padding) ||
        viewBoxTemp.y * 2 * padding ||
        1 + 2 * padding,
    }),
    [viewBoxTemp]
  )
  /// /////////////// ///
  /// VIEWBOX PADDING ///
  /// /////////////// ///

  const ratios = useMemo(() => {
    const xRatio = (rect && viewBox.xRange / rect.width) || 1
    const yRatio = (rect && viewBox.yRange / rect.height) || 1
    return { x: xRatio, y: yRatio }
  }, [rect, viewBox.xRange, viewBox.yRange])

  const [yAxisPoints, yGridPoints, barWidth] = useMemo(() => {
    const tempYPoints = generateGridPoints(
      viewBox.y,
      viewBox.yRange,
      ratios.y,
      gridSize
    )[0].map(point => {
      return {
        position: (point - viewBox.y) / viewBox.yRange,
        value: point * normalizer.y,
      }
    })
    if (!constantFlow) {
      tempYPoints.push({
        position: (start / normalizer.y - viewBox.y) / viewBox.yRange,
        value: start,
      })
      tempYPoints.push({
        position: (end / normalizer.y - viewBox.y) / viewBox.yRange,
        value: end,
      })
    }
    const tempYGrid = tempYPoints.map(point => point.position) || []
    // const tempBarWidth = Number.parseFloat(
    //   (barCount &&
    //     Math.min(
    //       Math.max(viewBox.xRange / barCount, gridSize * ratios.x),
    //       2 * gridSize * ratios.x
    //     ).toFixed(4)) ||
    //     0
    // )
    const tempBarWidth = Number.parseFloat(
      ((barCount && viewBox.xRange / barCount) || 0).toFixed(4)
    ) // <- strechy bar width w/o minimum
    return [tempYPoints, tempYGrid, tempBarWidth]
  }, [gridSize, viewBox, ratios, normalizer, constantFlow, start, end, barCount])

  // const pixelCorrection = 1 * ratios.y
  // const pixelCorrection = 0

  const bars = useMemo(() => {
    const fontSize = 12 * ratios.y
    if (data.bars?.length) {
      let lastPoint = -Number(Number.parseFloat(start / normalizer.y).toFixed(4))
      const tempResult = data.bars.map(({ feature, value }, i) => {
        const tempValue = Number(Number.parseFloat(value / normalizer.y).toFixed(4))
        const begin = lastPoint - (tempValue + Math.abs(tempValue)) / 2
        const height = Math.abs(tempValue)
        lastPoint -= tempValue
        return (
          <g key={`bar-${feature}-${value}`}>
            <rect
              className={classes.barRect}
              x={i * barWidth}
              // width={barWidth * 0.95}
              width={barWidth}
              y={begin}
              height={height}
              // fill={color}
              // style={{ fill: color }}
              // fill={Math.sign(tempValue) !== 1 ? 'red' : 'green'}
              style={{
                fill: tempValue && (Math.sign(tempValue) !== 1 ? 'red' : 'green'),
              }}
            />
            {/* <line
              className={classes.barLine}
              x1={i * barWidth}
              x2={(i + 1) * barWidth}
              y1={
                begin +
                (height * (1 + -Math.sign(tempValue))) / 2 +
                Math.sign(tempValue) * pixelCorrection * 0.75
              }
              y2={
                begin +
                (height * (1 + -Math.sign(tempValue))) / 2 +
                Math.sign(tempValue) * pixelCorrection * 0.75
              }
              // stroke={color}
              // style={{ stroke: color }}
              // stroke={Math.sign(tempValue) !== 1 ? 'red' : 'green'}
              style={{
                stroke: tempValue && (Math.sign(tempValue) !== 1 ? 'red' : 'green'),
              }}
              strokeWidth={1}
              vectorEffect="non-scaling-stroke"
            /> */}
            <text
              className={classes.barText}
              x={(i + 0.5) * barWidth}
              y={
                begin +
                (height * (1 + Math.sign(tempValue))) / 2 +
                Math.sign(tempValue) * fontSize * 0.5
              }
              fontSize={fontSize}
              alignmentBaseline={Math.sign(tempValue) !== 1 ? 'auto' : 'hanging'}
              transform={`scale(${ratios.x / ratios.y}, 1)`}
              color={tempValue && (Math.sign(tempValue) !== 1 ? 'red' : 'green')}
            >
              {(() => {
                const e = Math.floor(Math.log10(Math.abs(Number(value))))
                let eVal = value.toFixed(4)
                if (e >= 4 || e <= -4) {
                  eVal = `${(value / 10 ** e).toFixed(1)} E${e}`
                }
                return eVal
              })()}
              <Conditional dependencies={tempValue}>
                <Conditional dependencies={!!(Math.sign(value) + 1)}>
                  &#9650;
                </Conditional>
                <Conditional dependencies={!(Math.sign(value) + 1)}>
                  &#9660;
                </Conditional>
              </Conditional>
            </text>
            <text
              className={classes.barText}
              x={(i + 0.5) * barWidth}
              y={
                begin +
                (height * (1 - Math.sign(tempValue))) / 2 -
                Math.sign(tempValue) * fontSize * 0.5
              }
              fontSize={fontSize}
              alignmentBaseline={Math.sign(tempValue) === 1 ? 'auto' : 'hanging'}
              transform={`scale(${ratios.x / ratios.y}, 1)`}
              color={tempValue && (Math.sign(tempValue) !== 1 ? 'red' : 'green')}
            >
              {(() => {
                const e = Math.floor(Math.log10(Math.abs(Number(value))))
                let eVal = Number(value.toFixed(4))
                if (e >= 4 || e <= -4) {
                  eVal = `${(value / 10 ** e).toFixed(1)} E${e}`
                }
                return eVal
              })()}
              <Conditional dependencies={tempValue}>
                <Conditional dependencies={!!(Math.sign(value) + 1)}>
                  &#9650;
                </Conditional>
                <Conditional dependencies={!(Math.sign(value) + 1)}>
                  &#9660;
                </Conditional>
              </Conditional>
            </text>
          </g>
        )
      })
      return tempResult
    }
    return null
  }, [
    data.bars,
    start,
    barWidth,
    normalizer,
    // pixelCorrection,
    ratios,
    // color
  ])

  const features = useMemo(() => {
    // return data.bars?.map(({ feature, value }) => `${feature} (${value})`)
    return data.bars?.map(({ feature }) => feature)
  }, [data])

  const svgViewBox = {
    x: viewBox.x,
    y: Number(Number.parseFloat(-(viewBox.y + viewBox.yRange)).toFixed(4)),
    xRange: viewBox.xRange,
    yRange: viewBox.yRange,
  }

  return (
    <div className={classes.wrapper}>
      {/* <Conditional dependencies={loading}>
        <div className={classes.loading}>
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
          <span>Fetching data...</span>
        </div>
      </Conditional> */}
      <Conditional dependencies={!!data.bars?.length}>
        <ChartWrapper
          ref={wrapperRef}
          xAxis={
            xAxis && {
              ...xAxis,
              type: 'category',
              categories: features,
              // segments:1,
              // spacing:0,
              label: 'Features',
            }
          }
          minWidth={features.length * minBarWidth}
          yAxis={yAxis && { label: 'Impact', ...yAxis, points: yAxisPoints }}
          grid={(!!grid && { xGridPoints: [], yGridPoints }) || {}}
          // footer={{
          //   zoomOutCallback: () =>
          //     setZoomStack([...zoomStack].slice(0, zoomStack.length - 1)),
          //   zoomResetCallback: () => setZoomStack([]),
          //   currentZoom: zoom,
          // }}
        >
          <svg
            ref={ref}
            className={classes.graphSVG}
            style={{ minWidth: `${features.length * minBarWidth}px` }}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`${svgViewBox.x} ${svgViewBox.y} ${svgViewBox.xRange} ${svgViewBox.yRange}`}
            preserveAspectRatio="none"
          >
            {bars}
          </svg>
        </ChartWrapper>
      </Conditional>
      <Conditional dependencies={!data.bars?.length}>
        <div className={classes.noDataDiv}>
          <div>Rainflow Graph</div>
          <div>NO DATA</div>
        </div>
      </Conditional>
    </div>
  )
}

RainflowGraph.propTypes = {
  xAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['top', 'bottom']),
      height: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
    }),
    PropTypes.bool,
  ]),
  yAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
    }),
    PropTypes.bool,
  ]),
  grid: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  color: PropTypes.string,
}

export default RainflowGraph
