/* eslint-disable no-console */
import React from 'react'

import { BaseCard as Card } from 'components/CardLayout'
import { CurrencyGraph } from 'components/Charts/CurrencyGraph'

import './style.scss'

// const classes = {
//   wrapper: 'Dev-wrapper',
//   header: 'Dev-header',
// }

const currencyData = []

const timeNow = Date.now()

for (let index = 0; index < 8640; index++) {
  const low =
    // Math.random() / 20 +
    Math.cos((index * 2 * Math.PI) / (3250 * 2)) / 1.5 +
    Math.cos((index * 2 * Math.PI) / (2500 * 2)) * 1.5 +
    Math.cos((index * 2 * Math.PI) / (1100 * 2)) / 4 +
    Math.cos((index * 2 * Math.PI) / (550 * 2)) / 2 +
    Math.cos((index * 2 * Math.PI) / (350 * 2)) / 3 +
    Math.cos((index * 2 * Math.PI) / (100 * 2)) / 15 +
    Math.cos((index * 2 * Math.PI) / (150 * 2)) / 15 +
    Math.cos((index * 2 * Math.PI) / (200 * 2)) / 15 +
    Math.sin((index * 2 * Math.PI) / (600 * 2)) / 8
  const high =
    low +
    5 +
    //  Math.random() / 20
    +Math.cos((index * 2 * Math.PI) / (700 * 2)) / 1.5
  const mid =
    (low + high) / 2 +
    //  Math.random() / 20 +
    Math.cos((index * 2 * Math.PI) / (550 * 2)) / 1.5 +
    Math.cos((index * 2 * Math.PI) / (700 * 2)) / 2.5
  const candle = mid + (((high - low) / 2) * (Math.random() ** 3 - 0.5)) / 5
  const bar = Math.round(mid)
  const obj = {
    timestamp: timeNow + index * 300000,
    low,
    high,
    mid,
    bar,
    candle,
  }
  if (index % 72 === 0) obj.bottomGraphLine = Math.random()
  currencyData.push(obj)
}

const horizontals = [Math.random() + 1, Math.random() * 2 + 1, Math.random() * 3 + 2]

const range = [Math.random(), Math.random()].sort((a, b) => a - b)

const Dev = props => {
  return (
    <>
      <Card style={{ width: '100%', height: '100%' }}>
        <CurrencyGraph
          data={currencyData}
          horizontals={horizontals}
          bottomRange={range}
        />
      </Card>
    </>
  )
}

export default Dev
