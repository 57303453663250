import React from 'react'
import PropTypes from 'prop-types'

import { MenuItem, TextField } from '@material-ui/core'

const classes = {
  wrapper: 'ResampleBox-config-content-wrapper',
  ruleWrapper: 'ResampleBox-config-content-ResampleRule-wrapper',
}

const ResampleConfig = ({ config, meta, callback }) => {
  const dateFeatures = meta.filter(({ type }) => type === 'time')
  const options = ['minutes', 'hours', 'days', 'months', 'years', 'default']
  return (
    <div className={classes.wrapper}>
      <div className={classes.ruleWrapper}>
        <TextField
          select
          value={config?.frequency || ''}
          onChange={event => callback({ ...config, frequency: event.target.value })}
          label="Resample frequency"
          margin="dense"
          fullWidth
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          value={config.feature_id ?? ''}
          onChange={event => callback({ ...config, feature_id: event.target.value })}
          label="feature"
          margin="dense"
          fullWidth
        >
          {dateFeatures.map(({ id, name }) => (
            <MenuItem value={id}>{name}</MenuItem>
          ))}
        </TextField>
      </div>
    </div>
  )
}

ResampleConfig.propTypes = {
  config: PropTypes.object,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  callback: PropTypes.func,
}

export default ResampleConfig
