const em = 16
const configWidths = [
  { type: 'dataset', width: 500 },
  { type: 'join', width: 400 },
  { type: 'filter', width: 750 },
  { type: 'imputation', width: 650 },
  { type: 'sanitation', width: 700 },
  { type: 'calculation', width: 750 },
  { type: 'resample', width: 400 },
  { type: 'mapping', width: 500 },
]
const configHeights = [
  {
    type: 'dataset',
    height: (_, meta) => {
      const length = Math.min((meta && meta[0]?.features?.length) || 0, 5)
      return Number(length && length * 3.9 * em + (length - 1) * 5) + 7 * em + 30
    },
  },
  { type: 'join', height: () => 300 },
  {
    type: 'filter',
    height: ({ rules }) => {
      const length = Math.min(rules?.length || 0, 5)
      return Number(length && length * 3.9 * em + (length - 1) * 5) + 10 * em + 35
    },
  },
  {
    type: 'imputation',
    height: ({ rules }) => {
      const length = Math.min(rules?.length || 0, 5)
      return Number(length && length * 3.9 * em + (length - 1) * 5) + 10 * em + 35
    },
  },
  {
    type: 'sanitation',
    height: ({ rules }) => {
      const length = Math.min(rules?.length || 0, 5)
      return Number(length && length * 3.9 * em + (length - 1) * 5) + 10 * em + 35
    },
  },
  {
    type: 'calculation',
    height: ({ rules }) => {
      const length = Math.min(rules?.length || 0, 5)
      return Number(length && length * 3.9 * em + (length - 1) * 5) + 10 * em + 35
    },
  },
  { type: 'resample', height: () => 10.9 * em + 30 },
  {
    type: 'mapping',
    height: (_, meta) => {
      const length = Math.min((meta && meta[0]?.features?.length) || 0, 5)
      return Number(length && length * 3.9 * em + (length - 1) * 5) + 7 * em + 30
    },
  },
]
export const getWidthBasedOnType = type =>
  configWidths.find(e => e.type === type)?.width || 500
export const getHeightBasedOnType = type =>
  configHeights.find(e => e.type === type)?.height || 400
