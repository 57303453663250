/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import UserControlsBar from 'components/UserControls'
import { Provider } from '../BarChartContext'

const classes = {
  wrapper: 'BarChart-UserControlWrapper-wrapper',
}

const UserControlWrapper = props => {
  const {
    controlable,
    trainings: propTrainings,
    brains: propBrains,
    split: propSplit,
    datasets: propDatasets,
    stackingCategories: propStackingCategories,
    // datasetType: propDatasetType,
    features: propFeatures,
    featureType: propFeatureType,
    // scoredDataset: propScoredDataset,
    // objective: propObjective,
    // brain: propBrain,
    // explanation: propExplaination,
    labels,
    children,
  } = props
  const { x: xLabel, y: yLabel } = labels
  const { prefKey } = controlable
  const {
    registerResourceRequest,
    registerBrainsRequest,
    updatePref,
    pref: { [prefKey]: storePref = {} },
    brains: storeBrains,
    explanations: storeExplainations,
    resourceData,
    resourceMeta,
  } = useStore(
    'registerResourceRequest',
    'registerBrainsRequest',
    'updatePref',
    'selectPref',
    'selectBrains',
    'selectDatasets',
    'selectExplanations',
    'selectResourceData',
    'selectResourceMeta'
  )
  const [statePref, setStatePref] = useState({})

  const pref = (prefKey && storePref) || statePref

  const [
    trainings,
    brains,
    split,
    datasets,
    stackingCategories,
    mode,
    features,
    featureType,
  ] = useMemo(() => {
    const tempTrainings = pref.trainings || propTrainings || []
    const tempBrains = pref.brains || propBrains || []
    tempTrainings.forEach(training => {
      const tempTraining = tempBrains?.find(e => e.id === training.id)
      if (!tempTraining) tempBrains.push({ id: training.id, brains: [] })
    })
    const tempSplit = pref.split || propSplit || 'train'
    const tempDatasets = pref.datasets || propDatasets || []
    const tempStackingColumn =
      pref.stackingCategories || propStackingCategories || null
    let tempMode = 'datasetMode'
    if (
      controlable?.scoring ||
      controlable?.scoredDataset ||
      controlable?.objective ||
      controlable?.brain
    )
      tempMode = 'explanationMode'
    if (
      controlable?.trainings ||
      controlable?.brains ||
      propTrainings?.length ||
      propBrains?.length
    )
      tempMode = 'brainMode'
    const tempFeatures = pref.features || propFeatures || []
    const tempFeatureType = tempFeatures[0]?.type || propFeatureType || null
    return [
      tempTrainings,
      tempBrains,
      tempSplit,
      tempDatasets,
      tempStackingColumn,
      tempMode,
      tempFeatures,
      tempFeatureType,
    ]
  }, [
    pref,
    propTrainings,
    propBrains,
    propSplit,
    propDatasets,
    propStackingCategories,
    controlable,
    propFeatures,
    propFeatureType,
  ])

  const [stackedBy, datasetType] = useMemo(() => {
    const tempMeta = resourceMeta?.find(({ id }) => id === datasets[0])?.data || {}
    const { stacked_by: stackedById = null } = tempMeta
    const tempStackedBy =
      tempMeta.features?.find(({ id }) => id === stackedById) || null
    const tempType =
      // propDatasetType ||
      (datasets.length && tempMeta.type) || null
    return [tempStackedBy, tempType]
  }, [resourceMeta, datasets])

  const storeData = {
    brains: storeBrains || [],
    datasets:
      datasets
        .map(
          dataset =>
            resourceData.find(resource => resource.key === `chart-${dataset}`) ||
            null
        )
        .filter(e => !!e) || [],
    explanations: storeExplainations || [],
  }

  const loading = useMemo(() => {
    return (
      !!storeData.brains.some(({ isLoading }) => isLoading) ||
      !!storeData.datasets.some(({ isLoading }) => isLoading) ||
      !!storeData.explanations.some(({ isLoading }) => isLoading)
    )
  }, [storeData.brains, storeData.datasets, storeData.explanations])

  // /////////////////////////////////// CALLBACKS
  const trainingsCallback = useCallback(
    selectedTrainings => {
      const tempPref = { ...pref }
      const storeTrainingIDs = storeData.brains.map(({ id }) => id)
      const difference = selectedTrainings.filter(
        dataset => storeTrainingIDs.indexOf(dataset) === -1
      )
      difference.forEach(({ id: trainingID }) => registerBrainsRequest(trainingID))
      tempPref.trainings = selectedTrainings
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, storeData.brains, registerBrainsRequest, prefKey, updatePref]
  )
  const brainsCallback = useCallback(
    (trainingID, selectedBrains) => {
      const tempPref = { ...pref }
      tempPref.brains = [...brains]
      const tempTraining = tempPref.brains.find(
        training => training.id === trainingID
      )
      if (tempTraining) tempTraining.brains = selectedBrains
      else tempPref.brains.push({ id: trainingID, brains: selectedBrains })
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, prefKey, updatePref, brains]
  )
  const splitCallback = useCallback(
    ([selectedSplit]) => {
      const tempPref = { ...pref }
      tempPref.split = selectedSplit
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, prefKey, updatePref]
  )
  const datasetsCallback = useCallback(
    selectedDatasets => {
      const tempPref = { ...pref }
      const storeDatasetIDs = storeData.datasets.map(({ id }) => id)
      const difference = selectedDatasets.filter(
        dataset => storeDatasetIDs.indexOf(dataset) === -1
      )
      difference.forEach(dataset =>
        registerResourceRequest(`chart-${dataset}`, dataset, 'chart')
      )
      tempPref.datasets = selectedDatasets
      const tempType =
        (selectedDatasets.length &&
          resourceMeta?.find(({ id }) => id === selectedDatasets[0])?.data?.type) ||
        null
      if (tempType !== datasetType) tempPref.features = []
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [
      pref,
      storeData.datasets,
      registerResourceRequest,
      resourceMeta,
      datasetType,
      prefKey,
      updatePref,
    ]
  )
  const stackingCategoriesCallback = useCallback(
    selectedCategories => {
      const tempPref = { ...pref }
      tempPref.stackingCategories = selectedCategories
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, prefKey, updatePref]
  )
  const featuresCallback = useCallback(
    selectedFeatures => {
      const tempPref = { ...pref }
      tempPref.features = selectedFeatures
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, prefKey, updatePref]
  )
  // /////////////////////////////////////////////

  // //////////////////////////////////// CONTROLS
  const controls = useMemo(() => {
    const tempControls = []
    if (mode === 'brainMode') {
      if (controlable.trainings) {
        tempControls.push({
          control: 'trainingSelect',
          values: trainings.map(({ id }) => id),
          callback: trainingsCallback,
          multiple: controlable.trainings.multiple,
          title: `Training${controlable.trainings.multiple ? 's' : ''}`,
        })
      }
      if (controlable.split && trainings.length) {
        tempControls.push({
          control: 'trainingSplitSelect',
          values: [split],
          callback: splitCallback,
        })
      }
      if (controlable.brains && trainings.length && split) {
        trainings.forEach(({ id, name }) => {
          const tempTraining = brains.find(training => training.id === id)
          tempControls.push({
            control: 'brainSelect',
            id,
            split,
            values: tempTraining.brains || [],
            callback: selectedBrains => brainsCallback(id, selectedBrains),
            multiple: controlable.brains.multiple,
            title: name,
          })
        })
      }
    } else {
      if (controlable.datasets) {
        tempControls.push({
          control: 'datasetSelect',
          values: datasets,
          callback: datasetsCallback,
          multiple:
            datasetType && datasetType !== 'stacked-timeseries'
              ? controlable.datasets.multiple
              : false,
          type:
            datasetType && datasetType !== 'stacked-timeseries'
              ? controlable.datasets.multiple && datasetType
              : null,
          title: `Dataset${controlable.datasets.multiple ? 's' : ''}`,
        })
      }
      if (controlable.stackingCategories && datasetType === 'stacked-timeseries') {
        tempControls.push({
          control: 'stackingCategorySelect',
          ids: datasets,
          values: stackingCategories,
          callback: stackingCategoriesCallback,
          multiple: controlable.stackingCategories.multiple,
          title: `Categor${controlable.stackingCategories.multiple ? 'ies' : 'y'}`,
        })
      }
    }
    if (controlable.features) {
      if (mode === 'brainMode') {
        if (trainings.length)
          tempControls.push({
            control: 'brainFeatureSelect',
            ids: trainings.map(({ id }) => id),
            values: features,
            callback: featuresCallback,
            // chartType: 'objectives',
            multiple: controlable.features.multiple,
            type: ['numeric', 'category'],
            singleType: true,
            title: `Feature${controlable.features.multiple ? 's' : ''}`,
          })
      } else if (datasets.length)
        tempControls.push({
          control: 'featureSelect',
          ids: datasets,
          values: features,
          callback: featuresCallback,
          multiple: controlable.features.multiple,
          type: ['numeric', 'category'],
          singleType: true,
          title: `Feature${controlable.features.multiple ? 's' : ''}`,
        })
    }
    return tempControls
  }, [
    mode,
    controlable,
    trainingsCallback,
    brainsCallback,
    splitCallback,
    datasetsCallback,
    stackingCategoriesCallback,
    featuresCallback,
    trainings,
    brains,
    split,
    datasets,
    datasetType,
    stackingCategories,
    features,
  ])
  // /////////////////////////////////////////////

  const xAxisLabel = useMemo(() => {
    const tempXLabel = features.map(feature => feature.name).join(', ')
    return xLabel || tempXLabel
  }, [features, xLabel])

  const data = useMemo(() => {
    const returnData = []
    if (
      mode === 'brainMode' &&
      trainings.length &&
      brains.length &&
      features.length
    ) {
      const resultData = features.reduce((acc, cur) => {
        const tempResult = trainings
          .map(({ name, id }) => {
            const { objectives: tempBrains = {} } =
              storeData.brains.find(training => training.id === id)?.data || {}
            const { objectives } = tempBrains
            return { name, brains: tempBrains, objectives, id }
          })
          .filter(
            training =>
              training.objectives &&
              training.objectives.find(
                obj => obj.type === cur.type && obj.name === cur.name
              )
          )
          .map(training => {
            const filteredBrains = training.brains[split].filter(
              tempBrain =>
                brains
                  .find(e => e.id === training.id)
                  .brains.indexOf(tempBrain.id) !== -1
            )
            return filteredBrains.map(e => e[cur.name])
          })
        const combinedResult = tempResult.reduce((accumulated, current) => {
          const tempCombined = [...accumulated, ...current]
          return tempCombined
        }, [])
        return [...acc, ...combinedResult]
      }, [])
      returnData.push({
        title: `${trainings.length > 1 ? 'combined' : trainings[0]?.name}: ${
          features.length > 1 ? 'combined' : features[0]?.name
        }`,
        data: [...resultData],
      })
    }
    // if (mode !== 'brainMode' && scoredDataset && objective && brain) {
    //   const resultData =
    //     storeData.explanations
    //       .find(({ id }) => id === scoredDataset)
    //       ?.data?.find(
    //         ({ objective: expObj, brain_id: expBId, row: expR }) =>
    //           expObj === objective && expBId === brain && !(expR || expR === 0)
    //       )?.data?.weights || {}
    //   returnData.push({
    //     title: 'Overall importance',
    //     data:
    //       Object.keys(resultData).reduce((acc, cur) => {
    //         const tempResult = [...acc]
    //         tempResult.push({ category: cur, value: resultData[cur] })
    //         return tempResult
    //       }, []) || [],
    //   })
    // }
    if (
      mode !== 'brainMode' &&
      mode !== 'explanationMode' &&
      datasets.length &&
      (datasetType !== 'stacked-timeseries' ||
        (stackedBy && stackingCategories.length)) &&
      features.length
    ) {
      const resultData = features.reduce((acc, cur) => {
        const tempResult = storeData.datasets
          .filter(instance =>
            instance?.meta?.features?.find(
              tempFeature =>
                tempFeature.type === cur.type && tempFeature.name === cur.name
            )
          )
          .map(
            instance =>
              instance.data
                .filter(
                  e =>
                    datasetType !== 'stacked-timeseries' ||
                    stackingCategories.indexOf(e[stackedBy.name]) !== -1
                )
                .map(e =>
                  featureType === 'numeric' ? Number(e[cur.name]) : e[cur.name]
                )
                .filter(val => !!val || val === 0) || []
          )
        const combinedResult = tempResult.reduce((accumulated, current) => {
          const tempCombined = [...accumulated, ...current]
          return tempCombined
        }, [])
        return [...acc, ...combinedResult]
      }, [])
      // const name =
      //   datasets.length > 1
      //     ? 'combined'
      //     : resourceMeta?.find(dataset => dataset.id === datasets[0])?.data?.name ||
      //       ''
      returnData.push({
        // title: `${name}: ${features.length > 1 ? 'combined' : features[0]?.name}`,
        data: [...resultData],
      })
    }
    return returnData
  }, [
    mode,
    trainings,
    brains,
    split,
    storeData.brains,
    storeData.datasets,
    datasets,
    stackedBy,
    datasetType,
    stackingCategories,
    features,
    featureType,
  ])

  const contextData = {
    data,
    xAxisLabel,
    yAxisLabel: yLabel,
    type: featureType,
    loading,
  }

  if (mode === 'explanationMode') contextData.type = 'explanation'

  return (
    <div className={classes.wrapper}>
      <UserControlsBar controls={controls} chart />
      <Provider value={contextData}>{children}</Provider>
    </div>
  )
}

UserControlWrapper.propTypes = {
  controlable: PropTypes.shape({
    trainings: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    brains: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    split: PropTypes.bool,
    datasets: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    stackingCategories: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    features: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    scoring: PropTypes.bool,
    scoredDataset: PropTypes.bool,
    objective: PropTypes.bool,
    brain: PropTypes.bool,
    prefKey: PropTypes.string,
  }),

  // /////////////// TRAININGS DISPLAY
  trainings: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
  ),
  brains: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      brains: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  split: PropTypes.oneOf(['combined', 'test', 'train', 'validation']),
  // /////////////////////////////////

  // //////////////// DATASETS DISPLAY
  datasets: PropTypes.arrayOf(PropTypes.string),
  stackingCategories: PropTypes.arrayOf(PropTypes.string),
  // type: PropTypes.oneOf(['stacked-timeseries', 'basedata', 'timeseries']),
  // /////////////////////////////////

  features: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['numeric', 'category']),
      name: PropTypes.string,
    })
  ),
  featureType: PropTypes.oneOf(['numeric', 'category']),

  // //////////////// EXPLANATION DISPLAY
  scoredDataset: PropTypes.shape({ id: PropTypes.string }),
  objective: PropTypes.string,
  brain: PropTypes.string,
  explanation: PropTypes.shape({
    scored_dataset_id: PropTypes.string,
    objective: PropTypes.string,
    brain_id: PropTypes.string,
  }),
  // /////////////////////////////////

  labels: PropTypes.shape({ x: PropTypes.string, y: PropTypes.string }),
  children: PropTypes.node,
}

export default UserControlWrapper
