/* eslint-disable no-underscore-dangle */
export class ServerSentEventProxy {
  static _store = 'pera'

  static listeners = null

  listeners = {}

  constructor(store) {
    ServerSentEventProxy._store = store
  }

  // eslint-disable-next-line class-methods-use-this
  get store() {
    return ServerSentEventProxy._store
  }

  addEventListener(type, callback) {
    this.listeners[type] = this.listeners[type] || []
    this.listeners[type].push(callback)
  }

  removeEventListener(type, callback) {
    if (!this.listeners[type]) return

    const stack = this.listeners[type]
    for (let i = 0, l = stack.length; i < l; i++) {
      if (stack[i] === callback) {
        stack.splice(i, 1)
        return
      }
    }
  }

  dispatchEvent(event) {
    if (!this.listeners[event.type]) return true

    const stack = this.listeners[event.type].slice()
    for (let i = 0, l = stack.length; i < l; i++) {
      stack[i].call(null, event, this.store)
    }
    return !event.defaultPrevented
  }
}

export function ServerSentEvent(type, eventInitDict) {
  Object.defineProperty(this, 'type', {
    writable: false,
    value: type,
    enumerable: true,
  })
  Object.keys(eventInitDict).forEach(f => {
    if (Object.hasOwnProperty.call(eventInitDict, f)) {
      Object.defineProperty(this, f, {
        writable: false,
        value: eventInitDict[f],
        enumerable: true,
      })
    }
  })
}
