import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import { useRefRect } from 'libs/utils/hooks'
import {
  isObject,
  generateGridPoints,
  getFormatFromScale,
  getDomain,
  getViewBox,
  calculateZoomBox,
} from 'libs/utils/helpers'

import Conditional from 'components/Conditional'
import Spinner from 'components/Spinner'
import ChartWrapper from 'components/Charts/ChartWrapper'
import ScatterLegend from './ScatterLegend'
import { Context } from './ScatterChartContext'

import './style.scss'

const classes = {
  wrapper: 'ScatterChart-ScatterGraph-wrapper',
  loading: 'ScatterChart-ScatterGraph-loading',
  graphSVG: 'ScatterChart-ScatterGraph-graphSVG',
  dot: 'ScatterChart-ScatterGraph-dot',
  valueText: 'ScatterChart-ScatterGraph-valueText',
  noDataDiv: 'ScatterChart-ScatterGraph-noDataDiv',
}

const zoomCap = 10000 // percentage
const rounding = false

const ScatterGraph = props => {
  const [hovered, setHovered] = useState(null)
  const [zoomStack, setZoomStack] = useState([])
  const [rect, ref] = useRefRect()
  const wrapperRef = useRef()
  const {
    data: contextData = [],
    xAxisLabel,
    yAxisLabel,
    xAxisType,
    domain: contextDomain,
    loading,
  } = useContext(Context)

  const limits = useMemo(() => {
    const x = { min: null, max: null }
    const y = { min: null, max: null }
    if (isObject(contextDomain?.x)) {
      const { min, max } = contextDomain.x
      x.min = min || min === 0 ? min : null
      x.max = max || max === 0 ? max : null
    }
    if (isObject(contextDomain?.y)) {
      const { min, max } = contextDomain.y
      y.min = min || min === 0 ? min : null
      y.max = max || max === 0 ? max : null
    }
    return { x, y }
  }, [contextDomain])

  const { legend, xAxis, yAxis, dotSize = 5, valueTextSize = 16, grid } = props
  const gridSize = (grid !== true && grid) || 30

  const { position: legendPosition = 'left', width: legendWidth = 160 } =
    (legend && legend !== true ? legend : {}) || {}

  // const { position: yAxisPosition = 'left' } = yAxis || {}
  // const { position: xAxisPosition = 'bottom' } = xAxis || {}

  const { mouseDownHandler: handler } = wrapperRef.current || {}

  const tempData = useMemo(() => {
    return contextData.map(datum => {
      return { ...datum, id: uuid() }
    })
  }, [contextData])

  const domain = useMemo(() => {
    const tempDomain = getDomain(tempData, limits)
    if (Array.isArray(contextDomain?.x) && contextDomain.x.length >= 2) {
      const { x } = tempDomain
      const [min, max] = contextDomain.x
      x.min = min
      x.max = max
    }
    if (Array.isArray(contextDomain?.y) && contextDomain.y.length >= 2) {
      const { y } = tempDomain
      const [min, max] = contextDomain.y
      y.min = min
      y.max = max
    }
    return tempDomain
  }, [tempData, limits, contextDomain])

  /// /////////////// ///
  /// DOMAIN PADDING ///
  /// /////////////// ///
  const paddingX = 0.1
  const paddingY = 0.1
  const correctedDomain = useMemo(() => {
    const domainXRange = Math.abs(domain.x.max - domain.x.min)
    const domainYRange = Math.abs(domain.y.max - domain.y.min)
    const temp = { x: { min: null, max: null }, y: { min: null, max: null } }
    temp.x.min =
      domain.x.min - paddingX * (domainXRange || Math.abs(domain.x.min) || 1)
    temp.x.max =
      domain.x.max + paddingX * (domainXRange || Math.abs(domain.x.max) || 1)
    temp.y.min =
      domain.y.min - paddingY * (domainYRange || Math.abs(domain.y.min) || 1)
    temp.y.max =
      domain.y.max + paddingY * (domainYRange || Math.abs(domain.y.max) || 1)
    return temp
  }, [domain])
  /// /////////////// ///
  /// VIEWBOX PADDING ///
  /// /////////////// ///

  const [viewBox, tempZoom, normalizer] = useMemo(() => {
    return getViewBox(correctedDomain, zoomStack, rounding)
  }, [correctedDomain, zoomStack])

  const [zoom, allowZoom] = useMemo(
    () => [
      {
        x: Math.min(zoomCap, tempZoom.x),
        y: Math.min(zoomCap, tempZoom.y),
      },
      tempZoom.x < zoomCap || tempZoom.y < zoomCap,
    ],
    [tempZoom]
  )

  const ratios = useMemo(() => {
    const xRatio = (rect && viewBox.xRange / rect.width) || null
    const yRatio = (rect && viewBox.yRange / rect.height) || null
    return { x: xRatio, y: yRatio }
  }, [rect, viewBox.xRange, viewBox.yRange])

  const [xAxisPoints, xScale, yAxisPoints, xGridPoints, yGridPoints] =
    useMemo(() => {
      const [xPoints, tempXScale] = generateGridPoints(
        viewBox.x,
        viewBox.xRange,
        ratios.x,
        gridSize,
        normalizer.x,
        false,
        xAxisType === 'time'
      )
      const tempXPoints = xPoints.map(point => {
        return {
          position: (point - viewBox.x) / viewBox.xRange,
          value: point * normalizer.x,
        }
      })
      const tempYPoints = generateGridPoints(
        viewBox.y,
        viewBox.yRange,
        ratios.y,
        gridSize
      )[0].map(point => {
        return {
          position: (point - viewBox.y) / viewBox.yRange,
          value: point * normalizer.y,
        }
      })
      const tempXGrid = tempXPoints.map(point => point.position) || []
      const tempYGrid = tempYPoints.map(point => point.position) || []
      return [tempXPoints, tempXScale, tempYPoints, tempXGrid, tempYGrid]
    }, [viewBox, ratios, gridSize, xAxisType, normalizer])

  const data = tempData.map(datum => {
    return {
      ...datum,
      data: datum.data?.filter(cur => {
        const { x, y } = cur
        const xInRange =
          x >= viewBox.x * normalizer.x &&
          x <= (viewBox.x + viewBox.xRange) * normalizer.x
        const yInRange =
          y >= viewBox.y * normalizer.y &&
          y <= (viewBox.y + viewBox.yRange) * normalizer.y
        return xInRange && yInRange
      }, []),
    }
  })

  const groups = useMemo(() => {
    const tempResult = data.map((datum, i) => {
      let dots = null
      if (ratios.x && ratios.y)
        dots = datum.data.map((e, dotI) => {
          const x = Number(Number.parseFloat(e.x / normalizer.x).toFixed(4))
          const y = Number(Number.parseFloat(e.y / normalizer.y).toFixed(4))
          const relativeX = (x - viewBox.x) / viewBox.xRange
          const relativeY = Math.abs((y - viewBox.y) / viewBox.yRange)
          const baseline = relativeY > 0.5 ? 'hanging' : 'auto'
          return (
            // eslint-disable-next-line react/no-array-index-key
            <g key={`ScatterGraph-dot-${dotI}`}>
              <ellipse
                className={classes.dot}
                cx={x || 0}
                cy={-y || 0}
                r={1}
                rx={dotSize * ratios.x}
                ry={dotSize * ratios.y}
                style={{ fill: datum.color }}
              />

              <text
                className={classes.valueText}
                // x={x || 0}
                // y={-y || 0}
                x={
                  (x || 0) +
                  (-1) ** Number(relativeX > 0.5) *
                    dotSize *
                    ratios.x *
                    (2 + Number(relativeX > 0.5) * 10)
                }
                y={(-y || 0) + (-1) ** Number(relativeY < 0.5) * dotSize * ratios.y}
                alignmentBaseline={baseline}
                fontSize={valueTextSize * ratios.y}
                transform={`scale(${ratios.x / ratios.y || 1}, 1)`}
              >
                {(() => {
                  if (typeof e.z === 'string') return e.z
                  if (e.z || e.z === 0) {
                    const exp = Math.floor(Math.log10(Math.abs(Number(e.z))))
                    let eVal = Number(e.z?.toFixed(4))
                    if (exp >= 4 || exp <= -4) {
                      eVal = `${(e.z / 10 ** exp).toFixed(1)} E${exp}`
                    }
                    return eVal
                  }
                  return null
                })()}
              </text>
            </g>
          )
        })
      return (
        <g
          key={`ScatterGraph-group-${datum.color || i}`}
          style={{
            fillOpacity: (hovered && (hovered === datum.id ? 1 : 0.001)) || 1,
            display: hovered && hovered !== datum.id && 'none',
          }}
          onMouseEnter={() => setHovered(datum.id)}
          onMouseLeave={() => setHovered(null)}
        >
          {dots}
        </g>
      )
    })
    return tempResult
  }, [data, ratios, normalizer, hovered, viewBox, dotSize, valueTextSize])

  const zoomCallback = useCallback(
    zoomBox => {
      if (allowZoom) {
        const tempZoomBox = calculateZoomBox(
          zoomBox,
          zoomCap,
          correctedDomain,
          viewBox,
          normalizer,
          rounding
        )
        setZoomStack([...zoomStack, tempZoomBox])
      }
    },
    [allowZoom, zoomStack, correctedDomain, viewBox, normalizer]
  )

  const isThereDataToDisplay = tempData.reduce((acc, cur) => {
    if (!acc) return !!cur.data.length
    return acc
  }, false)

  const svgViewBox = {
    x: viewBox.x,
    y: Number(Number.parseFloat(-(viewBox.y + viewBox.yRange)).toFixed(4)),
    xRange: viewBox.xRange,
    yRange: viewBox.yRange,
  }

  const xFormat = useMemo(() => getFormatFromScale(xScale), [xScale])

  return (
    <div
      className={classes.wrapper}
      style={{ flexDirection: legendPosition === 'right' ? 'row-reverse' : '' }}
    >
      <Conditional dependencies={loading}>
        <div className={classes.loading}>
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
          <span>Fetching data...</span>
        </div>
      </Conditional>
      <Conditional dependencies={isThereDataToDisplay}>
        <Conditional dependencies={legend}>
          <ScatterLegend
            data={data}
            hoverCallback={id => setHovered(id)}
            hoverReset={() => setHovered(null)}
            width={legendWidth}
          />
        </Conditional>
        <ChartWrapper
          ref={wrapperRef}
          xAxis={
            xAxis && {
              ...xAxis,
              points: xAxisPoints,
              label: xAxisLabel,
              render:
                xAxisType === 'time'
                  ? (value, i, length, x, _) =>
                      i === 0 || i === length - 1 ? (
                        <>
                          <tspan dx="0" dy="-1em">
                            {moment(value * 1000).format('DD-MM-YYYY')}
                          </tspan>
                          <tspan x={x} dy="1em">
                            {moment(value * 1000).format('HH:mm:ss.SSS')}
                          </tspan>
                          <tspan x={x} dy="1em">
                            {moment(value * 1000).format('dddd')}
                          </tspan>
                        </>
                      ) : (
                        moment(value * 1000).format(xFormat)
                      )
                  : null,
            }
          }
          yAxis={yAxis && { ...yAxis, points: yAxisPoints, label: yAxisLabel }}
          grid={(!!grid && { xGridPoints, yGridPoints }) || {}}
          zoomCallback={allowZoom && zoomCallback}
          footer={{
            zoomOutCallback: () =>
              setZoomStack([...zoomStack].slice(0, zoomStack.length - 1)),
            zoomResetCallback: () => setZoomStack([]),
            currentZoom: zoom,
          }}
        >
          <svg
            ref={ref}
            className={classes.graphSVG}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`${svgViewBox.x} ${svgViewBox.y} ${svgViewBox.xRange} ${svgViewBox.yRange}`}
            preserveAspectRatio="none"
            onMouseDown={event => handler && allowZoom && handler(event)}
          >
            {groups}
          </svg>
        </ChartWrapper>
      </Conditional>
      <Conditional dependencies={!isThereDataToDisplay}>
        <div className={classes.noDataDiv}>
          <div>Scatter Graph</div>
          <div>NO DATA</div>
        </div>
      </Conditional>
    </div>
  )
}

ScatterGraph.propTypes = {
  legend: PropTypes.shape({
    position: PropTypes.oneOf(['left', 'right']),
    width: PropTypes.number,
  }),
  xAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['top', 'bottom']),
      height: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
    }),
    PropTypes.bool,
  ]),
  yAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
    }),
    PropTypes.bool,
  ]),
  dotSize: PropTypes.number,
  valueTextSize: PropTypes.number,
  grid: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

export default ScatterGraph
