/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable quotes */
export const momentumIndicators = [
  {
    label: 'Average Directional Movement Index',
    function: 'adx',
    type: 'momentum',
    low: null,
    high: null,
    close: null,
    time: 14,
  },
  {
    label: 'Aroon Oscillator',
    function: 'aroonosc',
    type: 'momentum',
    low: null,
    high: null,
    time: 14,
  },
  {
    label: 'Balance Of Power',
    function: 'bop',
    type: 'momentum',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Commodity Channel Index',
    function: 'cci',
    type: 'momentum',
    low: null,
    high: null,
    close: null,
    time: 14,
  },
  {
    label: 'Chande Momentum Oscillator',
    function: 'cmo',
    type: 'momentum',
    time: 14,
  },
  {
    label: 'Heikin Ashi',
    function: 'heikin',
    type: 'momentum',
    open: null,
    low: null,
    high: null,
    close: null,
    // time: 1,
  },
  {
    label: 'Moving Average Convergence / Divergence',
    function: 'macd',
    type: 'momentum',
    slow: 26,
    fast: 12,
    signal: 9,
  },
  {
    label: 'MACD with controllable MA type',
    function: 'macdext',
    type: 'momentum',
    slow: 26,
    slowmatype: 0,
    fast: 12,
    fastmatype: 0,
    signal: 9,
    signalmatype: 0,
  },
  {
    label: 'Money Flow Index',
    function: 'mfi',
    type: 'momentum',
    volume: null,
    low: null,
    high: null,
    close: null,
    time: 14,
  },
  {
    label: 'Minus Directional Indicator',
    function: 'minus_di',
    type: 'momentum',
    low: null,
    high: null,
    close: null,
    time: 14,
  },
  {
    label: 'Plus Directional Indicator',
    function: 'plus_di',
    type: 'momentum',
    low: null,
    high: null,
    close: null,
    time: 14,
  },
  {
    label: 'Minus Directional Movement',
    function: 'minus_dm',
    type: 'momentum',
    low: null,
    high: null,
    time: 14,
  },
  {
    label: 'Plus Directional Movement',
    function: 'plus_dm',
    type: 'momentum',
    low: null,
    high: null,
    time: 14,
  },
  {
    label: 'Rate of change Percentage: (price - prevPrice) / prevPrice',
    function: 'rocp',
    type: 'momentum',
    time: 10,
  },
  {
    label: 'Relative Strength Index',
    function: 'rsi',
    type: 'momentum',
    time: 14,
  },
  {
    label: 'Stochastic',
    function: 'stoch',
    type: 'momentum',
    low: null,
    high: null,
    close: null,
    fastk_period: 5,
    slowk_period: 3,
    slowk_matype: 0,
    slowd_period: 3,
    slowd_matype: 0,
    target: 'slowk',
  },
  {
    label: 'Stochastic Fast',
    function: 'stochf',
    type: 'momentum',
    low: null,
    high: null,
    close: null,
    fastk_period: 5,
    fastd_period: 3,
    fastd_matype: 0,
    target: 'fastk',
  },
  {
    label: 'Stochastic Relative Strength Index',
    function: 'stochrsi',
    type: 'momentum',
    // low: null,
    // high: null,
    // close: null,
    time: 14,
    fastk_period: 5,
    fastd_period: 3,
    fastd_matype: 0,
    target: 'fastk',
  },
  {
    label: '1 - day Rate - Of - Change(ROC) of a Triple Smooth EMA',
    function: 'trix',
    type: 'momentum',
    time: 30,
  },
  {
    label: 'Ultimate Oscillator',
    function: 'ultosc',
    type: 'momentum',
    low: null,
    high: null,
    close: null,
    time1: 7,
    time2: 14,
    time3: 28,
  },
  {
    label: "Williams' % R",
    function: 'willr',
    type: 'momentum',
    low: null,
    high: null,
    close: null,
    time: 14,
  },
  {
    label: 'Normalized Slope',
    function: 'normalized_slope',
    type: 'momentum',
    slope_center: false,
    slope_samples: 2,
    stddev_center: false,
    stddev_samples: 7,
  },
]
export const overlapStudies = [
  {
    label: 'Bollinger Bands',
    function: 'bbands',
    type: 'overlap',
    time: 30,
    nbdevup: 2,
    nbdevdn: 2,
    matype: 0,
    target: 'upperband',
  },
  {
    label: 'Double Exponential Moving Average',
    function: 'dema',
    type: 'overlap',
    time: 30,
  },
  {
    label: 'Exponential Moving Average',
    function: 'ema',
    type: 'overlap',
    time: 30,
  },
  {
    label: 'Hilbert Transform - Instantaneous Trendline',
    function: 'ht_trendline',
    type: 'overlap',
  },
  {
    label: 'Kaufman Adaptive Moving Average',
    function: 'kama',
    type: 'overlap',
    time: 30,
  },
  {
    label: 'MESA Adaptive Moving Average',
    function: 'mama',
    type: 'overlap',
    fastlimit: 0.5,
    slowlimit: 0.05,
    target: 'mama',
  },
  {
    label: 'Midpoint Price over period',
    function: 'midprice',
    type: 'overlap',
    low: null,
    high: null,
    time: 14,
  },
  {
    label: 'Parabolic SAR',
    function: 'sar',
    type: 'overlap',
    low: null,
    high: null,
    acceleration: 0.02,
    maximum: 0.2,
  },
  {
    label: 'Parabolic SAR - Extended',
    function: 'sarext',
    type: 'overlap',
    low: null,
    high: null,
    startvalue: 0,
    offsetonreverse: 0,
    accelerationinitlong: 0,
    accelerationlong: 0,
    accelerationmaxlong: 0,
    accelerationinitshort: 0,
    accelerationshort: 0,
    accelerationmaxshort: 0,
  },
  {
    label: 'Triple Exponential Moving Average (T3)',
    function: 't3',
    type: 'overlap',
    time: 5,
    vfactor: 0,
  },
  {
    label: 'Triple Exponential Moving Average',
    function: 'tema',
    type: 'overlap',
    time: 30,
  },
  {
    label: 'Triangular Moving Average',
    function: 'trima',
    type: 'overlap',
    time: 30,
  },
  { label: 'Weighted Moving Average', function: 'wma', type: 'overlap', time: 30 },
]
export const statisticFunctions = [
  {
    label: 'Beta',
    function: 'beta',
    type: 'statistic',
    low: null,
    high: null,
    time: 5,
  },
  {
    label: "Pearson's Correlation Coefficient (r)",
    function: 'correl',
    type: 'statistic',
    low: null,
    high: null,
    time: 30,
  },
  { label: 'Linear Regression', function: 'linearreg', type: 'statistic', time: 14 },
  {
    label: 'Linear Regression Angle',
    function: 'linearreg_angle',
    type: 'statistic',
    time: 14,
  },
  {
    label: 'Linear Regression Intercept',
    function: 'linearreg_intercept',
    type: 'statistic',
    time: 14,
  },
  {
    label: 'Linear Regression Slope',
    function: 'linearreg_slope',
    type: 'statistic',
    time: 14,
  },
  {
    label: 'Standard Deviation',
    function: 'stddev',
    type: 'statistic',
    time: 5,
    nbdev: 1,
  },
  {
    label: 'Time Series Forecast',
    function: 'tsf',
    type: 'statistic',
    time: 14,
  },
  {
    label: 'Variance',
    function: 'var',
    type: 'statistic',
    time: 5,
    nbdev: 1,
  },
]
export const patternRecognition = [
  {
    label: 'Rising / Falling Three Methods',
    function: 'cdlrisefall3methods',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Upside / Downside Gap Three Methods',
    function: 'cdlxsidegap3methods',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Tasuki Gap',
    function: 'cdltasukigap',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Upside Gap Two Crows',
    function: 'cdlupsidegap2crows',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Two Crows',
    function: 'cdl2crows',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Three Black Crows',
    function: 'cdl3blackcrows',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Three Inside Up / Down',
    function: 'cdl3inside',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Three - Line Strike',
    function: 'cdl3linestrike',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Three Outside Up / Down',
    function: 'cdl3outside',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Three Stars In The South',
    function: 'cdl3starsinsouth',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Three Advancing White Soldiers',
    function: 'cdl3whitesoldiers',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Unique Three River',
    function: 'cdlunique3river',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Abandoned Baby',
    function: 'cdlabandonedbaby',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Concealing Baby Swallow',
    function: 'cdlconcealbabyswall',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Advance Block',
    function: 'cdladvanceblock',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Belt - hold',
    function: 'cdlbelthold',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Mat Hold',
    function: 'cdlmathold',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Breakaway',
    function: 'cdlbreakaway',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Marubozu',
    function: 'cdlmarubozu',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Closing Marubozu',
    function: 'cdlclosingmarubozu',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Counterattack',
    function: 'cdlcounterattack',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Dark Cloud Cover',
    function: 'cdldarkcloudcover',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Doji',
    function: 'cdldoji',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Dragonfly Doji',
    function: 'cdldragonflydoji',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Gravestone Doji',
    function: 'cdlgravestonedoji',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Long Legged Doji',
    function: 'cdllongleggeddoji',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Doji Star',
    function: 'cdldojistar',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Morning Doji Star',
    function: 'cdlmorningdojistar',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Evening Doji Star',
    function: 'cdleveningdojistar',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Morning Star',
    function: 'cdlmorningstar',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Evening Star',
    function: 'cdlgapsidesidewhite',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Shooting Star',
    function: 'cdlshootingstar',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Hammer',
    function: 'cdlhammer',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Inverted Hammer',
    function: 'cdlinvertedhammer',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Hanging Man',
    function: 'cdlhangingman',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Rickshaw Man',
    function: 'cdlrickshawman',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Harami Pattern',
    function: 'cdlharami',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Harami Cross Pattern',
    function: 'cdlharamicross',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Piercing Pattern',
    function: 'cdlpiercing',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Engulfing Pattern',
    function: 'cdlengulfing',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Hikkake Pattern',
    function: 'cdlhikkake',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Modified Hikkake Pattern',
    function: 'cdlhikkakemod',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Stalled Pattern',
    function: 'cdlstalledpattern',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Thrusting Pattern',
    function: 'cdlthrusting',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Tristar Pattern',
    function: 'cdltristar',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'In - Neck Pattern',
    function: 'cdlinneck',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'On - Neck Pattern',
    function: 'cdlonneck',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Homing Pigeon',
    function: 'cdlhomingpigeon',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Kicking',
    function: 'cdlkicking',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Kicking - bull / bear determined by the longer marubozu',
    function: 'cdlkickingbylength',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Ladder Bottom',
    function: 'cdlladderbottom',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Matching Low',
    function: 'cdlmatchinglow',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'High - Wave Candle',
    function: 'cdlhighwave',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Short Line Candle',
    function: 'cdlshortline',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Long Line Candle',
    function: 'cdllongline',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Separating Lines',
    function: 'cdlseparatinglines',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Spinning Top',
    function: 'cdlspinningtop',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Stick Sandwich',
    function: 'cdlsticksandwich',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
  {
    label: 'Takuri (Dragonfly Doji with very long lower shadow)',
    function: 'cdltakuri',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  },
]

export const financialFunctionsArrayGenerator = () => {
  /// /////////////////////////// ///
  /// ///// TIME FUNCTIONS ////// ///
  /// /////////////////////////// ///
  const timeFunctions = []
  const noTimeMomentum = [
    'bop',
    'heikin',
    'macd',
    'macdext',
    'stoch',
    'stochf',
    'ultosc',
    'normalized_slope',
  ]
  const noTimeOverlap = ['ht_trendline', 'mama', 'sar', 'sarext']
  timeFunctions.push(
    ...momentumIndicators
      .filter(({ function: f }) => noTimeMomentum.indexOf(f) === -1)
      .map(({ function: f }) => f)
  ) /// ////////////////////////////////////////////////////////////////////////// MOMENTUM INDICATORS
  timeFunctions.push(
    ...overlapStudies
      .filter(({ function: f }) => noTimeOverlap.indexOf(f) === -1)
      .map(({ function: f }) => f)
  ) /// ////////////////////////////////////////////////////////////////////////// OVERLAP STUDIES
  timeFunctions.push(...statisticFunctions.map(({ function: f }) => f)) /// ////// STATISTIC FUNCTIONS
  /// //////////////////////////////////////////////////////////////////////////// NO PATTERN RECOGNITION
  /// /////////////////////////// ///

  /// /////////////////////////// ///
  /// ///// OPEN FUNCTIONS ////// ///
  /// /////////////////////////// ///
  const openFunctions = []
  openFunctions.push('bop', 'heikin') /// ////////////////////////////////////////////////// MOMENTUM INDICATORS
  /// //////////////////////////////////////////////////////////////////////////// NO OVERLAP STUDIES
  /// //////////////////////////////////////////////////////////////////////////// NO STATISTIC FUNCTIONS
  openFunctions.push(...patternRecognition.map(({ function: f }) => f)) /// ////// PATTERN RECOGNITION
  /// /////////////////////////// ///

  /// /////////////////////////// ///
  /// //// LOW-HIGH FUNCTIONS /// ///
  /// /////////////////////////// ///
  const lowHighFunctions = ['ad', 'adosc', 'atr', 'natr']
  const noLowHighMomentum = [
    'cmo',
    'macd',
    'macdext',
    'rocp',
    'rsi',
    'trix',
    'stochrsi',
    'normalized_slope',
  ]
  lowHighFunctions.push(
    ...momentumIndicators
      .filter(({ function: f }) => noLowHighMomentum.indexOf(f) === -1)
      .map(({ function: f }) => f)
  ) /// ////////////////////////////////////////////////////////////////////////// MOMENTUM INDICATORS
  lowHighFunctions.push('midprice', 'sar', 'sarext') /// //////////////////////////////// OVERLAP STUDIES
  lowHighFunctions.push('beta', 'correl') /// //////////////////////////////////// STATISTIC FUNCTIONS
  lowHighFunctions.push(...patternRecognition.map(({ function: f }) => f)) /// /// PATTERN RECOGNITION
  /// /////////////////////////// ///

  /// /////////////////////////// ///
  /// ///// CLOSE FUNCTIONS ///// ///
  /// /////////////////////////// ///
  const closeFunctions = ['ad', 'adosc', 'obv', 'atr', 'natr']
  const noCloseMomentum = [
    'aroonosc',
    'cmo',
    'macd',
    'macdext',
    'minus_dm',
    'plus_dm',
    'rocp',
    'rsi',
    'trix',
    'stochrsi',
    'normalized_slope',
  ]
  closeFunctions.push(
    ...momentumIndicators
      .filter(({ function: f }) => noCloseMomentum.indexOf(f) === -1)
      .map(({ function: f }) => f)
  ) /// ////////////////////////////////////////////////////////////////////////// MOMENTUM INDICATORS
  /// //////////////////////////////////////////////////////////////////////////// NO OVERLAP STUDIES
  /// //////////////////////////////////////////////////////////////////////////// NO STATISTIC FUNCTIONS
  closeFunctions.push(...patternRecognition.map(({ function: f }) => f)) /// ///// PATTERN RECOGNITION
  /// /////////////////////////// ///

  const noSuffixFunctions = ['obv']
  noSuffixFunctions.push(...lowHighFunctions)

  /// /////////////////////////// ///
  /// //////// CONSTANTS //////// ///
  /// /////////////////////////// ///
  // MOMENTUM
  // slowk_matype = 0, slowd_matype = 0, fastd_matype = 0,
  // time1 = 7, time2 = 14, time3 = 28

  // OVERLAP
  // nbdevup = 2, nbdevdn = 2, matype = 0,
  // fastlimit = 0.5, slowlimit = 0.05,
  // startvalue = 0, offsetonreverse = 0,

  // STATISTIC
  // nbdev = 1
  /// /////////////////////////// ///

  //

  //

  //

  // OVERLAP
  // accelerationinitlong = [0,0.02], accelerationlong = [0,0.02],                                 sarext
  // accelerationinitshort = [0,0.02], accelerationshort = [0,0.02],                               sarext
  // accelerationmaxlong = [0, 0.2], accelerationmaxshort = [0,0.2],                               sarext

  return [
    timeFunctions,
    openFunctions,
    lowHighFunctions,
    closeFunctions,
    noSuffixFunctions,
  ]
}
