import React from 'react'
import PropTypes from 'prop-types'
import { useRefRect } from 'libs/utils/hooks'

import Conditional from 'components/Conditional'

import './style.scss'

const classes = {
  wrapper: 'CategoryYAxis-wrapper',
  label: 'Axis-label',
  line: 'Axis-line',
  subLine: 'Axis-subLine',
  text: 'Axis-text',
}

const CategoryYAxis = props => {
  const {
    categories,
    spacing,
    segments,
    label,
    width,
    height,
    fontSize = 14,
    labelSize = 14,
    fontMargin = 10,
    labelMargin = 15,
    tickLength = 8,
    render,
    mirror,
    normal,
    style,
  } = props
  const [rect, container] = useRefRect()

  const yMin = 0
  const ponder = 1000
  const yRange = categories.length * ponder
  const ratio = rect ? yRange / rect.height : 1
  const xRange = rect ? rect.width * ratio : yRange
  let xMin = 0
  const textSize = fontSize * ratio
  const labelTextSize = labelSize * ratio
  let textMargin = -fontMargin * ratio
  let labelTextMargin = -labelMargin * ratio
  let lineLength = -tickLength * ratio

  if (!mirror) {
    xMin = -xRange
    textMargin *= -1
    labelTextMargin *= -1
    lineLength *= -1
  }

  const categoryArray = categories.map((category, index) => {
    const points = []
    for (let i = 1; i < segments; i++)
      points.push((index + spacing / 2 + (i / segments) * (1 - spacing)) * ponder)
    return { title: category, points }
  })

  const viewBox = `${xMin} ${yMin} ${xRange} ${yRange}`
  return (
    <svg
      style={{
        ...style,
        width: width ? `${width}px` : '',
        height: height ? `${height}px` : '',
      }}
      ref={container}
      className={classes.wrapper}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      preserveAspectRatio="xMinYMid slice"
    >
      <Conditional dependencies={rect}>
        <Conditional dependencies={label}>
          <text
            className={classes.label}
            x={2 * xMin + xRange + labelTextMargin}
            y={yRange / 2}
            fontSize={labelTextSize}
            transform={`rotate(${mirror ? 90 : -90},${
              2 * xMin + xRange + labelTextMargin
            },${yRange / 2})`}
          >
            {label}
          </text>
        </Conditional>
        {categoryArray.map((category, i) => {
          return (
            <React.Fragment key={`fragment-${category.title}`}>
              <line
                className={classes.line}
                x1={-lineLength}
                y1={i * ponder}
                x2={0}
                y2={i * ponder}
              />
              {category.points.map(point => {
                return (
                  <line
                    key={`tick-${point}`}
                    className={classes.subLine}
                    x1={0}
                    y1={point}
                    x2={lineLength / 2}
                    y2={point}
                  />
                )
              })}
              <text
                className={classes.text}
                x={0 - textMargin}
                y={(i + 0.5) * ponder}
                fontSize={textSize}
                textAnchor={(normal && (mirror ? 'start' : 'end')) || 'middle'}
                transform={
                  !normal
                    ? `rotate(${mirror ? 90 : -90},${0 - textMargin},${
                        (i + 0.5) * ponder
                      })`
                    : ''
                }
              >
                {render ? render(category.title) : category.title}
              </text>
            </React.Fragment>
          )
        })}
        <Conditional dependencies={categoryArray.length}>
          <line
            className={classes.line}
            x1={-lineLength}
            y1={yRange}
            x2={0}
            y2={yRange}
          />
        </Conditional>
      </Conditional>
    </svg>
  )
}

CategoryYAxis.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  spacing: PropTypes.number,
  segments: PropTypes.number,
  label: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  labelSize: PropTypes.number,
  fontMargin: PropTypes.number,
  labelMargin: PropTypes.number,
  tickLength: PropTypes.number,
  render: PropTypes.func,
  mirror: PropTypes.bool,
  normal: PropTypes.bool,
  style: PropTypes.object,
}

export default CategoryYAxis
