/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import { BaseCard as Card } from 'components/CardLayout'
import Icon from 'components/Icon'

import './style.scss'

const classes = {
  wrapper: 'ScoringDetails-wrapper',
  title: 'ScoringDetails-title',
  content: 'ScoringDetails-content',
  datasources: 'ScoringDetails-datasources',
  schedule: 'ScoringDetails-schedule',
  setup: 'ScoringDetails-setup',
}

const ScoringDetails = ({
  scoring: {
    datasets = [],
    spec: { training_id },
  },
}) => {
  const { trainings } = useStore('selectTrainings')
  const training = trainings && trainings.find(t => t.id === training_id)

  return (
    <Card className={classes.wrapper}>
      <div className={classes.title}>
        <Icon type="info" />
        Scoring details
      </div>
      <div className={classes.content}>
        <Conditional dependencies={datasets.length >= 1}>
          <div className={classes.datasources}>
            <span>
              Datasets scored:
              <span>{datasets.length}</span>
            </span>
          </div>
        </Conditional>
        {/* <Conditional dependencies={!!'scheduled'}>
              <div className={classes.schedule}>
                <span>
                  Scheduled for:
                  <span>{null}</span>
                </span>
                <span>
                  Next scoring in:
                  <span>{null}</span>
                </span>
              </div>
            </Conditional> */}
        <div className={classes.datasources}>
          <span>
            Training:
            <span>{training ? training.name : 'unknown'}</span>
          </span>
        </div>
        <Conditional dependencies={datasets.length === 1}>
          <div className={classes.setup}>
            <span>
              {`Brain${datasets[0].scoring_spec?.brains?.length === 1 ? ':' : 's'}`}
              <Conditional
                dependencies={datasets[0].scoring_spec?.brains?.length === 1}
              >
                <span>{datasets[0].scoring_spec?.brains?.[0]?.name}</span>
              </Conditional>
            </span>
            <Conditional dependencies={datasets[0].scoring_spec?.brains?.length > 1}>
              {datasets[0].scoring_spec?.brains?.map(brain => {
                return <span key={brain.id}>{brain.name}</span>
              })}
            </Conditional>
          </div>
        </Conditional>
      </div>
    </Card>
  )
}

ScoringDetails.propTypes = {
  scoring: PropTypes.any,
}

export default ScoringDetails
