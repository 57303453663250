import React from 'react'
import PropTypes from 'prop-types'
import { useRefRect } from 'libs/utils/hooks'

import Conditional from 'components/Conditional'

import './style.scss'

const classes = {
  wrapper: 'YAxis-wrapper',
  label: 'Axis-label',
  line: 'Axis-line',
  text: 'Axis-text',
}

const YAxis = props => {
  const {
    points,
    label,
    width,
    height,
    fontSize = 12,
    labelSize = 14,
    fontSpacing = 12,
    fontMargin = 10,
    labelMargin = 15,
    tickLength = 8,
    render,
    mirror,
    style,
  } = props
  const [rect, container] = useRefRect()

  const yRange = 1000
  const yMin = -yRange
  const ratio = rect ? yRange / rect.height : 1
  const xRange = rect ? rect.width * ratio : yRange
  let xMin = -xRange
  const textSize = fontSize * ratio
  const labelTextSize = labelSize * ratio
  const textSpacing = fontSpacing * ratio
  let textMargin = -fontMargin * ratio
  let labelTextMargin = -labelMargin * ratio
  let lineLength = -tickLength * ratio

  if (mirror) {
    xMin = 0
    textMargin *= -1
    labelTextMargin *= -1
    lineLength *= -1
  }

  const sortedPoints = points.sort((a, b) => a.position - b.position)
  const { points: filteredPoints } = sortedPoints.reduce(
    (acc, cur, i) => {
      const distance = (cur.position - acc.lastShownPosition) * yRange
      let curValue = distance > textSpacing ? cur.value : null
      curValue = i === 0 ? cur.value : curValue
      const result = { ...acc }
      result.points.push({ ...cur, value: curValue })
      result.lastShownPosition =
        curValue !== null ? cur.position : acc.lastShownPosition
      return result
    },
    {
      points: [],
      lastShownPosition: 0,
    }
  )

  const viewBox = `${xMin} ${yMin} ${xRange} ${yRange}`

  return (
    <svg
      style={{
        ...style,
        width: width ? `${width}px` : '',
        height: height ? `${height}px` : '',
      }}
      ref={container}
      className={classes.wrapper}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      preserveAspectRatio="xMaxYMid slice"
    >
      <Conditional dependencies={rect}>
        <Conditional dependencies={label}>
          <text
            className={classes.label}
            x={2 * xMin + xRange - labelTextMargin}
            y={-yRange / 2}
            fontSize={labelTextSize}
            transform={`rotate(${mirror ? 90 : -90},${
              2 * xMin + xRange - labelTextMargin
            },${-yRange / 2})`}
          >
            {label}
          </text>
        </Conditional>
        {filteredPoints.map(point => {
          const value = Number(Number.parseFloat(point.value).toFixed(4))
          const correctedPosition =
            Math.min(
              Math.max(point.position * yRange, textSize / 2),
              yRange - textSize / 2
            ) || 0
          return (
            <React.Fragment key={`fragment-${point.position}`}>
              <line
                className={classes.line}
                x1={0}
                y1={-point.position * yRange || 0}
                x2={lineLength}
                y2={-point.position * yRange || 0}
              />
              <Conditional dependencies={value === 0 || value}>
                <text
                  className={classes.text}
                  x={0 + textMargin}
                  y={-correctedPosition}
                  fontSize={textSize}
                  textAnchor={`${mirror ? 'start' : 'end'}`}
                >
                  {render ? render(value) : value}
                </text>
              </Conditional>
            </React.Fragment>
          )
        })}
      </Conditional>
    </svg>
  )
}

YAxis.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({ position: PropTypes.number, value: PropTypes.number })
  ),
  label: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  labelSize: PropTypes.number,
  fontSpacing: PropTypes.number,
  fontMargin: PropTypes.number,
  labelMargin: PropTypes.number,
  tickLength: PropTypes.number,
  render: PropTypes.func,
  mirror: PropTypes.bool,
  style: PropTypes.object,
}

export default YAxis
