import React from 'react'
import PropTypes from 'prop-types'
import { useRefRect } from 'libs/utils/hooks'

import Conditional from 'components/Conditional'

import './style.scss'

const classes = {
  wrapper: 'CategoryXAxis-wrapper',
  label: 'Axis-label',
  line: 'Axis-line',
  subLine: 'Axis-subLine',
  text: 'Axis-text',
}

const CategoryXAxis = props => {
  const {
    categories,
    spacing,
    segments,
    label,
    width,
    height,
    fontSize = 14,
    labelSize = 14,
    fontMargin = 10,
    labelMargin = 15,
    tickLength = 8,
    render,
    mirror,
    normal,
    minWidth,
    maxWidth,
    onScroll,
    style,
  } = props
  const [rect, container] = useRefRect()

  const xMin = 0
  const ponder = 1000
  const xRange = categories.length * ponder
  const ratio = rect ? xRange / rect.width : 1
  const yRange = rect ? rect.height * ratio : xRange
  let yMin = -yRange
  const textSize = fontSize * ratio
  const labelTextSize = labelSize * ratio
  let textMargin = -fontMargin * ratio
  let labelTextMargin = -labelMargin * ratio
  let lineLength = -tickLength * ratio

  if (!mirror) {
    yMin = 0
    textMargin *= -1
    labelTextMargin *= -1
    lineLength *= -1
  }

  const categoryArray = categories.map((category, index) => {
    const points = []
    for (let i = 1; i < segments; i++)
      points.push((index + spacing / 2 + (i / segments) * (1 - spacing)) * ponder)
    return { title: category, points }
  })

  const viewBox = `${xMin} ${yMin} ${xRange} ${yRange}`

  return (
    <div
      className={classes.wrapper}
      style={{
        width: width ? `${width}px` : '',
        height: height ? `${height}px` : '',
        ...style,
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
      onScroll={event => onScroll(event.target.scrollLeft)}
    >
      <svg
        ref={container}
        style={{
          minWidth: minWidth ? `${minWidth}px` : '',
          maxWidth: maxWidth ? `${maxWidth}px` : '',
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        preserveAspectRatio="xMidYMin slice"
      >
        <Conditional dependencies={rect}>
          <Conditional dependencies={label}>
            <text
              className={classes.label}
              x={xRange / 2}
              y={2 * yMin + yRange - labelTextMargin}
              fontSize={labelTextSize}
            >
              {label}
            </text>
          </Conditional>
          {categoryArray.map((category, i) => {
            return (
              <React.Fragment key={`fragment-${category.title}`}>
                <line
                  className={classes.line}
                  x1={i * ponder}
                  y1={0}
                  x2={i * ponder}
                  y2={lineLength}
                />
                {category.points.map(point => {
                  return (
                    <line
                      key={`tick-${point}`}
                      className={classes.subLine}
                      x1={point}
                      y1={0}
                      x2={point}
                      y2={lineLength / 2}
                    />
                  )
                })}
                <text
                  className={classes.text}
                  x={(i + 0.5) * ponder}
                  y={0 + textMargin}
                  fontSize={textSize}
                  textAnchor={
                    (normal && (normal === 'left' ? 'end' : 'start')) || 'middle'
                  }
                  // transform={`rotate(${!mirror ? 60 : -60},${i + 0.5},${
                  //   0 + textMargin
                  // })`}
                  transform={
                    normal
                      ? `rotate(${normal === 'left' ? -90 : 90},${
                          (i + 0.5) * ponder
                        },${0 + textMargin})`
                      : ''
                  }
                >
                  {render ? render(category.title) : category.title}
                </text>
              </React.Fragment>
            )
          })}
          <Conditional dependencies={categoryArray.length}>
            <line
              className={classes.line}
              x1={xRange}
              y1={0}
              x2={xRange}
              y2={lineLength}
            />
          </Conditional>
        </Conditional>
      </svg>
    </div>
  )
}

CategoryXAxis.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  spacing: PropTypes.number,
  segments: PropTypes.number,
  label: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  labelSize: PropTypes.number,
  fontMargin: PropTypes.number,
  labelMargin: PropTypes.number,
  tickLength: PropTypes.number,
  render: PropTypes.func,
  mirror: PropTypes.bool,
  normal: PropTypes.oneOf(['left', 'right']),
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  onScroll: PropTypes.func,
  style: PropTypes.object,
}

export default CategoryXAxis
