import React, { forwardRef, useRef, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useStore } from 'core'

import Modal from 'components/Modal'
import { Form, FormItem, FormConditional, FormFor } from 'components/Form'
import { TextField, MenuItem } from '@material-ui/core'

import './style.scss'

const classes = {
  wrapper: 'EditDatasetModal-wrapper',
  formDiv: 'EditDatasetModal-formDiv',
  datasetTypeDiv: 'EditDatasetModal-datasetTypeDiv',
  featureDiv: 'EditDatasetModal-featureDiv',
}

const FEATURE_TYPES = {
  NUMERIC: 'numeric',
  CATEGORY: 'category',
  TEXT: 'text',
  TIME: 'time',
}
const DATASET_TYPES = {
  BASEDATA: 'basedata',
  TIMESERIES: 'timeseries',
  'STACKED-TIMESERIES': 'stacked-timeseries',
}

const EditDatasetModal = forwardRef((props, ref) => {
  const formRef = useRef()
  const [stackedByError, setStackedByError] = useState(false)
  // const [orderedByError, setOrderedByError] = useState(false)
  const { editDataset } = useStore('editDataset')
  const { selectedDataset = {} } = props

  const categoryFeatures = selectedDataset?.spec?.features?.filter(
    feature => feature.type === 'category'
  )
  const numericFeatures = selectedDataset?.spec?.features?.filter(
    feature => feature.type === 'numeric'
  )
  const timeFeatures = selectedDataset?.spec?.features?.filter(
    feature => feature.type === 'time'
  )

  const hasCF = !!categoryFeatures?.length

  const hasNorT = !!numericFeatures?.length || !!timeFeatures?.length

  const stackedByDropdownOptions = hasCF ? (
    categoryFeatures.map(feature => (
      <MenuItem key={feature.id} value={feature.id}>
        {feature.name}
      </MenuItem>
    ))
  ) : (
    <MenuItem key="" value="error">
      No features available
    </MenuItem>
  )
  const orderedByDropdownOptions =
    (hasNorT &&
      numericFeatures.concat(timeFeatures).map(feature => (
        <MenuItem key={feature.id} value={feature.id}>
          {feature.name}
        </MenuItem>
      ))) ||
    []

  orderedByDropdownOptions.push(
    <MenuItem key="intrinsic" value="intrinsic">
      Intrinsic Order
    </MenuItem>
  )

  return (
    <Modal
      className={classes.wrapper}
      ref={ref}
      title={selectedDataset?.name}
      okText="Save"
      onOk={() => {
        let formData = formRef.current.get()
        const stackedBy = formData.spec.stacked_by
        const orderedBy = formData.spec.ordered_by
        const hasStackedByValue = !!(stackedBy || stackedBy === 0)
        // const hasOrderedByValue = !!(orderedBy || orderedBy === 0)
        const type = formData?.spec?.type
        // if (
        //   (type === 'stacked-timeseries' || type === 'timeseries') &&
        //   !hasOrderedByValue
        // ) {
        //   setOrderedByError(true)
        // } else
        if (type === 'stacked-timeseries' && !hasStackedByValue) {
          // setOrderedByError(false)
          setStackedByError(true)
        } else {
          ref.current.close()
          setStackedByError(false)
          // setOrderedByError(false)
          if (orderedBy === 'intrinsic')
            formData = {
              ...formData,
              spec: {
                ...Object.keys(formData.spec).reduce((acc, cur) => {
                  if (cur !== 'ordered_by')
                    return { ...acc, [cur]: formData.spec[cur] }
                  return acc
                }, {}),
              },
            }
          editDataset(formData)
        }
      }}
      onCancel={() => {
        setStackedByError(false)
        // setOrderedByError(false)
        ref.current.close()
      }}
      onClose={() => {
        setStackedByError(false)
        // setOrderedByError(false)
        ref.current.close()
      }}
      footer
    >
      <div className={classes.formDiv}>
        <Form ref={formRef} preload={selectedDataset}>
          <FormItem
            dataKey="name"
            defaultValue=""
            margin="dense"
            label="Name"
            fullWidth
            inputProps={{ autoFocus: true }}
            component={TextField}
          />
          <FormItem
            dataKey="description"
            defaultValue=""
            margin="dense"
            label="Description"
            fullWidth
            multiline
            component={TextField}
          />
          <div className={classes.datasetTypeDiv}>
            <FormItem
              dataKey="spec.type"
              defaultValue=""
              margin="dense"
              label="Dataset type"
              fullWidth
              select
              component={TextField}
            >
              {Object.values(DATASET_TYPES).map(datasetType => (
                <MenuItem key={datasetType} value={datasetType}>
                  {datasetType}
                </MenuItem>
              ))}
            </FormItem>
            <FormConditional
              dataKey="spec.type"
              value={value =>
                value === 'stacked-timeseries' || value === 'timeseries'
              }
            >
              <FormItem
                dataKey="spec.ordered_by"
                defaultValue={
                  ((selectedDataset?.spec?.type === 'stacked-timeseries' ||
                    selectedDataset?.spec?.type === 'timeseries') &&
                    selectedDataset?.spec.ordered_by) ||
                  'intrinsic'
                }
                margin="dense"
                label="Ordered by"
                // disabled={!hasNorT}
                fullWidth
                select
                // error={orderedByError}
                // helperText={orderedByError ? 'Field is required.' : ''}
                component={TextField}
              >
                {orderedByDropdownOptions}
              </FormItem>
            </FormConditional>
            <FormConditional dataKey="spec.type" value="stacked-timeseries">
              <FormItem
                dataKey="spec.stacked_by"
                defaultValue={
                  selectedDataset?.spec?.type === 'stacked-timeseries'
                    ? selectedDataset?.spec.stacked_by
                    : ''
                }
                margin="dense"
                label="Stacked by"
                disabled={!hasCF}
                fullWidth
                select
                error={stackedByError}
                helperText={stackedByError ? 'Field is required.' : ''}
                component={TextField}
              >
                {stackedByDropdownOptions}
              </FormItem>
            </FormConditional>
          </div>
          <FormFor dataKey="spec.features">
            <div className={classes.featureDiv}>
              <FormItem
                dataKey="name"
                defaultValue=""
                margin="dense"
                label="Rename"
                fullWidth
                component={TextField}
              />
              <FormItem
                dataKey="type"
                defaultValue=""
                margin="dense"
                label="Type"
                fullWidth
                select
                component={TextField}
              >
                {Object.values(FEATURE_TYPES).map(featureType => (
                  <MenuItem key={featureType} value={featureType}>
                    {featureType}
                  </MenuItem>
                ))}
              </FormItem>
            </div>
          </FormFor>
        </Form>
      </div>
    </Modal>
  )
})

EditDatasetModal.propTypes = {
  selectedDataset: PropTypes.object,
}

export default EditDatasetModal
