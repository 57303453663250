import React, { forwardRef, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useStore } from 'core'
import { formatDate } from 'libs/utils/helpers'

import Modal from 'components/Modal'
import { Form, FormItem } from 'components/Form'
import { TextField } from '@material-ui/core'

import './style.scss'

const classes = {
  wrapper: 'TransformationModal-wrapper',
}

const NewTransformationModal = forwardRef((_, ref) => {
  const formRef = useRef()
  const [error, setError] = useState(false)
  const { saveTransformation, setActivePipeline } = useStore(
    'saveTransformation',
    'setActivePipeline'
  )

  return (
    <Modal
      ref={ref}
      title="New transformation"
      okText="Ok"
      onOk={() => {
        const data = formRef.current.get()
        setError(!data.name)
        if (data.name) {
          ref.current.close()
          setActivePipeline(
            uuid(),
            [],
            [],
            (__, spec, datasets) => {
              saveTransformation({
                spec,
                datasets,
                name: data.name,
                description: data.description,
              })
            },
            null,
            true,
            data.name
          )
        }
      }}
      onCancel={() => ref.current.close()}
      onClose={() => ref.current.close()}
      footer
    >
      <div className={classes.wrapper}>
        <Form ref={formRef}>
          <FormItem
            dataKey="name"
            defaultValue={`New Transformation ${formatDate(Date.now())}`}
            margin="dense"
            label="Name"
            fullWidth
            inputProps={{ autoFocus: true }}
            error={error}
            helperText={error ? 'Field is required' : ''}
            component={TextField}
          />
          <FormItem
            dataKey="description"
            defaultValue=""
            margin="dense"
            label="Description"
            fullWidth
            multiline
            component={TextField}
          />
        </Form>
      </div>
    </Modal>
  )
})

export default NewTransformationModal
