import React from 'react'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import { BaseCard as Card } from 'components/CardLayout'
import Icon from 'components/Icon'
// import Uploads from 'pages/Uploads'
import { Button } from '@material-ui/core'
// import UnderConstruction from './under_construction.svg'

import 'style.scss'

const classes = {
  wrapper: 'DashboardPage-wrapper',
  // constructionWrapper: 'constructionWrapper',
  // svg: 'imageFilter',
  callToAction: 'DashboardPage-callToAction',
}
const DashboardPage = props => {
  const { trainings, initializeTrainingWizard, initializeScoringWizard } = useStore(
    'selectTrainings',
    'initializeTrainingWizard',
    'initializeScoringWizard'
  )
  return (
    <div className={classes.wrapper}>
      <Card>
        <div>
          <Button
            className={classes.callToAction}
            color="primary"
            variant="contained"
            onClick={() =>
              initializeTrainingWizard(null, null, null, null, null, true, true, 0)
            }
          >
            <Icon type="train" />
            Start a training
          </Button>
          <pre>
            {
              // eslint-disable-next-line max-len
              'If a training is started the user is lead through a 3-step wizard.\n\nFirst step: Select an existing data source, upload new static data source, or define a new dynamic data source for the training. Data source types (e.g.,Timeseries) can be changed in the Resources Tab.\n\nSecond step: Select an existing or create a new preprocessing pipeline. If a new data source is added to a pipeline, the system will suggest a modifiable default pipeline. Other preprocessing steps can be added to clean, impute and enrich one or multiple data sources into a dataset.\n\nThird step: Select which parts of the dataset are user for training, validation, and testing. Specify the one or two prediction labels as well as the optimization metric.'
            }
          </pre>
        </div>
      </Card>
      <Card>
        <div>
          <Button
            className={classes.callToAction}
            color="primary"
            variant="contained"
            disabled={!trainings?.length}
            onClick={() =>
              initializeScoringWizard(
                null,
                null,
                null,
                null,
                null,
                false,
                true,
                true,
                0
              )
            }
          >
            <Icon type="score" />
            Run a scoring
          </Button>
          <Conditional dependencies={!trainings?.length}>
            <span>There are no trainings available.</span>
          </Conditional>
          <pre>
            {
              // eslint-disable-next-line max-len
              'If a scoring is started the user is lead through a 3-step wizard.\n\nFirst step: Select a running or finished training or define a new one.\n\nSecond step: Select one or multiple models to score. The list can be sorted to find the preferred prediction metrics in the training, validation, or testing.\n\nThird step: Select which data sources should be used for the scoring. The data sources will be run through the corresponding pipeline before the resulting dataset it fed into the model.\n\nNote: After a scoring the build-in explainer will run, to explain the overall feature importance and the explicit contributions for a random subset of predictions.'
            }
          </pre>
        </div>
      </Card>
    </div>
  )
}

export default DashboardPage
