import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import { generateColors } from 'libs/utils/color'

import Conditional from 'components/Conditional'
import { UserControlWrapper } from './UCW'
import ConfusionMatrixGraph from './ConfusionMatrixGraph'
import ConfusionMatrixSimpleGraph from './ConfusionMatrixSimpleGraph'
import { Provider } from './ConfusionMatrixChartContext'

import './style.scss'

const classes = {
  wrapper: 'ConfusionMatrixChart-wrapper',
  matrixStrip: 'ConfusionMatrixChart-matrixStrip',
  matrixDiv: 'ConfusionMatrixChart-matrixDiv',
  noDataDiv: 'ConfusionMatrixChart-noDataDiv',
}

const stripSize = 130

const ConfusionMatrixChart = props => {
  const [selectedBrain, setSelectedBrain] = useState(null)
  const { brains: storeBrains } = useStore('selectBrains')
  const {
    controlable,
    legend,
    trainings: propTrainings,
    brains: propBrains,
    split = 'train',
    combineTrainings,
    stripPosition = 'right',
    mainColor,
  } = props

  let direction = ''
  switch (stripPosition) {
    case 'left':
      direction = 'row-reverse'
      break
    case 'right':
      direction = 'row'
      break
    case 'top':
      direction = 'column-reverse'
      break
    case 'bottom':
      direction = 'column'
      break
    default:
      direction = 'row'
  }

  const {
    position: legendPosition = 'right',
    width: legendWidth = 32,
    spacing: legendSpacing = 10,
  } = (legend && legend !== true ? legend : {}) || {}

  const storeData = { brains: storeBrains || [] }

  const [trainings, brains] = useMemo(() => {
    const tempTrainings =
      (propTrainings &&
        (!Array.isArray(propTrainings) ? [propTrainings] : propTrainings)) ||
      []
    const tempBrains = (propBrains && [...propBrains]) || []
    if (tempTrainings)
      tempTrainings.forEach(training => {
        const tempTraining = tempBrains?.find(e => e.id === training.id)
        if (!tempTraining) tempBrains.push({ id: training.id, brains: [] })
      })
    return [tempTrainings, tempBrains]
  }, [propTrainings, propBrains])

  const data = useMemo(() => {
    let returnData = []
    if (!controlable && trainings.length && brains.length) {
      const resultData = trainings.map(({ name, id }) => {
        const { confusionMatrix: trainingBrains = {} } =
          storeData.brains.find(training => training.id === id)?.data || {}
        const tempBrains =
          trainingBrains[split]?.filter(
            brain => brains.find(e => e.id === id).brains.indexOf(brain.id) !== -1
          ) || []
        // const { confusionMatrix } =
        //   storeData.brains.find(training => training.id === id)?.data || {}
        // const { [propSplit]: tempBrains } = confusionMatrix || {}
        const destructuredBrains = []
        if (tempBrains) {
          tempBrains.forEach(brain => {
            destructuredBrains.push(
              ...Object.keys(brain)
                .filter(key => key !== 'name' && key !== 'id')
                .map(key => {
                  const keys = Object.keys(brain[key])
                  const table = keys.map(outer => {
                    return keys.map(inner => brain[key][outer][inner] || 0)
                  })
                  return {
                    name: `${brain.name} - ${key}`,
                    table,
                    labels: keys,
                    id: brain.id,
                    objective: key,
                  }
                })
            )
          })
        }
        return { title: name, brains: destructuredBrains, id }
      })
      returnData = resultData
      if (combineTrainings && trainings.length > 1) {
        const combinedResult = resultData.reduce(
          (acc, cur) => {
            const tempCombined = { ...acc }
            tempCombined.brains = [...tempCombined.brains, ...cur.brains]
            return tempCombined
          },
          {
            title: 'combined',
            brains: [],
          }
        )
        returnData = combinedResult
      }
    }
    const colorArray =
      (returnData.length &&
        mainColor &&
        generateColors(
          mainColor,
          returnData.length,
          0.2 + returnData.length / (returnData.length + 1) / 10
        )) ||
      []
    return returnData
      .map((datum, i) => {
        return { ...datum, color: colorArray[i] }
      })
      .map(datum => ({
        ...datum,
        brains: datum.brains.filter(({ table }) => table.length),
      }))
  }, [
    controlable,
    storeData.brains,
    trainings,
    brains,
    split,
    combineTrainings,
    mainColor,
  ])

  const selected =
    selectedBrain ||
    (data.length &&
      data[0].brains.length && {
        trainingId: data[0].id,
        brainId: data[0].brains[0].id,
        table: data[0].brains[0].table,
        labels: data[0].brains[0].labels,
        objective: data[0].brains[0].objective,
      })

  const stripStyle = {
    flexDirection:
      stripPosition === 'top' || stripPosition === 'bottom' ? 'row' : 'column',
    width:
      stripPosition === 'top' || stripPosition === 'bottom'
        ? '100%'
        : `${stripSize}px`,
    height:
      stripPosition !== 'top' && stripPosition !== 'bottom'
        ? '100%'
        : `${stripSize}px`,
    overflowX: stripPosition === 'top' || stripPosition === 'bottom' ? 'auto' : '',
    overflowY: stripPosition !== 'top' && stripPosition !== 'bottom' ? 'auto' : '',
  }

  const isThereDataToDisplay = data.reduce((acc, cur) => {
    if (!acc) return !!cur.brains.length
    return acc
  }, false)

  useEffect(() => {
    if (
      selectedBrain &&
      !brains
        .find(training => training.id === selectedBrain.trainingId)
        ?.brains?.find(brain => brain === selectedBrain.brainId)
    )
      setSelectedBrain(null)
  }, [selectedBrain, brains])

  return (
    <div
      className={classes.wrapper}
      style={{
        flexDirection: `${direction}`,
      }}
    >
      {/* <Conditional dependencies={controlable}>
        <UserControlWrapper controlable={controlable} mainColor={mainColor} />
      </Conditional> */}
      <Conditional dependencies={isThereDataToDisplay}>
        <Conditional dependencies={!controlable}>
          <Provider
            value={{
              data: selected && selected.table,
              labels: selected && selected.labels,
            }}
          >
            <Conditional dependencies={!!selected}>
              <ConfusionMatrixGraph
                legend={
                  legend && {
                    position: legendPosition,
                    width: legendWidth,
                    spacing: legendSpacing,
                  }
                }
                style={{ width: `calc(100% - ${stripSize}px)` }}
                mainColor={mainColor}
              />
            </Conditional>
          </Provider>
          <Conditional
            dependencies={
              data.length
              //  > 1 || data[0].brains > 1
            }
          >
            {data.map(datum => (
              <div
                key={datum.id}
                className={classes.matrixStrip}
                style={{ ...stripStyle }}
              >
                {datum.brains.map((brain, i) => (
                  <div
                    key={`${brain.id}-${brain.objective}`}
                    className={
                      classes.matrixDiv +
                      (selected.trainingId === datum.id &&
                      selected.brainId === brain.id &&
                      selected.objective === brain.objective
                        ? ' selected'
                        : '')
                    }
                    role="button"
                    tabIndex={0}
                    onClick={event => {
                      event.stopPropagation()
                      setSelectedBrain({
                        trainingId: datum.id,
                        brainId: brain.id,
                        table: brain.table,
                        labels: brain.labels,
                        objective: brain.objective,
                      })
                    }}
                    onKeyDown={event => {
                      if (event.keyCode === 13) {
                        event.stopPropagation()
                        setSelectedBrain({
                          trainingId: datum.id,
                          brainId: brain.id,
                          table: brain.table,
                          labels: brain.labels,
                          objective: brain.objective,
                        })
                      }
                    }}
                  >
                    <Provider value={{ data: brain.table }}>
                      <ConfusionMatrixSimpleGraph
                        mainColor={mainColor}
                        title={brain.name}
                      />
                    </Provider>
                  </div>
                ))}
              </div>
            ))}
          </Conditional>
        </Conditional>
      </Conditional>
      <Conditional dependencies={!isThereDataToDisplay}>
        <div className={classes.noDataDiv}>
          <div>Confusion Matrix</div>
          <div>NO DATA</div>
        </div>
      </Conditional>
    </div>
  )
}

ConfusionMatrixChart.propTypes = {
  controlable: PropTypes.shape({
    trainings: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    split: PropTypes.bool,
    datasets: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
  }),
  legend: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
      spacing: PropTypes.number,
    }),
    PropTypes.bool,
  ]),
  trainings: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
    ),
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  ]),
  brains: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      brains: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  split: PropTypes.oneOf(['combined', 'test', 'train', 'validation']),
  combineTrainings: PropTypes.bool,
  stripPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  mainColor: PropTypes.string,
}

export default ConfusionMatrixChart
