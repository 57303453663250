/* eslint-disable camelcase */
import { createBundle } from 'core/bundler'

import {
  USER_LOGGED_OUT,
  LOGIN_REQUEST_STARTED,
  LOGIN_REQUEST_SUCCEEDED,
  LOGIN_REQUEST_FAILED,
  ACCOUNT_VERIFICATION_STARTED,
  ACCOUNT_VERIFICATION_SUCCEEDED,
  ACCOUNT_VERIFICATION_FAILED,
  PASSWORD_VERIFICATION_REQUESTED,
  PASSWORD_VERIFICATION_CANCELED,
  PASSWORD_VERIFICATION_STARTED,
  PASSWORD_VERIFICATION_SUCCEEDED,
  PASSWORD_VERIFICATION_FAILED,
} from 'core/actiontypes'

const initialState = {
  token: '',
  user: null,
  loading: false,
  loginError: null,
  lastError: null,
  verificationInProgress: false,
  successfullyVerified: false,
  verificationFailed: false,
  passwordVerificationRequested: false,
  passwordVerificationInProgress: false,
  passwordVerificationError: null,
}

// const confirmAccount =
//   route =>
//   (dispatch, { api }) => {
//     dispatch({ type: ACCOUNT_VERIFICATION_STARTED })
//     api(`/auth${route}`)
//       .then(data => {
//         dispatch({ type: ACCOUNT_VERIFICATION_SUCCEEDED })
//       })
//       .catch(error => {
//         dispatch({ type: ACCOUNT_VERIFICATION_FAILED })
//       })
//   }

const selectPasswordVerificationRequestInProgress = root =>
  root.auth.passwordVerificationRequested
const selectPasswordVerificationInProgress = root =>
  root.auth.passwordVerificationInProgress
const selectPasswordVerificationError = root => root.auth.passwordVerificationError

export default config =>
  createBundle({
    name: 'auth',
    reducer: (state = initialState, { type: action, payload }) => {
      if (action === USER_LOGGED_OUT) {
        return { ...state, user: null, token: '' }
      }
      if (action === LOGIN_REQUEST_STARTED) {
        return { ...state, loading: true, loginError: null }
      }
      if (action === LOGIN_REQUEST_SUCCEEDED) {
        return {
          ...state,
          loading: false,
          token: payload.token,
          user: payload.user,
        }
      }
      if (action === LOGIN_REQUEST_FAILED) {
        return {
          ...state,
          token: '',
          loading: false,
          loginError: payload.error,
          lastError: payload.time,
        }
      }

      // if (action === REGISTRATION_REQUEST_STARTED) {
      //   return {
      //     ...state,
      //     registerSucceeded: false,
      //     registerLoading: true,
      //     registerError: null,
      //   }
      // }

      // if (action === REGISTRATION_REQUEST_SUCCEEDED) {
      //   return {
      //     ...state,
      //     registerSucceeded: true,
      //     registerLoading: false,
      //   }
      // }
      // if (action === REGISTRATION_REQUEST_FAILED) {
      //   return {
      //     ...state,
      //     registerError: payload,
      //     registerLoading: false,
      //   }
      // }
      // if (action === ACCOUNT_NOT_CONFIRMED) {
      //   return {
      //     ...state,
      //     token: '',
      //     loading: false,
      //   }
      // }

      if (action === PASSWORD_VERIFICATION_REQUESTED) {
        return {
          ...state,
          passwordVerificationRequested: true,
        }
      }
      if (action === PASSWORD_VERIFICATION_CANCELED) {
        return {
          ...state,
          passwordVerificationRequested: false,
          passwordVerificationInProgress: false,
          passwordVerificationError: false,
        }
      }
      if (action === PASSWORD_VERIFICATION_STARTED) {
        return {
          ...state,
          passwordVerificationInProgress: true,
          passwordVerificationError: false,
        }
      }

      if (action === PASSWORD_VERIFICATION_SUCCEEDED) {
        return {
          ...state,
          passwordVerificationRequested: false,
          passwordVerificationInProgress: false,
          passwordVerificationError: false,
        }
      }

      if (action === PASSWORD_VERIFICATION_FAILED) {
        return {
          ...state,
          passwordVerificationInProgress: false,
          passwordVerificationError: payload,
        }
      }
      return state
    },
    selectors: {
      selectToken: state => state.auth.token,
      selectUser: state => state.auth.user,
      selectPasswordVerificationRequestInProgress,
      selectPasswordVerificationInProgress,
      selectPasswordVerificationError,
    },
    actions: {
      logout: () => ({ type: USER_LOGGED_OUT }),
      login:
        payload =>
        (dispatch, { api }) => {
          dispatch({ type: LOGIN_REQUEST_STARTED })
          api('/auth/login', {
            method: 'POST',
            body: payload,
            credentials: 'omit',
          })
            .then(data => {
              if (data.token && data.id) {
                const { token, token_valid_til, ...user } = data
                dispatch({
                  type: LOGIN_REQUEST_SUCCEEDED,
                  payload: { user, token: `${user.id}:${token}` },
                })
              } else {
                //  TODO: implement this properly
                // dispatch({ type: ACCOUNT_NOT_CONFIRMED })
              }
            })
            .catch(error => {
              dispatch({
                type: LOGIN_REQUEST_FAILED,
                payload: { error, time: Date.now() },
              })
            })
          return payload
        },
      requestPasswordVerification: () => ({ type: PASSWORD_VERIFICATION_REQUESTED }),
      cancelPasswordVerification: () => ({ type: PASSWORD_VERIFICATION_CANCELED }),
      updateUser:
        (current, firstname, lastname, email, password) =>
        (dispatch, { api }) => {
          const updatePassword = !!password.length
          const updateEmail = !!email.length
          const body = {
            current_password: current,
            first_name: firstname,
            last_name: lastname,
          }
          if (updateEmail) body.email = email
          if (updatePassword) body.new_password = password
          dispatch({ type: PASSWORD_VERIFICATION_STARTED })
          api('/profile', {
            method: 'PATCH',
            body,
          })
            .then(e => {
              dispatch({ type: PASSWORD_VERIFICATION_SUCCEEDED })
            })
            .catch(e => {
              dispatch({ type: PASSWORD_VERIFICATION_FAILED, payload: e })
            })
        },
    },
    init: null,
    args: null,
    persist: [LOGIN_REQUEST_SUCCEEDED, USER_LOGGED_OUT, LOGIN_REQUEST_STARTED],
    middleware: null,
  })
