import React, { useMemo, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import { BaseCard as Card } from 'components/CardLayout'
import DatasourceInspector from 'components/DatasourceInspector'
import { Button } from '@material-ui/core'
import { DatasetTableEntry, DatasetTableHeader } from './components'

import './style.scss'

const classes = {
  wrapper: 'DatasourcesPage-wrapper',
  title: 'DatasourcesPage-title',
  subTitle: 'DatasourcesPage-subTitle',
  horizontalScrollDiv: 'DatasourcesPage-horizontalScrollDiv',
  scrollDiv: 'DatasourcesPage-scrollDiv',
}

const getDatasets = (datasets, sort) =>
  datasets
    ?.map(datasource => {
      const {
        name,
        id,
        updated_at: date = 'N/A',
        status = 'unknown',
        spec = {},
      } = datasource
      const { type = 'unknown' } = spec
      return { name, date, type, id, status, original: datasource }
    })
    .sort((a, b) => {
      const aVal = a[sort.column]
      const bVal = b[sort.column]
      let result = 0
      if (typeof aVal === 'string' || typeof bVal === 'string') {
        result = `${aVal}`.localeCompare(`${bVal}`, 'en', { sensitivity: 'base' })
      } else result = aVal - bVal
      return result * (-1) ** (sort.order + 1)
    }) || []

const DatasourcesPage = () => {
  const inputRef = useRef()
  const [sort, setSort] = useState({ column: 'name', order: 1 })
  const {
    datasets: datasources,
    selectedDataset: selectedDatasetId,
    addUploadFile,
    fileStateInspectorDialogToggle,
    configureUploadEntity,
  } = useStore(
    'selectDatasets',
    'selectSelectedDataset',
    'addUploadFile',
    'fileStateInspectorDialogToggle',
    'configureUploadEntity'
  )

  const {
    name: resourceName = '',
    id: resourceID = null,
    type: resourceType = 'UPLOADED_DATASET',
  } = { ...(datasources?.find(({ id }) => id === selectedDatasetId) || {}) }

  const openDialog = () => fileStateInspectorDialogToggle(true)

  const statics = useMemo(() => {
    const staticDS = datasources?.filter(ds => ds.type === 'UPLOADED_DATASET') || []
    const tempS = getDatasets(staticDS, sort)
    return tempS
  }, [datasources, sort])

  return (
    <>
      <div className={classes.wrapper}>
        <Card>
          <div className={classes.title}>Static datasources</div>
          <span className={classes.subTitle}>
            Static data sources allow to specify training and scoring data from local
            file systems, that cannot update dynamically.
          </span>
          <div className={classes.horizontalScrollDiv}>
            <DatasetTableHeader
              sort={{
                column: sort.column,
                order: sort.order,
                callback: (column, order) => {
                  if (order) {
                    const tempSort = { column, order }
                    setSort(tempSort)
                  } else setSort({ column: null, order })
                },
              }}
            />
            <div className={classes.scrollDiv}>
              {statics.map(ds => (
                <DatasetTableEntry
                  key={ds.id}
                  id={ds.id}
                  name={ds.name}
                  type={ds.type}
                  status={ds.status}
                  date={ds.date}
                  original={ds.original}
                  selected={ds.id === selectedDatasetId}
                />
              ))}
            </div>
          </div>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              if (inputRef.current) inputRef.current.click()
            }}
          >
            <input
              ref={inputRef}
              type="file"
              name="file"
              files={[]}
              accept=".csv"
              multiple
              style={{ display: 'none' }}
              onChange={event => {
                const { target } = event
                const { files } = target
                if (files.length) {
                  for (let i = 0; i < files.length; i++) {
                    const file = files.item(i)
                    const id = uuid()
                    addUploadFile({ id, file })
                    if (files.length === 1) configureUploadEntity(id)
                  }
                  target.value = ''
                  openDialog()
                }
              }}
            />
            Upload a static dataset
          </Button>

          {/* <CSVUploader /> */}
        </Card>
      </div>
      <Conditional dependencies={resourceID}>
        {resourceID && (
          <DatasourceInspector
            title={resourceName}
            resource={resourceID}
            resourceType={resourceType}
          />
        )}
      </Conditional>
    </>
  )
}

export default DatasourcesPage
