import { createSelector } from 'create-selector'
import { createBundle } from 'core/bundler'
import { parseBrains } from 'libs/utils/helpers'
import {
  BRAINS_FETCH_REQUESTED,
  BRAINS_FETCH_STARTED,
  BRAINS_FETCH_SUCCEEDED,
  BRAINS_FETCH_FAILED,
  BRAINS_CLEARED,
  BRAINS_OUTDATED,
  BRAINS_EXPIRED,
  ACTIVE_TRAINING_SET,
  USER_LOGGED_OUT,
} from 'core/actiontypes'

const name = 'brains' // e.g notification, trainings, users
const uCaseName = name.charAt(0).toUpperCase() + name.slice(1)
const defaultConfig = {
  stale: 300000, // five minutes
  retry: 60000, // fifteen seconds,
  maxRetryCount: 3,
  expireAfter: Infinity,
  persist: false,
  // checkIfOnline: true,
}

const initialState = []

const initialRequestState = {
  id: null,
  data: null,
  errorTimes: [],
  error: null,
  lastSuccess: null,
  isOutdated: false,
  isLoading: false,
  isExpired: false,
  failedPermanently: false,
}

const SELECTORS = {
  DATA: 'selectBrains',
  IS_STALE: 'selectBrainsIsStale',
  IS_EXPIRED: 'selectBrainsIsExpired',
  IS_LOADING: 'selectBrainsRequestIsLoading',
  IS_WAITING: 'selectBrainsIsWaitingToRetry',
  LAST_ERROR: 'selectBrainsRequestLastError',
  LAST_SUCCESS: 'selectBrainsRequestLastSuccess',
  FAILED_PERMANENTLY: 'selectBrainsRequestFailedPermanently',
}

export default config => {
  // eslint-disable-next-line no-param-reassign
  config = { ...defaultConfig, ...config }
  const markDataAsOutdated = id => ({ type: BRAINS_OUTDATED, payload: id })
  const clearData = id => ({ type: BRAINS_CLEARED, payload: id })
  const expireData = id => ({ type: BRAINS_EXPIRED, payload: id })
  const requestData = id => ({ type: BRAINS_FETCH_REQUESTED, payload: id })
  const fetchData =
    id =>
    (dispatch, { api }) => {
      dispatch({ type: BRAINS_FETCH_STARTED, payload: id })
      return api(`/trainings/${id}/brains`).then(
        data => {
          dispatch({
            type: BRAINS_FETCH_SUCCEEDED,
            payload: {
              id,
              data,
            },
          })
        },
        error => {
          dispatch({ type: BRAINS_FETCH_FAILED, error, payload: id })
        }
      )
    }

  const requestSelector = (state, id) =>
    id ? state[name].find(e => e.id === id) || null : state[name]

  const dataSelector = (state, key) => {
    const request = requestSelector(state, key)
    const data = request?.data || null
    if (key) return data
    return request
  }

  const isLoadingSelector = (state, key) => {
    const isLoading = requestSelector(state, key)?.isLoading
    return isLoading !== undefined ? isLoading : null
  }

  const isStaleSelector = (state, key) => {
    const { appTime } = state
    const request = requestSelector(state, key)
    const { lastSuccess, isOutdated } = request
    return isOutdated || appTime - lastSuccess > config.stale
  }

  const isExpiredSelector = (state, key) => {
    const isExpired = requestSelector(state, key)?.isExpired
    return isExpired !== undefined ? isExpired : null
  }

  const lastSuccessSelector = (state, key) => {
    const lastSuccess = requestSelector(state, key)?.lastSuccess
    return lastSuccess !== undefined ? lastSuccess : null
  }

  const lastErrorSelector = (state, key) => {
    const request = requestSelector(state, key)
    const { error, errorTimes } = request || {}
    return error ? errorTimes.slice(-1)[0] : null
  }

  const isWaitingToRetrySelector = (state, key) => {
    const { appTime } = state
    const request = requestSelector(state, key)
    const { error, errorTimes } = request || {}
    return error ? appTime - (errorTimes.slice(-1)[0] || null) > config.retry : null
  }

  // const failedPermanentlySelector = (state, key) => {
  //   const request = requestSelector(state, key)
  //   const { error, errorTimes } = request
  //   return error ? errorTimes.length > config.maxRetryCount : null
  // }

  const failedPermanentlySelector = (state, key) => {
    const failedPermanently = requestSelector(state, key)?.failedPermanently
    return failedPermanently !== undefined ? failedPermanently : null
  }

  const selectors = {
    [SELECTORS.DATA]: dataSelector,
    [SELECTORS.IS_LOADING]: isLoadingSelector,
    [SELECTORS.IS_STALE]: isStaleSelector,
    [SELECTORS.IS_WAITING]: isWaitingToRetrySelector,
    [SELECTORS.IS_EXPIRED]: isExpiredSelector,
    [SELECTORS.LAST_SUCCESS]: lastSuccessSelector,
    [SELECTORS.LAST_ERROR]: lastErrorSelector,
    [SELECTORS.FAILED_PERMANENTLY]: failedPermanentlySelector,
    reactMissingBrains: createSelector(
      'selectUser',
      'selectAppTime',
      dataSelector,
      (user, appTime, requests) => {
        if (!user || !requests.length) return null
        const missing = requests
          .filter(e => !(e.data && !e.isOutdated)) // missing or outdated
          .filter(e => !e.isLoading) // isLoading
          .filter(e => e.errorTimes.length <= config.maxRetryCount) // failedPermanently
          .filter(e => appTime - (e.errorTimes.slice(-1)[0] || null) > config.retry) // waitingToRetry
          .filter(e => e.isOutdated || appTime - e.lastSuccess > config.stale) // isStale

        if (!missing.length) return null

        return (dispatch, { api }) => {
          const batch = []
          missing.forEach(request => {
            const { id } = request
            batch.push({ type: BRAINS_FETCH_STARTED, payload: id })
            return api(`/trainings/${id}/brains`).then(
              data => {
                dispatch({
                  type: BRAINS_FETCH_SUCCEEDED,
                  payload: {
                    id,
                    data,
                  },
                })
              },
              error => {
                dispatch({ type: BRAINS_FETCH_FAILED, error, payload: id })
              }
            )
          })
          dispatch({ type: 'BATCH_ACTIONS', actions: batch })
        }
      }
    ),
  }

  if (config.expireAfter !== Infinity) {
    selectors[`reactExpire${uCaseName}`] = createSelector(
      lastSuccessSelector,
      'selectAppTime',
      (time, appTime) => {
        if (!time) {
          return null
        }
        if (appTime - time > config.expireAfter) {
          return expireData()
        }
        return null
      }
    )
  }

  return createBundle({
    name,
    reducer: (state = initialState, { type, payload, error, merge }) => {
      if (type === BRAINS_FETCH_REQUESTED) {
        const requestState = state.find(request => request.id === payload) || null
        if (!requestState) return [...state, { ...initialRequestState, id: payload }]
        return [
          ...state.filter(e => e !== requestState),
          {
            ...requestState,
            isOutdated: true,
          },
        ]
      }
      if (type === BRAINS_FETCH_STARTED) {
        const requestState = state.find(request => request.id === payload) || null
        return [
          ...state.filter(e => e !== requestState),
          { ...requestState, isLoading: true },
        ]
      }
      if (type === BRAINS_FETCH_SUCCEEDED) {
        const { id, data } = payload
        const requestState = state.find(request => request.id === id) || null
        const updatedData = merge ? [...requestState.data, ...data] : data
        return [
          ...state.filter(e => e !== requestState),
          {
            ...requestState,
            isLoading: false,
            data: parseBrains(updatedData),
            lastSuccess: Date.now(),
            errorTimes: [],
            error: null,
            failedPermanently: false,
            isOutdated: false,
            isExpired: false,
          },
        ]
      }
      if (type === BRAINS_FETCH_FAILED) {
        const errorMessage = (error && error.message) || error
        const requestState = state.find(request => request.id === payload) || null
        return [
          ...state.filter(e => e !== requestState),
          {
            ...requestState,
            isLoading: false,
            errorTimes: [...requestState.errorTimes, Date.now()],
            error: errorMessage,
            failedPermanently: !!(error && error.permanent),
          },
        ]
      }
      if (type === BRAINS_CLEARED || type === USER_LOGGED_OUT) {
        if (!payload) return initialState
        return [...state.filter(training => training.id !== payload)]
      }
      if (type === BRAINS_EXPIRED) {
        return state.map(request => {
          if (!payload)
            return {
              ...initialRequestState,
              id: request.id,
              isExpired: true,
              errorTimes: request.errorTimes,
              error: request.error,
            }
          if (request.id === payload)
            return {
              ...initialRequestState,
              id: request.id,
              isExpired: true,
              errorTimes: request.errorTimes,
              error: request.error,
            }
          return request
        })
      }
      if (type === BRAINS_OUTDATED) {
        return state.map(request => {
          if (!payload) return { ...request, isOutdated: true }
          if (request.id === payload) return { ...request, isOutdated: true }
          return request
        })
      }
      return state
    },
    selectors,
    actions: {
      [`fetch${uCaseName}`]: fetchData,
      [`mark${uCaseName}AsOutdated`]: markDataAsOutdated,
      [`clear${uCaseName}`]: clearData,
      [`expire${uCaseName}`]: expireData,
      [`register${uCaseName}Request`]: requestData,
    },
    init: null,
    args: null,
    middleware: null,
    persist: config.persist
      ? [BRAINS_FETCH_SUCCEEDED, BRAINS_EXPIRED, BRAINS_OUTDATED, BRAINS_CLEARED]
      : null,
  })
}
