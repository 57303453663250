/* eslint-disable max-lines */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import moment from 'moment'
import { generateColors } from 'libs/utils/color'

import Conditional from 'components/Conditional'
import { UserControlWrapper } from './UCW'
import ScatterGraph from './ScatterGraph'
import { Provider } from './ScatterChartContext'

import './style.scss'

const classes = {
  wrapper: 'ScatterChart-wrapper',
}

const ScatterChart = props => {
  const {
    brains: storeBrains,
    resourceData,
    resourceMeta,
  } = useStore('selectBrains', 'selectResourceData', 'selectResourceMeta')

  const {
    controlable,
    legend,
    trainings: propTrainings,
    brains: propBrains,
    split = 'train',
    combineTrainings,
    datasets: propDatasets,
    stackingCategories: propStackingCategories,
    combineDatasets,
    type: propType,
    x: propX = [],
    y: propY = [],
    z: propZ = null,
    domain,
    mainColor,
    ...rest
  } = props

  const { xAxis: { label: xLabel } = {}, yAxis: { label: yLabel } = {} } = rest

  const { position: legendPosition = 'left', width: legendWidth = 160 } =
    (legend && legend !== true ? legend : {}) || {}

  const [trainings, brains, datasets, stackingCategories, mode, x, y, z] =
    useMemo(() => {
      const tempTrainings =
        (propTrainings &&
          (!Array.isArray(propTrainings) ? [propTrainings] : propTrainings)) ||
        []
      const tempBrains = (propBrains && [...propBrains]) || []
      if (tempTrainings)
        tempTrainings.forEach(training => {
          const tempTraining = tempBrains?.find(e => e.id === training.id)
          if (!tempTraining) tempBrains.push({ id: training.id, brains: [] })
        })
      const tempDatasets =
        (propDatasets &&
          (!Array.isArray(propDatasets) ? [propDatasets] : propDatasets).map(
            dataset => dataset.id
          )) ||
        []
      const tempStackingCategories =
        (propStackingCategories &&
          (!Array.isArray(propStackingCategories)
            ? [propStackingCategories]
            : propStackingCategories)) ||
        []
      const tempMode =
        (controlable?.trainings ||
          controlable?.brains ||
          propTrainings?.length ||
          propBrains?.length) &&
        'brainMode'
      const tempX = ((propX && (!Array.isArray(propX) ? [propX] : propX)) || [])
        .filter(
          feature =>
            feature.type === 'numeric' ||
            (tempMode !== 'brainMode' && feature.type === 'time')
        )
        .reduce((acc, cur) => {
          const tempFeatures = [...acc]
          const dynamicType = tempFeatures.length && tempFeatures[0].type
          if (
            !tempFeatures.find(
              feature => feature.type === cur.type && feature.name === cur.name
            )
          )
            if (dynamicType) dynamicType === cur.type && tempFeatures.push(cur)
            else tempFeatures.push(cur)
          return tempFeatures
        }, [])
      const tempY = ((propY && (!Array.isArray(propY) ? [propY] : propY)) || [])
        .filter(feature => feature.type === 'numeric')
        .reduce((acc, cur) => {
          const tempFeatures = [...acc]
          if (
            !tempFeatures.find(
              feature => feature.type === cur.type && feature.name === cur.name
            )
          )
            tempFeatures.push(cur)
          return tempFeatures
        }, [])
      const tempZ = propZ || null
      return [
        tempTrainings,
        tempBrains,
        tempDatasets,
        tempStackingCategories,
        tempMode,
        tempX,
        tempY,
        tempZ,
      ]
    }, [
      propTrainings,
      propBrains,
      propDatasets,
      propStackingCategories,
      propX,
      propY,
      propZ,
      controlable,
    ])

  const [stackedBy, type] = useMemo(() => {
    const tempMeta = resourceMeta?.find(({ id }) => id === datasets[0])?.data || {}
    const { stacked_by: stackedById = null } = tempMeta
    const tempStackedBy =
      tempMeta.features?.find(({ id }) => id === stackedById) || null
    const tempType = propType || (datasets.length && tempMeta.type) || null
    return [tempStackedBy, tempType]
  }, [resourceMeta, datasets, propType])

  if (controlable) {
    if (mode === 'brainMode') {
      controlable.datasets = null
      controlable.stackingCategories = null
    } else {
      controlable.split = null
    }
  }

  const storeData = {
    brains: storeBrains || [],
    datasets:
      datasets
        .map(
          dataset =>
            resourceData.find(resource => resource.key === `chart-${dataset}`) ||
            null
        )
        .filter(e => !!e) || [],
  }

  const loading = useMemo(() => {
    return (
      !!storeData.brains.some(({ isLoading }) => isLoading) ||
      !!storeData.datasets.some(({ isLoading }) => isLoading)
    )
  }, [storeData.brains, storeData.datasets])

  const [xyCombinations, xAxisLabel, yAxisLabel] = useMemo(() => {
    // let tempCombinations = []
    // let tempXLabel = ''
    // let tempYLabel = ''
    // if (mode === 'brainMode') {
    //   tempXLabel = x.join(', ')
    //   tempYLabel = y.join(', ')
    //   tempCombinations = x.reduce((acc, cur) => {
    //     const tempResult = [...acc]
    //     y.forEach(metric => tempResult.push({ x: cur, y: metric }))
    //     return tempResult
    //   }, [])
    // } else {
    const tempXLabel = x.map(feature => feature.name).join(', ')
    const tempYLabel = y.map(feature => feature.name).join(', ')
    const tempCombinations = x.reduce((acc, cur) => {
      const tempResult = [...acc]
      y.forEach(feature => tempResult.push({ x: cur, y: feature }))
      return tempResult
    }, [])
    // }
    return [tempCombinations, xLabel || tempXLabel, yLabel || tempYLabel]
  }, [x, y, xLabel, yLabel])

  const data = useMemo(() => {
    const returnData = []
    if (
      mode === 'brainMode' &&
      !controlable &&
      trainings.length &&
      brains.length &&
      xyCombinations.length
    ) {
      const resultData = xyCombinations.reduce(
        (acc, { x: xFeature, y: yFeature }) => {
          const tempResult = trainings
            .map(({ name, id }) => {
              const { objectives: tempBrains = {} } =
                storeData.brains.find(training => training.id === id)?.data || {}
              const { objectives } = tempBrains
              return { name, brains: tempBrains, objectives, id }
            })
            .filter(
              training =>
                training.objectives &&
                training.objectives.find(
                  feature =>
                    feature.type === xFeature.type && feature.name === xFeature.name
                ) &&
                training.objectives.find(
                  feature =>
                    feature.type === yFeature.type && feature.name === yFeature.name
                )
            )
            .map((training, _, { length }) => {
              const filteredBrains = training.brains[split].filter(
                brain =>
                  brains.find(e => e.id === training.id).brains.indexOf(brain.id) !==
                  -1
              )
              return {
                title: `${length > 1 ? `${training.name}: ` : ''}${
                  yFeature.name
                } / ${xFeature.name}`,
                data: filteredBrains.map(e => {
                  const rawValue = (z && e[z.name]) || null
                  const value =
                    rawValue && z?.type === 'numeric'
                      ? Math.ceil(Number(rawValue) * 100) / 100
                      : rawValue
                  let tempX = Number(e[xFeature.name])
                  let tempY = Number(e[yFeature.name])
                  tempX = tempX || tempX === 0 ? tempX : null
                  tempY = tempY || tempY === 0 ? tempY : null
                  return {
                    x: tempX,
                    y: tempY,
                    z: value,
                  }
                }),
              }
            })
          if (combineTrainings) {
            const combinedResult = tempResult.reduce(
              (accumulated, current) => {
                const tempCombined = { ...accumulated }
                tempCombined.data = [...tempCombined.data, ...current.data]
                return tempCombined
              },
              {
                title: `combined: ${yFeature.name} / ${xFeature.name}`,
                data: [],
              }
            )
            return [...acc, combinedResult]
          }
          return [...acc, ...tempResult]
        },
        []
      )
      returnData.push(...resultData)
    }
    if (
      mode !== 'brainMode' &&
      !controlable &&
      datasets.length &&
      xyCombinations.length &&
      (type !== 'stacked-timeseries' || (stackedBy && stackingCategories.length))
    ) {
      const resultData = xyCombinations.reduce(
        (acc, { x: xFeature, y: yFeature }) => {
          const tempResult = storeData.datasets
            .filter(
              instance =>
                instance?.meta?.features?.find(
                  feature =>
                    feature.type === xFeature.type && feature.name === xFeature.name
                ) &&
                instance.meta.features.find(
                  feature =>
                    feature.type === yFeature.type && feature.name === yFeature.name
                )
            )
            .map((instance, _, { length }) => {
              const name =
                resourceMeta?.find(dataset => dataset.id === instance.resource)?.data
                  ?.name || ''
              return {
                title: `${length > 1 ? `${name}: ` : ''}${yFeature.name} / ${
                  xFeature.name
                }`,
                data:
                  instance.data
                    .filter(
                      e =>
                        type !== 'stacked-timeseries' ||
                        stackingCategories.indexOf(e[stackedBy.name]) !== -1
                    )
                    .map(e => {
                      const rawValue = (z && e[z.name]) || null
                      const value =
                        rawValue && z?.type === 'numeric'
                          ? Math.ceil(Number(rawValue) * 100) / 100
                          : rawValue
                      let tempX =
                        xFeature.type === 'time'
                          ? moment(
                              e[xFeature.name],
                              'YYYY-MM-DDTHH:mm:ss.SSS Z'
                            ).valueOf() / 1000
                          : Number(e[xFeature.name])
                      let tempY = Number(e[yFeature.name])
                      tempX = tempX || tempX === 0 ? tempX : null
                      tempY = tempY || tempY === 0 ? tempY : null
                      return {
                        x: tempX,
                        y: tempY,
                        z: value,
                        stacked:
                          (type === 'stacked-timeseries' && e[stackedBy.name]) ||
                          null,
                      }
                    })
                    .filter(
                      ({ x: xVal, y: yVal }) =>
                        (!!xVal || xVal === 0) && (!!yVal || yVal === 0)
                    ) || [],
              }
            })
          let endResult = tempResult
          if (combineDatasets) {
            endResult = [
              tempResult.reduce(
                (accumulated, current) => {
                  const tempCombined = { ...accumulated }
                  tempCombined.data = [...tempCombined.data, ...current.data]
                  return tempCombined
                },
                {
                  title: `combined: ${yFeature.name} / ${xFeature.name}`,
                  data: [],
                }
              ),
            ]
          }
          if (type === 'stacked-timeseries') {
            endResult = endResult.reduce((accumulated, current) => {
              const tempRes = [...accumulated]
              current.data.forEach(datum => {
                const instance = tempRes.find(
                  ({ title }) => title === `${current.title} (${datum.stacked})`
                )
                if (instance)
                  instance.data.push({ x: datum.x, y: datum.y, z: datum.z })
                else
                  tempRes.push({
                    title: `${current.title} (${datum.stacked})`,
                    data: [],
                  })
              })
              return tempRes
            }, [])
          }
          return [...acc, ...endResult]
        },
        []
      )
      returnData.push(...resultData)
    }

    const colorArray =
      (returnData.length &&
        mainColor &&
        generateColors(
          mainColor,
          returnData.length,
          0.2 + returnData.length / (returnData.length + 1) / 10
        )) ||
      []

    return returnData.map((datum, i) => {
      return { ...datum, color: colorArray[i] }
    })
  }, [
    mode,
    controlable,
    trainings,
    combineTrainings,
    brains,
    split,
    storeData.brains,
    storeData.datasets,
    datasets,
    stackedBy,
    type,
    stackingCategories,
    resourceMeta,
    combineDatasets,
    xyCombinations,
    z,
    mainColor,
  ])

  const xAxisType = (mode !== 'brainMode' && x.length && x[0].type) || null
  const contextData = {
    data,
    xAxisLabel,
    yAxisLabel,
    xAxisType,
    domain,
    loading,
  }

  return (
    <div className={classes.wrapper}>
      <Provider value={contextData}>
        <Conditional dependencies={controlable}>
          <UserControlWrapper
            controlable={controlable}
            trainings={trainings}
            brains={brains}
            split={split}
            combineTrainings={combineTrainings}
            datasets={datasets}
            stackingCategories={stackingCategories}
            combineDatasets={combineDatasets}
            type={type}
            x={x}
            y={y}
            z={z}
            labels={{ x: xLabel, y: yLabel }}
            mainColor={mainColor}
          >
            <ScatterGraph
              legend={legend && { position: legendPosition, width: legendWidth }}
              {...rest}
            />
          </UserControlWrapper>
        </Conditional>

        <Conditional dependencies={!controlable}>
          <ScatterGraph
            legend={legend && { position: legendPosition, width: legendWidth }}
            {...rest}
          />
        </Conditional>
      </Provider>
    </div>
  )
}

ScatterChart.propTypes = {
  controlable: PropTypes.shape({
    trainings: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    brains: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    split: PropTypes.bool,
    datasets: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    stackingCategories: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    type: PropTypes.bool,
    x: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    y: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    z: PropTypes.bool,
    prefKey: PropTypes.string,
  }),
  legend: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
    }),
    PropTypes.bool,
  ]),
  xAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['top', 'bottom']),
      height: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
      label: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  yAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
      label: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  grid: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),

  // /////////////// TRAININGS DISPLAY
  trainings: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
    ),
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  ]),
  brains: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      brains: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  split: PropTypes.oneOf(['combined', 'test', 'train', 'validation']),
  combineTrainings: PropTypes.bool,
  // /////////////////////////////////

  // //////////////// DATASETS DISPLAY
  datasets: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    PropTypes.shape({ id: PropTypes.string }),
  ]),
  stackingCategories: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  combineDatasets: PropTypes.bool,
  type: PropTypes.oneOf(['stacked-timeseries', 'basedata', 'timeseries']),
  // /////////////////////////////////

  x: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
    ),
    PropTypes.shape({ type: PropTypes.string, name: PropTypes.string }),
    // PropTypes.arrayOf(PropTypes.string),
    // PropTypes.string,
  ]),
  y: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
    ),
    PropTypes.shape({ type: PropTypes.string, name: PropTypes.string }),
    // PropTypes.arrayOf(PropTypes.string),
    // PropTypes.string,
  ]),
  z: PropTypes.oneOfType([
    PropTypes.shape({ type: PropTypes.string, name: PropTypes.string }),
    // PropTypes.string,
  ]),
  domain: PropTypes.shape({
    x: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.shape({ min: PropTypes.number, max: PropTypes.number }),
    ]),
    y: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.shape({ min: PropTypes.number, max: PropTypes.number }),
    ]),
  }),
  mainColor: PropTypes.string,
}

export default ScatterChart
