import React, { useContext, useMemo, useCallback, useState } from 'react'
import { useStore } from 'core'

import warning from 'tiny-warning'
import datasourceRenderer from './renderers/datasource'
import scoringRenderer from './renderers/scoring'

export const renderContext = React.createContext({ resourceType: null })

export const useRenderer = requestedResource => {
  const { setResourceRequestSort } = useStore('setResourceRequestSort')
  const [statsVisible, setStatsVisible] = useState(false)
  const ctx = useContext(renderContext)
  const { resource: resourceId } = requestedResource || {}
  const { resourceType } = ctx
  warning(
    resourceType,
    `You must provide a { resourceType } to the "useRenderer" hook using the context provider. Expected a 'string' but got: ${typeof resourceType}`
  )

  const {
    key: requestKey,
    meta: resourceMeta = null,
    sortKey = null,
    sortOrder = 0,
  } = requestedResource || {}

  const columnTypes = useMemo(
    () =>
      resourceMeta?.features?.reduce((acc, { name, type }) => {
        acc[name] = type
        return acc
      }, {}) || {},
    [resourceMeta]
  )
  const columnStats = useMemo(
    () =>
      resourceMeta?.features?.reduce((acc, feature) => {
        acc[feature.name] = feature.stats
          ? Object.keys(feature.stats)
              .filter(stat => stat !== 'variance')
              .map(name => {
                const value = feature.stats[name]
                return name !== 'amount'
                  ? {
                      name,
                      value:
                        name === 'frequency'
                          ? `${feature.stats.amount} ${
                              feature.stats.amount === 1
                                ? value.slice(0, value.length - 1)
                                : value
                            }`
                          : value,
                    }
                  : null
              })
          : []
        return acc
      }, {}) || {},
    [resourceMeta]
  )

  const setSort = useCallback(
    (key, order) => setResourceRequestSort(requestKey, key, order),
    [setResourceRequestSort, requestKey]
  )
  const toggleStats = useCallback(() => {
    setStatsVisible(!statsVisible)
  }, [statsVisible])

  return useMemo(() => {
    const sort = { key: sortKey, order: sortOrder }
    if (resourceType === 'SCORED_DATASET')
      return scoringRenderer(
        resourceId,
        resourceMeta,
        columnTypes,
        columnStats,
        sort,
        setSort,
        statsVisible,
        toggleStats,
        ctx
      )
    if (resourceType === 'UPLOADED_DATASET' || resourceType === 'QUERY_DATASET')
      return datasourceRenderer(
        resourceId,
        resourceMeta,
        columnTypes,
        columnStats,
        sort,
        setSort,
        statsVisible,
        toggleStats,
        ctx
      )
    return e => e
  }, [
    resourceId,
    resourceType,
    resourceMeta,
    columnTypes,
    columnStats,
    sortKey,
    sortOrder,
    setSort,
    statsVisible,
    toggleStats,
    ctx,
  ])
}
