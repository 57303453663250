import { createBundle } from 'core/bundler'
import { createSelector } from 'create-selector'

import {
  ACTIVE_PROJECT_SET,
  ACTIVE_DATASOURCE_SET,
  ACTIVE_TRANSFORMATION_SET,
  ACTIVE_TRAINING_SET,
  ACTIVE_SCORING_SET,
} from 'core/actiontypes'

const initialState = {
  [ACTIVE_PROJECT_SET]: null,
  [ACTIVE_DATASOURCE_SET]: null,
  [ACTIVE_TRANSFORMATION_SET]: null,
  [ACTIVE_TRAINING_SET]: null,
  [ACTIVE_SCORING_SET]: null,
}

export default config =>
  createBundle({
    name: 'insight',
    reducer: (state = initialState, { type, payload }) => {
      if (
        type === ACTIVE_PROJECT_SET ||
        type === ACTIVE_DATASOURCE_SET ||
        type === ACTIVE_TRANSFORMATION_SET ||
        type === ACTIVE_TRAINING_SET ||
        type === ACTIVE_SCORING_SET
      ) {
        return {
          ...state,
          [type]: payload,
        }
      }
      return state
    },
    selectors: {
      // selectActiveProject: state => state.insight[ACTIVE_PROJECT_SET],
      // selectActiveDatasource: createSelector(
      //   'selectDatasets',
      //   state => state.insight[ACTIVE_DATASOURCE_SET],
      //   (datasets, activeID) =>
      //     (datasets && datasets.find(dataset => dataset.id === activeID)) || null
      // ),
      // selectActiveTransformation: state => state.insight[ACTIVE_TRANSFORMATION_SET],
      // selectActiveTraining: createSelector( //  WARNING: METHOD NAME COLLISION
      //   'selectTrainings',
      //   state => state.insight[ACTIVE_TRAINING_SET],
      //   (trainings, activeID) =>
      //     (trainings && trainings.find(training => training.id === activeID)) || null
      // ),
      // selectActiveScoring: state => state.insight[ACTIVE_SCORING_SET],
    },
    /* eslint-disable prettier/prettier */
    actions: {
      // setActiveProject: id => ({ type: ACTIVE_PROJECT_SET, payload: id }),
      // setActiveDatasource: id => ({ type: ACTIVE_DATASOURCE_SET, payload: id }),
      // setActiveTransformation: id => ({
      //   type: ACTIVE_TRANSFORMATION_SET,
      //   payload: id,
      // }),
      // setActiveTraining: id => ({ type: ACTIVE_TRAINING_SET, payload: id }),
      // setActiveScoring: id => ({ type: ACTIVE_SCORING_SET, payload: id }),
    },
    /* eslint-enable prettier/prettier */
    init: null,
    args: null,
    middleware: null,
    persist: null,
  })
