/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import { useStore } from 'core'
import { useRefRect } from 'libs/utils/hooks'
import {
  isObject,
  getBranchNodeAmount,
  getBranchLeafAmount,
} from 'libs/utils/helpers'

import Conditional from 'components/Conditional'
import Spinner from 'components/Spinner'
import Branch from './Branch'
import { Provider } from './PipelineContext'

import './style.scss'

const classes = {
  wrapper: 'Pipeline-wrapper',
  shadow: 'Pipeline-shadow',
  statusDisplayDiv: 'Pipeline-statusDisplayDiv',
  spinnerDiv: 'Pipeline-spinnerDiv',
  treeWrapper: 'Pipeline-Tree-wrapper',
  pipelineSVG: 'Pipeline-Tree-pipelineSVG',
  lines: 'Pipeline-Tree-lines',
  line: 'Pipeline-Tree-line',
  addBoxes: 'Pipeline-Tree-addBoxes',
  addBox: 'Pipeline-Tree-addBox',
  addBoxText: 'Pipeline-Tree-addBoxText',
  dialogListItem: 'Pipeline-Tree-dialogListItem',
}

const getLines = (tree, padding = 48, rect = { width: 1, height: 1 }) => {
  const treeLeaves = getBranchLeafAmount(tree)
  const calculateLines = (
    branch,
    posAdd,
    posMultiply,
    lenAdd,
    positionArray = []
  ) => {
    const branchDepth = getBranchNodeAmount(branch)
    const branchLeaves = getBranchLeafAmount(branch)
    const boxes = branch.filter(node => isObject(node))
    const branches = branch.filter(node => Array.isArray(node))
    const branchout = !!branches.length
    const linePosition = (0.5 * posMultiply + posAdd / treeLeaves) * rect.width
    const lineOffset = lenAdd
    const lineLength = (boxes.length / branchDepth) * (rect.height - lineOffset)
    const lines = [
      {
        linePosition,
        lineOffset,
        lineLength,
        path: `M ${linePosition} ${
          rect.height - lineOffset - padding
        } L ${linePosition} ${rect.height - lineOffset - lineLength || 0}`,
        nodes: boxes,
      },
    ]
    lines[0].positionArray = positionArray
    let positionAddition = Number(!branchout)
    if (branchout) {
      const calculated = branches.reduce(
        (acc, cur, i) => {
          const result = [...acc.lines]
          const res = calculateLines(
            cur,
            posAdd + acc.posAdd,
            (posMultiply * getBranchLeafAmount(cur)) / branchLeaves,
            lenAdd + lineLength,
            [...positionArray, i + boxes.length]
          )
          const tempLines = res[0]
          const first = res[0][0]
          const curve = {
            path: `M ${linePosition} ${
              rect.height - lineOffset - lineLength
            } C ${linePosition} ${rect.height - lineOffset - lineLength - padding} ${
              first.linePosition
            } ${rect.height - first.lineOffset + padding / 2} ${
              first.linePosition
            } ${rect.height - first.lineOffset - padding}`,
          }
          result.push(curve)
          result.push(...tempLines)
          const tempPosAdd = acc.posAdd + res[1]
          return { lines: result, posAdd: tempPosAdd }
        },
        {
          lines: [],
          posAdd: positionAddition,
        }
      )
      lines.push(...calculated.lines)
      positionAddition = calculated.posAdd
    }
    return [lines, positionAddition]
  }
  const lines = calculateLines(tree, 0, 1, 0)[0]
  return lines
}

const nodeTopBottomPadding = 3
const nodeBoxSize = 7.5

const Pipeline = () => {
  const contextContainer = useRef()
  const [rect, container] = useRefRect()
  const {
    pipelineSpec: spec,
    pipelineMeta: meta,
    pipelineConfiguringBoxLocation: configuring,
    pipelineIsProcessing: processing,
    pipelineIsCommitting: committing,
    configurePipelineBox: configure,
    activatePipelineBox: selectBox,
  } = useStore(
    'selectPipelineSpec',
    'selectPipelineMeta',
    'selectPipelineConfiguringBoxLocation',
    'selectPipelineIsProcessing',
    'selectPipelineIsCommitting',
    'configurePipelineBox',
    'activatePipelineBox'
  )

  const deselectBoxes = () => selectBox({ location: null })
  const resetConfiguring = () => configure({ location: null })

  const pipelineSVG = useMemo(() => {
    const tempLines = getLines(spec, nodeTopBottomPadding * 16, rect)
    const paths = tempLines.map(({ path }) => {
      return <path key={`Tree-line-${uuid()}`} className={classes.line} d={path} />
    })
    const tempSVG = (
      <Conditional dependencies={rect}>
        <svg
          className={classes.pipelineSVG}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox={(rect && `0 0 ${rect.width} ${rect.height}`) || '0 0 1 1'}
          preserveAspectRatio="none"
          width={rect && `${rect.width}px`}
          height={rect && `${rect.height}px`}
        >
          {paths}
        </svg>
      </Conditional>
    )
    return tempSVG
  }, [spec, rect])

  const contextData = {
    containerParent: contextContainer,
    containerChild: container,
  }

  // const RemoveJoinBoxDialog = (
  //   <Dialog
  //     open={removeBranchoutBoxDialog.open}
  //     onClose={() => {
  //       setRemoveBranchoutBoxDialog({
  //         open: false,
  //         location: null,
  //       })
  //     }}
  //     disableBackdropClick={false}
  //   >
  //     <List>
  //       <ListItem
  //         classes={{
  //           root: classes.dialogListItem,
  //         }}
  //         button
  //         onClick={() => {
  //           removeBox({
  //             location: removeBranchoutBoxDialog.location,
  //             keepLeft: true,
  //           })
  //           setRemoveBranchoutBoxDialog({
  //             open: false,
  //             location: null,
  //           })
  //         }}
  //       >
  //         Keep Left Branch
  //       </ListItem>
  //       <ListItem
  //         classes={{
  //           root: classes.dialogListItem,
  //         }}
  //         button
  //         onClick={() => {
  //           removeBox({
  //             location: removeBranchoutBoxDialog.location,
  //             keepLeft: false,
  //           })
  //           setRemoveBranchoutBoxDialog({
  //             open: false,
  //             location: null,
  //           })
  //         }}
  //       >
  //         Keep Right Branch
  //       </ListItem>
  //     </List>
  //   </Dialog>
  // )

  let status = null
  if (processing) status = 'processing'
  if (committing) status = 'committing'

  return (
    <div ref={contextContainer} className={classes.wrapper} onClick={deselectBoxes}>
      <Conditional dependencies={!!configuring}>
        <div
          className={classes.shadow}
          onClick={resetConfiguring}
          style={{
            minWidth:
              container && container.current
                ? container.current.getBoundingClientRect().width
                : '',
            minHeight:
              container && container.current
                ? container.current.getBoundingClientRect().height +
                  nodeTopBottomPadding * 16
                : '',
          }}
        />
      </Conditional>
      <div
        ref={container}
        className={classes.treeWrapper}
        style={{ marginTop: `${nodeTopBottomPadding * 16}px` }}
      >
        <Provider value={contextData}>
          <Branch
            spec={spec}
            meta={meta}
            nodePadding={nodeTopBottomPadding}
            boxSize={nodeBoxSize}
            location="0"
          />
        </Provider>
        {pipelineSVG}
      </div>
      <Conditional dependencies={status}>
        <div className={classes.statusDisplayDiv}>
          <div className={classes.spinnerDiv}>
            <Spinner
              spin={1}
              strokeWidth={2}
              mainColor="#1E90FF"
              emptyColor="#2e313a"
            />
          </div>
          {`${status}...`}
        </div>
      </Conditional>
    </div>
  )
}

export default Pipeline
