import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import Spinner from 'components/Spinner'
import { MenuItem, TextField } from '@material-ui/core'

const classes = {
  wrapper: 'UserControl-wrapper',
  title: 'UserControl-title',
}

const DatasetSelectControl = props => {
  const {
    datasets: storeDatasets,
    datasetsIsLoading,
    scorings,
    scoringsIsLoading,
  } = useStore(
    'selectDatasets',
    'selectDatasetsIsLoading',
    'selectScorings',
    'selectScoringsIsLoading'
  )

  const {
    values: propValues = [],
    onChange,
    multiple,
    type: propType,
    singleType = false,
    title,
    color,
  } = props

  const isLoading = datasetsIsLoading || scoringsIsLoading

  const scoredDatasets =
    scorings
      ?.reduce((acc, cur) => {
        const tempResult = [...acc]
        tempResult.push(...(cur.datasets || []))
        return tempResult
      }, [])
      ?.map(dataset => ({
        ...dataset,
        name: `${dataset.name} (scored dataset)`,
      })) || []

  const combinedDatasets = scoredDatasets.concat(storeDatasets || [])

  const type = (propType && (!Array.isArray(propType) ? [propType] : propType)) || []

  const datasetsFilteredByType = combinedDatasets
    .filter(dataset => (type.length ? type.indexOf(dataset.spec.type) !== -1 : true))
    .map(({ id, name, spec: { type: dsType } }) => ({ id, name, type: dsType }))

  const values = multiple ? propValues : propValues.slice(0, 1)

  // SELECTED DATASETS
  const filteredValues = values.reduce((acc, cur) => {
    const result = [...acc]
    const tempDataset = datasetsFilteredByType.find(({ id }) => cur === id)
    if (tempDataset) result.push({ id: cur, type: tempDataset?.type })
    return result
  }, [])

  const dynamicType = (filteredValues[0] && filteredValues[0].type) || null

  // ALL DATASETS FILTERED BY DYNAMIC TYPE
  const datasetsOfSingleType = datasetsFilteredByType.filter(dataset =>
    singleType && dynamicType ? dataset.type === dynamicType : true
  )

  const finalValues = filteredValues
    .filter(({ id }) => datasetsOfSingleType.find(dsst => id === dsst.id))
    .map(({ id }) => id)

  return (
    <div
      className={classes.wrapper}
      style={{
        filter: color ? `drop-shadow(0px 0px 5px ${color})` : '',
        boxShadow: color ? `0px 0px 3px 1px ${color}` : '',
      }}
    >
      <div className={classes.title}>
        <span>{title}</span>
        <Conditional dependencies={isLoading}>
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
        </Conditional>
      </div>
      <TextField
        label={
          datasetsOfSingleType.length
            ? `Dataset${(multiple && 's') || ''}`
            : `No dataset${(multiple && 's') || ''} to select`
        }
        select
        SelectProps={{ multiple: true, disabled: !datasetsOfSingleType.length }}
        value={finalValues}
        onChange={event => {
          const tempVal = event.target.value
          const val = tempVal && !Array.isArray(tempVal) ? [tempVal] : tempVal
          const difference = val.filter(id => finalValues.indexOf(id) === -1)
          onChange(multiple ? val : difference)
        }}
      >
        {datasetsOfSingleType
          .sort((a, b) =>
            `${a.name}`.localeCompare(`${b.name}`, 'en', { sensitivity: 'base' })
          )
          .map(dataset => {
            return (
              <MenuItem key={dataset.id} value={dataset.id}>
                {dataset.name}
              </MenuItem>
            )
          })}
      </TextField>
    </div>
  )
}

DatasetSelectControl.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  type: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  singleType: PropTypes.bool,
  title: PropTypes.string,
  color: PropTypes.string,
}

export default DatasetSelectControl
