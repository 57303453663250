import React, { useState } from 'react'
import { useStore } from 'core'
import './style.scss'

import Conditional from 'components/Conditional'
import { CardLayout, BaseCard as Card } from 'components/CardLayout'
import TrainingInspector from 'components/TrainingInspector'
import { Button } from '@material-ui/core'
import { TrainingCard, TrainingTableHeader, TrainingTableEntry } from './components'

const classes = {
  wrapper: 'TrainingsPage-wrapper',
  title: 'TrainingsPage-title',
  subTitle: 'TrainingsPage-subTitle',
  horizontalScrollDiv: 'TrainingsPage-horizontalScrollDiv',
  scrollDiv: 'TrainingsPage-scrollDiv',
}

const TrainingsPage = () => {
  const [sort, setSort] = useState({ column: 'name', order: 1 })
  const {
    trainings: storeTrainings,
    pref,
    initializeTrainingWizard,
    selectedTraining: selectedTrainingId,
  } = useStore(
    'selectTrainings',
    'selectPref',
    'initializeTrainingWizard',
    'selectSelectedTraining'
  )

  const display = pref['models-displayMode'] || 'table'

  const trainings = (storeTrainings || [])
    .map(tr => {
      const { name, id, started_at: date = 'N/A', status = 'unknown' } = tr
      return { name, date, status, id, original: tr }
    })
    .filter(tr => tr.status !== 'DELETING')
    .sort((a, b) => {
      const aVal = a[sort.column]
      const bVal = b[sort.column]
      let result = 0
      if (typeof aVal === 'string' || typeof bVal === 'string') {
        result = `${aVal}`.localeCompare(`${bVal}`, 'en', {
          sensitivity: 'base',
        })
      } else result = aVal - bVal
      return result * (-1) ** (sort.order + 1)
    })

  return (
    <>
      <TrainingInspector />
      <div className={classes.wrapper}>
        <Conditional dependencies={display === 'table'}>
          <Card>
            <div className={classes.title}>Models</div>
            <span className={classes.subTitle}>
              Models are swarms of neural networks that are trained with the output
              of a pipeline, to preform regression and classification tasks.
            </span>
            <div className={classes.horizontalScrollDiv}>
              <TrainingTableHeader
                sort={{
                  column: sort.column,
                  order: sort.order,
                  callback: (column, order) => {
                    if (order) {
                      const tempSort = { column, order }
                      setSort(tempSort)
                    } else setSort({ column: null, order })
                  },
                }}
              />
              <div className={classes.scrollDiv}>
                {trainings.map(tr => (
                  <TrainingTableEntry
                    key={tr.id}
                    id={tr.id}
                    name={tr.name}
                    status={tr.status}
                    date={tr.date}
                    original={tr.original}
                    selected={tr.id === selectedTrainingId}
                  />
                ))}
              </div>
            </div>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => {
                initializeTrainingWizard(null, null, null, null, null, true, true, 0)
              }}
            >
              Start a new training
            </Button>
          </Card>
        </Conditional>
        <Conditional dependencies={display === 'cards'}>
          <CardLayout justify="left">
            {trainings.map(tr => (
              <TrainingCard
                key={tr.id}
                id={tr.id}
                name={tr.name}
                status={tr.status}
                date={tr.date}
                original={tr.original}
                selected={tr.id === selectedTrainingId}
              />
            ))}
          </CardLayout>
        </Conditional>
      </div>
    </>
  )
}

export default TrainingsPage
