export const classes = {
  dialog: 'ScoringWizard-dialog',
  wrapper: 'ScoringWizard-wrapper',
  title: 'ScoringWizard-title',
  header: 'ScoringWizard-header',
  content: 'ScoringWizard-content',
  footer: 'ScoringWizard-footer',
  overlayWrapper: 'ScoringWizard-overlayWrapper',
  stepsWrapper: 'ScoringWizard-stepsWrapper',
  step: 'ScoringWizard-step',
  stepContent: 'ScoringWizard-stepContent',
  progressWrapper: 'ScoringWizard-progressWrapper',
  progressBar: 'ScoringWizard-progressBar',
  closeButton: 'ScoringWizard-closeButton',
}
