import React, { useRef, useState, useEffect } from 'react'
import Core, {
  useRouteMatch,
  Redirect,
  useHistory,
  useLocation,
  useStore,
} from 'core'
import 'style.scss'

import { Form, FormItem } from 'components/Form'
import Conditional from 'components/Conditional'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'

import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core'
import BackgroundLogoRings from '../BrainLogoRings.svg'

import { classes as classNames } from '../classNames'

const classes = {
  ...classNames,
  wrapper: 'ConfirmationPage-wrapper',
}

const ConfirmationPage = props => {
  const emailRef = useRef()
  const location = useLocation()
  const history = useHistory()
  const [emailError, setEmailError] = useState(null)
  const [email, setEmail] = useState(null)

  const {
    confirmAccount,
    requestVerificationToken,
    registrationSucceeded,
    verificationInProgress,
    verificationSucceeded,
    verificationError,
    tokenRefreshInProgress,
    // tokenRefreshSucceeded,
    tokenRefreshError,
  } = useStore(
    'confirmAccount',
    'requestVerificationToken',
    'selectVerificationInProgress',
    'selectVerificationSucceeded',
    'selectVerificationError',
    'selectTokenRefreshInProgress',
    // 'selectTokenRefreshSucceeded',
    'selectTokenRefreshError',
    'selectRegistrationSucceeded'
  )

  const invalidTokenError = verificationError?.type === 'TOKEN_INVALID'
  const expiredTokenError = verificationError?.type === 'TOKEN_EXPIRED'
  const alreadyVerifiedError = verificationError?.type === 'EMAIL_ALREADY_VERIFIED'

  const loginRedirect = () => history.push('/login')

  const invalidTokenRefreshRequest = () => {
    const validEmail = emailRef.current.checkValidity()
    if (!emailError && !validEmail) setEmailError('Must be a valid e-mail address.')
    if (validEmail) {
      requestVerificationToken(null, email)
    }
  }
  const expiredTokenRefreshRequest = () => {
    const token =
      location.search
        .substr(1)
        .split('&')
        .map(e => e.split('='))
        .find(e => e[0] === 'token')?.[1] || null
    if (token) {
      requestVerificationToken(token, null)
    }
  }

  const emailChangeHandler = e => {
    if (emailError) setEmailError(null)
    setEmail(e.target.value)
  }

  useEffect(() => {
    const token = location.pathname.match(/(?<=^\/confirm\/)(.)+/gi)?.[0] || null
    token && confirmAccount(token)
  }, [confirmAccount, location])

  return (
    <>
      <Conditional
        dependencies={
          !verificationInProgress &&
          !verificationSucceeded &&
          !verificationError &&
          !tokenRefreshInProgress &&
          !tokenRefreshError &&
          !registrationSucceeded
        }
      >
        <Redirect to="/dashboard" absolute />
      </Conditional>
      <div
        className={classes.wrapper}
        style={{
          flexDirection: 'column',
        }}
      >
        <Conditional dependencies={registrationSucceeded}>
          <div className={classes.messageWrapper}>
            <span className={classes.bigText}>
              Registration completed successfully
            </span>
            <span className={classes.smallText}>
              Please check your email for verification.
            </span>
          </div>
        </Conditional>
        <Conditional dependencies={verificationInProgress}>
          <div className={classes.loaderWrapper}>
            <span className={classes.loaderMessage}>Verifying...</span>
            <LinearProgress />
          </div>
        </Conditional>
        <Conditional dependencies={verificationSucceeded}>
          <div className={classes.messageWrapper}>
            <span className={classes.bigText}>Account successfully verified.</span>
            <span className={classes.smallText}>
              <Button variant="contained" color="primary" onClick={loginRedirect}>
                Go to Login page
              </Button>
            </span>
          </div>
        </Conditional>
        <Conditional dependencies={tokenRefreshInProgress}>
          <div className={classes.loaderWrapper}>
            <span className={classes.loaderMessage}>Verifying...</span>
            <LinearProgress />
          </div>
        </Conditional>
        <Conditional dependencies={tokenRefreshError}>
          <div className={classes.messageWrapper}>
            <span className={classes.bigText}>Something went wrong.</span>
            <span className={classes.smallText}>Please try again later.</span>
          </div>
        </Conditional>
        <Conditional dependencies={invalidTokenError}>
          <div className={classes.messageWrapper}>
            <span className={classes.bigText}>The token code is invalid</span>
            <span className={classes.smallText}>
              You can request a new token by entering your email.
            </span>
            <div className={classes.controlsWrapper}>
              <TextField
                inputProps={{
                  ref: emailRef,
                }}
                name="email"
                type="email"
                autoComplete="email"
                fullWidth
                required
                margin="normal"
                label="Email"
                error={!!emailError}
                helperText={emailError || ''}
                value={email}
                onChange={emailChangeHandler}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={invalidTokenRefreshRequest}
              >
                Request New Token
              </Button>
            </div>
          </div>
        </Conditional>
        <Conditional dependencies={expiredTokenError}>
          <div className={classes.messageWrapper}>
            <span className={classes.bigText}>The token has expired</span>
            <span className={classes.smallText}>
              We can send you a new token to your email.
            </span>
            <div className={classes.controlsWrapper}>
              <Button
                variant="contained"
                color="primary"
                onClick={expiredTokenRefreshRequest}
              >
                Request New Token
              </Button>
            </div>
          </div>
        </Conditional>
        <Conditional dependencies={alreadyVerifiedError}>
          <div className={classes.messageWrapper}>
            <span className={classes.bigText}>Already verified</span>
            <span className={classes.smallText}>
              You can log in to your account.
            </span>
            <div className={classes.controlsWrapper}>
              <Button variant="contained" color="primary" onClick={loginRedirect}>
                Go to Login page
              </Button>
            </div>
          </div>
        </Conditional>
      </div>
    </>
  )
}

export default ConfirmationPage
