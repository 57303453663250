import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import 'style.scss'

import Conditional from 'components/Conditional'
import Spinner from 'components/Spinner'
import Table, { Column } from 'components/Table'

import { TextField, MenuItem } from '@material-ui/core'

import { extractValue } from 'libs/utils/helpers'

const classes = {
  brainsLoading: 'ScoringWizard-brainsLoadingWrapper',
  brainsError: 'ScoringWizard-brainsError',
  brainsTableWrapper: 'Scoring-Wizard-brainsTable-wrapper',
  noDataMsg: 'ScoringWizard-no-data-msg',
  alternativeDataMsg: 'ScoringWizard-alternative-data-msg',
}

const splitOptions = [
  { id: 'test', name: 'Test' },
  { id: 'train', name: 'Train' },
  { id: 'validation', name: 'Validation' },
]

const ScoringBrainsTable = props => {
  const [split, setSplit] = useState('test')

  const { training, brains: selectedBrains, setBrains, disabled } = props
  const { brains, brainsRequestIsLoading /* brainsRequestLastError */ } = useStore(
    `selectBrains[${training}]`,
    `selectBrainsRequestIsLoading[${training}]`
    // `selectBrainsRequestLastError[${training}]`
  )

  const tableBrains = useMemo(() => {
    const parsed = ((brains?.objectives || {})[split] || []).map(brain => {
      const tempBrain = { ...brain }
      const learningBrain = ((brains.learningCurve || {})[split] || []).find(
        ({ id }) => id === brain.id
      )
      const learningBrainFeatures = (
        (brains.learningCurve || {}).features || []
      ).filter(feature => feature !== 'combined')
      learningBrainFeatures.forEach(feature => {
        if (
          learningBrain.lossEndPoint[feature] ||
          learningBrain.lossEndPoint[feature] === 0
        )
          tempBrain[`loss (${feature})`] = learningBrain.lossEndPoint[feature]
      })
      return tempBrain
    })
    return parsed
  }, [split, brains])

  return (
    <>
      <Conditional dependencies={brainsRequestIsLoading}>
        <div className={classes.brainsLoading}>
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
        </div>
      </Conditional>
      <Conditional dependencies={!brainsRequestIsLoading && !tableBrains.length}>
        <span className={classes.noDataMsg}>No brains found.</span>
        <span className={classes.alternativeDataMsg}>
          Please choose a different training.
        </span>
      </Conditional>
      {/* <Conditional dependencies={brainsRequestLastError}>
        <div className={classes.brainsError}>
          <h3>Brains fetching has failed.</h3>
          <span>Pleaes edit the pipeline and to fix the error.</span>
        </div>
      </Conditional> */}
      {/* <Conditional dependencies={outputHasGaps(pipeline_output)}>
          <div className={classes.pipelineOutput}>
            <h3>We have detected gaps in the output.</h3>
            <span>Non-objective features must not have gaps,</span>
            <span>please imputate your data.</span>
          </div>
        </Conditional> */}
      <Conditional dependencies={!brainsRequestIsLoading && tableBrains?.length}>
        <TextField
          style={{ marginBottom: '10px' }}
          disabled={disabled}
          select
          value={split}
          label="Data split: "
          onChange={event => setSplit(event.target.value)}
        >
          {splitOptions.map(e => (
            <MenuItem key={e.id} value={e.id}>
              {e.name}
            </MenuItem>
          ))}
        </TextField>
        <div className={classes.brainsTableWrapper}>
          <Table
            data={tableBrains}
            // footer={{
            //   manageColumns: false,
            //   pagination: { perPage: 13, currentPage, setCurrentPage },
            // }}
            check={{
              checked: selectedBrains,
              id: 'id',
              checkCallback: (_, checked, allRecords, id) => {
                if (checked) {
                  setBrains(selectedBrains.filter(c => c !== id))
                } else {
                  setBrains([...selectedBrains, id])
                }
              },
              checkAllCallback: (allRecords, checked) => {
                if (checked) {
                  setBrains([])
                } else {
                  setBrains(allRecords.map(r => extractValue(r, 'id')))
                }
              },
            }}
          >
            <Column
              title="Name"
              dataKey="name"
              width={150}
              sortFunction={(a, b) => {
                const aVal = extractValue(a, 'name')
                const bVal = extractValue(b, 'name')
                let result = 0
                if (typeof aVal === 'string' || typeof bVal === 'string') {
                  result = `${aVal}`.localeCompare(`${bVal}`, 'en', {
                    sensitivity: 'base',
                  })
                } else result = aVal - bVal
                return result
              }}
            />
            <Column title="ID" dataKey="id" hidden />
            <Column
              dataKey="_defaultColumn"
              width={180}
              render={(val, header, data) => {
                if (header) return val
                return Math.round(val * 1000000) / 1000000
              }}
              sortFunction={(a, b, key) => {
                const aVal = extractValue(a, key)
                const bVal = extractValue(b, key)
                let result = 0
                if (typeof aVal === 'string' || typeof bVal === 'string') {
                  result = `${aVal}`.localeCompare(`${bVal}`, 'en', {
                    sensitivity: 'base',
                  })
                } else result = aVal - bVal
                return result
              }}
            />
          </Table>
        </div>
      </Conditional>
    </>
  )
}

ScoringBrainsTable.propTypes = {
  training: PropTypes.string,
  split: PropTypes.string,
  brains: PropTypes.arrayOf(PropTypes.string),
  setBrains: PropTypes.func,
  disabled: PropTypes.bool,
}

export default ScoringBrainsTable
