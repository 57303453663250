import React from 'react'
import PropTypes from 'prop-types'

import { getLowHighPath, interpolateLine } from './helpers'

const classes = {
  lowHighPath: 'CurrencyGraph-lowHighPath',
}

const LowHighPath = props => {
  const { data, bounds } = props

  const low = data.map(({ x, low: y }) => ({ x, y }))
  const high = data.map(({ x, high: y }) => ({ x, y }))
  const interpolatedLow = interpolateLine(low, bounds)
  const interpolatedHigh = interpolateLine(high, bounds)

  const path = getLowHighPath(interpolatedLow, interpolatedHigh)
  //   const path2 = getLowHighPath(low, high)

  return <path className={classes.lowHighPath} d={path} />
}

LowHighPath.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  bounds: PropTypes.shape({
    xMin: PropTypes.number,
    xMax: PropTypes.number,
    yMin: PropTypes.number,
    yMax: PropTypes.number,
  }),
}

export default LowHighPath
