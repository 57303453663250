/* eslint-disable max-lines */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, TextField, Tooltip } from '@material-ui/core'
import { useInteractiveClick } from 'libs/interactive'

import { CALCULATION_FUNCTIONS } from './stringConstants'
import {
  momentumIndicators,
  overlapStudies,
  patternRecognition,
  statisticFunctions,
} from './FinancialFunctions'

const classes = {
  wrapper: 'ConfigBox-addRuleButtonsSection',
  financialFunctionsGroup:
    'CalculationBoxFinancialFunctions-financialFunctionsGroup',
  financialFunctionsGroupLabel:
    'CalculationBoxFinancialFunctions-financialFunctionsGroupLabel',
  addRuleButton: 'ConfigBox-addRuleButton',
}

const CycleIndicators = props => {
  const { onClick, onKeyDown } = props

  return (
    <div
      className={classes.financialFunctionsGroup}
      style={{
        justifyContent: 'center',
        width: 'calc(50% - 10px)',
        // flexGrow: '1'
      }}
    >
      <span className={classes.financialFunctionsGroupLabel}>Cycle Indicators</span>
      <Tooltip title="Hilbert Transform - Dominant Cycle Period">
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'ht_dcperiod',
              type: 'cycle',
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'ht_dcperiod',
              type: 'cycle',
            })
          }}
        >
          Period
        </button>
      </Tooltip>
      <Tooltip title="Hilbert Transform - Dominant Cycle Phase">
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'ht_dcphase',
              type: 'cycle',
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'ht_dcphase',
              type: 'cycle',
            })
          }}
        >
          Phase
        </button>
      </Tooltip>
      <Tooltip title="Hilbert Transform - Phasor Components">
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'ht_phasor',
              type: 'cycle',
              target: 'inphase',
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'ht_phasor',
              type: 'cycle',
              target: 'inphase',
            })
          }}
        >
          Phasor
        </button>
      </Tooltip>
      <Tooltip title="Hilbert Transform - SineWave">
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'ht_sine',
              type: 'cycle',
              target: 'sine',
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'ht_sine',
              type: 'cycle',
              target: 'sine',
            })
          }}
        >
          Sine
        </button>
      </Tooltip>
      <Tooltip title="Hilbert Transform - Trend vs Cycle Mode">
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'ht_trendmode',
              type: 'cycle',
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'ht_trendmode',
              type: 'cycle',
            })
          }}
        >
          Trend
        </button>
      </Tooltip>
    </div>
  )
}

const VolumeIndicators = props => {
  const { onClick, onKeyDown } = props

  return (
    <div
      className={classes.financialFunctionsGroup}
      style={{ justifyContent: 'center', width: 'unset', flexGrow: '1' }}
    >
      <span className={classes.financialFunctionsGroupLabel}>Volume Indicators</span>
      <Tooltip title="Chaikin A/D Line">
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'ad',
              type: 'volume',
              volume: null,
              low: null,
              high: null,
              close: null,
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'ad',
              type: 'volume',
              volume: null,
              low: null,
              high: null,
              close: null,
            })
          }}
        >
          AD
        </button>
      </Tooltip>
      <Tooltip title="Chaikin A/D Line Oscillator">
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'adosc',
              type: 'volume',
              volume: null,
              low: null,
              high: null,
              close: null,
              slow: 10,
              fast: 3,
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'adosc',
              type: 'volume',
              volume: null,
              low: null,
              high: null,
              close: null,
              slow: 10,
              fast: 3,
            })
          }}
        >
          ADOsc
        </button>
      </Tooltip>
      <Tooltip title="On Balance Volume">
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'obv',
              type: 'volume',
              volume: null,
              close: null,
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'obv',
              type: 'volume',
              volume: null,
              close: null,
            })
          }}
        >
          OBV
        </button>
      </Tooltip>
    </div>
  )
}

const VolatilityIndicators = props => {
  const { onClick, onKeyDown } = props

  return (
    <div
      className={classes.financialFunctionsGroup}
      style={{ justifyContent: 'center', width: 'unset', flexGrow: '1' }}
    >
      <span className={classes.financialFunctionsGroupLabel}>
        Volatility Indicators
      </span>
      <Tooltip title="Average True Range">
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'atr',
              type: 'volatility',
              low: null,
              high: null,
              close: null,
              time: 14,
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'atr',
              type: 'volatility',
              low: null,
              high: null,
              close: null,
              time: 14,
            })
          }}
        >
          ATR
        </button>
      </Tooltip>
      <Tooltip title="Normalized Average True Range">
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'natr',
              type: 'volatility',
              low: null,
              high: null,
              close: null,
              time: 14,
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, {
              function: 'natr',
              type: 'volatility',
              low: null,
              high: null,
              close: null,
              time: 14,
            })
          }}
        >
          NATR
        </button>
      </Tooltip>
    </div>
  )
}

const MomentumIndicators = props => {
  const [state, setState] = useState({
    function: 'adx',
    type: 'momentum',
    low: null,
    high: null,
    close: null,
    time: 14,
  })
  const { onClick, onKeyDown } = props

  return (
    <div className={classes.financialFunctionsGroup}>
      <TextField
        select
        value={state.function}
        onChange={event =>
          setState(
            momentumIndicators.find(({ function: f }) => f === event.target.value)
          )
        }
        label="Momentum Idicators"
        margin="dense"
        fullWidth
      >
        {momentumIndicators.map(({ label, function: f }) => (
          <MenuItem key={f} value={f}>
            {label}
          </MenuItem>
        ))}
      </TextField>
      <button
        className={classes.addRuleButton}
        type="button"
        onClick={event => {
          const options = Object.keys(state)
            .filter(key => key !== 'label')
            .reduce((acc, cur) => ({ ...acc, [cur]: state[cur] }), {})
          onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, { ...options })
        }}
        onKeyDown={event => {
          const options = Object.keys(state)
            .filter(key => key !== 'label')
            .reduce((acc, cur) => ({ ...acc, [cur]: state[cur] }), {})
          onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, { ...options })
        }}
      >
        Add
      </button>
    </div>
  )
}

const OverlapStudies = props => {
  const [state, setState] = useState({
    function: 'bbands',
    type: 'overlap',
    time: 30,
    nbdevup: 2,
    nbdevdn: 2,
    matype: 0,
    target: 'upperband',
  })
  const { onClick, onKeyDown } = props

  return (
    <div className={classes.financialFunctionsGroup}>
      <TextField
        select
        value={state.function}
        onChange={event =>
          setState(
            overlapStudies.find(({ function: f }) => f === event.target.value)
          )
        }
        label="Overlap Studies"
        margin="dense"
        fullWidth
      >
        {overlapStudies.map(({ label, function: f }) => (
          <MenuItem key={f} value={f}>
            {label}
          </MenuItem>
        ))}
      </TextField>
      <button
        className={classes.addRuleButton}
        type="button"
        onClick={event => {
          const options = Object.keys(state)
            .filter(key => key !== 'label')
            .reduce((acc, cur) => ({ ...acc, [cur]: state[cur] }), {})
          onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, { ...options })
        }}
        onKeyDown={event => {
          const options = Object.keys(state)
            .filter(key => key !== 'label')
            .reduce((acc, cur) => ({ ...acc, [cur]: state[cur] }), {})
          onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, { ...options })
        }}
      >
        Add
      </button>
    </div>
  )
}

const StatisticFunctions = props => {
  const [state, setState] = useState({
    function: 'beta',
    type: 'statistic',
    low: null,
    high: null,
    time: 5,
  })
  const { onClick, onKeyDown } = props

  return (
    <div className={classes.financialFunctionsGroup}>
      <TextField
        select
        value={state.function}
        onChange={event =>
          setState(
            statisticFunctions.find(({ function: f }) => f === event.target.value)
          )
        }
        label="Statistic Functions"
        margin="dense"
        fullWidth
      >
        {statisticFunctions.map(({ label, function: f }) => (
          <MenuItem key={f} value={f}>
            {label}
          </MenuItem>
        ))}
      </TextField>
      <button
        className={classes.addRuleButton}
        type="button"
        onClick={event => {
          const options = Object.keys(state)
            .filter(key => key !== 'label')
            .reduce((acc, cur) => ({ ...acc, [cur]: state[cur] }), {})
          onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, { ...options })
        }}
        onKeyDown={event => {
          const options = Object.keys(state)
            .filter(key => key !== 'label')
            .reduce((acc, cur) => ({ ...acc, [cur]: state[cur] }), {})
          onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, { ...options })
        }}
      >
        Add
      </button>
    </div>
  )
}

const PatternRecognition = props => {
  const [state, setState] = useState({
    function: 'cdlrisefall3methods',
    type: 'pattern',
    open: null,
    low: null,
    high: null,
    close: null,
  })
  const { onClick, onKeyDown } = props

  return (
    <div className={classes.financialFunctionsGroup}>
      <TextField
        select
        value={state.function}
        onChange={event =>
          setState(
            patternRecognition.find(({ function: f }) => f === event.target.value)
          )
        }
        label="Pattern Recognition"
        margin="dense"
        fullWidth
      >
        {patternRecognition.map(({ label, function: f }) => (
          <MenuItem key={f} value={f}>
            {label}
          </MenuItem>
        ))}
      </TextField>
      <button
        className={classes.addRuleButton}
        type="button"
        onClick={event => {
          const options = Object.keys(state)
            .filter(key => key !== 'label')
            .reduce((acc, cur) => ({ ...acc, [cur]: state[cur] }), {})
          onClick(event, CALCULATION_FUNCTIONS.FINANCIAL, { ...options })
        }}
        onKeyDown={event => {
          const options = Object.keys(state)
            .filter(key => key !== 'label')
            .reduce((acc, cur) => ({ ...acc, [cur]: state[cur] }), {})
          onKeyDown(event, CALCULATION_FUNCTIONS.FINANCIAL, { ...options })
        }}
      >
        Add
      </button>
    </div>
  )
}

const CalculationBoxFinancialFunctions = props => {
  const { callback } = props
  const { onClick, onKeyDown } = useInteractiveClick(callback)
  return (
    <div className={classes.wrapper}>
      <CycleIndicators onClick={onClick} onKeyDown={onKeyDown} />
      <VolumeIndicators onClick={onClick} onKeyDown={onKeyDown} />
      <VolatilityIndicators onClick={onClick} onKeyDown={onKeyDown} />
      <MomentumIndicators onClick={onClick} onKeyDown={onKeyDown} />
      <OverlapStudies onClick={onClick} onKeyDown={onKeyDown} />
      <StatisticFunctions onClick={onClick} onKeyDown={onKeyDown} />
      <PatternRecognition onClick={onClick} onKeyDown={onKeyDown} />
    </div>
  )
}

CalculationBoxFinancialFunctions.propTypes = {
  callback: PropTypes.func.isRequired,
}
OverlapStudies.propTypes = {
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
}
StatisticFunctions.propTypes = {
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
}
MomentumIndicators.propTypes = {
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
}
CycleIndicators.propTypes = {
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
}
VolumeIndicators.propTypes = {
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
}
VolatilityIndicators.propTypes = {
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
}
PatternRecognition.propTypes = {
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
}

export default CalculationBoxFinancialFunctions
