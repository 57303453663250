/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable dot-notation */
/* eslint-disable no-self-compare */
import React, {
  useReducer,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import 'style.scss'

import invariant from 'tiny-invariant'
import warning from 'tiny-warning'

import { Provider } from './FormContext'
import { HAS_DEBUG_FLAG } from '../../../libs/utils/developmentConstants'

const classes = {
  wrapper: 'Form-wrapper',
}

const serialize = obj => {
  const result = []
  Object.keys(obj).forEach(dataKey =>
    dataKey
      .replace(/\]/g, '.')
      .replace(/\[/g, '.[].')
      .split('.')
      .filter(e => e)
      .reduce((parent, key, i, a) => {
        if (key === '[]') return parent
        // if (Array.isArray(parent)) {
        //   invariant(
        //     key === `${Math.round(key)}`,
        //     `Error while serializing dataKey ${dataKey}. Expected a numeric value for an array parent, got ${typeof key}.`
        //   )
        // }
        if (i === a.length - 1) {
          invariant(
            typeof parent === 'object',
            `Attempted to create a property "${key}" on ${typeof parent} - "${parent}". Make sure your dataKey "${dataKey}" reflects the model's data. This usually happens if your arrays do not have consistent data structure.`
          )
          parent[key] = obj[dataKey]['_value']
          return parent
        }
        if (parent[key]) return parent[key]
        if (a[i + 1] !== '[]') return (parent[key] = {})
        return (parent[key] = [])
      }, result)
  )
  if (Object.keys(result).some(key => Number(key) !== Number(key))) {
    return Object.keys(result).reduce((o, key) => {
      o[key] = result[key]
      return o
    }, {})
  }
  return result
}

const flatten = (obj, flatKey, result = {}) => {
  // result = result || obj.constructor()
  Object.keys(obj).forEach(key => {
    const isParentArray = Array.isArray(obj)
    let fullKey = ''

    if (isParentArray) fullKey = `${flatKey || ''}[${key}]`
    else fullKey = flatKey ? `${flatKey}.${key}` : key

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      return flatten(obj[key], fullKey, result)
    }
    return (result[fullKey] = { _value: obj[key] })
  })
  return result
}

const verifyParse = state => {
  const parsed = flatten(serialize(state))
  if (JSON.stringify(state) === JSON.stringify(parsed)) return true

  if (
    Object.keys(state).length === Object.keys(parsed).length &&
    !Object.keys(parsed).some(
      key =>
        !state[key] || (state[key] && state[key]['_value'] !== parsed[key]['_value'])
    )
  )
    return true
  return false
}
// serialize({
//   a: { _value: 'A' },
//   'b.b': { _value: 'B' },
//   '[0]': { _value: 'C' },
//   '[1][0]': { _value: 'D' },
//   '[2].e': { _value: 'E' },
//   '[3][0][0][0]': { _value: 'F' },
//   '[4][0][0].a[0]': { _value: 'F' },
//   'a._value[0].0._value.a': { _value: 'a' },
//   'c[0][0]': { _value: 'C00' },
//   'c[0][1]': { _value: 'C01' },
//   'c[1][0]': { _value: 'C10' },
//   'c[1][1]': { _value: 'C11' },
// })

const Form = forwardRef(
  ({ children, preload = {}, onChange, className, onSubmit, ...rest }, ref) => {
    onSubmit = onSubmit || (e => e.preventDefault())
    const self = 'Form'
    const [state, setState] = useReducer(
      (s, action) => {
        let result
        if (typeof action === 'function') result = action(s)
        else result = { ...s, ...action }
        typeof onChange === 'function' && onChange(serialize(result))
        return result
      },
      // {
      // OBJECT-LIKE KEYS
      // a: { _value: 'A' },
      // 'b.b': { _value: 'B' },
      // 'c[0]': { _value: 'C0' },
      // 'c[1].c': { _value: 'C1' },
      // 'd[0][0]': { _value: 'D1' },
      // 'd[0][1].d': { _value: 'D2' },
      // 'e[0].a': { _value: 'for: e[0].a' },
      // 'e[0].b': { _value: 'for: e[0].b' },
      // 'e[1].a': { _value: 'for: e[1].a' },
      // 'e[1].b': { _value: 'for: e[1].b' },
      // 'f[0][0]': { _value: 'for: f[0][0]' },
      // 'f[0][1]': { _value: 'for: f[0][0]' },
      // ARRAY-LIKE KEYS
      // '[0]': { _value: 'index' },
      // '[1].a': { _value: 'index-object' },
      // '[2][0]': { _value: 'index-index' },
      // '[3][0].a': { _value: 'index-index-object' },
      // '[4][0][0]': { _value: 'index-index-index' },
      // '[5][0].a[0]': { _value: 'index-index-object-index' },
      // '[6][0].a': { _value: 'for: [6][0].a' },
      // '[6][0].b': { _value: 'for: [6][0].b' },
      // '[6][1].a': { _value: 'for: [6][1].a' },
      // '[6][1].b': { _value: 'for: [6][1].b' },
      // '[7][0][0]': { _value: '00' },
      // '[7][0][1]': { _value: '01' },
      // '[7][1][0]': { _value: '10' },
      // '[7][1][1]': { _value: '11' },
      // '[7][2][0]': { _value: '20' },
      // '[7][2][1]': { _value: '21' },
      // }
      flatten(preload)
      // {}
    )

    const htmlRef = useRef()

    const initField = useCallback(
      (key, value) => setState(s => ({ ...s, [key]: { ...s[key], _value: value } })),
      []
    )

    const setValue = useCallback(
      (key, value) =>
        setState(s => ({ ...s, [key]: { ...s[key], _value: value, _dirty: true } })),
      []
    )

    const setTouched = useCallback(key => {
      setState(s => ({ ...s, [key]: { ...s[key], _touched: true } }))
    }, [])

    const dispatch = useCallback((action, dataKey, value) => {
      if (action === 'replace') {
        return setState(() => flatten(value, null))
      }
      if (action === 'merge') {
        const merge =
          typeof value === 'object'
            ? flatten(value, dataKey)
            : { [dataKey]: { _value: value } }
        return setState(merge)
      }
      if (action === 'delete') {
        return setState(s =>
          Object.keys(s)
            .filter(key => !key.startsWith(dataKey))
            .reduce((o, key) => {
              o[key] = s[key]
              return o
            }, {})
        )
      }
      throw new Error(
        `Dispatch recieved an unrecognized action '${action}'. Make sure your 'FormAction' component is written properly.`
      )
    }, [])

    const get = (key, value) => {
      if (!key) {
        return serialize(state)
      }
      if (key && !value) return state[key]
      return state[key] && state[key][`_${value}`]
    }

    const isTouched = (key, value) => {
      if (!key) {
        // eslint-disable-next-line no-shadow
        return Object.keys(state).some(key => state[key]['_touched'])
      }
      return !!get(key, 'touched')
    }

    const isDirty = (key, value) => {
      if (!key) {
        // eslint-disable-next-line no-shadow
        return Object.keys(state).some(key => state[key]['_dirty'])
      }
      return !!get(key, 'dirty')
    }

    const set = (...args) => {
      let slice
      let key
      let value = args[0] // in case of single argument
      if (args.length > 1) [key, value] = args //  in case of multiple arguments
      if (key === undefined) slice = get()
      else slice = get(key, 'value')

      if (typeof value === 'function') value = value(slice)

      if (key === undefined && typeof args[0] === 'function') {
        // update whole state
        // throw new Error('we might have a problem here!?')
        dispatch('replace', null, value)
        // setState(() =>
        //   typeof value === 'object'
        //     ? flatten(value, key)
        //     : { [key]: { _value: value } }
        // )
      } else {
        // update state slice
        dispatch('merge', key, value)
      }
    }

    const ctx = {
      init: initField,
      set,
      get,
      dispatch,
      onChange: setValue,
      onBlur: setTouched,
      contextKey: '',
    }

    useImperativeHandle(ref, () => ({
      get,
      set,
      isTouched,
      isDirty,
      htmlRef,
    }))

    // console.log('form: ================================ ')
    // console.log('state: ', state)
    // console.log('flat: ', flatten(serialize(state)))
    // console.log('serialized: ', serialize(state))

    if (HAS_DEBUG_FLAG)
      invariant(
        verifyParse(state),
        `${self} component has detected a bad state structure. This may be due to inconsistent keys (i.e. no object should be targeted as both an 'array' and an 'object' at the same time) or because your 'set' function returned a bad structure.`
      )

    return (
      <form
        ref={htmlRef}
        className={`${classes.wrapper}${className ? ` ${className}` : ''}`}
        onSubmit={onSubmit}
        {...rest}
      >
        <Provider value={ctx}>{children}</Provider>
      </form>
    )
  }
)

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  preload: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default Form
