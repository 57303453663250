/* eslint-disable camelcase */
import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import 'style.scss'

import Conditional from 'components/Conditional'
import AlertDialog from 'components/AlertDialog'

import { TextField, MenuItem, Button } from '@material-ui/core'

import { classes as classNames } from '../../classNames'

const classes = {
  ...classNames,
  stepOne: 'ScoringWizard-step-one',
  noDataMsg: 'ScoringWizard-no-data-msg',
  alternativeDataMsg: 'ScoringWizard-alternative-data-msg',
  alternativeActions: 'ScoringWizard-alternative-actions',
  actionLink: 'ScoringWizard-action-link',
  // pipelineProcessing: 'ScoringWizard-pipelineProcessingWrapper',
  // pipelineOutput: 'ScoringWizard-pipelineOutputWrapper',
  // pipelineError: 'ScoringWizard-pipelineErrorWrapper',
}

export const validator = scoring => {
  return !!scoring.training
}

const ScoringWizardStepOne = props => {
  const [training, setTraining] = useState('')
  const warningDialog = useRef()
  const { scoring } = props
  const { step, training: currentTraining, training_name, datasets } = scoring
  const {
    trainings: all_trainings,
    setScoringWizardTraining,
    initializeTrainingWizard,
  } = useStore(
    'selectTrainings',
    'setScoringWizardTraining',
    'initializeTrainingWizard'
  )

  const trainings =
    all_trainings &&
    all_trainings.filter(
      e =>
        e.status === 'FINISHED' || e.status === 'RUNNING' || e.status === 'STOPPING'
    )

  const trainingsAvailable = trainings ? !!trainings.length : false
  const stepCompleted = validator(scoring)

  const wizardTrainingSetter = () => {
    const { id: trainingID, name } = trainings.find(tr => tr.id === training)
    setScoringWizardTraining(trainingID, name)
  }

  const discardTrainingHandler = () => setScoringWizardTraining(null)

  const selectTrainingHandler = () => {
    if (datasets.length) warningDialog.current.open()
    else wizardTrainingSetter()
  }

  const newTrainingHandler = useCallback(
    () => initializeTrainingWizard(),
    [initializeTrainingWizard]
  )

  return (
    <div className={classes.step} data-active={step === 0}>
      <AlertDialog
        ref={warningDialog}
        title="Confirm?"
        content="Choosing this training will modify the dataset selection, do you want to continue?"
        okText="Yes"
        cancelText="Cancel"
        onOk={event => {
          event.stopPropagation()
          event.preventDefault()
          wizardTrainingSetter()
          warningDialog.current.close()
        }}
        onCancel={event => {
          event.stopPropagation()
          warningDialog.current.close()
        }}
      />
      <div className={classes.progressWrapper}>
        <div className={classes.progressBar} data-completed={stepCompleted} />
      </div>
      <div className={`${classes.stepContent} ${classes.stepOne}`}>
        <h2>Choose a training:</h2>
        <Conditional dependencies={!currentTraining && !trainingsAvailable}>
          <span className={classes.noDataMsg}>No models available.</span>
          <span className={classes.alternativeDataMsg}>
            {'Please run a '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={classes.actionLink}
              /* eslint-disable-next-line no-script-url */
              href="javascript:void(0)"
              onClick={newTrainingHandler}
            >
              new training
            </a>
            {' instead.'}
          </span>
        </Conditional>
        <Conditional dependencies={!currentTraining && trainingsAvailable}>
          <TextField
            disabled={step !== 0}
            select
            value={training}
            label="Please select a training"
            onChange={event => setTraining(event.target.value)}
          >
            {trainings &&
              trainings
                .sort((a, b) => {
                  if (a.name < b.name) return -1
                  if (a.name > b.name) return 1
                  return 0
                })
                .map(tr => (
                  <MenuItem key={tr.id} value={tr.id}>
                    {tr.name}
                  </MenuItem>
                ))}
          </TextField>
          <Conditional dependencies={!currentTraining && training}>
            <Button
              disabled={step !== 0}
              style={{ marginTop: '20px' }}
              color="primary"
              variant="contained"
              onClick={selectTrainingHandler}
            >
              Use this training
            </Button>
          </Conditional>
          <span className={classes.alternativeDataMsg}>
            {'or run a '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={classes.actionLink}
              /* eslint-disable-next-line no-script-url */
              href="javascript:void(0)"
              onClick={newTrainingHandler}
            >
              new training
            </a>
            {' instead.'}
          </span>
        </Conditional>
        <Conditional dependencies={currentTraining}>
          <span className={classes.alternativeDataMsg}>
            {training_name ? `Using ${training_name}.` : 'Using an Unknown model.'}
          </span>
          <div className={classes.alternativeActions}>
            <Button
              disabled={step !== 0}
              variant="outlined"
              onClick={discardTrainingHandler}
            >
              Discard
            </Button>
            <Button
              disabled={step !== 0 || true}
              variant="contained"
              color="primary"
              // onClick={connectPipelineHandler}
            >
              Inspect
            </Button>
          </div>
        </Conditional>

        {/* <Conditional dependencies={pipeline_processing_id}>
          <div className={classes.pipelineProcessing}>
            <Spinner
              spin={1}
              strokeWidth={2}
              mainColor="#1E90FF"
              emptyColor="#2e313a"
            />
          </div>
        </Conditional> */}
        {/* <Conditional dependencies={pipeline_error}>
          <div className={classes.pipelineError}>
            <h3>Pipeline processing has failed.</h3>
            <span>Pleaes edit the pipeline and to fix the error.</span>
          </div>
        </Conditional> */}
        {/* <Conditional dependencies={outputHasGaps(pipeline_output)}>
          <div className={classes.pipelineOutput}>
            <h3>We have detected gaps in the output.</h3>
            <span>Non-objective features must not have gaps,</span>
            <span>please imputate your data.</span>
          </div>
        </Conditional> */}
      </div>
    </div>
  )
}

ScoringWizardStepOne.propTypes = {
  scoring: PropTypes.object.isRequired,
}

export default ScoringWizardStepOne
