import React, { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { useRefRect } from 'libs/utils/hooks'
// import { isObject } from 'libs/utils/helpers'
import { invertColor, getColorFromGradient } from 'libs/utils/color'

import Conditional from 'components/Conditional'
import { CategoryXAxis, CategoryYAxis } from 'components/Charts/Axis'
import { Context } from './ConfusionMatrixChartContext'

import './style.scss'

const classes = {
  wrapper: 'ConfusionMatrixChart-ConfusionMatrixGraph-wrapper',
  graph: 'ConfusionMatrixChart-ConfusionMatrixGraph-graph',
  horizontalAligner: 'ConfusionMatrixChart-ConfusionMatrixGraph-horizontalAligner',
  graphSVG: 'ConfusionMatrixChart-ConfusionMatrixGraph-graphSVG',
  legendLine: 'ConfusionMatrixChart-ConfusionMatrixGraph-legendLine',
  squareGroup: 'ConfusionMatrixChart-ConfusionMatrixGraph-squareGroup',
  rect: 'ConfusionMatrixChart-ConfusionMatrixGraph-rect',
  valueText: 'ConfusionMatrixChart-ConfusionMatrixGraph-valueText',
  percentText: 'ConfusionMatrixChart-ConfusionMatrixGraph-percentText',
}

const viewBoxRange = 1000
const axisSize = 128

const ConfusionMatrixGraph = props => {
  const [hovered, setHovered] = useState(null)
  const [rect, ref] = useRefRect()
  const { data = [], labels = [] } = useContext(Context)

  const {
    fontSize = 18,
    squareSpacing = 3,
    legend,
    mainColor = '#ffffff',
    ...rest
  } = props

  const { style } = rest

  const invertedColor = invertColor(mainColor)

  const {
    position: legendPosition,
    width: legendWidth = 0,
    spacing: legendSpacing = 0,
  } = (legend && legend !== true ? legend : {}) || {}
  const { width = 1, height = 1 } = rect || {}

  const matrixHeight = Math.max(
    Math.min(width - legendWidth - legendSpacing - axisSize, height),
    0
  )
  const matrixWidth = matrixHeight + legendWidth + legendSpacing
  const matrixRatio = (matrixHeight && matrixWidth / matrixHeight) || 1
  const rectRatio = Math.max(width - axisSize, 1) / height
  const viewBox = {
    x: 0,
    y: 0,
    xRange: viewBoxRange * matrixRatio,
    yRange: viewBoxRange,
  }

  let viewBoxRatio = 1
  const wideState = rectRatio > matrixRatio
  if (wideState) viewBoxRatio = viewBox.yRange / height
  else viewBoxRatio = viewBox.xRange / Math.max(width - axisSize, 1)

  const rects = useMemo(() => {
    const spacingSpace = squareSpacing * viewBoxRatio
    const availableSpace = Math.max(
      (data.length && viewBoxRange - (data.length - 1) * spacingSpace) ||
        viewBoxRange,
      0
    )
    const squareSize = (data.length && availableSpace / data.length) || 0
    const compressed = squareSize / viewBoxRatio < fontSize * 2

    const xOffset =
      (legendPosition === 'left' && (legendWidth + legendSpacing) * viewBoxRatio) ||
      0
    return data.reduce((acc, row, i) => {
      const maxRange = row.reduce((sum, cur) => sum + cur)
      const rowSquares = row.map((cell, j) => {
        const relativeValue = cell / maxRange
        const color = getColorFromGradient(
          [
            { color: mainColor, position: 0 },
            { color: invertedColor, position: 1 },
          ],
          relativeValue
        )
        const id = uuid()
        return (
          <g
            // eslint-disable-next-line react/no-array-index-key
            key={`ConfusionMatrix-square-${id}-${i}-${j}`}
            className={classes.squareGroup}
            onMouseEnter={() => setHovered(relativeValue)}
            onMouseLeave={() => setHovered(null)}
          >
            <rect
              // key={`ConfusionMatrix-square-${id}`}
              className={classes.rect}
              x={`${xOffset + j * (squareSize + spacingSpace)}`}
              y={`${i * (squareSize + spacingSpace)}`}
              width={`${squareSize}`}
              height={`${squareSize}`}
              fill={color}
            />
            <Conditional dependencies={!compressed}>
              <text
                // key={`ConfusionMatrix-valueText-${id}`}
                className={classes.valueText}
                // style={{ opacity: compressed ? 0 : 1 }}
                x={`${xOffset + j * (squareSize + spacingSpace) + squareSize / 2}`}
                y={`${
                  i * (squareSize + spacingSpace) +
                  squareSize / 2 -
                  fontSize * viewBoxRatio * 0.2
                }`}
                fontSize={fontSize * viewBoxRatio}
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                {cell}
              </text>
              <text
                // key={`ConfusionMatrix-percentText-${id}`}
                className={classes.percentText}
                // style={{ opacity: compressed ? 0 : 1 }}
                x={`${xOffset + j * (squareSize + spacingSpace) + squareSize / 2}`}
                y={`${
                  i * (squareSize + spacingSpace) +
                  squareSize / 2 +
                  fontSize * viewBoxRatio * 0.6
                }`}
                fontSize={fontSize * viewBoxRatio * 0.75}
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                {`${Math.round(relativeValue * 1000) / 10}%`}
              </text>
            </Conditional>
          </g>
        )
      })
      acc.push(...rowSquares)
      return acc
    }, [])
  }, [
    data,
    squareSpacing,
    legendWidth,
    legendSpacing,
    legendPosition,
    fontSize,
    viewBoxRatio,
    mainColor,
    invertedColor,
  ])

  const matrixVerticalMargin = wideState ? 0 : (height - matrixHeight) / 2
  const matrixHorizontalMargin = wideState ? (width - matrixWidth - axisSize) / 2 : 0

  const matrixHorizontalOffset =
    (legendPosition === 'left'
      ? legendWidth + legendSpacing - axisSize / 2
      : axisSize / 2) + (wideState ? (width - matrixWidth) / 2 : axisSize / 2)

  const gradientID = useMemo(() => uuid(), [])

  return (
    <div className={classes.wrapper} style={{ ...style }}>
      <div className={classes.graph}>
        <Conditional dependencies={rects.length}>
          <div
            ref={ref}
            className={classes.horizontalAligner}
            style={{ flexDirection: legendPosition === 'left' ? 'row-reverse' : '' }}
          >
            <CategoryYAxis
              categories={labels}
              label="Truth"
              fontSize={14}
              fontMargin={16}
              labelSize={12}
              labelMargin={6}
              tickLength={12}
              width={axisSize}
              height={matrixHeight}
              mirror={legendPosition === 'left'}
              normal
              style={{
                marginTop: `${matrixVerticalMargin}px`,
                marginLeft: `${
                  legendPosition === 'left'
                    ? -matrixHorizontalMargin
                    : matrixHorizontalMargin
                }px`,
                marginRight: `${
                  legendPosition === 'left'
                    ? matrixHorizontalMargin
                    : -matrixHorizontalMargin
                }px`,
              }}
            />
            <svg
              className={classes.graphSVG}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.xRange} ${viewBox.yRange}`}
              preserveAspectRatio="xMidYMid meet"
              style={{
                width: 'calc(100% - 7em - 64px)',
              }}
            >
              <defs>
                <linearGradient
                  id={`ConfusionMatrix-LegendGradient-${gradientID}`}
                  x1="0"
                  x2="0"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor={invertedColor} />
                  <stop offset="100%" stopColor={mainColor} />
                </linearGradient>
              </defs>
              <Conditional dependencies={legendWidth}>
                <rect
                  x={
                    legendPosition === 'left'
                      ? '0'
                      : `${viewBoxRange + legendSpacing * viewBoxRatio}`
                  }
                  y="0"
                  width={`${legendWidth * viewBoxRatio}`}
                  height={viewBoxRange}
                  fill={`url(#ConfusionMatrix-LegendGradient-${gradientID})`}
                />
                <Conditional dependencies={hovered}>
                  <line
                    className={classes.legendLine}
                    x1={
                      legendPosition === 'left'
                        ? '0'
                        : `${viewBoxRange + legendSpacing * viewBoxRatio}`
                    }
                    x2={
                      legendPosition === 'left'
                        ? `${legendWidth * viewBoxRatio}`
                        : `${
                            viewBoxRange +
                            (legendSpacing + legendWidth) * viewBoxRatio
                          }`
                    }
                    y1={`${viewBoxRange * (1 - hovered)}`}
                    y2={`${viewBoxRange * (1 - hovered)}`}
                  />
                </Conditional>
              </Conditional>
              {rects}
            </svg>
          </div>
          <CategoryXAxis
            categories={labels}
            label="Prediction"
            fontSize={14}
            fontMargin={16}
            labelSize={12}
            labelMargin={6}
            tickLength={12}
            width={matrixHeight}
            height={axisSize}
            style={{
              marginTop: `${-matrixVerticalMargin || ''}`,
              marginBottom: `${matrixVerticalMargin || ''}`,
              marginLeft: `${matrixHorizontalOffset}px`,
            }}
            normal="left"
          />
        </Conditional>
      </div>
    </div>
  )
}

ConfusionMatrixGraph.propTypes = {
  fontSize: PropTypes.number,
  squareSpacing: PropTypes.number,
  legend: PropTypes.shape({
    position: PropTypes.oneOf(['left', 'right']),
    width: PropTypes.number,
    spacing: PropTypes.number,
  }),
  mainColor: PropTypes.string,
}

export default ConfusionMatrixGraph
