/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import { useRefs } from 'libs/utils/hooks'

import Icon from 'components/Icon'

import { PipelineBox } from './Boxes'
import { Provider, Context } from './PipelineContext'

const classes = {
  wrapper: 'Branch-Node-wrapper',
  box: 'Branch-Node-box',
  addBoxButton: 'Branch-Node-addBoxButton',
  addBoxOptionList: 'Branch-Node-addBoxOptionList',
  addBoxOption: 'Branch-Node-addBoxOption',
}

const AddBoxButton = ({ boxType, stringLocation, dataType = 'basedata' }) => {
  const [getRef, setRef] = useRefs()
  const { insertPipelineBox: insertBox } = useStore('insertPipelineBox')
  const isMapping = boxType === 'mapping'
  const location = stringLocation.split('.').map(string => Number(string))
  const boxList = [
    {
      type: 'join',
      spec: {
        type: 'join',
        config: {
          method: 'inner',
          left: [],
          right: [],
        },
        locationArray: location,
      },
    },
    {
      type: 'filter',
      spec: {
        type: 'filter',
        config: { rules: [] },
        locationArray: location,
      },
    },
    {
      type: 'imputation',
      spec: {
        type: 'imputation',
        config: { rules: [] },
        locationArray: location,
      },
    },
    {
      type: 'sanitation',
      spec: {
        type: 'sanitation',
        config: { rules: [] },
        locationArray: location,
      },
    },
    {
      type: 'calculation',
      spec: {
        type: 'calculation',
        config: { rules: [] },
        locationArray: location,
      },
    },
  ]
  if (dataType !== 'basedata')
    boxList.push({
      type: 'resample',
      spec: {
        type: 'resample',
        config: {},
        locationArray: location,
      },
    })

  return !isMapping ? (
    <div className={classes.addBoxButton} onClick={event => event.stopPropagation()}>
      <span>+</span>
      <div className={classes.addBoxOptionList}>
        {boxList.map(({ type, spec }) => {
          return (
            <button
              ref={setRef(`${stringLocation}-${type}`)}
              key={type}
              className={classes.addBoxOption}
              type="button"
              onClick={() => {
                getRef(`${stringLocation}-${type}`)?.current?.blur()
                insertBox({ location, spec })
              }}
            >
              <Icon
                type={type}
                style={{ transform: type === 'join' ? 'rotate(180deg)' : '' }}
              />
              <div>{type.charAt(0).toUpperCase() + type.slice(1)}</div>
            </button>
          )
        })}
      </div>
    </div>
  ) : null
}

const Node = props => {
  const boxRef = useRef()
  const {
    activatePipelineBox: selectBox,
    pipelineDatasetArray: datasetArray,
    pipelineConfiguringBoxLocation: configuring,
    pipelineActiveBoxLocation: selected,
  } = useStore(
    'activatePipelineBox',
    'selectPipelineDatasetArray',
    'selectPipelineConfiguringBoxLocation',
    'selectPipelineActiveBoxLocation'
  )

  const parentContextData = useContext(Context)
  // const { addBox } = parentContextData

  const { spec, meta, padding = 3, size = 7.5 } = props
  const { type, locationArray = [] } = spec
  const ready = (meta && meta.ready) || false

  const isDatasetSlot = useMemo(() => {
    return (
      type === 'dataset' &&
      (spec.datasetArrayIndex || spec.datasetArrayIndex === 0) &&
      !datasetArray[spec.datasetArrayIndex]
    )
  }, [type, spec, datasetArray])

  const amISelected = useMemo(() => {
    return locationArray.join('') === (selected && selected.join(''))
  }, [locationArray, selected])

  const outputDataType = meta?.stats?.type || 'basedata'
  const stringLocation = locationArray.join('.')

  useEffect(() => {
    if (!ready && amISelected) selectBox({ location: null })
  }, [ready, amISelected, selectBox])

  const contextData = { ...parentContextData, wrapper: boxRef }

  return (
    <div
      className={classes.wrapper}
      style={{ paddingBottom: `${padding}em` }}
      tabIndex={-1}
    >
      <AddBoxButton
        boxType={type}
        stringLocation={stringLocation}
        dataType={outputDataType}
      />
      <div
        ref={boxRef}
        className={classes.box + (amISelected ? ' selected' : '')}
        role="button"
        tabIndex={!isDatasetSlot && !configuring && !amISelected && ready ? 0 : -1}
        style={{
          height: `${size}em`,
          width: `${size}em`,
          zIndex:
            locationArray.join('') === (configuring && configuring.join(''))
              ? 3
              : '',
        }}
        onClick={event => {
          event.stopPropagation()
          !isDatasetSlot &&
            !configuring &&
            !amISelected &&
            ready &&
            selectBox({ location: locationArray })
        }}
        onKeyDown={event => {
          event.stopPropagation()
          if (
            event.keyCode === 13 &&
            !isDatasetSlot &&
            !configuring &&
            !amISelected &&
            ready
          ) {
            selectBox({ location: locationArray })
          }
        }}
      >
        <Provider value={contextData}>
          <PipelineBox spec={spec} meta={meta} />
        </Provider>
      </div>
    </div>
  )
}

Node.propTypes = {
  spec: PropTypes.object,
  meta: PropTypes.object,
  padding: PropTypes.number,
  size: PropTypes.number,
}
AddBoxButton.propTypes = {
  boxType: PropTypes.string,
  stringLocation: PropTypes.string,
  dataType: PropTypes.string,
}

export default Node
