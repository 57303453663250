/* eslint-disable react/no-array-index-key */
import React, { useState, useRef } from 'react'
// import { SplitLayout } from 'components/Layout'
import {
  Form,
  FormItem,
  FormFor,
  FormConditional,
  FormContext,
  FormAction,
} from 'components/Form'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Switch,
  MenuItem,
  Typography,
  Slider,
  Select,
} from '@material-ui/core'
// import { MultiSelect } from 'components/MultiSelect'
import { PopUpMultiSelect } from 'components/PopUpMultiSelect'

import './style.scss'

const classes = {
  wrapper: 'Dev-wrapper',
  test: 'Dev-test',
}

const names = [
  { id: 0, name: 'Oliver' },
  { id: 1, name: 'Van' },
  { id: 2, name: 'April Tucker' },
  { id: 3, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 4, name: 'Omar Alexander' },
  { id: 5, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 6, name: 'Miriam Wagner' },
  { id: 7, name: 'Bradley Wilkerson' },
  { id: 8, name: 'Virginia Andrews' },
  { id: 9, name: 'Kelly Snyder' },
  { id: 10, name: 'Oliver' },
  { id: 11, name: 'Van' },
  { id: 12, name: 'April Tucker' },
  { id: 13, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 14, name: 'Omar Alexander' },
  { id: 15, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 16, name: 'Miriam Wagner' },
  { id: 17, name: 'Bradley Wilkerson' },
  { id: 18, name: 'Virginia Andrews' },
  { id: 19, name: 'Kelly Snyder' },
  { id: 20, name: 'Oliver' },
  { id: 21, name: 'Van' },
  { id: 22, name: 'April Tucker' },
  { id: 23, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 24, name: 'Omar Alexander' },
  { id: 25, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 26, name: 'Miriam Wagner' },
  { id: 27, name: 'Bradley Wilkerson' },
  { id: 28, name: 'Virginia Andrews' },
  { id: 29, name: 'Kelly Snyder' },
  { id: 30, name: 'Oliver' },
  { id: 31, name: 'Van' },
  { id: 32, name: 'April Tucker' },
  { id: 33, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 34, name: 'Omar Alexander' },
  { id: 35, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 36, name: 'Miriam Wagner' },
  { id: 37, name: 'Bradley Wilkerson' },
  { id: 38, name: 'Virginia Andrews' },
  { id: 39, name: 'Kelly Snyder' },
  { id: 40, name: 'Oliver' },
  { id: 41, name: 'Van' },
  { id: 42, name: 'April Tucker' },
  { id: 43, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 44, name: 'Omar Alexander' },
  { id: 45, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 46, name: 'Miriam Wagner' },
  { id: 47, name: 'Bradley Wilkerson' },
  { id: 48, name: 'Virginia Andrews' },
  { id: 49, name: 'Kelly Snyder' },
  { id: 50, name: 'Oliver' },
  { id: 51, name: 'Van' },
  { id: 52, name: 'April Tucker' },
  { id: 53, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 54, name: 'Omar Alexander' },
  { id: 55, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 56, name: 'Miriam Wagner' },
  { id: 57, name: 'Bradley Wilkerson' },
  { id: 58, name: 'Virginia Andrews' },
  { id: 59, name: 'Kelly Snyder' },
  { id: 60, name: 'Oliver' },
  { id: 61, name: 'Van' },
  { id: 62, name: 'April Tucker' },
  { id: 63, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 64, name: 'Omar Alexander' },
  { id: 65, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 66, name: 'Miriam Wagner' },
  { id: 67, name: 'Bradley Wilkerson' },
  { id: 68, name: 'Virginia Andrews' },
  { id: 69, name: 'Kelly Snyder' },
  { id: 70, name: 'Oliver' },
  { id: 71, name: 'Van' },
  { id: 72, name: 'April Tucker' },
  { id: 73, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 74, name: 'Omar Alexander' },
  { id: 75, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 76, name: 'Miriam Wagner' },
  { id: 77, name: 'Bradley Wilkerson' },
  { id: 78, name: 'Virginia Andrews' },
  { id: 79, name: 'Kelly Snyder' },
  { id: 80, name: 'Oliver' },
  { id: 81, name: 'Van' },
  { id: 82, name: 'April Tucker' },
  { id: 83, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 84, name: 'Omar Alexander' },
  { id: 85, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 86, name: 'Miriam Wagner' },
  { id: 87, name: 'Bradley Wilkerson' },
  { id: 88, name: 'Virginia Andrews' },
  { id: 89, name: 'Kelly Snyder' },
  { id: 90, name: 'Oliver' },
  { id: 91, name: 'Van' },
  { id: 92, name: 'April Tucker' },
  { id: 93, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 94, name: 'Omar Alexander' },
  { id: 95, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 96, name: 'Miriam Wagner' },
  { id: 97, name: 'Bradley Wilkerson' },
  { id: 98, name: 'Virginia Andrews' },
  { id: 99, name: 'Kelly Snyder' },
  { id: 100, name: 'Oliver' },
  { id: 101, name: 'Van' },
  { id: 102, name: 'April Tucker' },
  { id: 103, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 104, name: 'Omar Alexander' },
  { id: 105, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 106, name: 'Miriam Wagner' },
  { id: 107, name: 'Bradley Wilkerson' },
  { id: 108, name: 'Virginia Andrews' },
  { id: 109, name: 'Kelly Snyder' },
  { id: 110, name: 'Oliver' },
  { id: 111, name: 'Van' },
  { id: 112, name: 'April Tucker' },
  { id: 113, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 114, name: 'Omar Alexander' },
  { id: 115, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 116, name: 'Miriam Wagner' },
  { id: 117, name: 'Bradley Wilkerson' },
  { id: 118, name: 'Virginia Andrews' },
  { id: 119, name: 'Kelly Snyder' },
  { id: 120, name: 'Oliver' },
  { id: 121, name: 'Van' },
  { id: 122, name: 'April Tucker' },
  { id: 123, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 124, name: 'Omar Alexander' },
  { id: 125, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 126, name: 'Miriam Wagner' },
  { id: 127, name: 'Bradley Wilkerson' },
  { id: 128, name: 'Virginia Andrews' },
  { id: 129, name: 'Kelly Snyder' },
  { id: 130, name: 'Oliver' },
  { id: 131, name: 'Van' },
  { id: 132, name: 'April Tucker' },
  { id: 133, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 134, name: 'Omar Alexander' },
  { id: 135, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 136, name: 'Miriam Wagner' },
  { id: 137, name: 'Bradley Wilkerson' },
  { id: 138, name: 'Virginia Andrews' },
  { id: 139, name: 'Kelly Snyder' },
  { id: 140, name: 'Oliver' },
  { id: 141, name: 'Van' },
  { id: 142, name: 'April Tucker' },
  { id: 143, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 144, name: 'Omar Alexander' },
  { id: 145, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 146, name: 'Miriam Wagner' },
  { id: 147, name: 'Bradley Wilkerson' },
  { id: 148, name: 'Virginia Andrews' },
  { id: 149, name: 'Kelly Snyder' },
  { id: 150, name: 'Oliver' },
  { id: 151, name: 'Van' },
  { id: 152, name: 'April Tucker' },
  { id: 153, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 154, name: 'Omar Alexander' },
  { id: 155, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 156, name: 'Miriam Wagner' },
  { id: 157, name: 'Bradley Wilkerson' },
  { id: 158, name: 'Virginia Andrews' },
  { id: 159, name: 'Kelly Snyder' },
  { id: 160, name: 'Oliver' },
  { id: 161, name: 'Van' },
  { id: 162, name: 'April Tucker' },
  { id: 163, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 164, name: 'Omar Alexander' },
  { id: 165, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 166, name: 'Miriam Wagner' },
  { id: 167, name: 'Bradley Wilkerson' },
  { id: 168, name: 'Virginia Andrews' },
  { id: 169, name: 'Kelly Snyder' },
  { id: 170, name: 'Oliver' },
  { id: 171, name: 'Van' },
  { id: 172, name: 'April Tucker' },
  { id: 173, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 174, name: 'Omar Alexander' },
  { id: 175, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 176, name: 'Miriam Wagner' },
  { id: 177, name: 'Bradley Wilkerson' },
  { id: 178, name: 'Virginia Andrews' },
  { id: 179, name: 'Kelly Snyder' },
  { id: 180, name: 'Oliver' },
  { id: 181, name: 'Van' },
  { id: 182, name: 'April Tucker' },
  { id: 183, name: 'Ralph Hubbardaaaaaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 184, name: 'Omar Alexander' },
  { id: 185, name: 'Carlos Abbottaaaaaaaaaaaaaaaaaaaaaa' },
  { id: 186, name: 'Miriam Wagner' },
  { id: 187, name: 'Bradley Wilkerson' },
  { id: 188, name: 'Virginia Andrews' },
  { id: 189, name: 'Kelly Snyder' },
]

const Dev = props => {
  // const modalRef = useRef()
  const formRef = useRef()
  return (
    <div>
      <div className={classes.wrapper}>
        {/* <button
          type="button"
          onClick={() => {
            modalRef.current.open()
          }}
          style={{
            color: 'white',
            fontWeight: '300',
            backgroundColor: '#3377ff',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '2px',
            cursor: 'pointer',
            marginTop: '20%',
          }}
        >
          Create new training
        </button> */}
        {/* <TrainingModal ref={modalRef} /> */}
        <button
          type="button"
          onClick={() => console.log('MODEL', formRef.current.get())}
        >
          MultiSelect model log
        </button>
        <select multiple style={{ height: '300px' }}>
          <option value="volvo">Volvo</option>
          <option value="saab">Saab</option>
          <option value="fiat">Fiat</option>
          <option value="audi">Audi</option>
          <option value="volvo">Volvo</option>
          <option value="saab">Saab</option>
          <option value="fiat">Fiat</option>
          <option value="audi">Audi</option>
          <option value="volvo">Volvo</option>
          <option value="saab">Saab</option>
          <option value="fiat">Fiat</option>
          <option value="audi">Audi</option>
        </select>
      </div>
      <Form ref={formRef}>
        <FormItem
          dataKey="multi"
          defaultValue="[0, 1]"
          value={e => e}
          fullWidth
          component={PopUpMultiSelect}
        >
          {names.map(person => (
            <MenuItem key={person.id} value={person.id}>
              {person.name}
            </MenuItem>
          ))}
        </FormItem>
      </Form>
    </div>
  )
}

export default Dev
