import React, { useMemo, useEffect } from 'react'
import { useStore } from 'core'
import {
  ThemeProvider as MUIThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles'

import PropTypes from 'prop-types'

const ThemeProvider = props => {
  const { theme = 'dark' } = useStore('selectTheme')
  const { children } = props

  const MUITheme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: theme,
          primary: { main: '#3fa9f5' },
        },
        shape: {
          borderRadius: 2,
        },
      }),
    [theme]
  )

  useEffect(() => {
    const newTheme = `theme-${theme}`
    const oldTheme = `theme-${theme === 'dark' ? 'light' : 'dark'}`
    document.body.classList.remove(oldTheme)
    document.body.classList.add(newTheme)
    // document.getElementById('root').classList.remove(oldTheme)
    // document.getElementById('root').classList.add(newTheme)
  }, [theme])

  return <MUIThemeProvider theme={MUITheme}>{children}</MUIThemeProvider>
}
ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ThemeProvider
