import React, { useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import { formatDate } from 'libs/utils/helpers'

import Icon from 'components/Icon'
import { SimpleResourceDialog } from 'components/ResourceModalComponents'
import { Tooltip } from '@material-ui/core'
import TrainingDetails from './TrainingDetails'

import './style.scss'

const classes = {
  wrapper: 'TrainingTableEntry-wrapper',
  cell: 'TrainingTableEntry-cell',
  name: 'TrainingTableEntry-name',
  date: 'TrainingTableEntry-date',
  status: 'TrainingTableEntry-status',
  actions: 'TrainingTableEntry-actions',
  actionButton: 'TrainingTableEntry-actionButton',
}

const TrainingTableEntry = props => {
  const { deleteTraining, setSelectedTraining, setInspectedTraining } = useStore(
    'deleteTraining',
    'setSelectedTraining',
    'setInspectedTraining'
  )
  const dialogRef = useRef()

  const { id, name = '', status = '', date = '', original, selected } = props

  const [onClick, onDoubleClick] = useMemo(() => {
    // const timer = null
    const tempSingle = () => {
      // event.persist()
      // timer = setTimeout(() => {
      //   setSelectedTraining(id)
      // }, 300)
      setSelectedTraining(id)
    }
    const tempDouble = () => {
      setInspectedTraining(id)
      // clearTimeout(timer)
    }
    return [tempSingle, tempDouble]
  }, [id, setSelectedTraining, setInspectedTraining])

  return (
    <>
      <SimpleResourceDialog
        ref={dialogRef}
        titleText="Do you want to delete this training?"
        contentText="This action will permanently delete this training."
        okFunction={() => deleteTraining(id)}
      />
      <div
        className={`${classes.wrapper}${selected ? ' selected' : ''}`}
        role="button"
        tabIndex={0}
        onDoubleClick={onDoubleClick}
        onKeyDown={event => {
          if (event.keyCode === 13) onClick(event)
        }}
        onClick={onClick}
      >
        <Tooltip title={name}>
          <div className={`${classes.cell} ${classes.name}`}>
            <span>{name}</span>
          </div>
        </Tooltip>
        <Tooltip title={status}>
          <div className={`${classes.cell} ${classes.status}`}>
            <span>{status}</span>
          </div>
        </Tooltip>
        <div className={`${classes.cell} ${classes.date}`}>
          <span>{formatDate(date)}</span>
        </div>
        <div className={`${classes.cell} ${classes.actions}`}>
          <button
            type="button"
            className={classes.actionButton}
            onClick={event => {
              event.stopPropagation()
              dialogRef.current.open()
            }}
            onKeyDown={event => {
              event.stopPropagation()
              if (event.keyCode === 13) {
                dialogRef.current.open()
              }
            }}
          >
            <Icon type="trash" />
          </button>
        </div>
        <TrainingDetails training={original} />
      </div>
    </>
  )
}

TrainingTableEntry.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  status: PropTypes.string,
  date: PropTypes.string,
  original: PropTypes.any,
  selected: PropTypes.bool,
}

export default TrainingTableEntry
