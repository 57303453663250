/* eslint-disable max-lines */
import React, { forwardRef, useMemo, useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import { extractValue, formatDate } from 'libs/utils/helpers'
import { useInteractiveClick } from 'libs/interactive'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import AlertDialog from 'components/AlertDialog'
import Modal from 'components/Modal'
import { Table, Column } from 'components/Table'
import Spinner from 'components/Spinner'
import { Tooltip } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import MuiDialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
// import ConfigBox from './ConfigBox'
import ConfigModal from './ConfigModal'

import './PipelineBoxStyle.scss'

const classes = {
  wrapper: 'PipelineBox-wrapper',
  type: 'PipelineBox-type',
  statusDiv: 'PipelineBox-statusDiv',
  spinnerDiv: 'PipelineBox-spinnerDiv',
  actionsDiv: 'PipelineBox-actionsDiv',
  clearDatasetButton: 'PipelineBox-clearDatasetButton',
  removeBoxButton: 'PipelineBox-removeBoxButton',
  swapBranchesButton: 'PipelineBox-swapBranchesButton',
  configureButton: 'PipelineBox-configureButton',
  downloadButton: 'PipelineBox-downloadButton',
}

const tableKeys = ['name', 'updated_at', 'type', 'size', 'status', 'spec.type', 'id']
const widths = [
  { key: 'name', width: 250 },
  { key: 'updated_at', width: 200 },
  { key: 'type', width: 180 },
  { key: 'size', width: 120 },
  { key: 'status', width: 150 },
  { key: 'spec.type', width: 180 },
]
const titles = [
  { key: 'name', title: 'Name' },
  { key: 'updated_at', title: 'Last updated' },
  { key: 'type', title: 'Source' },
  { key: 'size', title: 'File size' },
  { key: 'status', title: 'Status' },
  { key: 'spec.type', title: 'Type' },
]
const getWidthBasedOnKey = key => widths.find(e => e.key === key)?.width || 150
const getTitleBasedOnKey = key => titles.find(e => e.key === key)?.title || ''
const tableData = datasets => {
  const tempDatasets =
    datasets?.map(dataset => {
      const mappedDataset = {}
      tableKeys.forEach(key => {
        const tempKey = key.split('.')
        if (tempKey.length === 1) mappedDataset[tempKey[0]] = dataset[tempKey[0]]
        else
          tempKey.reduce((acc, cur, i) => {
            if (i < tempKey.length - 1) {
              if (!acc[cur]) acc[cur] = {}
              return acc[cur]
            }
            acc[cur] = extractValue(dataset, key)
            return acc
          }, mappedDataset)
      })
      return mappedDataset
    }) || []
  return tempDatasets.filter(dataset => dataset?.status === 'READY') || []
}
const ChooseDatasetModal = forwardRef(({ callback }, ref) => {
  const dialogRef = useRef()
  const [record, setRecord] = useState(null)
  const { datasets } = useStore('selectDatasets')
  return (
    <>
      <Modal
        ref={ref}
        title="Select Dataset"
        onCancel={() => {
          ref.current.close()
        }}
      >
        <Table
          data={tableData(datasets)}
          prefKey="pipelineChooseDatasetTable"
          onRow={e => {
            setRecord(e)
            ref.current.close()
            if (
              datasets.find(dataset => dataset.id === e.id)?.spec
                ?.default_transformation
            )
              dialogRef.current.open()
            else {
              const datasetSpec =
                datasets.find(dataset => dataset.id === e.id)?.spec || {}
              const { stacked_by: stackedBy } = datasetSpec
              callback(e.id, stackedBy)
            }
          }}
        >
          {tableKeys.map(key => (
            <Column
              key={`ChooseDatasetTableColumn-${'name'}`}
              dataKey={key}
              title={getTitleBasedOnKey(key)}
              width={getWidthBasedOnKey(key)}
              sortFunction={(a, b) => {
                const aVal = extractValue(a, key)
                const bVal = extractValue(b, key)
                let result = 0
                if (typeof aVal === 'string' || typeof bVal === 'string') {
                  result = `${aVal}`.localeCompare(`${bVal}`, 'en', {
                    sensitivity: 'base',
                  })
                } else result = aVal - bVal
                return result
              }}
              hidden={key === 'id'}
              render={(e, eHeader, eRecord, eFullData, eKey) => {
                return eKey === 'updated_at' && !eHeader ? formatDate(e) : e
              }}
            />
          ))}
        </Table>
      </Modal>
      <AlertDialog
        ref={dialogRef}
        title={`Do you want to insert the default pipeline of "${
          record ? record.name : ''
        }" dataset?`}
        content="This action will create one or more default boxes with preset configuration downstream."
        onClose={() => {
          dialogRef.current.close()
        }}
        onOk={() => {
          const datasetSpec = datasets.find(e => e.id === record.id)?.spec || {}
          const { stacked_by: stackedBy } = datasetSpec
          const defaultTransformation = [
            ...(datasetSpec?.default_transformation || []),
          ]
          callback(record.id, stackedBy, true, defaultTransformation)
          dialogRef.current.close()
        }}
        onCancel={() => {
          const datasetSpec = datasets.find(e => e.id === record.id)?.spec || {}
          const { stacked_by: stackedBy } = datasetSpec
          callback(record.id, stackedBy)
          dialogRef.current.close()
        }}
      />
    </>
  )
})
const PipelineBox = ({ spec, meta }) => {
  const alertRef1 = useRef()
  const alertRef2 = useRef()
  const modalRef = useRef()
  const [open, setOpen] = useState(false)
  const {
    pipelineDatasetArray: datasetArray = [],
    pipelineConfiguringBoxLocation: configuring,
    datasets,
    chooseDataset,
    removePipelineBox: removeBox,
    swapPipelineBranches: swapBranches,
    configurePipelineBox: configure,
    downloadDataset,
  } = useStore(
    'selectPipelineDatasetArray',
    'selectPipelineConfiguringBoxLocation',
    'selectDatasets',
    'chooseDataset',
    'removePipelineBox',
    'swapPipelineBranches',
    'configurePipelineBox',
    'downloadDataset'
  )
  const { locationArray: location = [], type, datasetArrayIndex } = spec
  const stringLocation = location.join('.')
  const metadata = meta?.metadata?.length && meta.metadata
  const config = spec.config || {}
  // const amIBeingConfigured =
  //   (configuring && configuring.join('.')) === stringLocation
  const error = meta?.error || null
  const hasValidMeta = !!metadata?.reduce(
    (acc, cur) => acc && !!cur.type && !!cur.features?.length,
    true
  )
  const ready = hasValidMeta ? meta.ready : false
  const stackedBy =
    (hasValidMeta && metadata[0].stacked_by) || spec?.stackedBy || null
  const isTypeDataset = type === 'dataset'
  const isTypeMapping = type === 'mapping'
  const isVariationSlot =
    isTypeDataset &&
    (datasetArrayIndex || datasetArrayIndex === 0) &&
    !datasetArray[datasetArrayIndex]

  const chooseDatasetCallback = useCallback(
    (id, stacked, insertDefaultPipeline, defaultTransformation) => {
      const loc = stringLocation.split('.').map(string => Number(string))
      if (insertDefaultPipeline) {
        let count = 0
        defaultTransformation.reverse()
        const defaultPipeline =
          defaultTransformation.map(box => {
            const locationArray = [...loc]
            const lastLoc = locationArray.pop()
            locationArray.push(lastLoc + count)
            count += 1
            return { config: { rules: [] }, ...box, locationArray }
          }) || null
        const locationArray = [...loc]
        const lastLoc = locationArray.pop()
        locationArray.push(lastLoc + count)
        chooseDataset({
          datasetArrayIndex: spec.datasetArrayIndex,
          datasetID: id,
          location: loc,
          spec: {
            ...spec,
            config: { rules: [] },
            locationArray: loc,
            stackedBy: stacked,
          },
          defaultPipeline,
        })
      } else {
        chooseDataset({
          datasetArrayIndex,
          datasetID: id,
          location: loc,
          spec: {
            ...spec,
            config: { rules: [] },
            locationArray: loc,
            stackedBy: stacked,
          },
        })
      }
    },
    [chooseDataset, datasetArrayIndex, stringLocation, spec]
  )
  const openRef = useCallback((event, ref) => {
    event.stopPropagation()
    ref.current.open()
  }, [])
  const { onClick: openRefOnClick, onKeyDown: openRefOnKeyDown } =
    useInteractiveClick(openRef)

  // const statusText =
  //   (!hasValidMeta && 'invalid meta') || error || (!ready && 'processing...')

  const statusText =
    (!hasValidMeta && 'invalid meta') ||
    (error && ' ') ||
    (!ready && 'processing...')

  let status = null
  if (!ready) status = 'not-ready'

  let classNameAppendage = ''
  if (error) classNameAppendage = ' error'
  else if (!hasValidMeta) classNameAppendage = ' invalid'
  else if (status) classNameAppendage = ` ${status}`

  const spin = hasValidMeta && !ready && !error ? 1 : 0
  const spinnerFill =
    (!hasValidMeta && '#444444') ||
    (!!error && '#FF0000') ||
    (ready && '#00FF00') ||
    ''

  const spinner = useMemo(() => {
    return (
      <Spinner
        key={stringLocation}
        spin={spin}
        strokeWidth={2}
        mainColor="#1E90FF"
        emptyColor="#2e313a"
        fillColor={spinnerFill}
      />
    )
  }, [stringLocation, spin, spinnerFill])

  return (
    <>
      <Tooltip title={error || statusText || 'ready'} placement="left">
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className={classes.wrapper + (!isVariationSlot ? classNameAppendage : '')}
          role={isTypeDataset ? 'button' : ''}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={isVariationSlot ? 0 : -1}
          onClick={event => {
            if (isVariationSlot) openRef(event, modalRef)
          }}
          onKeyDown={event => {
            if (isVariationSlot && event.keyCode === 13) openRef(event, modalRef)
          }}
          box-type={(isTypeDataset && 'dataset') || ''}
          box-variation={(isVariationSlot && 'slot') || ''}
        >
          <Icon
            type={isVariationSlot ? 'slot' : type}
            style={{ transform: type === 'join' ? 'rotate(180deg)' : '' }}
          />
          <div className={classes.type}>
            <Conditional
              dependencies={
                isTypeDataset && !isVariationSlot && datasetArray[datasetArrayIndex]
              }
            >
              {datasets?.find(
                dataset => dataset.id === datasetArray[datasetArrayIndex]
              )?.name || 'Invalid Dataset'}
            </Conditional>
            <Conditional dependencies={isVariationSlot}>Choose Dataset</Conditional>
            <Conditional dependencies={!isTypeDataset}>
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </Conditional>
          </div>
          <Conditional dependencies={!isVariationSlot}>
            <div className={classes.statusDiv}>
              {statusText}
              <div className={classes.spinnerDiv}>{spinner}</div>
            </div>
          </Conditional>
          <Conditional dependencies={!configuring}>
            <div className={classes.actionsDiv}>
              <Conditional dependencies={isTypeDataset && !isVariationSlot}>
                <div
                  className={classes.clearDatasetButton}
                  role="button"
                  tabIndex={0}
                  onClick={event => openRefOnClick(event, alertRef1)}
                  onKeyDown={event => openRefOnKeyDown(event, alertRef1)}
                >
                  <Icon type="subtract" />
                </div>
              </Conditional>
              <Conditional dependencies={!isTypeDataset && !isTypeMapping}>
                <div
                  className={classes.removeBoxButton}
                  role="button"
                  tabIndex={0}
                  onClick={event => {
                    if (type !== 'join') openRefOnClick(event, alertRef2)
                    else {
                      event.stopPropagation()
                      setOpen(true)
                    }
                  }}
                  onKeyDown={event => {
                    if (type !== 'join') openRefOnClick(event, alertRef2)
                    else if (event.keyCode === 13) {
                      event.stopPropagation()
                      setOpen(true)
                    }
                  }}
                >
                  <Icon type="trash" />
                </div>
              </Conditional>
              <Conditional dependencies={type === 'join'}>
                <div
                  className={classes.swapBranchesButton}
                  role="button"
                  tabIndex={0}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) {
                      swapBranches({ location })
                    }
                  }}
                  onClick={event => {
                    event.stopPropagation()
                    swapBranches({ location })
                  }}
                >
                  <Icon type="swap" />
                </div>
              </Conditional>
              <Conditional dependencies={hasValidMeta}>
                <div
                  className={classes.configureButton}
                  role="button"
                  tabIndex={0}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) {
                      configure({ location })
                    }
                  }}
                  onClick={event => {
                    event.stopPropagation()
                    configure({ location })
                  }}
                >
                  <Icon type="gear" />
                </div>
              </Conditional>
              <Conditional dependencies={ready}>
                <div
                  className={classes.downloadButton}
                  role="button"
                  tabIndex={0}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) {
                      downloadDataset({ id: meta.hash_out })
                    }
                  }}
                  onClick={event => {
                    event.stopPropagation()
                    downloadDataset({ id: meta.hash_out })
                  }}
                >
                  <Icon type="download" />
                </div>
              </Conditional>
            </div>
          </Conditional>
        </div>
      </Tooltip>
      <AlertDialog
        ref={alertRef1}
        title="Do you want to clear this dataset selection?"
        content="Clearing this selection will make changes in the pipeline configuration."
        onOk={event => {
          event.stopPropagation()
          removeBox({ location })
          alertRef1.current.close()
        }}
        onCancel={event => {
          event.stopPropagation()
          alertRef1.current.close()
        }}
      />
      <AlertDialog
        ref={alertRef2}
        title="Do you want to delete this box?"
        content="Deleting this box will make changes in the pipeline configuration."
        onOk={event => {
          event.stopPropagation()
          removeBox({ location })
          alertRef2.current.close()
        }}
        onCancel={event => {
          event.stopPropagation()
          alertRef2.current.close()
        }}
      />
      <Dialog
        classes={{ root: 'AlertDialog' }}
        onKeyDown={event => {
          event.stopPropagation()
          if (event.keyCode === 27) setOpen(false)
        }}
        open={open}
      >
        <DialogTitle classes={{ root: 'AlertDialog-title' }}>
          <Typography>Do you want to delete this box?</Typography>
        </DialogTitle>
        <MuiDialogContent>
          <DialogContentText>
            Deleting this box will make changes in the pipeline configuration.
          </DialogContentText>
        </MuiDialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              removeBox({ location, keepLeft: true })
              setOpen(false)
            }}
            color="primary"
            variant="contained"
          >
            Keep Left
          </Button>
          <Button onClick={() => setOpen(false)} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => {
              removeBox({ location, keepLeft: false })
              setOpen(false)
            }}
            color="primary"
            variant="contained"
          >
            Keep Right
          </Button>
        </DialogActions>
      </Dialog>
      <Conditional dependencies={isTypeDataset}>
        <ChooseDatasetModal ref={modalRef} callback={chooseDatasetCallback} />
      </Conditional>
      <ConfigModal
        type={type}
        location={stringLocation}
        config={config}
        meta={metadata}
        stackedBy={stackedBy}
      />
    </>
  )
}

PipelineBox.propTypes = {
  spec: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

ChooseDatasetModal.propTypes = {
  callback: PropTypes.func,
}

ConfigModal.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  config: PropTypes.object,
  meta: PropTypes.arrayOf(PropTypes.object),
  stackedBy: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
}

export default PipelineBox
