import React from 'react'
import PropTypes from 'prop-types'
import { useRefRect } from 'libs/utils/hooks'

import Conditional from 'components/Conditional'

import './style.scss'

const classes = {
  wrapper: 'XAxis-wrapper',
  label: 'Axis-label',
  line: 'Axis-line',
  text: 'Axis-text',
}

const XAxis = props => {
  const {
    points,
    label,
    width,
    height,
    fontSize = 12,
    labelSize = 14,
    fontSpacing = 14,
    fontMargin = 10,
    labelMargin = 15,
    tickLength = 8,
    render,
    mirror,
    minWidth,
    maxWidth,
    onScroll,
    style,
  } = props
  const [rect, container] = useRefRect()

  const xMin = 0
  const xRange = 1000
  const ratio = rect ? xRange / rect.width : 1
  const yRange = rect ? rect.height * ratio : xRange
  let yMin = -yRange
  const textSize = fontSize * ratio
  const labelTextSize = labelSize * ratio
  const textSpacing = fontSpacing * ratio
  let textMargin = -fontMargin * ratio
  let labelTextMargin = -labelMargin * ratio
  let lineLength = -tickLength * ratio

  if (!mirror) {
    yMin = 0
    textMargin *= -1
    labelTextMargin *= -1
    lineLength *= -1
  }

  const sortedPoints = points.sort((a, b) => a.position - b.position)
  const { points: filteredPoints } = sortedPoints.reduce(
    (acc, cur, i) => {
      const distance = (cur.position - acc.lastShownPosition) * xRange
      // console.log(distance)
      const distToEnd = (1 - cur.position) * xRange
      let curValue =
        distance > textSpacing && distToEnd > textSpacing ? cur.value : null
      curValue = i === 0 ? cur.value : curValue
      const result = { ...acc }
      result.points.push({ ...cur, value: curValue })
      result.lastShownPosition =
        curValue !== null ? cur.position : acc.lastShownPosition
      return result
    },
    {
      points: [],
      lastShownPosition: 0,
    }
  )

  const viewBox = `${xMin} ${yMin} ${xRange} ${yRange}`

  return (
    <div
      className={classes.wrapper}
      style={{
        width: width ? `${width}px` : '',
        height: height ? `${height}px` : '',
        ...style,
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
      onScroll={event => onScroll(event.target.scrollLeft)}
    >
      <svg
        ref={container}
        style={{
          minWidth: minWidth ? `${minWidth}px` : '',
          maxWidth: maxWidth ? `${maxWidth}px` : '',
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        preserveAspectRatio="xMidYMin slice"
      >
        <Conditional dependencies={rect}>
          <Conditional dependencies={label}>
            <text
              className={classes.label}
              x={xRange / 2}
              y={2 * yMin + yRange - labelTextMargin}
              fontSize={labelTextSize}
            >
              {label}
            </text>
          </Conditional>
          {filteredPoints.map((point, i, { length }) => {
            const value = Number(Number.parseFloat(point.value).toFixed(4))
            return (
              <React.Fragment key={`fragment-${point.position}`}>
                <line
                  className={classes.line}
                  x1={point.position * xRange || 0}
                  y1={0}
                  x2={point.position * xRange || 0}
                  y2={lineLength}
                />
                <Conditional dependencies={value === 0 || value}>
                  <text
                    className={classes.text}
                    x={point.position * xRange || 0}
                    y={0 + textMargin}
                    fontSize={textSize}
                    textAnchor="start"
                    transform={`rotate(${!mirror ? 60 : -60},${
                      point.position * xRange || 0
                    },${0 + textMargin})`}
                    style={{ '>tspan': { x: point.position * xRange || 0 } }}
                  >
                    {render
                      ? render(
                          value,
                          i,
                          length,
                          point.position * xRange || 0,
                          0 + textMargin
                        )
                      : value}
                  </text>
                </Conditional>
              </React.Fragment>
            )
          })}
        </Conditional>
      </svg>
    </div>
  )
}

XAxis.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({ position: PropTypes.number, value: PropTypes.number })
  ),
  label: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  labelSize: PropTypes.number,
  fontSpacing: PropTypes.number,
  fontMargin: PropTypes.number,
  labelMargin: PropTypes.number,
  tickLength: PropTypes.number,
  render: PropTypes.func,
  mirror: PropTypes.bool,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  onScroll: PropTypes.func,
  style: PropTypes.object,
}

export default XAxis
