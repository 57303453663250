import React from 'react'
import ReactDOM from 'react-dom'
import Core, { useRouteMatch, useHistory, useLocation } from 'core'
import 'libs/CSSNormalizer.scss'
import 'style.scss'

import TrainingWizard from 'components/TrainingWizard'
import ScoringWizard from 'components/ScoringWizard'
import PipelineInspector from 'components/PipelineInspector'
import FileStateInspector from 'components/FileStateInspector'
import { Provider as MouseTrackerProvider } from 'components/MouseTracker'
import ThemeProvider from 'components/ThemeProvider'
import Conditional from 'components/Conditional'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Menu from 'components/Menu'

import AppRouter from 'router'

import cache from 'core/cache'

// import {
//   Container as ModalContainer,
//   // Provider as ModalProvider,
// } from 'components/Modal'
import Logo from './Logo'

const getLayout = route => {
  const noHeader = ['404', 'login', 'signup', 'confirm', 'password-reset']
  const noMenu = ['404', 'login', 'signup', 'confirm', 'pricing', 'password-reset']
  // const noFooter = ['404']
  return {
    hasHeader: noHeader.indexOf(route) === -1,
    hasMenu: noMenu.indexOf(route) === -1,
    hasFooter: false, // noFooter.indexOf(route) === -1,
  }
}

const classes = {
  wrapper: 'App-wrapper',
  main: 'Main-wrapper',
  contentWrapper: 'Content-wrapper',
}

const App = () => {
  const match = useRouteMatch('/:route')
  const history = useHistory()
  const location = useLocation()
  const route = match && match.params.route
  const { hasHeader, hasMenu, hasFooter } = getLayout(route)

  return (
    <React.StrictMode>
      <MouseTrackerProvider>
        <ThemeProvider>
          {/* <ModalProvider> */}
          <div className={classes.wrapper}>
            <Conditional dependencies={hasHeader}>
              <Header />
            </Conditional>
            <div className={classes.main}>
              <Logo />
              <Conditional dependencies={hasMenu}>
                <Menu />
              </Conditional>
              <div className={classes.contentWrapper}>
                <AppRouter match={match} history={history} location={location} />
              </div>
            </div>
            <Conditional dependencies={hasFooter}>
              <Footer />
            </Conditional>
          </div>
          <TrainingWizard />
          <ScoringWizard />
          {/* <ModalContainer /> */}
          {/* </ModalProvider> */}
          <PipelineInspector />
          <FileStateInspector />
        </ThemeProvider>
      </MouseTrackerProvider>
    </React.StrictMode>
  )
}

// console.log('FAIL THE PIPE')

cache.getAll().then(initialData => {
  if (initialData) {
    // console.log('starting with locally cache data:', initialData)
  }
  ReactDOM.render(
    <Core preloadedData={initialData}>
      <App />
    </Core>,
    document.getElementById('root')
  )
})
