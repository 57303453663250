/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { extractValue } from 'libs/utils/helpers'

import Conditional from 'components/Conditional'

import './TableViewStyle.scss'

const classes = {
  wrapper: 'TableViewCard-wrapper',
  column: 'TableViewCard-column',
}

const TableViewCard = props => {
  const {
    data,
    columns,
    // id
  } = props

  return (
    <div
      className={classes.wrapper}
      role="button"
      tabIndex={-1}
      onClick={() => {}}
      onKeyDown={event => {
        event.keyCode === 13 && (() => {})()
      }}
    >
      {columns.map(column => {
        const value = extractValue(data, column.dataKey)
        return (
          <div
            key={`TableViewCard-Column-${uuid()}`}
            className={classes.column}
            style={{ width: column.width || '' }}
          >
            {column.render ? column.render(value) : value}
          </div>
        )
      })}
    </div>
  )
}

TableViewCard.propTypes = {
  data: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.string,
      render: PropTypes.func,
    })
  ).isRequired,
  // id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default TableViewCard
