/* eslint-disable max-lines */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import moment from 'moment'
import { generateColors } from 'libs/utils/color'

import Conditional from 'components/Conditional'
import { UserControlWrapper } from './UCW'
import LineGraph from './LineGraph'
import { Provider } from './LineChartContext'

import './style.scss'

const classes = {
  wrapper: 'LineChart-wrapper',
}

const LineChart = props => {
  const {
    brains: storeBrains,
    resourceData,
    resourceMeta,
  } = useStore('selectBrains', 'selectResourceData', 'selectResourceMeta')
  const {
    controlable,
    legend,
    trainings: propTrainings,
    brains: propBrains,
    features: propFeatures,
    split = 'train',
    datasets: propDatasets,
    stackingCategories: propStackingCategories,
    combineDatasets,
    x: propX = [],
    y: propY = [],
    type: propType,
    domain,
    mainColor,
    ...rest
  } = props

  const { xAxis: { label: xLabel } = {}, yAxis: { label: yLabel } = {} } = rest

  const { position: legendPosition = 'left', width: legendWidth = 160 } =
    (legend && legend !== true ? legend : {}) || {}

  const [trainings, brains, features, datasets, stackingCategories, x, y, mode] =
    useMemo(() => {
      const tempTrainings =
        (propTrainings &&
          (!Array.isArray(propTrainings) ? [propTrainings] : propTrainings)) ||
        []
      const tempBrains = (propBrains && [...propBrains]) || []
      if (tempTrainings)
        tempTrainings.forEach(training => {
          const tempTraining = tempBrains?.find(e => e.id === training.id)
          if (!tempTraining) tempBrains.push({ id: training.id, brains: [] })
        })
      const tempFeatures =
        (propFeatures &&
          (!Array.isArray(propFeatures) ? [propFeatures] : propFeatures)) ||
        []
      const tempDatasets =
        (propDatasets &&
          (!Array.isArray(propDatasets) ? [propDatasets] : propDatasets).map(
            dataset => dataset.id
          )) ||
        []
      const tempStackingCategories =
        (propStackingCategories &&
          (!Array.isArray(propStackingCategories)
            ? [propStackingCategories]
            : propStackingCategories)) ||
        []
      const tempX = ((propX && (!Array.isArray(propX) ? [propX] : propX)) || [])
        .filter(feature => feature.type === 'numeric' || feature.type === 'time')
        .reduce((acc, cur) => {
          const e = [...acc]
          const dynamicType = e.length && e[0].type
          if (
            !e.find(
              feature => feature.type === cur.type && feature.name === cur.name
            )
          )
            if (dynamicType) dynamicType === cur.type && e.push(cur)
            else e.push(cur)
          return e
        }, [])
      const tempY = ((propY && (!Array.isArray(propY) ? [propY] : propY)) || [])
        .filter(feature => feature.type === 'numeric')
        .reduce((acc, cur) => {
          const e = [...acc]
          if (
            !e.find(
              feature => feature.type === cur.type && feature.name === cur.name
            )
          )
            e.push(cur)
          return e
        }, [])
      const tempMode =
        (controlable?.trainings ||
          controlable?.brains ||
          controlable?.features ||
          tempTrainings.length ||
          tempBrains.length) &&
        'brainMode'
      return [
        tempTrainings,
        tempBrains,
        tempFeatures,
        tempDatasets,
        tempStackingCategories,
        tempX,
        tempY,
        tempMode,
      ]
    }, [
      propTrainings,
      propBrains,
      propFeatures,
      propDatasets,
      propStackingCategories,
      propX,
      propY,
      controlable,
    ])

  const [stackedBy, type] = useMemo(() => {
    const tempMeta = resourceMeta?.find(({ id }) => id === datasets[0])?.data || {}
    const { stacked_by: stackedById = null } = tempMeta
    const tempStackedBy =
      tempMeta.features?.find(({ id }) => id === stackedById) || null
    const tempType =
      mode === 'brainMode'
        ? propType || 'learningCurve'
        : propType || (datasets.length && tempMeta.type) || null
    return [tempStackedBy, tempType]
  }, [resourceMeta, mode, datasets, propType])

  if (controlable) {
    if (mode === 'brainMode') {
      controlable.datasets = null
      controlable.stackingCategories = null
      controlable.x = null
      controlable.y = null
    } else {
      controlable.split = null
    }
  }
  const storeData = {
    brains: storeBrains || [],
    datasets:
      datasets
        .map(
          dataset =>
            resourceData.find(resource => resource.key === `chart-${dataset}`) ||
            null
        )
        .filter(e => !!e) || [],
  }

  const loading = useMemo(() => {
    return (
      !!storeData.brains.some(({ isLoading }) => isLoading) ||
      !!storeData.datasets.some(({ isLoading }) => isLoading)
    )
  }, [storeData.brains, storeData.datasets])

  const [xyCombinations, xAxisLabel, yAxisLabel] = useMemo(() => {
    let tempCombinations = []
    let tempXLabel = ''
    let tempYLabel = ''
    if (mode === 'brainMode') {
      switch (type) {
        case 'learningCurve':
          tempXLabel = 'epoch'
          tempYLabel =
            (features.length && features.map(feature => feature.name).join(', ')) ||
            'loss'
          break
        case 'rocCurve':
          tempXLabel = 'fpr'
          tempYLabel = 'tpr'
          break
        default:
          break
      }
    } else {
      tempXLabel = x.map(feature => feature.name).join(', ')
      tempYLabel = y.map(feature => feature.name).join(', ')
      if (x.length) {
        tempCombinations = x.reduce((acc, cur) => {
          const tempResult = [...acc]
          y.forEach(feature => tempResult.push({ x: cur, y: feature }))
          return tempResult
        }, [])
      } else if (y.length) {
        tempXLabel = 'index'
        tempCombinations = y.map(feature => ({
          x: { type: 'numeric', name: '_index', id: '_index-numeric' },
          y: feature,
        }))
      }
    }
    return [tempCombinations, xLabel || tempXLabel, yLabel || tempYLabel]
  }, [mode, type, features, x, y, xLabel, yLabel])

  const data = useMemo(() => {
    const returnData = []
    if (
      mode === 'brainMode' &&
      !controlable &&
      trainings.length &&
      brains.length &&
      features.length
    ) {
      const resultData = []
      const tempTrainings = trainings.map(({ name, id }) => {
        const { [type]: trainingBrains = {} } =
          storeData.brains.find(training => training.id === id)?.data || {}
        const { features: rocFeats = [], objectives: lrnObjs = [] } =
          trainingBrains || {}
        const tempBrains =
          trainingBrains[split]?.filter(
            brain => brains.find(e => e.id === id).brains.indexOf(brain.id) !== -1
          ) || []
        const returnObj = { name, brains: tempBrains, id }
        if (type === 'learningCurve') returnObj.objectives = lrnObjs
        if (type === 'rocCurve') returnObj.objectives = rocFeats
        return returnObj
      })
      features.forEach(feature => {
        tempTrainings.forEach((trainingBrains, _, { length }) => {
          trainingBrains.brains.forEach(brain => {
            const tempLine = brain[feature.name] || null
            if (tempLine)
              resultData.push({
                title: `${length > 1 ? `${trainingBrains.name}: ` : ''}${
                  feature.name
                } / ${brain.name}`,
                data: brain[feature.name] || [],
              })
          })
        })
      })
      returnData.push(...resultData)
    }
    if (
      mode !== 'brainMode' &&
      !controlable &&
      datasets.length &&
      xyCombinations.length &&
      (type !== 'stacked-timeseries' || (stackedBy && stackingCategories.length))
    ) {
      const resultData = xyCombinations.reduce(
        (acc, { x: xFeature, y: yFeature }) => {
          const tempResult = storeData.datasets
            .filter(
              instance =>
                (xFeature.id !== '_index-numeric'
                  ? instance?.meta?.features?.find(
                      feature =>
                        feature.type === xFeature.type &&
                        feature.name === xFeature.name
                    )
                  : true) &&
                instance.meta?.features.find(
                  feature =>
                    feature.type === yFeature.type && feature.name === yFeature.name
                )
            )
            .map((instance, _, { length }) => {
              const name =
                resourceMeta?.find(dataset => dataset.id === instance.resource)?.data
                  ?.name || ''
              return {
                title: `${length > 1 ? `${name}: ` : ''}${yFeature.name} / ${
                  xFeature.name
                }`,
                data:
                  instance.data
                    .filter(
                      e =>
                        type !== 'stacked-timeseries' ||
                        stackingCategories.indexOf(e[stackedBy.name]) !== -1
                    )
                    .map((e, i) => {
                      let tempX = null
                      if (xFeature.id !== '_index-numeric')
                        tempX =
                          xFeature.type === 'time'
                            ? moment(
                                e[xFeature.name],
                                'YYYY-MM-DDTHH:mm:ss.SSS Z'
                              ).valueOf() / 1000
                            : Number(e[xFeature.name])
                      else tempX = i
                      let tempY = Number(e[yFeature.name])
                      const tempStacked =
                        (type === 'stacked-timeseries' && e[stackedBy.name]) || null
                      tempX = tempX || tempX === 0 ? tempX : null
                      tempY = tempY || tempY === 0 ? tempY : null
                      return {
                        x: tempX,
                        y: tempY,
                        stacked: tempStacked,
                      }
                    })
                    .filter(
                      ({ x: xVal, y: yVal }) =>
                        (!!xVal || xVal === 0) && (!!yVal || yVal === 0)
                    ) || [],
              }
            })
          let endResult = tempResult
          if (combineDatasets) {
            endResult = [
              tempResult.reduce(
                (accumulated, current) => {
                  const tempCombined = { ...accumulated }
                  tempCombined.data = [...tempCombined.data, ...current.data]
                  return tempCombined
                },
                {
                  title: `combined: ${yFeature.name} / ${xFeature.name}`,
                  data: [],
                }
              ),
            ]
          }
          if (type === 'stacked-timeseries') {
            endResult = endResult.reduce((accumulated, current) => {
              const tempRes = [...accumulated]
              current.data.forEach(datum => {
                const instance = tempRes.find(
                  ({ title }) => title === `${current.title} (${datum.stacked})`
                )
                if (instance) instance.data.push({ x: datum.x, y: datum.y })
                else
                  tempRes.push({
                    title: `${current.title} (${datum.stacked})`,
                    data: [],
                  })
              })
              return tempRes
            }, [])
          }
          return [...acc, ...endResult]
        },
        []
      )
      returnData.push(...resultData)
    }
    const colorArray =
      (returnData.length &&
        mainColor &&
        generateColors(
          mainColor,
          returnData.length,
          0.2 + returnData.length / (returnData.length + 1) / 10
        )) ||
      []
    return returnData.map((datum, i) => {
      return { ...datum, color: colorArray[i] }
    })
  }, [
    mode,
    controlable,
    trainings,
    brains,
    features,
    split,
    type,
    storeData.brains,
    storeData.datasets,
    datasets,
    stackedBy,
    stackingCategories,
    resourceMeta,
    combineDatasets,
    xyCombinations,
    mainColor,
  ])

  const xAxisType = (mode !== 'brainMode' && x.length && x[0].type) || null

  const contextData = {
    data,
    xAxisLabel,
    yAxisLabel,
    xAxisType,
    domain,
    loading,
  }

  return (
    <div className={classes.wrapper}>
      <Provider value={contextData}>
        <Conditional dependencies={controlable}>
          <UserControlWrapper
            controlable={controlable}
            trainings={trainings}
            brains={brains}
            features={features}
            split={split}
            datasets={datasets}
            stackingCategories={stackingCategories}
            combineDatasets={combineDatasets}
            x={x}
            y={y}
            type={type}
            labels={{ x: xLabel, y: yLabel }}
            mainColor={mainColor}
          >
            <LineGraph
              legend={legend && { position: legendPosition, width: legendWidth }}
              {...rest}
            />
          </UserControlWrapper>
        </Conditional>
        <Conditional dependencies={!controlable}>
          <LineGraph
            legend={legend && { position: legendPosition, width: legendWidth }}
            {...rest}
          />
        </Conditional>
      </Provider>
    </div>
  )
}

LineChart.propTypes = {
  controlable: PropTypes.shape({
    trainings: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    brains: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    split: PropTypes.bool,
    features: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    datasets: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    stackingCategories: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    x: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    y: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    type: PropTypes.bool,
    prefKey: PropTypes.string,
  }),
  legend: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
    }),
    PropTypes.bool,
  ]),
  xAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['top', 'bottom']),
      height: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
      label: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  yAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
      label: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  grid: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),

  // /////////////// TRAININGS DISPLAY
  trainings: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
    ),
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  ]),
  brains: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      brains: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  features: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
    ),
    PropTypes.shape({ type: PropTypes.string, name: PropTypes.string }),
  ]),
  split: PropTypes.oneOf(['combined', 'test', 'train', 'validation']),
  // /////////////////////////////////

  // //////////////// DATASETS DISPLAY
  datasets: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    PropTypes.shape({ id: PropTypes.string }),
  ]),
  stackingCategories: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  combineDatasets: PropTypes.bool,
  x: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
    ),
    PropTypes.shape({ type: PropTypes.string, name: PropTypes.string }),
  ]),
  y: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
    ),
    PropTypes.shape({ type: PropTypes.string, name: PropTypes.string }),
  ]),
  // /////////////////////////////////

  type: PropTypes.oneOf([
    'learningCurve',
    'rocCurve',
    'stacked-timeseries',
    'basedata',
    'timeseries',
  ]),
  domain: PropTypes.shape({
    x: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.shape({ min: PropTypes.number, max: PropTypes.number }),
    ]),
    y: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.shape({ min: PropTypes.number, max: PropTypes.number }),
    ]),
  }),
  mainColor: PropTypes.string,
}

export default LineChart
