/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useCallback } from 'react'

import PropTypes from 'prop-types'

import invariant from 'tiny-invariant'
import { Provider, Context } from '../FormContext'

const self = 'FormFor'

const FormFor = ({ children, dataKey: fieldKey = '', absolute }) => {
  const context = useContext(Context)

  invariant(context, `You cannot use the '${self}' component outside of <Form>`)

  const { contextKey } = context

  let dataKey = !absolute ? contextKey : ''
  if (dataKey && fieldKey && !/^(\[[0-9]+\])/.test(fieldKey)) dataKey += '.'
  dataKey += fieldKey

  const child = React.Children.only(children)
  const state = context.get()

  const group = dataKey
    .replace(/(\[|\])/g, '.')
    .split('.')
    .filter(e => e)
    .reduce((o, key, i, arr) => {
      if (i !== arr.length - 1) {
        invariant(
          typeof o === 'object',
          `Component '${self}' attempted to access property '${key}' of ${o} while traversing dataKey '${dataKey}'`
        )
        return o[key]
      }
      if (Array.isArray(o[key])) {
        return o[key]
      }
      invariant(
        o[key] === undefined || Array.isArray(o[key]),
        `Component '${self}' can only operate on Array elements. Expected 'array' but got ${
          typeof o[key] === 'object'
            ? JSON.stringify(o[key])
            : `${o[key] ? typeof o[key] : ''} '${o[key]}'`
        } at ${dataKey}`
      )
      //  Default to an empty array if one does not exist
      return []
    }, state)

  invariant(
    Array.isArray(group),
    `Component '${self}' can only operate on Array elements, but got ${typeof group}. If property dataKey is not provided it will assume the context, make sure it is an array.`
  )

  return (
    <>
      {group.map((e, i) => (
        <Provider
          key={i}
          value={{
            ...context,
            index: i,
            contextKey: `${dataKey}[${i}]`,
          }}
        >
          {React.cloneElement(child)}
        </Provider>
      ))}
    </>
  )
}

FormFor.propTypes = {
  children: PropTypes.node,
  dataKey: PropTypes.string,
  absolute: PropTypes.bool,
}

export default FormFor
