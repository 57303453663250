/* eslint-disable max-lines */
/* eslint-disable no-shadow */
import { v4 as uuid } from 'uuid'
import { createBundle } from 'core/bundler'
import {
  buildBranch,
  buildMirrorStructure,
  parseMeta,
  updateElementMeta,
  compareMetaAndKeepReady,
} from 'libs/utils/helpers'
import {
  PIPELINE_CONFIGURATION_SET,
  PIPELINE_UPDATED,
  DATASET_CHOSEN,
  PIPELINE_CONFIG_BOX_INSERTED,
  PIPELINE_CONFIG_BOX_REMOVED,
  PIPELINE_CONFIG_BOX_SELECTED,
  // PIPELINE_BOXES_DESELECTED,
  PIPELINE_CONFIG_MODE_ENTERED,
  PIPELINE_CONFIG_BRANCHES_SWAPPED,
  PIPELINE_CONFIG_BOX_SPEC_UPDATED,
  PIPELINE_BOX_CONFIG_UPDATED,
  PIPELINE_STATE_SET_TO_IDLE,
  PIPELINE_COMMITTED,
  PIPELINE_PROCESSING_STARTED,
  PIPELINE_PROCESSING_FINISHED,
  PIPELINE_PROCESSING_FAILED,
  PIPELINE_ELEMENT_FINISHED,
  PIPELINE_ELEMENT_FAILED,
} from '../../../../core/actiontypes'
import { fillLocations } from '../../../../../libs/utils/helpers'

const specObjPlaceholder = {
  type: '',
  config: {
    rules: [],
  },
}

const metaObjPlaceholder = {
  metadata: [
    {
      type: 'basedata',
      features: [],
    },
  ],
  validation: [],
  hash_in: '',
  hash_out: '',
}
const metaObjPlaceholderJoin = {
  ...metaObjPlaceholder,
  metadata: [
    ...metaObjPlaceholder.metadata,
    {
      type: 'basedata',
      features: [],
    },
  ],
}
const pipelineSpec = [
  { type: 'mapping', config: { rules: [] }, locationArray: [0] },
  { type: 'dataset', datasetArrayIndex: 0, locationArray: [1] },
]
const pipelineMeta = [
  { ...metaObjPlaceholder, type: 'mapping' },
  { ...metaObjPlaceholder, type: 'dataset' },
]
const pipelineDatasetArray = [null]

const initialState = {
  id: uuid(),
  spec: pipelineSpec,
  meta: pipelineMeta,
  datasetArray: pipelineDatasetArray,
  active: null,
  configuring: null,
  shouldCommit: null,
  committing: null,
  processing: false,
}

const actions = {
  setPipeline: payload => ({ type: PIPELINE_CONFIGURATION_SET, payload }),
  updatePipeline: payload => ({ type: PIPELINE_UPDATED, payload }),
  chooseDataset: payload => ({ type: DATASET_CHOSEN, payload }),
  insertPipelineBox: payload => ({
    type: PIPELINE_CONFIG_BOX_INSERTED,
    payload,
  }),
  removePipelineBox: payload => ({
    type: PIPELINE_CONFIG_BOX_REMOVED,
    payload,
  }),
  activatePipelineBox: payload => ({
    type: PIPELINE_CONFIG_BOX_SELECTED,
    payload,
  }),
  // deactivatePipelineBoxes: () => ({
  //   type: PIPELINE_BOXES_DESELECTED,
  // }),
  configurePipelineBox: payload => ({
    type: PIPELINE_CONFIG_MODE_ENTERED,
    payload,
  }),
  swapPipelineBranches: payload => ({
    type: PIPELINE_CONFIG_BRANCHES_SWAPPED,
    payload,
  }),
  // REMOVE //
  updatePipelineBoxSpec: payload => ({
    // <-- remove later
    type: PIPELINE_CONFIG_BOX_SPEC_UPDATED, // <-- remove later
    payload, // <-- remove later
  }), // <-- remove later
  // REMOVE //
  updateBoxConfig: payload => ({
    type: PIPELINE_BOX_CONFIG_UPDATED,
    payload,
  }),
}

// REACTORS
const reactDefaultBoxes = ({ pipeline: state }) => {
  const tempState = { ...state }
  let tempSpec = [...tempState.spec]
  let tempMeta = [...tempState.meta]
  const tempDatasetArray = [...tempState.datasetArray]
  let change = false
  const hasMappingBox = !!(tempSpec[0] && tempSpec[0].type === 'mapping')
  if (!hasMappingBox) {
    tempSpec.unshift({
      type: 'mapping',
      config: { rules: [] },
      locationArray: [0],
    })
    tempMeta.unshift({ ...metaObjPlaceholder, type: 'mapping' })
    change = true
  }
  const tempPipeArray = []
  const [newConfig, newMeta, counter, newChange] = buildBranch(
    tempSpec,
    tempMeta,
    -1,
    tempPipeArray,
    tempDatasetArray,
    change
  )
  const [locConfig, locChange] = fillLocations(newConfig, newChange)

  tempSpec = locConfig
  tempMeta = newMeta
  change = change || newChange || locChange

  if (tempDatasetArray.length !== counter + 1) change = true

  if (change) {
    tempState.spec = tempSpec
    tempState.meta = tempMeta
    tempState.datasetArray = tempPipeArray
    return actions.updatePipeline(tempState)
  }
  return null
}

const reactCommit = ({ pipeline: state }) => {
  const { datasetArray, spec, id, shouldCommit, committing, processing } = state

  if (!datasetArray.some(e => !e)) {
    if (shouldCommit !== committing) {
      const body = {
        datasets: datasetArray,
        spec,
        request_id: shouldCommit,
      }
      return (dispatch, { api }) => {
        dispatch({ type: PIPELINE_COMMITTED, payload: { id: shouldCommit } })
        return api.transform('/commit', { method: 'POST', body })
      }
    }
  } else if (id || shouldCommit || committing || processing) {
    return { type: PIPELINE_STATE_SET_TO_IDLE }
  }

  return null
}

// const reactMappingBoxExcessRulesRemoval = ({ pipeline: state }) => {
//   const tempState = { ...state }
//   const { spec, meta } = tempState

//   const mappingMeta = meta.find(metaObj => metaObj?.type === 'mapping') || null
//   const mappingSpec = spec.find(specObj => specObj?.type === 'mapping') || null
//   const metaFeatures = mappingMeta?.metadata[0].features || []
//   const specRules = mappingSpec?.config?.rules || []

// const newSpecRules = specRules.filter(
//   ({ feature_id: featureId }) => !!metaFeatures.find(({ id }) => featureId === id)
// )

// if (newSpecRules.length !== specRules.length) {
//   console.log(newSpecRules, specRules)
//   const tempSpec = spec.map(specObj =>
//     specObj.type === 'mapping' && specObj.locationArray.join('') === '0'
//       ? { ...specObj, config: { rules: [...newSpecRules] } }
//       : specObj
//   )
//   tempState.spec = tempSpec
//   return actions.updatePipeline(tempState)
// }

//   return null
// }

const selectors = {
  selectPipeline: state => state.pipeline,
  selectPipelineSpec: state => state.pipeline.spec,
  selectPipelineMeta: state => state.pipeline.meta,
  selectPipelineDatasetArray: state => state.pipeline.datasetArray,
  selectPipelineActiveBoxLocation: state => state.pipeline.active,
  selectPipelineActiveBox: state => {
    const { pipeline } = state
    const { spec, meta, active } = pipeline
    const [boxSpec, boxMeta] = active?.reduce(
      (acc, cur) => {
        return [acc[0][cur], acc[1][cur]]
      },
      [spec, meta]
    ) || [null, null]
    return active ? { spec: boxSpec, meta: boxMeta } : null
  },
  selectPipelineConfiguringBoxLocation: state => state.pipeline.configuring,
  selectPipelineIsCommitting: state => state.pipeline.committing,
  selectPipelineIsProcessing: state => state.pipeline.processing,
  reactDefaultBoxes,
  reactCommit,
  // reactMappingBoxExcessRulesRemoval,
}

const reducer = (state = initialState, { type, payload }) => {
  if (type === PIPELINE_CONFIGURATION_SET) {
    const tempState = { ...state }
    tempState.spec = payload.spec || tempState.spec
    const tempMeta = buildMirrorStructure(tempState.spec)
    tempState.meta = tempMeta
    tempState.datasetArray = payload.datasetArray || tempState.datasetArray
    tempState.active = null
    tempState.configuring = null
    tempState.shouldCommit = uuid()
    tempState.committing = null
    tempState.processing = false
    return tempState
  }
  if (type === PIPELINE_UPDATED) return payload

  if (type === DATASET_CHOSEN) {
    const {
      datasetArrayIndex,
      datasetID,
      location,
      spec: boxSpec,
      defaultPipeline,
    } = payload
    const tempState = { ...state }
    const { spec, meta, datasetArray } = tempState
    datasetArray[datasetArrayIndex] = datasetID

    const defaultPipelineLength = defaultPipeline?.length || 0

    location.reduce(
      (acc, cur, i) => {
        if (i === location.length - 1) {
          acc[0][cur] = boxSpec
          acc[1].forEach((metaObj, i) => {
            if (!Array.isArray(metaObj))
              acc[1][i] = { ...metaObjPlaceholder, type: metaObj.type }
            else acc[1][i] = metaObj
          })
          if (defaultPipelineLength) {
            acc[0].splice(acc[0].length - 1, 0, ...defaultPipeline)
            acc[1].splice(
              acc[1].length - 1,
              0,
              ...defaultPipeline.map(box => ({ metaObjPlaceholder }))
            )
          }
        } else {
          acc[1].forEach((metaObj, i) => {
            if (!Array.isArray(metaObj))
              acc[1][i] =
                metaObj.type === 'join' || metaObj.type === 'concat'
                  ? { ...metaObjPlaceholderJoin, type: metaObj.type }
                  : { ...metaObjPlaceholder, type: metaObj.type }
            else acc[1][i] = metaObj
          })
        }
        return [acc[0][cur], acc[1][cur]]
      },
      [spec, meta]
    )

    tempState.spec = [...spec]
    tempState.meta = [...meta]
    tempState.datasetArray = datasetArray
    tempState.shouldCommit = uuid()
    return tempState
  }
  if (type === PIPELINE_CONFIG_BOX_INSERTED) {
    const { location, spec: boxSpec } = payload
    const tempState = { ...state }
    const { spec, meta, datasetArray, active } = tempState
    let shouldReselect = !!active
    let shouldDeselect = !active
    let inTheRightBranch = !!active

    if (boxSpec && (boxSpec.type === 'join' || boxSpec.type === 'concat')) {
      location.reduce(
        (acc, cur, i) => {
          if (
            inTheRightBranch &&
            i !== active.length - 1 &&
            i !== location.length - 1 &&
            cur !== active[i]
          )
            inTheRightBranch = false
          if (i === location.length - 1) {
            acc[0].splice(
              0,
              acc[0].length,
              ...[
                ...acc[0].slice(0, cur),
                { ...boxSpec },
                [...acc[0].slice(cur)],
                [{ ...specObjPlaceholder, type: 'dataset' }],
              ]
            )
            acc[1].splice(
              0,
              acc[1].length,
              ...[
                ...acc[1].slice(0, cur),
                { ...metaObjPlaceholderJoin, type: boxSpec.type },
                [...acc[1].slice(cur)],
                [{ ...metaObjPlaceholder }],
              ]
            )
            acc[1].forEach((metaObj, i) => {
              if (!Array.isArray(metaObj) && i < cur)
                acc[1][i] =
                  metaObj.type === 'join' || metaObj.type === 'concat'
                    ? { ...metaObjPlaceholderJoin, type: metaObj.type }
                    : { ...metaObjPlaceholder, type: metaObj.type }
              else acc[1][i] = metaObj
            })
            if (!shouldDeselect && inTheRightBranch && cur > active[i]) {
              shouldDeselect = true
            }
            if (shouldReselect)
              if ((active[i] || active[i] === 0) && cur > active[i]) {
                shouldReselect = false
              } else {
                const newSelection = [...active]
                newSelection.splice(i, 1, cur + 1, active[i] - cur)
                shouldReselect = [...newSelection]
              }
          } else {
            acc[1].forEach((metaObj, i) => {
              if (!Array.isArray(metaObj))
                acc[1][i] =
                  metaObj.type === 'join' || metaObj.type === 'concat'
                    ? { ...metaObjPlaceholderJoin, type: metaObj.type }
                    : { ...metaObjPlaceholder, type: metaObj.type }
              else acc[1][i] = metaObj
            })
            if (inTheRightBranch && active?.length === i + 1) shouldDeselect = true
            if (!inTheRightBranch) shouldReselect = false
          }
          return [acc[0][cur], acc[1][cur]]
        },
        [spec, meta]
      )
    } else {
      location.reduce(
        (acc, cur, i) => {
          if (
            inTheRightBranch &&
            i !== active.length - 1 &&
            i !== location.length - 1 &&
            cur !== active[i]
          )
            inTheRightBranch = false
          if (i === location.length - 1) {
            acc[0].splice(cur, 0, { ...boxSpec })
            acc[1].splice(cur, 0, { ...metaObjPlaceholder, type: boxSpec.type })
            if (shouldReselect) {
              if (cur > active[i]) shouldReselect = false
              else {
                shouldReselect = [...active]
                shouldReselect[i] += 1
              }
            }
          } else if (!inTheRightBranch || active?.length === i + 1)
            shouldReselect = false
          return [acc[0][cur], acc[1][cur]]
        },
        [spec, meta]
      )
    }

    tempState.spec = [...spec]
    tempState.meta = [...meta]
    tempState.datasetArray = datasetArray
    tempState.active = shouldDeselect ? null : shouldReselect || tempState.active
    tempState.shouldCommit = uuid()
    return tempState
  }
  if (type === PIPELINE_CONFIG_BOX_REMOVED) {
    const { location, keepLeft } = payload
    const tempState = { ...state }
    const { spec, meta, datasetArray, active } = tempState

    let shouldReselect = !!active
    let shouldDeselect = !active
    let inTheRightBranch = !!active

    location.reduce(
      (acc, cur, i) => {
        if (
          inTheRightBranch &&
          i !== active.length - 1 &&
          i !== location.length - 1 &&
          cur !== active[i]
        )
          inTheRightBranch = false
        if (i === location.length - 1) {
          if (acc[0][cur].type !== 'join' && acc[0][cur].type !== 'concat') {
            acc[0].splice(cur, 1)
            acc[1].splice(cur, 1)
            if (!shouldDeselect && cur > active[i]) {
              shouldDeselect = true
            }
            if (shouldReselect && !shouldDeselect) {
              shouldReselect = [...active]
              shouldReselect[i] -= 1
            }
          } else {
            const leftConfigBranch = [...acc[0][cur + 1]]
            const rightConfigBranch = [...acc[0][cur + 2]]
            const leftMetaBranch = [...acc[1][cur + 1]]
            const rightMetaBranch = [...acc[1][cur + 2]]
            acc[0].splice(cur)
            acc[1].splice(cur)
            if (keepLeft) {
              acc[0].push(...leftConfigBranch)
              acc[1].push(...leftMetaBranch)
            } else {
              acc[0].push(...rightConfigBranch)
              acc[1].push(...rightMetaBranch)
            }
            if (
              !shouldDeselect &&
              inTheRightBranch &&
              (cur > active[i] ||
                (!keepLeft && active[i] === cur + 1) ||
                (keepLeft && active[i] === cur + 2))
            ) {
              shouldDeselect = true
            }
            if (shouldReselect && !shouldDeselect) {
              shouldReselect = [...active]
              shouldReselect.splice(i, 1)
              shouldReselect[i] += active[i] - 1
            }
          }
          acc[1].forEach((metaObj, i) => {
            if (!Array.isArray(metaObj) && i < cur)
              acc[1][i] =
                metaObj.type === 'join' || metaObj.type === 'concat'
                  ? { ...metaObjPlaceholderJoin, type: metaObj.type }
                  : { ...metaObjPlaceholder, type: metaObj.type }
            else acc[1][i] = metaObj
          })
        } else {
          acc[1].forEach((metaObj, i) => {
            if (!Array.isArray(metaObj))
              acc[1][i] =
                metaObj.type === 'join' || metaObj.type === 'concat'
                  ? { ...metaObjPlaceholderJoin, type: metaObj.type }
                  : { ...metaObjPlaceholder, type: metaObj.type }
            else acc[1][i] = metaObj
          })
          if (!inTheRightBranch) shouldReselect = false
          if (inTheRightBranch && active?.length === i + 1) shouldDeselect = true
        }
        return [acc[0][cur], acc[1][cur]]
      },
      [spec, meta]
    )

    tempState.spec = [...spec]
    tempState.meta = [...meta]
    tempState.datasetArray = datasetArray
    tempState.active = shouldDeselect ? null : shouldReselect || tempState.active
    if (active && location.join('') === active.join('')) tempState.active = null
    tempState.shouldCommit = uuid()
    return tempState
  }
  if (type === PIPELINE_CONFIG_BOX_SELECTED) {
    const { location } = payload
    const tempState = { ...state }
    tempState.active = location
    return tempState
  }
  if (type === PIPELINE_CONFIG_MODE_ENTERED) {
    const { location } = payload
    const tempState = { ...state }
    tempState.configuring = location
    return tempState
  }
  if (type === PIPELINE_CONFIG_BRANCHES_SWAPPED) {
    const { location } = payload
    const tempState = { ...state }

    const { spec, meta } = tempState

    location.reduce(
      (acc, cur, i) => {
        if (i === location.length - 1) {
          acc[0].splice(cur + 1, 2, acc[0][cur + 2], acc[0][cur + 1])
          acc[1].splice(cur + 1, 2, acc[1][cur + 2], acc[1][cur + 1])
        }
        return [acc[0][cur], acc[1][cur]]
      },
      [spec, meta]
    )

    tempState.spec = [...spec]
    return tempState
  }
  if (type === PIPELINE_BOX_CONFIG_UPDATED) {
    const { location, config: boxConfig } = payload
    const tempState = { ...state }
    const { spec, meta, active } = tempState
    let shouldDeselect = !!active

    location.reduce(
      (acc, cur, i) => {
        if (i === location.length - 1) {
          acc[0][cur] = { ...acc[0][cur], config: { ...boxConfig } }
          acc[1].forEach((metaObj, i) => {
            if (!Array.isArray(metaObj) && i < cur)
              acc[1][i] =
                metaObj.type === 'join' || metaObj.type === 'concat'
                  ? { ...metaObjPlaceholderJoin, type: metaObj.type }
                  : { ...metaObjPlaceholder, type: metaObj.type }
            else if (cur === i) acc[1][i] = { ...metaObj, ready: false }
            else acc[1][i] = metaObj
          })
          if (shouldDeselect && !(cur >= active[i])) shouldDeselect = false
        } else {
          acc[1].forEach((metaObj, i) => {
            if (!Array.isArray(metaObj)) {
              acc[1][i] =
                metaObj.type === 'join' || metaObj.type === 'concat'
                  ? { ...metaObjPlaceholderJoin, type: metaObj.type }
                  : { ...metaObjPlaceholder, type: metaObj.type }
            } else {
              acc[1][i] = [...metaObj]
            }
          })
          if (shouldDeselect && !(cur === active[i])) shouldDeselect = false
        }
        return [acc[0][cur], acc[1][cur]]
      },
      [spec, meta]
    )

    tempState.spec = [...spec]
    tempState.meta = [...meta]
    tempState.active = shouldDeselect ? null : tempState.active
    tempState.shouldCommit = uuid()
    return tempState
  }
  // REMOVE LATER //
  // if (type === PIPELINE_CONFIG_BOX_SPEC_UPDATED) {
  //   const { location, spec: boxSpec } = payload
  //   const tempState = { ...state }
  //   const { spec, meta, active } = tempState
  //   let shouldDeselect = !!active
  //   location.reduce(
  //     (acc, cur, i) => {
  //       if (i === location.length - 1) {
  //         acc[0][cur] = boxSpec
  //         acc[1].forEach((metaObj, i) => {
  //           if (!Array.isArray(metaObj) && i < cur)
  //             acc[1][i] =
  //               metaObj.type === 'join' || metaObj.type === 'concat'
  //                 ? { ...metaObjPlaceholderJoin, type: metaObj.type }
  //                 : { ...metaObjPlaceholder, type: metaObj.type }
  //           else if (cur === i) acc[1][i] = { ...metaObj, ready: false }
  //           else acc[1][i] = metaObj
  //         })
  //         if (shouldDeselect && !(cur >= active[i])) shouldDeselect = false
  //       } else {
  //         acc[1].forEach((metaObj, i) => {
  //           if (!Array.isArray(metaObj))
  //             acc[1][i] =
  //               metaObj.type === 'join' || metaObj.type === 'concat'
  //                 ? { ...metaObjPlaceholderJoin, type: metaObj.type }
  //                 : { ...metaObjPlaceholder, type: metaObj.type }
  //           else acc[1][i] = { ...metaObj, ready: false }
  //         })
  //         if (shouldDeselect && !(cur === active[i])) shouldDeselect = false
  //       }
  //       return [acc[0][cur], acc[1][cur]]
  //     },
  //     [spec, meta]
  //   )

  //   tempState.spec = [...spec]
  //   tempState.meta = [...meta]
  //   tempState.active = shouldDeselect ? null : tempState.active
  //   tempState.shouldCommit = uuid()
  //   return tempState
  // }
  // REMOVE LATER //
  if (type === PIPELINE_STATE_SET_TO_IDLE) {
    const tempState = { ...state }
    tempState.id = null
    tempState.shouldCommit = null
    tempState.committing = null
    tempState.processing = null
    return tempState
  }
  if (type === PIPELINE_COMMITTED) {
    const { id } = payload
    const tempState = { ...state }
    const { shouldCommit } = tempState
    tempState.id = id
    tempState.committing = shouldCommit
    tempState.processing = false
    return tempState
  }
  if (type === PIPELINE_PROCESSING_STARTED) {
    const { id: payloadID } = payload
    const tempState = { ...state }
    const { id: pipelineID, shouldCommit, committing } = tempState
    if (payloadID === pipelineID && shouldCommit === committing) {
      // const { spec } = tempState
      // if (spec[0].type === 'mapping') {
      //   const { config } = spec[0]
      //   const { rules } = config
      //   spec[0] = {
      //     ...spec[0],
      //     config: {
      //       ...config,
      //       rules: [
      //         ...rules.map(rule =>
      //           Object.keys(rule).reduce((acc, cur) => {
      //             // if (cur === 'name' && rule.rename) acc[cur] = rule.rename
      //             // else if (cur !== 'rename') acc[cur] = rule[cur]
      //             if (cur !== 'rename') acc[cur] = rule[cur]
      //             return acc
      //           }, {})
      //         ),
      //       ],
      //     },
      //   }
      // }
      // tempState.spec = [...spec]
      tempState.processing = true
      tempState.shouldCommit = null
      tempState.committing = null
      return tempState
    }
  }
  if (type === PIPELINE_PROCESSING_FINISHED) {
    const { id: payloadID, meta } = payload
    const tempState = { ...state }
    const { id: pipelineID, shouldCommit, committing } = tempState
    if (payloadID === pipelineID && shouldCommit === committing) {
      const newMeta = compareMetaAndKeepReady(tempState.meta, meta)
      tempState.meta = newMeta
      tempState.processing = false
      return tempState
    }
  }
  if (type === PIPELINE_PROCESSING_FAILED) {
    const { id: payloadID } = payload
    const tempState = { ...state }
    const { id: pipelineID, shouldCommit, committing } = tempState
    if (payloadID === pipelineID && shouldCommit === committing) {
      tempState.processing = false
      return tempState
    }
  }
  if (type === PIPELINE_ELEMENT_FINISHED || type === PIPELINE_ELEMENT_FAILED) {
    const { id, hashOut, locationArray, meta: eventMeta } = payload
    const tempState = { ...state }
    const { meta } = tempState
    if (tempState.id === id) {
      tempState.meta = updateElementMeta(meta, hashOut, locationArray, eventMeta)
      return tempState
    }
  }
  return state
}

export default config =>
  createBundle({
    name: 'pipeline',
    reducer,
    selectors,
    actions,
    init: store => {
      store.serverEvent('PIPELINE_COMMITTED', ({ data }, store) => {
        store.dispatch({
          type: PIPELINE_PROCESSING_STARTED,
          payload: { id: data.request_id },
        })
      })
      store.serverEvent('PIPELINE_PARSED', ({ data }, store) => {
        const parsedMeta = parseMeta(data.spec)
        store.dispatch({
          type: PIPELINE_PROCESSING_FINISHED,
          payload: {
            id: data.request_id,
            meta: parsedMeta,
          },
        })
      })
      store.serverEvent('PIPELINE_FAILED', ({ data }, store) => {
        store.dispatch({
          type: PIPELINE_PROCESSING_FAILED,
          payload: {
            id: data.request_id,
          },
        })
      })
      store.serverEvent('PIPELINE_ELEMENT_FINISHED', ({ data }, store) => {
        // eslint-disable-next-line camelcase
        const { request_id, hash_out, locationArray, stats } = data
        store.dispatch({
          type: PIPELINE_ELEMENT_FINISHED,
          payload: {
            id: request_id,
            hashOut: hash_out,
            locationArray,
            meta: { ready: true, stats },
          },
        })
      })
      store.serverEvent('PIPELINE_ELEMENT_FAILED', ({ data }, store) => {
        // eslint-disable-next-line camelcase
        const { request_id, hash_out, locationArray, error } = data
        store.dispatch({
          type: PIPELINE_ELEMENT_FAILED,
          payload: {
            id: request_id,
            hashOut: hash_out,
            locationArray,
            meta: { ready: false, error },
          },
        })
      })
    },
    args: null,
    middleware: null,
  })
