import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import Conditional from 'components/Conditional'

import './RingProgressStyle.scss'

const classes = {
  wrapper: 'RingProgressCard-wrapper',
  content: 'RingProgressCard-content',
  header: 'RingProgressCard-header',
  main: 'RingProgressCard-main',
  footer: 'RingProgressCard-footer',
  details: 'RingProgressCard-details',
}

const RingProgressCard = props => {
  const [expanded, setExpanded] = useState(false)

  const { title, progress: propProgress = 0, spin = false, footer } = props
  const progress = Math.round(Math.max(0, Math.min(1, propProgress)) * 100) / 100

  const maskID = `ringMask-${uuid()}`
  let mask = <rect x="0%" y="0%" width="100%" height="100%" rx="50%" fill="white" />

  if (!spin && progress < 1)
    mask = (
      <path
        d={`M 50 50, L 50 0 A 50 50, 0, ${Number(progress > 0.5)}, 1, ${
          50 + 50 * Math.sin(2 * Math.PI * progress)
        } ${50 - 50 * Math.cos(2 * Math.PI * progress)} Z`}
        fill="white"
      />
    )

  return (
    <div className={classes.wrapper + (expanded ? ' expanded' : '')}>
      <div
        className={classes.content + (expanded ? ' expanded' : '')}
        onMouseEnter={() => {
          setExpanded(true)
        }}
        onMouseLeave={() => {
          setExpanded(false)
        }}
      >
        <div className={classes.header}>{title}</div>
        <div className={classes.main}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <mask id={maskID}>
              {mask}
              <rect x="2%" y="2%" width="96%" height="96%" rx="50%" fill="black" />
            </mask>
            <mask id="progressRingMask">
              <rect x="0%" y="0%" width="100%" height="100%" rx="50%" fill="white" />
              <rect x="2%" y="2%" width="96%" height="96%" rx="50%" fill="black" />
            </mask>
            <defs>
              <radialGradient
                id="ringGradient"
                cx={0.5 + 0.5 * Math.sin(2 * Math.PI * (progress - 0.2))}
                cy={0.5 - 0.5 * Math.cos(2 * Math.PI * (progress - 0.2))}
                fx={0.5 + 0.5 * Math.sin(2 * Math.PI * progress)}
                fy={0.5 - 0.5 * Math.cos(2 * Math.PI * progress)}
                r={0.6}
              >
                <stop offset="0%" stopColor="dodgerblue" />
                <stop offset="100%" stopColor="#414554" />
                <animateTransform
                  attributeName="gradientTransform"
                  attributeType="XML"
                  type="rotate"
                  from="0 0.5 0.5"
                  to="360 0.5 0.5"
                  dur="3s"
                  begin="0s"
                  repeatCount="indefinite"
                />
              </radialGradient>
            </defs>
            <Conditional dependencies={!spin}>
              <rect
                x="0%"
                y="0%"
                width="100%"
                height="100%"
                rx="50%"
                fill="#414554"
                mask="url(#progressRingMask)"
              />
            </Conditional>
            <rect
              x="0%"
              y="0%"
              width="100%"
              height="100%"
              rx="50%"
              fill={spin ? 'url(#ringGradient)' : 'dodgerblue'}
              mask={`url(#${maskID})`}
            />
          </svg>

          <span
            style={{
              fontSize: '2.5em',
              marginTop: '5px',
              fontWeight: 200,
              zIndex: 1,
            }}
          >
            {`${Math.round(progress * 100)}%`}
          </span>
          <span style={{ fontSize: '0.8em', opacity: 0.6 }}>pending...</span>
        </div>
        <div className={classes.footer + (expanded ? ' expanded' : '')}>
          <div className={classes.details}>hover for details...</div>
          <Conditional dependencies={expanded && footer}>{footer}</Conditional>
        </div>
      </div>
    </div>
  )
}

RingProgressCard.propTypes = {
  title: PropTypes.string,
  progress: PropTypes.number,
  spin: PropTypes.bool,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default RingProgressCard
