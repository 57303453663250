import React, { useCallback, useRef } from 'react'
import { useStore } from 'core'
import moment from 'moment'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import {
  LogsInfoDialog,
  SimpleResourceDialog,
  EditTrainingModal,
} from 'components/ResourceModalComponents'
import { Tooltip } from '@material-ui/core'

const classes = {
  wrapper: 'TrainingsMenu-wrapper',
  menuGroup: 'TrainingsMenu-menuGroup',
  title: 'TrainingsMenu-title',
  controlsDiv: 'TrainingsMenu-controlsDiv',
  control: 'TrainingsMenu-control',
}

const TrainingsMenu = () => {
  const editTrainingModalRef = useRef()
  const logsDialogRef = useRef()
  const stopDialogRef = useRef()
  const {
    logs,
    trainings,
    selectedTraining: selectedTrainingId,
    fetchTrainingLogs,
    initializeTrainingWizard,
    startTrainingFromResource,
    startScoringFromResource,
    stopTraining,
    setInspectedTraining,
    setSelectedTraining,
  } = useStore(
    'selectLogs',
    'selectTrainings',
    'selectSelectedTraining',
    'fetchTrainingLogs',
    'initializeTrainingWizard',
    'startTrainingFromResource',
    'startScoringFromResource',
    'stopTraining',
    'setInspectedTraining',
    'setSelectedTraining'
  )

  const selectedTraining =
    trainings?.find(({ id }) => id === selectedTrainingId) || null

  const startScoringFromTraining = useCallback(
    training => {
      startScoringFromResource(
        training.id,
        'training',
        true,
        `${training.name} on ${moment(Date.now()).format('DD.MM.YYYY HH:mm')}`
      )
    },
    [startScoringFromResource]
  )

  return (
    <>
      <EditTrainingModal
        ref={editTrainingModalRef}
        selectedTraining={selectedTraining || {}}
      />
      <LogsInfoDialog
        ref={logsDialogRef}
        title="Training Logs"
        logs={logs.find(e => e.id === selectedTrainingId) || []}
      />
      <SimpleResourceDialog
        ref={stopDialogRef}
        titleText="Do you want to stop this training?"
        contentText="This action will permanently stop this training."
        okFunction={() => stopTraining(selectedTrainingId)}
      />
      <div className={classes.wrapper}>
        <div
          className={classes.menuGroup}
          style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
        >
          <div className={classes.controlsDiv} style={{ height: '2em' }}>
            <Tooltip title="NEW training">
              <div
                className={classes.control}
                style={{ width: '2em' }}
                role="button"
                tabIndex={0}
                onClick={event => {
                  event.stopPropagation()
                  initializeTrainingWizard()
                }}
                onKeyDown={event => {
                  event.stopPropagation()
                  if (event.keyCode === 13) initializeTrainingWizard()
                }}
              >
                <Icon type="train" />
              </div>
            </Tooltip>
          </div>
          {/* <div className={classes.title} style={{ height: '18px' }}>
            Train
          </div> */}
        </div>
        <Conditional dependencies={!!selectedTrainingId}>
          <div
            className={classes.menuGroup}
            style={{ width: `calc(${3 * 1.5}em + ${(3 + 1) * 10}px)` }}
          >
            <div className={classes.title} style={{ height: '26px' }}>
              Actions
            </div>
            <div className={classes.controlsDiv} style={{ height: '1.5em' }}>
              <Tooltip title="TRAIN with selected training spec">
                <div
                  className={
                    classes.control + (selectedTrainingId ? '' : ' disabled')
                  }
                  style={{ width: '1.5em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedTrainingId)}
                  onClick={event => {
                    event.stopPropagation()
                    if (selectedTrainingId)
                      startTrainingFromResource(
                        selectedTrainingId,
                        'training',
                        true,
                        'New training'
                      )
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13 && selectedTrainingId)
                      startTrainingFromResource(
                        selectedTrainingId,
                        'training',
                        true,
                        'New $training'
                      )
                  }}
                >
                  <Icon type="train" />
                </div>
              </Tooltip>
              <Tooltip title="SCORE with selected training spec">
                <div
                  className={
                    classes.control + (selectedTrainingId ? '' : ' disabled')
                    // `${classes.control} disabled`
                  }
                  style={{ width: '1.5em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedTrainingId)}
                  // tabIndex={-1}
                  onClick={event => {
                    event.stopPropagation()
                    if (selectedTrainingId) {
                      startScoringFromTraining(selectedTraining)
                    }
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13 && selectedTrainingId) {
                      startScoringFromTraining(selectedTraining)
                    }
                  }}
                >
                  <Icon type="score" />
                </div>
              </Tooltip>
              <Tooltip title="EDIT selected training">
                <div
                  className={
                    classes.control + (selectedTrainingId ? '' : ' disabled')
                  }
                  style={{ width: '1.5em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedTrainingId)}
                  onClick={event => {
                    event.stopPropagation()
                    editTrainingModalRef.current.open()
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) {
                      editTrainingModalRef.current.open()
                    }
                  }}
                >
                  <Icon type="edit-box" />
                </div>
              </Tooltip>
            </div>
          </div>
          <Conditional dependencies={selectedTraining?.status === 'RUNNING'}>
            <div
              className={classes.menuGroup}
              style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
            >
              <div className={classes.controlsDiv} style={{ height: '2em' }}>
                <Tooltip title="STOP selected training">
                  <div
                    className={
                      classes.control + (selectedTrainingId ? '' : ' disabled')
                    }
                    style={{ width: '2em' }}
                    role="button"
                    tabIndex={-1 + Number(!!selectedTrainingId)}
                    onClick={event => {
                      event.stopPropagation()
                      stopDialogRef.current.open()
                    }}
                    onKeyDown={event => {
                      event.stopPropagation()
                      if (event.keyCode === 13) {
                        stopDialogRef.current.open()
                      }
                    }}
                  >
                    <Icon type="pause" />
                  </div>
                </Tooltip>
              </div>
              {/* <div className={classes.title} style={{ height: '18px' }}>
              {null}
            </div> */}
            </div>
          </Conditional>
          <div
            className={classes.menuGroup}
            style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
          >
            <div className={classes.controlsDiv} style={{ height: '2em' }}>
              <Tooltip title="INSPECT selected training">
                <div
                  className={
                    classes.control + (selectedTrainingId ? '' : ' disabled')
                  }
                  style={{ width: '2em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedTrainingId)}
                  onClick={event => {
                    event.stopPropagation()
                    setInspectedTraining(selectedTrainingId)
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13)
                      setInspectedTraining(selectedTrainingId)
                  }}
                >
                  <Icon type="eye" style={{ fontSize: '1.2em' }} />
                </div>
              </Tooltip>
            </div>
            {/* <div className={classes.title} style={{ height: '18px' }}>
              {null}
            </div> */}
          </div>
          <div
            className={classes.menuGroup}
            style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
          >
            <div className={classes.controlsDiv} style={{ height: '2em' }}>
              <Tooltip title="VIEW selected training logs">
                <div
                  className={
                    classes.control + (selectedTrainingId ? '' : ' disabled')
                  }
                  style={{ width: '2em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedTrainingId)}
                  onClick={event => {
                    event.stopPropagation()
                    fetchTrainingLogs(selectedTrainingId)
                    logsDialogRef.current.open()
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) {
                      fetchTrainingLogs(selectedTrainingId)
                      logsDialogRef.current.open()
                    }
                  }}
                >
                  <Icon type="list" />
                </div>
              </Tooltip>
            </div>
            {/* <div className={classes.title} style={{ height: '18px' }}>
              {null}
            </div> */}
          </div>
          <div
            className={classes.menuGroup}
            style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
          >
            <div className={classes.controlsDiv} style={{ height: '2em' }}>
              <Tooltip title="DESELECT">
                <div
                  className={
                    classes.control + (selectedTrainingId ? '' : ' disabled')
                  }
                  style={{ width: '2em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedTrainingId)}
                  onClick={event => {
                    event.stopPropagation()
                    setSelectedTraining(null)
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) setSelectedTraining(null)
                  }}
                >
                  <Icon type="close" />
                </div>
              </Tooltip>
            </div>
            {/* <div className={classes.title} style={{ height: '18px' }}>
              {null}
            </div> */}
          </div>
        </Conditional>
      </div>
    </>
  )
}

export default TrainingsMenu
