import React from 'react'
import { useStore } from 'core'

import Icon from 'components/Icon'
import { CSVUploader } from 'components/CSVUploader'
import { Dialog, Button } from '@material-ui/core'

import 'style.scss'

const classes = {
  wrapper: 'FileStateInspector-wrapper',
  header: 'FileStateInspector-header',
  title: 'FileStateInspector-title',
  closeButton: 'FileStateInspector-closeButton',
  content: 'FileStateInspector-content',
}

const FileStateInspector = props => {
  const { fileStateDialogOpen: open, fileStateInspectorDialogToggle } = useStore(
    'selectFileStateDialogOpen',
    'fileStateInspectorDialogToggle'
  )

  return (
    <Dialog
      className={classes.dialog}
      fullScreen
      open={open}
      onClose={() => fileStateInspectorDialogToggle(false)}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.title}>File Uploader</div>
          <Button
            className={classes.closeButton}
            type="button"
            onClick={() => fileStateInspectorDialogToggle(false)}
          >
            <Icon type="close" />
          </Button>
        </div>
        <div className={classes.content}>
          <CSVUploader />
        </div>
      </div>
    </Dialog>
  )
}

export default FileStateInspector
