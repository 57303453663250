import React from 'react'
import PropTypes from 'prop-types'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'

import './style.scss'

const classes = {
  wrapper: 'ScoringTableHeader-wrapper',
  cell: 'ScoringTableHeader-cell',
  name: 'ScoringTableHeader-name',
  status: 'ScoringTableHeader-status',
  date: 'ScoringTableHeader-date',
  actions: 'ScoringTableHeader-actions',
}

const ScoringTableHeader = ({ sort }) => {
  const { column, order, callback } = sort

  return (
    <div className={classes.wrapper}>
      <div
        className={`${classes.cell} ${classes.name}${
          column === 'name' && order === 1 ? ' ascending' : ''
        }`}
        role="button"
        tabIndex={0}
        onClick={event => {
          event.stopPropagation()
          callback('name', column === 'name' ? (order + 1) % 3 : 1)
        }}
        onKeyDown={event => {
          event.stopPropagation()
          if (event.keyCode === 13) {
            callback('name', column === 'name' ? (order + 1) % 3 : 1)
          }
        }}
      >
        Name
        <Conditional dependencies={column === 'name'}>
          <Icon type="arrow-down" />
        </Conditional>
      </div>
      <div
        className={`${classes.cell} ${classes.status}${
          column === 'status' && order === 1 ? ' ascending' : ''
        }`}
        role="button"
        tabIndex={0}
        onClick={event => {
          event.stopPropagation()
          callback('status', column === 'status' ? (order + 1) % 3 : 1)
        }}
        onKeyDown={event => {
          event.stopPropagation()
          if (event.keyCode === 13) {
            callback('status', column === 'status' ? (order + 1) % 3 : 1)
          }
        }}
      >
        Status
        <Conditional dependencies={column === 'status'}>
          <Icon type="arrow-down" />
        </Conditional>
      </div>
      <div
        className={`${classes.cell} ${classes.date}${
          column === 'date' && order === 1 ? ' ascending' : ''
        }`}
        role="button"
        tabIndex={0}
        onClick={event => {
          event.stopPropagation()
          callback('date', column === 'date' ? (order + 1) % 3 : 1)
        }}
        onKeyDown={event => {
          event.stopPropagation()
          if (event.keyCode === 13) {
            callback('date', column === 'date' ? (order + 1) % 3 : 1)
          }
        }}
      >
        Last Scored
        <Conditional dependencies={column === 'date'}>
          <Icon type="arrow-down" />
        </Conditional>
      </div>
      <div className={`${classes.cell} ${classes.actions}`}>{/* Actions */}</div>
    </div>
  )
}

ScoringTableHeader.propTypes = {
  sort: PropTypes.shape({
    column: PropTypes.string,
    order: PropTypes.number,
    callback: PropTypes.func,
  }),
}

export default ScoringTableHeader
