import React, { useState } from 'react'
import { BaseCard as Card } from 'components/CardLayout'
import { Button, Switch } from '@material-ui/core'
import PropTypes from 'prop-types'

import { Conditional } from 'components/Conditional'

import './style.scss'

const classes = {
  container: 'PlanCard-container',
  name: 'PlanCard-name',
  price: 'PlanCard-price',
  billing: 'PlanCard-billing',
  purchaseButton: 'PlanCard-btn-purchase',
  brief: 'PlanCard-brief',
  features: 'PlanCard-features',
  licencesType: 'PlanCard-licencesType',
  licences: 'PlanCard-licences',
  processes: 'PlanCard-processes',
  sizeLimit: 'PlanCard-sizeLimit',
  description: 'PlanCard-description',
}

const deriveBillingKey = (plan, fallback) => {
  if (plan.monthly && plan.annually) return fallback
  if (Object.prototype.hasOwnProperty.call(plan, 'annually')) return 'annually'
  if (Object.prototype.hasOwnProperty.call(plan, 'monthly')) return 'monthly'
  if (Object.prototype.hasOwnProperty.call(plan, 'daily')) return 'daily'
  throw new Error('No plan billing')
}

const PlanCard = ({ plan, showDetails, subscribe }) => {
  const [billing, setBilling] = useState(plan.monthly ? 'monthly' : 'annually')

  const {
    // id,
    name,
    description,
    processes,
    licences,
    sizeLimit,
    currency,
  } = plan

  const billingHandler = e => {
    const newBilling = e.target.checked ? 'annually' : 'monthly'
    if (billing !== newBilling) setBilling(newBilling)
  }

  // plan billing key
  const pbk = deriveBillingKey(plan, billing)

  return (
    <Card className={classes.container}>
      <h1 className={classes.name}>{name}</h1>
      <Conditional dependencies={!plan.monthly || !plan.annually}>
        <div className={classes.price}>
          {`${Math.floor(
            Number(plan[pbk].price / 100)
          ).toLocaleString()} ${currency}`}
        </div>
        <Conditional dependencies={!plan.monthly || !plan.annually}>
          <div className={classes.billing}>billed {pbk}</div>
        </Conditional>
        <Conditional dependencies={plan.monthly && plan.annually}>
          <div className={classes.billing}>
            <div>monthly</div>
            <div>
              <Switch
                color="primary"
                checked={billing === 'annually'}
                onChange={billingHandler}
              />
            </div>
            <div>annually</div>
          </div>
        </Conditional>
      </Conditional>
      <Conditional dependencies={plan.monthly && plan.annually}>
        <div className={classes.price}>
          {Math.floor(Number(plan[pbk].price / 100)).toLocaleString()} {currency}
        </div>
        {/* <div className={classes.billing}>billed {billing}</div> */}
        <div className={classes.billing}>
          <div>monthly</div>
          <div>
            <Switch
              color="primary"
              checked={billing === 'annually'}
              onChange={billingHandler}
            />
          </div>
          <div>annually</div>
        </div>
      </Conditional>
      <Button
        className={classes.purchaseButton}
        variant="contained"
        color="primary"
        onClick={() => subscribe(plan[pbk].chargeBeeId)}
      >
        Subscribe
      </Button>
      {/* <div className={classes.brief}> */}
      <Conditional dependencies={showDetails}>
        <div className={classes.features}>Features:</div>
        <>
          <div className={classes.licences}>{` - ${licences} Licences`}</div>
          <div className={classes.processes}>
            {` - ${processes} Parallel Processes`}
          </div>
          <div className={classes.sizeLimit}>
            {` - ${sizeLimit} Dataset Size Limit`}
          </div>
        </>
        <div className={classes.description}>
          {description ||
            // eslint-disable-next-line max-len
            'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur minus porro, rerum deleniti cupiditate dolorem aliquid nihil distinctio vitae! Ipsa!'}
        </div>
        {/* </div> */}
      </Conditional>
    </Card>
  )
}

PlanCard.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.string.isRequired,
    chargeBeeId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    currency: PropTypes.string.isRequired,
    licenceType: PropTypes.string.isRequired,
    licences: PropTypes.number.isRequired,
    processes: PropTypes.number.isRequired,
    sizeLimit: PropTypes.number.isRequired,
    monthly: PropTypes.shape({
      price: PropTypes.number,
      chargeBeeId: PropTypes.string,
    }),
    annually: PropTypes.shape({
      price: PropTypes.number,
      chargeBeeId: PropTypes.string,
    }),
    daily: PropTypes.shape({
      price: PropTypes.number,
      chargeBeeId: PropTypes.string,
    }),
  }),
  showDetails: PropTypes.bool,
  subscribe: PropTypes.func,
}

export default PlanCard
