/* eslint-disable react/prop-types */
import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import invariant from 'tiny-invariant'
import { useStore } from 'core'
import './style.scss'

import { Conditional } from 'components/Conditional'
import { Table, Column } from 'components/Table'
import { Pagination } from 'components/Pagination'
import Spinner from 'components/Spinner'

import { classes } from './classNames'

import { useRenderer } from './renderer'

const ResourceTable = ({ requestKey, resource, perPage, ...rest }) => {
  const {
    resourceDataRequests,
    registerResourceRequest,
    setResourceRequestPagination,
  } = useStore(
    'selectResourceDataRequests',
    'registerResourceRequest',
    'setResourceRequestPagination'
  )

  const requestedResource = useMemo(
    () => resourceDataRequests?.find(e => e.key === requestKey) || null,
    [requestKey, resourceDataRequests]
  )

  const {
    data: resourceData = null,
    meta: resourceMeta = null,
    page: resourcePage = 1,
  } = requestedResource || {}

  const currentPage = resourcePage || 1
  const setCurrentPage = page =>
    setResourceRequestPagination(requestKey, page, perPage)

  // DO NOT CHANGE THE DEPENDENCIES HERE, its important.
  // The table must never change it's request while mounted
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requestKeySaved = useMemo(() => requestKey, [])
  invariant(
    requestKey === requestKeySaved,
    'ResourceTable\'s "requestKey" prop must not change during the component lifetime.'
  )

  useEffect(() => {
    registerResourceRequest(requestKey, resource, 'table', 1, perPage, true)
    // DO NOT CHANGE THE DEPENDENCIES HERE, its important.
    // The table must never change it's request while mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.wrapper}>
      <Conditional dependencies={!resourceData?.length}>
        <div className={classes.loader}>
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
        </div>
      </Conditional>
      <Conditional dependencies={resourceData?.length}>
        <Table
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          data={resourceData || []}
          // footer
        >
          <Column
            dataKey="_defaultColumn"
            width={175}
            render={useRenderer(requestedResource)}
          />

          <Column dataKey="_id" hidden />
        </Table>
      </Conditional>
      <Conditional dependencies={resourceData?.length}>
        <Pagination
          dataLength={resourceMeta?.count}
          perPage={perPage}
          currentPage={currentPage}
          pageCallback={setCurrentPage}
        />
      </Conditional>
    </div>
  )
}

ResourceTable.propTypes = {
  requestKey: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  perPage: PropTypes.number.isRequired,
}

export default ResourceTable
