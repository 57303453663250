/* eslint-disable camelcase */
import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import 'style.scss'

import { v4 as uuid } from 'uuid'
import { formatDate } from 'libs/utils/helpers'

import Conditional from 'components/Conditional'
import { Form, FormItem } from 'components/Form'
import Spinner from 'components/Spinner'
import AlertDialog from 'components/AlertDialog'
import Modal from 'components/Modal'

import { TextField, MenuItem, Button } from '@material-ui/core'

import { classes as classNames } from '../../classNames'
import { validator as prevValidator } from '../1'

const classes = {
  ...classNames,
  stepTwo: 'TrainingWizard-step-two',
  noDataMsg: 'TrainingWizard-no-data-msg',
  alternativeDataMsg: 'TrainingWizard-alternative-data-msg',
  alternativeActions: 'TrainingWizard-alternative-actions',
  actionLink: 'TrainingWizard-action-link',
  pipelineProcessing: 'TrainingWizard-pipelineProcessingWrapper',
  pipelineProcessingMessage: 'TrainingWizard-pipelineProcessingMessage',
  pipelineWarning: 'TrainingWizard-pipelineWarningWrapper',
  pipelineError: 'TrainingWizard-pipelineErrorWrapper',
}

export const validator = training => {
  const { pipeline, pipeline_output } = training
  return prevValidator(training) && !!pipeline.length && !!pipeline_output
}

const createDefaultPipeline = (datasources, datasetID) => {
  const meta = datasources.find(ds => ds.id === datasetID).spec || {}
  const pipeline = meta.default_transformation || []
  const { stacked_by } = meta
  return [
    {
      type: 'mapping',
      config: {
        rules: [],
      },
      locationArray: [0],
    },
    ...pipeline,
    {
      type: 'dataset',
      config: { rules: [] },
      datasetArrayIndex: 0,
      locationArray: [pipeline.length],
      stacked_by,
    },
  ]
}

const outputHasGaps = meta => {
  return meta ? meta.features.some(feature => feature.stats.nmissing) : null
}

const outputHasLowVariance = meta => {
  return meta
    ? meta.features.some(
        feature => meta.stacked_by !== feature.id && feature.stats.variance < 2
      )
    : null
}

const TrainingWizardStepTwo = props => {
  const modalRef = useRef()
  const formRef = useRef()
  const [error, setError] = useState(false)

  const [transformation, setTransformation] = useState('')
  const warningDialog = useRef()
  const { training } = props
  const {
    id,
    datasets,
    step,
    pipeline,
    pipeline_processing_id,
    pipeline_operations_count,
    pipeline_processing_progress,
    pipeline_output,
    pipeline_processing_error,
    pipeline_id,
  } = training
  const {
    datasets: datasources,
    transformations: all_transforms,
    activePipeline,
    setActivePipeline,
    setTrainingWizardPipeline,
    saveTransformation,
  } = useStore(
    'selectDatasets',
    'selectTransformations',
    'selectActivePipeline',
    'setActivePipeline',
    'setTrainingWizardPipeline',
    'saveTransformation'
  )
  const transformations =
    all_transforms && all_transforms.filter(e => e.datasets.length && e.spec.length)
  const pipelineName = pipeline_id
    ? transformations.find(e => e.id === pipeline_id)?.name
    : null

  const stepCompleted = validator(training)
  const isPipelineManagerConnected = training.id === activePipeline.id

  const processingProgressMessage =
    pipeline_operations_count &&
    pipeline_processing_progress !== pipeline_operations_count
      ? `${pipeline_processing_progress}/${pipeline_operations_count}`
      : ''

  const lowVarianceFeatures = pipeline_output
    ? pipeline_output.features
        .filter(
          feature =>
            pipeline_output.stacked_by !== feature.id && feature.stats.variance < 2
        )
        .reduce((str, feature) => {
          if (str.length >= 20) return str
          let temp = `${str}`
          if (temp.length) temp = `${temp}, `
          temp += feature.name
          if (temp.length >= 20) temp = `${temp.substr(0, 17)}... `
          return temp
        }, '')
    : ''

  const savePipelineHandler = useCallback(
    (pID, pSpec, pDatasets, pMeta) => {
      setTrainingWizardPipeline(pSpec, pDatasets)
    },
    [setTrainingWizardPipeline]
  )

  const connectPipelineHandler = () =>
    setActivePipeline(
      id,
      pipeline.length ? pipeline : createDefaultPipeline(datasources, datasets[0]),
      datasets,
      savePipelineHandler,
      null,
      true,
      'New Pipeline'
    )

  const discardPipelineHandler = () => {
    setTrainingWizardPipeline([], [datasets[0]])
  }

  const wizardPipelineSetter = () => {
    const {
      spec: tfSpec,
      datasets: tfDatasets,
      id: transformation_id,
    } = transformations.find(tf => tf.id === transformation)
    setTrainingWizardPipeline(tfSpec, tfDatasets, transformation_id)
  }

  const selectTransformationHandler = () => {
    const { datasets: tfDatasets } = transformations.find(
      tf => tf.id === transformation
    )
    if (tfDatasets && tfDatasets.length && tfDatasets.some(e => e === datasets[0])) {
      wizardPipelineSetter()
    } else {
      warningDialog.current.open()
    }
  }

  return (
    <div className={classes.step} data-active={step === 1}>
      <Modal
        ref={modalRef}
        title="New transformation"
        okText="Ok"
        onOk={() => {
          const data = formRef.current.get()
          setError(!data.name)
          if (data.name) {
            modalRef.current.close()
            saveTransformation({
              spec: pipeline,
              datasets,
              name: data.name,
              description: data.description,
            })
          }
        }}
        onCancel={() => {
          modalRef.current.close()
        }}
        footer
      >
        <div className={classes.formDiv}>
          <Form ref={formRef}>
            <FormItem
              dataKey="name"
              defaultValue={`New Transformation ${formatDate(Date.now())}`}
              margin="dense"
              label="Name"
              fullWidth
              inputProps={{ autoFocus: true }}
              error={error}
              helperText={error ? 'Field is required' : ''}
              component={TextField}
            />
            <FormItem
              dataKey="description"
              defaultValue=""
              margin="dense"
              label="Description"
              fullWidth
              multiline
              component={TextField}
            />
          </Form>
        </div>
      </Modal>
      <AlertDialog
        ref={warningDialog}
        title="Confirm?"
        content="Choosing this pipeline will modify the dataset selection, do you want to continue?"
        okText="Yes"
        cancelText="Cancel"
        onOk={event => {
          event.stopPropagation()
          event.preventDefault()
          wizardPipelineSetter()
          warningDialog.current.close()
        }}
        onCancel={event => {
          event.stopPropagation()
          warningDialog.current.close()
        }}
      />
      <div className={classes.progressWrapper}>
        <div className={classes.progressBar} data-completed={stepCompleted} />
      </div>
      <div className={`${classes.stepContent} ${classes.stepTwo}`}>
        <h2>Choose a pipeline:</h2>
        <Conditional dependencies={!pipeline.length && !transformations}>
          <span className={classes.noDataMsg}>No pipelines available.</span>
          <span className={classes.alternativeDataMsg}>
            {'Please create a '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={classes.actionLink}
              /* eslint-disable-next-line no-script-url */
              href="javascript:void(0)"
              onClick={connectPipelineHandler}
            >
              new pipeline
            </a>
            {' instead.'}
          </span>
        </Conditional>
        <Conditional dependencies={!pipeline.length && transformations}>
          <TextField
            disabled={step !== 1}
            select
            value={transformation}
            label="Please select a pipeline"
            onChange={event => setTransformation(event.target.value)}
          >
            {transformations &&
              transformations
                .sort((a, b) => {
                  if (a.name < b.name) return -1
                  if (a.name > b.name) return 1
                  return 0
                })
                .map(tf => (
                  <MenuItem key={tf.id} value={tf.id}>
                    {tf.name}
                  </MenuItem>
                ))}
          </TextField>
          <Conditional dependencies={transformation}>
            <Button
              disabled={step !== 1}
              style={{ marginTop: '20px' }}
              color="primary"
              variant="contained"
              onClick={selectTransformationHandler}
            >
              Use this pipeline
            </Button>
          </Conditional>
          <span className={classes.alternativeDataMsg}>
            {'or create a '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={classes.actionLink}
              /* eslint-disable-next-line no-script-url */
              href="javascript:void(0)"
              onClick={connectPipelineHandler}
            >
              new pipeline
            </a>
            {' instead.'}
          </span>
        </Conditional>
        <Conditional dependencies={pipeline.length}>
          <span className={classes.alternativeDataMsg}>
            {pipelineName
              ? `Using ${pipelineName}'s pipeline.`
              : 'Using an Unnamed pipeline.'}
          </span>
          <div className={classes.alternativeActions}>
            <Button
              disabled={step !== 1 || isPipelineManagerConnected}
              variant="outlined"
              onClick={discardPipelineHandler}
            >
              Discard
            </Button>
            <Button
              disabled={step !== 1 || isPipelineManagerConnected}
              variant="contained"
              color="primary"
              onClick={connectPipelineHandler}
            >
              Edit
            </Button>
            <Conditional dependencies={!pipeline_id}>
              <Button
                disabled={step !== 1 || isPipelineManagerConnected}
                variant="contained"
                color="primary"
                onClick={() => modalRef.current.open()}
              >
                Save As
              </Button>
            </Conditional>
          </div>
        </Conditional>
        <Conditional dependencies={pipeline_processing_id}>
          <div className={classes.pipelineProcessing}>
            <Spinner
              spin={1}
              strokeWidth={2}
              mainColor="#1E90FF"
              emptyColor="#2e313a"
            />
            <div className={classes.pipelineProcessingMessage}>
              {processingProgressMessage}
            </div>
          </div>
        </Conditional>
        <Conditional dependencies={pipeline_processing_error}>
          <div className={classes.pipelineError}>
            <h3>Pipeline processing has failed.</h3>
            <span>Pleaes edit the pipeline and to fix the error.</span>
          </div>
        </Conditional>
        <Conditional dependencies={outputHasGaps(pipeline_output)}>
          <div className={classes.pipelineWarning}>
            <h3>We have detected gaps in the output.</h3>
            <span>Non-objective features must not have gaps,</span>
            <span>please imputate your data.</span>
          </div>
        </Conditional>
        <Conditional dependencies={outputHasLowVariance(pipeline_output)}>
          <div className={classes.pipelineWarning}>
            <h3>Some features have no variance.</h3>
            <span>Features with no variance are meaningless,</span>
            <span>please filter them out of your data.</span>
            <span>({lowVarianceFeatures})</span>
          </div>
        </Conditional>
      </div>
    </div>
  )
}

TrainingWizardStepTwo.propTypes = {
  training: PropTypes.object.isRequired,
}

export default TrainingWizardStepTwo
