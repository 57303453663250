import React, { useRef, useState, useMemo } from 'react'
import { useStore } from 'core'

import { BaseCard as Card } from 'components/CardLayout'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import Conditional from 'components/Conditional'
import { Form, FormItem } from 'components/Form'
import { MenuItem, TextField, Button } from '@material-ui/core'

import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'

import './style.scss'

const classes = {
  wrapper: 'ProfilePage-wrapper',
  section: 'AccountPages-section',
  sectionTitle: 'AccountPages-section-title',
  sectionSubtitle: 'AccountPages-section-subtitle',
  formDiv: 'AccountPages-formDiv',
  avatarDiv: 'ProfilePage-avatarDiv',
  avatarControls: 'ProfilePage-avatarControls',
}

const matchPasswords = (p1, p2) => {
  if (p1 === p2) return true
  if ((p1 === undefined && p2 === '') || (p1 === '' && p2 === undefined)) return true
  return p1 === p2
}

const ProfilePage = props => {
  const formRef = useRef()
  const [currentPassword, setCurrentPassword] = useState('')
  const [passwordRequiredError, setPasswordRequiredError] = useState('')
  const [passwordMatchError, setPasswordMatchError] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const {
    user,
    updateUser,
    passwordVerificationRequestInProgress,
    passwordVerificationInProgress,
    passwordVerificationError,
    requestPasswordVerification,
    cancelPasswordVerification,
  } = useStore(
    'selectUser',
    'updateUser',
    'selectPasswordVerificationRequestInProgress',
    'selectPasswordVerificationInProgress',
    'selectPasswordVerificationError',
    'requestPasswordVerification',
    'cancelPasswordVerification'
  )

  const modalError = passwordVerificationError
    ? 'Password incorrect'
    : passwordRequiredError

  const {
    id,
    email,
    first_name: firstname,
    last_name: lastname,
    api_key: apiKey,
    // username,
    // phone,
    organization: { name: organization },
    // country = 'DE',
    // timezone = 'Etc/Greenwich',
  } = user

  const formTouchHandler = e => {
    if (formDirty) return
    const dirty = formRef.current.isDirty()
    if (dirty && !formDirty) {
      setFormDirty(true)
    }
  }

  const formSubmitHandler = () => {
    const { password } = formRef.current.get()
    const { new: newPassword, repeat: repeatPassword } = password

    // Passwords change match
    const passwordsMatch = matchPasswords(newPassword, repeatPassword)
    if (!passwordsMatch) setPasswordMatchError(true)
    else setPasswordMatchError(false)

    if (passwordsMatch) {
      requestPasswordVerification()
      setCurrentPassword('')
      setPasswordRequiredError('')
    }
  }

  const showPasswordToggle = () => setShowPassword(!showPassword)
  const showRepeatPasswordToggle = () => setShowRepeatPassword(!showRepeatPassword)
  // eslint-disable-next-line prettier/prettier
  const showCurrentPasswordToggle = () => setShowCurrentPassword(!showCurrentPassword)

  return (
    <div className={classes.wrapper}>
      <Modal
        open={passwordVerificationRequestInProgress}
        title="Authorization"
        okText="Update"
        disabled={passwordVerificationInProgress}
        onOk={() => {
          if (!currentPassword) setPasswordRequiredError('Field is required')
          if (passwordRequiredError && currentPassword) setPasswordRequiredError('')
          if (currentPassword) {
            const data = formRef.current.get()
            updateUser(
              currentPassword,
              data.firstname,
              data.lastname,
              data.email,
              data.password.new
            )
          }
        }}
        onCancel={() => cancelPasswordVerification()}
        onClose={() => cancelPasswordVerification()}
        footer
      >
        <div className={classes.wrapper}>
          <form
            style={{ width: '100%' }}
            onSubmit={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <TextField
              margin="dense"
              label="Current password"
              fullWidth
              autoComplete="current-password"
              type={showCurrentPassword ? 'text' : 'password'}
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
              error={!!modalError}
              helperText={modalError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showCurrentPasswordToggle}
                    >
                      {showCurrentPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </div>
      </Modal>
      <Card>
        <div className={classes.section}>
          <h1 className={classes.sectionTitle}>Account Info</h1>
          <span>{`ID: ${id}`}</span>
          <span>{`\nAPI KEY: ${apiKey}`}</span>
          <div className={classes.formDiv}>
            {/* <div className={classes.avatarDiv}>
              <img />
              <span>AVATAR</span>
              <div className={classes.avatarControls}>
                <button type="button" onClick={() => {}}>
                  <Icon type="download" />
                </button>
                <button type="button" onClick={() => {}}>
                  <Icon type="upload" />
                </button>
              </div>
            </div> */}
            <Form
              onChange={formTouchHandler}
              ref={formRef}
              preload={{
                firstname,
                lastname,
                organization,
                oldemail: email,
                email: '',
                password: {
                  new: '',
                  repeat: '',
                },
              }}
            >
              {/* <h2 className={classes.sectionSubtitle} style={{ marginTop: 0 }}>
                Personal Info
              </h2> */}
              <FormItem
                dataKey="firstname"
                margin="dense"
                label="First Name"
                // inputProps={{ autoFocus: true }}
                // error={nameError}
                // helperText={nameError ? 'Field is required' : ''}
                component={TextField}
              />
              <FormItem
                dataKey="lastname"
                margin="dense"
                label="Last Name"
                // inputProps={{ autoFocus: true }}
                // error={nameError}
                // helperText={nameError ? 'Field is required' : ''}
                component={TextField}
              />
              {/* <FormItem
                dataKey="phone"
                margin="dense"
                label="Phone"
                inputProps={{ type: 'tel' }}
                component={TextField}
              /> */}
              <FormItem
                dataKey="organization"
                disabled
                margin="dense"
                label="Organization"
                component={TextField}
              />
              <h2 className={classes.sectionSubtitle}>Change e-mail</h2>
              <FormItem
                dataKey="oldemail"
                margin="dense"
                disabled
                label="Current e-mail"
                // inputProps={{ autoFocus: true }}
                // error={nameError}
                // helperText={nameError ? 'Field is required' : ''}
                component={TextField}
              />
              <FormItem
                dataKey="email"
                margin="dense"
                label="New e-mail"
                autoComplete="off"
                // inputProps={{ autoFocus: true }}
                // error={nameError}
                // helperText={nameError ? 'Field is required' : ''}
                component={TextField}
              />
              <h2 className={classes.sectionSubtitle}>Change password</h2>
              <FormItem
                dataKey="password.new"
                type={showPassword ? 'text' : 'password'}
                autoComplete="off"
                margin="dense"
                label="New password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={showPasswordToggle}
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                component={TextField}
              />
              <FormItem
                dataKey="password.repeat"
                type={showRepeatPassword ? 'text' : 'password'}
                autoComplete="off"
                margin="dense"
                label="Repeat password"
                error={passwordMatchError}
                helperText={passwordMatchError ? 'Passwords must match' : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={showRepeatPasswordToggle}
                      >
                        {showRepeatPassword ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                component={TextField}
              />
            </Form>
            <Conditional dependencies={formDirty}>
              <Button
                style={{ alignSelf: 'flex-end', marginRight: '10px', width: '10em' }}
                color="primary"
                onClick={formSubmitHandler}
              >
                Update
              </Button>
            </Conditional>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ProfilePage
