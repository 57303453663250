/* eslint-disable no-console */
import React, { useState } from 'react'

import Conditional from 'components/Conditional'
import { LineChart, ScatterChart } from 'components/Charts'

import './style.scss'

const classes = {
  wrapper: 'ChartsPage-wrapper',
  header: 'ExplanationCharts-header',
  headerButton: 'ExplanationCharts-headerButton',
  test: 'ExplanationCharts-test',
}

const ExplanationCharts = props => {
  const [state, setState] = useState('datasources')

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <button
          type="button"
          className={classes.headerButton}
          onClick={() => setState('trainings')}
          style={{ border: state === 'trainings' ? '1px solid dodgerblue' : '' }}
        >
          TRAININGS
        </button>
        <button
          type="button"
          className={classes.headerButton}
          onClick={() => setState('datasources')}
          style={{ border: state === 'datasources' ? '1px solid dodgerblue' : '' }}
        >
          DATASOURCES
        </button>
      </div>
      <Conditional dependencies={state === 'trainings'}>
        <div className={classes.test}>
          <ScatterChart
            controlable={{
              trainings: { multiple: true },
              brains: { multiple: true },
              split: true,
              x: { multiple: true },
              y: { multiple: true },
              z: true,
            }}
            legend={{ position: 'right', width: 350 }}
            xAxis
            yAxis
            grid
            split="test"
            mainColor="#1E90FF"
          />
        </div>
        <div className={classes.test}>
          <LineChart
            controlable={{
              trainings: { multiple: true },
              brains: { multiple: true },
              features: { multiple: true },
              split: true,
              type: true,
            }}
            legend={{ position: 'right', width: 350 }}
            xAxis
            yAxis
            grid
            split="test"
            mainColor="#1E90FF"
          />
        </div>
      </Conditional>
      <Conditional dependencies={state === 'datasources'}>
        <div className={classes.test}>
          <ScatterChart
            controlable={{
              datasets: { multiple: true },
              stackingCategories: { multiple: true },
              x: { multiple: true },
              y: { multiple: true },
              z: true,
            }}
            legend={{ position: 'right', width: 350 }}
            xAxis
            yAxis
            grid
            mainColor="#1E90FF"
          />
        </div>
        <div className={classes.test}>
          <LineChart
            controlable={{
              datasets: { multiple: true },
              stackingCategories: { multiple: true },
              x: { multiple: true },
              y: { multiple: true },
            }}
            legend={{ position: 'right', width: 350 }}
            xAxis
            yAxis
            grid
            mainColor="#1E90FF"
          />
        </div>
      </Conditional>
    </div>
  )
}

export default ExplanationCharts
