import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import 'style.scss'

import Conditional from 'components/Conditional'
// import AlertDialog from 'components/AlertDialog'
import { DynamicDatasourceQueryModal } from 'components/ResourceModalComponents'

import { TextField, MenuItem } from '@material-ui/core'

import { classes as classNames } from '../../classNames'

const classes = {
  ...classNames,
  stepOne: 'TrainingWizard-step-one',
  noDataMsg: 'TrainingWizard-no-data-msg',
  alternativeDataMsg: 'TrainingWizard-alternative-data-msg',
  actionLink: 'TrainingWizard-action-link',
}

export const validator = training => {
  const { datasets } = training
  return !!datasets.length && !datasets.some(e => e === null)
}

const TrainingWizardStepOne = props => {
  const dynamicModal = useRef()
  const { training } = props
  const { datasets, step } = training
  const {
    datasets: datasources,
    setTrainingWizardDataset,
    fileStateInspectorDialogToggle,
  } = useStore(
    'selectDatasets',
    'setTrainingWizardDataset',
    'fileStateInspectorDialogToggle'
  )

  const stepCompleted = validator(training)

  const datasourcesAvailable = datasources
    ? !!datasources.filter(ds => ds.status === 'READY').length
    : false

  const datasetInput = useMemo(() => {
    return datasets.map((datasetID, i) => {
      const value = datasetID !== null ? datasetID : ''
      return (
        <TextField
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          disabled={step !== 0}
          select
          value={value}
          label="Please select a datasource"
          onChange={event => setTrainingWizardDataset(event.target.value, i)}
        >
          {datasources &&
            datasources
              .sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
              })
              .map(datasource => (
                <MenuItem key={datasource.id} value={datasource.id}>
                  {datasource.name}
                </MenuItem>
              ))}
        </TextField>
      )
    })
  }, [step, datasets, datasources, setTrainingWizardDataset])

  return (
    <>
      <DynamicDatasourceQueryModal ref={dynamicModal} />
      <div className={classes.step} data-active={step === 0}>
        <div className={classes.progressWrapper}>
          <div className={classes.progressBar} data-completed={stepCompleted} />
        </div>
        <div className={`${classes.stepContent} ${classes.stepOne}`}>
          <h2>Choose a datasource:</h2>
          <Conditional dependencies={!datasourcesAvailable}>
            <span className={classes.noDataMsg}>No datasources available.</span>
            <span className={classes.alternativeDataMsg}>
              {'Please upload a new '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={classes.actionLink}
                /* eslint-disable-next-line no-script-url */
                href="javascript:void(0)"
                onClick={() => fileStateInspectorDialogToggle(true)}
              >
                static
              </a>
              {' / '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={classes.actionLink}
                /* eslint-disable-next-line no-script-url */
                href="javascript:void(0)"
                onClick={() => dynamicModal.current.open()}
              >
                dynamic
              </a>
              {' datasource.'}
            </span>
          </Conditional>
          <Conditional dependencies={datasourcesAvailable}>
            {datasetInput}
            <span className={classes.alternativeDataMsg}>
              {'or upload a '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={classes.actionLink}
                /* eslint-disable-next-line no-script-url */
                href="javascript:void(0)"
                onClick={() => fileStateInspectorDialogToggle(true)}
              >
                static
              </a>
              {' / '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={classes.actionLink}
                /* eslint-disable-next-line no-script-url */
                href="javascript:void(0)"
                onClick={() => dynamicModal.current.open()}
              >
                dynamic
              </a>
              {' datasource.'}
            </span>
          </Conditional>
        </div>
      </div>
    </>
  )
}

TrainingWizardStepOne.propTypes = {
  training: PropTypes.object.isRequired,
}

export default TrainingWizardStepOne
