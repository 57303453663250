import React from 'react'
import { Switch, Route } from 'core'

import ResourcesContextualMenuRouter from './Routers/ResourcesContextualMenuRouter'

import 'style.scss'

const ContextualMenu = () => {
  return (
    <Switch>
      {/* <Route path="/dashboard" component={Dashboard} /> */}
      <Route path="/resources" component={ResourcesContextualMenuRouter} />
      {/* <Route path="/projects" component={ProjectsRouter} /> */}
      {/* <Route path={['/pipeline/:id', '/pipeline']} component={PipelinePage} /> */}
      {/* <Route path="/dev" component={DevRouter} /> */}
      {/* <Route path="/uploads" component={Uploads} /> */}
    </Switch>
  )
}

export default ContextualMenu
