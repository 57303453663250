import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'core'

import DatasourcesMenu from '../Menus/DatasourcesMenu'
import TransformationsMenu from '../Menus/TransformationsMenu'
import TrainingsMenu from '../Menus/TrainingsMenu'
import ScoringsMenu from '../Menus/ScoringsMenu'
// import ResourceViewMenu from '../Menus/ResourceViewMenu'

const ResourcesContextualMenuRouter = props => {
  const { match } = props

  return (
    <>
      {/* <Switch>
        <Route
          path={`${match.path}/datasources`}
          render={() => <ResourceViewMenu resource="datasources" />}
        />
        <Route
          path={`${match.path}/pipelines`}
          render={() => <ResourceViewMenu resource="pipelines" />}
        />
        <Route
          path={`${match.path}/models`}
          render={() => <ResourceViewMenu resource="models" />}
        />
        <Route
          path={`${match.path}/predictions`}
          render={() => <ResourceViewMenu resource="predictions" />}
        />
      </Switch> */}
      <Switch>
        <Route path={`${match.path}/datasources`} component={DatasourcesMenu} />
        <Route path={`${match.path}/pipelines`} component={TransformationsMenu} />
        <Route path={`${match.path}/models`} component={TrainingsMenu} />
        <Route path={`${match.path}/predictions`} component={ScoringsMenu} />
        {/* <Route path={`${match.path}`} component={ProjectsPage} /> */}
      </Switch>
    </>
  )
}

ResourcesContextualMenuRouter.propTypes = {
  match: PropTypes.exact({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.object,
    isExact: PropTypes.bool,
  }),
}

export default ResourcesContextualMenuRouter
