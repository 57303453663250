import React, { Children, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useRefs, useRefRect } from 'libs/utils/hooks'

import CardWrapper from './CardWrapper'
import { Provider } from './CardContext'

import './style.scss'

const classes = {
  wrapper: 'CardLayout-wrapper',
}

const CardLayout = props => {
  const [getRef, setRef] = useRefs()
  const [rect, layoutRef] = useRefRect()
  const [
    sortProp,
    // setSortProp
  ] = useState(null)

  const {
    direction = 'wrap',
    stretch = true,
    justify = 'center',
    sort = {},
    padding = 0,
    spacing = 20,
    children,
  } = props

  const sortOrder = sort.order || 1

  const [cards, cardsData] = useMemo(() => {
    const sortFunction = sort.function || ((a, b) => a - b)
    const childrenObjs = []
    const tempCards = React.Children.toArray(children).map((child, i, n) => {
      const { id } = child.props
      const ref = setRef(`Card-${id}`)
      childrenObjs[i] = {
        ref: () => getRef(`Card-${id}`),
        props: child.props,
        id: child.props.id,
      }
      return (
        <CardWrapper
          // eslint-disable-next-line react/no-array-index-key
          key={`Card-${id}`}
          cardRef={ref}
          id={id}
          spacing={spacing}
          padding={padding}
          direction={direction}
          stretch={(direction === 'row' || direction === 'column') && stretch}
        >
          {child}
        </CardWrapper>
      )
    })
    const sortedCardsData = [...childrenObjs].sort((a, b) => {
      const value = sortProp || sort.default
      // const tempResult = (a.props[value] - b.props[value]) * sortOrder || 0
      const tempResult =
        sortFunction(a.props[value] || 0, b.props[value] || 0) * sortOrder
      return tempResult
    })
    return [tempCards, sortedCardsData]
  }, [
    children,
    setRef,
    getRef,
    spacing,
    padding,
    direction,
    stretch,
    sort.default,
    sort.function,
    sortOrder,
    sortProp,
  ])

  const cardRows = [...cardsData]
    .reduce((acc, cur, i) => {
      //   const maxHeight = (rect && rect.height - 2 * padding) || 0
      const layoutWidth = (rect && rect.width - 2 * padding) || 0
      const tempResult = [...acc]
      if (!tempResult.length)
        tempResult.push({
          height: 0,
          space: layoutWidth + spacing,
          cards: [],
        })
      const row = tempResult[tempResult.length - 1]
      let maxWidth = row.space
      if (direction === 'column') maxWidth = 0
      if (direction === 'row') maxWidth = Infinity
      const cardRef = cur.ref().current
      const cardRect = cardRef && cardRef.getBoundingClientRect()
      const cardWidth = cardRect && cardRect.width
      const cardHeight = cardRect && cardRect.height

      if (cardWidth + spacing > maxWidth) {
        tempResult.push({
          height: cardHeight,
          space: layoutWidth - cardWidth,
          cards: [cur.id],
        })
        return tempResult
      }

      row.height = Math.max(row.height, cardHeight)
      row.space -= cardWidth + spacing
      row.cards.push(cur.id)

      return tempResult
    }, [])
    .filter(row => !!row.cards.length)

  const contextData = { cardsData, cardRows, justify }

  return (
    <div
      ref={layoutRef}
      className={classes.wrapper}
      style={{
        overflowX: direction === 'row' ? 'auto' : 'hidden',
        overflowY: direction === 'row' ? 'hidden' : 'auto',
      }}
    >
      <Provider value={contextData}>{cards}</Provider>
    </div>
  )
}

CardLayout.propTypes = {
  direction: PropTypes.string,
  stretch: PropTypes.bool,
  justify: PropTypes.oneOf(['left', 'center', 'right']),
  sort: PropTypes.shape({
    function: PropTypes.func,
    default: PropTypes.string,
    order: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.string),
  }),
  padding: PropTypes.number,
  spacing: PropTypes.number,
  children: PropTypes.node,
}

export default CardLayout
