/* eslint-disable camelcase */
import React, { useMemo, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import { Dialog, Button, TextField, MenuItem } from '@material-ui/core'
import MinimizeIcon from '@material-ui/icons/Close'
import Modal from 'components/Modal'
import AlertDialog from 'components/AlertDialog'
import ScoringWizardStepOne, { validator as step1Validator } from './steps/1'
import ScoringWizardStepTwo, { validator as step2Validator } from './steps/2'
import ScoringWizardStepThree, { validator as step3Validator } from './steps/3'

import './style.scss'
import { classes } from './classNames'

const ScoringWizard = props => {
  const {
    activeScoring,
    closeScoringWizard,
    discardActiveScoring,
    rejectScoringChange,
    scoringWizardPrevStep,
    scoringWizardNextStep,
    scoringWizardStartScoring,
  } = useStore(
    'selectActiveScoring',
    'closeScoringWizard',
    'discardActiveScoring',
    'rejectScoringChange',
    'scoringWizardPrevStep',
    'scoringWizardNextStep',
    'scoringWizardStartScoring'
  )

  const { open, title, step, id, pending } = activeScoring
  const discardWarning = !!(id && pending)

  const isPipelineManagerConnected = false
  const step1Complete = step1Validator(activeScoring)
  const step2Complete = step2Validator(activeScoring)
  const step3Complete = step3Validator(activeScoring)

  const prevDisabled = isPipelineManagerConnected ? true : step === 0
  const nextText = step === 2 ? 'Finish' : 'Next'
  const nextDisabled = useMemo(() => {
    if (isPipelineManagerConnected) return true
    if (step3Complete) return false
    if (step2Complete && step < 2) return false
    if (step1Complete && step < 1) return false
    return true
  }, [isPipelineManagerConnected, step, step1Complete, step2Complete, step3Complete])

  const nextCallback = useCallback(() => {
    if (step !== 2) scoringWizardNextStep()
    // else modalRef.current.open()
    else scoringWizardStartScoring()
  }, [step, scoringWizardNextStep, scoringWizardStartScoring])

  return (
    <>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={open}
        onClose={closeScoringWizard}
      >
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <div className={classes.title}>{title || 'Scoring Wizard'}</div>
            <Button
              className={classes.closeButton}
              type="button"
              onClick={closeScoringWizard}
            >
              <MinimizeIcon />
            </Button>
          </div>
          <div className={classes.content}>
            <div className={classes.stepsWrapper}>
              <ScoringWizardStepOne scoring={activeScoring} />
              <ScoringWizardStepTwo scoring={activeScoring} />
              <ScoringWizardStepThree scoring={activeScoring} />
            </div>
            <div
              className={classes.overlayWrapper}
              data-active={isPipelineManagerConnected}
            />
          </div>
          <div className={classes.footer}>
            <Button onClick={discardActiveScoring}>Discard</Button>
            <div>
              <Button disabled={prevDisabled} onClick={scoringWizardPrevStep}>
                Previous
              </Button>
              <Button color="primary" disabled={nextDisabled} onClick={nextCallback}>
                {nextText}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <AlertDialog
        open={discardWarning}
        title="Discard current scoring?"
        content="You currently have an active scoring. Do you wish to discard it?"
        okText="Discard"
        cancelText="Cancel"
        onOk={event => {
          event.stopPropagation()
          event.preventDefault()
          discardActiveScoring()
        }}
        onCancel={event => {
          event.stopPropagation()
          rejectScoringChange()
        }}
      />
      {/* <Modal
        ref={modalRef}
        title="CPU configuration"
        footer
        okText="Start"
        cancelText="Cancel"
        onOk={() => {
          modalRef.current.close()
          scoringWizardStartScoring()
        }}
        onCancel={() => modalRef.current.close()}
      >
        <div className={classes.cpuModal}>
          <TextField
            // eslint-disable-next-line react/no-array-index-key
            select
            value={no_cpus}
            fullWidth
            label="Number of CPUs"
            onChange={event => {
              // setScoringWizardSpec({ no_cpus: event.target.value })
            }}
          >
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={16} disabled>
              16
            </MenuItem>
            <MenuItem value={32} disabled>
              32
            </MenuItem>
          </TextField>
        </div>
      </Modal> */}
    </>
  )
}

ScoringWizard.propTypes = {}

export default ScoringWizard
