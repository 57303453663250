/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'

import Conditional from 'components/Conditional'
import { Switch, TextField, Tooltip } from '@material-ui/core'

const classes = {
  wrapper: 'MappingBox-config-content-wrapper',
  ruleWrapper: 'MappingBox-config-content-MappingRule-wrapper',
}

const MappingConfig = ({ config, meta: tempMeta = [], stackedBy, callback }) => {
  const meta = tempMeta.map(feature => ({
    ...feature,
    rename:
      config.rules.find(({ feature_id }) => feature.id === feature_id)?.rename || '',
    active:
      feature.id === stackedBy ||
      !(
        config.rules.find(({ feature_id }) => feature.id === feature_id)?.active ===
        false
      ),
  }))
  return (
    <div className={classes.wrapper}>
      {meta.map(({ id, name, _, rename, active }) => {
        return (
          <div key={id} className={classes.ruleWrapper}>
            <Tooltip
              title={name.charAt(0).toUpperCase() + name.slice(1)}
              placement="right"
            >
              <span>{name.charAt(0).toUpperCase() + name.slice(1)}</span>
            </Tooltip>
            <TextField
              value={rename}
              onChange={event => {
                const tempConfig = { ...config }
                const tempRule = tempConfig.rules.find(
                  rule => rule.feature_id === id
                )
                if (event.target.value) {
                  if (tempRule) tempRule.rename = event.target.value
                  else
                    tempConfig.rules = [
                      ...config.rules,
                      { feature_id: id, rename: event.target.value },
                    ]
                  callback({ ...tempConfig })
                } else {
                  tempConfig.rules = [...config.rules].filter(
                    e => e.feature_id !== id
                  )
                  if (tempRule && !tempRule.active)
                    tempConfig.rules.push({ feature_id: id, active })
                  callback({ ...tempConfig })
                }
              }}
              label="Rename"
              margin="dense"
              fullWidth
            />
            <Conditional dependencies={id === stackedBy}>
              <span>stacked by this feature</span>
            </Conditional>
            <Switch
              checked={!!active}
              color={active ? 'primary' : 'default'}
              disabled={id === stackedBy}
              onChange={event => {
                const tempConfig = { ...config }
                const tempRule = tempConfig.rules.find(
                  rule => rule.feature_id === id
                )
                if (!event.target.checked) {
                  if (tempRule) tempRule.active = event.target.checked
                  else
                    tempConfig.rules = [
                      ...config.rules,
                      { feature_id: id, active: event.target.checked },
                    ]
                  callback({ ...tempConfig })
                } else {
                  tempConfig.rules = [...config.rules].filter(
                    e => e.feature_id !== id
                  )
                  if (tempRule.rename)
                    tempConfig.rules.push({ feature_id: id, rename })
                  callback({ ...tempConfig })
                }
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

MappingConfig.propTypes = {
  config: PropTypes.object,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  stackedBy: PropTypes.number,
  callback: PropTypes.func,
}

export default MappingConfig
