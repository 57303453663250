import React from 'react'
import { Switch, Route, Redirect, useStore } from 'core'

import Conditional from 'components/Conditional'
import Dashboard from 'pages/Dashboard'
import LoginPage from 'pages/Login'
import PlansPage from 'pages/Plans'
import {
  RegistrationPage,
  ConfirmationPage,
  PasswordResetPage,
} from 'pages/Registration'

import ResourcesRouter from './ResourcesRouter'
import ChartsRouter from './ChartsRouter'
// import ProjectsRouter from './ProjectsRouter'
import DevRouter from './DevRouter'
import UserSectionRouter from './UserSectionRouter'

const BaseRouter = () => {
  const { user } = useStore('selectUser')
  return (
    <>
      <Conditional dependencies={!user}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route
            path={['/confirm/:token', '/confirm']}
            component={ConfirmationPage}
          />
          <Route
            path={['/password-reset/:token', '/password-reset']}
            component={PasswordResetPage}
          />
          <Route path="/signup" component={RegistrationPage} />
          <Route path="/pricing" component={PlansPage} />
          <Redirect path="/" exact={false} to="/login" />
          <Route path="/dev" component={DevRouter} />
          <Route
            render={({ location: loc }) => {
              return (
                <pre>{`  404 Page Not Found\n\n  The page under "${loc.pathname}" does not exist.`}</pre>
              )
            }}
          />
        </Switch>
      </Conditional>
      <Conditional dependencies={user}>
        <Switch>
          <Redirect path={['/', '/login', '/signup']} to="/dashboard" />
          {/* <Redirect path="/dashboard" to="/resources" /> */}
          <Route path="/pricing" component={PlansPage} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/resources" component={ResourcesRouter} />
          <Route path="/charts" component={ChartsRouter} />
          {/* <Route path="/projects" component={ProjectsRouter} /> */}
          <Route path="/dev" component={DevRouter} />
          <Route path="/account" component={UserSectionRouter} />
          <Route
            render={({ location: loc }) => {
              return (
                <pre>{`  404 Page Not Found\n\n  The page under "${loc.pathname}" does not exist.`}</pre>
              )
            }}
          />
        </Switch>
      </Conditional>
    </>
  )
}

export default BaseRouter
