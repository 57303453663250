/* eslint-disable camelcase */
import React, { useRef, useEffect, useState } from 'react'
import 'style.scss'

import { useStore, Link } from 'core'

import { Conditional } from 'components/Conditional'
import { Form, FormItem } from 'components/Form'
import { BaseCard as Card } from 'components/CardLayout'
import { TextField, Button } from '@material-ui/core'

const classes = {
  wrapper: 'LoginPage-wrapper',
  cardWrapper: 'LoginPage-card-wrapper',
  formWrapper: 'LoginForm-wrapper',
  registrationLinkWrapper: 'LoginPage-registration-link-wrapper',
  registrationLink: 'LoginPage-registration-link',
  recoveryLinkWrapper: 'LoginPage-recovery-link-wrapper',
  recoveryLink: 'LoginPage-recovery-link',
}

const LoginPage = props => {
  const { login, requestPasswordRecovery } = useStore(
    'login',
    'requestPasswordRecovery'
  )
  const [recoveryMode, setRecoveryMode] = useState(false)
  const [emailError, setEmailError] = useState(null)
  const loginFormRef = useRef()
  const recoveryFormRef = useRef()

  const loginHandler = e => {
    e.preventDefault()
    const { get } = loginFormRef.current
    const { username, password } = get && get()
    if (username && password) {
      login({ username, password })
    }
  }

  const recoveryHandler = e => {
    e.preventDefault()
    const { get } = recoveryFormRef.current
    const { email } = get && get()
    if (!email && !emailError) {
      setEmailError('Invalid email')
    }
    if (email) {
      requestPasswordRecovery(email)
    }
  }

  const toggleRecoveryMode = e => setRecoveryMode(!recoveryMode)

  return (
    <div className={classes.wrapper}>
      <div className={classes.formWrapper} style={{ width: '25em' }}>
        {/* <BackgroundLogoRings height="225px" widht="225px" /> */}
        <Card className={classes.cardWrapper}>
          <Conditional dependencies={recoveryMode}>
            <Form ref={recoveryFormRef} onSubmit={recoveryHandler}>
              <FormItem
                fullWidth
                required
                margin="normal"
                label="E-mail"
                dataKey="email"
                defaultValue=""
                autoComplete="email"
                autoFocus
                component={TextField}
              />
              <Button fullWidth type="submit" variant="contained" color="primary">
                Recover password
              </Button>
              <Button
                fullWidth
                type="button"
                variant="outlined"
                color="primary"
                onClick={toggleRecoveryMode}
              >
                Back to login
              </Button>
              <div className={classes.registrationLinkWrapper}>
                Not registered?
                <Link to="/signup" absolute className={classes.registrationLink}>
                  Create an account.
                </Link>
              </div>
            </Form>
          </Conditional>
          <Conditional dependencies={!recoveryMode}>
            <Form ref={loginFormRef} onSubmit={loginHandler}>
              <FormItem
                fullWidth
                margin="normal"
                label="Username"
                dataKey="username"
                defaultValue=""
                autoComplete="username"
                autoFocus
                component={TextField}
              />
              <FormItem
                fullWidth
                margin="normal"
                label="Password"
                type="password"
                dataKey="password"
                defaultValue=""
                autoComplete="current-password"
                component={TextField}
              />
              <Button fullWidth type="submit" variant="contained" color="primary">
                Login
              </Button>
              <div className={classes.registrationLinkWrapper}>
                Not registered?
                <Link to="/signup" absolute className={classes.registrationLink}>
                  Create an account.
                </Link>
              </div>
              <div className={classes.recoveryLinkWrapper}>
                Forgot password?
                <button
                  type="button"
                  className={classes.recoveryLink}
                  onClick={toggleRecoveryMode}
                >
                  Recover password.
                </button>
              </div>
            </Form>
          </Conditional>
        </Card>
      </div>
    </div>
  )
}

export default LoginPage
