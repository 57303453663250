import React, { useCallback } from 'react'
import { useStore } from 'core'

import './style.scss'

import Icon from 'components/Icon'

const classes = {
  wrapper: 'FileStateMenuButton-wrapper',
}

const FileStateMenuButton = props => {
  const {
    uploads,
    fileStateDialogOpen: open,
    fileStateInspectorDialogToggle,
  } = useStore(
    'selectUploads',
    'selectFileStateDialogOpen',
    'fileStateInspectorDialogToggle'
  )

  const keys = Object.keys(uploads)

  if (!keys.length) return null

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      // eslint-disable-next-line no-script-url
      href="javascript:void(0)"
      className={classes.wrapper}
      data-active={open}
      onClick={() => fileStateInspectorDialogToggle(!open)}
    >
      <Icon type="folder" />
      <span>File State</span>
    </a>
  )
}

export default FileStateMenuButton
