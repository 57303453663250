import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import Spinner from 'components/Spinner'
import { MenuItem, TextField } from '@material-ui/core'

const classes = {
  wrapper: 'UserControl-wrapper',
  title: 'UserControl-title',
}

const TrainingSelectControl = props => {
  const { trainings: storeTrainings, trainingsIsLoading: loading } = useStore(
    'selectTrainings',
    'selectTrainingsIsLoading'
  )
  const { values: propValues = [], onChange, multiple, title, color } = props

  const trainings = storeTrainings || []

  const values = multiple ? propValues : [propValues[0]]

  return (
    <div
      className={classes.wrapper}
      style={{
        filter: color ? `drop-shadow(0px 0px 5px ${color})` : '',
        boxShadow: color ? `0px 0px 3px 1px ${color}` : '',
      }}
    >
      <div className={classes.title}>
        <span>{title}</span>
        <Conditional dependencies={loading}>
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
        </Conditional>
      </div>
      <TextField
        label={trainings.length ? 'Trainings' : 'No training to select'}
        select
        SelectProps={{ multiple: true, disabled: !trainings.length }}
        value={values}
        onChange={event => {
          const tempVal = event.target.value
          const val = (tempVal && !Array.isArray(tempVal) ? [tempVal] : tempVal).map(
            id => ({
              id,
              name: trainings.find(training => training.id === id)?.name,
            })
          )
          const difference = val.filter(({ id }) => values.indexOf(id) === -1)
          onChange(multiple ? val : difference)
        }}
      >
        {trainings
          .sort((a, b) =>
            `${a.name}`.localeCompare(`${b.name}`, 'en', { sensitivity: 'base' })
          )
          .map(training => {
            return (
              <MenuItem key={training.id} value={training.id}>
                {training.name}
              </MenuItem>
            )
          })}
      </TextField>
    </div>
  )
}

TrainingSelectControl.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  title: PropTypes.string,
  color: PropTypes.string,
}

export default TrainingSelectControl
