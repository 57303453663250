import { createBundle } from 'core/bundler'
import { createSelector } from 'create-selector'

import {
  USER_LOGGED_OUT,
  PIPELINE_CONFIGURATION_SET,
  ACTIVE_PIPELINE_CHANGE_REQUESTED,
  ACTIVE_PIPELINE_CHANGE_ACCEPTED,
  ACTIVE_PIPELINE_CHANGE_REJECTED,
  ACTIVE_PIPELINE_DISCARDED,
  ACTIVE_PIPELINE_SAVED,
  PIPELINE_MANAGER_ACTIVATED,
  PIPELINE_MANAGER_DEACTIVATED,
} from 'core/actiontypes'

const NAME = 'pipelines-manager'

const initialState = {
  id: null,
  pending: null,
  onSave: null,
  onDiscard: null,
  title: null,
  open: false,
}

export default config => {
  const rawSelector = state => state[NAME]

  const openPipelineManager = () => ({ type: PIPELINE_MANAGER_ACTIVATED })
  const closePipelineManager = () => ({ type: PIPELINE_MANAGER_DEACTIVATED })
  const acceptPipelineChange = () => ({ type: ACTIVE_PIPELINE_CHANGE_ACCEPTED })
  const rejectPipelineChange = () => ({ type: ACTIVE_PIPELINE_CHANGE_REJECTED })
  const savePipeline =
    () =>
    (dispatch, { store }) => {
      const { id, onSave, spec, meta, datasets } = store.selectActivePipeline()
      typeof onSave === 'function' && onSave(id, spec, datasets, meta)
      dispatch({ type: ACTIVE_PIPELINE_SAVED })
    }
  const discardPipeline =
    () =>
    (dispatch, { store }) => {
      const { id, onDiscard, spec, meta, datasets } = store.selectActivePipeline()
      typeof onDiscard === 'function' && onDiscard(id, spec, datasets, meta)
      dispatch({ type: ACTIVE_PIPELINE_DISCARDED })
    }

  return createBundle({
    name: NAME,
    reducer: (state = initialState, { type, payload, error }) => {
      if (type === PIPELINE_MANAGER_ACTIVATED) {
        return { ...state, open: true }
      }
      if (type === PIPELINE_MANAGER_DEACTIVATED) {
        return { ...state, open: false }
      }
      if (type === ACTIVE_PIPELINE_CHANGE_REQUESTED) {
        const { id, spec, datasets, onSave, onDiscard, title, open } = payload
        if (id === state.id)
          return {
            ...state,
            open: true,
          }
        return {
          ...state,
          pending: {
            id,
            title,
            open,
            spec,
            datasets,
            onSave,
            onDiscard,
          },
        }
      }
      if (type === ACTIVE_PIPELINE_CHANGE_REJECTED) {
        return {
          ...state,
          pending: null,
        }
      }
      if (type === ACTIVE_PIPELINE_CHANGE_ACCEPTED) {
        return {
          ...state,
          ...state.pending,
          pending: null,
        }
      }
      if (type === ACTIVE_PIPELINE_DISCARDED || type === ACTIVE_PIPELINE_SAVED) {
        return { ...initialState, pending: state.pending }
      }
      if (type === USER_LOGGED_OUT) {
        return initialState
      }
      return state
    },
    selectors: {
      selectActivePipeline: createSelector(
        rawSelector,
        'selectPipelineSpec',
        'selectPipelineMeta',
        'selectPipelineDatasetArray',
        (state, spec, meta, datasets) => ({
          id: state.id,
          spec,
          meta,
          datasets,
          onSave: state.onSave,
          onDiscard: state.onDiscard,
          title: state.title,
          open: state.open,
          pending: state.pending,
        })
      ),
      reactPipelineChangeRequest: createSelector(rawSelector, state => {
        if (!state.id && state.pending)
          return {
            type: 'BATCH_ACTIONS',
            actions: [
              acceptPipelineChange(),
              {
                type: PIPELINE_CONFIGURATION_SET,
                payload: {
                  spec: state.pending.spec,
                  datasetArray: state.pending.datasets,
                },
              },
            ],
          }
        return null
      }),
    },
    actions: {
      acceptPipelineChange,
      rejectPipelineChange,
      openPipelineManager,
      closePipelineManager,
      savePipeline,
      discardPipeline,
      setActivePipeline: (
        id,
        spec,
        datasets,
        saveCallback,
        cancelCallback,
        open = false,
        title
      ) => ({
        type: ACTIVE_PIPELINE_CHANGE_REQUESTED,
        payload: {
          id,
          spec,
          datasets,
          onSave: saveCallback,
          onDiscard: cancelCallback,
          open,
          title,
        },
      }),
    },
    init: null,
    args: null,
    middleware: null,
    persist: null,
  })
}
