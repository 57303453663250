import moment from 'moment'
import { IS_BROWSER } from './developmentConstants'

export { locationMatch } from '../../src/core/helpers'

const ricRafShim = func => setTimeout(func, 0)
export const raf =
  IS_BROWSER && self.requestAnimationFrame ? self.requestAnimationFrame : ricRafShim
export const ric =
  IS_BROWSER && self.requestIdleCallback ? self.requestIdleCallback : ricRafShim

export const debounce = (fn, wait) => {
  let timeout
  const debounced = function () {
    const ctx = this
    const args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.apply(ctx, args)
    }, wait)
  }
  debounced.cancel = () => {
    clearTimeout(timeout)
  }
  return debounced
}

export const extractValue = (object, string) => {
  const stringArray = string.split('.')
  const stringLength = stringArray.length
  const newObject = object[stringArray[0]]
  const shiftedString = stringArray.slice()
  shiftedString.shift()
  const newString = shiftedString.join('.')
  let result = {}
  if (newObject || newObject === 0) {
    result = stringLength > 1 ? extractValue(newObject, newString) : newObject
  } else result = null
  return result
}

export const paginate = (data, perPage) => {
  return {
    perPage,
    page: page => {
      return data.slice(perPage * (page - 1), perPage * page)
    },
  }
}

export const isObject = object =>
  Object.prototype.toString.call(object) === '[object Object]'

export const isFunction = functionParam =>
  functionParam && {}.toString.call(functionParam) === '[object Function]'

export const formatDate = value => moment(value).format('DD.MM.YYYY HH:mm:ss')

export const matchPassword = (a, b) => {
  if (a === b) return true
  const trimA = a === undefined || a === null ? '' : String(a).trim()
  const trimB = b === undefined || b === null ? '' : String(b).trim()
  return trimA === trimB
}

// ======================================================
//           MORE SPECIFIC FUNCTIONS - MOVE
// ======================================================

export const parseBrains = brains => {
  const objectiveFunctionsMetricsToFilter = ['confusion_matrix', 'roc_curve', 'type']
  const trainingStatsMetricsToFilter = []
  const tempBrains = (brains && (!Array.isArray(brains) ? [brains] : brains)) || []
  const brainObjectives = []
  const brainFeatures = []
  const confusionFeatures = []
  const brainTrainStatObjectives = []
  const result = tempBrains.reduce(
    (acc, cur) => {
      const { objectives, rocCurve, confusionMatrix, learningCurve } = { ...acc }
      const { id, name, statistics } = cur
      const { objective_functions, training_stats } = statistics
      Object.keys(objective_functions).forEach(split => {
        //// OBJECTIVES ////
        const objBrain = objectives[split].find(brain => brain.id === id)
        if (!objBrain) {
          const combinations = Object.keys(objective_functions[split]).reduce(
            (accumulated, current) => {
              const temp = { ...accumulated }
              const metrics = Object.keys(
                objective_functions[split][current]
              ).filter(e => objectiveFunctionsMetricsToFilter.indexOf(e) === -1)
              if (metrics.length) {
                metrics.forEach(metric => {
                  const brainObjective = {
                    name: `${current} [${metric}]`,
                    // type: objective_functions[split][current].type,
                    type: 'numeric',
                  }
                  if (
                    !brainObjectives.find(
                      brObj =>
                        brObj.type === brainObjective.type &&
                        brObj.name === brainObjective.name
                    )
                  )
                    brainObjectives.push(brainObjective)
                  temp[`${current} [${metric}]`] =
                    objective_functions[split][current][metric]
                })
              }
              return temp
            },
            {}
          )
          objectives[split].push({
            name,
            id,
            ...combinations,
          })
        }
        ////////////////////

        //// ROC  CURVE ////
        const rocBrain = rocCurve[split].find(brain => brain.id === id)
        if (!rocBrain) {
          const features = Object.keys(objective_functions[split]).reduce(
            (accumulated, current) => {
              const temp = {
                ...accumulated,
              }
              temp[current] =
                objective_functions[split][current]?.roc_curve?.fpr
                  ?.map((e, i) => ({
                    x: e,
                    y: objective_functions[split][current].roc_curve.tpr[i],
                  }))
                  .filter(({ x, y }) => (x || x === 0) && (y || y === 0)) || null
              const brainFeature = {
                name: current,
                // type: objective_functions[split][current].type,
                type: 'numeric',
              }
              if (
                !brainFeatures.find(
                  brFeat =>
                    brFeat.type === brainFeature.type &&
                    brFeat.name === brainFeature.name
                )
              ) {
                brainFeatures.push(brainFeature)
              }
              return temp
            },
            {}
          )
          rocCurve[split].push({
            name,
            id,
            ...features,
          })
        }
        ////////////////////

        //// CONFMATRIX ////
        const confBrain = confusionMatrix[split].find(brain => brain.id === id)
        if (!confBrain) {
          const matrices = Object.keys(objective_functions[split]).reduce(
            (accumulated, current) => {
              const temp = { ...accumulated }
              const unaccountedKeys = []
              const keys = Object.keys(
                objective_functions[split][current]?.confusion_matrix?.table || {}
              )
              temp[current] = keys.reduce((acc, cur) => {
                const tempResult = { ...acc }
                tempResult[cur] =
                  objective_functions[split][current].confusion_matrix.table[cur]
                const trcKeys = Object.keys(tempResult[cur])
                tempResult[cur]._sum = trcKeys.reduce(
                  (accu, curr) => accu + tempResult[cur][curr],
                  0
                )
                unaccountedKeys.push(
                  ...trcKeys.filter(
                    uk => keys.indexOf(uk) === -1 && trcKeys.indexOf(uk) === -1
                  )
                )
                return tempResult
              }, {})
              keys.forEach(key => {
                const missingKeys = unaccountedKeys.filter(
                  uk => Object.keys(temp[current][key]).indexOf(uk) === -1
                )
                missingKeys.forEach(mk => {
                  temp[current][key][mk] = 0
                })
              })
              const everyKey = keys.concat(unaccountedKeys)
              unaccountedKeys.forEach(uk => {
                temp[current][uk] = {}
                everyKey.forEach(k => (temp[current][uk][k] = 0))
              })

              const confusionFeature = {
                name: current,
                // type: objective_functions[split][current].type,
                type: 'numeric',
              }
              if (
                !confusionFeatures.find(
                  brFeat =>
                    brFeat.type === confusionFeature.type &&
                    brFeat.name === confusionFeature.name
                )
              ) {
                confusionFeatures.push(confusionFeature)
              }
              return temp
            },
            {}
          )
          confusionMatrix[split].push({
            name,
            id,
            ...matrices,
          })
        }
        ////////////////////
      })

      Object.keys(training_stats).forEach(split => {
        // LEARNING CURVE //
        const lrnBrain = learningCurve[split].find(brain => brain.id === id)
        const lossEndPoint = {}
        if (!lrnBrain) {
          const objectives = Object.keys(training_stats[split]).reduce(
            (accumulated, current) => {
              const temp = { ...accumulated }
              const metrics = Object.keys(training_stats[split][current]).filter(
                e => trainingStatsMetricsToFilter.indexOf(e) === -1
              )
              metrics.forEach(metric => {
                temp[`${current} [${metric}]`] =
                  training_stats[split][current][metric]?.map((e, i) => ({
                    x: i,
                    y: e,
                  })) || null
                const brainTrainStatObjective = {
                  name: `${current} [${metric}]`,
                  type: 'numeric',
                }
                if (
                  !brainTrainStatObjectives.find(
                    brObj =>
                      brObj.type === brainTrainStatObjective.type &&
                      brObj.name === brainTrainStatObjective.name
                  )
                )
                  brainTrainStatObjectives.push(brainTrainStatObjective)
              })
              return temp
            },
            {}
          )
          Object.keys(objectives).forEach(objective => {
            lossEndPoint[objective] = [...objectives[objective]].sort(
              (a, b) => a.x - b.x
            )[objectives[objective].length - 1].y
          })
          learningCurve[split].push({ name, id, ...objectives, lossEndPoint })
        }
        ////////////////////
      })

      return acc
    },
    {
      objectives: {
        combined: [],
        train: [],
        validation: [],
        test: [],
        objectives: brainObjectives,
      },
      rocCurve: {
        combined: [],
        train: [],
        validation: [],
        test: [],
        features: brainFeatures,
      },
      confusionMatrix: {
        combined: [],
        train: [],
        validation: [],
        test: [],
        features: confusionFeatures,
      },
      learningCurve: {
        train: [],
        validation: [],
        test: [],
        objectives: brainTrainStatObjectives,
      },
    }
  )
  result.brainFeatures = brainFeatures

  return result
}

export const logarithmWithBase = (value, base) => Math.log(value) / Math.log(base)

export const generateGridPoints = (
  min,
  range,
  ratio,
  size,
  tempNormalizer = 1,
  rounding,
  time = false
) => {
  const relativeSize = size * ratio
  if (time) {
    const normalizer = tempNormalizer * 1000
    const timeRange = Math.round(range * normalizer)
    const relativeSizeRange = Math.min(relativeSize * normalizer, 315360000000000)
    let absPonder = 1
    let relPonder = 1
    let scale = ''
    let tempRange = relativeSizeRange
    const log10 = Math.log10(relativeSizeRange)
    if (log10 < 3) {
      absPonder *= 10 ** Math.floor(log10)
      scale = 'millisecond'
      // const relValues = [1, 2.5, 5, 10]
      const relValues = [2.5, 5, 10]
      const absValues = [...relValues]
        .map(e => e * absPonder)
        .filter(e => !(e - Math.trunc(e)))
      let i = 0
      while (absValues[i] <= relativeSizeRange) i++
      tempRange = absValues[i]
      relPonder = relValues[i] * 10 ** Math.floor(log10)
    } else {
      absPonder *= 1000
      const log60 = logarithmWithBase(relativeSizeRange / absPonder, 60)
      if (log60 < 2) {
        absPonder *= 60 ** Math.floor(log60)
        scale = log60 < 1 ? 'second' : 'minute'
        // const relValues = [1, 5, 10, 30, 60]
        const relValues = [5, 10, 30, 60]
        const absValues = [...relValues].map(e => e * absPonder)
        let i = 0
        while (absValues[i] <= relativeSizeRange) i++
        tempRange = absValues[i]
        // relPonder = relValues[i] * 60 ** Math.floor(log60) // think this was a bug
        relPonder = relValues[i]
      } else {
        absPonder *= 60 * 60
        if (relativeSizeRange < absPonder * 12) {
          scale = 'hour'
          // const relValues = [1, 3, 6, 12]
          const relValues = [3, 6, 12]
          const absValues = [...relValues].map(e => e * absPonder)
          let i = 0
          while (absValues[i] <= relativeSizeRange) i++
          tempRange = absValues[i]
          relPonder = relValues[i]
        } else {
          absPonder *= 24
          if (relativeSizeRange < absPonder * 14) {
            scale = 'date'
            const relValues = [1, 2, 3, 5, 7, 14]
            const absValues = [...relValues].map(e => e * absPonder)
            let i = 0
            while (absValues[i] <= relativeSizeRange) i++
            tempRange = absValues[i]
            relPonder = relValues[i]
          } else {
            scale = 'bigger'
          }
        }
      }
    }

    let startingPoint = min * normalizer
    let points = []

    if (scale !== 'bigger') {
      startingPoint = moment(min * normalizer).startOf(scale)
      const remainder = startingPoint[scale]()

      if (scale === 'date') {
        if (relPonder >= 7)
          startingPoint = moment(startingPoint.valueOf()).isoWeekday(8)
        else
          startingPoint = moment(startingPoint.valueOf()).add(
            relPonder - 1 - ((remainder - 1) % relPonder),
            'day'
          )
      } else {
        startingPoint = moment(startingPoint.valueOf()).add(
          relPonder - 1 - ((remainder - 1) % relPonder),
          scale
        )
      }

      points.push(startingPoint.valueOf() / normalizer)
      let lastPoint = points[points.length - 1]
      while (lastPoint < min + range) {
        const newPoint = lastPoint * normalizer + tempRange
        points.push(newPoint / normalizer)
        lastPoint = points[points.length - 1]
      }
    } else {
      absPonder *= 30
      if (relativeSizeRange < absPonder * 6) {
        scale = 'month'
        const relValues = [1, 3, 6]
        const absValues = [...relValues].map(e => e * absPonder)
        let i = 0
        while (absValues[i] <= relativeSizeRange) i++
        // tempRange = absValues[i]
        relPonder = relValues[i]
      } else {
        absPonder *= 365 / 30
        scale = 'year'
        const relValues = [1, 5, 10]
        let absValues = [...relValues].map(e => e * absPonder)
        let i = 0
        let j = 0
        while (absValues[i] <= relativeSizeRange) {
          i++
          if (i === absValues.length) {
            if (!j) {
              relValues.splice(1, 0, 2.5)
            }
            i = 0
            j++
            absValues = [...relValues].map(e => e * 10 ** j * absPonder)
          }
        }
        // tempRange = absValues[i]
        relPonder = relValues[i] * 10 ** j
      }
      startingPoint = moment(min * normalizer)
        .startOf(scale)
        .add(1, scale)
      const remainder = startingPoint[scale]()
      startingPoint = moment(startingPoint.valueOf()).add(
        relPonder - 1 - ((remainder - 1) % relPonder),
        scale
      )
      points.push(startingPoint.valueOf() / normalizer)
      let lastPoint = points[points.length - 1]
      while (lastPoint < min + range) {
        const newPoint = moment(lastPoint * normalizer)
          .add(relPonder, scale)
          .valueOf()
        points.push(newPoint / normalizer)
        lastPoint = points[points.length - 1]
      }
      points.pop()
    }

    if (scale === 'month' && relPonder > 1)
      points = [...points].map(
        point =>
          moment(point * normalizer)
            .subtract(1, 'day')
            .valueOf() / normalizer
      )
    return [points, scale]
  } else {
    const tempValues = [0.5, 1, 2.5, 5, 10]

    let ponder = 1
    if (tempValues[tempValues.length - 1] * ponder > relativeSize) {
      while (tempValues[tempValues.length - 1] * ponder > relativeSize)
        ponder = ponder / 10
      ponder = ponder * 10
    }

    if (tempValues[0] * ponder < relativeSize) {
      while (tempValues[0] * ponder < relativeSize) ponder = ponder * 10
      ponder = ponder / 10
    }

    const values =
      rounding && ponder * tempNormalizer <= 1
        ? tempValues.filter(v => !rounding || v !== 2.5)
        : tempValues

    let [distance, add] = values.reduce(
      (acc, cur, i) => {
        return acc[0] * ponder > relativeSize
          ? [acc[0], acc[1]]
          : [cur, i === 2 ? true : false] // 2.5 decimal option
      },
      [0, false]
    )

    let decimals = 0
    if (ponder) while (10 ** decimals < 1 / ponder) decimals++
    if (add) decimals++

    distance = Number(Number.parseFloat(distance * ponder).toFixed(decimals))

    const startingPoint =
      Number(
        Number.parseFloat(Math.ceil(min / distance) * distance).toFixed(decimals)
      ) || 0

    let points = []
    if (distance) {
      points.push(startingPoint)
      while (points[points.length - 1] + distance <= min + range) {
        const newPoint = Number(
          Number.parseFloat(points[points.length - 1] + distance).toFixed(decimals)
        )
        points.push(newPoint)
      }
    }

    return [points, null]
  }
}

export const getFormatFromScale = scale => {
  switch (scale) {
    case 'millisecond':
      return 'ss.SSS'
    case 'second':
      return 'mm:ss'
    case 'minute':
      return 'HH:mm'
    case 'hour':
      return 'DD-MM hh A'
    case 'date':
      return 'DD-MM'
    case 'month':
      return 'DD-MM-YY'
    case 'year':
      return 'YYYY'
    default:
      return null
  }
}

const metaObjPlaceholder = {
  metadata: [
    {
      type: 'basedata',
      features: [],
    },
  ],
  validation: [],
  hash_in: '',
  hash_out: '',
}
const metaObjPlaceholderJoin = {
  ...metaObjPlaceholder,
  metadata: [
    ...metaObjPlaceholder.metadata,
    {
      type: 'basedata',
      features: [],
    },
  ],
}
export const buildBranch = (
  branch,
  meta,
  tempCounter,
  tempPipeArray,
  tempDatasetArray,
  tempChange
) => {
  let counter = tempCounter
  const pipeArray = tempPipeArray
  const datasetArray = tempDatasetArray
  let change = tempChange
  const buildInnerBranch = (innerBranch, innerMeta) => {
    if (innerBranch.length > 0) {
      const returnResult = [
        ...innerBranch.reduce(
          (acc, cur, i) => {
            const result = [...acc[0]]
            const resultMeta = [...acc[1]]
            if (isObject(cur)) {
              if (cur.type === 'dataset') {
                counter++
                const tempDatasetBox = { ...cur }
                const tempIndex = tempDatasetBox.datasetArrayIndex
                if (tempIndex || tempIndex === 0) {
                  if (tempIndex !== counter) {
                    change = true
                    tempDatasetBox.datasetArrayIndex = counter
                    if (!pipeArray[counter]) pipeArray[counter] = null
                  }
                  pipeArray[counter] = datasetArray[tempIndex] || null
                } else {
                  change = true
                  tempDatasetBox.datasetArrayIndex = counter
                  if (!pipeArray[counter]) pipeArray[counter] = null
                }
                result.push(tempDatasetBox)
                resultMeta.push(innerMeta[i])
              } else if (i === innerBranch.length - 1) {
                result.push(cur)
                resultMeta.push(innerMeta[i])
                counter++
                result.push({
                  type: 'dataset',
                  datasetArrayIndex: counter,
                })
                resultMeta.push({ ...metaObjPlaceholder, type: 'dataset' })
                change = true
                pipeArray[counter] = null
              } else {
                result.push(cur)
                resultMeta.push(innerMeta[i])
              }
            } else if (Array.isArray(cur)) {
              const [tempBranch, tempMeta] = buildInnerBranch(cur, innerMeta[i])
              result.push(tempBranch)
              resultMeta.push(tempMeta)
            }
            return [result, resultMeta]
          },
          [[], []]
        ),
      ]
      return [...returnResult, counter]
    }
    counter++
    change = true
    pipeArray[counter] = null
    return [
      [
        {
          type: 'dataset',
          datasetArrayIndex: counter,
        },
      ],
      [{ ...metaObjPlaceholder, type: 'dataset' }],
      counter,
    ]
  }
  return [...buildInnerBranch(branch, meta), change]
}
export const fillLocations = (branch, change, location = []) => {
  let tempChange = change
  const tempResult = branch.reduce((acc, cur, i) => {
    const tempLocation = [...location]
    tempLocation.push(i)
    if (isObject(cur)) {
      if (
        !(cur.locationArray && cur.locationArray.join('') === tempLocation.join(''))
      ) {
        cur.locationArray = tempLocation
        tempChange = true
      }
      acc.push(cur)
    } else if (Array.isArray(cur)) {
      const [innerBranch, innerChange] = fillLocations(cur, tempChange, tempLocation)
      tempChange = tempChange || innerChange
      acc.push(innerBranch)
    }
    return acc
  }, [])
  return [tempResult, tempChange]
}
export const buildMirrorStructure = branch => {
  return branch.reduce((acc, cur) => {
    if (isObject(cur)) {
      if (cur.type === 'join' || cur.type === 'concat')
        acc.push({ ...metaObjPlaceholderJoin, type: cur.type })
      else acc.push({ ...metaObjPlaceholder, type: cur.type })
    } else if (Array.isArray(cur)) {
      acc.push(buildMirrorStructure(cur))
    }
    return acc
  }, [])
}
export const parseMeta = branch => {
  return branch.reduce((acc, cur) => {
    if (isObject(cur)) {
      const parsedMetaObj = Object.keys(cur).reduce((acc, key) => {
        if (key !== 'config') acc[key] = cur[key]
        return acc
      }, {})
      acc.push(parsedMetaObj)
    } else if (Array.isArray(cur)) {
      acc.push(parseMeta(cur))
    }
    return acc
  }, [])
}
export const updateElementMeta = (branch, hash, locationArray = [], elementMeta) => {
  let tempLocation = [...locationArray]
  const locLength = tempLocation.length
  if (!locLength) return branch
  return branch.reduce((acc, cur, i) => {
    if (isObject(cur)) {
      if (locLength === 1 && tempLocation[0] === i && cur.hash_out === hash) {
        const tempCur = { ...cur, ...elementMeta }
        acc.push(tempCur)
      } else acc.push(cur)
    } else if (Array.isArray(cur)) {
      if (tempLocation[0] === i) {
        tempLocation.shift()
        acc.push(updateElementMeta(cur, hash, tempLocation, elementMeta))
      } else acc.push(updateElementMeta(cur, hash, [], elementMeta))
    }
    return acc
  }, [])
}
export const compareMetaAndKeepReady = (oldMeta = [], newMeta = []) => {
  let differenceFound = false
  let tempMeta = []
  tempMeta = newMeta.map((e, i) => {
    if (oldMeta[i]) {
      if (isObject(e) && isObject(oldMeta[i]) && !differenceFound)
        if (e.hash_out === oldMeta[i].hash_out) return oldMeta[i]
        else return e
      else if (Array.isArray(e) && Array.isArray(oldMeta[i]) && !differenceFound)
        return compareMetaAndKeepReady(oldMeta[i], e)
      else {
        differenceFound = true
        return e
      }
    }
  })
  return tempMeta
}

export const getDomain = (data, limits) => {
  let minX =
    data.reduce((acc, cur) => {
      const val = cur.data.reduce((accumulated, current) => {
        return accumulated || accumulated === 0
          ? Math.min(accumulated, current.x)
          : current.x
      }, null)
      return acc || acc === 0 ? Math.min(acc, val) : val
    }, null) || 0

  let maxX =
    data.reduce((acc, cur) => {
      const val = cur.data.reduce((accumulated, current) => {
        return accumulated || accumulated === 0
          ? Math.max(accumulated, current.x)
          : current.x
      }, null)
      return acc || acc === 0 ? Math.max(acc, val) : val
    }, null) || 0
  let minY =
    data.reduce((acc, cur) => {
      const val = cur.data.reduce((accumulated, current) => {
        return accumulated || accumulated === 0
          ? Math.min(accumulated, current.y)
          : current.y
      }, null)
      return acc || acc === 0 ? Math.min(acc, val) : val
    }, null) || 0
  let maxY =
    data.reduce((acc, cur) => {
      const val = cur.data.reduce((accumulated, current) => {
        return accumulated || accumulated === 0
          ? Math.max(accumulated, current.y)
          : current.y
      }, null)
      return acc || acc === 0 ? Math.max(acc, val) : val
    }, null) || 0
  const limitMinX = limits?.x?.min
  const limitMaxX = limits?.x?.max
  const limitMinY = limits?.y?.min
  const limitMaxY = limits?.y?.max

  if (limitMinX || limitMinX === 0) {
    minX = Math.min(Math.max(limitMinX, minX), limitMaxX)
  } else minX = limitMaxX || limitMaxX === 0 ? Math.min(minX, limitMaxX) : minX
  if (limitMaxX || limitMaxX === 0) {
    maxX = Math.max(Math.min(limitMaxX, maxX), limitMinX)
  } else maxX = limitMinX || limitMinX === 0 ? Math.max(maxX, limitMinX) : maxX
  if (limitMinY || limitMinY === 0) {
    minY = Math.min(Math.max(limitMinY, minY), limitMaxY)
  } else minY = limitMaxY || limitMaxY === 0 ? Math.min(minY, limitMaxY) : minY
  if (limitMaxY || limitMaxY === 0) {
    maxY = Math.max(Math.min(limitMaxY, maxY), limitMinY)
  } else maxY = limitMinY || limitMinY === 0 ? Math.max(maxY, limitMinY) : maxY

  return { x: { min: minX, max: maxX }, y: { min: minY, max: maxY } }
}

export const getViewBox = (domain, zoomStack = [], round = true) => {
  let tempX = round ? Math.floor(domain.x.min) : domain.x.min
  let tempY = round ? Math.floor(domain.y.min) : domain.y.min
  let tempXRange = round
    ? Math.ceil(Math.ceil(domain.x.max) - domain.x.min)
    : domain.x.max - domain.x.min
  let tempYRange = round
    ? Math.ceil(Math.ceil(domain.y.max) - domain.y.min)
    : domain.y.max - domain.y.min
  const domainXRange = tempXRange
  const domainYRange = tempYRange
  const tempNormalizer = {
    // x: 10 ** Math.max(Math.round(Math.log10(tempXRange)) - 2, 0),
    // y: 10 ** Math.max(Math.round(Math.log10(tempYRange)) - 2, 0),
    x: 10 ** (Math.round(Math.log10(tempXRange)) - 1) || 1,
    y: 10 ** (Math.round(Math.log10(tempYRange)) - 1) || 1,
  }

  if (zoomStack?.length)
    zoomStack.forEach(zoomBox => {
      tempX += tempXRange * zoomBox.left
      tempY += tempYRange * (1 - (zoomBox.top + zoomBox.height))
      tempXRange *= zoomBox.width
      tempYRange *= zoomBox.height
    })
  const xZoom = Number(
    Number.parseFloat((domainXRange / tempXRange) * 100).toFixed(2)
  )
  const yZoom = Number(
    Number.parseFloat((domainYRange / tempYRange) * 100).toFixed(2)
  )
  const x = Number(Number.parseFloat(tempX / (tempNormalizer.x || 1)).toFixed(4))
  const y = Number(Number.parseFloat(tempY / (tempNormalizer.y || 1)).toFixed(4))
  const xRange = Number(
    Number.parseFloat(tempXRange / (tempNormalizer.x || 1)).toFixed(4)
  )
  const yRange = Number(
    Number.parseFloat(tempYRange / (tempNormalizer.y || 1)).toFixed(4)
  )
  return [{ x, y, xRange, yRange }, { x: xZoom, y: yZoom }, tempNormalizer]
}

export const calculateZoomBox = (
  zoomBox,
  zoomCap,
  domain,
  viewBox,
  normalizer,
  round = true
) => {
  const { top, left, width, height } = zoomBox
  const tempZoomBox = { top, left, width, height }

  const originViewXRange = round
    ? Math.ceil(Math.ceil(domain.x.max) - domain.x.min) / normalizer.x
    : (domain.x.max - domain.x.min) / normalizer.x
  const newXRange = viewBox.xRange * width

  const originViewYRange = round
    ? Math.ceil(Math.ceil(domain.y.max) - domain.y.min) / normalizer.y
    : (domain.y.max - domain.y.min) / normalizer.y
  const newYRange = viewBox.yRange * height

  if ((originViewXRange / newXRange) * 100 > zoomCap) {
    const centerX = tempZoomBox.left + tempZoomBox.width / 2
    const maxWidth = (originViewXRange * 100) / zoomCap / viewBox.xRange
    tempZoomBox.left = centerX - maxWidth / 2
    tempZoomBox.width = maxWidth
  }
  if ((originViewYRange / newYRange) * 100 > zoomCap) {
    const centerY = tempZoomBox.top + tempZoomBox.height / 2
    const maxHeight = (originViewYRange * 100) / zoomCap / viewBox.yRange
    tempZoomBox.top = centerY - maxHeight / 2
    tempZoomBox.height = maxHeight
  }
  return tempZoomBox
}

export const interpolateLine = (line, viewBox, ratios) => {
  const boundaries = {
    xMin: viewBox.x,
    xMax: viewBox.x + viewBox.xRange,
    yMin: viewBox.y,
    yMax: viewBox.y + viewBox.yRange,
  }

  let tempLine = line.reduce((acc, cur, i, array) => {
    const { length } = array
    if (i !== 0 && i < length - 1) {
      const yRange = array[i + 1].y - array[i - 1].y
      const xRange = array[i + 1].x - array[i - 1].x

      if (!xRange)
        if (cur.x === array[i - 1].x) return acc
        else {
          acc.push(cur)
          return acc
        }
      if (!yRange)
        if (cur.y === array[i - 1].y) return acc
        else {
          acc.push(cur)
          return acc
        }

      const xDistance = cur.x - array[i - 1].x
      const expectedY = array[i - 1].y + yRange * (xDistance / xRange)

      // console.log(cur.y === expectedY, cur.y, expectedY)
      // if (!expectedY) console.log(array[i - 1].y, yRange, xDistance, xRange)

      if (!(cur.y === expectedY)) acc.push(cur)
    } else acc.push(cur)
    return acc
  }, [])

  const dashArray = tempLine.map((_, i) => i !== 0)

  // CUTTING BEFORE //
  let firstInBounds = tempLine.findIndex(line => line.x >= boundaries.xMin)
  if (firstInBounds === -1) tempLine = []
  else if (firstInBounds > 0) {
    const fill = {
      x: boundaries.xMin,
      y:
        tempLine[firstInBounds - 1].y +
        ((tempLine[firstInBounds].y - tempLine[firstInBounds - 1].y) *
          (boundaries.xMin - tempLine[firstInBounds - 1].x)) /
          (tempLine[firstInBounds].x - tempLine[firstInBounds - 1].x),
    }
    tempLine.splice(0, firstInBounds, fill)
    dashArray.splice(0, firstInBounds, false)
  }
  ////////////////////

  // CUTTING  AFTER //
  let firstOutOfBounds = tempLine.findIndex(line => line.x >= boundaries.xMax)
  if (firstOutOfBounds === 0) tempLine = []
  else if (firstOutOfBounds !== -1) {
    const fill = {
      x: boundaries.xMax,
      y:
        tempLine[firstOutOfBounds - 1].y +
        ((tempLine[firstOutOfBounds].y - tempLine[firstOutOfBounds - 1].y) *
          (boundaries.xMax - tempLine[firstOutOfBounds - 1].x)) /
          (tempLine[firstOutOfBounds].x - tempLine[firstOutOfBounds - 1].x),
    }
    tempLine.splice(firstOutOfBounds, tempLine.length - firstOutOfBounds, fill)
    dashArray.splice(firstOutOfBounds, dashArray.length - firstOutOfBounds, true)
  }
  ////////////////////

  // CUTTING ABOVE //
  firstOutOfBounds = tempLine.findIndex(line => {
    return line.y > boundaries.yMax
  })
  while (firstOutOfBounds !== -1) {
    const firstFill =
      firstOutOfBounds === 0
        ? { x: tempLine[0].x, y: boundaries.yMax }
        : {
            x:
              tempLine[firstOutOfBounds - 1].x +
              ((tempLine[firstOutOfBounds].x - tempLine[firstOutOfBounds - 1].x) *
                (boundaries.yMax - tempLine[firstOutOfBounds - 1].y)) /
                (tempLine[firstOutOfBounds].y - tempLine[firstOutOfBounds - 1].y),
            y: boundaries.yMax,
          }
    firstInBounds = tempLine.findIndex(
      (line, i) => line.y < boundaries.yMax && i > firstOutOfBounds
    )
    const lastFill =
      firstInBounds === -1
        ? { x: tempLine[tempLine.length - 1].x, y: boundaries.yMax }
        : {
            x:
              tempLine[firstInBounds - 1].x +
              ((tempLine[firstInBounds].x - tempLine[firstInBounds - 1].x) *
                (boundaries.yMax - tempLine[firstInBounds - 1].y)) /
                (tempLine[firstInBounds].y - tempLine[firstInBounds - 1].y),
            y: boundaries.yMax,
          }
    tempLine.splice(
      firstOutOfBounds,
      firstInBounds !== -1
        ? firstInBounds - firstOutOfBounds
        : tempLine.length - firstOutOfBounds,
      firstFill,
      lastFill
    )
    dashArray.splice(
      firstOutOfBounds,
      firstInBounds !== -1
        ? firstInBounds - firstOutOfBounds
        : dashArray.length - firstOutOfBounds,
      firstOutOfBounds === 0,
      false
    )
    firstOutOfBounds = tempLine.findIndex(line => line.y > boundaries.yMax)
  }
  ///////////////////

  // CUTTING BELOW //
  firstOutOfBounds = tempLine.findIndex(line => line.y < boundaries.yMin)
  while (firstOutOfBounds !== -1) {
    const firstFill =
      firstOutOfBounds === 0
        ? { x: tempLine[0].x, y: boundaries.yMin }
        : {
            x:
              tempLine[firstOutOfBounds - 1].x +
              ((tempLine[firstOutOfBounds].x - tempLine[firstOutOfBounds - 1].x) *
                (boundaries.yMin - tempLine[firstOutOfBounds - 1].y)) /
                (tempLine[firstOutOfBounds].y - tempLine[firstOutOfBounds - 1].y),
            y: boundaries.yMin,
          }
    firstInBounds = tempLine.findIndex(
      (line, i) => line.y > boundaries.yMin && i > firstOutOfBounds
    )
    const lastFill =
      firstInBounds === -1
        ? { x: tempLine[tempLine.length - 1].x, y: boundaries.yMin }
        : {
            x:
              tempLine[firstInBounds - 1].x +
              ((tempLine[firstInBounds].x - tempLine[firstInBounds - 1].x) *
                (boundaries.yMin - tempLine[firstInBounds - 1].y)) /
                (tempLine[firstInBounds].y - tempLine[firstInBounds - 1].y),
            y: boundaries.yMin,
          }
    tempLine.splice(
      firstOutOfBounds,
      firstInBounds !== -1
        ? firstInBounds - firstOutOfBounds
        : tempLine.length - firstOutOfBounds,
      firstFill,
      lastFill
    )
    dashArray.splice(
      firstOutOfBounds,
      firstInBounds !== -1
        ? firstInBounds - firstOutOfBounds
        : dashArray.length - firstOutOfBounds,
      firstOutOfBounds === 0,
      false
    )
    firstOutOfBounds = tempLine.findIndex(line => line.y < boundaries.yMin)
  }
  ///////////////////

  dashArray.pop()
  dashArray.push(!([...dashArray].filter(b => !b).length % 2))

  const [pathString, dashStringArray] = tempLine.length
    ? tempLine.reduce(
        (acc, { x, y }, i) => {
          let tempString = acc[0]
          const tempDashArray = [...acc[1]]
          tempString += `${i === 0 ? 'M' : ' L'} ${x} ${-y}`
          let length = 0
          if (i === tempLine.length - 1) {
            tempString += ` L ${x} ${-Math.min(
              Math.max(boundaries.yMin, 0),
              boundaries.yMax
            )} L ${tempLine[0].x} ${-Math.min(
              Math.max(boundaries.yMin, 0),
              boundaries.yMax
            )} Z`
            length = Number(
              Number.parseFloat(
                (y - Math.min(Math.max(boundaries.yMin, 0), boundaries.yMax)) /
                  ratios.y +
                  (x - tempLine[0].x) / ratios.x +
                  (tempLine[0].y -
                    Math.min(Math.max(boundaries.yMin, 0), boundaries.yMax)) /
                    ratios.y
              ).toFixed(4)
            )
          } else {
            const next = tempLine[i + 1]
            length = Math.sqrt(
              ((next.x - x) / ratios.x) ** 2 + ((next.y - y) / ratios.y) ** 2
            )
          }
          if (dashArray[i]) tempDashArray[tempDashArray.length - 1] += length
          else tempDashArray.push(length)
          return [tempString, tempDashArray]
        },
        ['', [0]]
      )
    : ['', []]

  const firstSpacing = dashStringArray.shift()
  dashStringArray[dashStringArray.length - 1] += firstSpacing
  const dashString = dashStringArray.join(' ')

  return [pathString, dashString, firstSpacing]
}

export const secondsToReadable = (seconds = 0) => {
  const h = seconds / 3600
  const m = (seconds % 3600) / 60
  const s = Math.round((seconds % 3600) % 60)
  const format = []
  if (h) format.push(`${h}h`)
  if (m) format.push(`${m}h`)
  if (s) format.push(`${s}h`)
  return format.join(' ')
}

export const getBranchNodeAmount = branch => {
  const result = branch.reduce(
    (acc, cur) => {
      if (isObject(cur)) {
        const tempResult = { ...acc }
        tempResult.boxes += 1
        return tempResult
      }
      if (Array.isArray(cur)) {
        const tempResult = { ...acc }
        tempResult.longestBranch = Math.max(
          tempResult.longestBranch,
          getBranchNodeAmount(cur)
        )
        return tempResult
      }
      return acc
    },
    { boxes: 0, longestBranch: 0 }
  )
  return result.boxes + result.longestBranch
}
export const getBranchLeafAmount = branch => {
  const result = branch.reduce((acc, cur) => {
    if (Array.isArray(cur)) {
      return acc + Math.max(1, getBranchLeafAmount(cur))
    }
    return acc
  }, 0)
  return result || 1
}
