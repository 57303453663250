/* eslint-disable no-shadow */
import { createBundle } from 'core/bundler'
import { v4 as uuid } from 'uuid'
import { Upload } from 'tus-js-client'
import Papa from 'papaparse'

import {
  UPLOAD_PREPROCESSING_STARTED,
  UPLOAD_PREPROCESSING_FAILED,
  UPLOAD_PREPROCESSING_SUCCEEDED,
  FILE_UPLOAD_STARTED,
  FILE_UPLOAD_FAILED,
  FILE_UPLOAD_PROGRESSED,
  FILE_UPLOAD_SUCCEEDED,
  FILE_TYPIFICATION_STARTED,
  FILE_TYPIFICATION_FAILED,
  FILE_TYPIFICATION_SUCCEEDED,
  UPLOAD_FILE_ADDED,
  UPLOAD_DATA_PARSE_STEP,
  // UPLOAD_DATA_PARSED,
  UPLOAD_FILE_REPARSE_STARTED,
  UPLOAD_CONFIGURATION_STARTED,
  UPLOAD_CONFIGURATION_FINISHED,
  UPLOAD_ENTITY_REMOVED,
  FILE_STATE_INSPECTOR_DIALOG_TOGGLED,
  BATCH_ACTIONS,
  DATASETS_OUTDATED,
} from '../../../../core/actiontypes'

const initialState = {
  uploading: {},
  dialogOpen: false,
  configuring: null,
}

export default config =>
  createBundle({
    name: 'upload-csv',
    reducer: (state = initialState, { type, payload }) => {
      if (type === UPLOAD_FILE_ADDED) {
        const tempUploading = {
          ...state.uploading,
          [payload.id]: {
            status: 'configurable',
            file: payload.file,
            fileName: payload.file.name,
            configuration: {
              name: payload.file.name,
              delimiter: '',
              has_header: true,
              spec: {
                date_format: 'YYYY-MM-DD',
                type: 'basedata',
              },
            },
            sampleData: [],
          },
        }
        return { ...state, uploading: tempUploading }
      }
      if (type === UPLOAD_DATA_PARSE_STEP) {
        const tempUploading = { ...state.uploading }
        if (Object.keys(tempUploading).includes(payload.id)) {
          const tempData = payload.data
          if (!payload.header)
            tempData.data = tempData.data.reduce(
              (acc, cur, i) => ({ ...acc, [`Column - ${i}`]: cur }),
              {}
            )
          tempUploading[payload.id].sampleData = {
            data: [
              ...(tempUploading[payload.id].sampleData.data || []),
              tempData.data,
            ],
            errors: [
              ...(tempUploading[payload.id].sampleData.errors || []),
              tempData.errors,
            ],
            meta: { ...tempData.meta },
          }
          const delimiter = (tempData.meta && tempData.meta.delimiter) || ''
          tempUploading[payload.id].configuration.delimiter = delimiter
        }
        return { ...state, uploading: tempUploading }
      }
      // if (type === UPLOAD_DATA_PARSED) {
      //   const tempUploading = { ...state.uploading }
      //   if (Object.keys(tempUploading).includes(payload.id)) {
      //     const tempData = payload.data
      //     if (!payload.header) {
      //       tempData.data = tempData.data.map(array => {
      //         const tempObj = {}
      //         array.forEach((val, i) => {
      //           tempObj[`Column - ${i}`] = val
      //         })
      //         return tempObj
      //       })
      //     }
      //     tempUploading[payload.id].sampleData = tempData
      //     const delimiter = (tempData.meta && tempData.meta.delimiter) || ''
      //     tempUploading[payload.id].configuration.delimiter = delimiter
      //   }
      //   return { ...state, uploading: tempUploading }
      // }
      if (type === UPLOAD_FILE_REPARSE_STARTED) {
        const tempUploading = {
          ...state.uploading,
          [payload.id]: { ...state.uploading[payload.id], sampleData: [] },
        }
        return { ...state, uploading: tempUploading }
      }
      if (type === UPLOAD_CONFIGURATION_STARTED) {
        return { ...state, configuring: payload }
      }
      if (type === UPLOAD_CONFIGURATION_FINISHED) {
        const tempUploading = { ...state.uploading }
        tempUploading[payload.id].configuration = payload.data
        return { ...state, uploading: tempUploading }
      }
      if (type === UPLOAD_PREPROCESSING_STARTED) {
        const tempUploading = { ...state.uploading }
        tempUploading[payload.id].status = 'preprocessing'
        return { ...state, uploading: tempUploading }
      }
      if (type === UPLOAD_PREPROCESSING_FAILED) {
        const tempUploading = { ...state.uploading }
        tempUploading[payload.id].status = 'preprocessing-failed'
        return { ...state, uploading: tempUploading }
      }
      if (type === UPLOAD_PREPROCESSING_SUCCEEDED) {
        const stateKeys = Object.keys(state.uploading)
        const body = { ...state.uploading[payload.fileId] }
        const tempState = stateKeys.reduce((acc, cur) => {
          return cur === payload.fileId
            ? { ...acc }
            : { ...acc, [cur]: state.uploading[cur] }
        }, {})
        const tempUploading = {
          ...tempState,
          [payload.id]: { ...body, status: 'uploading' },
        }
        return { ...state, uploading: tempUploading }
      }
      if (type === FILE_UPLOAD_STARTED) {
        const tempUploading = { ...state.uploading }
        tempUploading[payload.id].status = 'uploading'
        tempUploading[payload.id].progress = 0
        return { ...state, uploading: tempUploading }
      }
      if (type === FILE_UPLOAD_FAILED) {
        const tempUploading = { ...state.uploading }
        tempUploading[payload.id].status = 'uploading-failed'
        return { ...state, uploading: tempUploading }
      }
      if (type === FILE_UPLOAD_PROGRESSED) {
        const tempUploading = { ...state.uploading }
        tempUploading[payload.id].progress = payload.progress
        return { ...state, uploading: tempUploading }
      }
      if (type === FILE_UPLOAD_SUCCEEDED) {
        const tempUploading = { ...state.uploading }
        tempUploading[payload.id].status = 'uploaded'
        tempUploading[payload.id].progress = 1
        return { ...state, uploading: tempUploading }
      }
      if (type === FILE_TYPIFICATION_STARTED) {
        const tempUploading = { ...state.uploading }
        if (tempUploading[payload.id]) {
          tempUploading[payload.id].status = 'typifing'
          tempUploading[payload.id].progress = 1
        }
        return { ...state, uploading: tempUploading }
      }
      if (type === FILE_TYPIFICATION_FAILED) {
        const tempUploading = { ...state.uploading }
        if (tempUploading[payload.id]) {
          tempUploading[payload.id].status = 'typification-failed'
          tempUploading[payload.id].progress = 1
        }
        return { ...state, uploading: tempUploading }
      }
      if (type === FILE_TYPIFICATION_SUCCEEDED) {
        const tempUploading = { ...state.uploading }
        if (tempUploading[payload.id]) {
          tempUploading[payload.id].status = 'completed'
          tempUploading[payload.id].progress = 1
        }
        return { ...state, uploading: tempUploading }
      }
      if (type === UPLOAD_ENTITY_REMOVED) {
        const stateKeys = Object.keys(state.uploading)
        const tempUploading = stateKeys.reduce((acc, cur) => {
          return cur === payload.id
            ? { ...acc }
            : { ...acc, [cur]: state.uploading[cur] }
        }, {})
        return { ...state, uploading: tempUploading }
      }
      if (type === FILE_STATE_INSPECTOR_DIALOG_TOGGLED) {
        return { ...state, dialogOpen: payload }
      }
      return state
    },
    selectors: {
      selectUploads: state => state['upload-csv'].uploading,
      selectUploadEntity: (state, a) => state['upload-csv'].uploading[a],
      selectUploadEntityConfiguring: (state, a) => state['upload-csv'].configuring,
      selectFileStateDialogOpen: state => state['upload-csv'].dialogOpen,
    },
    actions: {
      uploadFile:
        key =>
        (dispatch, { store, api }) => {
          const { configuration, file } = store.selectUploads()[key]
          const token = store.selectToken()
          dispatch({
            type: UPLOAD_PREPROCESSING_STARTED,
            payload: {
              id: key,
            },
          })
          api
            .upload('/dataset', {
              method: 'POST',
              mode: 'cors',
              // credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                name: configuration.name,
                // filename: file.name,
                file_type: 'text/csv',
                type: 'UPLOADED_DATASET',
                delimiter: configuration.delimiter,
                has_header: configuration.has_header,
                spec: {
                  date_format: configuration.spec.date_format,
                  type: configuration.spec.type,
                  stacked_by: configuration.spec.stacked_by,
                },
              }),
            })
            .then(data => {
              const { id } = data
              dispatch({
                type: UPLOAD_PREPROCESSING_SUCCEEDED,
                payload: { fileId: key, id },
              })
              const upload = new Upload(file, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                chunkSize: 10 * 1024 * 1024,
                endpoint:
                  process.env.NODE_ENV === 'production'
                    ? `${api.upload.path}/uploads/${data.id}`
                    : `https://localhost:${process.env.SERVER_PORT}/${api.upload.service}/uploads/${data.id}`,
                metadata: {
                  filename: file.name,
                },
                removeFingerprintOnSuccess: true,
                onError: error => {
                  dispatch({
                    type: FILE_UPLOAD_FAILED,
                    payload: { id },
                  })
                },
                onProgress: (bytesUploaded, bytesTotal) => {
                  dispatch({
                    type: FILE_UPLOAD_PROGRESSED,
                    payload: { id, progress: bytesUploaded / bytesTotal },
                  })
                },
                onSuccess: () => {
                  dispatch({
                    type: FILE_UPLOAD_SUCCEEDED,
                    payload: { id },
                  })
                },
              })
              upload.start()
              dispatch({ type: FILE_UPLOAD_STARTED, payload: { id } })
            })
            .catch(error => {
              dispatch({
                type: UPLOAD_PREPROCESSING_FAILED,
                payload: { id: key },
              })
              throw error
            })
        },
      addUploadFile:
        ({ id, file }) =>
        dispatch => {
          dispatch({
            type: UPLOAD_FILE_ADDED,
            payload: { id, file },
          })
          const parseConfig = {
            delimiter: '',
            header: true,
            dynamicTyping: true,
            preview: 20,
            skipEmptyLines: true,
            delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
            step: res => {
              dispatch({
                type: UPLOAD_DATA_PARSE_STEP,
                payload: { id, data: res, header: true },
              })
            },
            // complete: res =>
            //   dispatch({
            //     type: UPLOAD_DATA_PARSED,
            //     payload: { id, data: res, header: true },
            //   }),
          }

          Papa.parse(file, parseConfig)
        },
      parseUploadData:
        ({ id, file, config }) =>
        dispatch => {
          dispatch({
            type: UPLOAD_FILE_REPARSE_STARTED,
            payload: { id },
          })
          const parseConfig = {
            delimiter: '',
            header: true,
            dynamicTyping: true,
            preview: 20,
            skipEmptyLines: true,
            delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
            step: res => {
              dispatch({
                type: UPLOAD_DATA_PARSE_STEP,
                payload: {
                  id,
                  data: res,
                  header: config.header !== false,
                },
              })
            },
            // complete: res =>
            //   dispatch({
            //     type: UPLOAD_DATA_PARSED,
            //     payload: {
            //       id,
            //       data: res,
            //       header: config.header !== false,
            //     },
            //   }),
            ...config,
          }
          Papa.parse(file, parseConfig)
        },
      configureUploadEntity: payload => ({
        type: UPLOAD_CONFIGURATION_STARTED,
        payload,
      }),
      updateUploadEntityConfiguration: payload => ({
        type: UPLOAD_CONFIGURATION_FINISHED,
        payload,
      }),
      removeUploadEntity: payload => ({
        type: UPLOAD_ENTITY_REMOVED,
        payload,
      }),
      fileStateInspectorDialogToggle: payload => ({
        type: FILE_STATE_INSPECTOR_DIALOG_TOGGLED,
        payload,
      }),
    },
    init: store => {
      store.serverEvent('TYPIFICATION_STARTED', ({ data }, store) => {
        store.dispatch({
          type: FILE_TYPIFICATION_STARTED,
          payload: {
            id: data.dataset_id,
          },
        })
      })
      store.serverEvent('TYPIFICATION_FAILED', ({ data }, store) => {
        store.dispatch({
          type: FILE_TYPIFICATION_FAILED,
          payload: {
            id: data.dataset_id,
          },
        })
      })
      store.serverEvent('TYPIFICATION_FINISHED', ({ data }, store) => {
        store.dispatch({
          type: FILE_TYPIFICATION_SUCCEEDED,
          payload: {
            id: data.dataset_id,
          },
        })
      })
    },
    args: null,
  })
