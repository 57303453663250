import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import Spinner from 'components/Spinner'
import { MenuItem, TextField } from '@material-ui/core'
import PopUpMultiSelect from 'components/PopUpMultiSelect'

const classes = {
  wrapper: 'UserControl-wrapper',
  background: 'UserControl-background',
  title: 'UserControl-title',
}

const BrainSelectControl = props => {
  const { brains: storeBrains } = useStore('selectBrains')
  const {
    id,
    split,
    values: propValues = [],
    onChange,
    multiple,
    title,
    color,
  } = props

  const training = storeBrains.find(t => t.id === id) || {}
  const loading = training.isLoading

  const values = multiple ? propValues : [propValues[0]]

  const brains =
    training.data?.objectives[split]?.map(brain => {
      return {
        id: brain.id,
        name: brain.name,
        checked: !!values.find(val => brain.id === val),
      }
    }) || []

  return (
    <div
      className={classes.wrapper}
      style={{
        filter: color ? `drop-shadow(0px 0px 5px ${color})` : '',
        boxShadow: color ? `0px 0px 3px 1px ${color}` : '',
      }}
    >
      <div className={classes.title}>
        <span>{title}</span>
        <Conditional dependencies={loading}>
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
        </Conditional>
      </div>
      {multiple ? (
        <PopUpMultiSelect
          label={brains.length ? 'Brains' : 'No brains to select'}
          disabled={!brains.length}
          margin="dense"
          fullWidth
          value={JSON.stringify(values)}
          onChange={e => {
            onChange([...JSON.parse(e)])
          }}
        >
          {brains.map(brain => (
            <MenuItem key={brain.id} value={brain.id}>
              {brain.name}
            </MenuItem>
          ))}
        </PopUpMultiSelect>
      ) : (
        <TextField
          label={brains.length ? 'Brain' : 'No brain to select'}
          select
          SelectProps={{ disabled: !brains.length }}
          value={values}
          onChange={event => {
            const tempVal = event.target.value
            const val = (
              tempVal && !Array.isArray(tempVal) ? [tempVal] : tempVal
            ).shift()
            onChange(val)
          }}
        >
          {brains
            .sort((a, b) =>
              `${a.name}`.localeCompare(`${b.name}`, 'en', { sensitivity: 'base' })
            )
            .map(brain => (
              <MenuItem key={brain.id} value={brain.id}>
                {brain.name}
              </MenuItem>
            ))}
        </TextField>
      )}
    </div>
  )
}

BrainSelectControl.propTypes = {
  id: PropTypes.string.isRequired,
  split: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  title: PropTypes.string,
  color: PropTypes.string,
}

export default BrainSelectControl
