import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import Spinner from 'components/Spinner'
import { MenuItem, TextField } from '@material-ui/core'

const classes = {
  wrapper: 'UserControl-wrapper',
  title: 'UserControl-title',
}

const BrainFeatureSelectControl = props => {
  const { brains: storeBrains } = useStore('selectBrains')

  const {
    values: propValues = [],
    onChange,
    chartType,
    multiple,
    ids,
    type: propType,
    singleType = false,
    title,
    color,
  } = props

  const trainings =
    storeBrains.filter(training => ids.indexOf(training.id) !== -1) || []
  const loading = trainings
    .filter(training => training.isLoading)
    .map(({ id }) => id)

  const type = (propType && (!Array.isArray(propType) ? [propType] : propType)) || []

  // ALL BRAIN FEATURES FILTERED BY STATIC TYPE
  const features = trainings
    .reduce((acc, { data }) => {
      const tempResult = [...acc]
      const tempFeatures = []
      if (chartType === 'learningCurve')
        tempFeatures.push(...(data?.learningCurve?.objectives || []))
      else if (chartType === 'rocCurve')
        tempFeatures.push(...(data?.rocCurve?.features || []))
      else {
        tempFeatures.push(...(data?.objectives?.objectives || []))
        if (!type.length || type.indexOf('category') !== -1)
          tempFeatures.push({ name: 'name', type: 'category' }) // add Swarm Name as option if category features can be selected
      }
      tempFeatures.forEach(feature => {
        if (
          !tempResult.find(
            res => feature.type === res.type && feature.name === res.name
          )
        )
          tempResult.push({
            type: feature.type,
            name: feature.name,
            id: `${feature.name}-${feature.type}`,
          })
      })
      return tempResult
    }, [])
    .filter(feature => (type.length ? type.indexOf(feature.type) !== -1 : true))

  const values = multiple ? propValues : propValues.slice(0, 1)

  // SELECTED FEATURES
  const filteredValues = values.reduce((acc, cur) => {
    const result = [...acc]
    const tempFeature = features.find(
      feature => feature.type === cur.type && feature.name === cur.name
    )
    if (tempFeature)
      result.push({
        type: tempFeature.type,
        name: tempFeature.name,
        id: tempFeature.id,
      })
    return result
  }, [])

  const dynamicType = (filteredValues[0] && filteredValues[0].type) || null

  // ALL BRAIN FEATURES FILTERED BY DYNAMIC TYPE
  const featuresOfType = features.filter(feature =>
    singleType && dynamicType ? feature.type === dynamicType : true
  )

  const finalValues = filteredValues
    .filter(({ id }) => featuresOfType.find(fot => id === fot.id))
    .map(({ id }) => id)

  return (
    <div
      className={classes.wrapper}
      style={{
        filter: color ? `drop-shadow(0px 0px 5px ${color})` : '',
        boxShadow: color ? `0px 0px 3px 1px ${color}` : '',
      }}
    >
      <div className={classes.title}>
        <span>{title}</span>
        <Conditional dependencies={loading.length}>
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
        </Conditional>
      </div>
      <TextField
        label={
          features.length
            ? `Feature${(multiple && 's') || ''}`
            : `No feature${(multiple && 's') || ''} to select`
        }
        select
        SelectProps={{ multiple: true, disabled: !features.length }}
        value={finalValues}
        onChange={event => {
          const tempVal = event.target.value
          const val = tempVal && !Array.isArray(tempVal) ? [tempVal] : tempVal
          const tempValues = [...val].map(e => {
            const feature = features.find(feat => feat.id === e)
            return { type: feature.type, name: feature.name, id: feature.id }
          })
          const difference = tempValues.filter(
            ({ id }) => finalValues.indexOf(id) === -1
          )
          onChange(multiple ? tempValues : difference)
        }}
      >
        {featuresOfType
          .sort((a, b) =>
            `${a.name}`.localeCompare(`${b.name}`, 'en', { sensitivity: 'base' })
          )
          .map(feature => {
            return (
              <MenuItem key={feature.id} value={feature.id}>
                {`${feature.name} (${feature.type})`}
              </MenuItem>
            )
          })}
      </TextField>
    </div>
  )
}

BrainFeatureSelectControl.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
  ),
  onChange: PropTypes.func,
  chartType: PropTypes.oneOf(['learninfCurve', 'rocCurve', 'objectives']),
  multiple: PropTypes.bool,
  type: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(['numeric', 'category'])),
    PropTypes.oneOf(['numeric', 'category']),
  ]),
  singleType: PropTypes.bool,
  title: PropTypes.string,
  color: PropTypes.string,
}

export default BrainFeatureSelectControl
