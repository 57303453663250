import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useRefRect } from 'libs/utils/hooks'

import { useMouseTracker } from 'components/MouseTracker'

const classes = {
  wrapper: 'Grid-wrapper',
  grid: 'Grid-grid',
}

const Grid = props => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [rect, rectRef] = useRefRect()
  const { grid, style } = props

  let { top: absTop = 0, left: absLeft = 0 } = { ...rect }
  const { width = 1, height = 1 } = { ...rect }
  absTop += (rect || 0) && rectRef.current.getBoundingClientRect().top
  absLeft += (rect || 0) && rectRef.current.getBoundingClientRect().left
  const xRange = 1000
  const yRange = 1000

  const { xGridPoints, yGridPoints } = grid || {}

  const updateMouseData = useCallback(
    hookMouseData => {
      const xRatio = (width && 1 / width) || 0
      const yRatio = (height && 1 / height) || 0
      const x = (hookMouseData.x - absLeft) * xRatio
      const y = (hookMouseData.y - absTop) * yRatio
      setPosition({ x, y })
    },
    [setPosition, absLeft, absTop, width, height]
  )
  useMouseTracker(hookMouseData => updateMouseData(hookMouseData))

  return (
    <div className={classes.wrapper}>
      <svg
        ref={rectRef}
        className={classes.grid}
        style={{
          ...style,
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${xRange} ${yRange}`}
        preserveAspectRatio="none"
      >
        {xGridPoints?.map(point => (
          <line
            key={`verticalLine-${point}`}
            x1={point * xRange || 0}
            x2={point * xRange || 0}
            y1={0}
            y2={yRange}
          />
        ))}
        {yGridPoints?.map(point => (
          <line
            key={`horizontalLine-${point}`}
            x1={0}
            x2={xRange || 0}
            y1={(1 - point) * yRange || 0}
            y2={(1 - point) * yRange || 0}
          />
        ))}
        <line
          x1={position.x * xRange || 0}
          x2={position.x * xRange || 0}
          y1={0}
          y2={yRange || 0}
        />
        <line
          x1={0}
          x2={xRange || 0}
          y1={position.y * yRange || 0}
          y2={position.y * yRange || 0}
        />
      </svg>
    </div>
  )
}

Grid.propTypes = {
  grid: PropTypes.shape({
    xGridPoints: PropTypes.arrayOf(PropTypes.number),
    yGridPoints: PropTypes.arrayOf(PropTypes.number),
  }),
  style: PropTypes.object,
}

export default Grid
