/* eslint-disable max-lines */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import { UserControlWrapper } from './UCW'
import BarGraph from './BarGraph'
import { Provider } from './BarChartContext'

import './style.scss'

const classes = {
  wrapper: 'BarChart-wrapper',
}

const BarChart = props => {
  const {
    brains: storeBrains,
    explanations: storeExplainations,
    resourceData,
    resourceMeta,
  } = useStore(
    'selectBrains',
    'selectExplanations',
    'selectResourceData',
    'selectResourceMeta'
  )

  const {
    controlable,
    // legend,
    trainings: propTrainings,
    brains: propBrains,
    split = 'train',
    datasets: propDatasets,
    stackingCategories: propStackingCategories,
    // datasetType: propDatasetType,
    features: propFeatures,
    featureType: propFeatureType,
    scoredDataset: propScoredDataset,
    objective: propObjective,
    brain: propBrain,
    explanation: propExplaination,
    ...rest
  } = props

  const { xAxis: { label: xLabel } = {}, yAxis: { label: yLabel } = {} } = rest

  let scoredDataset = propScoredDataset?.id || null
  let objective = propObjective || null
  let brain = propBrain || null

  if (propExplaination) {
    if (!scoredDataset) scoredDataset = propExplaination.scored_dataset_id || null
    if (propExplaination.scored_dataset_id === scoredDataset) {
      if (!objective) objective = propExplaination.objective || null
      if (propExplaination.objective === objective) {
        if (!brain) brain = propExplaination.brain_id || null
      }
    }
  }

  // const { position: legendPosition = 'left', width: legendWidth = 160 } =
  //   (legend && legend !== true ? legend : {}) || {}

  const [
    trainings,
    brains,
    datasets,
    stackingCategories,
    mode,
    features,
    featureType,
  ] = useMemo(() => {
    const tempTrainings =
      (propTrainings &&
        (!Array.isArray(propTrainings) ? [propTrainings] : propTrainings)) ||
      []
    const tempBrains = (propBrains && [...propBrains]) || []
    if (tempTrainings)
      tempTrainings.forEach(training => {
        const tempTraining = tempBrains?.find(e => e.id === training.id)
        if (!tempTraining) tempBrains.push({ id: training.id, brains: [] })
      })
    const tempDatasets =
      (propDatasets &&
        (!Array.isArray(propDatasets) ? [propDatasets] : propDatasets).map(
          dataset => dataset.id
        )) ||
      []
    const tempStackingCategories =
      (propStackingCategories &&
        (!Array.isArray(propStackingCategories)
          ? [propStackingCategories]
          : propStackingCategories)) ||
      []
    let tempMode = 'datasetMode'
    if (
      controlable?.scoring ||
      controlable?.scoredDataset ||
      controlable?.objective ||
      controlable?.brain ||
      propScoredDataset ||
      propObjective ||
      propBrain ||
      propExplaination
    )
      tempMode = 'explanationMode'
    if (
      controlable?.trainings ||
      controlable?.brains ||
      propTrainings?.length ||
      propBrains?.length
    )
      tempMode = 'brainMode'
    let tempFeatures =
      (propFeatures &&
        (!Array.isArray(propFeatures) ? [propFeatures] : propFeatures)) ||
      []
    let tempFeatureType = null
    if (tempMode === 'brainMode')
      tempFeatures = [...tempFeatures].filter(
        (e, i) => typeof e === 'string' && tempFeatures.indexOf(e) === i
      )
    else {
      tempFeatures = tempFeatures.reduce((acc, cur) => {
        const tempFeats = [...acc]
        if (
          !tempFeats.find(
            feature => feature.type === cur.type && feature.name === cur.name
          )
        )
          tempFeats.push(cur)
        return tempFeats
      }, [])
      tempFeatureType = propFeatureType || tempFeatures[0]?.type || null
      tempFeatures = tempFeatures.filter(feature => feature.type === tempFeatureType)
    }
    return [
      tempTrainings,
      tempBrains,
      tempDatasets,
      tempStackingCategories,
      tempMode,
      tempFeatures,
      tempFeatureType,
    ]
  }, [
    propTrainings,
    propBrains,
    propDatasets,
    propStackingCategories,
    propFeatures,
    propFeatureType,
    controlable,
    propScoredDataset,
    propBrain,
    propObjective,
    propExplaination,
  ])

  const [stackedBy, datasetType] = useMemo(() => {
    const tempMeta = resourceMeta?.find(({ id }) => id === datasets[0])?.data || {}
    const { stacked_by: stackedById = null } = tempMeta
    const tempStackedBy =
      tempMeta.features?.find(({ id }) => id === stackedById) || null
    const tempType =
      // propDatasetType ||
      (datasets.length && tempMeta.type) || null
    return [tempStackedBy, tempType]
  }, [resourceMeta, datasets])

  if (controlable) {
    if (mode === 'brainMode') {
      controlable.scoring = null
      controlable.scoredDataset = null
      controlable.objective = null
      controlable.brain = null
      controlable.datasets = null
      controlable.stackingCategories = null
    } else if (mode === 'explanationMode') {
      controlable.trainings = null
      controlable.brains = null
      controlable.split = null
      controlable.datasets = null
      controlable.stackingCategories = null
    } else {
      controlable.trainings = null
      controlable.brains = null
      controlable.split = null
      controlable.scoring = null
      controlable.scoredDataset = null
      controlable.objective = null
      controlable.brain = null
    }
  }

  const storeData = {
    brains: storeBrains || [],
    datasets:
      datasets
        .map(
          dataset =>
            resourceData.find(resource => resource.key === `chart-${dataset}`) ||
            null
        )
        .filter(e => !!e) || [],
    explanations: storeExplainations,
  }

  const loading = useMemo(() => {
    return (
      !!storeData.brains.some(({ isLoading }) => isLoading) ||
      !!storeData.datasets.some(({ isLoading }) => isLoading) ||
      !!storeData.explanations.some(({ isLoading }) => isLoading)
    )
  }, [storeData.brains, storeData.datasets, storeData.explanations])

  const xAxisLabel = useMemo(() => {
    const tempXLabel = features.map(feature => feature.name).join(', ')
    return xLabel || tempXLabel
  }, [features, xLabel])

  const data = useMemo(() => {
    const returnData = []
    if (
      mode === 'brainMode' &&
      !controlable &&
      trainings.length &&
      brains.length &&
      features.length
    ) {
      const resultData = features.reduce((acc, cur) => {
        const tempResult = trainings
          .map(({ name, id }) => {
            const { objectives: tempBrains = {} } =
              storeData.brains.find(training => training.id === id)?.data || {}
            const { features: objectives } = tempBrains
            return { name, brains: tempBrains, objectives, id }
          })
          .filter(
            training =>
              training.objectives &&
              training.objectives.find(
                obj => obj.type === cur.type && obj.name === cur.name
              )
          )
          .map(training => {
            const filteredBrains = training.brains[split].filter(
              tempBrain =>
                brains
                  .find(e => e.id === training.id)
                  .brains.indexOf(tempBrain.id) !== -1
            )
            return filteredBrains.map(e => e[cur])
          })
        const combinedResult = tempResult.reduce((accumulated, current) => {
          const tempCombined = [...accumulated, ...current]
          return tempCombined
        }, [])
        return [...acc, ...combinedResult]
      }, [])
      returnData.push({
        title: `${trainings.length > 1 ? 'combined' : trainings[0]?.name}: ${
          features.length > 1 ? 'combined' : features[0]
        }`,
        data: [...resultData],
      })
    }
    if (
      mode !== 'brainMode' &&
      !controlable &&
      scoredDataset &&
      objective &&
      brain
    ) {
      const resultData =
        storeData.explanations
          .find(({ id }) => id === scoredDataset)
          ?.data?.find(
            ({ objective: expObj, brain_id: expBId, row: expR }) =>
              expObj === objective && expBId === brain && !(expR || expR === 0)
          )?.data?.weights || {}
      returnData.push({
        title: 'Overall importance',
        data:
          Object.keys(resultData).reduce((acc, cur) => {
            const tempResult = [...acc]
            tempResult.push({ category: cur, value: Math.abs(resultData[cur]) })
            return tempResult
          }, []) || [],
      })
    }
    if (
      mode !== 'brainMode' &&
      mode !== 'explanationMode' &&
      !controlable &&
      datasets.length &&
      (datasetType !== 'stacked-timeseries' ||
        (stackedBy && stackingCategories.length)) &&
      features.length
    ) {
      const resultData = features.reduce((acc, cur) => {
        const tempResult = storeData.datasets
          .filter(instance =>
            instance?.meta?.features?.find(
              tempFeature =>
                tempFeature.type === cur.type && tempFeature.name === cur.name
            )
          )
          .map(
            instance =>
              instance.data
                .filter(
                  e =>
                    datasetType !== 'stacked-timeseries' ||
                    stackingCategories.indexOf(e[stackedBy.name]) !== -1
                )
                .map(e =>
                  featureType === 'numeric' ? Number(e[cur.name]) : e[cur.name]
                )
                .filter(val => !!val || val === 0) || []
          )
        const combinedResult = tempResult.reduce((accumulated, current) => {
          const tempCombined = [...accumulated, ...current]
          return tempCombined
        }, [])
        return [...acc, ...combinedResult]
      }, [])
      // const name =
      //   datasets.length > 1
      //     ? 'combined'
      //     : resourceMeta?.find(dataset => dataset.id === datasets[0])?.data?.name ||
      //       ''
      returnData.push({
        // title: `${name}: ${features.length > 1 ? 'combined' : features[0]?.name}`,
        data: [...resultData],
      })
    }
    return returnData
  }, [
    mode,
    controlable,
    trainings,
    brains,
    split,
    storeData.brains,
    scoredDataset,
    brain,
    objective,
    storeData.explanations,
    storeData.datasets,
    datasets,
    stackedBy,
    datasetType,
    features,
    featureType,
    stackingCategories,
    // resourceMeta,
  ])

  const contextData = {
    data,
    xAxisLabel,
    yAxisLabel: yLabel,
    type: featureType,
    loading,
  }

  if (mode === 'explanationMode') contextData.type = 'explanation'

  return (
    <div className={classes.wrapper}>
      <Provider value={contextData}>
        <Conditional dependencies={controlable}>
          <UserControlWrapper
            controlable={controlable}
            trainings={trainings}
            brains={brains}
            split={split}
            datasets={datasets}
            stackingCategories={stackingCategories}
            datasetType={datasetType}
            features={features}
            featureType={featureType}
            labels={{ x: xLabel, y: yLabel }}
          >
            <BarGraph
              // legend={legend && { position: legendPosition, width: legendWidth }}
              {...rest}
            />
          </UserControlWrapper>
        </Conditional>
        <Conditional dependencies={!controlable}>
          <BarGraph
            // legend={legend && { position: legendPosition, width: legendWidth }}
            {...rest}
          />
        </Conditional>
      </Provider>
    </div>
  )
}

BarChart.propTypes = {
  controlable: PropTypes.shape({
    trainings: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    brains: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    split: PropTypes.bool,
    datasets: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    stackingCategories: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    features: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    scoring: PropTypes.bool,
    scoredDataset: PropTypes.bool,
    objective: PropTypes.bool,
    brain: PropTypes.bool,
    prefKey: PropTypes.string,
  }),
  // legend: PropTypes.oneOfType([
  //   PropTypes.shape({
  //     position: PropTypes.oneOf(['left', 'right']),
  //     width: PropTypes.number,
  //   }),
  //   PropTypes.bool,
  // ]),
  xAxis: PropTypes.oneOfType([
    PropTypes.shape({
      height: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
      label: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  yAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
      label: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  grid: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  barWidth: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),

  // /////////////// TRAININGS DISPLAY
  trainings: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
    ),
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  ]),
  brains: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      brains: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  split: PropTypes.oneOf(['combined', 'test', 'train', 'validation']),
  // /////////////////////////////////

  // //////////////// DATASETS DISPLAY
  datasets: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    PropTypes.shape({ id: PropTypes.string }),
  ]),
  stackingCategories: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  // datasetType: PropTypes.oneOf(['stacked-timeseries', 'basedata', 'timeseries']),
  // /////////////////////////////////

  features: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(['numeric', 'category']),
        name: PropTypes.string,
      })
    ),
    PropTypes.shape({
      type: PropTypes.oneOf(['numeric', 'category']),
      name: PropTypes.string,
    }),
  ]),
  featureType: PropTypes.oneOf(['numeric', 'category']),

  // //////////////// EXPLANATION DISPLAY
  scoredDataset: PropTypes.shape({ id: PropTypes.string }),
  objective: PropTypes.string,
  brain: PropTypes.string,
  explanation: PropTypes.shape({
    scored_dataset_id: PropTypes.string,
    objective: PropTypes.string,
    brain_id: PropTypes.string,
  }),
  // /////////////////////////////////

  mainColor: PropTypes.string,
  color: PropTypes.string,
}

export default BarChart
