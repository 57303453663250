import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import { LineChart, ScatterChart, RainflowChart, BarChart } from 'components/Charts'
import { ResourceTable, Provider as RenderProvider } from 'components/ResourceTable'
import Modal from 'components/Modal'

import { useInteractiveClick } from 'libs/interactive'

import 'style.scss'

const PREF_KEY = 'ScoringInspector-expanded'
const RESOURCE_TABLE_KEY = 'ScoringInspector-table'

const classes = {
  wrapper: 'ScoringInspector-wrapper',
  header: 'ScoringInspector-header',
  download: 'download-link',
  body: 'ScoringInspector-body',
  content: 'ScoringInspector-content',
  tableWrapper: 'ScoringInspector-brains-table-wrapper',
  chartWrapper: 'ScoringInspector-chart-wrapper',
  trackBorder: 'ScoringInspector-track-border',
  track: 'ScoringInspector-track',
  tab: 'ScoringInspector-tab',
  explainerModalContentWrapper: 'ScoringInspector-explainerModalContentWrapper',
}

const ScoringInspector = ({ title, resource, resourceType }) => {
  const [tab, setTab] = useState('table')
  const [expanded, setExpanded] = useState(null)
  const [explainerData, setExplainerData] = useState(null)
  const contentRef = useRef()
  const trackRef = useRef()

  const {
    resourceDataResources: resources,
    explanations,
    requestModelExplanation,
    registerExplanationsRequest,
    registerResourceRequest,
  } = useStore(
    'selectResourceDataResources',
    'selectExplanations',
    'requestModelExplanation',
    'registerExplanationsRequest',
    'registerResourceRequest'
  )

  const resourceExplanations =
    explanations.find(e => e.id === resource)?.data || null

  const openExplainer = useCallback(
    (column, row) => {
      const explanation = resourceExplanations.find(
        e =>
          e.column === column &&
          (row === 'overall' ? e.row === null : e.row === Number(row))
      )
      if (explanation) setExplainerData(explanation)
    },
    [resourceExplanations]
  )
  const closeExplainer = useCallback(() => setExplainerData(null), [])

  const ctx = useMemo(
    () => ({
      resourceType: 'SCORED_DATASET', // TODO: fix when backend fixes things
      explanations: resourceExplanations,
      openExplainer,
      closeExplainer,
      requestModelExplanation,
    }),
    [
      // resourceType, // TODO: fix when backend fixes things
      requestModelExplanation,
      resourceExplanations,
      openExplainer,
      closeExplainer,
    ]
  )

  const loading = !!resources.find(e => e.id === resource)?.isLoading
  const prepared = !!resources.find(e => e.id === resource)?.prepared
  const data = prepared

  const pref = window.sessionStorage[PREF_KEY]
    ? JSON.parse(window.sessionStorage[PREF_KEY])
    : null
  const autoExpand =
    pref !== null ? pref : expanded || (expanded === null && data ? true : expanded)

  const { onClick, onKeyDown } = useInteractiveClick(() => {
    if (data) {
      setExpanded(!(expanded || expanded === null))
      window.sessionStorage[PREF_KEY] = !autoExpand
    }
  })

  useEffect(() => {
    const contentNode = contentRef.current
    const timeout = autoExpand ? 0 : 300
    const id = setTimeout(() => {
      contentNode && (contentNode.style.visibility = autoExpand ? '' : 'hidden')
    }, timeout)
    return () => {
      clearTimeout(id)
    }
  }, [autoExpand])

  useEffect(() => {
    if (loading) {
      let el = document.activeElement
      while (el) {
        el = el.parentElement
        if (el === contentRef.current) break
      }
      if (!el) {
        trackRef.current && trackRef.current.focus()
      }
    }
  }, [loading])

  useEffect(() => {
    registerExplanationsRequest(resource)
  }, [registerExplanationsRequest, resource])

  useEffect(() => {
    if (tab === 'chart')
      registerResourceRequest(`chart-${resource}`, resource, 'chart')
  }, [tab, registerResourceRequest, resource])

  return (
    <div className={classes.wrapper}>
      <Conditional dependencies={resource}>
        <div className={classes.body} data-active={autoExpand}>
          <div
            className={classes.track}
            onClick={onClick}
            role="button"
            tabIndex={0}
            onKeyDown={onKeyDown}
            data-expanded={autoExpand}
            data-loading={loading}
            ref={trackRef}
          >
            <Icon data-icon="collapse" type="arrow-left" />
            <Icon data-icon="loading" type="spinner" />
          </div>
          <div
            className={classes.tab}
            onClick={event => {
              event.stopPropagation()
              setTab('table')
              if (!expanded) {
                setExpanded(true)
                window.sessionStorage[PREF_KEY] = true
              }
            }}
            role="button"
            tabIndex={0}
            onKeyDown={event => {
              event.stopPropagation()
              if (event.keyCode === 13) {
                setTab('table')
                if (!expanded) {
                  setExpanded(true)
                  window.sessionStorage[PREF_KEY] = true
                }
              }
            }}
            data-tab={tab === 'table' ? 'active' : ''}
          >
            <Icon type="list" />
          </div>
          <div
            className={classes.tab}
            onClick={event => {
              event.stopPropagation()
              setTab('chart')
              if (!expanded) {
                setExpanded(true)
                window.sessionStorage[PREF_KEY] = true
              }
            }}
            role="button"
            tabIndex={0}
            onKeyDown={event => {
              event.stopPropagation()
              if (event.keyCode === 13) {
                setTab('chart')
                if (!expanded) {
                  setExpanded(true)
                  window.sessionStorage[PREF_KEY] = true
                }
              }
            }}
            data-tab={tab === 'chart' ? 'active' : ''}
          >
            <Icon type="chart" />
          </div>
          <div className={classes.trackBorder} />
          <div className={classes.content} ref={contentRef}>
            <h2 className={classes.header}>{title}</h2>
            {/* <div className={classes.chartsWrapper}>Chart Area</div> */}
            <div
              className={classes.tableWrapper}
              style={{ display: tab !== 'table' ? 'none' : '' }}
            >
              <RenderProvider value={ctx}>
                <ResourceTable
                  key={resource}
                  requestKey={RESOURCE_TABLE_KEY}
                  resource={resource}
                  perPage={14}
                >
                  {/* <Column
                  dataKey="_defaultColumn"
                  width={120}
                  resizable={{ minWidth: 120 }}
                /> */}
                </ResourceTable>
              </RenderProvider>
            </div>
            <div
              className={classes.chartWrapper}
              style={{ display: tab !== 'chart' ? 'none' : '' }}
            >
              <LineChart
                controlable={{
                  stackingCategories: { multiple: true },
                  x: {},
                  y: { multiple: true },
                }}
                legend={{ position: 'right', width: 200 }}
                xAxis={{ label: ' ', height: 50 }}
                yAxis={{ label: ' ', width: 50 }}
                grid
                datasets={{ id: resource }}
                mainColor="#1E90FF"
              />
              <ScatterChart
                controlable={{
                  stackingCategories: { multiple: true },
                  x: {},
                  y: { multiple: true },
                  z: true,
                }}
                legend={{ position: 'right', width: 200 }}
                xAxis={{ label: ' ', height: 50 }}
                yAxis={{ label: ' ', width: 50 }}
                grid
                datasets={{ id: resource }}
                mainColor="#1E90FF"
              />
            </div>
          </div>
        </div>
        <Modal
          open={!!explainerData}
          onClose={closeExplainer}
          title={`${explainerData?.column}: ${explainerData?.row || 'overall'}`}
        >
          <div className={classes.explainerModalContentWrapper}>
            <Conditional
              dependencies={explainerData?.row || explainerData?.row === 0}
            >
              <RainflowChart
                explanation={{
                  scored_dataset_id: explainerData?.scored_dataset_id,
                  objective: explainerData?.objective,
                  brain_id: explainerData?.brain_id,
                  row: explainerData?.row,
                }}
                xAxis={{ height: 160, normal: 'left' }}
                yAxis={{ width: 100 }}
                grid={25}
                color="#1E90FF"
              />
            </Conditional>
            <Conditional
              dependencies={!(explainerData?.row || explainerData?.row === 0)}
            >
              <BarChart
                explanation={{
                  scored_dataset_id: explainerData?.scored_dataset_id,
                  objective: explainerData?.objective,
                  brain_id: explainerData?.brain_id,
                }}
                xAxis={{ height: 160, normal: 'left', label: 'Features' }}
                yAxis={{ width: 100, label: 'Impact' }}
                grid={25}
              />
            </Conditional>
          </div>
        </Modal>
      </Conditional>
    </div>
  )
}

ScoringInspector.propTypes = {
  title: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
}

export default ScoringInspector
