import React, { useRef } from 'react'
import { Link, useStore, useHistory } from 'core'
import { useInteractiveClick } from 'libs/interactive'

import Logo from 'logo.svg'
import ContextualMenu from 'components/ContextualMenu'
import Icon from 'components/Icon'

import 'style.scss'

const classes = {
  wrapper: 'Header-wrapper',
  logoDiv: 'Header-logoDiv',
  contextualMenuDiv: 'Header-contextualMenuDiv',
  controlBar: 'Header-controlBar',
  loginLink: 'Header-login-link',
  accountButton: 'Header-accountButton',
  userDropdown: 'Header-userDropdown',
  dropdownItem: 'Header-userDropdown-item',
}

const Header = () => {
  const dropdownRef = useRef()
  const history = useHistory()
  const {
    // theme,
    // updatePref,
    logout,
    user,
  } = useStore(
    // 'selectTheme',
    // 'updatePref',
    'logout',
    'selectUser'
  )

  const { onClick, onKeyDown } = useInteractiveClick(logout)
  // const changeTheme = () => {
  //   const newTheme = theme === 'light' ? 'dark' : 'light'
  //   updatePref({ prefKey: 'theme', data: newTheme })
  // }

  return (
    <div className={classes.wrapper}>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <div
        className={classes.logoDiv}
        onClick={() => {
          history.push('/dashboard')
        }}
      >
        <Logo />
      </div>
      {/* eslint-enable jsx-a11y/click-events-have-key-events */}
      {/* eslint-enable jsx-a11y/no-static-element-interactions */}
      <div className={classes.contextualMenuDiv}>
        <ContextualMenu />
      </div>
      <div className={classes.controlBar}>
        {/* <Icon
          type="sun"
          tabIndex={0}
          role="button"
          onClick={event => {
            event.stopPropagation()
            changeTheme()
          }}
          onKeyDown={event => {
            event.stopPropagation()
            if (event.keyCode === 13) changeTheme()
          }}
        /> */}
        {user ? (
          <div
            ref={dropdownRef}
            className={classes.userDropdown}
            onFocus={() => {
              dropdownRef.current.scrollTop = 0
            }}
          >
            <Link
              className={classes.accountButton}
              to="/account"
              absolute
              indexable
              exact
            >
              <span>{user?.username}</span>
            </Link>
            <div>
              {/* <button
              className={classes.dropdownItem}
              type="button"
              onClick={event => event.target.blur()}
            >
              My plan
            </button>
            <button
              className={classes.dropdownItem}
              type="button"
              onClick={event => event.target.blur()}
            >
              Settings
            </button> */}
              <a
                className={classes.dropdownItem}
                href="/pricing"
                role="button"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  history.push('/pricing')
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    e.stopPropagation()
                    e.preventDefault()
                    history.push('/pricing')
                  }
                }}
              >
                Pricing
              </a>
              <a
                className={classes.dropdownItem}
                href="/logout"
                role="button"
                onClick={onClick}
                onKeyDown={onKeyDown}
              >
                Logout
              </a>
            </div>
          </div>
        ) : (
          <Link className={classes.loginLink} to="/login" absolute>
            Login
          </Link>
        )}
      </div>
    </div>
  )
}

export default Header
