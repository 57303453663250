import React from 'react'
import { useStore, Switch, Route } from 'core'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import { TrainingMenuButton } from 'components/TrainingWizard'
import { ScoringMenuButton } from 'components/ScoringWizard'
import { PipelineMenuButton } from 'components/PipelineInspector'
import { FileStateMenuButton } from 'components/FileStateInspector'
import MenuItem from './MenuItem'
import { Provider } from './MenuContext'

import 'style.scss'

const classes = {
  wrapper: 'Menu-wrapper',
  logoutButton: 'Menu-logoutButton',
}

const USER_PERMISSIONS = {
  MANAGE_USER_LICENCES: 'MANAGE_USER_LICENCES',
  MANAGE_ORGANIZATION_PAYMENTS: 'MANAGE_ORGANIZATION_PAYMENTS',
  MANAGE_USER_PERMISSIONS: 'MANAGE_USER_PERMISSIONS',
}

const UserMenu = () => {
  const { logout, users, user, /* portalUrl, */ openPortal } = useStore(
    'logout',
    'selectUsers',
    'selectUser',
    // 'selectPortalUrl',
    'openPortal'
  )

  const { id: userId } = user || {}

  const userPermissions = users
    ?.find(({ id }) => id === userId)
    ?.permissions?.filter(p => Object.values(USER_PERMISSIONS).indexOf(p) !== -1)

  // const canManageUserPermissions =
  //   userPermissions?.indexOf(USER_PERMISSIONS.MANAGE_USER_PERMISSIONS) !== -1
  const canManageUserLinences =
    userPermissions?.indexOf(USER_PERMISSIONS.MANAGE_USER_LICENCES) !== -1
  const canManagePayments =
    userPermissions?.indexOf(USER_PERMISSIONS.MANAGE_ORGANIZATION_PAYMENTS) !== -1 // && !!portalUrl

  const heightString = `calc(${
    2 +
    Number(canManagePayments) +
    // Number(canManagePayments || canManageUserLinences) +
    Number(canManageUserLinences)
  } * 2em + 3em`

  const portalHandler = e => {
    e.preventDefault()
    e.stopPropagation()
    openPortal()
  }

  return (
    <MenuItem
      to="/account"
      icon="account"
      text="Account"
      // dropdownDirection="up"
      button={
        <button className={classes.logoutButton} type="button" onClick={logout}>
          <Icon type="power" />
        </button>
      }
      heightString={heightString}
    >
      <MenuItem to="/account/profile" simple text="Profile Settings" />
      <MenuItem to="/account/users" simple text="Users" />
      <Conditional dependencies={canManageUserLinences}>
        <MenuItem to="/account/licences" simple text="Licences" />
      </Conditional>
      <Conditional dependencies={canManagePayments}>
        <MenuItem simple text="ChargeBee Portal" onClick={portalHandler} />
      </Conditional>
      {/* <Conditional dependencies={canManagePayments}>
        <MenuItem to="/account/plans" simple text="Plans" />
      </Conditional> */}
      {/* <MenuItem to="/account/prefs" simple text="Preferences" /> */}
    </MenuItem>
  )
}

const Menu = () => {
  const { updatePref, pref: storePref } = useStore('updatePref', 'selectPref')
  const { menu: pref = {} } = storePref
  const { collapsed = false } = pref

  return (
    <div className={`${classes.wrapper}${collapsed ? ' collapsed' : ''}`}>
      <Provider value={{ indexable: true }}>
        <div>
          <MenuItem to="/dashboard" icon="dashboard" text="Dashboard" />
          <MenuItem to="/resources" icon="resources" text="Resources">
            <MenuItem to="/resources/datasources/static" simple text="Static Data" />
            <MenuItem
              to="/resources/datasources/dynamic"
              simple
              text="Dynamic Data"
            />
            <MenuItem to="/resources/pipelines" simple text="Pipelines" />
            <MenuItem to="/resources/models" simple text="Models" />
            <MenuItem to="/resources/predictions" simple text="Predictions" />
          </MenuItem>
          <MenuItem to="/charts" icon="chart" text="Charts">
            <MenuItem to="/charts/datasets" simple text="Datasets" />
            <MenuItem to="/charts/trainings" simple text="Trainings" />
            {/* <MenuItem
              to="/charts/explanations"
              simple
              disabled
              text="Explanations"
            /> */}
          </MenuItem>
          {/* <MenuItem to="/dev" icon="dev" text="_Dev">
            <MenuItem to="/dev/charts" simple text="Charts" />
            <MenuItem to="/dev/pipeline" simple text="Pipeline" />
            <MenuItem to="/dev/Simeon" simple text="Simeon" />
            <MenuItem to="/dev/Marko" simple text="Marko" />
            <MenuItem to="/dev/Mitar" simple text="Mitar" />
          </MenuItem> */}
          <Switch>
            <Route path="/account" component={UserMenu} />
          </Switch>
        </div>
        <div>
          <div>
            <TrainingMenuButton />
            <ScoringMenuButton />
            <PipelineMenuButton />
            <FileStateMenuButton />
          </div>
          <button
            type="button"
            onClick={event => {
              event.stopPropagation()
              updatePref({ prefKey: 'menu', data: { collapsed: !collapsed } })
            }}
          >
            <span>collapse menu</span>
            <Icon type="arrow-down" />
          </button>
        </div>
      </Provider>
    </div>
  )
}

export default Menu
