import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import {
  isObject,
  getBranchNodeAmount,
  getBranchLeafAmount,
} from 'libs/utils/helpers'

import Conditional from 'components/Conditional'
import Node from './Node'

import './style.scss'

const classes = {
  wrapper: 'Branch-wrapper',
  crown: 'Branch-crown',
  trunk: 'Branch-trunk',
  straigthLine: 'Branch-straigthLine',
}

const Branch = props => {
  const { spec, meta, nodePadding = 3, boxSize = 7.5, location = '0' } = props
  const treeDepth = useMemo(() => getBranchNodeAmount(spec), [spec])
  const treeLeaves = useMemo(() => getBranchLeafAmount(spec), [spec])
  const boxes = useMemo(() => spec.filter(node => isObject(node)), [spec])
  const branches = useMemo(
    () => spec.filter(node => Array.isArray(node)).map(branch => [...branch]),
    [spec]
  )
  const metaBoxes = useMemo(() => meta?.filter(node => isObject(node)) || [], [meta])
  const metaBranches = useMemo(
    () => meta?.filter(node => Array.isArray(node)).map(branch => [...branch]) || [],
    [meta]
  )

  return (
    <div className={classes.wrapper} style={{ flex: treeLeaves || 1 }}>
      <Conditional dependencies={branches.length}>
        <div
          className={classes.crown}
          tabIndex={-1}
          style={{ height: `${(1 - boxes.length / treeDepth) * 100}%` }}
        >
          {branches.map((branch, i) => (
            <Branch
              key={`Branch-${location ? `${location}.${i}` : i}`}
              spec={branch}
              meta={metaBranches[i]}
              nodePadding={nodePadding}
              boxSize={boxSize}
              location={location ? `${location}.${i}` : i}
            />
          ))}
        </div>
      </Conditional>
      <div
        className={classes.trunk}
        tabIndex={-1}
        style={{ height: `${(boxes.length / treeDepth) * 100}%` }}
      >
        {boxes.map((box, i) => (
          <Node
            // eslint-disable-next-line react/no-array-index-key
            key={`Branch-${location}-Node-${i}`}
            spec={box}
            meta={metaBoxes[i]}
            padding={nodePadding}
            size={boxSize}
          />
        ))}
      </div>
    </div>
  )
}

Branch.propTypes = {
  spec: PropTypes.array.isRequired,
  meta: PropTypes.array.isRequired,
  nodePadding: PropTypes.number,
  boxSize: PropTypes.number,
  location: PropTypes.string,
}

export default Branch
