/* eslint-disable camelcase */
import { createBundle } from 'core/bundler'
import { createSelector } from 'create-selector'

import {
  PLANS_FETCH_STARTED,
  PLANS_FETCH_SUCCEEDED,
  PLANS_FETCH_FAILED,
  // CHARGEBEE_PORTAL_REQUEST_STARTED,
  // CHARGEBEE_PORTAL_REQUEST_SUCCEEDED,
  // CHARGEBEE_PORTAL_REQUEST_FAILED,
  // USER_INVITATION_FAILED,
} from 'core/actiontypes'

const name = 'chargebee'
const initialState = {
  plans: {
    data: [],
    isLoading: false,
    error: null,
  },
  // portalUrlPending: false,
  // portalUrlError: [],
  // portalUrl: null,
}
// =================================
//              PLANS
// =================================
const fetchPlans =
  () =>
  (dispatch, { api }) => {
    dispatch({ type: PLANS_FETCH_STARTED })
    api('/plans')
      .then(data => dispatch({ type: PLANS_FETCH_SUCCEEDED, payload: data }))
      .catch(err => dispatch({ type: PLANS_FETCH_FAILED, error: err }))
  }
// const portalInfoSelector = state => {
//   const { portalUrl, portalUrlError, portalUrlPending } = state[name]
//   return {
//     portalUrl,
//     portalUrlError,
//     portalUrlPending,
//   }
// }
const plansSelectorRaw = state => state[name].plans
const plansSelector = createSelector(plansSelectorRaw, plans => plans.data)
const reactMissingPlans = createSelector(plansSelectorRaw, plans => {
  if (plans.isLoading || plans.error) return null
  if (!plans.data.length) {
    return fetchPlans()
  }
  return null
})

// const requestPortalUrl =
//   () =>
//   (dispatch, { api }) => {
//     dispatch({ type: CHARGEBEE_PORTAL_REQUEST_STARTED })
//     return api('/portal')
//       .then(data => {
//         dispatch({ type: CHARGEBEE_PORTAL_REQUEST_SUCCEEDED, payload: data.url })
//       })
//       .catch(err => {
//         dispatch({ type: CHARGEBEE_PORTAL_REQUEST_FAILED, error: err })
//       })
//   }

// const selectPortalUrl = createSelector(
//   portalInfoSelector,
//   portal => portal.portalUrl
// )
// const reactMissingPortal = createSelector(
//   portalInfoSelector,
//   'selectContracts',
//   'selectUser',
//   (portal, contracts, user) => {
//     const { portalUrl, portalUrlPending, portalUrlError } = portal
//     const hasSubscriptions = contracts?.subscriptions?.length
//     if (portalUrl || portalUrlPending || portalUrlError.length >= 3) return null
//     if (!user || !hasSubscriptions) return null
//     if (user && hasSubscriptions && !portalUrl) {
//       return requestPortalUrl()
//     }
//     return null
//   }
// )

export default config =>
  createBundle({
    name,
    reducer: (state = initialState, { type: action, payload, error }) => {
      if (action === PLANS_FETCH_STARTED) {
        return {
          ...state,
          plans: {
            ...state.plans,
            isLoading: true,
          },
        }
      }
      if (action === PLANS_FETCH_SUCCEEDED) {
        return {
          ...state,
          plans: {
            ...state.plans,
            data: payload,
            isLoading: false,
          },
        }
      }
      if (action === PLANS_FETCH_FAILED) {
        return {
          ...state,
          plans: {
            ...state.plans,
            isLoading: false,
            error,
          },
        }
      }
      // if (action === CHARGEBEE_PORTAL_REQUEST_STARTED) {
      //   return {
      //     ...state,
      //     portalUrlPending: true,
      //     portalUrl: null,
      //   }
      // }
      // if (action === CHARGEBEE_PORTAL_REQUEST_SUCCEEDED) {
      //   return {
      //     ...state,
      //     portalUrlPending: false,
      //     portalUrl: payload,
      //   }
      // }
      // if (action === CHARGEBEE_PORTAL_REQUEST_FAILED) {
      //   return {
      //     ...state,
      //     portalUrlPending: false,
      //     portalUrl: null,
      //     portalUrlError: [...state.portalUrlError, error],
      //   }
      // }
      return state
    },
    selectors: {
      selectPlans: plansSelector,
      reactMissingPlans,
      // selectPortalUrl,
      // reactMissingPortal,
    },
    actions: {
      fetchPlans,
      checkout:
        planID =>
        (dispatch, { api }) => {
          const popup = window.open(
            '',
            'Checkout',
            // eslint-disable-next-line max-len
            'scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=480,height=720,left=100,top=100'
          )
          const { document } = popup
          const container = document.createElement('DIV')
          const message = document.createElement('H2')
          const body = document.createElement('DIV')
          message.innerHTML = 'Loading... please wait'
          body.appendChild(message)
          body.style.display = 'flex'
          body.style.height = '100vh'
          body.style.width = '100vw'
          body.style.justifyContent = 'center'
          body.style.alignItems = 'center'
          container.appendChild(body)
          document.body.appendChild(container)
          // popup.document.write(container.innerHTML)
          api(`/checkout?plan_id=${planID}`, {
            method: 'GET',
          })
            .then(data => {
              popup.location.href = data.url
            })
            .catch(err => {
              message.innerHTML = 'Error loading checkout page :('
            })
        },
      openPortal:
        url =>
        (dispatch, { api }) => {
          const popup = window.open(
            '',
            'Checkout',
            // eslint-disable-next-line max-len
            'scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=480,height=720,left=100,top=100'
          )
          const { document } = popup
          const container = document.createElement('DIV')
          const message = document.createElement('H2')
          const body = document.createElement('DIV')
          message.innerHTML = 'Loading... please wait'
          body.appendChild(message)
          body.style.display = 'flex'
          body.style.height = '100vh'
          body.style.width = '100vw'
          body.style.justifyContent = 'center'
          body.style.alignItems = 'center'
          container.appendChild(body)
          document.body.appendChild(container)
          // popup.document.write(container.innerHTML)
          api('/portal')
            .then(data => {
              popup.location.href = data.url
            })
            .catch(err => {
              message.innerHTML = 'Error loading portal page :('
            })
          // popup.location.href = url
        },
    },
    init: null,
    args: null,
    // persist: [LOGIN_REQUEST_SUCCEEDED, USER_LOGGED_OUT, LOGIN_REQUEST_STARTED],
    middleware: null,
  })
