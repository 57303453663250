/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
// import { UserControlWrapper } from './UCW'
import RainflowGraph from './RainflowGraph'
import { Provider } from './RainflowChartContext'

import './style.scss'

const classes = {
  wrapper: 'RainflowChart-wrapper',
}

const RainflowChart = props => {
  const {
    // scorings: storeScorings,
    explanations: storeExplainations,
  } = useStore(
    // 'selectScorings',
    'selectExplanations'
  )
  const {
    controlable,
    scoredDataset: propScoredDataset,
    objective: propObjective,
    brain: propBrain,
    row: propRow,
    explanation: propExplaination,
    ...rest
  } = props

  let scoredDataset = propScoredDataset?.id || null
  let objective = propObjective || null
  let brain = propBrain || null
  let row = propRow || propRow === 0 ? propRow : null

  if (propExplaination) {
    if (!scoredDataset) scoredDataset = propExplaination.scored_dataset_id || null
    if (propExplaination.scored_dataset_id === scoredDataset) {
      if (!objective) objective = propExplaination.objective || null
      if (propExplaination.objective === objective) {
        if (!brain) brain = propExplaination.brain_id || null
        if (propExplaination.brain_id === brain) {
          if (!(row || row === 0))
            row =
              propExplaination.row || propExplaination.row === 0
                ? propExplaination.row
                : null
        }
      }
    }
  }
  const storeData = {
    // explanations:
    //   (scoredDataset &&
    //     storeExplainations.find(e => e.id === scoredDataset)?.data) ||
    //   [],
    explanations: storeExplainations,
  }

  const data = useMemo(() => {
    const tempExplaination =
      storeData.explanations
        // .find(resource => scoredDataset && resource.key === `chart-${scoredDataset}`)
        .find(resource => scoredDataset && resource.id === scoredDataset)
        ?.data?.find(
          ({
            objective: explanationObjective,
            brain_id: explanationBrainId,
            row: explanationRow,
          }) =>
            explanationObjective === objective &&
            explanationBrainId === brain &&
            explanationRow === row
        ) || null
    const tempData = tempExplaination?.data
    let startingValue = null
    let tempResult = {}
    if (tempData) {
      if (tempData.type === 'regression') {
        tempResult = tempData.weights || {}
        startingValue = tempData.starting_values || null
      } else {
        const { output_value: result } = tempData
        tempResult = tempData.weights[result] || {}
        startingValue = tempData.starting_values[result] || null
      }
    }
    const features = Object.keys(tempResult)
    const result = {
      start: startingValue,
      bars: features
        .map(key => ({ feature: key, value: tempResult[key] }))
        .filter(({ value }) => !!(value || value === 0)),
    }
    return result
  }, [storeData.explanations, scoredDataset, objective, brain, row])

  const contextData = { data }

  return (
    <div className={classes.wrapper}>
      <Provider value={contextData}>
        {/* <Conditional dependencies={controlable}>
          <UserControlWrapper
            controlable={controlable}
            trainings={trainings}
            brains={brains}
            features={features}
            split={split}
            datasets={datasets}
            stackingCategories={stackingCategories}
            combineDatasets={combineDatasets}
            x={x}
            y={y}
            type={type}
            color={color}
          >
            <RainflowGraph
              legend={legend && { position: legendPosition, width: legendWidth }}
              {...rest}
            />
          </UserControlWrapper>
        </Conditional> */}
        <Conditional dependencies={!controlable}>
          <RainflowGraph {...rest} />
        </Conditional>
      </Provider>
    </div>
  )
}

RainflowChart.propTypes = {
  controlable: PropTypes.shape({
    scoring: PropTypes.bool,
    scoredDataset: PropTypes.bool,
    objective: PropTypes.bool,
    brain: PropTypes.bool,
    row: PropTypes.bool,
    prefKey: PropTypes.string,
  }),
  xAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['top', 'bottom']),
      height: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
    }),
    PropTypes.bool,
  ]),
  yAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
    }),
    PropTypes.bool,
  ]),
  grid: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  scoredDataset: PropTypes.shape({ id: PropTypes.string }),
  objective: PropTypes.string,
  brain: PropTypes.string,
  row: PropTypes.number,
  explanation: PropTypes.shape({
    scored_dataset_id: PropTypes.string,
    objective: PropTypes.string,
    brain_id: PropTypes.string,
    row: PropTypes.number,
  }),
  color: PropTypes.string,
}

export default RainflowChart
