import React from 'react'
import PropTypes from 'prop-types'

import { Button, MenuItem, TextField } from '@material-ui/core'

const classes = {
  wrapper: 'JoinBox-config-content-wrapper',
  joinSVG: 'JoinBox-config-content-joinSVG',
  line: 'JoinBox-config-content-line',
  curve: 'JoinBox-config-content-curve',
  joinPairDiv: 'JoinBox-config-content-joinPairDiv',
  removePairButton: 'JoinBox-config-content-removePairButton',
}

const JoinConfig = ({ config, metaLeft, metaRight, callback }) => {
  return (
    <div className={classes.wrapper}>
      {/* <svg
        className={classes.joinSVG}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <line className={classes.line} x1="25" y1="15" x2="25" y2="40" />
        <line className={classes.line} x1="75" y1="15" x2="75" y2="40" />
        <path className={classes.curve} d="M 25 40 C 25 60, 50 40, 50 60" />
        <path className={classes.curve} d="M 75 40 C 75 60, 50 40, 50 60" />
        <line className={classes.line} x1="50" y1="60" x2="50" y2="85" />
      </svg> */}
      {config.left?.map((e, i) => (
        <div className={classes.joinPairDiv}>
          <TextField
            select
            value={e ?? ''}
            onChange={event => {
              const tempLeft = [...config.left]
              tempLeft[i] = event.target.value
              callback({ ...config, left: [...tempLeft] })
            }}
            margin="dense"
            fullWidth
          >
            {metaLeft.map(({ id, name }) => (
              <MenuItem value={id}>{name}</MenuItem>
            ))}
          </TextField>
          <TextField
            select
            value={config.right[i] ?? ''}
            onChange={event => {
              const tempRight = [...config.right]
              tempRight[i] = event.target.value
              callback({ ...config, right: [...tempRight] })
            }}
            margin="dense"
            fullWidth
          >
            {metaRight.map(({ id, name }) => (
              <MenuItem value={id}>{name}</MenuItem>
            ))}
          </TextField>
          <Button
            color="primary"
            variant="contained"
            classes={{ root: classes.removePairButton }}
            onClick={() => {
              const tempLeft = [...config.left]
              const tempRight = [...config.right]
              tempLeft.splice(i, 1)
              tempRight.splice(i, 1)
              callback({ ...config, left: tempLeft, right: tempRight })
            }}
          >
            X
          </Button>
        </div>
      ))}
      <Button
        color="primary"
        variant="contained"
        className={classes.joinPairDiv}
        onClick={() => {
          callback({
            ...config,
            left: [...config.left, 0],
            right: [...config.right, 0],
          })
        }}
      >
        Add Join Pair
      </Button>
      <div>
        <TextField
          select
          value={config.method || ''}
          onChange={event => callback({ ...config, method: event.target.value })}
        >
          <MenuItem value="left">Left</MenuItem>
          <MenuItem value="inner">Inner</MenuItem>
          <MenuItem value="right">Right</MenuItem>
        </TextField>
      </div>
    </div>
  )
}

JoinConfig.propTypes = {
  config: PropTypes.object,
  metaLeft: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  metaRight: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  callback: PropTypes.func,
}

export default JoinConfig
