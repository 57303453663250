/* eslint-disable max-len */
import React, { useCallback, useMemo, useState } from 'react'
import { useRefs, useRefRect } from 'libs/utils/hooks'

import { useMouseTracker } from 'components/MouseTracker'

import './style.scss'

// const classes = {
//   wrapper: 'Logo-wrapper',
// }

const paths = (
  <g className="Main-logo-lines">
    <path
      d="M662.9,653.3c22-7.7,41.6-21.2,56.5-39.2c22.5-25.6,31.8-60.2,25-93.6c-1.3-5-2.7-11.1-4.6-17.7c-1.7-5.6-3.7-11.1-6.2-16.4
		c-6.4,8.9-15.3,15.7-25.6,19.5c4.4,10.2,6.6,21.2,6.4,32.4c-0.5,16.9-5.6,33.3-14.5,47.6c-18.4,31.2-55.2,46.4-90.3,37.4
		c-7.3-1.8-14.3-4.2-21.5-6.2c-2.5-0.7-5-1.3-7.5-1.6c-7-0.7-13.6,3.2-16.3,9.7c-2.6,5.9-0.8,12.9,4.4,16.8c2.8,2.5,6.1,4.5,9.6,5.9
		c8.4,3.2,16.9,5.9,25.6,8C623.6,660.7,644,659.8,662.9,653.3z"
    />
    <path
      d="M1598.2,423.5c-15.6-77.2-70.6-140.5-144.9-166.8c-5.2-1.8-10.5-3.4-15.8-5c-5.2-1.3-9.1-5.5-10.1-10.8
		c0-1.3-0.5-2.7-0.9-4.1c-16.3-84.2-97.8-139.3-182-123c-3.1,0.6-6.2,1.3-9.2,2.1c-5.9,2-12.3-0.3-15.7-5.6
		c-7.1-9.4-14.7-18.4-22.7-27C1096.2-23.4,927.8-28.3,821,72.5c-3,2.9-6,5.8-8.9,8.8c-4.5,5.2-11.5,7.4-18.2,5.8
		c-34.3-6.2-69.6-5.3-103.6,2.8c-101.7,24.7-177.8,109.4-191.4,213.2c-1,8.3-2.5,9.7-10.4,11.9c-9,2.2-17.8,5-26.5,8.2
		c-77.1,30-115.6,116.4-86.6,193.8l17.7,37.4c-1.4,1.1-4.2,3.2-5.4,4.3c-14.3,13.9-26.9,29.4-37.7,46.2
		c-22,33.8-33.4,73.3-32.9,113.5c0.4,24.8,4.7,49.3,12.7,72.8c17.6,55.5,57.9,100.9,110.8,125c18.8,8.8,38.4,15.8,58.5,21
		c24.4,6,49.9,7.1,74.7,3.2c32.1-4.4,62.4-17.2,88-37.1c11.4-9,22-19,31.7-29.9C740.7,822.6,787.9,771.3,835,720
		c5.3-6.3,13.1-9.8,21.3-9.5c42.7,0,85.4,0,128.2,0c-1.4-5-2.1-10.2-2.1-15.4c0-5.4,0.8-10.8,2.3-16c-48.3,0-96.6,0-144.9,0
		c-6.7-0.3-13.3,2.5-17.7,7.6c-5,5.6-10.2,11-15.2,16.4c-45.3,48.9-90.2,98.2-134.8,147.8c-41.3,49-106.5,70.8-169,56.4
		c-103.1-20-170.5-119.9-150.5-223c8.8-45.1,33.6-85.6,69.9-113.8c16.3-12.8,34.6-22.7,54.1-29.4c4.4-1.2,8.4-3.5,11.6-6.6
		c4.2-4.2,5.2-10.6,2.7-15.9c-1.8-5.8-7.2-9.8-13.4-9.7c-3.4,0-6.8,0.7-10,2c-13.4,5.7-26.5,11.8-39.8,17.7
		c-3.3,1.5-6.3,3.5-9.5,5.4c-0.7-0.7-1.3-1.4-1.9-2.1c-1.9-3-3.9-5.8-5.5-8.8c-18.3-34.2-19-75.2-1.9-110
		c23.6-49.8,77.6-77.7,131.9-68.2c18.1,2.9,35.1,10.4,49.3,21.8c1.4,1.1,5.8,5.3,7.2,6.5c-1.5,2.2-8,10.9-8.8,12.8
		c-4.7,7.2-8.7,14.8-12,22.7c-9.7,22.3-12.2,47-7.3,70.7c5.9,25.3,19.7,48.1,39.4,65.1c1.2,1,2.4,2,3.8,2.7
		c6.4,3.7,14.5,2.4,19.5-3.1c4.8-4.9,5.5-12.5,1.8-18.2c-1.5-2.1-3.1-4.1-4.9-6c-2.8-3.1-5.8-6-8.8-8.8
		c-19.5-21.6-26.4-51.7-18.3-79.6c11.9-47.8,60.3-76.9,108.1-65.1c0.4,0.1,0.8,0.2,1.3,0.3c16.7,4.6,31.8,13.8,43.6,26.5
		c2.4,2.7,5.1,5.2,8,7.5c6.5,4.9,15.8,3.6,20.7-2.9c0,0,0.1-0.1,0.1-0.1c5.4-6.5,4.7-16.1-1.5-21.8
		c-37.9-45.7-103.1-57.5-154.7-28.1l-5,2.7c-12.8-11.3-27.3-20.5-43-27.2c-15.9-6.4-32.6-10.6-49.6-12.2c1.1-6.5,2-12.7,3.4-18.7
		c14-73.7,65.1-134.9,135.1-161.7c33.1-13.8,69.2-18.8,104.8-14.4c9.8,1.1,19.5,3.1,29.2,4.8c7.6,1.5,15.5-0.6,21.4-5.7
		c2.4-2,4.6-4.2,6.7-6.5c55.3-61.3,138.9-88.8,219.8-72.4c55.7,10.6,105.6,41.4,139.9,86.6c1.9,2.4,3.6,5,5.6,7.7l-4.3,3.4
		c-5.8,4.4-11.8,8.8-17.7,13.1c-3.1,2.6-5.8,5.5-8,8.8c-3.4,5.1-3,11.9,1.1,16.4c4,6.3,12.3,8.3,18.7,4.6c3.7-2,7.1-4.3,10.3-6.9
		c27.2-22.2,62.7-31.5,97.3-25.6c51.7,8.8,85,39.2,100.7,89c1,2.9,1.5,6,2.3,9.4l-5,0.4c-10.5,0.6-21.1,1.1-31.7,1.9
		c-36.9,2.3-72.6,14.6-103.2,35.4l-5.7,3.8c-4.4,3-10,3.4-14.9,1.1l-4.2-1.9c-30.7-15.3-66.2-18.1-99-7.8l-16,5.7
		c8.8,7.2,15.2,16.8,18.6,27.6l14.6-5c12.7-2.9,25.8-3.3,38.6-1.1c57.9,8.8,97.8,62.9,89,120.8c-1.2,8.1-3.4,16.1-6.5,23.8
		c-1.2,2.9-2.3,5.8-3.1,8.8c-0.6,2-0.6,4.1,0,6c1.9,4.9,5.4,9,9.9,11.7c4.9,3.2,11.3,2.3,15.2-1.9c2.1-2.1,3.7-4.6,4.9-7.3
		c12.2-27,15.3-57.3,8.8-86.2c-5.3-23.8-16.6-45.8-33-63.9l-4.1-4.9c5.3-3.4,10-6.8,14.9-9.6c92.1-51.8,208.8-19.2,260.6,72.9
		c21,37.4,28.9,80.8,22.3,123.2c-11.2,67.3-49.4,115.5-111.2,144.4c-22.4,10.5-46.8,15.7-71.5,15.1c-21-0.4-42.2,0-63.3,0h-9.9
		c-2.7,0.3-5.2-1.6-5.7-4.2c-1.3-4.2-2.7-8.8-4.4-12.7c-11.9-33.1-35.1-60.9-65.6-78.4c-23.6-13.8-50.4-21.1-77.7-21.2
		c-63.7,0-127.5,0-191.2,0c-5,0-10-0.4-14.9-1.3c-27.9-5.5-48.4-29.4-49.3-57.8c-1.2-29.1,18.1-55.1,46.3-62.4
		c7.3-1.4,14.7-2.2,22.1-2.2c13.9-1.1,27.8,2.2,39.8,9.4c3,1.8,5.8,3.7,8.8,5.5c33,21.3,74.4,24.7,110.4,8.8
		c4.9-1.9,9-5.5,11.6-10.1c4-6.2,2.2-14.6-4-18.6c-1.1-0.7-2.2-1.2-3.5-1.6c-2.6-0.9-5.4-1.1-8.1-0.7c-3.6,0.6-7.1,1.5-10.5,2.8
		c-37,13.3-78.3,2.2-103.6-27.9c-20.3-23.6-27.4-55.8-18.8-85.8c1.2-4.1,1.9-8.4,1.9-12.7c0-6.5-4.6-12.1-11-13.4
		c-6.3-2.3-13.3,0.1-16.9,5.7c-1.4,2-2.4,4.2-3.2,6.5c-3.4,10-5.5,20.4-6.2,31c-1.4,21.3,2.6,42.6,11.7,61.9
		c0.8,1.9,1.5,3.8,2.6,6.4c-1.9,0-2.8,0.7-3.8,0.8c-7.1,0.8-14.2,1.2-21.1,2.5c-50.8,10-83.9,59.3-73.9,110.1
		c8.6,43.8,46.9,75.4,91.5,75.6h50.9h142.8c23.6-0.4,46.7,6.6,66.1,20.1c38.5,24.8,59.5,69.5,54,115c-1.9,28.3-14.3,54.9-34.7,74.6
		c-23.6,23.6-55.7,36.7-89.1,36.3c-18.3,0-36.7,0-55.1,0h-72.2c-3.2-0.2-6.3,0.8-8.8,2.8c-1.5,1.4-2.9,2.9-4.3,4.4l-210.2,227.4
		c-1.1,1.1-2.1,2.3-3.1,3.5c-5,6-4.5,14.9,1.3,20.2c5.8,5.5,14.9,5.5,20.7,0c2-1.9,3.8-4,5.7-6l199.6-215.9
		c2.5-3.1,6.3-4.8,10.3-4.6c39.8,0,79.6,0,119.5,0c29.1,0.1,57.6-8.3,82-24.3c40.7-25.7,66.9-69,70.7-117c0.6-5.9,1.3-11.9,2.1-18.4
		h6.5c30.9,0,61.9,0,92.5-0.7c3.4,0,6.9-0.4,10.3-1.1c39.8-8,76.6-27,106.1-54.9C1586.5,580.1,1613,500.7,1598.2,423.5z"
    />
    <path
      d="M929.7,123.4c27.3-14.4,59.3-16.9,88.4-6.8c9.6,3.3,19.2,6.9,28.8,10.3c2.9,1.1,5.8,1.8,8.8,2.3c6.5,1.1,13-2.2,15.9-8.2
		c3.1-5.9,1.9-13.2-3.1-17.7c-2-1.9-4.3-3.5-6.7-4.8c-34.3-19.1-74.6-24.4-112.7-14.9c-49.6,11.1-90.8,45.5-110.5,92.3l-4.4,10
		c-9.8-4.1-18.9-8.3-28.4-11.7c-22.6-8.4-47-10.7-70.7-6.8c-21.2,4.4-41.8,11.5-61.1,21.2c-7.5,3.1-11,11.6-8,19
		c0.1,0.2,0.2,0.4,0.3,0.6c2.9,7.6,11.3,11.5,19,8.8c3.3-1.1,6.4-2.5,9.5-4.2c17.9-9.6,37.8-15,58.1-15.6
		c20.5-0.3,40.8,4.4,59.1,13.6c13,6.3,13.6,7.8,14.9,22.1c1.2,14.3,2.8,28.7,4.5,42.9c0.3,3.6,1.1,7.2,2.2,10.6
		c2.1,6.4,8.5,10.3,15.1,9.5c6.7,0.3,12.6-4.4,13.8-11c0.7-3.9,0.9-7.9,0.4-11.8c-0.7-7.6-2.1-15-2.9-22.6
		c-2.3-15.6-1.6-31.5,2.2-46.9C872,168.3,896.6,139,929.7,123.4z"
    />
    <path
      d="M452.9,686.4c1.2-2.3,2.1-4.7,2.9-7.2c1.9-6.8-0.9-14-6.8-17.7c-5.4-3.7-12.6-3.1-17.2,1.4c-3,2.5-5.6,5.5-7.5,8.8
		c-4.6,7.7-8.8,15.7-12.4,24c-7.9,18.4-10.4,38.7-7.2,58.5c3.7,23,13.6,44.6,28.7,62.4c21.3,26.6,53.8,41.8,87.9,40.9
		c5.1-0.4,11.5-0.8,17.7-1.5c21.5-2.2,41.9-10.5,58.8-23.9c1.9-1.5,3.6-3.2,5.1-5.1c5-6.9,3.5-16.5-3.3-21.5
		c-1.9-1.4-4.1-2.3-6.4-2.7c-5.4-1-10.9,0.6-14.9,4.2c-6.6,5-13.9,9.2-21.6,12.3c-15.1,6.7-31.9,8.6-48.2,5.6
		c-16.5-3.5-31.7-11.3-44.2-22.5c-27.5-23.6-36.1-62.5-21.1-95.5C446.1,699.7,449.6,693.1,452.9,686.4z"
    />
    <path
      d="M963.9,809c3.2-3,5-7.1,5.1-11.5c0-8.4-6.7-15.1-15.1-15.2c-0.4,0-0.8,0-1.2,0c-4.8,0.7-9.2,3.2-12.2,7.1l-210.4,228.4
		c-1.1,1.1-2.2,2.3-3.1,3.4c-5.5,6.3-4.7,15.9,1.6,21.3c6,5.2,14.9,4.8,20.5-0.7c1.4-1.3,2.7-2.7,4-4.2l168-182L963.9,809z"
    />
    <path
      d="M1431.9,554.4c-3.8,1.3-7.5,2.8-11.1,4.6c-5.7,2.6-8.9,8.7-8,14.9c0.5,8.5,7.7,15,16.2,14.5c1.3-0.1,2.6-0.3,3.9-0.7
		c19.4-4.8,37.2-14.7,51.6-28.7c25.9-24.4,40.1-58.7,39-94.3c0.1-17.8-3.4-35.5-10.4-51.9c-15.3-32-43.8-55.8-78-65.4
		c-3.1-0.9-6.4-1.3-9.6-1.1c-6.4-0.1-11.9,4.3-13.3,10.5c-2.3,5.7-0.3,12.2,4.8,15.7c3.5,2.3,7.1,4.3,11,5.9
		c5.7,2.7,11.8,4.9,17.7,7.9c19.9,10,35,27.5,41.9,48.7c2.5,7.9,4.2,16.1,5,24.3c3.7,28.3-6.9,56.6-28.3,75.4
		C1454.8,543.5,1443.8,550.1,1431.9,554.4z"
    />
    <path
      d="M807.8,863.2c4.6-6.9,2.8-16.2-4-20.9c-1.7-1.1-3.5-1.9-5.5-2.3c-5.9-1.1-11.9,1.2-15.6,5.9L715,919.1
		c-10.3,11.1-20.4,22.1-30.7,33.1c-3,2.8-4.9,6.7-5,10.9c-0.4,8.3,6.1,15.4,14.4,15.8c0.7,0,1.5,0,2.2-0.1c4.5-0.6,8.6-3,11.4-6.5
		c32.4-35.4,64.8-70.4,97.3-105.1C805.8,865.9,806.8,864.6,807.8,863.2z"
    />
    <path
      d="M836.3,825.9c4.8-0.1,9.2-2.3,12.2-6c16.4-17.7,32.8-35.4,49.3-53.1c0.9-1,1.8-2.1,2.6-3.3c4.6-6.9,2.6-16.3-4.3-20.8
		c-2.2-1.4-4.7-2.3-7.4-2.4c-5.1-0.3-10.1,1.9-13.3,5.9c-16.4,17.7-32.8,35.4-49.3,53.1c-0.8,0.9-1.6,1.8-2.3,2.8
		c-4.8,6.8-3.1,16.3,3.7,21.1C830.1,825,833.1,825.9,836.3,825.9L836.3,825.9z"
    />
  </g>
)
const points = [
  { x: 524.4, y: 736.6, id: 'LogoCirclePoint0' },
  { x: 687.6, y: 453.1, id: 'LogoCirclePoint1' },
  { x: 1079.9, y: 326.1, id: 'LogoCirclePoint2' },
  { x: 1402.4, y: 466.2, id: 'LogoCirclePoint3' },
  { x: 1039.1, y: 695.1, id: 'LogoCirclePoint4' },
]

const maskRadii = { min: 50, max: 1000 }
const animationDuration = 3
const maskAnimationRadii = { min: 50, max: 700 }
const radius = 57.1
const ringMaxRadius = 250
const animationOpacity = { start: 0.08, end: 0 }

const circles = (
  <g className="Main-logo-circles">
    {points.map(({ x, y, id }) => (
      <circle key={`Main-logo-circle-${id}`} cx={x} cy={y} r={radius} />
    ))}
  </g>
)

const top = 0.1
const right = 0.15
const bottom = 0.2
const left = 0.15
const xRange = 1920 / (1 - (left + right))
const yRange = 1080 / (1 - (top + bottom))
const ratio = xRange / yRange
const proximityPx = 150

const Logo = props => {
  const [proximity, setProximity] = useState([
    { id: points[0].id, proximity: false },
    { id: points[1].id, proximity: false },
    { id: points[2].id, proximity: false },
    { id: points[3].id, proximity: false },
    { id: points[4].id, proximity: false },
  ])
  const [getRef, setRef] = useRefs()
  const [rect, rectRef] = useRefRect()

  let { top: absTop = 0, left: absLeft = 0 } = { ...rect }
  const { width = 1, height = 1 } = { ...rect }
  absTop += (rect || 0) && rectRef.current.getBoundingClientRect().top
  absLeft += (rect || 0) && rectRef.current.getBoundingClientRect().left

  const [ratios, actualViewBox] = useMemo(() => {
    const actualRatio = width / height
    const keepY = actualRatio > ratio
    const tempXRange = keepY ? yRange * actualRatio : xRange
    const tempYRange = keepY ? yRange : xRange / actualRatio
    return [
      { x: width / tempXRange, y: height / tempYRange },
      { x: tempXRange, y: tempYRange },
    ]
  }, [width, height])

  const updateMouseData = useCallback(
    hookMouseData => {
      const xRatio = (width && 1 / width) || 0
      const yRatio = (height && 1 / height) || 0
      const x = (hookMouseData.x - absLeft) * xRatio
      const y = (hookMouseData.y - absTop) * yRatio
      const xDifference = (actualViewBox.x - xRange) / 2
      const yDifference = (actualViewBox.y - yRange) / 2
      const { proximity: tempProximity, change } = proximity.reduce(
        (acc, cur, i) => {
          const tempResult = [...acc.proximity]
          const distanceX =
            Math.abs(
              x * actualViewBox.x - (points[i].x + xRange * left + xDifference)
            ) * ratios.x
          const distanceY =
            Math.abs(
              y * actualViewBox.y - (points[i].y + yRange * top + yDifference)
            ) * ratios.y
          const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2)
          const pointProximity = distance < proximityPx
          tempResult.push({ id: cur.id, proximity: pointProximity })
          if (pointProximity && !cur.proximity)
            getRef(cur.id)?.current?.beginElement()
          return {
            proximity: [...tempResult],
            change: acc.change || cur.proximity !== pointProximity,
          }
        },
        {
          proximity: [],
          change: false,
        }
      )
      if (change) setProximity(tempProximity)
    },
    [getRef, proximity, ratios, actualViewBox, absLeft, absTop, width, height]
  )
  useMouseTracker(hookMouseData => updateMouseData(hookMouseData))

  const neuronPoints = (
    <g className="Main-logo-rings">
      {points.map(({ x, y, id }) => {
        const ref = setRef(id)
        return (
          <g id={id} key={id}>
            <mask id={`${id}-mask`}>
              <circle cx={x} cy={y} r={maskRadii.max} fill="white" />
              <circle cx={x} cy={y} r={maskRadii.min} fill="black">
                <animate
                  ref={ref}
                  attributeName="r"
                  dur={`${animationDuration}s`}
                  from={maskAnimationRadii.min}
                  to={maskAnimationRadii.max}
                  calcMode="linear"
                  begin="never"
                  // repeatCount="indefinite"
                  fill="freeze"
                  restart="whenNotActive"
                  id={`${id}maskAnimator`}
                />
              </circle>
            </mask>
            <circle cx={x} cy={y} r={radius} mask={`url(#${id}-mask)`}>
              <animate
                attributeName="r"
                dur={`${animationDuration}s`}
                from={radius}
                to={ringMaxRadius}
                calcMode="spline"
                keyTimes="0; 1"
                keySplines="0 .6 .25 1"
                begin={`${id}maskAnimator.begin`}
                fill="freeze"
                restart="whenNotActive"
              />
              <animate
                attributeName="opacity"
                dur={`${animationDuration}s`}
                from={animationOpacity.start}
                to={animationOpacity.end}
                calcMode="spline"
                keyTimes="0; 1"
                keySplines="0 .6 .25 1"
                begin={`${id}maskAnimator.begin`}
                fill="freeze"
                restart="whenNotActive"
              />
            </circle>
          </g>
        )
      })}
    </g>
  )

  return (
    <svg
      ref={rectRef}
      className="Main-logo"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`${-xRange * left} ${-yRange * top} ${xRange} ${yRange}`}
      preserveAspectRatio="xMidYMid meet"
    >
      {neuronPoints}
      {paths}
      {circles}
    </svg>
  )
}

export default Logo
