/* eslint-disable max-lines */
/* eslint-disable no-shadow */
import { createBundle } from 'core/bundler'
import { createSelector } from 'create-selector'

import invariant from 'tiny-invariant'
import { v4 as uuid } from 'uuid'

import {
  USER_LOGGED_OUT,
  PIPELINE_PROCESSING_FINISHED,
  PIPELINE_PROCESSING_FAILED,
  PIPELINE_ELEMENT_FINISHED,
  PIPELINE_ELEMENT_FAILED,
  ACTIVE_TRAINING_CHANGE_REQUESTED,
  ACTIVE_TRAINING_CHANGE_ACCEPTED,
  ACTIVE_TRAINING_CHANGE_REJECTED,
  ACTIVE_TRAINING_DISCARDED,
  TRAINING_WIZARD_ACTIVATED,
  TRAINING_WIZARD_DEACTIVATED,
  TRAINING_WIZARD_NEXT_STEP,
  TRAINING_WIZARD_PREVIOUS_STEP,
  TRAINING_WIZARD_DATASETS_UPDATED,
  TRAINING_WIZARD_PIPELINE_UPDATED,
  TRAINING_WIZARD_SPEC_UPDATED,
  TRAINING_WIZARD_NAME_UPDATED,
  TRAINING_WIZARD_PIPELINE_COMMITED,
  TRAINING_START_REQUEST_STARTED,
  TRAINING_START_REQUEST_SUCCEEDED,
  TRAINING_START_REQUEST_FAILED,
} from 'core/actiontypes'

const mergeObjectiveMetrics = objectives => {
  return objectives.reduce((acc, { id, metric }) => {
    const existingObjective = acc.find(objective => objective.id === Number(id))
    const existingMetric = existingObjective?.metric.find(e => e === metric)
    if (existingObjective) {
      if (!existingMetric) existingObjective.metric.push(metric)
    } else acc.push({ id: Number(id), metric: [metric] })
    return acc
  }, [])
}

const expandObjectiveMetrics = objectives =>
  objectives.reduce((acc, { id = '', metric = '' }) => {
    if (metric.constructor === Array) {
      metric.forEach((m = '') => acc.push({ id, metric: m }))
    } else {
      acc.push({ id, metric })
    }
    return acc
  }, [])

const getBoxCount = branch => {
  if (!branch || !branch.length) return 0
  return branch.reduce((acc, cur) => {
    if (Array.isArray(cur)) {
      return acc + getBoxCount(cur)
    }
    return acc + 1
  }, 0)
}

const NAME = 'training-wizard'

const initialState = {
  id: null,
  title: null,
  pending: null,
  open: false,
  editMode: true,
  step: 0,
  datasets: [null],
  pipeline: [],
  pipeline_id: null,
  pipeline_processing_id: null,
  pipeline_processing_error: null,
  pipeline_processing_progress: 0,
  pipeline_operations_count: 0,
  pipeline_output: null,
  pipeline_output_hash: null,
  pipeline_output_error: null,
  spec: {
    data_split_train: 0.7,
    data_split_validate: 0.2,
    data_split_test: 0.1,
    max_no_of_brains: 100,
    time_to_run_seconds: 60 * 60 * 3,
    optimize_inputs: true,
    reduce_dimensionality: false,
    metadata: {
      lookback: 6,
      prediction_horizon: 6,
      ignore_stacking: false,
    },
    objectives: [],
    // no_cpus: 2,
  },
  starting: false,
}

export default config => {
  const rawSelector = state => state[NAME]
  const selectTrainingWizardStep = state => state[NAME].step
  const selectTrainingWizardEditable = state => state[NAME].editMode
  const selectTrainingWizardPipelineProcessing = state =>
    !!state[NAME].pipeline_processing_id
  const selectTrainingWizardIsConnected = createSelector(
    rawSelector,
    'selectActivePipeline',
    (state, manager) => {
      if (!state.id || !manager.id) return false
      return state.id === manager.id
    }
  )

  const initializeTrainingWizard = (
    id,
    spec,
    pipeline,
    datasets,
    title,
    editMode = true,
    open = true,
    step = 0,
    pipelineID
  ) => {
    const adjustedSpec = spec
      ? {
          ...spec,
          objectives: expandObjectiveMetrics(spec.objectives || []),
          optimize_inputs: !!spec.optimize_inputs,
          reduce_dimensionality: !!spec.reduce_dimensionality,
          metadata: {
            prediction_horizon: Number(spec.metadata?.prediction_horizon),
            lookback: Number(spec.metadata?.lookback),
            ignore_stacking: !!spec.metadata?.ignore_stacking,
          },
        }
      : null
    return {
      type: ACTIVE_TRAINING_CHANGE_REQUESTED,
      payload: {
        ...initialState,
        id: id || uuid(),
        title,
        open,
        editMode,
        step,
        datasets: datasets || [null],
        pipeline: pipeline || [],
        pipeline_id: pipelineID || null,
        spec: {
          ...initialState.spec,
          objectives: [],
          ...adjustedSpec,
        },
      },
    }
  }
  const startTrainingFromResource =
    (resourceID, resourceType, open, title) =>
    (dispatch, { store }) => {
      if (resourceType === 'dataset') {
        // eslint-disable-next-line prettier/prettier
        dispatch(
          initializeTrainingWizard(
            null,
            null,
            [],
            [resourceID],
            title,
            true,
            open,
            0
          )
        )
      }
      if (resourceType === 'transformation') {
        const transformations = store.selectTransformations()
        if (!transformations) return

        const transformation = transformations.find(tf => tf.id === resourceID)
        if (!transformation) return

        const { name, spec: pipeline, datasets } = transformation
        // eslint-disable-next-line prettier/prettier
        dispatch(
          initializeTrainingWizard(
            null,
            null,
            pipeline,
            datasets,
            title,
            true,
            open,
            1,
            name
          )
        )
      }
      if (resourceType === 'training') {
        const trainings = store.selectTrainings()
        if (!trainings) return

        const training = trainings.find(t => t.id === resourceID)
        if (!training) return

        const { pipeline, spec, metadata } = training
        const datasets = metadata.map(ds => ds.trained_on_id || null)
        // eslint-disable-next-line prettier/prettier
        dispatch(
          initializeTrainingWizard(
            null,
            spec,
            pipeline,
            datasets,
            title,
            true,
            open,
            2,
            null
          )
        )
      }
    }
  const openTrainingWizard = () => ({ type: TRAINING_WIZARD_ACTIVATED })
  const closeTrainingWizard = () => ({ type: TRAINING_WIZARD_DEACTIVATED })
  const acceptTrainingChange = () => ({ type: ACTIVE_TRAINING_CHANGE_ACCEPTED })
  const rejectTrainingChange = () => ({ type: ACTIVE_TRAINING_CHANGE_REJECTED })
  const discardActiveTraining = () => ({ type: ACTIVE_TRAINING_DISCARDED })

  const trainingWizardNextStep = () => ({ type: TRAINING_WIZARD_NEXT_STEP })
  const trainingWizardPrevStep = () => ({ type: TRAINING_WIZARD_PREVIOUS_STEP })
  const setTrainingWizardDataset = (datasetID, index = 0) => ({
    type: TRAINING_WIZARD_DATASETS_UPDATED,
    payload: {
      id: datasetID,
      index,
    },
  })
  const setTrainingWizardPipeline = (pipeline, datasets, id) => {
    invariant(Array.isArray(pipeline), 'Pipeline must be an array')
    invariant(Array.isArray(datasets), 'Datasets must be an array')
    return {
      type: TRAINING_WIZARD_PIPELINE_UPDATED,
      payload: {
        pipeline,
        datasets,
        id,
      },
    }
  }
  const setTrainingWizardSpec = spec => ({
    type: TRAINING_WIZARD_SPEC_UPDATED,
    payload: spec,
  })
  const setTrainingWizardName = title => ({
    type: TRAINING_WIZARD_NAME_UPDATED,
    payload: title,
  })

  const trainingWizardStartTraining =
    () =>
    (dispatch, { api, store }) => {
      const activeTraining = store.selectActiveTraining()
      if (!activeTraining) return
      const { datasets, pipeline, spec, title: name } = activeTraining
      dispatch({ type: TRAINING_START_REQUEST_STARTED })
      const mergedObjectives = mergeObjectiveMetrics(spec.objectives)
      const adjustedSpec = {
        ...spec,
        objectives: mergedObjectives,
        optimize_inputs: !!spec.optimize_inputs,
        reduce_dimensionality: !!spec.reduce_dimensionality,
        metadata: {
          prediction_horizon: Number(spec.metadata.prediction_horizon),
          lookback: Number(spec.metadata.lookback),
          ignore_stacking: !!spec.metadata.ignore_stacking,
        },
        ...(mergedObjectives.some(e => e.metric.some(e => e === 'sortino'))
          ? {
              freqtrade: {
                symbol_column: 'symbol',
              },
            }
          : null),
      }
      api('/trainings', {
        method: 'POST',
        body: {
          name: name || String(new Date()),
          description: '',
          pipeline,
          datasets,
          metadata: [],
          spec: adjustedSpec,
        },
      })
        .then(response => {
          dispatch({ type: TRAINING_START_REQUEST_SUCCEEDED })
          store.markTrainingsAsOutdated()
        })
        .catch(error => dispatch({ type: TRAINING_START_REQUEST_FAILED }))
    }

  return createBundle({
    name: NAME,
    reducer: (state = initialState, { type, payload, error }) => {
      if (type === TRAINING_WIZARD_ACTIVATED) {
        return { ...state, open: true }
      }
      if (type === TRAINING_WIZARD_DEACTIVATED) {
        return { ...state, open: false }
      }
      if (type === ACTIVE_TRAINING_CHANGE_REQUESTED) {
        const { id } = payload
        if (id === state.id) {
          return {
            ...state,
            open: true,
          }
        }
        return {
          ...state,
          pending: payload,
        }
      }
      if (type === ACTIVE_TRAINING_CHANGE_REJECTED) {
        return {
          ...state,
          pending: null,
        }
      }
      if (type === ACTIVE_TRAINING_CHANGE_ACCEPTED) {
        return {
          ...state.pending,
          pending: null,
        }
      }
      if (type === ACTIVE_TRAINING_DISCARDED) {
        return { ...initialState, pending: state.pending }
      }
      if (type === USER_LOGGED_OUT) {
        return initialState
      }
      if (type === TRAINING_WIZARD_NEXT_STEP) {
        return { ...state, step: Math.min(state.step + 1, 2) }
      }
      if (type === TRAINING_WIZARD_PREVIOUS_STEP) {
        return { ...state, step: Math.max(state.step - 1, 0) }
      }
      if (type === TRAINING_WIZARD_DATASETS_UPDATED) {
        const { id, index } = payload
        return {
          ...state,
          datasets: state.datasets.map((e, i) => {
            if (i === index) return id
            return e
          }),
          pipeline_output: null,
          pipeline_output_hash: null,
          pipeline_output_error: null,
          pipeline_processing_error: null,
        }
      }
      if (type === TRAINING_WIZARD_PIPELINE_UPDATED) {
        const { pipeline, datasets, id } = payload
        return {
          ...state,
          pipeline,
          pipeline_output: null,
          pipeline_output_hash: null,
          pipeline_output_error: null,
          pipeline_processing_id: null,
          pipeline_processing_error: null,
          pipeline_processing_progress: 0,
          pipeline_id: id,
          datasets,
        }
      }
      if (type === TRAINING_WIZARD_SPEC_UPDATED) {
        return {
          ...state,
          spec: {
            ...state.spec,
            ...payload,
            // objectives: (payload.objectives || []).filter(e => e),
          },
        }
      }
      if (type === TRAINING_WIZARD_NAME_UPDATED) {
        return { ...state, title: payload }
      }
      if (type === TRAINING_WIZARD_PIPELINE_COMMITED) {
        return {
          ...state,
          pipeline_processing_id: payload,
          pipeline_processing_error: null,
          pipeline_processing_progress: 0,
          pipeline_output: null,
          pipeline_output_hash: null,
          pipeline_output_error: null,
        }
      }
      if (type === PIPELINE_PROCESSING_FINISHED) {
        const { id, meta } = payload
        if (!id) return state
        if (id === state.pipeline_processing_id) {
          return {
            ...state,
            pipeline_output_hash: meta[0].hash_out,
            pipeline_processing_error: null,
            pipeline_operations_count: getBoxCount(meta),
          }
        }
      }
      if (type === PIPELINE_PROCESSING_FAILED) {
        // TODO error message
        const { id } = payload
        if (!id) return state
        if (id === state.pipeline_processing_id) {
          return {
            ...state,
            pipeline_processing_error: true,
            pipeline_processing_id: null,
          }
        }
      }
      if (type === PIPELINE_ELEMENT_FINISHED) {
        const { id, meta, hashOut: hash } = payload
        if (!id) return state
        if (id === state.pipeline_processing_id) {
          if (hash === state.pipeline_output_hash)
            return {
              ...state,
              pipeline_output: meta.stats ? { ...meta.stats } : null,
              pipeline_output_error: null,
              pipeline_processing_id: null,
              pipeline_processing_progress: state.pipeline_processing_progress + 1,
            }
          return {
            ...state,
            pipeline_processing_progress: state.pipeline_processing_progress + 1,
          }
        }
      }
      if (type === PIPELINE_ELEMENT_FAILED) {
        const { id, hashOut: hash } = payload
        if (!id) return state
        if (
          id === state.pipeline_processing_id &&
          hash === state.pipeline_output_hash
        ) {
          return {
            ...state,
            pipeline_output: null,
            pipeline_output_error: true,
            pipeline_processing_id: null,
          }
        }
      }
      if (type === TRAINING_START_REQUEST_STARTED) {
        return { ...state, starting: true }
      }
      if (type === TRAINING_START_REQUEST_SUCCEEDED) {
        return { ...initialState, starting: false }
      }
      if (type === TRAINING_START_REQUEST_FAILED) {
        return { ...initialState, starting: false }
      }
      return state
    },
    selectors: {
      selectActiveTraining: rawSelector,
      selectTrainingWizardStep,
      selectTrainingWizardEditable,
      selectTrainingWizardPipelineProcessing,
      selectTrainingWizardIsConnected,
      reactTrainingChangeRequest: createSelector(rawSelector, state => {
        if (!state.id && state.pending) return acceptTrainingChange()
        return null
      }),
      reactTrainingPipelineOutputMissing: createSelector(rawSelector, state => {
        if (
          state.pipeline.length &&
          !state.pipeline_output &&
          !state.pipeline_processing_id &&
          state.step >= 1
        ) {
          const requestID = uuid()
          return (dispatch, { api }) => {
            dispatch({
              type: TRAINING_WIZARD_PIPELINE_COMMITED,
              payload: requestID,
            })
            api.transform('/commit', {
              method: 'POST',
              body: {
                spec: state.pipeline,
                datasets: state.datasets,
                request_id: requestID,
              },
            })
          }
        }
        return null
      }),
    },
    actions: {
      initializeTrainingWizard,
      startTrainingFromResource,
      acceptTrainingChange,
      rejectTrainingChange,
      openTrainingWizard,
      closeTrainingWizard,
      discardActiveTraining,
      trainingWizardNextStep,
      trainingWizardPrevStep,
      setTrainingWizardDataset,
      setTrainingWizardPipeline,
      setTrainingWizardSpec,
      setTrainingWizardName,
      trainingWizardStartTraining,
    },
    init: null,
    args: null,
    middleware: null,
    persist: null,
  })
}
