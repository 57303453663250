import React, { useRef, useState } from 'react'
import 'style.scss'

import { useStore, Link, useLocation, useRouteMatch } from 'core'

import { Form, FormItem } from 'components/Form'
import { BaseCard as Card } from 'components/CardLayout'
import Conditional from 'components/Conditional'
import LinearProgress from '@material-ui/core/LinearProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'

import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core'
import { matchPassword } from 'libs/utils/helpers'

import { classes as classNames } from './classNames'

const classes = {
  ...classNames,
  wrapper: 'RegistrationPage-wrapper',
  cardWrapper: 'RegistrationPage-card-wrapper',
  loginLinkWrapper: 'RegistrationPage-login-link-wrapper',
  loginLink: 'RegistrationPage-login-link',
  formWrapper: 'RegistrationPageForm-wrapper',
  error: 'error-message',
  termsLink: 'terms-and-conditions-link',
  loaderWrapper: 'loader-wrapper',
  loaderMessage: 'message',
}

const RegistrationPage = props => {
  const [validationError, setValidationError] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const [showMatchPassword, setShowMatchPassword] = useState(false)
  // const match = useRouteMatch()
  const location = useLocation()

  const token =
    location.search &&
    location.search
      .replace('?', '')
      .split('&')
      .map(pair => pair.split('='))
      .find(pair => pair[0] === 'token')?.[1]

  // const { token } = match.params

  const {
    register,
    registrationInProgress,
    registrationSucceeded,
    registrationError,
  } = useStore(
    'register',
    'selectRegistrationInProgress',
    'selectRegistrationSucceeded',
    'selectRegistrationError'
  )

  const ref = useRef()

  const registrationHandler = e => {
    e.preventDefault()
    const formState = ref.current.get() || {}
    const {
      firstName,
      lastName,
      username,
      email,
      organization,
      password,
      terms: TOSAgreementSigned,
      confirmPassword,
    } = formState
    const passwordMatch = matchPassword(password, confirmPassword)

    const fields = [...ref.current.htmlRef.current.getElementsByTagName('INPUT')]
    const requiredFields = fields.filter(field => field.required)
    const validationErrors = requiredFields.reduce((errors, field) => {
      if (field.name === 'confirmPassword' && !passwordMatch) {
        return { ...errors, [field.name]: 'Passwords must match.' }
      }
      if (field.name === 'email' && !field.checkValidity()) {
        return { ...errors, [field.name]: 'Email is invalid.' }
      }
      if (field.name === 'terms' && !TOSAgreementSigned) {
        return { ...errors, [field.name]: 'You must agree to terms of service.' }
      }
      const fieldValue =
        formState[field.name] === undefined || formState[field.name] === null
          ? ''
          : String(formState[field.name]).trim()
      if (!fieldValue) {
        return { ...errors, [field.name]: 'Field is required.' }
      }
      return errors
    }, {})
    if (Object.keys(validationErrors).length) {
      setValidationError(validationErrors)
    } else {
      register({
        email,
        username,
        password,
        organization,
        firstName,
        lastName,
        token,
      })
    }
  }

  const showPasswordHandler = () => setShowPassword(!showPassword)
  const showMatchPasswordHandler = () => setShowMatchPassword(!showMatchPassword)

  const emailError = registrationError?.email?.[0] || validationError.email
  const usernameError = registrationError?.username?.[0] || validationError.username
  const organizationError =
    registrationError?.organization?.[0] || validationError.organization
  const passwordError = registrationError?.password?.[0] || validationError.password
  const confirmPasswordError = validationError.confirmPassword
  const termsError = validationError.terms
  const firstNameError = validationError.firstName
  const lastNameError = validationError.lastName

  const showForm = !registrationInProgress

  return (
    <div
      className={classes.wrapper}
      style={{
        flexDirection:
          registrationSucceeded || registrationError || registrationInProgress
            ? 'column'
            : 'row',
      }}
    >
      <div
        className={classes.formWrapper}
        style={{ width: '25em', display: showForm ? 'flex' : 'none' }}
      >
        {/* <Conditional dependencies={!registrationSucceeded && !registrationInProgress}> */}
        <Card className={classes.cardWrapper}>
          <Form
            ref={ref}
            // onSubmit={registrationHandler}
          >
            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormItem
                fullWidth
                required
                margin="normal"
                label="First Name"
                name="firstName"
                dataKey="firstName"
                defaultValue=""
                autoComplete="firstName"
                style={{
                  width: '48%',
                }}
                error={!!firstNameError}
                helperText={firstNameError || ''}
                component={TextField}
              />
              <FormItem
                fullWidth
                required
                margin="normal"
                label="Last Name"
                name="lastName"
                dataKey="lastName"
                defaultValue=""
                autoComplete="lastName"
                style={{
                  width: '48%',
                }}
                error={!!lastNameError}
                helperText={lastNameError || ''}
                component={TextField}
              />
            </span>
            <Conditional dependencies={!token}>
              <FormItem
                fullWidth
                required
                margin="normal"
                label="Email"
                name="email"
                dataKey="email"
                defaultValue=""
                autoComplete="email"
                type="email"
                error={!!emailError}
                helperText={emailError || ''}
                component={TextField}
              />
            </Conditional>
            <FormItem
              fullWidth
              required
              margin="normal"
              label="Username"
              name="username"
              dataKey="username"
              defaultValue=""
              autoComplete="username"
              error={!!usernameError}
              helperText={usernameError || ''}
              component={TextField}
            />
            <Conditional dependencies={!token}>
              <FormItem
                fullWidth
                required
                margin="normal"
                label="Company"
                name="organization"
                dataKey="organization"
                defaultValue=""
                autoComplete="company"
                error={!!organizationError}
                helperText={organizationError || ''}
                component={TextField}
              />
            </Conditional>
            <FormItem
              fullWidth
              required
              autoComplete="new-password"
              margin="normal"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              dataKey="password"
              defaultValue=""
              error={!!passwordError}
              helperText={passwordError || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showPasswordHandler}
                      // onKeyDown={passwordKeyDownHandler}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              component={TextField}
            />
            <FormItem
              fullWidth
              required
              autoComplete="new-password"
              margin="normal"
              label="Confirm Password"
              type={showMatchPassword ? 'text' : 'password'}
              name="confirmPassword"
              dataKey="confirmPassword"
              defaultValue=""
              error={!!confirmPasswordError}
              helperText={confirmPasswordError || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showMatchPasswordHandler}
                      // onKeyDown={passwordMatchKeyDownHandler}
                    >
                      {showMatchPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              component={TextField}
            />
            <FormControl error={!!termsError}>
              <FormItem
                dataKey="terms"
                margin="dense"
                label={
                  <span>
                    I agree to the
                    <a
                      href="https://www.brainalyzed.com/legal/terms-and-conditions/"
                      target="_blank"
                      rel="noreferrer"
                      className={classes.termsLink}
                    >
                      Terms and Conditions
                    </a>
                  </span>
                }
                checked={false}
                control={<Checkbox name="terms" required color="default" />}
                component={FormControlLabel}
              />
              <Conditional dependencies={termsError}>
                <FormHelperText>{termsError}</FormHelperText>
              </Conditional>
            </FormControl>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              onClick={registrationHandler}
            >
              Sign Up
            </Button>
            <div className={classes.loginLinkWrapper}>
              Have an account?
              <Link to="/login" absolute className={classes.loginLink}>
                Log in
              </Link>
            </div>
          </Form>
        </Card>
        {/* </Conditional> */}
      </div>
      <Conditional dependencies={registrationInProgress}>
        <div className={classes.loaderWrapper}>
          <span className={classes.loaderMessage}>Signing up...</span>
          <LinearProgress />
        </div>
      </Conditional>
      {/* <Conditional dependencies={registrationError}>
        <div className={classes.messageWrapper}>
          <span className={classes.bigText}>Something went wrong.</span>
          <span className={classes.smallText}>Please try again later.</span>
        </div>
      </Conditional> */}
      {/* <Conditional dependencies={registrationSucceeded}>
        <div className={classes.messageWrapper}>
          <span className={classes.bigText}>
            Registration completed successfully.
          </span>
          <span className={classes.smallText}>
            Please check your email for verification.
          </span>
        </div>
      </Conditional> */}
    </div>
  )
}

export default RegistrationPage
