import { createBundle } from 'core/bundler'
import {} from 'core/actiontypes'

const initialState = {
  id: null,
}

export default config =>
  createBundle({
    name: 'transformation',
    reducer: (state = initialState, { type, payload }) => {
      return state
    },
    selectors: {},
    actions: {
      saveTransformation:
        payload =>
        (_, { api, store, matchPath }) => {
          const { id, spec, datasets, name, description } = payload
          const URL = id ? `/transformations/${id}` : '/transformations'
          const method = id ? 'PATCH' : 'POST'
          return api(URL, {
            method,
            body: {
              name,
              description,
              spec,
              datasets,
            },
          })
            .then(data => {
              return store.fetchTransformations().then(() => data)
            })
            .then(data => {
              if (matchPath('/pipeline'))
                store.historyRedirect(`/pipeline/${data.id}`)
            })
        },
      removeTransformation:
        id =>
        (_, { api, store }) => {
          return api(`/transformations/${id}`, { method: 'DELETE' }).then(data => {
            store.markTransformationsAsOutdated()
            if (store.selectActivePipeline()?.id === id) store.discardPipeline()
          })
        },
      cloneTransformation:
        id =>
        (_, { api, store }) => {
          return api(`/transformations/${id}/clone`, { method: 'POST' }).then(data =>
            store.markTransformationsAsOutdated()
          )
        },
    },
    init: null,
    args: null,
    middleware: null,
    persist: null,
  })
