/* eslint-disable no-shadow */
import { createSelector } from 'create-selector'
import { createBundle } from 'core/bundler'
import {
  DATASETS_FETCH_STARTED,
  DATASETS_FETCH_SUCCEEDED,
  DATASETS_FETCH_FAILED,
  DATASETS_CLEARED,
  DATASETS_OUTDATED,
  DATASETS_EXPIRED,
  USER_LOGGED_OUT,
  DATASET_SELECTED,
} from 'core/actiontypes'

const name = 'datasets' // e.g notification, trainings, users
const uCaseName = name.charAt(0).toUpperCase() + name.slice(1)
const defaultConfig = {
  apiRoute: '/datasets',
  stale: 900000, // fifteen minutes
  retry: 60000, // one minute,
  expireAfter: Infinity,
  persist: false,
  // checkIfOnline: true,
}

const initialState = {
  data: null,
  errorTimes: [],
  error: null,
  lastSuccess: null,
  isOutdated: false,
  isLoading: false,
  isExpired: false,
  failedPermanently: false,
  selectedDataset: null,
}

const singularString = uCaseName.slice(0, uCaseName.length - 1)

const rawSelector = `select${uCaseName}Raw`
const dataSelector = `select${uCaseName}`
const selectedSelector = `selectSelected${singularString}`
const lastSuccessSelector = `select${uCaseName}LastSuccess`
const isExpiredSelector = `select${uCaseName}IsExpired`
const lastErrorSelector = `select${uCaseName}LastError`
const isStaleSelector = `select${uCaseName}IsStale`
const isWaitingToRetrySelector = `select${uCaseName}IsWaitingToRetry`
const isLoadingSelector = `select${uCaseName}IsLoading`
const failedPermanentlySelector = `select${uCaseName}FailedPermanently`
const shouldUpdateSelector = `select${uCaseName}ShouldUpdate`

const fetchSuccess = payload => ({ type: DATASETS_FETCH_SUCCEEDED, payload })
const fetchError = error => ({ type: DATASETS_FETCH_FAILED, error })

export default config => {
  // eslint-disable-next-line no-param-reassign
  config = { ...defaultConfig, ...config }
  const markDataAsOutdated = () => ({ type: DATASETS_OUTDATED })
  const clearData = () => ({ type: DATASETS_CLEARED })
  const expireData = () => ({ type: DATASETS_EXPIRED })
  const fetchData =
    () =>
    (dispatch, { api }) => {
      dispatch({ type: DATASETS_FETCH_STARTED })
      return api(config.apiRoute).then(
        payload => {
          // dispatch(fetchSuccess([...payload.owned]))
          dispatch(fetchSuccess(payload))
        },
        error => {
          dispatch(fetchError(error))
        }
      )
    }
  const removeDataset =
    ({ id }) =>
    (dispatch, { api }) => {
      api(`/datasets/${id}`, { method: 'DELETE' }).then(() =>
        dispatch(markDataAsOutdated())
      )
    }
  const editDataset =
    payload =>
    (dispatch, { api }) =>
      api(`/datasets/${payload.id}`, {
        method: 'PATCH',
        body: { ...payload },
      }).then(() => dispatch(markDataAsOutdated()))
  const setSelectedDataset = payload => ({ type: DATASET_SELECTED, payload })

  const selectors = {
    [rawSelector]: state => state[name],
    [dataSelector]: createSelector(rawSelector, root => root.data),
    [selectedSelector]: createSelector(rawSelector, state => state.selectedDataset),
    [isStaleSelector]: createSelector(
      rawSelector, // raw - everything
      lastSuccessSelector, //  ???
      'selectAppTime',
      (state, time, appTime) => {
        if (state.isOutdated) {
          return true
        }
        if (!time) {
          return false
        }
        return appTime - time > config.stale
      }
    ),
    [isExpiredSelector]: createSelector(rawSelector, root => root.isExpired),
    [lastErrorSelector]: createSelector(
      rawSelector,
      resource => resource.errorTimes.slice(-1)[0] || null
    ),
    [lastSuccessSelector]: createSelector(rawSelector, root => root.lastSuccess),
    [isWaitingToRetrySelector]: createSelector(
      lastErrorSelector,
      'selectAppTime',
      (time, appTime) => {
        if (!time) {
          return false
        }
        return appTime - time < config.retry
      }
    ),
    [isLoadingSelector]: createSelector(
      rawSelector,
      resourceState => resourceState.isLoading
    ),
    [failedPermanentlySelector]: createSelector(
      rawSelector,
      resourceState => resourceState.failedPermanently
    ),
    [shouldUpdateSelector]: createSelector(
      isLoadingSelector,
      failedPermanentlySelector,
      isWaitingToRetrySelector,
      dataSelector,
      isStaleSelector,
      // 'selectIsOnline',
      (
        isLoading,
        failedPermanently,
        isWaitingToRetry,
        data,
        isStale
        // isOnline
      ) => {
        if (
          // (checkIfOnline && !isOnline) ||
          isLoading ||
          failedPermanently ||
          isWaitingToRetry
        ) {
          return false
        }
        if (data === null) {
          return true
        }
        return isStale
      }
    ),
    reactMissingDatasets: createSelector(
      'selectUser',
      shouldUpdateSelector,
      (user, update) => {
        if (update && user) {
          return fetchData()
        }
        return null
      }
    ),
    reactSelectedDatasetNonExistent: createSelector(
      selectedSelector,
      dataSelector,
      (selected, data) => {
        if (selected && !data?.find(dataset => dataset.id === selected)) {
          return setSelectedDataset(null)
        }
        return null
      }
    ),
  }

  if (config.expireAfter !== Infinity) {
    selectors[`reactExpire${uCaseName}`] = createSelector(
      lastSuccessSelector,
      'selectAppTime',
      (time, appTime) => {
        if (!time) {
          return null
        }
        if (appTime - time > config.expireAfter) {
          return expireData()
        }
        return null
      }
    )
  }

  return createBundle({
    name,
    reducer: (state = initialState, { type, payload, error, merge }) => {
      if (type === DATASETS_FETCH_STARTED) {
        return { ...state, isLoading: true }
      }
      if (type === DATASETS_FETCH_SUCCEEDED) {
        const updatedData = merge ? { ...state.data, ...payload } : payload
        return {
          ...state,
          isLoading: false,
          data: updatedData,
          lastSuccess: Date.now(),
          errorTimes: [],
          error: null,
          failedPermanently: false,
          isOutdated: false,
          isExpired: false,
        }
      }
      if (type === DATASETS_FETCH_FAILED) {
        const errorMessage = (error && error.message) || error
        return {
          ...state,
          isLoading: false,
          errorTimes: [...state.errorTimes, Date.now()],
          error: errorMessage,
          failedPermanently: !!(error && error.permanent),
        }
      }
      if (type === DATASETS_CLEARED || type === USER_LOGGED_OUT) {
        return initialState
      }
      if (type === DATASETS_EXPIRED) {
        return {
          ...initialState,
          isExpired: true,
          errorTimes: state.errorTimes,
          error: state.error,
        }
      }
      if (type === DATASETS_OUTDATED) {
        return { ...state, isOutdated: true }
      }
      if (type === DATASET_SELECTED) {
        return { ...state, selectedDataset: payload }
      }
      return state
    },
    selectors,
    actions: {
      [`fetch${uCaseName}`]: fetchData,
      [`mark${uCaseName}AsOutdated`]: markDataAsOutdated,
      [`clear${uCaseName}`]: clearData,
      [`expire${uCaseName}`]: expireData,
      removeDataset,
      editDataset,
      setSelectedDataset,
    },
    init: store => {
      store.serverEvent('TYPIFICATION_FINISHED', ({ data }, store) => {
        store.markDatasetsAsOutdated()
      })
      store.serverEvent('TYPIFICATION_FAILED', ({ data }, store) => {
        store.markDatasetsAsOutdated()
      })
      store.serverEvent('STATS_CALCULATION_FINISHED', ({ data }, store) => {
        store.markDatasetsAsOutdated()
      })
      store.serverEvent('STATS_CALCULATION_FAILED', ({ data }, store) => {
        store.markDatasetsAsOutdated()
      })
      /* eslint-disable prettier/prettier */
      store.serverEvent('DEFAULT_PIPELINE_GENERATION_FINISHED', ({ data }, store) => {
        store.markDatasetsAsOutdated()
      })
      store.serverEvent('DEFAULT_PIPELINE_GENERATION_FAILED', ({ data }, store) => {
        store.markDatasetsAsOutdated()
      })
      /* eslint-enable prettier/prettier */
    },
    args: null,
    middleware: null,
    persist: config.persist
      ? [
          DATASETS_FETCH_SUCCEEDED,
          DATASETS_EXPIRED,
          DATASETS_OUTDATED,
          DATASETS_CLEARED,
        ]
      : null,
  })
}
