import React, { useRef, useState, useEffect } from 'react'
import Core, {
  useRouteMatch,
  Redirect,
  useHistory,
  useLocation,
  useStore,
} from 'core'
import 'style.scss'

import { matchPassword } from 'libs/utils/helpers'

import {
  TextField,
  Button,
  FormControlLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core'

import { BaseCard as Card } from 'components/CardLayout'
import Conditional from 'components/Conditional'
import LinearProgress from '@material-ui/core/LinearProgress'

import { classes as classNames } from '../classNames'

const classes = {
  ...classNames,
  wrapper: 'PasswordResetPage-wrapper',
  passwordRecoveryControls: 'PasswordResetPage-passwordRecoveryControls',
}

const PasswordResetPage = props => {
  // const location = useLocation()
  const history = useHistory()
  const passwordRef = useRef()
  const repeatRef = useRef()
  const [matchError, setMatchError] = useState(null)
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const {
    passwordRecoveryRequestInProgress,
    passwordRecoveryRequestSucceeded,
    passwordRecoveryRequestError,
    recoveryTokenValidationInProgress,
    // recoveryTokenValidationSucceeded,
    recoveryTokenValidationError,
    passwordResetInProgress,
    passwordResetSucceeded,
    passwordResetError,
    passwordRecoveryToken,
    validateRecoveryToken,
    resetPassword,
  } = useStore(
    'selectPasswordRecoveryRequestInProgress',
    'selectPasswordRecoveryRequestSucceeded',
    'selectPasswordRecoveryRequestError',
    'selectRecoveryTokenValidationInProgress',
    'selectRecoveryTokenValidationSucceeded',
    'selectRecoveryTokenValidationError',
    'selectPasswordRecoveryToken',
    'selectPasswordResetInProgress',
    'selectPasswordResetSucceeded',
    'selectPasswordResetError',
    'validateRecoveryToken',
    'resetPassword'
  )

  const match = useRouteMatch()
  const { token } = match.params

  const loginRedirect = () => history.push('/login')

  const passwordChangeHandler = e => {
    setPassword(e.target.value)
  }

  const repeatPasswordChangeHandler = e => {
    setRepeatPassword(e.target.value)
  }

  const resetPasswordHandler = () => {
    const passMatch = matchPassword(password, repeatPassword)
    if (!passMatch && !matchError) {
      setMatchError('Passwords must match.')
    }
    if (passMatch) {
      resetPassword(password, passwordRecoveryToken)
    }
  }

  useEffect(() => {
    token && validateRecoveryToken(token)
  }, [validateRecoveryToken, token])

  return (
    <>
      <Conditional
        dependencies={
          !token &&
          !passwordRecoveryRequestInProgress &&
          !passwordRecoveryRequestSucceeded &&
          !passwordRecoveryRequestError &&
          !recoveryTokenValidationInProgress &&
          !passwordRecoveryToken &&
          !recoveryTokenValidationError &&
          !passwordResetInProgress &&
          !passwordResetSucceeded &&
          !passwordResetError
        }
      >
        <Redirect to="/dashboard" absolute />
      </Conditional>
      <div
        className={classes.wrapper}
        style={{
          flexDirection: 'column',
        }}
      >
        <Conditional dependencies={passwordRecoveryRequestInProgress}>
          <div className={classes.loaderWrapper}>
            <span className={classes.loaderMessage}>Recovering password...</span>
            <LinearProgress />
          </div>
        </Conditional>
        <Conditional dependencies={passwordRecoveryRequestSucceeded}>
          <div className={classes.messageWrapper}>
            <span className={classes.bigText}>Password recovery</span>
            <span className={classes.smallText}>
              Password recovery link has been sent to provided email.
            </span>
          </div>
        </Conditional>
        <Conditional dependencies={passwordRecoveryRequestError}>
          <div className={classes.messageWrapper}>
            <span className={classes.bigText}>Password recovery failed</span>
            <span className={classes.smallText}>
              Something went wrong, please try again later.
            </span>
          </div>
        </Conditional>
        <Conditional dependencies={recoveryTokenValidationInProgress}>
          <div className={classes.loaderWrapper}>
            <span className={classes.loaderMessage}>Token validation...</span>
            <LinearProgress />
          </div>
        </Conditional>
        <Conditional dependencies={passwordRecoveryToken}>
          <div className={classes.messageWrapper}>
            <span className={classes.bigText}>Password Recovery</span>
            <span className={classes.smallText}>
              Enter a new password for your account.
            </span>
            <div
              className={`${classes.controlsWrapper} ${classes.passwordRecoveryControls}`}
            >
              <form>
                <TextField
                  inputProps={{
                    ref: passwordRef,
                  }}
                  name="password"
                  type="password"
                  autoComplete="off"
                  fullWidth
                  required
                  margin="normal"
                  label="Password"
                  error={!!matchError}
                  // helperText={passwordError || ''}
                  value={password}
                  onChange={passwordChangeHandler}
                />
                <TextField
                  inputProps={{
                    ref: repeatRef,
                  }}
                  name="repeat-password"
                  type="password"
                  autoComplete="off"
                  fullWidth
                  required
                  margin="normal"
                  label="Repeat Password"
                  error={!!matchError}
                  helperText={matchError || ''}
                  value={repeatPassword}
                  onChange={repeatPasswordChangeHandler}
                />
              </form>
              <Button
                variant="contained"
                color="primary"
                onClick={resetPasswordHandler}
              >
                Reset Password
              </Button>
            </div>
          </div>
        </Conditional>
        <Conditional dependencies={recoveryTokenValidationError}>
          <div className={classes.messageWrapper}>
            <span className={classes.bigText}>Password recovery failed</span>
            <span className={classes.smallText}>
              Something went wrong, please try again later.
            </span>
          </div>
        </Conditional>
        <Conditional dependencies={passwordResetInProgress}>
          <div className={classes.loaderWrapper}>
            <span className={classes.loaderMessage}>Resetting password...</span>
            <LinearProgress />
          </div>
        </Conditional>
        <Conditional dependencies={passwordResetSucceeded}>
          <div className={classes.messageWrapper}>
            <span className={classes.bigText}>Password reset succeeded</span>
            <span className={classes.smallText}>
              Password reset succeeded, you can now log in.
            </span>
            <div className={classes.controlsWrapper}>
              <Button variant="contained" color="primary" onClick={loginRedirect}>
                Go to Login page
              </Button>
            </div>
          </div>
        </Conditional>
        <Conditional dependencies={passwordResetError}>
          <div className={classes.messageWrapper}>
            <span className={classes.bigText}>Password reset failed</span>
            <span className={classes.smallText}>
              Something went wrong, please try again later.
            </span>
          </div>
        </Conditional>
      </div>
    </>
  )
}

export default PasswordResetPage
