/* eslint-disable camelcase */
import React, { forwardRef, useState, useRef, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStore } from 'core'
import Modal from 'components/Modal'

import {
  Form,
  FormItem,
  FormFor,
  FormConditional,
  FormContext,
  FormAction,
} from 'components/Form'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Switch,
  MenuItem,
  Typography,
  Slider,
  RadioGroup,
  Radio,
  FormLabel,
} from '@material-ui/core'

import Conditional from 'components/Conditional'

import './style.scss'

const classes = {
  formInnerWrapper: 'Dynamic-datasets-form-inner-wrapper',
  margin: 'margin',
  inputsWrapper: 'inputs-wrapper',
}

const DynamicDatasourceQueryModal = forwardRef((props, ref) => {
  const [type, setType] = useState('table')
  const [error, setError] = useState({
    name: false,
    description: false,
    user: false,
    password: false,
    host: false,
    port: false,
    type: false,
    db_name: false,
    schema: false,
    path: false,
    table_name: false,
    limit: false,
    offset: false,
    sql: false,
  })

  const { createDynamicDataset } = useStore('createDynamicDataset')
  const formRef = useRef()
  return (
    <Modal
      ref={ref}
      title="NEW QUERY"
      footer
      onCancel={() => ref.current.close()}
      onClose={() => ref.current.close()}
      onOk={() => {
        const formState = JSON.parse(JSON.stringify(formRef.current.get()))
        const tempErrorStorage = {}

        formState.config.port = Number(formState.config.port)
        formState.config.limit =
          formState.config.limit === '' || formState.config.limit < 0
            ? null
            : Number(formState.config.limit)
        formState.config.offset =
          formState.config.offset === '' || formState.config.offset < 0
            ? null
            : Number(formState.config.offset)
        formState.spec = {}
        formState.spec.type = 'basedata'

        tempErrorStorage.description = false
        if (formState.name) tempErrorStorage.name = false
        if (!formState.name) tempErrorStorage.name = true

        Object.keys(formState.config).forEach(input => {
          if (formState.config[input] || formState.config[input] === 0) {
            tempErrorStorage[input] = false
          }
          if (!formState.config[input] && formState.config[input] !== 0) {
            tempErrorStorage[input] = true
          }
          // console.log(formState.config[input]) // if port === null, the form defaults it to 0 which creates a "problem"
          if (input === 'port')
            tempErrorStorage[input] =
              !(formState.config[input] || formState.config[input] === 0) ||
              (formState.config[input] <= 0 && null)
          if (input === 'offset' || input === 'limit') {
            tempErrorStorage[input] = false
          }
        })

        setError(tempErrorStorage)
        if (
          !Object.keys(tempErrorStorage).some(
            inputError =>
              tempErrorStorage[inputError] === true ||
              tempErrorStorage[inputError] === null
          )
        ) {
          createDynamicDataset(formState)
          ref.current.close()
        }
      }}
    >
      <Form ref={formRef}>
        <div className={classes.formInnerWrapper}>
          <FormItem
            dataKey="name"
            defaultValue=""
            label="Dataset Name"
            InputProps={{ style: { width: '100%' }, autoFocus: true }}
            style={{ width: '48%', marginTop: '-10px' }}
            error={error.name}
            helperText={error.name ? 'Field is required.' : ''}
            component={TextField}
          />

          <FormItem
            dataKey="description"
            defaultValue=""
            label="Description"
            fullWidth
            className={classes.margin}
            component={TextField}
          />
          <span className={classes.inputsWrapper}>
            <FormItem
              dataKey="config.user"
              defaultValue=""
              label="Username"
              fullWidth
              style={{
                width: '48%',
              }}
              error={error.user}
              helperText={error.user ? 'Field is required.' : ''}
              component={TextField}
            />

            <FormItem
              dataKey="config.password"
              defaultValue=""
              label="Password"
              fullWidth
              style={{
                width: '48%',
              }}
              error={error.password}
              helperText={error.password ? 'Field is required.' : ''}
              component={TextField}
            />
          </span>
          <span className={classes.inputsWrapper}>
            <FormItem
              dataKey="config.host"
              defaultValue=""
              label="Host"
              fullWidth
              style={{ width: '80%' }}
              error={error.host}
              helperText={error.host ? 'Field is required.' : ''}
              component={TextField}
            />
            <FormItem
              dataKey="config.port"
              defaultValue={0}
              label="Port"
              type="number"
              error={error.port || error.port === null}
              fullWidth
              style={{ width: '15%' }}
              helperText={
                // eslint-disable-next-line no-nested-ternary
                error.port
                  ? 'Field is required.'
                  : error.port === null
                  ? 'Value must be higher than 0.'
                  : ''
              }
              InputProps={{ inputProps: { min: 0 } }}
              component={TextField}
            />
          </span>
          <FormItem
            dataKey="type"
            defaultValue="POSTGRES_DATASET"
            onChange={event => {
              event.target.value === 'POSTGRES_DATASET' && setType('table')
              event.target.value === 'SFTP_DATASET' && setType('')
            }}
            select
            label="Upload Type"
            className={classes.margin}
            fullWidth
            component={TextField}
          >
            <MenuItem value="POSTGRES_DATASET">Postgress Dataset</MenuItem>
            <MenuItem value="SFTP_DATASET">SFTP Dataset</MenuItem>
          </FormItem>
          <FormConditional dataKey="type" value="POSTGRES_DATASET">
            <FormItem
              dataKey="config.db_name"
              defaultValue=""
              label="Database Name"
              fullWidth
              className={classes.margin}
              error={error.db_name}
              helperText={error.user ? 'Field is required.' : ''}
              component={TextField}
            />
          </FormConditional>
          <FormConditional dataKey="type" value="SFTP_DATASET">
            <FormItem
              dataKey="config.path"
              defaultValue=""
              label="Path"
              fullWidth
              className={classes.margin}
              error={error.path}
              helperText={error.path ? 'Field is required.' : ''}
              component={TextField}
            />
          </FormConditional>

          <FormConditional dataKey="type" value="POSTGRES_DATASET">
            <FormLabel style={{ display: 'block', marginTop: '20px' }}>
              Choose type:
            </FormLabel>
            <RadioGroup
              value={type}
              onChange={event => setType(event.target.value)}
              margin="dense"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                value="table"
                control={<Radio size="small" color="default" />}
                label="Table"
              />
              <FormControlLabel
                value="sql"
                control={<Radio size="small" color="default" />}
                label="SQL"
              />
            </RadioGroup>
          </FormConditional>
          <Conditional dependencies={type === 'table'}>
            <FormItem
              dataKey="config.schema"
              defaultValue=""
              label="Schema"
              className={classes.margin}
              fullWidth
              error={error.schema}
              helperText={error.schema ? 'Field is required.' : ''}
              component={TextField}
            />
            <FormItem
              dataKey="config.table_name"
              defaultValue=""
              label="Table Name"
              fullWidth
              className={classes.margin}
              error={error.table_name}
              helperText={error.table_name ? 'Field is required.' : ''}
              component={TextField}
            />
            {/* <span className={classes.inputsWrapper}>
              <FormItem
                dataKey="config.limit"
                defaultValue={1}
                label="Limit"
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                fullWidth
                className={classes.margin}
                style={{ width: '48%' }}
                component={TextField}
              />
              <FormItem
                dataKey="config.offset"
                defaultValue={0}
                label="Offset"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                fullWidth
                className={classes.margin}
                style={{ width: '48%' }}
                component={TextField}
              />
            </span> */}
          </Conditional>
          <Conditional dependencies={type === 'sql'}>
            <FormItem
              dataKey="config.sql"
              defaultValue=""
              label="SQL"
              fullWidth
              multiline
              className={classes.margin}
              error={error.sql}
              helperText={error.sql ? 'Field is required.' : ''}
              component={TextField}
            />
          </Conditional>
        </div>
      </Form>
    </Modal>
  )
})

export default DynamicDatasourceQueryModal
