import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import 'style.scss'

import Conditional from 'components/Conditional'
import ScoringBrainsTable from 'components/ScoringBrainsTable'

import { TextField, MenuItem } from '@material-ui/core'

import { classes as classNames } from '../../classNames'
import { validator as prevValidator } from '../1'

const classes = {
  ...classNames,
  stepTwo: 'ScoringWizard-step-two',
  noDataMsg: 'ScoringWizard-no-data-msg',
  alternativeDataMsg: 'ScoringWizard-alternative-data-msg',
  actionLink: 'ScoringWizard-action-link',
}

export const validator = scoring => {
  // const { datasets } = scoring
  // return !!datasets.length && !datasets.some(e => e === null)
  return prevValidator(scoring) && !!scoring.brains.length
}

const ScoringWizardStepTwo = props => {
  const { scoring } = props
  const { training, step, brains } = scoring
  const { setScoringWizardBrains } = useStore('setScoringWizardBrains')
  const stepCompleted = validator(scoring)

  // const datasetInput = useMemo(() => {
  //   return datasets.map((datasetID, i) => {
  //     const value = datasetID !== null ? datasetID : ''
  //     return (
  //       <TextField
  //         // eslint-disable-next-line react/no-array-index-key
  //         key={i}
  //         disabled={step !== 1}
  //         select
  //         value={value}
  //         label="Please select a dataset"
  //         onChange={event => setScoringWizardDataset(event.target.value, i)}
  //       >
  //         {datasources &&
  //           datasources.map(datasource => (
  //             <MenuItem key={datasource.id} value={datasource.id}>
  //               {datasource.name}
  //             </MenuItem>
  //           ))}
  //       </TextField>
  //     )
  //   })
  // }, [step, datasets, datasources, setScoringWizardDataset])

  return (
    <div className={classes.step} data-active={step === 1}>
      <div className={classes.progressWrapper}>
        <div className={classes.progressBar} data-completed={stepCompleted} />
      </div>
      <div className={`${classes.stepContent} ${classes.stepTwo}`}>
        <h2>Choose the models:</h2>
        <Conditional dependencies={!training}>
          <span className={classes.noDataMsg}>No training choosen.</span>
          <span className={classes.alternativeDataMsg}>
            Please choose a training.
          </span>
        </Conditional>
        {training && (
          <ScoringBrainsTable
            key={training}
            training={training}
            brains={brains}
            setBrains={setScoringWizardBrains}
            disabled={step !== 1}
          />
        )}
      </div>
    </div>
  )
}

ScoringWizardStepTwo.propTypes = {
  scoring: PropTypes.object.isRequired,
}

export default ScoringWizardStepTwo
