/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import 'style.scss'

// ////////// MATERIAL UI ICONS //////////
import WbSunnyIcon from '@material-ui/icons/WbSunny'
import AppsIcon from '@material-ui/icons/Apps'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import GetAppIcon from '@material-ui/icons/GetApp'
import ReorderIcon from '@material-ui/icons/Reorder'
import ViewListIcon from '@material-ui/icons/ViewList'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import BurstModeIcon from '@material-ui/icons/BurstMode'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import CallMergeIcon from '@material-ui/icons/CallMerge'
import TitleIcon from '@material-ui/icons/Title'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import GrainIcon from '@material-ui/icons/Grain'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import FunctionsIcon from '@material-ui/icons/Functions'
import ExposureIcon from '@material-ui/icons/Exposure'
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease'
import CodeIcon from '@material-ui/icons/Code'
import MergeTypeIcon from '@material-ui/icons/MergeType'
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay'
import ColorizeIcon from '@material-ui/icons/Colorize'
import EcoIcon from '@material-ui/icons/Eco'
import DeleteIcon from '@material-ui/icons/Delete'
import SettingsIcon from '@material-ui/icons/Settings'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import ListAltIcon from '@material-ui/icons/ListAlt'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import FolderIcon from '@material-ui/icons/Folder'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import DescriptionIcon from '@material-ui/icons/Description'
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import InfoIcon from '@material-ui/icons/Info'
import AirplayIcon from '@material-ui/icons/Airplay'
import FormatIndentDecreaseIcon from '@material-ui/icons/FormatIndentDecrease'

// ////////// CUSTOM ICONS //////////
import Rect from './CustomIcons/rect.svg'
import Gear from './CustomIcons/gear.svg'
import Play from './CustomIcons/play.svg'
import Pause from './CustomIcons/pause.svg'
import Eye from './CustomIcons/eye.svg'
import World from './CustomIcons/world.svg'
import Trash from './CustomIcons/trash.svg'
import EditBox from './CustomIcons/edit-box.svg'
import Loading from './CustomIcons/loading.svg'
import Spinner from './CustomIcons/spinner.svg'
// //////////////////////////////////

const classes = {
  wrapper: 'Icon-wrapper',
  svg: 'Icon-svg',
}

const Icon = forwardRef((props, ref) => {
  const { type, className, ...rest } = props
  let icon = null

  switch (type) {
    case 'rect':
      icon = <Rect />
      break
    case 'arrow-up':
      icon = <KeyboardArrowUpIcon />
      break
    case 'arrow-right':
      icon = <KeyboardArrowRightIcon />
      break
    case 'arrow-down':
      icon = <KeyboardArrowDownIcon />
      break
    case 'arrow-left':
      icon = <KeyboardArrowLeftIcon />
      break
    case 'loading':
      icon = <Loading />
      break
    case 'spinner':
      icon = <Spinner />
      break
    case 'gear':
      icon = <SettingsIcon />
      break
    case 'play':
      icon = <Play />
      break
    case 'world':
      icon = <World />
      break
    case 'trash':
      icon = <DeleteIcon />
      break
    case 'pause':
      icon = <Pause />
      break
    case 'eye':
      icon = <Eye />
      break
    case 'sun':
      icon = <WbSunnyIcon />
      break
    case 'edit-box':
      icon = <EditBox />
      break
    case 'apps':
      icon = <AppsIcon />
      break
    case 'cards':
      icon = <AppsIcon />
      break
    case 'chart':
      icon = <EqualizerIcon />
      break
    case 'list':
      icon = <ReorderIcon />
      break
    case 'bullet-list':
      icon = <ViewListIcon />
      break
    case 'resources':
      icon = <BurstModeIcon />
      break
    case 'dashboard':
      icon = <AssignmentIcon />
      break
    case 'upload':
      icon = <CloudUploadIcon />
      break
    case 'download':
      icon = <GetAppIcon />
      break
    case 'projects':
      icon = <PermMediaIcon />
      break
    case 'merge':
      icon = <CallMergeIcon />
      break
    case 'training':
      icon = <TitleIcon />
      break
    case 'info':
      icon = <InfoOutlinedIcon />
      break
    case 'pipeline':
      icon = <DeviceHubIcon />
      break
    case 'train':
      icon = <FitnessCenterIcon />
      break
    case 'brains':
      icon = <GrainIcon />
      break
    case 'score':
      icon = <PlaylistAddCheckIcon />
      break
    case 'dataset':
      icon = <ReorderIcon />
      break
    case 'slot':
      icon = <CheckBoxOutlineBlankIcon />
      break
    case 'join':
      icon = <MergeTypeIcon />
      break
    case 'filter':
      icon = <CalendarViewDayIcon />
      break
    case 'imputation':
      icon = <FormatIndentIncreaseIcon />
      break
    case 'sanitation':
      icon = <EcoIcon />
      break
    case 'calculation':
      icon = <FunctionsIcon />
      break
    case 'resample':
      icon = <ColorizeIcon />
      break
    case 'mapping':
      icon = <ListAltIcon />
      break
    case 'swap':
      icon = <SwapHorizIcon />
      break
    case 'dev':
      icon = <CodeIcon />
      break
    case 'close':
      icon = <CloseIcon />
      break
    case 'subtract':
      icon = <IndeterminateCheckBoxIcon />
      break
    case 'account':
      icon = <AccountCircleIcon />
      break
    case 'power':
      icon = <PowerSettingsNewIcon />
      break
    case 'folder':
      icon = <FolderIcon />
      break
    case 'static-dataset':
      icon = <InsertDriveFileIcon />
      break
    case 'dynamic-datasource':
      icon = <DescriptionIcon />
      break
    case 'automate':
      icon = <SlowMotionVideoIcon />
      break
    case 'reupload':
      icon = <AutorenewIcon />
      break
    case 'clone':
      icon = <FileCopyIcon />
      break
    // case 'info':
    //   icon = <InfoIcon />
    //   break
    case 'group':
      icon = <FormatIndentIncreaseIcon />
      break
    case 'ungroup':
      icon = <FormatIndentDecreaseIcon />
      break
    default:
      icon = null
  }

  return (
    <div
      ref={ref}
      {...rest}
      className={className ? `${className} ${classes.wrapper}` : classes.wrapper}
    >
      {icon}
    </div>
  )
})

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Icon
