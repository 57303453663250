import React, { useMemo, useRef, useState } from 'react'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import { BaseCard as Card } from 'components/CardLayout'
import DatasourceInspector from 'components/DatasourceInspector'
import { DynamicDatasourceQueryModal } from 'components/ResourceModalComponents'
import { Button } from '@material-ui/core'
import { DatasetTableEntry, DatasetTableHeader } from './components'

import './style.scss'

const classes = {
  wrapper: 'DatasourcesPage-wrapper',
  title: 'DatasourcesPage-title',
  subTitle: 'DatasourcesPage-subTitle',
  horizontalScrollDiv: 'DatasourcesPage-horizontalScrollDiv',
  scrollDiv: 'DatasourcesPage-scrollDiv',
}

const getDatasets = (datasets, sort) =>
  datasets
    ?.map(datasource => {
      const {
        name,
        id,
        updated_at: date = 'N/A',
        status = 'unknown',
        spec = {},
      } = datasource
      const { type = 'unknown' } = spec
      return { name, date, type, id, status, original: datasource }
    })
    .sort((a, b) => {
      const aVal = a[sort.column]
      const bVal = b[sort.column]
      let result = 0
      if (typeof aVal === 'string' || typeof bVal === 'string') {
        result = `${aVal}`.localeCompare(`${bVal}`, 'en', { sensitivity: 'base' })
      } else result = aVal - bVal
      return result * (-1) ** (sort.order + 1)
    }) || []

const DatasourcesPage = () => {
  const dynamicModal = useRef()
  const [sort, setSort] = useState({ column: 'name', order: 1 })
  const { datasets: datasources, selectedDataset: selectedDatasetId } = useStore(
    'selectDatasets',
    'selectSelectedDataset'
  )

  const {
    name: resourceName = '',
    id: resourceID = null,
    type: resourceType = 'UPLOADED_DATASET',
  } = { ...(datasources?.find(({ id }) => id === selectedDatasetId) || {}) }

  const dynamics = useMemo(() => {
    const dynamicDS = datasources?.filter(ds => ds.type === 'QUERY_DATASET') || []
    const tempD = getDatasets(dynamicDS, sort)
    return tempD
  }, [datasources, sort])

  return (
    <>
      <DynamicDatasourceQueryModal ref={dynamicModal} />
      <div className={classes.wrapper}>
        <Card>
          <div className={classes.title}>Dynamic datasources</div>
          <span className={classes.subTitle}>
            Dynamic data sources allow to specify training and scoring data from
            database systems, that can update dynamically if an automation is
            specified.
          </span>
          <div className={classes.horizontalScrollDiv}>
            <DatasetTableHeader
              sort={{
                column: sort.column,
                order: sort.order,
                callback: (column, order) => {
                  if (order) {
                    const tempSort = { column, order }
                    setSort(tempSort)
                  } else setSort({ column: null, order })
                },
              }}
            />
            <div className={classes.scrollDiv}>
              {dynamics.map(ds => (
                <DatasetTableEntry
                  key={ds.id}
                  id={ds.id}
                  name={ds.name}
                  type={ds.type}
                  status={ds.status}
                  date={ds.date}
                  original={ds.original}
                  selected={ds.id === selectedDatasetId}
                />
              ))}
            </div>
          </div>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => dynamicModal.current.open()}
          >
            Upload a dynamic dataset
          </Button>
        </Card>
      </div>
      <Conditional dependencies={resourceID}>
        {resourceID && (
          <DatasourceInspector
            title={resourceName}
            resource={resourceID}
            resourceType={resourceType}
          />
        )}
      </Conditional>
    </>
  )
}

export default DatasourcesPage
