/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'

import './UploadColumnStyle.scss'

const classes = {
  wrapper: 'UploadColumnCard-wrapper',
  bar: 'UploadColumnCard-bar',
  gauge: 'UploadColumnCard-gauge',
  gaugeDot: 'UploadColumnCard-gaugeDot',
  rows: 'UploadColumnCard-rows',
  topRow: 'UploadColumnCard-topRow',
  bottomRow: 'UploadColumnCard-bottomRow',
  percentage: 'UploadColumnCard-percentage',
  percentNumber: 'UploadColumnCard-percentNumber',
  percentSymbol: 'UploadColumnCard-percentSymbol',
  uploadStart: 'UploadColumnCard-uploadStart',
  uploadDiscard: 'UploadColumnCard-uploadDiscard',
  actionsDiv: 'UploadColumnCard-actionsDiv',
}

const UploadColumnCard = props => {
  const {
    title,
    progress: propProgress = 0,
    continuous = false,
    status,
    onEntity = () => {},
    uploadFile = () => {},
    removeEntity = () => {},
  } = props

  const progress = Math.round(Math.max(0, Math.min(1, propProgress)) * 100) / 100

  let displayStatus = ''

  let color = 'dodgerblue'

  if (status.search('failed') !== -1) {
    displayStatus = 'Failed!'
    color = '#e4002b'
  } else if (status === 'completed') {
    displayStatus = 'Completed!'
    color = '#08875b'
  } else if (status === 'typifying') {
    displayStatus = 'Typifying...'
    color = '#08875b'
  } else if (status === 'uploaded') {
    displayStatus = 'Uploaded'
    // color = '#08875b'
  } else if (status === 'configurable') displayStatus = 'Click to configure!'
  else displayStatus = `${status.charAt(0).toUpperCase() + status.slice(1)}...`

  const renderType =
    status.search('failed') !== -1 ||
    status === 'completed' ||
    status === 'typifying' ||
    status === 'configurable'

  const gradientID = `uploadColumnGradient-${uuid()}`
  const statusClass =
    status.search('failed') !== -1
      ? ' failed'
      : `${status}` === 'completed'
      ? ' completed'
      : `${status}` === 'configurable'
      ? ' configurable'
      : ''

  return (
    <div
      className={classes.wrapper + statusClass}
      role="button"
      tabIndex={status === 'configurable' ? '0' : ''}
      onClick={() => status === 'configurable' && onEntity()}
      onKeyDown={event => {
        event.keyCode === 13 && status === 'configurable' && onEntity()
      }}
      style={{ cursor: status === 'configurable' ? 'pointer' : '' }}
    >
      <div className={classes.bar}>
        <div className={classes.rows}>
          <div className={classes.topRow}>{title}</div>
          <div
            className={classes.bottomRow}
            style={{
              opacity: renderType ? 1 : '',
            }}
          >
            {displayStatus}
          </div>
        </div>
        <Conditional dependencies={!continuous && !renderType}>
          <div className={classes.percentage}>
            <div className={classes.percentNumber}>
              {`${Math.round(progress * 100)}`}
            </div>
            <div className={classes.percentSymbol}>%</div>
          </div>
        </Conditional>
        <div
          className={classes.gauge}
          style={{
            width: `calc(100% - 10px - ${
              !continuous && !renderType ? '4em' : '10px'
            })`,
          }}
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <defs>
              <radialGradient
                id={gradientID}
                cx={-0.4}
                cy={0.5}
                fx={-0.1}
                fy={0.5}
                r={0.3}
              >
                <stop offset="0%" stopColor={continuous ? 'dodgerblue' : color} />
                <stop offset="100%" stopColor="#171a24" />
                <animate
                  attributeName="cx"
                  from="-0.4"
                  to="1.3"
                  dur="3"
                  begin="0s"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="fx"
                  from="-0.1"
                  to="1.6"
                  dur="3"
                  begin="0s"
                  repeatCount="indefinite"
                />
              </radialGradient>
            </defs>
            <rect
              className={classes.gaugeFill}
              x={0}
              width={`${
                !continuous && !renderType ? Math.round(progress * 100) : 100
              }`}
              y={0}
              height={100}
              fill={!continuous ? color : `url(#${gradientID})`}
            />
          </svg>
        </div>
        <Conditional dependencies={!continuous && !renderType}>
          <div
            className={classes.gaugeDot}
            style={{
              left: `calc(${Math.round(progress * 100)}% + 10px - ${
                !continuous && !renderType
                  ? `${(4 * Math.round(progress * 100)) / 100}em`
                  : `${(10 * Math.round(progress * 100)) / 100}px`
              } - ${(10 * Math.round(progress * 100)) / 100}px - 0.2em)`,
            }}
          />
        </Conditional>
      </div>
      <div className={classes.actionsDiv}>
        <Conditional dependencies={status === 'configurable'}>
          <div
            className={classes.uploadStart}
            role="button"
            tabIndex="0"
            onClick={event => {
              event.stopPropagation()
              uploadFile()
            }}
            onKeyDown={event => {
              event.stopPropagation()
              event.keyCode === 13 && uploadFile()
            }}
          >
            <Icon type="play" />
          </div>
        </Conditional>
        <Conditional
          dependencies={status !== 'configurable' || status !== 'preprocessing'}
        >
          <div
            className={classes.uploadDiscard}
            role="button"
            tabIndex="0"
            onClick={event => {
              event.stopPropagation()
              removeEntity()
            }}
            onKeyDown={event => {
              event.stopPropagation()
              event.keyCode === 13 && removeEntity()
            }}
          >
            <Icon type="close" />
          </div>
        </Conditional>
      </div>
    </div>
  )
}

UploadColumnCard.propTypes = {
  title: PropTypes.string,
  progress: PropTypes.number,
  continuous: PropTypes.bool,
  status: PropTypes.string.isRequired,
  onEntity: PropTypes.func,
  uploadFile: PropTypes.func,
  removeEntity: PropTypes.func,
}

export default UploadColumnCard
