import React from 'react'
import PropTypes from 'prop-types'

import { getLinePath, interpolateLine } from './helpers'

const classes = {
  line: 'CurrencyGraph-line',
}

const Line = props => {
  const { data, bounds } = props

  const interpolated = interpolateLine(data, bounds)

  const path = getLinePath(interpolated)

  return <path className={classes.line} d={path} />
}

Line.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  bounds: PropTypes.shape({
    xMin: PropTypes.number,
    xMax: PropTypes.number,
    yMin: PropTypes.number,
    yMax: PropTypes.number,
  }),
}

export default Line
