import React, { useState } from 'react'

import { BaseCard as Card } from 'components/CardLayout'
import { LineChart, ScatterChart, BarChart } from 'components/Charts'

import './style.scss'

const classes = {
  wrapper: 'ChartsPage-wrapper',
  tabDiv: 'ChartsPage-tabDiv',
  tabButton: 'ChartsPage-tabButton',
}

const DatasetCharts = props => {
  const [state, setState] = useState('line')

  return (
    <div className={classes.wrapper}>
      <div className={classes.tabDiv}>
        <button
          className={classes.tabButton}
          type="button"
          data-active={state === 'line'}
          onClick={event => {
            event.preventDefault()
            setState('line')
          }}
        >
          Line Chart
        </button>
        <button
          className={classes.tabButton}
          type="button"
          data-active={state === 'scatter'}
          onClick={event => {
            event.preventDefault()
            setState('scatter')
          }}
        >
          Scatter Chart
        </button>
        <button
          className={`${classes.tabButton}`}
          type="button"
          data-active={state === 'bar'}
          onClick={event => {
            event.preventDefault()
            setState('bar')
          }}
        >
          Bar Chart
        </button>
      </div>
      <Card style={{ visibility: state === 'line' ? 'visible' : 'hidden' }}>
        <LineChart
          controlable={{
            datasets: { multiple: true },
            stackingCategories: { multiple: true },
            x: { multiple: true },
            y: { multiple: true },
          }}
          legend={{ position: 'right', width: 300 }}
          xAxis
          yAxis
          grid
          mainColor="#1E90FF"
        />
      </Card>
      <Card style={{ visibility: state === 'scatter' ? 'visible' : 'hidden' }}>
        <ScatterChart
          controlable={{
            datasets: { multiple: true },
            stackingCategories: { multiple: true },
            x: { multiple: true },
            y: { multiple: true },
            z: true,
          }}
          legend={{ position: 'right', width: 300 }}
          xAxis
          yAxis
          grid
          mainColor="#1E90FF"
        />
      </Card>
      <Card style={{ visibility: state === 'bar' ? 'visible' : 'hidden' }}>
        <BarChart
          controlable={{
            datasets: { multiple: true },
            stackingCategories: { multiple: true },
            features: { multiple: true },
          }}
          xAxis
          yAxis
          grid
          mainColor="#1E90FF"
        />
      </Card>
    </div>
  )
}

export default DatasetCharts
