import React, { useRef, useState } from 'react'
import { useStore, useHistory } from 'core'

import Modal from 'components/Modal'
import Conditional from 'components/Conditional'
import { BaseCard as Card } from 'components/CardLayout'
import { Table, Column } from 'components/Table'
import { Button } from '@material-ui/core'

import './style.scss'

const classes = {
  wrapper: 'LicencesPage-wrapper',
  section: 'AccountPages-section',
  sectionTitle: 'AccountPages-section-title',
  formDiv: 'AccountPages-formDiv',
  tableDiv: 'LicencesPage-tableDiv',
  modalUsersDiv: 'LicencesPage-modalUsersDiv',
  userWrapper: 'LicencesPage-userWrapper',
  noLicenceMessageWrapper: 'LicencesPage-noLicencesMessageWrapper',
}

const LicencesPage = props => {
  const assignLicenceModalRef = useRef()
  const history = useHistory()
  const [licenceId, setLicenceId] = useState(null)
  const {
    users: storeUsers,
    licences: storeLicences,
    addUserLicence,
    removeUserLicence,
    fetchUsers,
    fetchLicences,
  } = useStore(
    'selectUsers',
    'selectLicences',
    'addUserLicence',
    'removeUserLicence',
    'fetchUsers',
    'fetchLicences'
  )

  const users = storeUsers
  // ?.map(u => ({
  //   ...u,
  //   permissions: u.permissions
  //     ?.filter(p => Object.values(USER_PERMISSIONS).indexOf(p.name) !== -1)
  //     .map(p => p.name),
  // })) || []

  const licences = []
  storeLicences?.forEach(licence => {
    const {
      id,
      licence: {
        // id: key,
        name: type,
      },
      contract: { id: contractId },
    } = licence
    licences.push({
      id,
      type,
      contractId,
      user: { username: licence.user?.username, id: licence.user?.id },
    })
  })

  // console.log(storeLicences?.map(lic => lic.user?.username))

  return (
    <>
      <Modal
        ref={assignLicenceModalRef}
        title="Assign a licence"
        onCancel={() => {
          assignLicenceModalRef.current.close()
        }}
      >
        <div className={classes.modalUsersDiv}>
          {users?.map(user => (
            <Button
              className={classes.userWrapper}
              color="primary"
              onClick={() => {
                addUserLicence(user.id, licenceId)
                fetchUsers()
                fetchLicences()
                setLicenceId(null)
                assignLicenceModalRef.current.close()
              }}
            >
              <span>{user.username}</span>
              <span>{user.email}</span>
            </Button>
          ))}
        </div>
      </Modal>
      <div className={classes.wrapper}>
        <Card>
          <div className={classes.section} style={{ flex: 5 }}>
            <span className={classes.sectionTitle}>
              <span>Licences</span>
              <Conditional dependencies={licences.length}>
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => history.push('/pricing')}
                >
                  Buy Additional Licences
                </Button>
              </Conditional>
            </span>
            {!licences.length ? (
              <div className={classes.noLicenceMessageWrapper}>
                <span>No licences</span>
                <Button variant="contained" color="primary">
                  Buy more
                </Button>
              </div>
            ) : (
              <div className={classes.tableDiv}>
                <Table data={licences}>
                  <Column
                    dataKey="_defaultColumn"
                    render={(value, header) => {
                      const stringValue = `${value}`
                      return header
                        ? stringValue.charAt(0).toUpperCase() + stringValue.slice(1)
                        : value
                    }}
                  />
                  <Column dataKey="id" hidden />
                  <Column dataKey="key" />
                  <Column dataKey="type" />
                  <Column
                    dataKey="contractId"
                    render={(value, header) => {
                      return header ? 'Contract' : value
                    }}
                  />
                  <Column
                    dataKey="user.username"
                    render={(value, header) => {
                      return header ? 'Username' : value
                    }}
                  />
                  <Column dataKey="user.id" hidden />
                  <Column
                    dataKey="_actionsColumn"
                    render={(value, header, record) => {
                      const stringValue = `${value}`
                      return header ? (
                        stringValue.charAt(0).toUpperCase() + stringValue.slice(1)
                      ) : (
                        <Button
                          color="primary"
                          onClick={() => {
                            if (record.user.id) {
                              removeUserLicence(record.user.id, record.id)
                              fetchUsers()
                              fetchLicences()
                            } else {
                              setLicenceId(record.id)
                              assignLicenceModalRef.current.open()
                            }
                          }}
                        >
                          {record.user.id ? 'UNASSIGN' : 'ASSIGN'}
                        </Button>
                      )
                    }}
                  />
                </Table>
              </div>
            )}
          </div>
        </Card>
      </div>
    </>
  )
}

export default LicencesPage
