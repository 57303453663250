/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react'

import PropTypes from 'prop-types'

import invariant from 'tiny-invariant'
import { Provider, Context } from '../FormContext'

const self = 'FormAction'

const FormAction = ({
  component,
  dataKey: fieldKey = '',
  absolute,
  action,
  onClick = true,
  ...rest
}) => {
  const context = useContext(Context)

  invariant(context, `You cannot use the '${self}' component outside of <Form>`)

  const { contextKey, dispatch } = context

  let dataKey = !absolute ? contextKey : ''
  if (dataKey && fieldKey && !/^(\[[0-9]+\])/.test(fieldKey)) dataKey += '.'
  dataKey += fieldKey

  const child = component
  const state = context.get()

  const value = dataKey
    .replace(/(\[|\])/g, '.')
    .split('.')
    .filter(e => e)
    .reduce((o, key, i, arr) => {
      return o && o[key]
    }, state)

  const dispatchAction = () => {
    let result
    if (typeof action === 'function') {
      result = action(value)
      invariant(
        value === undefined || Array.isArray(value) === Array.isArray(result),
        `Component '${self}' expected '${
          Array.isArray(value) ? 'array' : typeof value
        }' but recieved '${
          Array.isArray(result) ? 'array' : typeof result
        }' as a value.`
      )
      invariant(
        value === undefined ||
          (value.constructor === Object) === (result.constructor === Object),
        `Component '${self}' expected '${typeof value} but recieved ${typeof result} as a value.`
      )
      dispatch('merge', dataKey, result)
    } else {
      dispatch(action, dataKey, result)
    }
  }

  const eventHandlers = { ...rest, onClick }
  const handlers = Object.keys(eventHandlers)
    .filter(key => key.match(/^on[A-Z].+/))
    .reduce((acc, key) => {
      const hasEventHandler = typeof eventHandlers[key] === 'function'
      acc[key] = (...params) => {
        let takeAction = false
        if (hasEventHandler) takeAction = eventHandlers[key](...params)
        else if (eventHandlers[key]) takeAction = true
        takeAction && dispatchAction()
      }
      return acc
    }, {})

  return React.createElement(child, { ...rest, ...handlers })
}

FormAction.propTypes = {
  component: PropTypes.elementType,
  dataKey: PropTypes.string,
  absolute: PropTypes.bool,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

export default FormAction
