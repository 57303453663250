/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useReducer,
  useContext,
} from 'react'
import './style.scss'
// import './styleTrainings.scss'
import { useStore, useRouteMatch } from 'core'
import { CardLayout, ModelCard } from 'components/CardLayout'
import Icon from 'components/Icon'
import {
  Form,
  FormItem,
  FormFor,
  FormConditional,
  FormContext,
  FormAction,
} from 'components/Form'

import { TextField, Tooltip } from '@material-ui/core'
import PipelineInspector from 'components/PipelineInspector'
import AlertDialog from 'components/AlertDialog/AlertDialog'

const classes = {
  wrapper: 'Dev-wrapper',
  test: 'Dev-test',
  // ================================
  // formWrapper: 'Trainings-form-wrapper',
  // formHeader: 'form-header separator',
}

const Dev = props => {
  const [open, setOpen] = useState(false)
  const { requestModelExplanation, checkout } = useStore(
    'requestModelExplanation',
    'checkout'
  )

  return (
    <div className={classes.wrapper}>
      <AlertDialog
        open={open}
        onOk={() => console.log('ok')}
        onCancel={() => {
          setOpen(false)
          console.log('cancel')
        }}
      >
        Pera
      </AlertDialog>
      <button type="button" onClick={requestModelExplanation}>
        DO ET
      </button>
      <Tooltip title="Pera">
        <button type="button" onClick={() => setOpen(true)}>
          Open
        </button>
      </Tooltip>
      <button onClick={checkout}>checkout</button>
      {/* <PipelineInspector open={open} setOpen={setOpen} /> */}
    </div>
  )
}

export default Dev
