import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Spinner from 'components/Spinner'
import InfoDialog from 'components/InfoDialog'

import './style.scss'

const classes = {
  wrapper: 'LogsInfoDialog-wrapper',
  content: 'LogsInfoDialog-content',
}

const LogsInfoDialog = forwardRef((props, ref) => {
  const { logs: logsData, title } = props

  const logs = useMemo(() => {
    if (!logsData || logsData.error) return <pre>Error loading logs</pre>
    return (
      <div className={classes.content}>
        {!logsData.data || logsData.isLoading ? (
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
        ) : (
          logsData.data
            .map(e => {
              return {
                message: e.message,
                time: moment(e.created_at).format('D/M/YY h:mm:ss a'),
              }
            })
            .map((e, i) => <pre key={e.created_at}>{`${e.time}: ${e.message}`}</pre>)
        )}
      </div>
    )
  }, [logsData])

  return (
    <InfoDialog
      className={classes.wrapper}
      ref={ref}
      title={title || 'Logs'}
      onBtnClick={() => ref.current.close()}
    >
      {logs}
    </InfoDialog>
  )
})

LogsInfoDialog.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
}

export default LogsInfoDialog
