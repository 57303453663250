import React from 'react'
import Conditional from 'components/Conditional'
import Icon from 'components/Icon'

import { classes } from '../classNames'

const renderer = (
  resourceId,
  resourceMeta,
  columnTypes,
  columnStats,
  sort,
  setSort,
  statsVisible,
  toggleStats,
  context
) => {
  const count = resourceMeta?.count || 0
  const sortKey =
    resourceMeta?.features?.find(feat => feat.id === sort.key)?.name || null
  const sortOrder = sort.order === 'asc' ? 1 : -1

  const maxStatsCount =
    Object.keys(columnStats).reduce(
      (acc, key) => Math.max(columnStats[key].length, acc),
      0
    ) + 1
  const height = 40 * maxStatsCount + 5 * (maxStatsCount - 1) + 9
  //  + 56

  return (key, header) => {
    if (!header) return key
    const stats = [...columnStats[key], { name: 'count', value: count }]
    const missing = stats.find(stat => stat?.name === 'nmissing')?.value
    return (
      <>
        <div
          className={classes.headerSortingCellDiv}
          role="button"
          tabIndex={-1}
          onClick={event => {
            event.stopPropagation()
            const order = sortKey === key ? (sortOrder + 1) % 3 : 1
            const feature = resourceMeta?.features?.find(feat => feat.name === key)
            if (feature && order) {
              const newKey = feature.id
              const newOrder = order === 1 ? 'asc' : 'desc'
              if (newKey || newKey === 0) setSort(newKey, newOrder)
            } else setSort(null, '')
          }}
          onKeyDown={event => {
            event.stopPropagation()
            if (event.keyCode === 13) {
              const order = sortKey === key ? (sortOrder + 1) % 3 : 1
              const feature = resourceMeta?.features?.find(feat => feat.name === key)
              if (feature && order) {
                const newKey = feature.id
                const newOrder = order === 1 ? 'asc' : 'desc'
                if (newKey || newKey === 0) setSort(newKey, newOrder)
              } else setSort(null, '')
            }
          }}
        >
          <div className={classes.textDiv}>
            <div>{key}</div>
            <div>
              <span>
                {`:${
                  resourceMeta?.features?.find(({ name }) => name === key)?.id ?? '?'
                }: (${columnTypes[key]})`}
              </span>
            </div>
          </div>
          <div
            className={
              classes.sortingArrow +
              (sortKey === key && sortOrder === 1 ? ' ascending' : '')
            }
          >
            <Conditional dependencies={sortKey === key}>
              <Icon type="arrow-down" />
            </Conditional>
          </div>
        </div>
        <div
          className={`${classes.dropDownHeader}${statsVisible ? ' visible' : ''}`}
          style={{
            // flexDirection: statsVisible ? 'column-reverse' : '',
            height: statsVisible ? `${height}px` : '',
            // background: statsVisible
            //   ? `linear-gradient(0deg, rgba(23, 26, 36, 1) 0px, rgba(23, 26, 36, 0.9) ${
            //       height - 80
            //     }px, rgba(255,255,255,0) ${height - 45}px)`
            //   : '',
            // cursor: statsVisible ? 'inherit' : '',
            // top: statsVisible ? '0' : '',
          }}
        >
          {stats.map(stat => {
            const { name, value } = stat || {}
            return (
              <div key={`stat:${name}/${value}`} className={classes.stat}>
                {`${name}: ${value}`}
              </div>
            )
          })}
          <div
            className={statsVisible ? 'open' : ''}
            role="button"
            tabIndex={-1}
            style={
              {
                // display: open
                //   ?
                //     'none'
                //   : '',
              }
            }
            onClick={event => {
              event.stopPropagation()
              toggleStats()
            }}
            onKeyDown={() => {}}
          >
            <svg
              className={classes.svgBar}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1 1"
              preserveAspectRatio="none"
            >
              <rect x="0%" y="0%" width="100%" height="100%" fill="#171a24" />
              <rect
                x="0%"
                y="0%"
                width={`${count ? (1 - (missing || 0) / count) * 100 : 100}%`}
                height="100%"
                fill="dodgerblue"
              />
            </svg>
            <Icon type="arrow-down" />
          </div>
        </div>
      </>
    )
  }
}

export default renderer
