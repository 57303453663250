import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useRefRect } from 'libs/utils/hooks'

import { useMouseTracker } from 'components/MouseTracker'

import {
  adjustSegments,
  generateTicks,
  getStaticElements,
  getStepElements,
  getTickPositions,
  ticksToSegments,
} from './helpers'

import './style.scss'

const classes = {
  wrapper: 'Slider-wrapper',
  svg: 'Slider-svg',
  label: 'Slider-label',
  dragableTick: 'Slider-dragableTick',
  inputs: 'Slider-inputs',
}

// eslint-disable-next-line consistent-return
const lt0Validator = (props, propName, componentName) => {
  const prop = props[propName]
  const type = typeof prop
  if (prop) {
    if (type !== 'number')
      return new Error(
        `Failed prop type: Invalid prop \`${propName}\` of type \`${type}\` supplied to \`${componentName}\`, expected \`number\`.`
      )
    if (prop < 0)
      return new Error(
        `Failed prop type: Invalid prop \`${propName}\` of negative value supplied to \`${componentName}\`, expected a positive value.`
      )
  }
  return null
}
// eslint-disable-next-line consistent-return
const valuesValidator = (props, propName, componentName) => {
  const { min, max, fixed, name } = props
  const prop = props[propName]
  lt0Validator(props, propName, componentName)
  if (fixed && !(prop || prop === 0))
    return new Error(
      `Failed prop type: The prop \`${propName}\` is marked as fixed in \`${componentName}\`, but its value is \`${prop}\`.`
    )
  if (min && max) {
    if (min > max)
      return new Error(
        `Failed prop type: Invalid bounds provided for \`${name}\` segment.`
      )
    if (prop < min || prop > max)
      return new Error(
        `Value provided for \`${name}\` segment is out of bounds. D:[${min || 0},${
          max ? `${max}]` : 'infinity)'
        }, value: ${prop}`
      )
  }
  return true
}

const barPadding = 10
const barThickness = 4
const tickRadius = 6
const fontSize = 14

const Slider = props => {
  const [mouseData, setMouseData] = useState({ x: null, mousedown: false })
  const [tickDrag, setTickDrag] = useState({
    dragging: false,
    start: null,
    tick: null,
  })
  const [rect, ref] = useRefRect()
  const {
    segments: propSegments = [],
    // onChange,
    onMouseUp,
    normalized,
    total: propTotal,
    step,
    interchangable,
  } = props

  let { left: absLeft = 0 } = { ...rect }
  const { width = 1, height = 1 } = { ...rect }
  const xMin = 0
  const yMin = 0
  const yRange = 1000
  const ratio = rect ? yRange / height : 1
  const xRange = rect ? width * ratio : yRange
  const viewBox = `${xMin} ${yMin} ${xRange} ${yRange}`
  absLeft += (rect || 0) && ref.current.getBoundingClientRect().left

  const { x: absX, mousedown } = mouseData
  const x = Math.min(Math.max(absX - absLeft, barPadding), width - barPadding)
  const draggingPosition = mousedown
    ? ((x - barPadding) * ratio) / (xRange - 2 * barPadding * ratio)
    : null

  const tempSegments = !Array.isArray(propSegments) ? [propSegments] : propSegments
  // const disabledValues = tempSegments.filter(value => value.disabled)
  const segments = tempSegments.filter(value => !value.disabled)

  const [adjustedSegments, total] = useMemo(
    () => adjustSegments(segments, propTotal, step),
    [segments, propTotal, step]
  )

  const tickData = useMemo(
    () => generateTicks(adjustedSegments, total),
    [adjustedSegments, total]
  )

  // console.table(adjustedSegments)
  // console.log(total)
  // console.table(tickData)

  const staticElements = useMemo(
    () => getStaticElements(rect, ratio, tickData),
    [rect, ratio, tickData]
  )

  let tempTickData =
    draggingPosition || draggingPosition === 0
      ? getTickPositions(tickData, tickDrag.tick, draggingPosition, interchangable)
      : null

  const stepCount = Math.round(total / step)
  const stepElements = step
    ? getStepElements(
        rect,
        ratio,
        stepCount,
        adjustedSegments,
        tempTickData || tickData
      )
    : null

  if (step && tempTickData) {
    const stepNorm = 1 / stepCount
    tempTickData = tempTickData.map(tick => ({
      ...tick,
      value: Math.round(tick.value / stepNorm) * stepNorm,
    }))
  }

  const dragableTicks = (tempTickData || tickData).map(({ value }, i) => (
    <circle
      // eslint-disable-next-line react/no-array-index-key
      key={`dragable-tick-${i}-${value}`}
      className={classes.dragableTick}
      cx={
        ((width - 2 * (barPadding + barThickness / 2)) * value +
          barPadding +
          barThickness / 2) *
        ratio
      }
      cy="50%"
      r={tickRadius * ratio}
      onMouseDown={event => {
        setTickDrag({
          dragging: true,
          start: event.clientX,
          tick: i,
        })
      }}
    />
  ))

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [_, colorBarsData] = (tempTickData || tickData).reduce(
    (acc, { value }, i) => {
      const tempColor = adjustedSegments[i].color
      const tempStart =
        (barPadding +
          (i === 0 ? 0 : barThickness / 2) +
          acc[0] * (width - 2 * (barPadding + barThickness / 2))) *
        ratio
      const tempWidth = Math.max(
        ((value - acc[0]) * (width - 2 * (barPadding + barThickness / 2)) +
          (i === 0 ? barThickness / 2 : 0)) *
          ratio,
        0
      )
      if (tempColor)
        acc[1].push({ start: tempStart, width: tempWidth, color: tempColor })
      acc[0] = value
      if (i === tickData.length - 1) {
        const lastColor = adjustedSegments[i + 1]?.color
        if (lastColor) {
          const lastStart =
            (barPadding +
              barThickness / 2 +
              acc[0] * (width - 2 * (barPadding + barThickness / 2))) *
            ratio
          const lastWidth = Math.max(
            ((1 - acc[0]) *
              (width - 2 * (barPadding + barThickness / 2) + barThickness / 2) +
              barThickness / 2) *
              ratio,
            0
          )
          acc[1].push({ start: lastStart, width: lastWidth, color: lastColor })
        }
      }
      return acc
    },
    [0, []]
  )

  const segmentColors = colorBarsData.map(
    ({ start: rectX, width: rectWidth, color: rectFill }) => (
      <rect
        x={rectX}
        rx={(barThickness / 2) * ratio}
        y={((height - barThickness) / 2) * ratio}
        width={rectWidth}
        height={barThickness * ratio}
        style={{
          fill: rectFill,
          // opacity: 0.5
        }}
      />
    )
  )

  const segmentCenters = (tempTickData || tickData).map(
    ({ value }, i, all) => (value + (i && all[i - 1].value)) / 2
  )
  segmentCenters.push(
    (1 + (tempTickData || tickData)[tickData.length - 1].value) / 2
  )
  const currentSegments = ticksToSegments(
    adjustedSegments,
    tempTickData || tickData,
    total
  )

  const labels = currentSegments.map(({ name, value, color }, i) => (
    <text
      className={classes.label}
      x={
        ((width - 2 * (barPadding + barThickness / 2)) * segmentCenters[i] +
          barPadding +
          barThickness / 2) *
        ratio
      }
      y={yRange / 2 + (-1) ** (i % 2) * tickRadius * 3 * ratio}
      fontSize={fontSize * ratio}
      style={{ color }}
    >
      {/* {normalized
        ? `${name}: ${Math.round((value / total) * 10000) / 100}%`
        : `${name}: ${value} (${Math.round((value / total) * 10000) / 100}%)`} */}

      {normalized
        ? `${Math.round((value / total) * 10000) / 100}`
        : `${value} (${Math.round((value / total) * 10000) / 100}%)`}
    </text>
  ))

  const updateMouseData = useCallback(
    hookMouseData => {
      if (tickDrag.dragging) {
        if (!hookMouseData.mousedown) {
          setTickDrag({ draging: false, startX: null, tick: null })
          setMouseData({ x: null, mousedown: false })
          const callbackData = ticksToSegments(adjustedSegments, tempTickData, total)
          onMouseUp && onMouseUp(callbackData)
        }
        setMouseData({
          x: hookMouseData.x,
          mousedown: hookMouseData.mousedown,
        })
      }
    },
    [tickDrag, onMouseUp, tempTickData, adjustedSegments, total]
  )
  useMouseTracker(hookMouseData => updateMouseData(hookMouseData))

  return (
    <div className={classes.wrapper}>
      <svg
        ref={ref}
        className={classes.svg}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        preserveAspectRatio="none"
      >
        {staticElements}
        {stepElements}
        {segmentColors}
        {labels}
        {dragableTicks}
      </svg>
      {/* <div className={classes.inputs}>
        {currentSegments.map(({ name, value }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <p key={`${i}-${value}`}>
            {normalized
              ? `${name}: ${Math.round((value / total) * 10000) / 100}%`
              : `${name}: ${value} (${Math.round((value / total) * 10000) / 100}%)`}
          </p>
        ))}
      </div> */}
    </div>
  )
}

Slider.propTypes = {
  segments: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: valuesValidator,
        min: lt0Validator,
        max: lt0Validator,
        fixed: PropTypes.bool,
        disabled: PropTypes.bool,
        color: PropTypes.string,
      })
    ),
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: valuesValidator,
      min: lt0Validator,
      max: lt0Validator,
      fixed: PropTypes.bool,
      disabled: PropTypes.bool,
      color: PropTypes.string,
    }),
  ]).isRequired,
  // onChange: PropTypes.func,
  onMouseUp: PropTypes.func,
  normalized: PropTypes.bool,
  total: lt0Validator,
  step: PropTypes.number,
  interchangable: PropTypes.bool,
}

export default Slider
