import { createBundle } from 'core/bundler'
import url from 'url'

/* eslint-disable no-param-reassign */

export default config =>
  createBundle({
    name: 'api',
    reducer: null,
    selectors: null,
    actions: {
      logApi:
        () =>
        (dispatch, { api }) => {
          api('/foo/bar')
        },
    },
    init: store => {
      store.SERVICES = Object.freeze(
        Object.keys(config).reduce((routes, serviceName) => {
          const service = {}
          Object.defineProperty(service, 'path', {
            value: config[serviceName].path,
            writable: false,
          })
          Object.defineProperty(service, 'options', {
            value: Object.freeze({ ...config[serviceName].options }),
            writable: false,
          })
          Object.defineProperty(service, 'authorization', {
            value: Object.freeze({ ...config[serviceName].authorization }),
            writable: false,
          })
          routes[serviceName] = Object.freeze(service)
          return routes
        }, {})
      )
    },
    args: store => {
      const executor = (path, options, service) => {
        if (!path) throw new Error('Path not supplied to the API executor')
        if (!options) throw new Error('Options not supplied to the API executor')

        if (process.env.NODE_ENV === 'development') {
          const parsed = url.parse(path)
          // eslint-disable-next-line no-param-reassign
          path = `https://localhost:${process.env.SERVER_PORT}/${service}${parsed.path}`
        }

        const request = fetch(path, options).then(
          res =>
            res.ok ? res.json() : res.json().then(error => Promise.reject(error)) // errorHandler(res, path, options)
        )
        return request
      }

      //  Make Base API service request wrapper for the backend service
      const api = (path = '/', options = {}, auth) => {
        const resource = `${config.backend.path}${path}`
        const defaultOptions = config.backend.options || null

        const requestOptions = defaultOptions
          ? {
              ...defaultOptions,
              ...options,
              ...{ headers: { ...defaultOptions.headers, ...options.headers } },
              ...{ body: JSON.stringify(options.body) },
            }
          : options
        if (requestOptions.credentials !== 'omit' && config.backend.authorization) {
          let token = ''
          const { type } = config.backend.authorization
          if (config.backend.authorization.source === 'store') {
            token = store[config.backend.authorization.selector]()
          }
          if (token) {
            requestOptions.headers = requestOptions.headers || {}
            requestOptions.headers.Authorization = `${type} ${token}`
          }
        }
        return executor(resource, requestOptions, 'api')
      }
      api.path = config.backend.path
      api.service = 'backend'

      //  Iterate for all other services creating a request wrappers for each
      Object.keys(config).forEach(service => {
        if (service !== 'backend') {
          const serviceHandler = (path = '/', options = {}) => {
            const resource = `${config[service].path}${path}`
            const defaultOptions = config[service].options || null
            const target = config[service].target || service

            const requestOptions = defaultOptions
              ? {
                  ...defaultOptions,
                  ...options,
                  ...{ headers: { ...defaultOptions.headers, ...options.headers } },
                  ...{ body: JSON.stringify(options.body) },
                }
              : options
            if (
              requestOptions.credentials !== 'omit' &&
              config[service].authorization
            ) {
              let token = ''
              const { type } = config[service].authorization
              if (config[service].authorization.source === 'store') {
                token = store[config[service].authorization.selector]()
              }
              if (token) {
                requestOptions.headers = requestOptions.headers || {}
                requestOptions.headers.Authorization = `${type} ${token}`
              }
            }
            return executor(resource, requestOptions, target)
          }
          serviceHandler.path = config[service].path
          serviceHandler.service = service
          api[service] = serviceHandler
        }
      })

      return { api }
    },
  })
