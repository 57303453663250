import React, { useContext, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useRefRect } from 'libs/utils/hooks'
import { generateGridPoints, getViewBox } from 'libs/utils/helpers'
import { generateColors } from 'libs/utils/color'

import Conditional from 'components/Conditional'
import Spinner from 'components/Spinner'
import ChartWrapper from 'components/Charts/ChartWrapper'
import { Context } from './BarChartContext'

import './style.scss'

const classes = {
  wrapper: 'BarChart-BarGraph-wrapper',
  loading: 'BarChart-BarGraph-loading',
  graphSVG: 'BarChart-BarGraph-graphSVG',
  invisBar: 'BarChart-BarGraph-invisBar',
  bar: 'BarChart-BarGraph-bar',
  valueText: 'BarChart-BarGraph-valueText',
  noDataDiv: 'BarChart-BarGraph-noDataDiv',
}

const defaultRangeCount = 40
const barPadding = 5
const rounding = false

const BarGraph = props => {
  const [
    ranges,
    // setRanges
  ] = useState({ count: null, width: null })
  const [rect, ref] = useRefRect()
  const wrapperRef = useRef()
  const {
    data: contextData = [],
    xAxisLabel,
    yAxisLabel,
    type: contextType = 'numeric',
    loading,
  } = useContext(Context)

  const uniques = useMemo(
    () =>
      contextData[0]?.data
        .reduce((acc, cur) => {
          const tempResult = [...acc]
          if (tempResult.indexOf(cur) === -1) tempResult.push(cur)
          return tempResult
        }, [])
        .sort((a, b) => a - b) || [],
    [contextData]
  )

  const {
    xAxis,
    yAxis,
    valueTextSize = 16,
    grid,
    barWidth,
    mainColor,
    color: fill,
  } = props
  const gridSize = (grid !== true && grid) || 30

  const { min: minBarWidth = 32, max: maxBarWidth = 96 } = barWidth || {}

  const [data, domain, rangeCount] = useMemo(() => {
    if (contextData?.length) {
      if (contextType === 'explanation') {
        const tempData = [...(contextData[0]?.data || [])].sort(
          (a, b) => b.value - a.value
        )
        const tempRangeCount = tempData.length
        const tempDomain = {
          x: { min: 0, max: 1000 },
          y: {
            min: 0,
            max: tempData[0]?.value || 1000,
          },
        }
        const xRange = 1000
        const rangeWidth = xRange / tempRangeCount
        const colorArray =
          (tempData.length &&
            mainColor &&
            generateColors(
              mainColor,
              tempData.length,
              0.2 + tempData.length / (tempData.length + 1) / 10
            )) ||
          []
        return [
          tempData.map((datum, i) => {
            return {
              min: i * rangeWidth,
              max: (i + 1) * rangeWidth,
              count: datum.value,
              color: colorArray[i] || fill || 'dodgerblue',
              category: datum.category,
            }
          }),
          tempDomain,
          tempRangeCount,
        ]
      }
      if (contextType === 'numeric') {
        const tempRangeCount =
          ranges.count || Math.min(defaultRangeCount, uniques.length) || 1
        const tempDomain = {}
        tempDomain.x = { min: uniques[0], max: uniques[uniques.length - 1] }
        const xRange = tempDomain.x.max - tempDomain.x.min
        if (!xRange) {
          tempDomain.x.min -= 0.5
          tempDomain.x.max += 0.5
        }
        const rangeWidth = xRange / tempRangeCount || 1
        const tempRanges = []
        for (let i = 0; i < tempRangeCount; i++)
          tempRanges.push({
            min: tempDomain.x.min + i * rangeWidth,
            max: tempDomain.x.min + (i + 1) * rangeWidth,
            count: 0,
          })
        const tempBars =
          contextData[0]?.data.reduce((acc, cur) => {
            const tempResult = [...acc]
            let i = 0
            while (cur >= tempRanges[i]?.min) i++
            tempResult[i - 1].count++
            return tempResult
          }, tempRanges) || []
        const maxCount =
          [...tempBars].sort((a, b) => b.count - a.count)[0]?.count || 0
        tempDomain.y = { min: 0, max: maxCount }
        const colorArray =
          (tempBars.length &&
            mainColor &&
            generateColors(
              mainColor,
              tempBars.length,
              0.2 + tempBars.length / (tempBars.length + 1) / 10
            )) ||
          []
        return [
          tempBars.map((datum, i) => {
            return { ...datum, color: colorArray[i] || fill || 'dodgerblue' }
          }),
          tempDomain,
          tempRangeCount,
        ]
      }
      if (contextType === 'category') {
        const tempRangeCount = uniques.length || 1
        const tempDomain = {
          x: { min: 0, max: 1000 },
        }
        const xRange = 1000
        const rangeWidth = xRange / tempRangeCount
        const tempBars = contextData[0]?.data.reduce((acc, cur) => {
          const tempResult = [...acc]
          const tempBar = tempResult.find(({ category }) => category === cur)
          const barCount = tempResult.length
          if (tempBar) tempBar.count++
          else
            tempResult.push({
              min: barCount * rangeWidth,
              max: (barCount + 1) * rangeWidth,
              count: 1,
              category: cur,
            })
          return tempResult
        }, [])
        const maxCount =
          [...tempBars].sort((a, b) => b.count - a.count)[0]?.count || 0
        tempDomain.y = { min: 0, max: maxCount }
        const colorArray =
          (tempBars.length &&
            mainColor &&
            generateColors(
              mainColor,
              tempBars.length,
              0.2 + tempBars.length / (tempBars.length + 1) / 10
            )) ||
          []
        return [
          tempBars.map((datum, i) => {
            return { ...datum, color: colorArray[i] || fill || 'dodgerblue' }
          }),
          tempDomain,
          tempRangeCount,
        ]
      }
    }
    return [[], { x: {}, y: {} }, 1]
  }, [contextType, ranges.count, uniques, contextData, mainColor, fill])

  /// /////////////// ///
  /// DOMAIN PADDING ///
  /// /////////////// ///
  // const paddingX = contextType === 'numeric' ? 0.05 : 0
  const rangePadding = contextType === 'numeric' ? 1 : 0 // # of ranges of padding left and right
  const paddingX = rangePadding / rangeCount
  const paddingY = 0.1
  const correctedDomain = useMemo(() => {
    const domainXRange = Math.abs(domain.x.max - domain.x.min)
    const domainYRange = Math.abs(domain.y.max - domain.y.min)
    const temp = { x: { min: null, max: null }, y: { min: null, max: null } }
    temp.x.min =
      domain.x.min - paddingX * (domainXRange || Math.abs(domain.x.min) || 1)
    temp.x.max =
      domain.x.max + paddingX * (domainXRange || Math.abs(domain.x.max) || 1)
    temp.y.min =
      domain.y.min - paddingY * (domainYRange || Math.abs(domain.y.min) || 1) * 0 // no Y padding below 0
    temp.y.max =
      domain.y.max + paddingY * (domainYRange || Math.abs(domain.y.max) || 1)
    return temp
  }, [paddingX, domain])
  /// /////////////// ///
  /// VIEWBOX PADDING ///
  /// /////////////// ///

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [viewBox, _, normalizer] = useMemo(() => {
    return getViewBox(correctedDomain, [], rounding)
  }, [correctedDomain])

  const ratios = useMemo(() => {
    const xRatio = (rect && viewBox.xRange / rect.width) || null
    const yRatio = (rect && viewBox.yRange / rect.height) || null
    return { x: xRatio, y: yRatio }
  }, [rect, viewBox.xRange, viewBox.yRange])

  const [bars, invisBars] = useMemo(
    () => [
      data.map(({ min, max, count, color }) => (
        <rect
          key={`Bar-${color}: ${min}-${max}`}
          className={classes.bar}
          x={
            min / normalizer.x +
            (contextType !== 'numeric' ? barPadding * ratios.x : 0)
          }
          width={
            (domain.x.max - domain.x.min) / data.length / normalizer.x -
            (contextType !== 'numeric' ? 2 * barPadding * ratios.x : 0)
          }
          y={-count / normalizer.y}
          height={count / normalizer.y}
          fill={color}
        />
      )),
      data.map(({ min, max, count, color }) => (
        <g key={`invisBar-group-${color}: ${min}-${max}`}>
          <rect
            className={classes.invisBar}
            x={min / normalizer.x}
            width={(domain.x.max - domain.x.min) / data.length / normalizer.x}
            y={Number(Number.parseFloat(-(viewBox.y + viewBox.yRange)).toFixed(4))}
            height={viewBox.yRange}
          />
          <text
            className={classes.valueText}
            x={(min + max) / 2 / normalizer.x}
            y={-count / normalizer.y}
            fontSize={valueTextSize * ratios.y}
            transform={`scale(${ratios.x / ratios.y || 1}, 1)`}
          >
            {Number(count.toFixed(4))}
          </text>
        </g>
      )),
    ],
    [data, domain, normalizer, contextType, ratios, viewBox, valueTextSize]
  )

  const [xAxisPoints, yAxisPoints, xGridPoints, yGridPoints] = useMemo(() => {
    const paddingRange = paddingX / (1 + 2 * paddingX)
    const tempXPoints = [
      paddingRange,
      ...data.map(
        (datum, i) =>
          paddingRange +
          (i + 1) * ((1 - (2 * paddingX) / (1 + 2 * paddingX)) / data.length)
      ),
    ].map(point => ({
      position: point,
      value: (point * viewBox.xRange + viewBox.x) * normalizer.x,
    }))
    const tempYPoints = generateGridPoints(
      viewBox.y,
      viewBox.yRange,
      ratios.y,
      gridSize,
      normalizer.y,
      true
    )[0].map(point => ({
      position: (point - viewBox.y) / viewBox.yRange,
      value: point * normalizer.y,
    }))
    const tempXGrid = tempXPoints.map(point => point.position) || []
    const tempYGrid = tempYPoints.map(point => point.position) || []
    return [tempXPoints, tempYPoints, tempXGrid, tempYGrid]
  }, [paddingX, data, gridSize, ratios, viewBox, normalizer])

  const isThereDataToDisplay = data.length && domain.y.max

  const svgViewBox = {
    x: viewBox.x,
    y: Number(Number.parseFloat(-(viewBox.y + viewBox.yRange)).toFixed(4)),
    xRange: viewBox.xRange,
    yRange: viewBox.yRange,
  }

  return (
    <div className={classes.wrapper}>
      <Conditional dependencies={loading}>
        <div className={classes.loading}>
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
          <span>Fetching data...</span>
        </div>
      </Conditional>
      <Conditional dependencies={isThereDataToDisplay}>
        <ChartWrapper
          ref={wrapperRef}
          xAxis={
            xAxis && {
              ...xAxis,
              points: xAxisPoints,
              label: xAxisLabel,
              type: contextType === 'numeric' ? 'numeric' : 'category',
              categories: data.map(({ category }) => category),
            }
          }
          yAxis={
            yAxis && { label: yAxisLabel || 'Count', ...yAxis, points: yAxisPoints }
          }
          minWidth={data.length * minBarWidth}
          maxWidth={data.length * maxBarWidth}
          grid={(!!grid && { xGridPoints, yGridPoints }) || {}}
        >
          <svg
            ref={ref}
            className={classes.graphSVG}
            style={{
              minWidth: `${data.length * minBarWidth}px`,
              maxWidth: `${data.length * maxBarWidth}px`,
            }}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`${svgViewBox.x} ${svgViewBox.y} ${svgViewBox.xRange} ${svgViewBox.yRange}`}
            preserveAspectRatio="none"
          >
            {bars}
            {invisBars}
          </svg>
        </ChartWrapper>
      </Conditional>
      <Conditional dependencies={!isThereDataToDisplay}>
        <div className={classes.noDataDiv}>
          <div>Bar Graph</div>
          <div>NO DATA</div>
        </div>
      </Conditional>
    </div>
  )
}

BarGraph.propTypes = {
  // legend: PropTypes.shape({
  //   position: PropTypes.oneOf(['left', 'right']),
  //   width: PropTypes.number,
  // }),
  xAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['top', 'bottom']),
      height: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
    }),
    PropTypes.bool,
  ]),
  yAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
    }),
    PropTypes.bool,
  ]),
  valueTextSize: PropTypes.number,
  grid: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  barWidth: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  mainColor: PropTypes.string,
  color: PropTypes.string,
}

export default BarGraph
