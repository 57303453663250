/* eslint-disable no-param-reassign */
import { createBundle } from 'core/bundler'
import { createSelector } from 'create-selector'
import url from 'url'

import EventSource from 'eventsource'
import {
  SSE_CONNECTION_ESTABLISHED,
  SSE_CONNECTION_TERMINATED,
  SSE_CONNECTION_ERROR_RECIEVED,
  USER_LOGGED_OUT,
} from 'core/actiontypes'
import { ServerSentEventProxy, ServerSentEvent } from './ServerSentEventProxy'

const IS_CONNECTED_SELECTOR = 'selectSSEConnected'
const IS_WAITING_RETRY_SELECTOR = 'selectSSEIsWaitingToRetry'
const ERROR_LIST_SELECTOR = 'selectSSEErrorList'
const SHOULD_CONNECT_SELECTOR = 'selectSSEShouldConnect'

/* eslint-disable no-shadow */

const defaultConfig = {
  service: 'message',
  apiPath: '/connect',
  retry: 60000, // one minute before retrying on a closed connection
}

const initialState = {
  connection: null,
  errorTimes: [],
  connectionError: null,
}

let eventProxy

export default config => {
  config = { ...defaultConfig, ...config }
  const sseConnectedSelector = store => !!store.sse.connection
  const errorListSelector = store => store.sse.errorTimes

  const isWaitingToRetrySelector = createSelector(
    errorListSelector,
    'selectAppTime',
    (errorList, appTime) => {
      if (!errorList.length) {
        return false
      }
      return appTime - errorList[errorList.length - 1] < config.retry
    }
  )
  const shouldConnectSelector = createSelector(
    isWaitingToRetrySelector,
    sseConnectedSelector,
    'selectUser',
    (waiting, connected, user) => {
      if (waiting || connected) return false
      return !!user
    }
  )

  const closeStuckConnectionReactor = createSelector(
    store => store.sse.connection,
    ERROR_LIST_SELECTOR,
    (connection, errorList) => {
      if (!connection || errorList.length < 3) return null
      connection && connection.close()
      return { type: SSE_CONNECTION_TERMINATED }
    }
  )

  const noConnectionReactor = createSelector(
    'selectSSEShouldConnect',
    shouldConnect => {
      if (!shouldConnect) return null

      return (dispatch, { store }) => {
        const defaultOptions = store.SERVICES.message.options
        const requestOptions = {
          ...defaultOptions,
          ...config.options,
          ...{
            headers: { ...defaultOptions.headers, ...config.headers },
          },
        }

        // Order is important for priority
        const auth = config.authorization || store.SERVICES.message.authorization
        if (auth) {
          let token = ''
          const { type } = auth
          if (auth.source === 'store') {
            token = store[auth.selector]()
          }
          if (token) {
            requestOptions.headers = requestOptions.headers || {}
            requestOptions.headers.Authorization = `${type} ${token}`
          }
        }

        let path = `${store.SERVICES[config.service].path}${config.apiPath}`
        if (process.env.NODE_ENV === 'development') {
          const parsed = url.parse(path)
          path = `https://localhost:${process.env.SERVER_PORT}/${config.service}${parsed.pathname}`
        }
        const connection = new EventSource(path, requestOptions)
        connection.onerror = error => {
          if (connection.readyState === 2) {
            dispatch({ type: SSE_CONNECTION_TERMINATED })
          } else {
            dispatch({ type: SSE_CONNECTION_ERROR_RECIEVED, payload: error })
          }
        }
        connection.onmessage = e => {
          const { event, payload: data, timestamp } = JSON.parse(e.data)
          // console.log(event, data, timestamp)
          const sse = new ServerSentEvent(event, {
            data,
            timestamp,
            origin: e.origin,
            lastEventId: e.lastEventId,
          })
          // MOVE TO NEW STACK TO AVOID SSE ERROR
          setTimeout(() => {
            eventProxy.dispatchEvent(sse)
          }, 0)
        }
        dispatch({ type: SSE_CONNECTION_ESTABLISHED, payload: connection })
      }
    }
  )

  return createBundle({
    name: 'sse',
    reducer: (state = initialState, { type, payload }) => {
      if (type === SSE_CONNECTION_ESTABLISHED) {
        return {
          ...state,
          connection: payload,
          errorTimes: [],
        }
      }
      if (type === SSE_CONNECTION_TERMINATED || type === USER_LOGGED_OUT) {
        return { ...state, connection: null }
      }
      if (type === SSE_CONNECTION_ERROR_RECIEVED) {
        const errors = [...state.errorTimes, Date.now()]
        return {
          ...state,
          errorTimes: errors,
        }
      }
      return state
    },
    selectors: {
      [IS_CONNECTED_SELECTOR]: sseConnectedSelector,
      [IS_WAITING_RETRY_SELECTOR]: isWaitingToRetrySelector,
      [ERROR_LIST_SELECTOR]: errorListSelector,
      [SHOULD_CONNECT_SELECTOR]: shouldConnectSelector,
      reactNoConnection: noConnectionReactor,
      reactCloseConnection: closeStuckConnectionReactor,
    },
    actions: {},
    init: store => {
      eventProxy = new ServerSentEventProxy(store)
      store.serverEvent = (...args) => {
        if (!args.length) return
        const event = args.length > 1 ? args[0] : 'message'
        const callback = args.length > 1 ? args[1] : args[0]
        eventProxy.addEventListener.call(eventProxy, event, callback)
      }
    },
    args: null,
    middleware: null,
    persist: null,
  })
}
