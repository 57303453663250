import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Context } from './CardContext'

const classes = {
  wrapper: 'CardWrapper-wrapper',
}

const CardWrapper = props => {
  const { cardRef, id, spacing, padding, direction, stretch, children } = props
  const { cardsData, cardRows, justify } = useContext(Context)

  const row = cardRows.findIndex(e => e.cards.indexOf(id) !== -1)
  const column = cardRows[row].cards.indexOf(id)

  const cardCount = cardRows.reduce((acc, cur) => {
    return acc + cur.cards.length
  }, 0)

  const top =
    cardRows
      .slice(0, row)
      .reduce((acc, cur) => acc + cur.height + spacing, padding) || 0
  const index =
    1 + cardRows.slice(0, row).reduce((acc, cur) => acc + cur.cards.length, 0) || 0

  const leadingSpace = cardRows.map(cardRow => {
    let tempSpace = cardRow.space / 2
    switch (justify) {
      case 'left':
        tempSpace = 0
        break
      case 'right':
        tempSpace = cardRow.space
        break
      default:
        break
    }
    return tempSpace
  })

  const left =
    direction !== 'column'
      ? cardRows[row].cards.slice(0, column).reduce((acc, cur) => {
          const card = cardsData.find(e => e.id === cur).ref()
          return (
            acc +
            ((card && card.current && card.current.getBoundingClientRect().width) ||
              0) +
            spacing
          )
        }, (padding || 0) + (direction !== 'row' ? leadingSpace[row] : 0)) || 0
      : padding || 0

  return (
    <div
      ref={cardRef}
      className={classes.wrapper}
      style={{
        top,
        left,
        zIndex: cardCount - index,
        height: direction === 'row' && stretch ? `calc(100% - ${2 * padding}px` : '',
        width:
          direction === 'column' && stretch ? `calc(100% - ${2 * padding}px` : '',
      }}
    >
      {children}
    </div>
  )
}

CardWrapper.propTypes = {
  cardRef: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  spacing: PropTypes.number,
  padding: PropTypes.number,
  direction: PropTypes.string,
  stretch: PropTypes.bool,
  children: PropTypes.node,
}

export default CardWrapper
