import Router from './Base'
import DatasourcesRouter from './DatasourcesRouter'
import ChartsRouter from './ChartsRouter'
import DevRouter from './DevRouter'
import UserSectionRouter from './UserSectionRouter'
// import ProjectsRouter from './ProjectsRouter'

// export { default as DevRouter } from './DevRouter'
// export { default as ProjectsRouter } from './ProjectsRouter'
// export { default as TransformationsRouter } from './TransformationsRouter'
// export { default as UserSectionRouter } from './UserSectionRouter'

export {
  DatasourcesRouter,
  ChartsRouter,
  DevRouter,
  UserSectionRouter,
  //   ProjectsRouter,
}

export default Router
