import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import Modal from 'components/Modal'
import {
  getWidthBasedOnType,
  // getHeightBasedOnType
} from './ConfigModalHelpers'
import DatasetConfig from './BoxConfigs/DatasetConfig'
import JoinConfig from './BoxConfigs/JoinConfig'
import FilterConfig from './BoxConfigs/FilterConfig'
import ImputationConfig from './BoxConfigs/ImputationConfig'
import SanitationConfig from './BoxConfigs/SanitationConfig'
import CalculationConfig from './BoxConfigs/CalculationConfig'
import ResampleConfig from './BoxConfigs/ResampleConfig'
import MappingConfig from './BoxConfigs/MappingConfig'

import 'ConfigModalStyle.scss'

const classes = {
  // wrapper: 'ConfigModal-wrapper',
  content: 'ConfigModal-content',
}

const configContent = (type, config, tempMeta, stackedBy, callback) => {
  const meta = tempMeta?.map(metaObj => metaObj?.features || [])
  let content = null
  switch (type) {
    case 'dataset':
      content = (
        <DatasetConfig
          config={config}
          meta={meta[0]}
          stackedBy={stackedBy}
          callback={callback}
        />
      )
      break
    case 'join':
      content = (
        <JoinConfig
          config={config}
          metaLeft={meta[0]}
          metaRight={meta[1]}
          callback={callback}
        />
      )
      break
    case 'filter':
      content = (
        <FilterConfig
          config={config}
          meta={meta[0]}
          stackedBy={stackedBy}
          callback={callback}
        />
      )
      break
    case 'imputation':
      content = (
        <ImputationConfig
          config={config}
          meta={meta[0] || []}
          dataType={tempMeta[0]?.type}
          callback={callback}
        />
      )
      break
    case 'sanitation':
      content = (
        <SanitationConfig
          config={config}
          meta={meta[0]}
          stackedBy={stackedBy}
          callback={callback}
        />
      )
      break
    case 'calculation':
      content = (
        <CalculationConfig
          config={config}
          meta={meta[0]}
          callback={callback}
          dataType={tempMeta[0]?.type}
        />
      )
      break
    case 'resample':
      content = <ResampleConfig config={config} meta={meta[0]} callback={callback} />
      break
    case 'mapping':
      content = (
        <MappingConfig
          config={config}
          meta={meta[0]}
          stackedBy={stackedBy}
          callback={callback}
        />
      )
      break
    default:
      return null
  }
  return content
}

const ConfigModal = props => {
  const ref = useRef()
  const [stateConfig, setStateConfig] = useState(null)
  const {
    pipelineConfiguringBoxLocation: configuring,
    configurePipelineBox: configure,
    updateBoxConfig: updateConfig,
  } = useStore(
    'selectPipelineConfiguringBoxLocation',
    'configurePipelineBox',
    'updateBoxConfig'
  )
  const {
    type,
    location: stringLocation,
    config: propConfig = {},
    meta = [],
    stackedBy,
  } = props

  const config = stateConfig || propConfig
  const location = stringLocation.split('.').map(string => Number(string))
  const width = getWidthBasedOnType(type)
  // const height = getHeightBasedOnType(type)(config, meta)
  const amIBeingConfigured =
    (configuring && configuring.join('.')) === stringLocation

  useEffect(() => {
    setStateConfig(null)
  }, [configuring])

  return (
    <Modal
      ref={ref}
      open={amIBeingConfigured}
      title={`Configure ${type.charAt(0).toUpperCase() + type.slice(1)} Box`}
      onCancel={() => {
        setStateConfig(null)
        configure({ location: null })
      }}
      onClose={() => {
        setStateConfig(null)
        configure({ location: null })
      }}
      onOk={() => {
        setStateConfig(null)
        configure({ location: null })
        updateConfig({
          location,
          config: { ...config },
        })
      }}
      okText="Save"
      footer
    >
      <div
        className={classes.content}
        style={{
          width: width ? `${width}px` : '',
          // height: height ? `${height}px` : '',
        }}
      >
        {configContent(type, config, meta, stackedBy, c => setStateConfig({ ...c }))}
      </div>
    </Modal>
  )
}

ConfigModal.propTypes = {
  type: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  config: PropTypes.object,
  meta: PropTypes.arrayOf(PropTypes.object),
  stackedBy: PropTypes.number,
}

export default ConfigModal
