import { useMemo } from 'react'

export const useInteractiveClick = (handler, role) => {
  return useMemo(() => {
    return {
      onClick: (e, ...args) => {
        e.stopPropagation()
        e.preventDefault()
        handler.apply(null, [e, ...args])
      },
      onKeyDown: (e, ...args) => {
        if (e.keyCode === 13) {
          e.stopPropagation()
          e.preventDefault()
          handler.apply(null, [e, ...args])
        }
      },
    }
  }, [handler])
}
