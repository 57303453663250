import { createBundle } from 'core/bundler'
import {
  USER_PREFS_TABLE_INIT,
  USER_PREFS_TABLE_UPDATED,
  USER_PREFS_UPDATED,
} from '../../core/actiontypes'

/* eslint-disable no-underscore-dangle */

const initialState = {
  theme: 'dark',
}

export default config =>
  createBundle({
    name: 'userPrefs',
    reducer: (state = initialState, { type, payload }) => {
      if (type === USER_PREFS_TABLE_INIT) {
        if (!state[payload.prefKey]) {
          return { ...state, [payload.prefKey]: payload.data }
        }
      }
      if (type === USER_PREFS_TABLE_UPDATED) {
        const obj = { ...state[payload.prefKey] }
        const payloadDataKeys = Object.keys(payload.data)
        payloadDataKeys.forEach(pdk => {
          if (pdk === '_order') {
            const currentOrder = [...obj._order].filter(key => key !== payload.key)
            currentOrder.splice(payload.data._order, 0, payload.key)
            obj._order = currentOrder
          } else {
            obj[payload.key] = { ...obj[payload.key], [pdk]: payload.data[pdk] }
          }
        })
        return { ...state, [payload.prefKey]: { ...obj } }
      }
      if (type === USER_PREFS_UPDATED) {
        return { ...state, [payload.prefKey]: payload.data }
      }
      return state
    },
    selectors: {
      selectPref: state => state.userPrefs,
      selectTheme: state => state.userPrefs.theme,
    },
    actions: {
      updatePref: payload => ({ type: USER_PREFS_UPDATED, payload }),
      updateTablePref: payload => ({ type: USER_PREFS_TABLE_UPDATED, payload }),
      setTableDefault: payload => ({ type: USER_PREFS_TABLE_INIT, payload }),
    },
    init: null,
    args: null,
    middleware: null,
    persist: [USER_PREFS_TABLE_INIT, USER_PREFS_TABLE_UPDATED, USER_PREFS_UPDATED],
  })
