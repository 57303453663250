/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useRef } from 'react'
import PropTypes from 'prop-types'

import Conditional from 'components/Conditional'
import SearchIcon from '@material-ui/icons/Search'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor'
import { XAxis, YAxis, CategoryXAxis } from 'components/Charts/Axis'
import GraphGrid from './GraphGrid'
import GraphOverlay from './GraphOverlay'

import './style.scss'

const classes = {
  wrapper: 'ChartWrapper-wrapper',
  horizontalAligner: 'ChartWrapper-horizontalAligner',
  graphDiv: 'ChartWrapper-graphDiv',
  gridWrapper: 'GraphGrid-wrapper',
  footer: 'ChartWrapper-footer',
}

const ChartWrapper = forwardRef((props, ref) => {
  const gridRef = useRef()
  const graphRef = useRef()
  // const overlayRef = useRef()
  const {
    xAxis,
    yAxis,
    grid,
    zoomCallback,
    footer,
    width,
    minWidth,
    maxWidth,
    height,
    children,
  } = props

  const {
    type = 'numeric',
    height: xAxisHeight = 96,
    position: xAxisPosition = 'bottom',
  } = xAxis || {}
  const { width: yAxisWidth = 64, position: yAxisPosition = 'left' } = yAxis || {}

  const overlayRef = ref?.current?.scrollDivRef

  return (
    <div
      className={classes.wrapper}
      style={{
        width: width && `${width}px`,
        height: height && `${height}px`,
        flexDirection: xAxisPosition === 'top' ? 'column-reverse' : '',
      }}
    >
      <div
        className={classes.horizontalAligner}
        style={{
          flexDirection: yAxisPosition === 'right' ? 'row-reverse' : '',
        }}
      >
        <Conditional dependencies={yAxis}>
          <YAxis {...yAxis} mirror={yAxisPosition === 'right'} width={yAxisWidth} />
        </Conditional>
        <Conditional dependencies={grid}>
          <div
            ref={gridRef}
            className={classes.gridWrapper}
            style={{
              width: yAxis ? `calc(100% - ${yAxisWidth}px)` : '',
              marginLeft:
                yAxis && yAxisPosition !== 'right' ? `${yAxisWidth}px` : '',
            }}
          >
            <GraphGrid
              style={{
                minWidth: minWidth ? `${minWidth}px` : '',
                maxWidth: maxWidth ? `${maxWidth}px` : '',
              }}
              grid={grid}
            />
          </div>
        </Conditional>
        <div ref={graphRef} className={classes.graphDiv}>
          {children}
        </div>
        <GraphOverlay
          ref={ref}
          zoomCallback={zoomCallback}
          style={{
            width: yAxis ? `calc(100% - ${yAxisWidth}px)` : '',
            marginLeft: yAxis && yAxisPosition !== 'right' ? `${yAxisWidth}px` : '',
          }}
          minWidth={minWidth}
          maxWidth={maxWidth}
        />
      </div>
      <Conditional dependencies={xAxis}>
        <Conditional dependencies={type === 'numeric'}>
          <XAxis
            {...xAxis}
            mirror={xAxisPosition === 'top'}
            style={{
              marginLeft:
                yAxis && yAxis.position !== 'right' ? `${yAxisWidth}px` : '',
              marginRight:
                yAxis && yAxis.position === 'right' ? `${yAxisWidth}px` : '',
              width: yAxis ? `calc(100% - ${yAxisWidth}px)` : '',
              overflowX: minWidth ? 'auto' : '',
            }}
            minWidth={minWidth}
            maxWidth={maxWidth}
            onScroll={left => {
              gridRef.current.scrollTo(left, 0)
              graphRef.current.scrollTo(left, 0)
              overlayRef.current.scrollTo(left, 0)
            }}
            height={xAxisHeight}
          />
        </Conditional>
        <Conditional dependencies={type === 'category'}>
          <CategoryXAxis
            {...xAxis}
            mirror={xAxisPosition === 'top'}
            style={{
              marginLeft:
                yAxis && yAxis.position !== 'right' ? `${yAxisWidth}px` : '',
              marginRight:
                yAxis && yAxis.position === 'right' ? `${yAxisWidth}px` : '',
              width: yAxis ? `calc(100% - ${yAxisWidth}px)` : '',
            }}
            minWidth={minWidth}
            maxWidth={maxWidth}
            onScroll={left => {
              gridRef.current.scrollTo(left, 0)
              graphRef.current.scrollTo(left, 0)
              overlayRef.current.scrollTo(left, 0)
            }}
            height={xAxisHeight}
          />
        </Conditional>
      </Conditional>
      <Conditional dependencies={footer}>
        <div className={classes.footer}>
          <SearchIcon />
          {`(${footer?.currentZoom?.x}%, ${footer?.currentZoom?.y}%)`}
          <Conditional
            dependencies={
              footer?.currentZoom?.x > 100 || footer?.currentZoom?.y > 100
            }
          >
            <button type="button" onClick={footer?.zoomOutCallback}>
              <ZoomOutIcon />
            </button>
          </Conditional>
          <Conditional
            dependencies={
              footer?.currentZoom?.x > 100 || footer?.currentZoom?.y > 100
            }
          >
            <button type="button" onClick={footer?.zoomResetCallback}>
              <YoutubeSearchedForIcon />
            </button>
          </Conditional>
        </div>
      </Conditional>
    </div>
  )
})

ChartWrapper.propTypes = {
  xAxis: PropTypes.shape({
    type: PropTypes.oneOf(['numeric', 'category']),
    position: PropTypes.oneOf(['top', 'bottom']),
    points: PropTypes.arrayOf(
      PropTypes.shape({ position: PropTypes.number, value: PropTypes.number })
    ),
    label: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    fontSize: PropTypes.number,
    labelSize: PropTypes.number,
    fontSpacing: PropTypes.number,
    fontMargin: PropTypes.number,
    labelMargin: PropTypes.number,
    tickLength: PropTypes.number,
    render: PropTypes.func,
    style: PropTypes.object,
  }),
  yAxis: PropTypes.shape({
    position: PropTypes.oneOf(['left', 'right']),
    points: PropTypes.arrayOf(
      PropTypes.shape({ position: PropTypes.number, value: PropTypes.number })
    ),
    label: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    fontSize: PropTypes.number,
    labelSize: PropTypes.number,
    fontSpacing: PropTypes.number,
    fontMargin: PropTypes.number,
    labelMargin: PropTypes.number,
    tickLength: PropTypes.number,
    render: PropTypes.func,
    style: PropTypes.object,
  }),
  grid: PropTypes.shape({
    xGridPoints: PropTypes.arrayOf(PropTypes.number),
    yGridPoints: PropTypes.arrayOf(PropTypes.number),
  }),
  zoomCallback: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  footer: PropTypes.shape({
    zoomOutCallback: PropTypes.func,
    zoomResetCallback: PropTypes.func,
    currentZoom: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }),
  width: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default ChartWrapper
