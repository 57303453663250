import { createSelector } from 'create-selector'
import { createBundle } from 'core/bundler'
import {
  LOGS_FETCH_STARTED,
  LOGS_FETCH_SUCCEEDED,
  LOGS_FETCH_FAILED,
  LOGS_CLEARED,
  USER_LOGGED_OUT,
} from 'core/actiontypes'

const initialState = []

const initialLog = {
  id: null,
  data: null,
  loading: false,
  lastSuccess: null,
  error: null,
  errorTimes: [],
}

export default config => {
  const logsSelector = (state, id) => {
    const { logs } = state
    if (!id) return logs
    return logs.find(e => e.id === id) || null
  }
  const logsDataSelector = (state, id) => {
    const logs = logsSelector(state, id)
    const data = logs?.data || null
    if (id) return data
    return logs
  }
  const logsDataIsLoadingSelector = (state, id) => {
    const isLoading = logsSelector(state, id)?.isLoading
    return isLoading !== undefined ? isLoading : null
  }

  return createBundle({
    name: 'logs',
    reducer: (state = initialState, { type, payload, error, merge }) => {
      if (type === LOGS_FETCH_STARTED) {
        return [
          ...state.filter(e => e.id !== payload.id),
          { ...initialLog, id: payload.id },
        ]
      }
      if (type === LOGS_FETCH_SUCCEEDED) {
        return state.map(log => {
          if (log.id !== payload.id) return log
          return {
            id: log.id,
            data: payload.data,
            loading: false,
            lastSuccess: null,
            error: null,
            errorTimes: [],
          }
        })
      }
      if (type === LOGS_FETCH_FAILED) {
        return state.map(log => {
          if (log.id !== payload.id) return log
          return {
            id: log.id,
            loading: false,
            error,
            errorTimes: [...log.errorTimes, Date.now()],
          }
        })
      }
      if (type === LOGS_CLEARED) {
        return state.filter(e => e.id !== payload.id)
      }
      if (type === USER_LOGGED_OUT) {
        return initialState
      }
      return state
    },
    selectors: {
      selectLogs: logsSelector,
      selectLogsData: logsDataSelector,
      selectLogsDataIsLoading: logsDataIsLoadingSelector,
    },
    actions: {
      fetchTrainingLogs:
        resourceID =>
        (dispatch, { api }) => {
          dispatch({
            type: LOGS_FETCH_STARTED,
            payload: { id: resourceID },
          })
          api(`/trainings/${resourceID}/logs`)
            .then(data => {
              dispatch({
                type: LOGS_FETCH_SUCCEEDED,
                payload: { id: resourceID, data },
              })
            })
            .catch(error => {
              dispatch({
                type: LOGS_FETCH_FAILED,
                payload: { id: resourceID, error },
              })
            })
        },
      fetchScoringLogs:
        resourceID =>
        (dispatch, { api }) => {
          dispatch({ type: LOGS_FETCH_STARTED, payload: { id: resourceID } })
          api(`/scorings/${resourceID}/logs`)
            .then(data => {
              dispatch({
                type: LOGS_FETCH_SUCCEEDED,
                payload: { id: resourceID, data },
              })
            })
            .catch(error => {
              dispatch({
                type: LOGS_FETCH_FAILED,
                payload: { id: resourceID, error },
              })
            })
        },
    },
    init: null,
    args: null,
    middleware: null,
    persist: config.persist ? [LOGS_FETCH_SUCCEEDED, LOGS_CLEARED] : null,
  })
}
