import React, { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { useRefRect } from 'libs/utils/hooks'
// import { isObject } from 'libs/utils/helpers'
import { invertColor, getColorFromGradient } from 'libs/utils/color'

import Conditional from 'components/Conditional'
// import { XAxis, YAxis, CategoryAxis } from 'components/Charts/Axis'
import { Context } from './ConfusionMatrixChartContext'

import './style.scss'

const classes = {
  wrapper: 'ConfusionMatrixChart-ConfusionMatrixSimpleGraph-wrapper',
  blurOverlayDiv: 'ConfusionMatrixChart-ConfusionMatrixSimpleGraph-blurOverlayDiv',
  rect: 'ConfusionMatrixChart-ConfusionMatrixSimpleGraph-rect',
  valueText: 'ConfusionMatrixChart-ConfusionMatrixSimpleGraph-valueText',
}

const viewBoxRange = 1000
// const fontSize = 10

const ConfusionMatrixSimpleGraph = props => {
  // const [hovered, setHovered] = useState(null)
  // const [rect, ref] = useRefRect()
  const { data = [] } = useContext(Context)

  const { title: propTitle, mainColor = '#ffffff' } = props
  const titles = propTitle.split(' - ')
  const invertedColor = invertColor(mainColor)
  // const { width = 1, height = 1 } = rect || {}

  // const matrixHeight = Math.max(Math.min(width, height), 0)
  // const matrixWidth = matrixHeight
  // const rectRatio = width / height
  const viewBox = {
    x: 0,
    y: 0,
    xRange: viewBoxRange,
    yRange: viewBoxRange,
  }

  // let viewBoxRatio = 1
  // if (rectRatio > 1) viewBoxRatio = viewBox.yRange / height
  // else viewBoxRatio = viewBox.xRange / width

  const rects = useMemo(() => {
    const squareSize = (data.length && viewBoxRange / data.length) || 0
    return data.reduce((acc, row, i) => {
      const maxRange = row.reduce((sum, cur) => sum + cur)
      const rowSquares = row.map((cell, j) => {
        const relativeValue = cell / maxRange
        const color = getColorFromGradient(
          [
            { color: mainColor, position: 0 },
            { color: invertedColor, position: 1 },
          ],
          relativeValue
        )
        const id = uuid()
        return (
          <g
            // eslint-disable-next-line react/no-array-index-key
            key={`ConfusionMatrix-square-${id}-${i}-${j}`}
            className={classes.squareGroup}
          >
            <rect
              // key={`ConfusionMatrix-square-${id}`}
              className={classes.rect}
              x={`${j * squareSize}`}
              y={`${i * squareSize}`}
              width={`${squareSize}`}
              height={`${squareSize}`}
              fill={color}
            />
            {/* <text
              // key={`ConfusionMatrix-valueText-${id}`}
              className={classes.valueText}
              x={`${j * squareSize + squareSize / 2}`}
              y={`${i * squareSize + squareSize / 2}`}
              fontSize={fontSize * viewBoxRatio}
              textAnchor="middle"
              alignmentBaseline="middle"
            >
              {cell}
            </text> */}
          </g>
        )
      })
      acc.push(...rowSquares)
      return acc
    }, [])
  }, [
    data,
    // viewBoxRatio,
    mainColor,
    invertedColor,
  ])

  return (
    <Conditional dependencies={rects.length}>
      <svg
        // ref={ref}
        className={classes.wrapper}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.xRange} ${viewBox.yRange}`}
        preserveAspectRatio="xMidYMid meet"
      >
        {rects}
      </svg>
      <div className={classes.blurOverlayDiv}>
        {titles.map(title => (
          <span key={title}>{title}</span>
        ))}
      </div>
    </Conditional>
  )
}

ConfusionMatrixSimpleGraph.propTypes = {
  title: PropTypes.string,
  mainColor: PropTypes.string,
}

export default ConfusionMatrixSimpleGraph
