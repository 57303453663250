import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import { formatDate } from 'libs/utils/helpers'

import Icon from 'components/Icon'
import { SimpleResourceDialog } from 'components/ResourceModalComponents'
import { Tooltip } from '@material-ui/core'

import './style.scss'
import { useMemo } from 'use-memo-one'

const classes = {
  wrapper: 'TransformationTableEntry-wrapper',
  cell: 'TransformationTableEntry-cell',
  name: 'TransformationTableEntry-name',
  date: 'TransformationTableEntry-date',
  status: 'TransformationTableEntry-status',
  actions: 'TransformationTableEntry-actions',
  actionButton: 'TransformationTableEntry-actionButton',
}

const TransformationTableEntry = props => {
  const dialogRef = useRef()
  const { removeTransformation, saveTransformation, setActivePipeline } = useStore(
    'removeTransformation',
    'saveTransformation',
    'setActivePipeline'
  )

  const { setSelectedTransformation } = useStore('setSelectedTransformation')

  const { id, name = '', date = '', original = {}, selected } = props

  const [onClick, onDoubleClick] = useMemo(() => {
    // const timer = null
    const onSave = (_, spec, datasets) => {
      saveTransformation({
        id,
        spec,
        datasets,
      })
    }
    const tempSingle = () =>
      // event
      {
        // event.persist()
        // timer = setTimeout(() => {
        //   setSelectedTransformation(id)
        // }, 300)
        setSelectedTransformation(id)
      }
    const tempDouble = () => {
      setActivePipeline(
        id,
        original.spec,
        original.datasets,
        onSave,
        null,
        true,
        name
      )
      // clearTimeout(timer)
    }
    return [tempSingle, tempDouble]
  }, [
    id,
    name,
    original.spec,
    original.datasets,
    saveTransformation,
    setActivePipeline,
    setSelectedTransformation,
  ])

  return (
    <>
      <SimpleResourceDialog
        ref={dialogRef}
        titleText="Do you want to delete this transformation?"
        contentText="This action will permanently delete this transformation."
        okFunction={() => removeTransformation(id)}
      />
      <div
        className={`${classes.wrapper}${selected ? ' selected' : ''}`}
        role="button"
        tabIndex={0}
        onDoubleClick={onDoubleClick}
        onKeyDown={event => {
          if (event.keyCode === 13) onClick(event)
        }}
        onClick={onClick}
      >
        <Tooltip title={name}>
          <div className={`${classes.cell} ${classes.name}`}>
            <span>{name}</span>
          </div>
        </Tooltip>
        <div className={`${classes.cell} ${classes.date}`}>
          <span>{formatDate(date)}</span>
        </div>
        <div className={`${classes.cell} ${classes.actions}`}>
          <button
            type="button"
            className={classes.actionButton}
            onClick={event => {
              event.stopPropagation()
              dialogRef.current.open()
            }}
            onKeyDown={event => {
              if (event.keyCode === 13) {
                event.stopPropagation()
                dialogRef.current.open()
              }
            }}
          >
            <Icon type="trash" />
          </button>
        </div>
      </div>
    </>
  )
}

TransformationTableEntry.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  date: PropTypes.string,
  original: PropTypes.object,
  selected: PropTypes.bool,
}

export default TransformationTableEntry
