/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react'

import PropTypes from 'prop-types'

import invariant from 'tiny-invariant'
import { Context } from '../FormContext'

const self = 'FormConditional'

const FormConditional = ({
  children,
  dataKey: fieldKey = '',
  absolute,
  value = check => !!check,
}) => {
  const context = useContext(Context)

  invariant(context, `You cannot use the '${self}' component outside of <Form>`)

  const { contextKey } = context

  let dataKey = !absolute ? contextKey : ''
  if (dataKey && fieldKey && !/^(\[[0-9]+\])/.test(fieldKey)) dataKey += '.'
  dataKey += fieldKey

  // const child = React.Children.only(children)
  const state = context.get()

  const check = dataKey
    .replace(/(\[|\])/g, '.')
    .split('.')
    .filter(e => e !== '')
    .reduce((o, key, i, arr) => {
      return o && o[key]
    }, state)

  const dependency = typeof value === 'function' ? value(check) : check === value

  return dependency ? children : null
}

FormConditional.propTypes = {
  children: PropTypes.node,
  dataKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  absolute: PropTypes.bool,
}

export default FormConditional
