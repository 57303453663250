/* eslint-disable camelcase */
import React, { useMemo, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'

import { Dialog, Button, TextField, MenuItem } from '@material-ui/core'
import MinimizeIcon from '@material-ui/icons/Close'
import Spinner from 'components/Spinner'
import Modal from 'components/Modal'
import AlertDialog from 'components/AlertDialog'
import TrainingWizardStepOne, { validator as step1Validator } from './steps/1'
import TrainingWizardStepTwo, { validator as step2Validator } from './steps/2'
import TrainingWizardStepThree, { validator as step3Validator } from './steps/3'

import './style.scss'
import { classes } from './classNames'

const TrainingWizard = props => {
  const {
    activeTraining,
    activePipeline,
    // assets,
    closeTrainingWizard,
    discardActiveTraining,
    rejectTrainingChange,
    trainingWizardPrevStep,
    trainingWizardNextStep,
    trainingWizardStartTraining,
    // setTrainingWizardSpec,
  } = useStore(
    'selectActiveTraining',
    'selectActivePipeline',
    // 'selectAssets',
    'closeTrainingWizard',
    'discardActiveTraining',
    'rejectTrainingChange',
    'trainingWizardPrevStep',
    'trainingWizardNextStep',
    'trainingWizardStartTraining'
    // 'setTrainingWizardSpec'
  )

  // const modalRef = useRef()
  const { open, title, step, id, pending, /* spec, */ starting } = activeTraining
  // const {
  //   no_cpus,
  //   machine_id,
  // } = spec
  const discardWarning = !!(id && pending)

  const isPipelineManagerConnected = activeTraining.id === activePipeline.id
  const step1Complete = step1Validator(activeTraining)
  const step2Complete = step2Validator(activeTraining)
  const step3Complete = step3Validator(activeTraining)

  const prevDisabled = isPipelineManagerConnected ? true : step === 0
  const nextText = step === 2 ? 'Finish' : 'Next'
  const nextDisabled = useMemo(() => {
    if (isPipelineManagerConnected || starting) return true
    if (step3Complete) return false
    if (step2Complete && step < 2) return false
    if (step1Complete && step < 1) return false
    return true
  }, [
    isPipelineManagerConnected,
    step,
    step1Complete,
    step2Complete,
    step3Complete,
    starting,
  ])

  const nextCallback = useCallback(() => {
    if (step !== 2) trainingWizardNextStep()
    // else modalRef.current.open()
    else trainingWizardStartTraining()
  }, [step, trainingWizardNextStep, trainingWizardStartTraining])

  // const availableMachines = ['cx31', 'cx41', 'cx51']

  return (
    <>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={open}
        onClose={closeTrainingWizard}
      >
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <div className={classes.title}>{title || 'Training Wizard'}</div>
            <Button
              className={classes.closeButton}
              type="button"
              onClick={closeTrainingWizard}
            >
              <MinimizeIcon />
            </Button>
          </div>
          <div className={classes.content}>
            <div className={classes.stepsWrapper}>
              <TrainingWizardStepOne training={activeTraining} />
              <TrainingWizardStepTwo training={activeTraining} />
              <TrainingWizardStepThree training={activeTraining} />
            </div>
            <div
              className={classes.overlayWrapper}
              data-active={isPipelineManagerConnected}
            />
          </div>
          <div className={classes.footer}>
            <Button onClick={discardActiveTraining}>Discard</Button>
            <div>
              <Button disabled={prevDisabled} onClick={trainingWizardPrevStep}>
                Previous
              </Button>
              <Button color="primary" disabled={nextDisabled} onClick={nextCallback}>
                {nextText}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <AlertDialog
        open={discardWarning}
        title="Discard current training?"
        content="You currently have an active training. Do you wish to discard it?"
        okText="Discard"
        cancelText="Cancel"
        onOk={event => {
          event.stopPropagation()
          event.preventDefault()
          discardActiveTraining()
        }}
        onCancel={event => {
          event.stopPropagation()
          rejectTrainingChange()
        }}
      />
      {/* <Modal
        ref={modalRef}
        title="CPU configuration"
        footer
        okText="Start"
        cancelText="Cancel"
        onOk={() => {
          modalRef.current.close()
          trainingWizardStartTraining()
        }}
        onCancel={() => modalRef.current.close()}
      >
        <div className={classes.cpuModal}>
          <TextField
            // eslint-disable-next-line react/no-array-index-key
            select
            value={machine_id}
            fullWidth
            label="Choose a machine"
            onChange={event => {
              setTrainingWizardSpec({ machine_id: event.target.value })
            }}
          >
            {assets.map(({ id: assetId, machine_type, name, cpu_count, ram }) => (
              <MenuItem
                key={assetId}
                value={assetId}
                disabled={availableMachines.indexOf(name) === -1}
              >
                {`${machine_type} - ${name} (CPUs: ${cpu_count} / RAM: ${ram})`}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </Modal> */}
    </>
  )
}

TrainingWizard.propTypes = {}

export default TrainingWizard
