/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import { formatDate } from 'libs/utils/helpers'
import { useInteractiveClick } from 'libs/interactive'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import {
  DeleteScoringModal,
  SimpleResourceDialog,
} from 'components/ResourceModalComponents'
import { Tooltip } from '@material-ui/core'
import ScoringDetails from './ScoringDetails'

import './style.scss'

const classes = {
  wrapper: 'ScoringTableEntry-wrapper',
  scoringDiv: 'ScoringTableEntry-scoringDiv',
  scoredDatasourcesDiv: 'ScoringTableEntry-scoredDatasourcesDiv',
  scoredDatasource: 'ScoringTableEntry-scoredDatasource',
  cell: 'ScoringTableEntry-cell',
  error: 'ScoringTableEntry-error',
  name: 'ScoringTableEntry-name',
  date: 'ScoringTableEntry-date',
  status: 'ScoringTableEntry-status',
  actions: 'ScoringTableEntry-actions',
  actionButton: 'ScoringTableEntry-actionButton',
}

const ScoringTableEntry = props => {
  const [tempOpen, setOpen] = useState(false)
  const [scoredDatasetId, setScoreDatasetId] = useState(null)
  const deleteModalRef = useRef()
  const deleteScoredDatasetRef = useRef()
  // const deleteScoringRef = useRef()
  const {
    selectedScoredDataset: selectedScoredDatasetId,
    deleteScoredDataset,
    removeScoring,
    setSelectedScoredDataset,
    setSelectedScoring,
  } = useStore(
    'selectSelectedScoredDataset',
    'deleteScoredDataset',
    'removeScoring',
    'setSelectedScoredDataset',
    'setSelectedScoring'
  )
  const {
    id,
    name = '',
    // status = '',
    date = '',
    original = {},
    selected,
    sort,
  } = props

  const open = tempOpen && selected

  const { datasets = [] } = { ...original }

  const scoringHandler = useCallback(
    event => {
      event.stopPropagation()
      if (datasets.length > 0) {
        setOpen(!open)
        if (!open) setSelectedScoring(id)
      }
      // else if (datasets.length === 1) setSelectedScoredDataset(datasets[0].id)
      else setSelectedScoring(id)
    },
    [
      datasets,
      open,
      // setSelectedScoredDataset,
      setSelectedScoring,
      id,
    ]
  )
  const { onClick, onKeyDown } = useInteractiveClick(scoringHandler)

  return (
    <>
      {/* <DeleteScoringModal ref={deleteModalRef} selectedScoring={original} /> */}
      <SimpleResourceDialog
        ref={deleteModalRef}
        titleText="Do you want to delete this scoring?"
        contentText="This action will permanently delete this scoring and it's datasets."
        okFunction={() => {
          removeScoring({ id })
          datasets.forEach(({ id: sDsId }) => deleteScoredDataset(sDsId))
          setScoreDatasetId(null)
        }}
      />
      <SimpleResourceDialog
        ref={deleteScoredDatasetRef}
        titleText="Do you want to delete this dataset?"
        contentText="This action will permanently delete this dataset."
        okFunction={() => {
          setScoreDatasetId(null)
          deleteScoredDataset(scoredDatasetId)
        }}
      />
      {/* <SimpleResourceDialog
        ref={deleteScoringRef}
        titleText="Do you want to delete this scoring?"
        contentText="This action will permanently delete this scoring."
        okFunction={() => removeScoring(id)}
      /> */}
      <div
        className={`${classes.wrapper}${open ? ' open' : ''}${
          selected ? ' selected' : ''
        }`}
        style={{
          height: open
            ? `calc(${datasets.length * 2.5 + 4}em + ${datasets.length}px)`
            : '',
        }}
      >
        <div
          className={`${classes.scoringDiv}${
            (datasets.length &&
              (datasets.length > 0 ? ' expandable' : ' clickable')) ||
            ''
          }`}
          role={datasets.length ? 'button' : null}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={datasets.length ? 0 : -1}
          onClick={onClick}
          onKeyDown={onKeyDown}
        >
          <Tooltip title={name}>
            <div className={`${classes.cell} ${classes.name}`}>
              <span>{name}</span>
            </div>
          </Tooltip>
          <div className={`${classes.cell} ${classes.status}`} />
          <div
            className={`${classes.cell} ${classes.date}${
              !date ? ` ${classes.error}` : ''
            }`}
          >
            <span>{date ? formatDate(date) : 'N/A'}</span>
          </div>
          <div className={`${classes.cell} ${classes.actions}`}>
            <button
              type="button"
              className={classes.actionButton}
              onClick={event => {
                event.stopPropagation()
                // if (datasets.length) deleteModalRef.current.open()
                // else deleteScoringRef.current.open()
                deleteModalRef.current.open()
              }}
              onKeyDown={event => {
                event.stopPropagation()
                if (event.keyCode === 13)
                  // if (datasets.length) deleteModalRef.current.open()
                  // else deleteScoringRef.current.open()
                  deleteModalRef.current.open()
              }}
            >
              <Icon type="trash" />
            </button>
          </div>
        </div>
        <Conditional dependencies={datasets.length > 0}>
          <div className={classes.scoredDatasourcesDiv}>
            {datasets
              .map(e => {
                const {
                  name: sdName,
                  id: sdId,
                  status: sdStatus,
                  created_at: sdDate,
                } = e
                return { name: sdName, id: sdId, status: sdStatus, date: sdDate }
              })
              .sort((a, b) => {
                const aVal = a[sort.column]
                const bVal = b[sort.column]
                let result = 0
                if (typeof aVal === 'string' || typeof bVal === 'string') {
                  result = `${aVal}`.localeCompare(`${bVal}`, 'en', {
                    sensitivity: 'base',
                  })
                } else result = aVal - bVal
                return result * (-1) ** (sort.order + 1)
              })
              .map(dataset => (
                <div
                  key={dataset.id}
                  className={`${classes.scoredDatasource}${
                    dataset.id === selectedScoredDatasetId ? ' selected' : ''
                  }`}
                  tabIndex={open ? 0 : -1}
                  role="button"
                  onClick={event => {
                    event.stopPropagation()
                    setSelectedScoredDataset(dataset.id)
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) {
                      setSelectedScoredDataset(dataset.id)
                    }
                  }}
                >
                  <div className={`${classes.cell} ${classes.name}`}>
                    <span>{dataset.name}</span>
                  </div>
                  <div className={`${classes.cell} ${classes.status}`}>
                    <span>{dataset.status}</span>
                  </div>
                  <div className={`${classes.cell} ${classes.date}`}>
                    <span>{formatDate(dataset.date)}</span>
                  </div>
                  <div className={`${classes.cell} ${classes.actions}`}>
                    <button
                      type="button"
                      className={classes.actionButton}
                      tabIndex={open ? 0 : -1}
                      onClick={event => {
                        event.stopPropagation()
                        deleteScoredDatasetRef.current.open()
                        setScoreDatasetId(dataset.id)
                      }}
                      onKeyDown={event => {
                        event.stopPropagation()
                        if (event.keyCode === 13) {
                          deleteScoredDatasetRef.current.open()
                          setScoreDatasetId(dataset.id)
                        }
                      }}
                    >
                      <Icon type="trash" />
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </Conditional>
        <ScoringDetails scoring={original} />
      </div>
    </>
  )
}

ScoringTableEntry.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  status: PropTypes.string,
  date: PropTypes.string,
  original: PropTypes.object,
  selected: PropTypes.bool,
  sort: PropTypes.shape({ column: PropTypes.string, order: PropTypes.number }),
}

export default ScoringTableEntry
