import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'

const classes = {
  wrapper: 'ScatterChart-ScatterLegend-wrapper',
  entry: 'ScatterChart-ScatterLegend-entry',
}

const ScatterLegend = props => {
  const { data, hoverCallback, hoverReset, width = 160 } = props

  return (
    <div className={classes.wrapper} style={{ width: `${width}px` }}>
      {data.map(datum => {
        return (
          <div
            key={`ScatterGraph-Legend-entry-${datum.title}`}
            className={classes.entry}
            onMouseEnter={() => hoverCallback(datum.id)}
            onMouseLeave={() => hoverReset()}
            style={{ color: datum.color }}
          >
            {datum.title}
          </div>
        )
      })}
    </div>
  )
}

ScatterLegend.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, id: PropTypes.string })
  ).isRequired,
  hoverCallback: PropTypes.func,
  hoverReset: PropTypes.func,
  width: PropTypes.number,
}

export default ScatterLegend
