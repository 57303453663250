import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { useInteractiveClick } from 'libs/interactive'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import { Checkbox, FormControlLabel, MenuItem, TextField } from '@material-ui/core'
import PopUpMultiSelect from 'components/PopUpMultiSelect'

import { SANITATION_FUNCTIONS, FEATURE_TYPES } from './stringConstants'

const SANITATION_FUNCTIONS_FEATURE_TYPES = {
  [SANITATION_FUNCTIONS.HARMONIZE_CASING]: [
    FEATURE_TYPES.CATEGORY,
    FEATURE_TYPES.TEXT,
    FEATURE_TYPES.TIME,
  ],
  [SANITATION_FUNCTIONS.TRIM]: [FEATURE_TYPES.CATEGORY, FEATURE_TYPES.TEXT],
  [SANITATION_FUNCTIONS.SUBSTRING]: [FEATURE_TYPES.CATEGORY, FEATURE_TYPES.TEXT],
  [SANITATION_FUNCTIONS.TEXT_REPLACE]: [FEATURE_TYPES.CATEGORY, FEATURE_TYPES.TEXT],
  [SANITATION_FUNCTIONS.DECIMAL_CORRECTION]: [FEATURE_TYPES.NUMERIC],
}

const classes = {
  wrapper: 'SanitationBox-config-content-wrapper',
  ruleWrapper: 'SanitationBox-config-content-SanitationRule-wrapper',
  ruleDescription: 'SanitationBox-config-content-SanitationRule-description',
  ruleApplication: 'SanitationBox-config-content-SanitationRule-application',
  ruleRemoveButton: 'SanitationBox-config-content-SanitationRule-removeButton',
  addRuleButtonsDiv: 'ConfigBox-addRuleButtonsDiv',
  addRuleButton: 'ConfigBox-addRuleButton',
}

const SanitationRule = ({
  id,
  method,
  options,
  features,
  meta: tempMeta = [],
  callback,
}) => {
  const rule = { id, method, feature_id: features }
  const meta = tempMeta.filter(
    ({ type }) => SANITATION_FUNCTIONS_FEATURE_TYPES[method].indexOf(type) !== -1
  )
  if (options) rule.options = options

  const replaceError = !options?.replace

  return (
    <div className={classes.ruleWrapper}>
      <div className={classes.ruleDescription}>
        <Conditional dependencies={method === SANITATION_FUNCTIONS.HARMONIZE_CASING}>
          <span>Harmonize casings</span>
          {/* <span>Harmonize</span>
          <TextField
            className="harmonize"
            select
            value={options?.harmonize}
            onChange={event => {
              callback(id, { ...rule, options: { harmonize: event.target.value } })
            }}
            margin="dense"
            fullWidth
          >
            <MenuItem value="casings">casings</MenuItem>
            <MenuItem value="dates">dates</MenuItem>
          </TextField> */}
        </Conditional>
        <Conditional dependencies={method === SANITATION_FUNCTIONS.TRIM}>
          <span>Remove</span>
          <TextField
            className="spaces"
            select
            value={
              options?.leading
                ? (options?.trailing && 'both') || 'leading'
                : 'trailing'
            }
            onChange={event => {
              const tempOptions = {}
              if (event.target.value === 'leading') {
                tempOptions.leading = true
                tempOptions.trailing = false
              }
              if (event.target.value === 'trailing') {
                tempOptions.leading = false
                tempOptions.trailing = true
              }
              if (event.target.value === 'both') {
                tempOptions.leading = true
                tempOptions.trailing = true
              }
              callback(id, {
                ...rule,
                options: tempOptions,
              })
            }}
            margin="dense"
            fullWidth
          >
            <MenuItem value="leading">leading</MenuItem>
            <MenuItem value="trailing">trailing</MenuItem>
            <MenuItem value="both">leading and trailing</MenuItem>
          </TextField>
          <span>spaces</span>
        </Conditional>
        <Conditional dependencies={method === SANITATION_FUNCTIONS.SUBSTRING}>
          <span>Remove</span>
          <TextField
            className="remove"
            type="number"
            value={options?.remove || 1}
            onChange={event => {
              callback(id, {
                ...rule,
                options: { ...options, remove: Math.max(event.target.value, 0) },
              })
            }}
            onFocus={event => {
              event.target.select()
            }}
            margin="dense"
            fullWidth
          />
          <span>
            {options?.remove > 1 ? 'characters ' : 'character '}
            from
          </span>
          <TextField
            className="position"
            type="number"
            value={options?.position || 0}
            onChange={event => {
              callback(id, {
                ...rule,
                options: { ...options, position: Math.max(event.target.value, 0) },
              })
            }}
            onFocus={event => {
              event.target.select()
            }}
            margin="dense"
            fullWidth
          />
          <FormControlLabel
            className="backwards"
            label="Counting backwards"
            checked={!!options?.backwards}
            onChange={event => {
              callback(id, {
                ...rule,
                options: { ...options, backwards: event.target.checked },
              })
            }}
            margin="dense"
            control={<Checkbox color="default" />}
          />
        </Conditional>
        <Conditional dependencies={method === SANITATION_FUNCTIONS.TEXT_REPLACE}>
          <span>Replace</span>
          <TextField
            className="replace"
            error={replaceError}
            value={options?.replace || ''}
            onChange={event => {
              callback(id, {
                ...rule,
                options: { ...options, replace: event.target.value },
              })
            }}
            onFocus={event => {
              event.target.select()
            }}
            margin="dense"
            fullWidth
          />
          <span>with</span>
          <TextField
            className="with"
            value={options?.with || ''}
            onChange={event => {
              callback(id, {
                ...rule,
                options: { ...options, with: event.target.value },
              })
            }}
            onFocus={event => {
              event.target.select()
            }}
            margin="dense"
            fullWidth
          />
        </Conditional>
        <Conditional
          dependencies={method === SANITATION_FUNCTIONS.DECIMAL_CORRECTION}
        >
          <span>Correct decimals</span>
        </Conditional>
      </div>
      <div className={classes.ruleApplication}>
        <PopUpMultiSelect
          error={!features.length}
          label="Apply to"
          margin="dense"
          fullWidth
          value={JSON.stringify(features)}
          onChange={e => {
            callback(id, {
              ...rule,
              feature_id: JSON.parse(e).sort((a, b) => a - b),
            })
          }}
        >
          {meta.map(feature => (
            <MenuItem key={feature.id} value={feature.id}>
              {feature.name}
            </MenuItem>
          ))}
        </PopUpMultiSelect>
      </div>
      <button
        className={classes.ruleRemoveButton}
        type="button"
        onClick={event => {
          event.stopPropagation()
          callback(id, null)
        }}
        onKeyDown={event => {
          event.stopPropagation()
          if (event.keyCode === 13) {
            callback(id, null)
          }
        }}
      >
        <Icon type="trash" />
      </button>
    </div>
  )
}

const SanitationConfig = ({ config, meta, stackedBy, callback }) => {
  const contentRef = useRef()
  const ruleCallback = (id, rule) => {
    if (rule) {
      const tempConfig = { ...config }
      tempConfig.rules = [...config.rules].map(e =>
        e.id === id ? { ...rule } : { ...e }
      )
      callback({ ...tempConfig })
    } else {
      const tempConfig = { ...config }
      tempConfig.rules = [...config.rules].filter(e => e.id !== id)
      callback({ ...tempConfig })
    }
  }
  const addRule = useCallback(
    (event, method, options) => {
      const tempRule = {
        id: uuid(),
        method,
        feature_id: [],
      }
      const tempConfig = {
        ...config,
        rules: [...config.rules, tempRule],
      }
      if (options !== undefined) tempRule.options = options
      callback(tempConfig)
    },
    [callback, config]
  )
  const { onClick, onKeyDown } = useInteractiveClick(addRule)
  const ruleLength = config?.rules?.length || 0
  useEffect(() => {
    if (contentRef?.current)
      contentRef.current.scrollTop = contentRef.current.scrollHeight
  }, [ruleLength])
  return (
    <>
      <div className={classes.wrapper} ref={contentRef}>
        {config.rules.map(({ id, method, options, feature_id: featureIds }) => (
          <SanitationRule
            key={id}
            id={id}
            method={method}
            options={options}
            features={featureIds}
            meta={meta.filter(({ id: featureId }) => featureId !== stackedBy)}
            callback={ruleCallback}
          />
        ))}
      </div>
      <div className={classes.addRuleButtonsDiv}>
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, SANITATION_FUNCTIONS.HARMONIZE_CASING, {
              harmonize: 'casings',
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, SANITATION_FUNCTIONS.HARMONIZE_CASING, {
              harmonize: 'casings',
            })
          }}
        >
          Harmonize
        </button>
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, SANITATION_FUNCTIONS.TRIM, {
              leading: true,
              trailing: false,
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, SANITATION_FUNCTIONS.TRIM, {
              leading: true,
              trailing: false,
            })
          }}
        >
          Spaces
        </button>
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, SANITATION_FUNCTIONS.SUBSTRING, {
              remove: 1,
              position: 0,
              backwards: false,
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, SANITATION_FUNCTIONS.SUBSTRING, {
              remove: 1,
              position: 0,
              backwards: false,
            })
          }}
        >
          Characters
        </button>
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => {
            onClick(event, SANITATION_FUNCTIONS.TEXT_REPLACE, {
              replace: '',
              with: '',
            })
          }}
          onKeyDown={event => {
            onKeyDown(event, SANITATION_FUNCTIONS.TEXT_REPLACE, {
              replace: '',
              with: '',
            })
          }}
        >
          Replace Text
        </button>
        <button
          className={classes.addRuleButton}
          type="button"
          onClick={event => onClick(event, SANITATION_FUNCTIONS.DECIMAL_CORRECTION)}
          onKeyDown={event => {
            onKeyDown(event, SANITATION_FUNCTIONS.DECIMAL_CORRECTION)
          }}
        >
          Correct Decimals
        </button>
      </div>
    </>
  )
}

SanitationConfig.propTypes = {
  config: PropTypes.object,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  stackedBy: PropTypes.number,
  callback: PropTypes.func,
}

SanitationRule.propTypes = {
  id: PropTypes.string,
  method: PropTypes.string,
  options: PropTypes.shape({
    harmonize: PropTypes.string,
    remove: PropTypes.number,
    position: PropTypes.number,
    backwards: PropTypes.bool,
    leading: PropTypes.bool,
    trailing: PropTypes.bool,
    replace: PropTypes.string,
    with: PropTypes.string,
  }),
  features: PropTypes.arrayOf(PropTypes.number),
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOf(['numeric', 'category', 'time', 'text']),
    })
  ),
  callback: PropTypes.func,
}

export default SanitationConfig
