/* eslint-disable camelcase */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import 'style.scss'

import Conditional from 'components/Conditional'
import Spinner from 'components/Spinner'

import { TextField, MenuItem, Switch, FormControlLabel } from '@material-ui/core'

import { classes as classNames } from '../../classNames'
import { validator as prevValidator } from '../2'

const classes = {
  ...classNames,
  stepThree: 'ScoringWizard-step-three',
  noDataMsg: 'ScoringWizard-no-data-msg',
  alternativeDataMsg: 'ScoringWizard-alternative-data-msg',
  actionLink: 'ScoringWizard-action-link',
  pipelineProcessing: 'ScoringWizard-pipelineProcessingWrapper',
  pipelineProcessingMessage: 'ScoringWizard-pipelineProcessingMessage',
  pipelineError: 'ScoringWizard-pipeline-error',
}

export const validator = scoring => {
  const {
    datasets,
    brains,
    pipeline_output,
    pipeline_processing_id,
    pipeline_error,
    title,
  } = scoring
  return (
    prevValidator(scoring) &&
    !!brains.length &&
    !!datasets.length &&
    !datasets.some(e => e === null) &&
    !!pipeline_output &&
    !pipeline_processing_id &&
    !pipeline_error &&
    !!title
  )
}

const ScoringWizardStepThree = props => {
  const { scoring } = props
  const {
    datasets,
    training,
    step,
    pipeline_processing_id,
    pipeline_operations_count,
    pipeline_processing_progress,
    pipeline_error,
    title,
    last_row_only,
  } = scoring
  const {
    datasets: datasources,
    setScoringWizardDataset,
    setScoringWizardName,
    setScoringWizardSwitch,
  } = useStore(
    'selectDatasets',
    'setScoringWizardDataset',
    'setScoringWizardName',
    'setScoringWizardSwitch'
  )
  const stepCompleted = validator(scoring)

  const processingProgressMessage =
    pipeline_operations_count &&
    pipeline_processing_progress !== pipeline_operations_count
      ? `${pipeline_processing_progress}/${pipeline_operations_count}`
      : ''

  const datasetInput = useMemo(() => {
    return datasets.map((datasetID, i) => {
      const value = datasetID !== null ? datasetID : ''
      return (
        <TextField
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          disabled={step !== 2}
          select
          value={value}
          label="Please select a datasource"
          onChange={event => setScoringWizardDataset(event.target.value, i)}
        >
          {datasources &&
            datasources
              .sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
              })
              .map(datasource => (
                <MenuItem key={datasource.id} value={datasource.id}>
                  {datasource.name}
                </MenuItem>
              ))}
        </TextField>
      )
    })
  }, [step, datasets, datasources, setScoringWizardDataset])

  return (
    <div className={classes.step} data-active={step === 2}>
      <div className={classes.progressWrapper}>
        <div className={classes.progressBar} data-completed={stepCompleted} />
      </div>
      <div className={`${classes.stepContent} ${classes.stepThree}`}>
        <TextField
          disabled={step !== 2}
          // dataKey="name"
          // defaultValue={`New Training - ${moment().format('hh:mm YYYY-MM-DD')}`}
          defaultValue={title}
          label="Name"
          inputProps={{ autoFocus: true }}
          onChange={e => setScoringWizardName(e.target.value)}
        />
        <h2>Choose a datasource:</h2>
        <Conditional dependencies={!datasources}>
          <span className={classes.noDataMsg}>No datasources available.</span>
          <span className={classes.alternativeDataMsg}>
            Please upload a datasource.
          </span>
        </Conditional>
        <Conditional dependencies={datasources && !training}>
          <span className={classes.noDataMsg}>No training choosen.</span>
          <span className={classes.alternativeDataMsg}>
            Please choose a training.
          </span>
        </Conditional>
        <Conditional dependencies={datasources && training}>
          {datasetInput}
          {/* <span className={classes.alternativeDataMsg}>
            or upload a new datasource.
          </span> */}
        </Conditional>
        <Conditional dependencies={pipeline_processing_id}>
          <div className={classes.pipelineProcessing}>
            <Spinner
              spin={1}
              strokeWidth={2}
              mainColor="#1E90FF"
              emptyColor="#2e313a"
            />
            <div className={classes.pipelineProcessingMessage}>
              {processingProgressMessage}
            </div>
          </div>
        </Conditional>
        <Conditional dependencies={pipeline_error}>
          <div className={classes.pipelineError}>
            <h3>Pipeline processing has failed.</h3>
            <span>Dataset incompatible or service out of order.</span>
          </div>
        </Conditional>
        <div style={{ display: 'flex', width: '100%', padding: '0 15px' }}>
          <FormControlLabel
            style={{
              width: '100%',
              marginTop: '10px',
              marginLeft: '0',
              marginRight: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
            margin="dense"
            label="Score only offsample test and beyond"
            labelPlacement="start"
            checked={last_row_only}
            onChange={event => setScoringWizardSwitch(event.target.checked)}
            control={<Switch color="primary" />}
          />
        </div>
      </div>
    </div>
  )
}

ScoringWizardStepThree.propTypes = {
  scoring: PropTypes.object.isRequired,
}

export default ScoringWizardStepThree
