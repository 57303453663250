import { createSelector } from 'create-selector'
import { createBundle } from 'core/bundler'

import {
  PAYMENTS_FETCH_STARTED,
  PAYMENTS_FETCH_SUCCEEDED,
  PAYMENTS_FETCH_FAILED,
  PAYMENTS_CLEARED,
  PAYMENTS_OUTDATED,
  PAYMENTS_EXPIRED,
} from 'core/actiontypes'

const name = 'payments' // e.g notification, trainings, users
const uCaseName = name.charAt(0).toUpperCase() + name.slice(1)
const defaultConfig = {
  stale: 900000, // fifteen minutes
  retry: 60000, // one minute,
  expireAfter: Infinity,
  persist: false,
  // checkIfOnline: true,
}

const initialState = {
  data: null,
  errorTimes: [],
  error: null,
  lastSuccess: null,
  isOutdated: false,
  isLoading: false,
  isExpired: false,
  failedPermanently: false,
}

const rawSelector = `select${uCaseName}Raw`
const dataSelector = `select${uCaseName}`
const lastSuccessSelector = `select${uCaseName}LastSuccess`
const isExpiredSelector = `select${uCaseName}IsExpired`
const lastErrorSelector = `select${uCaseName}LastError`
const isStaleSelector = `select${uCaseName}IsStale`
const isWaitingToRetrySelector = `select${uCaseName}IsWaitingToRetry`
const isLoadingSelector = `select${uCaseName}IsLoading`
const failedPermanentlySelector = `select${uCaseName}FailedPermanently`
const shouldUpdateSelector = `select${uCaseName}ShouldUpdate`
const missingDataReactor = `reactMissing${uCaseName}`

const fetchSuccess = payload => ({ type: PAYMENTS_FETCH_SUCCEEDED, payload })
const fetchError = error => ({ type: PAYMENTS_FETCH_FAILED, error })

export default config => {
  // eslint-disable-next-line no-param-reassign
  config = { ...defaultConfig, ...config }
  const markDataAsOutdated = () => ({ type: PAYMENTS_OUTDATED })
  const clearData = () => ({ type: PAYMENTS_CLEARED })
  const expireData = () => ({ type: PAYMENTS_EXPIRED })
  const fetchData =
    () =>
    (dispatch, { api }) => {
      dispatch({ type: PAYMENTS_FETCH_STARTED })
      return api('/payments').then(
        payload => {
          dispatch(fetchSuccess(payload))
        },
        error => {
          dispatch(fetchError(error))
        }
      )
    }

  const selectors = {
    [rawSelector]: state => state[name],
    [dataSelector]: createSelector(rawSelector, root => root.data),
    [isStaleSelector]: createSelector(
      rawSelector,
      lastSuccessSelector,
      'selectAppTime',
      (state, time, appTime) => {
        if (state.isOutdated) {
          return true
        }
        if (!time) {
          return false
        }
        return appTime - time > config.stale
      }
    ),
    [isExpiredSelector]: createSelector(rawSelector, root => root.isExpired),
    [lastErrorSelector]: createSelector(
      rawSelector,
      resource => resource.errorTimes.slice(-1)[0] || null
    ),
    [lastSuccessSelector]: createSelector(rawSelector, root => root.lastSuccess),
    [isWaitingToRetrySelector]: createSelector(
      lastErrorSelector,
      'selectAppTime',
      (time, appTime) => {
        if (!time) {
          return false
        }
        return appTime - time < config.retry
      }
    ),
    [isLoadingSelector]: createSelector(
      rawSelector,
      resourceState => resourceState.isLoading
    ),
    [failedPermanentlySelector]: createSelector(
      rawSelector,
      resourceState => resourceState.failedPermanently
    ),
    [shouldUpdateSelector]: createSelector(
      isLoadingSelector,
      failedPermanentlySelector,
      isWaitingToRetrySelector,
      dataSelector,
      isStaleSelector,
      // 'selectIsOnline',
      (
        isLoading,
        failedPermanently,
        isWaitingToRetry,
        data,
        isStale
        // isOnline
      ) => {
        if (
          // (checkIfOnline && !isOnline) ||
          isLoading ||
          failedPermanently ||
          isWaitingToRetry
        ) {
          return false
        }
        if (data === null) {
          return true
        }
        return isStale
      }
    ),
    [missingDataReactor]: createSelector(
      'selectUser',
      shouldUpdateSelector,
      (user, update) => {
        if (update && user) {
          return fetchData()
        }
        return null
      }
    ),
  }

  if (config.expireAfter !== Infinity) {
    selectors[`reactExpire${uCaseName}`] = createSelector(
      lastSuccessSelector,
      'selectAppTime',
      (time, appTime) => {
        if (!time) {
          return null
        }
        if (appTime - time > config.expireAfter) {
          return expireData()
        }
        return null
      }
    )
  }

  return createBundle({
    name,
    reducer: (state = initialState, { type, payload, error, merge }) => {
      if (type === PAYMENTS_FETCH_STARTED) {
        return { ...state, isLoading: true }
      }
      if (type === PAYMENTS_FETCH_SUCCEEDED) {
        const updatedData = merge ? { ...state.data, ...payload } : payload
        return {
          ...state,
          isLoading: false,
          data: updatedData,
          lastSuccess: Date.now(),
          errorTimes: [],
          error: null,
          failedPermanently: false,
          isOutdated: false,
          isExpired: false,
        }
      }
      if (type === PAYMENTS_FETCH_FAILED) {
        const errorMessage = (error && error.message) || error
        return {
          ...state,
          isLoading: false,
          errorTimes: [...state.errorTimes, Date.now()],
          error: errorMessage,
          failedPermanently: !!(error && error.permanent),
        }
      }
      if (type === PAYMENTS_CLEARED) {
        return initialState
      }
      if (type === PAYMENTS_EXPIRED) {
        return {
          ...initialState,
          isExpired: true,
          errorTimes: state.errorTimes,
          error: state.error,
        }
      }
      if (type === PAYMENTS_OUTDATED) {
        return { ...state, isOutdated: true }
      }
      return state
    },
    selectors,
    actions: {
      [`fetch${uCaseName}`]: fetchData,
      [`mark${uCaseName}AsOutdated`]: markDataAsOutdated,
      [`clear${uCaseName}`]: clearData,
      [`expire${uCaseName}`]: expireData,
    },
    init: null,
    args: null,
    middleware: null,
    persist: config.persist
      ? [
          PAYMENTS_FETCH_SUCCEEDED,
          PAYMENTS_EXPIRED,
          PAYMENTS_OUTDATED,
          PAYMENTS_CLEARED,
        ]
      : null,
  })
}
