/* eslint-disable prefer-destructuring */
import React from 'react'
import PropTypes from 'prop-types'

const classes = {
  candlesGroup: 'CurrencyGraph-candlesGroup',
  candle: 'CurrencyGraph-candle',
  wick: 'CurrencyGraph-wick',
}

const Candles = props => {
  const { data, range, bounds } = props

  const candles = [...data]
    .reverse()
    .filter(e => e.x >= bounds.xMin && e.x <= bounds.xMax)
    .reduce((acc, cur) => {
      const res = [...acc]
      let lastCandle = res[0]
      if (!lastCandle) {
        res.push({
          xMin: cur.x - range,
          xMax: cur.x,
          vals: [],
        })
        lastCandle = res[0]
      }
      if (cur.x <= lastCandle.xMin) {
        if (cur.x < lastCandle.xMin)
          while (cur.x < lastCandle.xMin) {
            res.unshift({
              xMin: lastCandle.xMin - range,
              xMax: lastCandle.xMin,
              vals: [],
            })
            lastCandle = res[0]
          }
        else if (cur.x === lastCandle.xMin) {
          lastCandle.vals.push(cur.candle)
          res.unshift({
            xMin: lastCandle.xMin - range,
            xMax: lastCandle.xMin,
            vals: [],
          })
          lastCandle = res[0]
          lastCandle.vals.push(cur.candle)
        }
      } else lastCandle.vals.push(cur.candle)
      return res
    }, [])
    .map(e => {
      const { xMin, xMax, vals } = e
      const length = vals.length
      if (length) {
        const open = vals[length - 1]
        const close = vals[0]
        const sorted = vals.sort((a, b) => a - b)
        const low = sorted[0]
        const high = sorted[length - 1]
        const color = (open - close && (open < close ? 'green' : 'red')) || 'white'
        return { xMin, xMax, open, low, high, close, color }
      }
      return null
    })
    .filter(e => e)

  return (
    <g className={classes.candlesGroup}>
      {candles.map(({ xMin, open, low, high, close, color }) => (
        <g>
          <rect
            key={`${xMin}`}
            className={`${classes.candle} ${color}`}
            x={xMin + range * 0.05}
            width={range * 0.9}
            y={-Math.max(open, close) + 2}
            height={Math.abs(close - open)}
          />
          <line
            className={`${classes.wick} ${color}`}
            x1={xMin + range / 2}
            x2={xMin + range / 2}
            y1={-high + 2}
            y2={-low + 2}
          />
        </g>
      ))}
    </g>
  )
}

Candles.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  range: PropTypes.number,
  bounds: PropTypes.shape({
    xMin: PropTypes.number,
    xMax: PropTypes.number,
    yMin: PropTypes.number,
    yMax: PropTypes.number,
  }),
}

export default Candles
