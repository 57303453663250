import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { useRefRect } from 'libs/utils/hooks'

import { useMouseTracker } from 'components/MouseTracker'

const classes = {
  wrapper: 'GraphOverlay-wrapper',
  overlay: 'GraphOverlay-overlay',
  shadow: 'GraphOverlay-shadow',
  borderBox: 'GraphOverlay-borderBox',
}

const GraphOverlay = forwardRef((props, ref) => {
  const overlayRef = useRef()
  const [rect, rectRef] = useRefRect()
  const [mouseData, setMouseData] = useState({ x: 0, y: 0, mousedown: false })
  const [boxDrag, setBoxDrag] = useState({
    dragging: false,
    startX: null,
    startY: null,
  })
  const { zoomCallback, minWidth, maxWidth, style } = props

  let { top: absTop = 0, left: absLeft = 0 } = { ...rect }
  const { width = 1, height = 1 } = { ...rect }
  absTop += (rect || 0) && rectRef.current.getBoundingClientRect().top
  absLeft += (rect || 0) && rectRef.current.getBoundingClientRect().left
  const xRange = 1000
  const yRange = 1000

  useImperativeHandle(ref, () => ({
    mouseDownHandler: event => {
      setBoxDrag({
        dragging: true,
        startX: event.clientX,
        startY: event.clientY,
      })
    },
    scrollDivRef: overlayRef,
  }))

  const dragRect = useMemo(() => {
    if (boxDrag.dragging && mouseData.mousedown) {
      const startX = Math.max(boxDrag.startX - absLeft, 0)
      const startY = Math.max(boxDrag.startY - absTop, 0)
      const xRatio = (width && 1 / width) || 0
      const yRatio = (height && 1 / height) || 0
      const x = Math.min(Math.max(mouseData.x - absLeft, 0), width)
      const y = Math.min(Math.max(mouseData.y - absTop, 0), height)
      const dragTop = Math.min(startY, y) * yRatio
      const dragLeft = Math.min(startX, x) * xRatio
      const dragWidth = Math.abs(x - startX) * xRatio
      const dragHeight = Math.abs(y - startY) * yRatio
      return { top: dragTop, left: dragLeft, width: dragWidth, height: dragHeight }
    }
    return { top: 0, left: 0, width: 1, height: 1 }
  }, [mouseData, boxDrag, absTop, absLeft, width, height])

  const updateMouseData = useCallback(
    hookMouseData => {
      if (boxDrag.dragging && zoomCallback) {
        if (!hookMouseData.mousedown) {
          setBoxDrag({ draging: false, startX: null, startY: null })
          dragRect && zoomCallback(dragRect)
        }
        setMouseData(hookMouseData)
      }
    },
    [boxDrag, zoomCallback, dragRect]
  )
  useMouseTracker(hookMouseData => updateMouseData(hookMouseData))

  const maskID = useMemo(() => {
    return `zoomShadowMask-${uuid()}`
  }, [])

  return (
    <div
      ref={overlayRef}
      className={classes.wrapper}
      style={{
        ...style,
        display: boxDrag.dragging && zoomCallback ? 'flex' : 'none',
      }}
    >
      <svg
        ref={rectRef}
        className={classes.overlay}
        style={{
          minWidth: minWidth ? `${minWidth}px` : '',
          maxWidth: maxWidth ? `${maxWidth}px` : '',
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${xRange} ${yRange}`}
        preserveAspectRatio="none"
      >
        <mask id={maskID}>
          <rect x="0" y="0" width="100%" height="100%" fill="white" />
          <rect
            x={dragRect.left * xRange || 0}
            y={dragRect.top * yRange || 0}
            width={dragRect.width * xRange || 0}
            height={dragRect.height * yRange || 0}
            fill="black"
          />
        </mask>
        <rect
          className={classes.shadow}
          x="0"
          y="0"
          width="100%"
          height="100%"
          mask={`url(#${maskID})`}
        />
        <rect
          className={classes.borderBox}
          x={dragRect.left * xRange || 0}
          y={dragRect.top * yRange || 0}
          width={dragRect.width * xRange || 0}
          height={dragRect.height * yRange || 0}
        />
      </svg>
    </div>
  )
})

GraphOverlay.propTypes = {
  zoomCallback: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  style: PropTypes.object,
}

export default GraphOverlay
