import React, { useRef } from 'react'
import { useStore } from 'core'

import Conditional from 'components/Conditional'
import Icon from 'components/Icon'
import {
  NewTransformationModal,
  SimpleResourceDialog,
  EditTransformationModal,
} from 'components/ResourceModalComponents'
import { Tooltip } from '@material-ui/core'

const classes = {
  wrapper: 'TransformationsMenu-wrapper',
  menuGroup: 'TransformationsMenu-menuGroup',
  title: 'TransformationsMenu-title',
  controlsDiv: 'TransformationsMenu-controlsDiv',
  control: 'TransformationsMenu-control',
}

const TransformationsMenu = () => {
  const newTransformationModalRef = useRef()
  const editTransformationModalRef = useRef()
  const cloneTransformationModalRef = useRef()

  const {
    transformations,
    selectedTransformation: selectedTransformationId,
    startTrainingFromResource,
    cloneTransformation,
    saveTransformation,
    setActivePipeline,
    setSelectedTransformation,
  } = useStore(
    'selectTransformations',
    'selectSelectedTransformation',
    'startTrainingFromResource',
    'cloneTransformation',
    'saveTransformation',
    'setActivePipeline',
    'setSelectedTransformation'
  )

  const selectedTransformation =
    transformations?.find(({ id }) => id === selectedTransformationId) || null

  const onSave = (_, spec, datasets) => {
    saveTransformation({
      selectedTransformationId,
      spec,
      datasets,
    })
  }

  return (
    <>
      <NewTransformationModal ref={newTransformationModalRef} />
      <SimpleResourceDialog
        ref={cloneTransformationModalRef}
        titleText="Do you want to clone this transformation?"
        contentText="This action will create a permanent copy of this transformation."
        okFunction={() => cloneTransformation(selectedTransformationId)}
      />
      <EditTransformationModal
        ref={editTransformationModalRef}
        selectedTransformation={selectedTransformation || {}}
      />
      <div className={classes.wrapper}>
        <div
          className={classes.menuGroup}
          style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
        >
          <div className={classes.controlsDiv} style={{ height: '2em' }}>
            <Tooltip title="NEW pipeline">
              <div
                className={classes.control}
                style={{ width: '2em' }}
                role="button"
                tabIndex={0}
                onClick={event => {
                  event.stopPropagation()
                  newTransformationModalRef.current.open()
                }}
                onKeyDown={event => {
                  event.stopPropagation()
                  if (event.keyCode === 13) newTransformationModalRef.current.open()
                }}
              >
                <Icon type="merge" style={{ transform: 'rotate(180deg)' }} />
              </div>
            </Tooltip>
          </div>
          {/* <div className={classes.title} style={{ height: '18px' }}>
            New
          </div> */}
        </div>

        <Conditional dependencies={!!selectedTransformationId}>
          <div
            className={classes.menuGroup}
            style={{ width: `calc(${3 * 1.5}em + ${(3 + 1) * 10}px)` }}
          >
            <div className={classes.title} style={{ height: '26px' }}>
              Actions
            </div>
            <div className={classes.controlsDiv} style={{ height: '1.5em' }}>
              <Tooltip title="TRAIN with selected pipeline">
                <div
                  className={
                    classes.control + (selectedTransformationId ? '' : ' disabled')
                  }
                  style={{ width: '1.5em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedTransformationId)}
                  onClick={event => {
                    event.stopPropagation()
                    if (selectedTransformationId)
                      startTrainingFromResource(
                        selectedTransformationId,
                        'transformation',
                        true,
                        'New training'
                      )
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13 && selectedTransformationId)
                      startTrainingFromResource(
                        selectedTransformationId,
                        'transformation',
                        true,
                        'New $training'
                      )
                  }}
                >
                  <Icon type="train" />
                </div>
              </Tooltip>
              <Tooltip title="CLONE selected pipeline">
                <div
                  className={
                    classes.control + (selectedTransformationId ? '' : ' disabled')
                  }
                  style={{ width: '1.5em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedTransformationId)}
                  onClick={event => {
                    event.stopPropagation()
                    cloneTransformationModalRef.current.open()
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13)
                      cloneTransformationModalRef.current.open()
                  }}
                >
                  <Icon type="clone" style={{ fontSize: '0.75em' }} />
                </div>
              </Tooltip>
              <Tooltip title="EDIT selected pipeline">
                <div
                  className={
                    classes.control + (selectedTransformationId ? '' : ' disabled')
                  }
                  style={{ width: '1.5em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedTransformationId)}
                  onClick={event => {
                    event.stopPropagation()
                    editTransformationModalRef.current.open()
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) {
                      editTransformationModalRef.current.open()
                    }
                  }}
                >
                  <Icon type="edit-box" />
                </div>
              </Tooltip>
            </div>
          </div>
          <div
            className={classes.menuGroup}
            style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
          >
            <div className={classes.controlsDiv} style={{ height: '2em' }}>
              <Tooltip title="CONFIGURE selected pipeline">
                <div
                  className={
                    classes.control + (selectedTransformationId ? '' : ' disabled')
                  }
                  style={{ width: '2em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedTransformationId)}
                  onClick={event => {
                    event.stopPropagation()
                    setActivePipeline(
                      selectedTransformationId,
                      selectedTransformation.spec,
                      selectedTransformation.datasets,
                      onSave,
                      null,
                      true,
                      selectedTransformation.name
                    )
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13)
                      setActivePipeline(
                        selectedTransformationId,
                        selectedTransformation.spec,
                        selectedTransformation.datasets,
                        onSave,
                        null,
                        true,
                        selectedTransformation.name
                      )
                  }}
                >
                  <Icon type="gear" />
                </div>
              </Tooltip>
            </div>
            {/* <div className={classes.title} style={{ height: '18px' }}>
              {null}
            </div> */}
          </div>
          <div
            className={classes.menuGroup}
            style={{ width: `calc(${1 * 1.5}em + ${(1 + 1) * 10}px)` }}
          >
            <div className={classes.controlsDiv} style={{ height: '2em' }}>
              <Tooltip title="DESELECT">
                <div
                  className={
                    classes.control + (selectedTransformationId ? '' : ' disabled')
                  }
                  style={{ width: '2em' }}
                  role="button"
                  tabIndex={-1 + Number(!!selectedTransformationId)}
                  onClick={event => {
                    event.stopPropagation()
                    setSelectedTransformation(null)
                  }}
                  onKeyDown={event => {
                    event.stopPropagation()
                    if (event.keyCode === 13) setSelectedTransformation(null)
                  }}
                >
                  <Icon type="close" />
                </div>
              </Tooltip>
            </div>
            {/* <div className={classes.title} style={{ height: '18px' }}>
              {null}
            </div> */}
          </div>
        </Conditional>
      </div>
    </>
  )
}

export default TransformationsMenu
