/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'core'
import moment from 'moment'
import { generateColors } from 'libs/utils/color'

import UserControlsBar from 'components/UserControls'
import { Provider } from '../LineChartContext'

const classes = {
  wrapper: 'LineChart-UserControlWrapper-wrapper',
}

const UserControlWrapper = props => {
  const {
    controlable,
    trainings: propTrainings,
    brains: propBrains,
    features: propFeatures,
    split: propSplit,
    datasets: propDatasets,
    stackingCategories: propStackingCategories,
    combineDatasets,
    x: propX = [],
    y: propY = [],
    type: propType,
    domain,
    labels,
    mainColor,
    children,
  } = props
  const { x: xLabel, y: yLabel } = labels
  const { prefKey } = controlable
  const {
    registerResourceRequest,
    registerBrainsRequest,
    updatePref,
    pref: { [prefKey]: storePref = {} },
    brains: storeBrains,
    resourceData,
    resourceMeta,
  } = useStore(
    'registerResourceRequest',
    'registerBrainsRequest',
    'updatePref',
    'selectPref',
    'selectBrains',
    'selectResourceData',
    'selectResourceMeta'
  )
  const [statePref, setStatePref] = useState({})

  const pref = (prefKey && storePref) || statePref

  const [
    trainings,
    brains,
    features,
    split,
    datasets,
    stackingCategories,
    x,
    y,
    mode,
  ] = useMemo(() => {
    const tempTrainings = pref.trainings || propTrainings || []
    const tempBrains = pref.brains || propBrains || []
    tempTrainings.forEach(training => {
      const tempTraining = tempBrains?.find(e => e.id === training.id)
      if (!tempTraining)
        tempBrains.push({
          id: training.id,
          brains: [],
        })
    })
    const tempFeatures = pref.features || propFeatures || []
    const tempSplit = pref.split || propSplit || 'train'
    const tempDatasets = pref.datasets || propDatasets || []
    const tempStackingCategories =
      pref.stackingCategories || propStackingCategories || []
    const tempX = (pref.x || propX || [])
      .filter(feature => feature.type === 'numeric' || feature.type === 'time')
      .reduce((acc, cur) => {
        const e = [...acc]
        const dynamicType = e.length && e[0].type
        if (
          !e.find(feature => feature.type === cur.type && feature.name === cur.name)
        )
          if (dynamicType) dynamicType === cur.type && e.push(cur)
          else e.push(cur)
        return e
      }, [])
    const tempY = (pref.y || propY || [])
      .filter(feature => feature.type === 'numeric')
      .reduce((acc, cur) => {
        const e = [...acc]
        if (
          !e.find(feature => feature.type === cur.type && feature.name === cur.name)
        )
          e.push(cur)
        return e
      }, [])
    const tempMode =
      (controlable?.trainings ||
        controlable?.brains ||
        propTrainings.length ||
        propBrains.length) &&
      'brainMode'
    return [
      tempTrainings,
      tempBrains,
      tempFeatures,
      tempSplit,
      tempDatasets,
      tempStackingCategories,
      tempX,
      tempY,
      tempMode,
    ]
  }, [
    pref,
    propTrainings,
    propBrains,
    propFeatures,
    propSplit,
    propDatasets,
    propStackingCategories,
    propX,
    propY,
    controlable,
  ])

  const [stackedBy, type] = useMemo(() => {
    const tempMeta = resourceMeta?.find(({ id }) => id === datasets[0])?.data || {}
    const { stacked_by: stackedById = null } = tempMeta
    const tempStackedBy =
      tempMeta.features?.find(({ id }) => id === stackedById) || null
    const tempType =
      mode === 'brainMode'
        ? pref.type || propType || 'learningCurve'
        : propType || (datasets.length && tempMeta.type) || null
    return [tempStackedBy, tempType]
  }, [resourceMeta, mode, pref.type, datasets, propType])

  const storeData = {
    brains: storeBrains || [],
    datasets:
      datasets
        .map(
          dataset =>
            resourceData.find(resource => resource.key === `chart-${dataset}`) ||
            null
        )
        .filter(e => !!e) || [],
  }

  const loading = useMemo(() => {
    return (
      !!storeData.brains.some(({ isLoading }) => isLoading) ||
      !!storeData.datasets.some(({ isLoading }) => isLoading)
    )
  }, [storeData.brains, storeData.datasets])

  // /////////////////////////////////// CALLBACKS
  const trainingsCallback = useCallback(
    selectedTrainings => {
      const tempPref = { ...pref }
      const storeTrainingIDs = storeData.brains.map(({ id }) => id)
      const difference = selectedTrainings.filter(
        dataset => storeTrainingIDs.indexOf(dataset) === -1
      )
      difference.forEach(({ id: trainingID }) => registerBrainsRequest(trainingID))
      tempPref.trainings = selectedTrainings
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, storeData.brains, registerBrainsRequest, prefKey, updatePref]
  )
  const brainsCallback = useCallback(
    (trainingID, selectedBrains) => {
      const tempPref = { ...pref }
      tempPref.brains = [...brains]
      const tempTraining = tempPref.brains.find(
        training => training.id === trainingID
      )
      if (tempTraining) tempTraining.brains = selectedBrains
      else
        tempPref.brains.push({
          id: trainingID,
          brains: selectedBrains,
        })
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, prefKey, updatePref, brains]
  )
  const featuresCallback = useCallback(
    selectedFeatures => {
      const tempPref = { ...pref }
      tempPref.features = selectedFeatures
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, prefKey, updatePref]
  )
  const splitCallback = useCallback(
    ([selectedSplit]) => {
      const tempPref = { ...pref }
      tempPref.split = selectedSplit
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, prefKey, updatePref]
  )
  const typeCallback = useCallback(
    selectedType => {
      const tempPref = { ...pref }
      tempPref.type = selectedType
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, prefKey, updatePref]
  )
  const datasetsCallback = useCallback(
    selectedDatasets => {
      const tempPref = { ...pref }
      const storeDatasetIDs = storeData.datasets.map(({ id }) => id)
      const difference = selectedDatasets.filter(
        dataset => storeDatasetIDs.indexOf(dataset) === -1
      )
      difference.forEach(dataset =>
        registerResourceRequest(`chart-${dataset}`, dataset, 'chart')
      )
      tempPref.datasets = selectedDatasets
      const tempType =
        (selectedDatasets.length &&
          resourceMeta?.find(({ id }) => id === selectedDatasets[0])?.data?.type) ||
        null
      if (tempType !== type) tempPref.x = []
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [
      pref,
      storeData.datasets,
      registerResourceRequest,
      resourceMeta,
      type,
      prefKey,
      updatePref,
    ]
  )
  const stackingCategoriesCallback = useCallback(
    selectedCategories => {
      const tempPref = { ...pref }
      tempPref.stackingCategories = selectedCategories
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, prefKey, updatePref]
  )
  const xCallback = useCallback(
    selectedX => {
      const tempPref = { ...pref }
      tempPref.x = selectedX
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, prefKey, updatePref]
  )
  const yCallback = useCallback(
    selectedY => {
      const tempPref = { ...pref }
      tempPref.y = selectedY
      if (prefKey) {
        updatePref({ prefKey, data: tempPref })
      } else setStatePref(tempPref)
    },
    [pref, prefKey, updatePref]
  )
  // /////////////////////////////////////////////

  // //////////////////////////////////// CONTROLS
  const controls = useMemo(() => {
    const tempControls = []
    if (mode === 'brainMode') {
      if (controlable.trainings) {
        tempControls.push({
          control: 'trainingSelect',
          values: trainings.map(({ id }) => id),
          callback: trainingsCallback,
          multiple: controlable.trainings.multiple,
          title: `Training${controlable.trainings.multiple ? 's' : ''}`,
        })
      }
      if (controlable.type && trainings.length) {
        tempControls.push({
          control: 'curveSelect',
          ids: trainings.map(({ id }) => id),
          value: type,
          callback: typeCallback,
        })
      }
      if (controlable.split && trainings.length) {
        tempControls.push({
          control: 'trainingSplitSelect',
          values: [split],
          callback: splitCallback,
        })
      }
      if (controlable.features && type && trainings.length) {
        tempControls.push({
          control: 'brainFeatureSelect',
          ids: trainings.map(({ id }) => id),
          values: features,
          callback: featuresCallback,
          chartType: type,
          multiple: controlable.features.multiple,
          type: ['numeric'],
          singleType: true,
          title: `Feature${controlable.features.multiple ? 's' : ''}`,
        })
      }
      if (controlable.brains && trainings.length && split) {
        trainings.forEach(({ id, name }) => {
          const tempTraining = brains.find(training => training.id === id)
          tempControls.push({
            control: 'brainSelect',
            id,
            split,
            values: tempTraining.brains || [],
            callback: selectedBrains => brainsCallback(id, selectedBrains),
            multiple: controlable.brains.multiple,
            title: name,
          })
        })
      }
    } else {
      if (controlable.datasets) {
        tempControls.push({
          control: 'datasetSelect',
          values: datasets,
          callback: datasetsCallback,
          multiple:
            type && type !== 'stacked-timeseries'
              ? controlable.datasets.multiple
              : false,
          type:
            type && type !== 'stacked-timeseries'
              ? controlable.datasets.multiple && type
              : null,
          title: `Dataset${controlable.datasets.multiple ? 's' : ''}`,
        })
      }
      if (controlable.stackingCategories && type === 'stacked-timeseries') {
        tempControls.push({
          control: 'stackingCategorySelect',
          ids: datasets,
          values: stackingCategories,
          callback: stackingCategoriesCallback,
          multiple: controlable.stackingCategories.multiple,
          title: `Categor${controlable.stackingCategories.multiple ? 'ies' : 'y'}`,
        })
      }
      if (controlable.x && datasets.length) {
        tempControls.push({
          control: 'featureSelect',
          ids: datasets,
          values: x,
          callback: xCallback,
          multiple: controlable.x.multiple,
          // type: type === 'basedata' ? ['numeric', 'time'] : 'time',
          type: ['numeric', 'time'],
          singleType: true,
          title: 'xAxis',
        })
      }
      if (controlable.y && datasets.length) {
        tempControls.push({
          control: 'featureSelect',
          ids: datasets,
          values: y,
          callback: yCallback,
          multiple: controlable.y.multiple,
          type: 'numeric',
          title: 'yAxis',
        })
      }
    }
    return tempControls
  }, [
    mode,
    controlable,
    trainingsCallback,
    brainsCallback,
    featuresCallback,
    splitCallback,
    typeCallback,
    datasetsCallback,
    stackingCategoriesCallback,
    xCallback,
    yCallback,
    trainings,
    brains,
    features,
    split,
    type,
    datasets,
    stackingCategories,
    x,
    y,
  ])
  // /////////////////////////////////////////////

  const [xyCombinations, xAxisLabel, yAxisLabel] = useMemo(() => {
    let tempCombinations = []
    let tempXLabel = ''
    let tempYLabel = ''
    if (mode === 'brainMode') {
      switch (type) {
        case 'learningCurve':
          tempXLabel = 'epoch'
          tempYLabel =
            (features.length && features.map(feature => feature.name).join(', ')) ||
            'loss'
          break
        case 'rocCurve':
          tempXLabel = 'fpr'
          tempYLabel = 'tpr'
          break
        default:
          break
      }
    } else {
      tempXLabel = x.map(feature => feature.name).join(', ')
      tempYLabel = y.map(feature => feature.name).join(', ')
      if (x.length) {
        tempCombinations = x.reduce((acc, cur) => {
          const tempResult = [...acc]
          y.forEach(feature => tempResult.push({ x: cur, y: feature }))
          return tempResult
        }, [])
      } else if (y.length) {
        tempXLabel = 'index'
        tempCombinations = y.map(feature => ({
          x: { type: 'numeric', name: '_index', id: '_index-numeric' },
          y: feature,
        }))
      }
    }
    return [tempCombinations, xLabel || tempXLabel, yLabel || tempYLabel]
  }, [mode, type, features, x, y, xLabel, yLabel])

  const data = useMemo(() => {
    const returnData = []
    if (
      mode === 'brainMode' &&
      trainings.length &&
      brains.length &&
      features.length
    ) {
      const resultData = []
      const tempTrainings = trainings.map(({ name, id }) => {
        const { [type]: trainingBrains = {} } =
          storeData.brains.find(training => training.id === id)?.data || {}
        const { features: rocFeats = [], objectives: lrnObjs = [] } =
          trainingBrains || {}
        const tempBrains =
          trainingBrains[split]?.filter(
            brain => brains.find(e => e.id === id).brains.indexOf(brain.id) !== -1
          ) || []
        const returnObj = { name, brains: tempBrains, id }
        if (type === 'learningCurve') returnObj.objectives = lrnObjs
        if (type === 'rocCurve') returnObj.objectives = rocFeats
        return returnObj
      })
      features.forEach(feature => {
        tempTrainings.forEach((trainingBrains, _, { length }) => {
          trainingBrains.brains.forEach(brain => {
            const tempLine = brain[feature.name] || null
            if (tempLine)
              resultData.push({
                title: `${length > 1 ? `${trainingBrains.name}: ` : ''}${
                  feature.name
                } / ${brain.name}`,
                data: brain[feature.name] || [],
              })
          })
        })
      })
      returnData.push(...resultData)
    }
    if (
      mode !== 'brainMode' &&
      datasets.length &&
      xyCombinations.length &&
      (type !== 'stacked-timeseries' || (stackedBy && stackingCategories.length))
    ) {
      const resultData = xyCombinations.reduce(
        (acc, { x: xFeature, y: yFeature }) => {
          const tempResult = storeData.datasets
            .filter(
              instance =>
                (xFeature.id !== '_index-numeric'
                  ? instance?.meta?.features?.find(
                      feature =>
                        feature.type === xFeature.type &&
                        feature.name === xFeature.name
                    )
                  : true) &&
                instance.meta?.features.find(
                  feature =>
                    feature.type === yFeature.type && feature.name === yFeature.name
                )
            )
            .map((instance, _, { length }) => {
              const name =
                resourceMeta?.find(dataset => dataset.id === instance.resource)?.data
                  ?.name || ''
              return {
                title: `${length > 1 ? `${name}: ` : ''}${yFeature.name} / ${
                  xFeature.name
                }`,
                data:
                  instance.data
                    .filter(
                      e =>
                        type !== 'stacked-timeseries' ||
                        stackingCategories.indexOf(e[stackedBy.name]) !== -1
                    )
                    .map((e, i) => {
                      let tempX = null
                      if (xFeature.id !== '_index-numeric')
                        tempX =
                          xFeature.type === 'time'
                            ? moment(
                                e[xFeature.name],
                                'YYYY-MM-DDTHH:mm:ss.SSS Z'
                              ).valueOf() / 1000
                            : Number(e[xFeature.name])
                      else tempX = i
                      let tempY = Number(e[yFeature.name])
                      const tempStacked =
                        (type === 'stacked-timeseries' && e[stackedBy.name]) || null
                      tempX = tempX || tempX === 0 ? tempX : null
                      tempY = tempY || tempY === 0 ? tempY : null
                      return {
                        x: tempX,
                        y: tempY,
                        stacked: tempStacked,
                      }
                    })
                    .filter(
                      ({ x: xVal, y: yVal }) =>
                        (!!xVal || xVal === 0) && (!!yVal || yVal === 0)
                    ) || [],
              }
            })
          let endResult = tempResult
          if (combineDatasets)
            endResult = [
              tempResult.reduce(
                (accumulated, current) => {
                  const tempCombined = { ...accumulated }
                  tempCombined.data = [...tempCombined.data, ...current.data]
                  return tempCombined
                },
                {
                  title: `combined: ${yFeature.name} / ${xFeature.name}`,
                  data: [],
                }
              ),
            ]
          if (type === 'stacked-timeseries') {
            endResult = endResult.reduce((accumulated, current) => {
              const tempRes = [...accumulated]
              current.data.forEach(datum => {
                const instance = tempRes.find(
                  ({ title }) => title === `${current.title} (${datum.stacked})`
                )
                if (instance) instance.data.push({ x: datum.x, y: datum.y })
                else
                  tempRes.push({
                    title: `${current.title} (${datum.stacked})`,
                    data: [{ x: datum.x, y: datum.y }],
                  })
              })
              return tempRes
            }, [])
          }
          return [...acc, ...endResult]
        },
        []
      )
      returnData.push(...resultData)
    }
    const colorArray =
      (returnData.length &&
        mainColor &&
        generateColors(
          mainColor,
          returnData.length,
          0.2 + returnData.length / (returnData.length + 1) / 10
        )) ||
      []
    return returnData.map((datum, i) => {
      return { ...datum, color: colorArray[i] }
    })
  }, [
    mode,
    trainings,
    brains,
    features,
    split,
    type,
    storeData.brains,
    storeData.datasets,
    datasets,
    stackedBy,
    stackingCategories,
    resourceMeta,
    combineDatasets,
    xyCombinations,
    mainColor,
  ])

  const xAxisType = (mode !== 'brainMode' && x.length && x[0].type) || null

  const contextData = {
    data,
    xAxisLabel,
    yAxisLabel,
    xAxisType,
    domain,
    loading,
  }

  return (
    <div className={classes.wrapper}>
      <UserControlsBar controls={controls} chart />
      <Provider value={contextData}>{children}</Provider>
    </div>
  )
}

UserControlWrapper.propTypes = {
  controlable: PropTypes.shape({
    trainings: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    brains: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    split: PropTypes.bool,
    features: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    datasets: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    stackingCategories: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    x: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    y: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
    type: PropTypes.bool,
    prefKey: PropTypes.string,
  }),

  // /////////////// TRAININGS DISPLAY
  trainings: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
  ),
  brains: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      brains: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  features: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
  ),
  split: PropTypes.oneOf(['combined', 'test', 'train', 'validation']),
  // /////////////////////////////////

  // //////////////// DATASETS DISPLAY
  datasets: PropTypes.arrayOf(PropTypes.string),
  stackingCategories: PropTypes.arrayOf(PropTypes.string),
  combineDatasets: PropTypes.bool,
  x: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
  ),
  y: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
  ),
  // /////////////////////////////////

  type: PropTypes.oneOf([
    'learningCurve',
    'rocCurve',
    'stacked-timeseries',
    'basedata',
    'timeseries',
  ]),
  domain: PropTypes.shape({
    x: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.shape({ min: PropTypes.number, max: PropTypes.number }),
    ]),
    y: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.shape({ min: PropTypes.number, max: PropTypes.number }),
    ]),
  }),
  labels: PropTypes.shape({ x: PropTypes.string, y: PropTypes.string }),
  mainColor: PropTypes.string,
  children: PropTypes.node,
}

export default UserControlWrapper
