import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useRefRect } from 'libs/utils/hooks'
import { generateGridPoints, getFormatFromScale } from 'libs/utils/helpers'

import Grid from './Grid'
import LowHighPath from './LowHighPath'
import Line from './Line'
import Candles from './Candles'
import Bars from './Bars'
import XAxis from './XAxis'
import YAxis from './YAxis'

import './style.scss'

const classes = {
  wrapper: 'CurrencyGraph-wrapper',
  sideBar: 'CurrencyGraph-sideBar',
  sideBarDivider: 'CurrencyGraph-sideBarDivider',
  sideBarButton: 'CurrencyGraph-sideBarButton',
  content: 'CurrencyGraph-content',
  header: 'CurrencyGraph-header',
  graph: 'CurrencyGraph-graph',
  infoDiv: 'CurrencyGraph-infoDiv',
  hoverDiv: 'CurrencyGraph-hoverDiv',
  aligner: 'CurrencyGraph-aligner',
  topGraph: 'CurrencyGraph-topGraph',
  horizontal: 'CurrencyGraph-horizontal',
  bottomGraph: 'CurrencyGraph-bottomGraph',
  bottomRange: 'CurrencyGraph-bottomRange',
  bottomLine: 'CurrencyGraph-bottomLine',
  yAxis: 'CurrencyGraph-yAxis',
  footer: 'CurrencyGraph-footer',
}

const SideBar = () => {
  return (
    <div className={classes.sideBar}>
      <div className={classes.sideBarDivider}>
        <div className={classes.sideBarButton} />
        <div className={classes.sideBarButton} />
        <div className={classes.sideBarButton} />
      </div>
      <div className={classes.sideBarDivider}>
        <div className={classes.sideBarButton} />
        <div className={classes.sideBarButton} />
      </div>
      <div className={classes.sideBarDivider}>
        <div className={classes.sideBarButton} />
        <div className={classes.sideBarButton} />
        <div className={classes.sideBarButton} />
        <div className={classes.sideBarButton} />
      </div>
    </div>
  )
}

const CurrencyGraph = props => {
  const [
    range,
    // setRange
  ] = useState(300)
  const [rect, ref] = useRefRect()
  const [bottomRect, bottomRef] = useRefRect()
  const { data, horizontals, bottomRange } = props
  const sortedData = [...data].sort((a, b) => a.timestamp - b.timestamp)
  const viewBoxPonder = 1000
  const mappedData = [...sortedData].map(e => ({
    ...e,
    timestamp: e.timestamp / viewBoxPonder,
    x: (e.timestamp - sortedData[0].timestamp) / viewBoxPonder,
  }))
  const midLine = [...mappedData].map(({ x, mid }) => ({ x, y: mid }))
  const bottomGraphLine = [...mappedData]
    .filter(e => e.bottomGraphLine || e.bottomGraphLine === 0)
    .map(({ x, bottomGraphLine: bgl }) => ({ x, y: bgl }))

  const [yMin, yMax] = mappedData.reduce(
    (acc, cur) => {
      const res = [...acc]
      let low = null
      if (cur.low || cur.low === 0) low = cur.low
      let mid = null
      if (cur.mid || cur.mid === 0) mid = cur.mid
      let high = null
      if (cur.high || cur.high === 0) high = cur.high
      if (!(acc[0] || acc[0] === 0)) res[0] = Math.min(low, mid, high)
      if (!(acc[1] || acc[1] === 0)) res[1] = Math.max(low, mid, high)
      res[0] = Math.min(res[0], low, mid, high)
      res[1] = Math.max(res[1], low, mid, high)
      return res
    },
    [null, null]
  )
  const [bottomYMin, bottomYMax] = bottomGraphLine.reduce(
    (acc, cur) => {
      const res = [...acc]
      let min = null
      let max = null
      if (cur.y || cur.y === 0) min = cur.y
      if (cur.y || cur.y === 0) max = cur.y
      if (!(acc[0] || acc[0] === 0)) res[0] = min
      if (!(acc[1] || acc[1] === 0)) res[1] = max
      res[0] = Math.min(res[0], min)
      res[1] = Math.max(res[1], max)
      return res
    },
    [null, null]
  )
  const viewBoxPadding = 0.2
  const yRange = yMax - yMin
  const bounds = {
    xMin: mappedData[0].x,
    xMax: mappedData[mappedData.length - 1].x,
    yMin: yMin - yRange * viewBoxPadding,
    yMax: yMax + yRange * viewBoxPadding,
  }
  const bottomYRange = bottomYMax - bottomYMin
  const bottomBounds = {
    xMin: mappedData[0].x,
    xMax: mappedData[mappedData.length - 1].x,
    yMin: bottomYMin - bottomYRange * viewBoxPadding,
    yMax: bottomYMax + bottomYRange * viewBoxPadding,
  }
  const vbXRange = bounds.xMax - bounds.xMin
  const vbYRange = bounds.yMax - bounds.yMin
  const viewBox = {
    x: bounds.xMin,
    xRange: vbXRange,
    y: bounds.yMin,
    yRange: vbYRange,
  }
  const bottomVBXRange = bottomBounds.xMax - bottomBounds.xMin
  const bottomVBYRange = bottomBounds.yMax - bottomBounds.yMin
  const bottomViewBox = {
    x: bottomBounds.xMin,
    xRange: bottomVBXRange,
    y: bottomBounds.yMin,
    yRange: bottomVBYRange,
  }

  // const testBounds = {
  //   xMin: bounds.xMin + 86400,
  //   xMax: bounds.xMax - 86400,
  //   yMin: bounds.yMin + 5,
  //   yMax: bounds.yMax - 5,
  // }
  const testBounds = { ...bounds }

  const ratios = useMemo(() => {
    const xRatio = (rect && viewBox.xRange / rect.width) || null
    const yRatio = (rect && viewBox.yRange / rect.height) || null
    const bottomYRatio =
      (bottomRect && bottomViewBox.yRange / bottomRect.height) || null
    return { x: xRatio, y: yRatio, bottomY: bottomYRatio }
  }, [rect, viewBox.xRange, viewBox.yRange, bottomRect, bottomViewBox.yRange])

  const [xPoints, xScale] = generateGridPoints(
    viewBox.x,
    viewBox.xRange,
    ratios.x,
    64,
    1,
    false,
    true
  )
  const xAxisPoints = xPoints.map(point => {
    return {
      position: (point - viewBox.x) / viewBox.xRange,
      value: point,
    }
  })
  const yAxisPoints = generateGridPoints(
    viewBox.y,
    viewBox.yRange,
    ratios.y,
    25
  )[0].map(point => {
    return {
      position: (point - viewBox.y) / viewBox.yRange,
      value: point * 1,
    }
  })
  const yAxisPointsBottom = generateGridPoints(
    bottomViewBox.y,
    bottomViewBox.yRange,
    ratios.bottomY,
    25
  )[0].map(point => {
    return {
      position: (point - bottomViewBox.y) / bottomViewBox.yRange,
      value: point * 1,
    }
  })
  const xGridPoints = xAxisPoints.map(point => point.position) || []
  const yGridPoints = yAxisPoints.map(point => point.position) || []
  const yBottomGridPoints = yAxisPointsBottom.map(point => point.position) || []
  const xFormat = useMemo(() => getFormatFromScale(xScale), [xScale])

  return (
    <div className={classes.wrapper}>
      <SideBar />
      <div className={classes.content}>
        <div className={classes.header}>{null}</div>
        <div className={classes.graph}>
          <div className={classes.infoDiv}>
            <span>Company Something Something...</span>
            <span>Portfolio LRG LTTRS</span>
            <span>123.56, 78.9</span>
            <span>tag</span>
          </div>
          <div className={classes.hoverDiv}>
            <span>O:115.21 L:110.12 H:139.67 C:121.07</span>
          </div>
          <div className={classes.aligner}>
            <Grid grid={{ xGridPoints, yGridPoints }} />
            <Grid grid={{ xGridPoints, yGridPoints: yBottomGridPoints }} />
            <svg
              ref={ref}
              className={classes.topGraph}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox={`${viewBox.x} ${-(viewBox.y + viewBox.yRange)} ${
                viewBox.xRange
              } ${viewBox.yRange}`}
              preserveAspectRatio="none"
            >
              <LowHighPath data={mappedData} bounds={testBounds} />
              <Line data={midLine} bounds={testBounds} />
              {horizontals.map(y => (
                <line
                  className={classes.horizontal}
                  x1={bounds.xMin}
                  x2={bounds.xMax}
                  y1={-y}
                  y2={-y}
                />
              ))}
              <Candles data={mappedData} range={72 * range} bounds={testBounds} />
              <Bars
                data={mappedData}
                viewBox={viewBox}
                range={72 * range}
                bounds={testBounds}
              />
            </svg>
            <svg
              ref={bottomRef}
              className={classes.bottomGraph}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox={`${bottomViewBox.x} ${-(
                bottomViewBox.y + bottomViewBox.yRange
              )} ${bottomViewBox.xRange} ${bottomViewBox.yRange}`}
              preserveAspectRatio="none"
            >
              <Line data={bottomGraphLine} bounds={bottomBounds} />
              <rect
                className={classes.bottomRange}
                x={bottomBounds.xMin}
                width={bottomBounds.xMax - bottomBounds.xMin}
                y={-bottomRange[1]}
                height={bottomRange[1] - bottomRange[0]}
              />
              {bottomRange.map(y => (
                <line
                  className={classes.bottomLine}
                  x1={bottomBounds.xMin}
                  x2={bottomBounds.xMax}
                  y1={-y}
                  y2={-y}
                />
              ))}
            </svg>
            <XAxis
              points={xAxisPoints}
              render={value => moment(value * 1000).format(xFormat)}
              height={48}
            />
          </div>
          <div className={classes.yAxis}>
            <YAxis mirror points={yAxisPoints} />
            <YAxis mirror points={yAxisPointsBottom} />
          </div>
        </div>
        <div className={classes.footer}>{null}</div>
      </div>
    </div>
  )
}

CurrencyGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  horizontals: PropTypes.arrayOf(PropTypes.number),
  bottomRange: PropTypes.arrayOf(PropTypes.number),
}

export default CurrencyGraph
