/* eslint-disable camelcase */
import React, { forwardRef, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useStore } from 'core'

import Modal from 'components/Modal'
import Conditional from 'components/Conditional'
import { Form, FormItem, FormConditional } from 'components/Form'
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  MenuItem,
} from '@material-ui/core'

import './style.scss'
import { useMemo } from 'use-memo-one'

const classes = {
  wrapper: 'AutomationModal-wrapper',
  deleteLink: 'AutomationModal-deleteLink',
  content: 'AutomationModal-content',
  activePeriodSwitch: 'AutomationModal-activePeriodSwitch',
  groupLabel: 'AutomationModal-groupLabel',
  formGroup: 'AutomationModal-formGroup',
  formTitle: 'AutomationModal-formTitle',
  formDescription: 'AutomationModal-formDescription',
  formType: 'AutomationModal-formType',
  formPeriodicInputDiv: 'AutomationModal-formPeriodicInputDiv',
}

const timeUnitMultiplier = {
  s: 1,
  m: 60,
  h: 60 * 60,
  d: 60 * 60 * 24,
}

const deriveUnit = time => {
  // eslint-disable-next-line no-param-reassign
  time = Number(time)
  const days = 60 * 60 * 24
  const hours = 60 * 60
  const minutes = 60

  let measurement = 's'
  if (time % minutes === 0) measurement = 'm'
  if (time % hours === 0) measurement = 'h'
  if (time % days === 0) measurement = 'd'
  return measurement
}

const AutomationModal = forwardRef((props, ref) => {
  const [error, setError] = useState({
    timed: null,
    periodic: { period: null, dates: null },
  })
  const formRef = useRef()
  const { automations, createAutomation, deleteAutomation } = useStore(
    'selectAutomations',
    'createAutomation',
    'deleteAutomation'
  )

  const { resource, resourceId } = props
  const automation =
    automations && automations.find(a => a.spec[`${resource}_id`] === resourceId)
  const [activePeriodEnabled, setActivePeriodEnabled] = useState(
    !!(automation?.active_from || automation?.active_to)
  )

  let action = ''
  switch (resource) {
    case 'dataset':
      action = 'UPDATE_QUERY_DATASET'
      break
    case 'training':
      action = 'RUN_TRAINING'
      break
    case 'scoring':
      action = 'RUN_SCORING'
      break
    default:
      break
  }

  let triggerKey = ''
  if (automation?.type === 'TIMED') triggerKey = 'trigger_time'
  if (automation?.type === 'PERIODIC') triggerKey = 'trigger_interval'
  if (automation?.type === 'REACTION') triggerKey = 'trigger_action'

  const timeUnit = automation?.trigger_interval
    ? deriveUnit(automation.trigger_interval)
    : 'h'

  const date = useMemo(() => moment(Date.now()), [])

  const formPreload = {
    id: automation?.id || null,
    type: automation?.type || 'TIMED',
    trigger:
      (automation &&
        (automation?.type === 'PERIODIC'
          ? automation[triggerKey] / timeUnitMultiplier[timeUnit]
          : automation[triggerKey])) ||
      date.format('YYYY-MM-DDTHH:mm:ss'),
    trigger_time:
      moment(automation?.trigger_time).format('YYYY-MM-DDTHH:mm:ss') ||
      date.format('YYYY-MM-DDTHH:mm:ss'),
    trigger_interval:
      automation?.trigger_interval / timeUnitMultiplier[timeUnit] || '1',
    // trigger_action: automation?.trigger_action || '' // currently hardcoded so no option uses it
    intervalTimeUnit: timeUnit,
    action,
    enabled: true,
    activeFrom: automation?.active_from
      ? moment(automation.active_from).format('YYYY-MM-DDTHH:mm:ss')
      : date.format('YYYY-MM-DDTHH:mm:ss'),
    activeTo: automation?.active_to
      ? moment(automation.active_to).format('YYYY-MM-DDTHH:mm:ss')
      : date.format('YYYY-MM-DDTHH:mm:ss'),
    title: automation?.name || 'New automation',
    description: automation?.description || resourceId,
    condition: {
      automation_id: automation?.config?.automation_id,
    },
  }

  return (
    <Modal
      className={classes.wrapper}
      ref={ref}
      title={
        <div>
          Automate
          <Conditional dependencies={automation && automation.id}>
            <span
              className={classes.deleteLink}
              tabIndex={0}
              role="button"
              onClick={event => {
                event.stopPropagation()
                deleteAutomation(automation.id)
                ref.current.close()
              }}
              onKeyDown={event => {
                event.stopPropagation()
                if (event.keyCode === 13) {
                  deleteAutomation(automation.id)
                  ref.current.close()
                }
              }}
            >
              delete
            </span>
          </Conditional>
        </div>
      }
      onOk={() => {
        const {
          type,
          trigger: tempTrigger,
          condition,
          enabled,
          title,
          description,
          activeFrom: tempFrom,
          activeTo: tempTo,
          intervalTimeUnit,
        } = formRef.current.get()

        const activeFrom = moment(tempFrom, 'YYYY-MM-DDTHH:mm:ss').valueOf()
        const activeTo = moment(tempTo, 'YYYY-MM-DDTHH:mm:ss').valueOf()

        let trigger
        if (type === 'PERIODIC')
          trigger = Number(tempTrigger) * timeUnitMultiplier[intervalTimeUnit]
        else if (type === 'REACTION') trigger = 'AUTOMATED_EVENT_FINISHED'
        else trigger = moment(tempTrigger, 'YYYY-MM-DDTHH:mm:ss').valueOf()

        let tempError
        if (type === 'PERIODIC' && Number(tempTrigger) < 1) {
          tempError = true
          setError(prev => ({
            ...prev,
            periodic: {
              ...prev.periodic,
              period: 'Period must be at least 1\n',
            },
          }))
        }
        if (type === 'TIMED' && !trigger) {
          tempError = true
          setError({ ...error, timed: 'No trigger' })
        }
        if (
          type === 'PERIODIC' &&
          activePeriodEnabled &&
          (activeFrom < date.valueOf() ||
            activeTo < date.valueOf() ||
            activeFrom >= activeTo)
        ) {
          tempError = true
          setError(prev => ({
            ...prev,
            periodic: {
              ...prev.periodic,
              dates:
                'Invalid Dates\nActive dates cannot be in the past\n"From" must be before "To"\n',
            },
          }))
        }

        if (!tempError) {
          createAutomation(
            automation?.id || null,
            type,
            trigger,
            condition,
            action,
            resourceId,
            enabled,
            activePeriodEnabled ? activeFrom : null,
            activePeriodEnabled ? activeTo : null,
            title,
            description
          )
          ref.current.close()
          setError({ timed: null, periodic: { period: null, dates: null } })
        }
      }}
      onClose={() => {
        ref.current.close()
        setError({ timed: null, periodic: { period: null, dates: null } })
      }}
      onCancel={() => {
        ref.current.close()
        setError({ timed: null, periodic: { period: null, dates: null } })
      }}
      okText="Automate"
      footer
    >
      <Form ref={formRef} preload={formPreload} className={classes.content}>
        <div className={classes.formGroup}>
          <FormItem
            className={classes.formTitle}
            dataKey="title"
            margin="dense"
            fullWidth
            label="Title"
            component={TextField}
          />
          <FormItem
            className={classes.formDescription}
            dataKey="description"
            margin="dense"
            fullWidth
            label="Description"
            component={TextField}
          />
        </div>
        <div className={classes.formGroup}>
          <div className={classes.groupLabel}>Activation:</div>
          <FormItem
            className={classes.formType}
            dataKey="type"
            margin="dense"
            onChange={() =>
              setError({ timed: null, periodic: { period: null, dates: null } })
            }
            component={RadioGroup}
          >
            <FormControlLabel
              value="TIMED"
              control={<Radio size="small" color="default" />}
              label="Timed"
            />
            <FormControlLabel
              value="PERIODIC"
              control={<Radio size="small" color="default" />}
              label="Periodic"
            />
            <FormControlLabel
              value="REACTION"
              control={
                <Radio
                  disabled={
                    automations
                      ? !automations.filter(a => a !== automation).length
                      : true
                  }
                  size="small"
                  color="default"
                />
              }
              label="Run after"
            />
          </FormItem>
          <FormConditional dataKey="type" value="TIMED">
            <FormItem
              dataKey="trigger"
              margin="dense"
              defaultValue={formPreload.trigger_time}
              fullWidth
              error={!!error.timed}
              onChange={() => setError({ ...error, timed: null })}
              type="datetime-local"
              InputProps={{
                inputProps: { step: 1 },
              }}
              component={TextField}
            />
          </FormConditional>
          <FormConditional dataKey="type" value="PERIODIC">
            <div className={classes.formPeriodicInputDiv}>
              <FormItem
                label="Period"
                dataKey="trigger"
                defaultValue={formPreload.trigger_interval}
                margin="dense"
                error={!!error.periodic?.period}
                onChange={() =>
                  setError({
                    ...error,
                    periodic: { ...error.periodic, period: null },
                  })
                }
                type="number"
                InputProps={{
                  inputProps: { min: 1, step: 1 },
                }}
                component={TextField}
              />
              <FormItem
                label="unit"
                dataKey="intervalTimeUnit"
                defaultValue={formPreload.intervalTimeUnit}
                margin="dense"
                select
                component={TextField}
              >
                <MenuItem value="s">seconds</MenuItem>
                <MenuItem value="m">minutes</MenuItem>
                <MenuItem value="h">hours</MenuItem>
                <MenuItem value="d">days</MenuItem>
              </FormItem>
            </div>
          </FormConditional>
          <FormConditional dataKey="type" value="REACTION">
            <FormItem
              dataKey="condition.automation_id"
              defaultValue=""
              select
              label="Automation"
              fullWidth
              component={TextField}
            >
              {automations &&
                automations
                  .filter(a => a !== automation)
                  .map(a => (
                    <MenuItem key={a.id} value={a.id}>
                      {a.name}
                    </MenuItem>
                  ))}
            </FormItem>
          </FormConditional>
          <FormConditional
            dataKey="type"
            value={val => val === 'PERIODIC' || val === 'REACTION'}
          >
            <FormControlLabel
              className={classes.activePeriodSwitch}
              control={
                <Switch
                  color="primary"
                  checked={activePeriodEnabled}
                  onChange={e => {
                    setActivePeriodEnabled(e.target.checked)
                    setError({
                      ...error,
                      periodic: { ...error.periodic, dates: null },
                    })
                  }}
                />
              }
              label="Use active period (input in local time)"
            />
          </FormConditional>
          <FormConditional
            dataKey="type"
            value={val =>
              activePeriodEnabled && (val === 'PERIODIC' || val === 'REACTION')
            }
          >
            <FormItem
              label="Active from"
              dataKey="activeFrom"
              margin="dense"
              defaultValue={formPreload.activeFrom}
              fullWidth
              error={!!error.periodic?.dates}
              onChange={() =>
                setError({
                  ...error,
                  periodic: { ...error.periodic, dates: null },
                })
              }
              type="datetime-local"
              InputProps={{
                inputProps: { step: 1 },
              }}
              component={TextField}
            />
            <FormItem
              label="Active to"
              dataKey="activeTo"
              margin="dense"
              defaultValue={formPreload.activeTo}
              fullWidth
              error={!!error.periodic?.dates}
              onChange={() =>
                setError({
                  ...error,
                  periodic: { ...error.periodic, dates: null },
                })
              }
              type="datetime-local"
              InputProps={{
                inputProps: { step: 1 },
              }}
              component={TextField}
            />
          </FormConditional>
        </div>
      </Form>
      <Conditional dependencies={Object.values(error).some(val => !!val)}>
        <pre
          style={{
            display: 'flex',
            width: '100%',
            color: 'red',
            fontSize: '12px',
            lineHeight: '12px',
            fontWeight: 600,
          }}
        >
          {error.timed}
          {error.periodic?.period}
          {error.periodic?.dates}
        </pre>
      </Conditional>
    </Modal>
  )
})

AutomationModal.propTypes = {
  resource: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
}

export default AutomationModal
