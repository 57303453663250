import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { useStore } from 'core'
import { paginate, isObject, extractValue } from 'libs/utils/helpers'
import { useRefs } from 'libs/utils/hooks'

import Conditional from 'components/Conditional'
import PopUpMenu from 'components/PopUpMenu'
import Pagination from 'components/Pagination'
import Column from './Column'
import { Provider } from './TableContext'

import './style.scss'

/* eslint-disable no-underscore-dangle */

const classes = {
  wrapper: 'Table-wrapper',
  content: 'Table-content',
  footer: 'Table-footer',
  checkboxWrapper: 'Table-checkboxWrapper',
}

const getKeyStrings = (obj, prefix) => {
  if (isObject(obj)) {
    const keys = Object.keys(obj)
    return keys.reduce((acc, cur) => {
      const result = [...acc]
      if (isObject(obj[cur])) {
        result.push(...getKeyStrings(obj[cur], prefix ? `${prefix}.${cur}` : cur))
      } else {
        result.push(prefix ? `${prefix}.${cur}` : cur)
      }
      return result
    }, [])
  }
  return null
}
const getObjFromKeys = keyArray => {
  const sortedArray = [...keyArray].sort().filter(string => string.charAt(0) !== '_')
  const splitArray = [...sortedArray].map(string => string.split('.'))

  const injectIntoObj = (obj = {}, array) => {
    const keys = Object.keys(obj)
    const key = array[0]
    const hasKey = keys.indexOf(key) !== -1
    let newObj = null
    if (array.length > 2) {
      newObj = array
        .slice(1)
        .reverse()
        .slice(1)
        .reduce((acc, cur) => {
          return { [cur]: acc }
        }, array[array.length - 1] === 'true')
    } else newObj = array[array.length - 1] === 'true'
    // const newObj =
    //   array.length > 2
    //     ? array
    //         .slice(1)
    //         .reverse()
    //         .reduce((acc, cur) => {
    //           return { [cur]: acc }
    //         }, defVal
    //     : defVal
    const tempObj = { ...obj }
    if (!hasKey) {
      tempObj[key] = newObj
    } else if (array) {
      tempObj[key] = { ...injectIntoObj(tempObj[key], array.slice(1)) }
    }
    return tempObj
  }

  const obj = splitArray.reduce((acc, cur) => {
    return { ...injectIntoObj(acc, cur) }
  }, {})

  return obj
}

const Table = props => {
  const [placeholder, setPlaceholder] = useState(null)
  const [sort, setSort] = useState({})
  const [tableStatePref, setTableStatePref] = useState(null)
  const container = useRef()
  // const [rect, container] = useRefRect()
  const [getRef, setRef] = useRefs()
  const { data = [], footer, check, onRow, prefKey, children } = props
  const {
    id: checkID,
    checked,
    checkCallback = () => {},
    checkAllCallback = () => {},
  } = { ...check }
  const { manageColumns = true, pagination } = { ...footer }
  const { perPage = 10, currentPage = 1, setCurrentPage } = { ...pagination }
  // let { left = 0 } = { ...rect }
  // left += (rect || 0) && container.current.getBoundingClientRect().left
  const {
    setTableDefault,
    updateTablePref,
    pref: tableStorePref,
  } = useStore('setTableDefault', 'updateTablePref', 'selectPref')
  const pref = prefKey ? tableStorePref[prefKey] : tableStatePref
  const { _order: order = [] } = { ...pref }

  const scrollPositionCallback = useCallback(() => {
    const ref = container.current
    return ref && ref.scrollLeft
  }, [container])

  const placeholderOrderCallback = useCallback(obj => {
    if (obj) setPlaceholder({ order: obj.order, key: obj.key })
    else setPlaceholder(null)
  }, [])

  const sortDataCallback = useCallback(sortObj => setSort(sortObj), [setSort])

  const savePrefCallback = useCallback(
    (key, payload) => {
      if (prefKey) updateTablePref({ prefKey, key, data: payload })
      else {
        const obj = { ...tableStatePref }
        const payloadDataKeys = Object.keys(payload)
        payloadDataKeys.forEach(pdk => {
          if (pdk === '_order') {
            const currentOrder = [...obj._order].filter(e => e !== key)
            currentOrder.splice(payload._order, 0, key)
            obj._order = currentOrder
          } else {
            obj[key] = { ...obj[key], [pdk]: payload[pdk] }
          }
        })
        setTableStatePref({ ...obj })
      }
    },
    [updateTablePref, prefKey, tableStatePref]
  )

  const dataKeys = useMemo(() => {
    if (data) {
      const tempDataKeys = data.reduce((acc, cur) => {
        const strings = getKeyStrings(cur) || []
        const result = [...acc]
        strings.forEach(
          string => result.indexOf(string) === -1 && result.push(string)
        )
        return result
      }, [])
      const devColumns = React.Children.toArray(children)
        .map(child => child.props.dataKey)
        .reverse()
      let tempResult = [...tempDataKeys]
      devColumns.forEach(col => {
        tempResult = tempResult.filter(key => key !== col)
        if (col !== '_actionsColumn' && col !== '_defaultColumn')
          tempResult.unshift(col)
        if (col === '_actionsColumn') tempResult.push('_actionsColumn')
      })
      check && tempResult.unshift('_checkerColumn')
      tempResult.push('_fillerColumn')
      return tempResult
    }
    return []
  }, [data, check, children])

  const columnData = useMemo(() => {
    const defaultColumn = React.Children.toArray(children).find(
      child => child.props.dataKey === '_defaultColumn'
    )
    const {
      title: defTitle = null,
      width: defWidth = null,
      resizable: defResizable = {},
      render: defRender = null,
      hidden: defHidden = false,
      sortFunction: defSortFunction = null,
    } = defaultColumn?.props || {}
    const defaultColumnProps = {
      width: defWidth,
      resizable: defResizable,
      render: defRender,
      hidden: defHidden,
      sortFunction: defSortFunction,
    }
    if (defTitle) defaultColumnProps.title = defTitle
    const tempColumnData = dataKeys.reduce((acc, cur) => {
      const result = { ...acc }
      const column = React.Children.toArray(children).find(
        child => child.props.dataKey === cur
      )
      let columnProps = {}
      if (column) {
        const { title, dataKey, width, resizable, render, hidden, sortFunction } =
          column.props
        if (title) columnProps.title = title
        if (dataKey) columnProps.dataKey = dataKey
        if (width) columnProps.width = width
        if (resizable) columnProps.resizable = resizable
        if (render) columnProps.render = render
        if (sortFunction) columnProps.sortFunction = sortFunction
        if (hidden)
          columnProps.hidden = typeof hidden === 'function' ? !!hidden(cur) : hidden
        // columnProps = {
        //   title,
        //   dataKey,
        //   width,
        //   resizable,
        //   render,
        //   hidden: typeof hidden === 'function' ? !!hidden(cur) : hidden,
        //   sortFunction,
        // }
      } else {
        columnProps = {
          ...defaultColumnProps,
          hidden:
            typeof defaultColumnProps.hidden === 'function'
              ? !!defaultColumnProps.hidden(cur)
              : defaultColumnProps.hidden,
        }
      }
      switch (cur) {
        case '_checkerColumn':
          result[cur] = {
            title: 'Checker',
            dataKey: '_checkerColumn',
            width: 80,
            hidden: false,
            className: 'checker',
            type: 'checker',
            render: (value, header, record, records) => {
              const isChecked = header
                ? checked.length !== 0 &&
                  (checked.length === records.length || 'undetermined')
                : checked.indexOf(extractValue(record, checkID)) !== -1
              return (
                <div
                  role="checkbox"
                  aria-checked={isChecked}
                  tabIndex={0}
                  className={classes.checkboxWrapper + (isChecked ? ' checked' : '')}
                  style={{ top: header ? 'calc(50% - 0.5em)' : '' }}
                  onClick={() => {
                    if (header) {
                      checkAllCallback(records, isChecked, records)
                    } else {
                      checkCallback(
                        record,
                        isChecked,
                        records,
                        extractValue(record, checkID)
                      )
                    }
                  }}
                  onKeyDown={() => {}}
                >
                  &nbsp;
                </div>
              )
            },
          }
          break
        case '_actionsColumn':
          result[cur] = {
            title: 'Actions',
            dataKey: '_actionsColumn',
            width: 120,
            hidden: false,
            className: 'actions',
            type: 'actions',
            render: () => {},
            ...columnProps,
          }
          break
        case '_fillerColumn':
          result[cur] = {
            title: 'Filler',
            dataKey: '_fillerColumn',
            hidden: false,
            className: 'filler',
            type: 'filler',
            render: () => {},
          }
          break
        default:
          result[cur] = {
            title: cur,
            dataKey: cur,
            resizable: {},
            hidden: false,
            sortFunction: (a, b) => extractValue(a, cur) - extractValue(b, cur),
            ...columnProps,
          }
      }
      const refGetter = () => getRef(cur)
      result[cur] = { ...result[cur], _ref: refGetter }
      return result
    }, {})
    return tempColumnData
  }, [dataKeys, checkID, checked, checkCallback, checkAllCallback, getRef, children])

  const columns = useMemo(() => {
    const tempColumns = dataKeys.map(key =>
      key !== '_placeholderColumn' ? (
        <Column key={`Table-column-${key}`} dataKey={key} setRef={setRef} />
      ) : null
    )
    return tempColumns
  }, [dataKeys, setRef])

  const sortedData = useMemo(() => {
    let tempSortedData = null
    if (sort.key) {
      tempSortedData =
        sort.sort &&
        data
          .slice()
          .sort(
            (a, b) =>
              sort.sort(a, b, sort.key) * (sort.order && (-1) ** (sort.order + 1))
          )
    }
    return tempSortedData || data || []
  }, [sort, data])

  const dataSegment = useMemo(() => {
    return pagination ? paginate(sortedData, perPage).page(currentPage) : sortedData
  }, [sortedData, pagination, perPage, currentPage])

  const contextColumnData = useMemo(() => {
    const tempData = { ...columnData }
    if (pref) {
      Object.keys(columnData).forEach(column => {
        tempData[column] = { ...tempData[column], ...pref[column] }
      })
    }
    return tempData
  }, [columnData, pref])

  const popUpCallback = (key, bool) => {
    if (prefKey)
      updateTablePref({
        prefKey,
        key,
        data: { hidden: !bool, _order: order.length },
      })
    else savePrefCallback(key, { hidden: !bool, _order: order.length })
  }

  const contextOrder = useMemo(() => {
    let tempOrder = [...dataKeys]
    if (order.length) {
      tempOrder = [...order]
      if (check) tempOrder.unshift('_checkerColumn')
      tempOrder.push(
        ...dataKeys.filter(
          key => order.indexOf(key) === -1 && key !== '_fillerColumn'
        )
      )
      tempOrder.splice(tempOrder.length, 0, '_fillerColumn')
    }
    if (placeholder) {
      tempOrder = tempOrder.filter(key => key !== placeholder.key)
      tempOrder.splice(placeholder.order, 0, '_placeholderColumn')
    }
    return tempOrder
  }, [order, placeholder, check, dataKeys])

  const contextData = {
    columns: contextColumnData,
    order: contextOrder,
    hasCheck: !!check,
    sort,
    getTableLeft: () =>
      (container.current && container.current.getBoundingClientRect().left) || 0,
    data: dataSegment,
    fullData: data,
    onRow,
    updateTablePref: savePrefCallback,
    getScrollPosition: scrollPositionCallback,
    setPlaceholderOrder: placeholderOrderCallback,
    sortData: sortDataCallback,
  }

  useEffect(() => {
    if (!pref && dataKeys.length) {
      const tempKeys = dataKeys.filter(
        key =>
          key !== '_checkerColumn' &&
          key !== '_actionsColumn' &&
          key !== '_fillerColumn'
      )
      const tableDefault = Object.keys(columnData).reduce(
        (acc, cur) => {
          const result = {
            ...acc,
            [cur]: { resizeWidth: null, hidden: columnData[cur].hidden },
          }
          return result
        },
        { _order: [...tempKeys] }
      )
      if (prefKey) setTableDefault({ prefKey, data: { ...tableDefault } })
      else setTableStatePref({ ...tableDefault })
    }
  }, [pref, columnData, dataKeys, setTableDefault, prefKey])

  const mappedDataKeys = dataKeys.map(
    key => `${key}.${!contextData.columns[key].hidden}`
  )
  const columnDataObj = getObjFromKeys(mappedDataKeys)

  return (
    <div className={classes.wrapper}>
      <Provider value={contextData}>
        <div
          ref={container}
          className={classes.content}
          style={{ maxHeight: footer ? 'calc(100% - 3em)' : '' }}
        >
          {columns}
        </div>
      </Provider>
      <Conditional dependencies={!!footer}>
        <div className={classes.footer}>
          <Conditional dependencies={manageColumns}>
            <PopUpMenu
              dataObj={columnDataObj}
              title="Show columns"
              callback={popUpCallback}
            />
          </Conditional>
          <Conditional dependencies={!!pagination}>
            <Pagination
              dataLength={(data && data.length) || 0}
              perPage={perPage}
              currentPage={currentPage}
              pageCallback={page => setCurrentPage && setCurrentPage(page)}
            />
          </Conditional>
        </div>
      </Conditional>
    </div>
  )
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  footer: PropTypes.shape({
    manageColumns: PropTypes.bool,
    pagination: PropTypes.shape({
      perPage: PropTypes.number,
      currentPage: PropTypes.number,
      setCurrentPage: PropTypes.func,
    }),
  }),
  check: PropTypes.shape({
    id: PropTypes.string,
    checked: PropTypes.array,
    checkCallback: PropTypes.func,
    checkAllCallback: PropTypes.func,
  }),
  onRow: PropTypes.func,
  prefKey: PropTypes.string,
  children: PropTypes.node,
}

export default Table
