import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import { useRefRect } from 'libs/utils/hooks'
import {
  isObject,
  generateGridPoints,
  getFormatFromScale,
  getDomain,
  getViewBox,
  interpolateLine,
  calculateZoomBox,
} from 'libs/utils/helpers'

import Conditional from 'components/Conditional'
import Spinner from 'components/Spinner'
import ChartWrapper from 'components/Charts/ChartWrapper'
import LineLegend from './LineLegend'
import { Context } from './LineChartContext'

import './style.scss'

const classes = {
  wrapper: 'LineChart-LineGraph-wrapper',
  loading: 'LineChart-LineGraph-loading',
  graphSVG: 'LineChart-LineGraph-graphSVG',
  noDataDiv: 'LineChart-LineGraph-noDataDiv',
}

const zoomCap = 10000 // percentage
const rounding = false

const LineGraph = props => {
  const [hovered, setHovered] = useState(null)
  const [zoomStack, setZoomStack] = useState([])
  const [rect, ref] = useRefRect()
  const wrapperRef = useRef()
  const {
    data: contextData = [],
    xAxisLabel,
    yAxisLabel,
    xAxisType,
    domain: contextDomain,
    loading,
  } = useContext(Context)

  const limits = useMemo(() => {
    const x = { min: null, max: null }
    const y = { min: null, max: null }
    if (isObject(contextDomain?.x)) {
      const { min, max } = contextDomain.x
      x.min = min || min === 0 ? min : null
      x.max = max || max === 0 ? max : null
    }
    if (isObject(contextDomain?.y)) {
      const { min, max } = contextDomain.y
      y.min = min || min === 0 ? min : null
      y.max = max || max === 0 ? max : null
    }
    return { x, y }
  }, [contextDomain])

  const { legend, xAxis, yAxis, grid } = props
  const gridSize = (grid !== true && grid) || 30

  const { position: legendPosition = 'left', width: legendWidth = 160 } =
    (legend && legend !== true ? legend : {}) || {}

  // const { position: yAxisPosition = 'left' } = yAxis || {}
  // const { position: xAxisPosition = 'bottom' } = xAxis || {}

  const { mouseDownHandler: handler } = wrapperRef.current || {}

  const data = useMemo(() => {
    return contextData.map(datum => {
      return { ...datum, id: uuid() }
    })
  }, [contextData])

  const domain = useMemo(() => {
    const tempDomain = getDomain(data, limits)
    if (Array.isArray(contextDomain?.x) && contextDomain.x.length >= 2) {
      const { x } = tempDomain
      const [min, max] = contextDomain.x
      x.min = min
      x.max = max
    }
    if (Array.isArray(contextDomain?.y) && contextDomain.y.length >= 2) {
      const { y } = tempDomain
      const [min, max] = contextDomain.y
      y.min = min
      y.max = max
    }
    return tempDomain
  }, [data, limits, contextDomain])

  /// /////////////// ///
  /// DOMAIN PADDING ///
  /// /////////////// ///
  const paddingX = 0
  const paddingY = 0.1
  const correctedDomain = useMemo(() => {
    const domainXRange = Math.abs(domain.x.max - domain.x.min)
    const domainYRange = Math.abs(domain.y.max - domain.y.min)
    const temp = { x: { min: null, max: null }, y: { min: null, max: null } }
    temp.x.min =
      domain.x.min - paddingX * (domainXRange || Math.abs(domain.x.min) || 1)
    temp.x.max =
      domain.x.max + paddingX * (domainXRange || Math.abs(domain.x.max) || 1)
    temp.y.min =
      domain.y.min - paddingY * (domainYRange || Math.abs(domain.y.min) || 1)
    temp.y.max =
      domain.y.max + paddingY * (domainYRange || Math.abs(domain.y.max) || 1)
    return temp
  }, [domain])
  /// /////////////// ///
  /// VIEWBOX PADDING ///
  /// /////////////// ///

  const [viewBox, tempZoom, normalizer] = useMemo(() => {
    return getViewBox(correctedDomain, zoomStack, rounding)
  }, [correctedDomain, zoomStack])

  const [zoom, allowZoom] = useMemo(
    () => [
      {
        x: Math.min(zoomCap, tempZoom.x),
        y: Math.min(zoomCap, tempZoom.y),
      },
      tempZoom.x < zoomCap || tempZoom.y < zoomCap,
    ],
    [tempZoom]
  )

  const ratios = useMemo(() => {
    const xRatio = (rect && viewBox.xRange / rect.width) || null
    const yRatio = (rect && viewBox.yRange / rect.height) || null
    return { x: xRatio, y: yRatio }
  }, [rect, viewBox.xRange, viewBox.yRange])

  const [xAxisPoints, xScale, yAxisPoints, xGridPoints, yGridPoints] =
    useMemo(() => {
      const [xPoints, tempXScale] = generateGridPoints(
        viewBox.x,
        viewBox.xRange,
        ratios.x,
        gridSize,
        normalizer.x,
        false,
        xAxisType === 'time'
      )
      const tempXPoints = xPoints.map(point => {
        return {
          position: (point - viewBox.x) / viewBox.xRange,
          value: point * normalizer.x,
        }
      })
      const tempYPoints = generateGridPoints(
        viewBox.y,
        viewBox.yRange,
        ratios.y,
        gridSize
      )[0].map(point => {
        return {
          position: (point - viewBox.y) / viewBox.yRange,
          value: point * normalizer.y,
        }
      })
      const tempXGrid = tempXPoints.map(point => point.position) || []
      const tempYGrid = tempYPoints.map(point => point.position) || []
      return [tempXPoints, tempXScale, tempYPoints, tempXGrid, tempYGrid]
    }, [viewBox, ratios, gridSize, xAxisType, normalizer])

  const lines = useMemo(() => {
    if (data.length) {
      const tempResult = data.map(datum => {
        if (datum.data.length) {
          const line = datum.data
            .sort((a, b) => a.x - b.x)
            .map(({ x, y }) => ({
              x: Number(Number.parseFloat(x / normalizer.x).toFixed(4)),
              y: Number(Number.parseFloat(y / normalizer.y).toFixed(4)),
            }))
          const [pathString, dashString, dashOffset] = interpolateLine(
            line,
            viewBox,
            ratios
          )
          return (
            <path
              key={`LineGraph-line-${datum.color}-${uuid()}`}
              d={pathString}
              strokeDasharray={dashString}
              strokeDashoffset={dashOffset ? `-${dashOffset}` : ''}
              // onMouseEnter={() => setHovered(datum.id)}
              // onMouseLeave={() => setHovered(null)}
              style={{
                fillOpacity: (hovered && (hovered === datum.id ? 0.25 : 0.001)) || 0,
                display: hovered && hovered !== datum.id && 'none',
                fill: datum.color,
                stroke: datum.color,
                strokeWidth: dashString === '' ? 0 : '',
              }}
            />
          )
        }
        return null
      })
      return tempResult
    }
    return null
  }, [data, ratios, hovered, normalizer, viewBox])

  const zoomCallback = useCallback(
    zoomBox => {
      if (allowZoom) {
        const tempZoomBox = calculateZoomBox(
          zoomBox,
          zoomCap,
          correctedDomain,
          viewBox,
          normalizer,
          rounding
        )
        setZoomStack([...zoomStack, tempZoomBox])
      }
    },
    [allowZoom, zoomStack, correctedDomain, viewBox, normalizer]
  )

  const isThereDataToDisplay = data.reduce((acc, cur) => {
    if (!acc) return cur.data.length > 1
    return acc
  }, false)

  const svgViewBox = {
    x: viewBox.x,
    y: Number(Number.parseFloat(-(viewBox.y + viewBox.yRange)).toFixed(4)),
    xRange: viewBox.xRange,
    yRange: viewBox.yRange,
  }

  const xFormat = useMemo(() => getFormatFromScale(xScale), [xScale])

  return (
    <div
      className={classes.wrapper}
      style={{ flexDirection: legendPosition === 'right' ? 'row-reverse' : '' }}
    >
      <Conditional dependencies={loading}>
        <div className={classes.loading}>
          <Spinner
            spin={1}
            strokeWidth={2}
            mainColor="#1E90FF"
            emptyColor="#2e313a"
          />
          <span>Fetching data...</span>
        </div>
      </Conditional>
      <Conditional dependencies={isThereDataToDisplay}>
        <Conditional dependencies={legend}>
          <LineLegend
            data={data}
            hoverCallback={id => setHovered(id)}
            hoverReset={() => setHovered(null)}
            width={legendWidth}
          />
        </Conditional>
        <ChartWrapper
          ref={wrapperRef}
          xAxis={
            xAxis && {
              ...xAxis,
              points: xAxisPoints,
              label: xAxisLabel,
              render:
                xAxisType === 'time'
                  ? (value, i, length, x, _) =>
                      i === 0 || i === length - 1 ? (
                        <>
                          <tspan dx="0" dy="-1em">
                            {moment(value * 1000).format('DD-MM-YYYY')}
                          </tspan>
                          <tspan x={x} dy="1em">
                            {moment(value * 1000).format('HH:mm:ss.SSS')}
                          </tspan>
                          <tspan x={x} dy="1em">
                            {moment(value * 1000).format('dddd')}
                          </tspan>
                        </>
                      ) : (
                        moment(value * 1000).format(xFormat)
                      )
                  : null,
            }
          }
          yAxis={yAxis && { ...yAxis, points: yAxisPoints, label: yAxisLabel }}
          grid={(!!grid && { xGridPoints, yGridPoints }) || {}}
          zoomCallback={allowZoom && zoomCallback}
          footer={{
            zoomOutCallback: () =>
              setZoomStack([...zoomStack].slice(0, zoomStack.length - 1)),
            zoomResetCallback: () => setZoomStack([]),
            currentZoom: zoom,
          }}
        >
          <svg
            ref={ref}
            className={classes.graphSVG}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`${svgViewBox.x} ${svgViewBox.y} ${svgViewBox.xRange} ${svgViewBox.yRange}`}
            preserveAspectRatio="none"
            onMouseDown={event => handler && allowZoom && handler(event)}
          >
            {lines}
          </svg>
        </ChartWrapper>
      </Conditional>
      <Conditional dependencies={!isThereDataToDisplay}>
        <div className={classes.noDataDiv}>
          <div>Line Graph</div>
          <div>NO DATA</div>
        </div>
      </Conditional>
    </div>
  )
}

LineGraph.propTypes = {
  legend: PropTypes.shape({
    position: PropTypes.oneOf(['left', 'right']),
    width: PropTypes.number,
  }),
  xAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['top', 'bottom']),
      height: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
    }),
    PropTypes.bool,
  ]),
  yAxis: PropTypes.oneOfType([
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      width: PropTypes.number,
      fontSize: PropTypes.number,
      labelSize: PropTypes.number,
      render: PropTypes.func,
    }),
    PropTypes.bool,
  ]),
  grid: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

export default LineGraph
